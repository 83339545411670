import { isPresent, max, min } from './validation'
import dotProp from 'dot-prop'
import moment from 'moment'

/**
 * Returns a ConstraintViolations object for the specified subject, see state.js for structure details
 * Does NOT contain 'Mandatory' constraints.
 */
export function validateConstraintsExceptMandatoryOnSubject (layout, subjectKey, rfp) {
  return _validateConstraintsOnSubject(layout, subjectKey, rfp, false)
}

/**
 * Returns a ConstraintViolations object with only the specified subject and only for 'Mandatory' constraints
 */
export function validateMandatoryFieldsOnSubject (layout, subjectKey, rfp) {
  return _validateConstraintsOnSubject(layout, subjectKey, rfp, true)
}

function _validatePanel (panel, section, isList, listObject, rootObject, onlyMandatory, constraintViolations) {
  for (const row of panel.rows) {
    for (const field of row.fields) {
      if (!_isEnabled(field, null, rootObject, listObject, panel.key)) {
        continue
      }

      for (const constraint of field.constraints) {
        if ((onlyMandatory && constraint.dtype !== 'Mandatory') ||
          (!onlyMandatory && constraint.dtype === 'Mandatory')) {
          continue
        }

        const isViolated = isList
          ? isConstraintViolated(constraint,
            listObject[field.key],
            listObject,
            field.fieldType)
          : isConstraintViolated(constraint,
            dotProp.get(rootObject, field.key),
            rootObject,
            field.fieldType)

        if (isViolated) {
          const path = isList
            ? `${section.key}.${panel.key}.${listObject.id}.${field.key}`
            : `${section.key}.${panel.key}.${field.key}`

          const fieldViolations = dotProp.get(constraintViolations, path) || []
          dotProp.set(constraintViolations, path, [...fieldViolations, constraint.dtype])
        }
      }
    }
  }
}

function _validateConstraintsOnSubject (layout, subjectKey, rfp, onlyMandatory) {
  const constraintViolations = {}
  const rootObject = dotProp.get(rfp, `scope.${subjectKey}`)
  for (const section of layout.sections) {
    if (!_sectionApplicable(section.key, rootObject)) {
      continue
    }

    for (const panel of section.panels) {
      if (panel.isList) {
        const panels = dotProp.get(rootObject, panel.key) || []

        for (const listObject of panels) {
          _validatePanel(panel, section, true, listObject, rootObject, onlyMandatory, constraintViolations)
        }
      } else {
        _validatePanel(panel, section, false, null, rootObject, onlyMandatory, constraintViolations)
      }
    }
  }

  return constraintViolations
}

export function isEnabled (fieldOrPanel, sectionKey, subjectKey, root, listObject, panelKey) {
  const rootObject = dotProp.get(root, subjectKey)
  return _isEnabled(fieldOrPanel, sectionKey, rootObject, listObject, panelKey)
}

function _isEnabled (fieldOrPanel, sectionKey, rootObject, listObject, panelKey) {
  if (!_sectionApplicable(sectionKey, rootObject)) {
    return false
  }

  if (panelKey && !_panelApplicable(panelKey, rootObject)) {
    return false
  }

  for (const enabledByRelation of fieldOrPanel.enabledBy) {
    let referencedValue
    if (enabledByRelation.relativePath) {
      referencedValue = dotProp.get(listObject, enabledByRelation.relativePath)
    } else if (enabledByRelation.absolutePath) {
      referencedValue = dotProp.get(rootObject, enabledByRelation.absolutePath)
    } else {
      console.error('invalid enabledByRelation definition: either relative or absolute path must be set', enabledByRelation)
      return true
    }
    if (!enabledByRelation.values.includes(referencedValue)) {
      return false
    }
  }
  return true
}

export function sectionApplicable (sectionKey, rootObject) {
  return _sectionApplicable(sectionKey, rootObject)
}

export function panelApplicable (panelKey, rootObject) {
  return _panelApplicable(panelKey, rootObject)
}

function _sectionApplicable (sectionKey, rootObject) {
  const _notApplicableSections = rootObject ? rootObject._notApplicableSections : null
  return !_notApplicableSections || !_notApplicableSections.includes(sectionKey)
}

function _panelApplicable (panelKey, rootObject) {
  const _notApplicableSections = rootObject ? rootObject._notApplicableSubSections : null
  return !_notApplicableSections || !_notApplicableSections.includes(panelKey)
}

export function isConstraintViolated (constraint, value, referencedObject, fieldType) {
  switch (constraint.dtype) {
    case 'Mandatory':
      return Mandatory(value)
    case 'MaxDouble':
      return MaxDouble(constraint, value)
    case 'MaxDate':
      return MaxDate(constraint, value)
    case 'MinDate':
      return MinDate(constraint, value)
    case 'MinDouble':
      return MinDouble(constraint, value)
    case 'SmallerThan':
      return SmallerThan(constraint, value, referencedObject, fieldType)
    case 'GreaterThan':
      return GreaterThan(constraint, value, referencedObject, fieldType)
    default:
      console.error(`unknown constraint type: "${constraint.dtype}". Cannot validate`)
      return false
  }
}

const Mandatory = (value) => {
  return !isPresent(value)
}

const MaxDouble = (constraint, value) => {
  return !max(constraint.value)(value)
}

const MinDouble = (constraint, value) => {
  return !min(constraint.value)(value)
}

const MaxDate = (constraint, value) => {
  return isPresent(value) && moment(value).isAfter(moment(constraint.value))
}

const MinDate = (constraint, value) => {
  return isPresent(value) && moment(value).isBefore(moment(constraint.value))
}

const SmallerThan = (constraint, value, referencedObject, fieldType) => {
  if (!isPresent(value)) {
    return false
  }
  let referencedValue
  if (constraint.relativePath) {
    referencedValue = referencedObject[constraint.relativePath]
  } else if (constraint.absolutePath) {
    referencedValue = referencedObject[constraint.absolutePath]
  } else {
    console.error('invalid constraint definition: either relative or absolute path must be set', constraint)
    return true
  }

  if (!isPresent(referencedValue)) {
    return false
  }
  if (['DATE', 'DATETIME'].includes(fieldType)) {
    return moment(value).isSameOrAfter(moment(referencedValue))
  }

  return !(value < referencedValue)
}

const GreaterThan = (constraint, value, referencedObject, fieldType) => {
  if (!isPresent(value)) {
    return false
  }
  let referencedValue
  if (constraint.relativePath) {
    referencedValue = referencedObject[constraint.relativePath]
  } else if (constraint.absolutePath) {
    referencedValue = referencedObject[constraint.absolutePath]
  } else {
    console.error('invalid constraint definition: either relative or absolute path must be set', constraint)
    return false
  }
  if (referencedValue === undefined) {
    console.error('invalid constraint definition: referenced field not found', constraint)
    return false
  }
  if (!isPresent(referencedValue)) {
    return false
  }
  if (['DATE', 'DATETIME'].includes(fieldType)) {
    return moment(value).isSameOrBefore(moment(referencedValue))
  }

  return !(value > referencedValue)
}
