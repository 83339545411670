import Vue from 'vue'

export default {

  setAnalytics (state, dashboardMetrics) {
    Vue.set(state, 'analytics', dashboardMetrics)
  },

  setAnalyticsCurrency (state, currency) {
    Vue.set(state, 'analyticsCurrency', currency)
  }

}
