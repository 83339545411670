import dotProp from 'dot-prop'

export default {

  updateNotApplicable ({ commit }, { notApplicable, rootObject, subjectKey, sectionKey, updateMutation, subSection }) {
    const fieldName = subSection ? '_notApplicableSubSections' : '_notApplicableSections'
    const notApplicableSections = dotProp.get(rootObject, `${subjectKey}.${fieldName}`) || []
    const index = notApplicableSections.indexOf(sectionKey)

    if (notApplicable) {
      if (index < 0) {
        notApplicableSections.push(sectionKey)
      }
    } else {
      if (index > -1) {
        notApplicableSections.splice(index, 1)
      }
    }

    commit(updateMutation, {
      rootObject,
      subjectKey,
      field: fieldName,
      value: notApplicableSections
    })

    commit('validateConstraints', subjectKey)
  }

}
