<template>
  <div v-if="currentRfp">
    <ConfirmDialog
      v-if="showPageLeaveDialog"
      :text="$t('activeRFP.proposal.unsavedChangesDialog.text')"
      :title="'activeRFP.proposal.unsavedChangesDialog.title'  | t"
      @close="showPageLeaveDialog = false"
      @submit="cleanupAndLeave(); showPageLeaveDialog = false"
      acceptButtonTextOrKey="activeRFP.proposal.unsavedChangesDialog.submit"
      cancelButtonTextOrKey="buttons.cancel"/>

    <ConfirmDialog
      v-if="showAbortDialog"
      :text="$t('activeRFP.abort.abortDialog.text', { rfpName: currentRfp.name })"
      :title="$t('activeRFP.abort.abortDialog.title')"
      @close="showAbortDialog = false"
      @submit="abortCurrentRfp(); showAbortDialog = false"
      :disableWithoutProgress="verificationName !== currentRfp.name"
      acceptButtonTextOrKey="activeRFP.abort.abortDialog.submit"
      cancelButtonTextOrKey="buttons.cancel">
      <template v-slot:custom-input>
        <div style="margin-top: -10px">
          <v-textarea
            style="padding-top: 0;"
            v-model="abortionMessage"
          ></v-textarea>
          <p
            style="margin: 1px 0 5px 0;"
            v-html="$t('activeRFP.abort.abortDialog.confirmation', { interpolation: true, rfpName: currentRfp.name })"></p>
          <v-text-field
            style="padding-top: 0;"
            v-model="verificationName"
          ></v-text-field>
        </div>
      </template>
    </ConfirmDialog>

    <div class="breadcrumbs">
      <a @click="handleBackClick"><span>{{ "activeRFP.headingDashboard" | t}}</span></a>
      <span> / </span>
      <span class="project-name">{{ currentRfp.name }}</span>
    </div>

    <div class="full-title">
      <section >
        <h1>
          <v-btn style="text-transform: capitalize;" flat @click="handleBackClick">
            <v-icon>ic-chevron-left</v-icon>
            <TooltipHolder
              v-if="userIsLawFirm"
              :spanId="currentRfp.clientName"
              :text="currentRfp.clientName"
              :tooltipText="currentRfp.clientName"
              :tableDataWidth="350"
              :moveLeft="-20"
              tableData />
            <span v-if="userIsLawFirm">:&nbsp;</span>
            <TooltipHolder
              :spanId="currentRfp.name"
              :text="currentRfp.name"
              :tooltipText="currentRfp.name"
              :size="30"
              :tableDataWidth="400"
              :moveLeft="-20"
              tableData />
          </v-btn>
        </h1>
        <v-chip>
          <section>{{ `dropDownOptions.MandateType.${currentRfp.mandateType}` | t }}</section>
          <section v-if="currentRfp.mandateSubType !== null">&nbsp;/ {{ `dropDownOptions.MandateSubType.${currentRfp.mandateSubType}` | t }}</section>
        </v-chip>
        <v-chip v-if="currentRfpLawFirmRelation && currentRfpLawFirmRelation.requiresResubmission" class="warning-chip">
          <v-icon>error</v-icon>
          {{ $t('activeRFP.requiresResubmission') }}
        </v-chip>
      </section>
      <section v-if="matterWon" style="margin-left: 40px">
        <v-chip color="rgb(102, 174, 4)" style="margin-top: 17px; margin-left: 0">{{ "general.rfp.matterWonLabel" | t }}</v-chip>
      </section>
    </div>

    <div class="rfpProgress">
      <span style="word-wrap: break-word;">{{ currentRfp.description.length >= 170 ? currentRfp.description.substring(0,170)+'...' : currentRfp.description }}</span>
      <div>
        <span class="light">{{ 'activeRFP.biddingProcessSection.status.projectStageStatus' | t }}</span>
        <span style="font-size: 13px;"><b>{{ rfpLabelsStatus(currentRfp).stage | t }}</b></span>
      </div>

      <div>
        <span class="light">{{ 'activeRFP.biddingProcessSection.status.endStageStatus' | t }}</span>
        <span style="font-size: 13px;"><b>{{ date ? date : '-' }}</b></span>
      </div>

      <div>
        <span class="light">{{ 'activeRFP.biddingProcessSection.status.nextStageStatus' | t }}</span>
        <span style="font-size: 13px;"><b>{{ nextStageLabel }}</b></span>
      </div>
    </div>

    <Tabs class="tabs">
      <div><router-link :to="{ name: 'activeRfp', params: { activeTab: 'team' } }" :class="{active: activeTab === 'Team'}">{{ "activeRFP.linkTabs.projectTeam" | t }}</router-link></div>
      <div><router-link :to="{ name: 'activeRfp', params: { activeTab: 'summary' } }" :class="{active: activeTab === 'Summary'}">{{ "activeRFP.linkTabs.summary" | t }}</router-link></div>
      <div v-if="currentRfp.allowQA"><router-link :to="{ name: 'activeRfp', params: { activeTab: 'qa' } }" :class="{active: activeTab === 'QAndA'}">{{ "activeRFP.linkTabs.Q&A" | t }}</router-link></div>
      <div v-if="userIsCorporate"><router-link  :to="{ name: 'activeRfp', params: { activeTab: 'proposal' } }" :class="{active: activeTab === 'Proposal'}">{{ "activeRFP.linkTabs.lawFirms" | t }}</router-link></div>
      <div v-if="userIsLawFirm && relevantQualityScoreCriteriaSelected(currentRfp) && currentRfpLawFirmRelation.status !== 'COMPLETED_NO_CAPACITY'"><router-link :to="{ name: 'activeRfp', params: { activeTab: 'proposal' } }" :class="{active: activeTab === 'Proposal'}">{{ "activeRFP.linkTabs.proposal" | t }}</router-link></div>
      <div v-if="currentRfp.status !== 'COMPLETED_ABORTED' && !rfpIsAbortedAndLawFirmCouldNotSeeBiddingPanel && !rfpAbortedAndNoBidsPlaced">
        <TooltipHolder
          v-if="(userIsLawFirm && (currentRfpLawFirmRelation.status === 'COMPLETED_NO_CAPACITY' || ((!proposalIsClosed() || isQandA(currentRfp)) && relevantQualityScoreCriteriaSelected(currentRfp))))"
          :text="$t('activeRFP.linkTabs.biddingProcess')"
          :tooltip-text="$t('activeRFP.linkTabs.tooltips.biddingProcess')"
          is-link
          disabled
          class="disabled"
          :moveTop="15"
        />
        <router-link
          v-else
          :to="{ name: 'activeRfp', params: { activeTab: 'bidding' } }"
          :class="{active: activeTab === 'EAuction', disable: (userDidNotEnterInitialBid)}">{{ "activeRFP.linkTabs.biddingProcess" | t }}</router-link>
      </div>
      <div v-if="currentRfp.status !== 'COMPLETED_ABORTED' && (currentRfp.status !== 'COMPLETED_ABORTED_BY_CORPORATE' || progressPreAbortion(currentRfp) >= RfpProgress.ACTIVE_DECISION_PENDING)">
        <TooltipHolder
          v-if="(userIsCorporate && !decisionPending) || (!userIsCorporate && !decisionIsMade(currentRfp))"
          :text="$t('activeRFP.linkTabs.decision')"
          :tooltip-text="$t(userIsCorporate ? 'activeRFP.linkTabs.tooltips.decisionCoporate' : 'activeRFP.linkTabs.tooltips.decisionLawFirm')"
          is-link
          class="disabled"
          disabled
          :moveTop="40"
        />
        <router-link
          v-else
          :to="{ name: 'activeRfp', params: { activeTab: 'decision' } }"
          :class="{active: activeTab === 'Decision'}">{{ 'activeRFP.linkTabs.decision' | t }}</router-link>
      </div>
      <div v-if="shouldShowFeedback" ><router-link :to="{ name: 'activeRfp', params: { activeTab: 'feedback' } }" :class="{active: activeTab === 'Feedback'}">{{ 'activeRFP.linkTabs.feedback' | t }}</router-link></div>

      <div key="qa" class="filters qa" style="width: max-content" v-if="activeTab === 'QAndA'">
        <v-btn class="qa-button" flat :class="{activeQa: qaFilter === 'unanswered'}" @click="qaFilter = 'unanswered'">{{ 'activeRFP.q&a.unanswered' | t }}</v-btn>
        <v-btn class="qa-button" flat :class="{activeQa: qaFilter === 'answered'}" @click="qaFilter = 'answered'">{{ 'activeRFP.q&a.answered' | t }}</v-btn>
        <v-btn class="qa-button" flat :class="{activeQa: qaFilter === 'all'}" @click="qaFilter = 'all'">{{ 'activeRFP.q&a.all' | t }}</v-btn>
      </div>
      <div key="proposal" class="filters proposal" v-if="showLawfirmComparisonButtons">
        <v-switch
          color="secondary"
          :input-value="currentRfp.shareLiveFeedback"
          @click.stop="showLiveFeddbackToggleDialog = true"
          >
          <template v-slot:label>
            <label @click.stop="showLiveFeddbackToggleDialog = true">Share live feedback</label>
          </template>
        </v-switch>
        <v-btn @click="isCardView = false" class="proposal-btn" flat><v-icon :class="{activeProposal: !isCardView}">view_agenda</v-icon></v-btn>
        <v-btn @click="isCardView = true" class="proposal-btn" flat><v-icon :class="{activeProposal: isCardView}">view_week</v-icon></v-btn>
      </div>
      <div key="abort" class="filters" v-if="activeTab === 'Summary' && canCancelRfp && userIsCorporate && userIsOrganizationAdmin">
        <v-btn @click="showAbortDialog = true" class="abort-button" flat>{{ $t('activeRFP.abort.abortRfp') }}</v-btn>
      </div>
    </Tabs>
    <v-alert
      :value="userDidNotEnterInitialBid || currentRfp.status === 'COMPLETED_ABORTED'"
      class="lintum-alert"
      icon="error" >
      <span>{{ userDidNotEnterInitialBid ? (proposalNotYetSubmitted ? "activeRFP.noProposalAlert" : "activeRFP.noInitialBiddingAlert") : "activeRFP.abortedRfpAlert" | t }}</span>
    </v-alert>

    <v-alert
      :value="currentRfp.status === 'COMPLETED_ABORTED_BY_CORPORATE'"
      class="lintum-alert"
      style="margin-top: 20px;"
      icon="info">
      <span style="font-weight: bold">{{ $t('activeRFP.abort.abortInfo', { timestamp: formatTimestamp(currentRfp.abortion, 'DD.MM.YYYY HH:mm') }) }}</span>
      <p v-if="currentRfp.abortionMessage && activeTab === 'Summary'" style="margin: 8px 0;">{{ $t(`activeRFP.abort.${userIsCorporate ? 'corporateMessage' : 'corporateMessageLawfirm'}`) }}</p>
      <p v-if="currentRfp.abortionMessage && activeTab === 'Summary'" style="white-space: pre;">{{ currentRfp.abortionMessage }}</p>
    </v-alert>
    <component
      @changeCardView="isCardView = false"
      :is="activeTab"
      style="border-top: none;"
      :userDidNotEnterInitialBid="userDidNotEnterInitialBid"
      :rfpStatus="false"
      :filter="qaFilter"
      :proposalView="isCardView"
      :date="date"
      @openSummary="activeTab = 'Summary'"/>

    <ConfirmDialog
      v-if="showLiveFeddbackToggleDialog"
      :title="currentRfp.shareLiveFeedback ? 'Stop Live Feedback' : 'Share Live Feedback'"
      :text="currentRfp.shareLiveFeedback ?  'Are you sure you do not longer want to share live feedback?' : 'Are you sure you want to share live feedback?'"
      cancel-button-text-or-key="Cancel"
      :accept-button-text-or-key="currentRfp.shareLiveFeedback ? 'Stop Live Sharing' : 'Share Live Feedback'"
      @close="showLiveFeddbackToggleDialog = false"
      @input="showLiveFeddbackToggleDialog = false"
      @submit="toggleLiveFeedbackLocal"
    />
  </div>
</template>

<script>
import Summary from '../newRfpCorporate/summary/Summary'
import EAuction from '../eAuction/EAuction'
import LawFirmTable from '../newRfpCorporate/lawFirmSelection/LawFirmTable'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Tabs from '../generalComponents/Tabs'
import moment from 'moment'
import QAndA from './q&a/Q&A'
import Proposal from './proposal/Proposal'
import Team from './team/Team'
import Decision from './decision/Decision'
import Feedback from './feedback/Feedback'
import TooltipHolder from '../generalComponents/TooltipHolder'
import ConfirmDialog from '../generalComponents/ConfirmDialog'
import { formattedDurationUntil } from '@/util/dateTimeUtil'

export default {
  name: 'ActiveRfp',
  components: { ConfirmDialog, Decision, Team, Proposal, QAndA, Tabs, Summary, EAuction, LawFirmTable, Feedback, TooltipHolder },
  props: {
    rfpId: {
      type: String,
      required: true
    },
    activeTab: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      lastBidLoaded: false,
      qaFilter: 'all',
      interval: null,
      date: '',
      isCardView: false,
      showPageLeaveDialog: false,
      next: null,
      showAbortDialog: false,
      abortionMessage: '',
      verificationName: '',
      showLiveFeddbackToggleDialog: false
    }
  },
  watch: {
    async $route (to, from) {
      if (to.params.id !== from.params.id || to.query.aborted) {
        clearInterval(this.interval)
        await this.initialization()
      }
    }
  },
  async created () {
    await this.initialization()

    // this.addCallback({
    //   callback: () => {
    //     if (this.userIsLawFirm) {
    //       this.loadUsersLastBid()
    //         .finally(() => { this.lastBidLoaded = true })
    //     }
    //   },
    //   type: 'afterUserDataLoaded'
    // })
  },
  destroyed () {
    this.setUsersLastBid(null)
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters([
      'userIsCorporate',
      'userIsLawFirm',
      'isEAuction',
      'isAdvancedRfp',
      'isSimpleRfp',
      'formatDateTime',
      'rfpLabelsStatus',
      'nextRfpDeadline',
      'currentRfpLawFirmRelation',
      'auctionProgress',
      'auctionIsClosed',
      'RfpProgress',
      'isQandA',
      'decisionIsMade',
      'proposalIsClosed',
      'userIsOrganizationAdmin',
      'progressPreAbortion',
      'formatTimestamp',
      'relevantQualityScoreCriteriaSelected'
    ]),
    ...mapState({
      currentRfp: state => state.currentRfp,
      usersLastBid: state => state.auction.usersLastBid,
      proposalHasUnsavedChanges: state => state.proposalHasUnsavedChanges
    }),

    rfpAbortedAndNoBidsPlaced () {
      if (this.userIsCorporate && this.currentRfp.status === 'COMPLETED_ABORTED_BY_CORPORATE') {
        return this.currentRfp.minBid === null
      }
      return false
    },

    rfpIsAbortedAndLawFirmCouldNotSeeBiddingPanel () {
      if (this.userIsLawFirm) {
        if (this.currentRfp.status === 'COMPLETED_ABORTED_BY_CORPORATE' && this.currentRfpLawFirmRelation.status === 'COMPLETED_ABORTED_BY_CORPORATE') {
          return !this.rfpIsAbortedAndLawFirmCouldSeeBiddingPanel
        }
      }

      return false
    },

    rfpIsAbortedAndLawFirmCouldSeeBiddingPanel () {
      if (this.userIsLawFirm) {
        if (this.currentRfp.status === 'COMPLETED_ABORTED_BY_CORPORATE' && this.currentRfpLawFirmRelation.status === 'COMPLETED_ABORTED_BY_CORPORATE') {
          if (['COMPLETED_OUTBIDDEN', 'COMPLETED_WON', 'ACTIVE'].includes(this.currentRfpLawFirmRelation.statusPreAbortion)) {
            if (['ACTIVE_FIRST_BIDS_CLOSED', 'ACTIVE_SECOND_BIDS_OR_AUCTION', 'ACTIVE_DECISION_PENDING', 'COMPLETED_MATTER_IN_PROGRESS', 'COMPLETED_FEEDBACK_REQUIRED', 'COMPLETED_MATTER_CLOSED'].includes(this.currentRfp.statusPreAbortion)) {
              return true
            }
          }
        }
      }

      return false
    },

    canCancelRfp () {
      return this.currentRfp.status !== 'COMPLETED_FEEDBACK_REQUIRED' &&
        this.currentRfp.status !== 'COMPLETED_ABORTED' &&
        this.currentRfp.status !== 'COMPLETED_MATTER_CLOSED' &&
        this.currentRfp.status !== 'COMPLETED_MATTER_IN_PROGRESS' &&
        this.currentRfp.status !== 'COMPLETED_ABORTED_BY_CORPORATE'
    },

    decisionPending () {
      if (this.auctionProgress(this.currentRfp) > this.RfpProgress.ACTIVE_SECOND_BIDS_OR_AUCTION && (this.currentRfp.status !== 'COMPLETED_ABORTED_BY_CORPORATE' || this.progressPreAbortion(this.currentRfp) >= this.RfpProgress.ACTIVE_DECISION_PENDING)) {
        return this.canTakeADecision()
      } else {
        return false
      }
    },

    proposalNotYetSubmitted () {
      return this.userIsLawFirm && this.currentRfp.terms.qualityScoreCriteria.length && !this.currentRfpLawFirmRelation.proposalSubmitted
    },

    userDidNotEnterInitialBid () {
      return this.userIsLawFirm && this.currentRfp.lawFirmRelations[0].status === 'COMPLETED_MISSED_FIRST_BID_DEADLINE'
    },

    nextStageLabel () {
      const next = this.rfpLabelsStatus(this.currentRfp).next
      if (this._i18n.i18next.exists('general.rfp.status.nextStage.' + next)) {
        return this.$t('general.rfp.status.nextStage.' + next)
      } else if (this._i18n.i18next.exists('general.rfp.status.' + next)) {
        return this.$t('general.rfp.status.' + next)
      } else {
        return this.$t(next)
      }
    },

    pendingProposals () {
      return this.currentRfp.lawFirmRelations.filter((lawfirmRelation) => {
        return lawfirmRelation.proposalSubmitted === false && lawfirmRelation.status !== 'COMPLETED_CONFLICTED' && lawfirmRelation.status !== 'COMPLETED_NO_CAPACITY'
      }).length
    },

    matterWon () {
      return this.userIsLawFirm && this.currentRfp.lawFirmRelations[0].status === 'COMPLETED_WON'
    },

    shouldShowFeedback () {
      return (this.userIsCorporate && this.auctionProgress(this.currentRfp) > this.RfpProgress.COMPLETED_MATTER_IN_PROGRESS && (this.currentRfp.status !== 'COMPLETED_ABORTED_BY_CORPORATE' || this.progressPreAbortion(this.currentRfp) > this.RfpProgress.COMPLETED_MATTER_IN_PROGRESS)) || (this.userIsLawFirm && this.auctionProgress(this.currentRfp) > this.RfpProgress.COMPLETED_FEEDBACK_REQUIRED && this.currentRfp.lawFirmRelations[0].discloseFeedback)
    },

    showLawfirmComparisonButtons () {
      return (this.activeTab === 'Proposal' && this.userIsCorporate && this.currentRfp.terms.qualityScoreCriteria.length)
    }
  },
  methods: {
    ...mapActions([
      'loadRfp',
      'subscribeToEAuction',
      'unsubscribeAuction',
      'loadUsersLastBid',
      'subscribeToRfpStatusChanges',
      'unsubscribeStatusChanges',
      'abortRfp',
      'toggleLiveFeedback'
    ]),
    ...mapMutations(['hideNotification', 'setCurrentRfp', 'setUsersLastBid', 'setDraftProposal', 'setProposalHasUnsavedChanges']),

    async toggleLiveFeedbackLocal () {
      await this.toggleLiveFeedback()
      this.showLiveFeddbackToggleDialog = false
    },

    canTakeADecision () {
      return ![...this.currentRfp.lawFirmRelations].every(lawfirmRelation => lawfirmRelation.status === 'COMPLETED_MISSED_FIRST_BID_DEADLINE')
    },

    abortCurrentRfp () {
      this.abortRfp({
        rfpId: this.currentRfp.id,
        abortionMessage: this.abortionMessage
      })
    },

    handleBackClick () {
      if ((this.auctionProgress(this.currentRfp) >= this.RfpProgress.ACTIVE_QA && this.auctionProgress(this.currentRfp) < this.RfpProgress.COMPLETED_MATTER_IN_PROGRESS) || this.auctionProgress(this.currentRfp) === 10 || this.currentRfp.status === 'COMPLETED_ABORTED_BY_CORPORATE') {
        this.$router.push({ name: 'dashboardRfps' })
      } else {
        this.$router.push({ name: 'dashboardMatters' })
      }
    },

    async initialization () {
      if (!this.activeTab) {
        this.$router.push({ name: 'activeRfp', params: { activeTab: 'summary' } })
      }
      await this.loadRfp({ rfpId: this.rfpId, includeQA: true })

      if (this.$route.name !== 'newRfp') {
        this.subscribeToEAuction()
        this.subscribeToRfpStatusChanges()
      }

      if (this.userIsLawFirm) {
        this.loadUsersLastBid({ currentId: this.rfpId })
          .finally(() => { this.lastBidLoaded = true })
      }

      if (this.$route.name !== 'newRfp' && this.currentRfp.status !== 'COMPLETED_ABORTED' && this.currentRfp.status !== 'COMPLETED_ABORTED_BY_CORPORATE' && !this.auctionIsClosed(this.currentRfp)) {
        this.date = formattedDurationUntil(moment.utc(this.nextRfpDeadline(this.currentRfp)))
        this.interval = setInterval(() => {
          this.date = formattedDurationUntil(moment.utc(this.nextRfpDeadline(this.currentRfp)))
        }, 1000)
      }
    },

    cleanupAndLeave () {
      this.hideNotification()

      this.unsubscribeAuction()
      this.unsubscribeStatusChanges()

      this.setCurrentRfp(null)
      this.setDraftProposal({
        updateMode: false,
        credentials: null,
        newCredential: null,
        showNewCredentialInputForm: false,
        team: null,
        liability: null,
        editLiability: false,
        editFirstBid: false
      })
      this.setProposalHasUnsavedChanges(false)
      window.onbeforeunload = null
      this.next()
    }
  },
  beforeRouteLeave (to, from, next) {
    this.next = next
    if (this.proposalHasUnsavedChanges) {
      this.showPageLeaveDialog = true
      return
    }

    this.cleanupAndLeave()
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"
  .light
    color $icon-gray

  .full-title
    display grid
    grid-template-columns: 1fr auto

  .rfpProgress
    display grid
    grid-template-columns 1fr repeat(3, auto)
    margin-top 5px
    margin-bottom 15px
    margin-left 15px
    div
      margin-left 40px
      display grid
      grid-template-rows 1fr 1fr

  >>> .v-btn {
    font-weight bold
  }

  h1
    display inline-block

    *,
    *:before
      font-size 28px
      color $dark-gray !important
      padding-left 0

  >>> .v-chip
    background-color $icon-gray
    height 25px
    .v-chip__content
      color #ffffff !important
      section
        color #ffffff !important

  .info-icon
    font-size 20px
    margin-left 5px

  >>> .tabs-panel
    grid-template-columns repeat(7, auto) 1fr !important
    text-align center

  .abort-button {
    color #fff
    background $maroon100
    text-transform none
    border-radius 5px
    margin-top 15px
  }

  .filters
    grid-column-start -1

    &.proposal
      align-self center
      display grid
      grid-template-columns max-content max-content max-content
      align-items center

    &.qa
      padding-top 15px
      margin 0 0 0 auto

    .proposal-btn
      padding 0
      min-width 35px

      .activeProposal
        color $dark-gray !important

    .qa-button
      text-transform none
      border 1px solid $maroon100
      margin 0
      color $maroon100

      &.activeQa
        color #fff
        background $maroon100

      &:not(:first-child):not(:last-child)
        border-left none
        border-right none
        border-radius 0

      &:first-child
        border-top-left-radius 5px
        border-bottom-left-radius 5px
        border-top-right-radius 0px
        border-bottom-right-radius 0px

      &:last-child
        border-top-right-radius 5px
        border-bottom-right-radius 5px
        border-top-left-radius 0px
        border-bottom-left-radius 0px

  .disable
    pointer-events none

  a
    text-decoration none
  .active
    color $dark-gray !important
    text-decoration underline

  .pending-proposal
    display: inline-block
    font-size 18px
    color #808080
    &.active
      color $dark-gray !important

  >>> .v-chip.warning-chip .v-chip__content {
    background: #a72c00;
  }
  >>> .v-chip.warning-chip i {
    color #fff !important;
  }
</style>
