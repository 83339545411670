<template>
  <div v-if="isCoiCheckHeader">
    <v-toolbar
      app
      class="elevation-1 new-rfp-header coi-check-header">
      <v-toolbar-title class="headline header-img">
        <img src="../../assets/img/logo.png" width="100" style="display: block; margin-top: 20px" @click="cancel"/>
      </v-toolbar-title>
      <div style="padding-top: 10px;">
        <!-- <span class="title">{{ currentRfp ? currentRfp.name : '' }}</span> -->
        <TooltipHolder
          class="title"
          style="display: inline-flex !important"
          :spanId="currentRfp ? currentRfp.name : ''"
          :text="currentRfp ? currentRfp.name : ''"
          :tooltipText="currentRfp ? currentRfp.name : ''"
          :tableDataWidth="500"
          :size="70"
          :width="400"
          :moveTop="-1"
          rfpTitle
          bottom />
        <v-chip style="height: 20px;">{{ currentRfp ? $t(`dropDownOptions.MandateType.${currentRfp.mandateType}`) : '' }}</v-chip><br>
        <!-- <span class="headline sub-title" style="display: inline-block; margin-top: -10px;">{{ currentRfp ? currentRfp.clientName : '' }}</span> -->
        <TooltipHolder
          class="sub-title headline coi-check-header"
          style="font-size: 14px !important"
          :spanId="currentRfp ? currentRfp.id : ''"
          :text="currentRfp ? currentRfp.clientName : ''"
          :tooltipText="currentRfp.clientName"
          :tableDataWidth="500"
          :size="70"
          :width="400"
          :moveTop="-1"
          rfpTitle
          bottom />
      </div>
      <template v-if="currentRfpLawFirmRelation.status !== 'COMPLETED_NO_CAPACITY'">
        <v-btn
          flat
          class="cancel"
          @click="approveCoi = false; showCoiConfirmationDialog = true" >
          {{ 'buttons.reject' | t }}
        </v-btn>
        <v-btn
          flat
          @click="approveCoi = true; showCoiConfirmationDialog = true"
          class="accept" >
          {{ 'coi2LawFirm.participate' | t }}
        </v-btn>
      </template>
      <v-btn
        v-else
        flat
        style="grid-column: 4"
        @click="$router.push({ name: 'index' })">
        {{ 'buttons.close' | t }}
      </v-btn>
    </v-toolbar>

    <COIIConfirmDialog
      v-if="showCoiConfirmationDialog"
      @close="showCoiConfirmationDialog = false"
      @submit="value =>{ this.coi2RejectReason = value ; waitButtonToEnd(confirm) }"
      :title="approveCoi ? $t('coi2LawFirm.dialog.confirmTitle') : $t('coi2LawFirm.dialog.rejectTitle')"
      :COII="{status: true, approve: approveCoi}"
      :rfp="currentRfp"
      :showCloseBtn="true"
      :buttonDisable="isButtonExecuting"
      :acceptButtonTextOrKey=" approveCoi ? $t('coi2LawFirm.dialog.approve') : $t('coi2LawFirm.dialog.reject')">
      <template v-slot:custom-input>
        <p v-if="approveCoi" v-html="$t('coi2LawFirm.dialog.approveText', { rfpName: currentRfp.name, clientName: currentRfp.clientName})"></p>
        <p v-else v-html="$t('coi2LawFirm.dialog.declineText', { rfpName: currentRfp.name, clientName: currentRfp.clientName})"></p>
      </template>
    </COIIConfirmDialog>
  </div>

  <v-toolbar
    app
    class="elevation-1 new-rfp-header"
    v-else-if="isNewRfpHeader">
    <v-toolbar-title class="headline header-img">
      <img src="../../assets/img/logo.png" width="100" style="display: block; margin-top: 20px" @click="cancel"/>
    </v-toolbar-title>
    <div class="middle-headline">
      <b><a @click="moveBack" :to="{name: 'dashboardRfps'}" class="move-back"><v-icon>ic-chevron-left</v-icon> {{ previousStep.label | t}}</a><br></b>
      <TooltipHolder
        class="headline newrfp"
        :spanId="'currentRfp'+ currentRfp ? currentRfp : ''"
        :text="currentRfp ? currentRfp.name : ''"
        :tooltipText="currentRfp ? currentRfp.name : ''"
        :tableDataWidth="850"
        :size="70"
        :width="400"
        :moveTop="-1"
        bold rfpTitle
        bottom />
      <!-- <span class="headline newrfp">
        {{ currentRfp ? currentRfp.name : '' }}
      </span> -->
    </div>
    <v-btn
      flat
      @click="saveAndExit" >
      {{ 'buttons.saveExit' | t }}
    </v-btn>
  </v-toolbar>

  <v-toolbar
    app
    style="z-index: 11"
    class="elevation-1"
    v-else>
    <v-toolbar-title class="headline header-img">
      <img src="../../assets/img/logo.png" width="100" style="display: block;" @click="cancel"/>
    </v-toolbar-title>

    <v-spacer />

    <v-btn class="toolbar-btn" v-if="userIsAdmin" flat fab @click="$router.push({ name: 'scopeGeneratorAdminView' })">
      <v-icon>settings</v-icon>
    </v-btn>

    <v-btn
      flat
      fab
      class="toolbar-btn"
      @click="notificationVisible = true;" >
      <v-badge>
        <template v-slot:badge v-if="numberOfUnreadNotifications">
          <span>{{ numberOfUnreadNotifications }}</span>
        </template>
        <v-icon>ic-bell</v-icon>
      </v-badge>
    </v-btn>

    <div class="notification-holder">
      <Notifications v-model="notificationVisible" @close="notificationVisible = false" />
    </div>

    <v-btn class="toolbar-btn help" icon flat fab @click="showHelpDialog = true">
      <v-icon>help_outlined</v-icon>
    </v-btn>

    <v-btn
      flat
      fab
      class="toolbar-btn"
      @click="userIsAdmin ? $router.push({name: 'userManagement'}) : $router.push({name: 'profile'})" >
      <v-icon v-if="userIsAdmin">account_circle</v-icon>
      <v-icon v-else>ic-user</v-icon>
    </v-btn>

    <div style="margin-left: 10px">
      <span>{{ userName }}</span><br>
      <a @click="checkLogout">{{ 'buttons.logout' | t }}</a><span> ({{ 'buttons.autoLogoutIn' | t }} {{ formattedSessionDuration }})</span>
    </div>

    <ConfirmDialog
      v-if="showPageLeaveDialog"
      :text="$t('activeRFP.proposal.unsavedChangesDialog.text')"
      :title="'activeRFP.proposal.unsavedChangesDialog.title'  | t"
      @close="showPageLeaveDialog = false"
      @submit="logout(); showPageLeaveDialog = false"
      acceptButtonTextOrKey="activeRFP.proposal.unsavedChangesDialog.submit"
      cancelButtonTextOrKey="buttons.cancel"/>
    <ConfirmDialog
      v-if="showSessionExpiryWarningDialog"
      :text="isSessionExpired ? $t('login.sessionExpiredDialog.text') : $t('login.sessionExpiryWarningDialog.text')"
      :title="isSessionExpired ? $t('login.sessionExpiredDialog.title') : $t('login.sessionExpiryWarningDialog.title')"
      @submit="extendSession"
      :acceptButtonTextOrKey="isSessionExpired ? 'login.sessionExpiredDialog.submit' : 'login.sessionExpiryWarningDialog.submit'"
      :show-close-btn="false"
      :hide-cancel="true"
    />

    <ConfirmDialog
      v-if="showHelpDialog"
      @close="showHelpDialog = false"
      @submit="showHelpDialog = false"
      :title="$t('help.title')"
      :text="$t('help.text')"
      accept-button-text-or-key="help.button"
      hide-cancel
    >
    </ConfirmDialog>
  </v-toolbar>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import COIIConfirmDialog from '../generalComponents/ConfirmDialog'
import Notifications from './Notifications'
import TooltipHolder from './TooltipHolder'
import ConfirmDialog from './ConfirmDialog'
import moment from 'moment'
import { formattedDurationUntil } from '@/util/dateTimeUtil'

export default {
  name: 'Header',
  components: { ConfirmDialog, Notifications, COIIConfirmDialog, TooltipHolder },
  data () {
    return {
      showCoiConfirmationDialog: false,
      approveCoi: false,
      coi2RejectReason: '',
      notificationVisible: false,
      showPageLeaveDialog: false,
      showSessionExpiryWarningDialog: false,
      formattedSessionDuration: '10m',
      interval: null,
      showHelpDialog: false
    }
  },
  created () {
    this.formattedSessionDuration = formattedDurationUntil(this.userData.sessionExpiry, { hideSecondsUntil3m: true })
    this.interval = setInterval(() => {
      this.formattedSessionDuration = formattedDurationUntil(this.userData.sessionExpiry, { hideSecondsUntil3m: true })
      if (this.formattedSessionDuration.startsWith('0')) { // show warning under 1 minute
        this.showSessionExpiryWarningDialog = true
      }
    }, 1000)
  },
  destroyed () {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters(['requiredFields', 'userIsAdmin', 'loadRfpStatusTab', 'userIsOrganizationAdmin', 'numberOfUnreadNotifications', 'currentRfpLawFirmRelation', 'isSessionExpired']),
    ...mapState({
      currentRfp: state => state.currentRfp,
      userName: state => state.currentUser.fullName,
      userData: state => state.userData,
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields,
      notifications: state => state.notifications.notifications,
      proposalHasUnsavedChanges: state => state.proposalHasUnsavedChanges
    }),
    isNewRfpHeader () {
      return this.$route.meta.newRfpHeader
    },

    isCoiCheckHeader () {
      return this.$route.meta.coiCheckHeader
    },

    previousStep () {
      return this.$route.meta.newRfpPreviousPage || { title: '', route: null }
    },

    isDashboardHeader () {
      return this.$route.meta.dashboardHeader
    },

    isRfpSelected () {
      return this.$route.meta.type === 'rfps'
    }
  },
  methods: {
    ...mapActions(['saveCurrentRfp', 'validate', 'logout', 'acceptDeclineCoi2']),
    ...mapMutations(['setAlert', 'updateCurrentRfpTerms', 'removeInvalidField', 'updateCurrentRfp', 'resetMandatoryViolations']),

    extendSession () {
      if (this.isSessionExpired) {
        window.open('/login?sessionExpired=1')
      }
      this.showSessionExpiryWarningDialog = false
    },

    checkLogout () {
      if (this.$route.name === 'activeRfp' && this.proposalHasUnsavedChanges) {
        this.showPageLeaveDialog = true
        return
      }
      this.logout()
    },

    async confirm () {
      await this.acceptDeclineCoi2({
        rfpId: this.currentRfp.id,
        decision: this.approveCoi,
        rejectReason: this.coi2RejectReason === '' ? null : this.coi2RejectReason
      })
      this.setAlert({
        type: this.approveCoi ? 'coiIIaccepted' : 'coiIIdeclined',
        visible: true,
        rfpName: this.currentRfp.name,
        to: {
          name: 'activeRfp',
          params: {
            id: this.currentRfp.id,
            activeTab: this.loadRfpStatusTab(this.currentRfp)
          }
        }
      })

      this.showCoiConfirmationDialog = false

      this.$router.push({
        name: 'dashboardRfps'
      })
    },

    async moveBack () {
      const previousRouteName = this.previousStep.route
      this.preValidationAndReset()
      await this.saveCurrentRfp()
      if (previousRouteName) {
        this.$router.push({
          name: previousRouteName
        })
      }
    },

    async saveAndExit () {
      this.preValidationAndReset()
      await this.saveCurrentRfp()
      this.$router.push({
        name: 'dashboardRfps'
      })
    },

    preValidationAndReset () {
      let rfpDatesArray = ['firstBidDeadline', 'auctionEnd', 'auctionStart', 'qaEnd']
      this.resetMandatoryViolations()
      rfpDatesArray.forEach(dateField => {
        if (this.invalidFields.includes(dateField)) {
          this.removeInvalidField(dateField)
          // this.updateCurrentRfp({ field: dateField, value: this.currentRfp[dateField] })
        }
      })
      if (this.invalidFields.includes('terms/travelExpenseCap')) {
        this.updateCurrentRfpTerms({ field: 'travelExpenseType', value: null })
        this.updateCurrentRfpTerms({ field: 'travelExpenseCap', value: null })
        this.removeInvalidField('terms/travelExpenseCap')
      }
      if (this.invalidFields.includes('terms/liabilityAmount') || this.currentRfp.terms.liabilityType === null) {
        this.updateCurrentRfpTerms({ field: 'liabilityAmount', value: null })
        this.updateCurrentRfpTerms({ field: 'liabilityType', value: null })
        this.removeInvalidField('terms/liabilityAmount')
      }
      if (this.invalidFields.includes('terms/maximumFee')) {
        this.updateCurrentRfpTerms({ field: 'generalFees', value: null })
        this.updateCurrentRfpTerms({ field: 'maximumFee', value: null })
        this.removeInvalidField('terms/maximumFee')
      }
      if (this.invalidFields.includes('terms/alertThreshold')) {
        this.updateCurrentRfpTerms({ field: 'alertThreshold', value: null })
        this.updateCurrentRfpTerms({ field: 'hasAlertThreshold', value: null })
        this.removeInvalidField('terms/alertThreshold')
      }
    },

    async cancel () {
      const newRouteNamesArr = [
        'newRfp',
        'generalInfoCorporate',
        'scopeDetailsCorporate',
        'lawFirmSelectionCoprporate',
        'proposal',
        'termsAndConditionsCorporate',
        'billingTypeCorporate',
        'summaryCorporate'
      ]

      if (newRouteNamesArr.includes(this.$route.name)) {
        await this.saveAndExit()
      }

      this.$router.push({
        name: 'dashboardRfps'
      })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  >>> .v-toolbar__content
    padding-left 0
    padding-right 0

  .toolbar-btn
    width 40px
    height 40px

  &.help >>> i {
    width: 20px;
    height: 20px;
    display: block;
    margin-left: -3px;
  }

  .activeHeaderLink
    color $dark-gray

  .new-rfp-header >>> .v-toolbar__content
    display grid
    grid-template-columns 150px 1fr 150px

    > *
      height 100%

    .v-btn
      border-left 1px solid lightgray
      margin 0

      &:last-child
        border-right 1px solid lightgray

    .move-back
      color $dark-gray
      font-size 14px !important
      text-decoration none

      .v-icon
      .v-icon:before
        font-size 16px
        color $dark-gray !important

    .headline
      color $icon-gray
      font-weight bold
      line-height 1em !important
      &.newrfp
        padding-left 4px
        height 30px

    .middle-headline
      margin-top 3px
      height 100% - 4px
      display grid
      grid-template-rows 0.3fr 0.5fr
      b
        text-align left

  .cancel
    color red

  .accept
    color rgb(102, 174, 4)

  .coi-check-header >>> .v-toolbar__content
    grid-template-columns 150px 1fr 150px 150px

    .v-chip
      margin-top 0
      margin-bottom 0
      margin-left 10px

    span.title
      font-size 20px !important

    span.sub-title
      font-size 14px !important

  .header-img:hover {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  >>> .v-icon
    font-size 22px

  >>> .v-badge__badge
    background-color $red !important

    span
      color #fff

  .notification-holder
    position relative
</style>
