import apollo from '../../apollo'
import gql from 'graphql-tag'
import { auctionBid } from '../graphqlTags/rfpTags'

export default {

  async placeBid ({ state, commit, getters }, jAuctionBidInput) {
    try {
      const response = await apollo(state).mutate({
        mutation: gql`
          mutation jPlaceAuctionBid($jAuctionBidInput: JAuctionBidInput!) {
            jPlaceAuctionBid(jAuctionBidInput: $jAuctionBidInput) {
              ${auctionBid}
            }
          }
        `,
        variables: {
          jAuctionBidInput: jAuctionBidInput
        }
      })
      console.log(response)
      const usersLastBid = response.data.jPlaceAuctionBid

      commit('setUsersLastBid', usersLastBid)
    } catch (error) {
      // @todo show an error message
    }
  },

  subscribeToEAuction ({ state, commit, getters }) {
    try {
      const idField = getters.userIsLawFirm ? 'law_firm_id: law_firm_hash' : 'law_firm_id'
      const observer = apollo(state).subscribe({
        // GraphQL document
        query: gql`subscription auction_computed_bid($rfpId: uuid!) {
          auction_computed_bid(where: {rfp_id: {_eq: $rfpId}}) {
            id
            relevant_display_value
            amount_blended_rate
            amount_rate_level1
            amount_rate_level2
            amount_rate_level3
            amount_rate_level4
            is_intial_bid
            time_of_placement
            ${idField}
          }
        }`,
        // Variables passed to the subscription
        variables: {
          rfpId: state.currentRfp.id
        }
      })

      const subscription = observer.subscribe({
        next (data) {
          console.log('SUB', data)
          commit('setBids', data.data.auction_computed_bid)
        },
        error (error) {
          // todo show error message
          console.error('SUB_ERROR', error)
          commit('setShowSubscriptionError', true)
        }
      })
      console.log('Subscription', subscription)
      console.log('Subscription state', subscription._state)

      commit('setAuctionSubscription', subscription)
    } catch (error) {
      console.log('Catched in Subscription', error)
    }
  },

  subscribeToRfpStatusChanges ({ state, commit, getters }) {
    try {
      let observer

      if (getters.userIsLawFirm) {
        observer = apollo(state).subscribe({
          // GraphQL document
          query: gql`subscription rfp($rfpId: uuid!) {
            rfp(where: {id: {_eq: $rfpId}}) {
              status
              status_pre_abortion
              auction_end
              active_law_firms
              abortion_message
              rfp_law_firm_relations {
                status
                status_pre_abortion
                law_firm_hash
                rank
              }
            }
          }`,
          // Variables passed to the subscription
          variables: {
            rfpId: state.currentRfp.id
          }
        })
      } else {
        observer = apollo(state).subscribe({
          // GraphQL document
          query: gql`subscription rfp($rfpId: uuid!) {
            rfp(where: {id: {_eq: $rfpId}}) {
              status
              status_pre_abortion
              auction_end
              active_law_firms
              abortion_message
            }
          }`,
          // Variables passed to the subscription
          variables: {
            rfpId: state.currentRfp.id
          }
        })
      }

      const subscription = observer.subscribe({
        next (data) {
          console.log('SUB', data)
          if (getters.userIsLawFirm) {
            commit('setLawFirmRelationStatusAndRank', {
              status: data.data.rfp[0].rfp_law_firm_relations[0].status,
              rank: data.data.rfp[0].rfp_law_firm_relations[0].rank,
              statusPreAbortion: data.data.rfp[0].rfp_law_firm_relations[0].status_pre_abortion
            })
          }
          commit('setCurrentRfpStatus', data.data.rfp[0].status)
          commit('setCurrentRfpStatusPreAbortion', data.data.rfp[0].status_pre_abortion)
          commit('setCurrentRfpAuctionEnd', data.data.rfp[0].auction_end)
          commit('setCurrentRfpActiveLawFirms', data.data.rfp[0].active_law_firms)
          commit('setCurrentRfpAbortionMessage', data.data.rfp[0].abortion_message)
        },
        error (error) {
          // todo show error message
          console.error('SUB_ERROR', error)
          commit('setShowSubscriptionError', true)
        }
      })
      console.log('Subscription', subscription)
      console.log('Subscription state', subscription._state)

      commit('setStatusSubscription', subscription)
    } catch (error) {
      console.log('Catched in Subscription', error)
    }
  },

  unsubscribeStatusChanges ({ state, commit }) {
    const { statusSubscription } = state.auction

    if (statusSubscription) {
      statusSubscription.unsubscribe()
      commit('setStatusSubscription', null)
    }
  },

  unsubscribeAuction ({ state, commit }) {
    const { subscription } = state.auction

    if (subscription) {
      subscription.unsubscribe()
      commit('setAuctionSubscription', null)
    }
  },

  async loadUsersLastBid ({ state, commit, getters }) {
    const response = await apollo(state).query({
      query: gql`
        query jCurrentAuctionBid($lawFirmId: ID!, $rfpId: ID!) {
          jCurrentAuctionBid(lawFirmId: $lawFirmId, rfpId: $rfpId) {
            ${auctionBid}
          }
        }
      `,
      variables: {
        lawFirmId: state.userData.lawFirmId,
        rfpId: state.currentRfp.id
      }
    })

    const usersLastBid = response.data.jCurrentAuctionBid
    if (!usersLastBid) {
      if (getters.userIsLawFirm) {
        if (state.currentRfp) {
          commit('setUsersLastBid', getters.currentRfpLawFirmRelation.firstBidToBePlaced || getters.currentRfpLawFirmRelation.firstBidToBePlacedDraft)
        }
      }
    } else {
      commit('setUsersLastBid', usersLastBid)
    }
  },

  async chooseRfpWinner ({ state, commit, getters }, { rfpId, lawFirmId }) {
    try {
      await apollo(state).mutate({
        mutation: gql`
          mutation chooseRfpWinner($rfpInput: RfpReferenceInput!, $lawFirmInput: LawFirmReferenceInput!) {
            chooseRfpWinner(rfpInput: $rfpInput, lawFirmInput: $lawFirmInput )
          }
        `,
        variables: {
          rfpInput: { id: rfpId },
          lawFirmInput: { id: lawFirmId }
        }
      })
      commit('setCurrentRfp', { ...state.currentRfp, winner: { id: lawFirmId } })
    } catch (error) {
      console.log(error)
    }
  },

  async triggerDynamicDemoBids ({ state, getters }) {
    if (!getters.showSampleDataButton) {
      return
    }
    try {
      await apollo(state).mutate({
        mutation: gql`
          mutation generateDynamicDemoBids($rfpId: ID!) {
            generateDynamicDemoBids(rfpId: $rfpId)
          }
        `,
        variables: {
          rfpId: state.currentRfp.id
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

}
