<template>
  <v-text-field
    solo
    flat
    :disabled="disabled"
    @blur="validateInput"
    @keyup.enter="validateInput"
    @input="handleInput"
    :value="UStoGER(value)"
    :class="{invalid: invalid}"
    class="bid-input"
    :prefix="prefix"
    :suffix="suffix"
    :rules="rules"
    :placeholder="placeholder" />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NumberField',
  props: {
    /** @type {String} value of field in american format (point ad decimal delimeter e.g. 5.12 and not 5,12) */
    value: {
      type: [Number, String], // either number (correct value) or string (invalid value)
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    prefix: {
      type: String,
      default: '',
      required: false
    },
    suffix: {
      type: String,
      default: '',
      required: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => ([])
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    invalid: {
      type: Boolean,
      default: false,
      required: false
    },
    min: {
      type: Number,
      required: false,
      default: null
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    liveUpdates: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters(['formatNumber'])
  },
  methods: {
    handleInput (value) {
      this.$emit('invalidInput', false)
      if (this.liveUpdates) {
        this.validateInput({ target: { value } })
      }
    },
    formatGerman (value) {
      value = String(value)

      return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    /* E.g. 1234.56 -> 1.234,56 */
    UStoGER (value) {
      if (value || value === 0) {
        return typeof value === 'number' ? this.formatNumber(value) : value
      } else {
        return null
      }
    },
    /* E.g. 1.234,56 -> 1234.56 */
    GERtoUS (value) {
      if (value || value === 0) {
        const euros = String(value).split(',').join('')
        return euros
      } else {
        return null
      }
    },
    /* Checks if the input is valid. If so, emits an input event with the input
    in american format. */
    validateInput (event) {
      this.$emit('disableSave')

      const inputValue = event.target.value // event.target.value === '' ? this.placeholder : event.target.value
      const USValue = inputValue !== null ? this.GERtoUS(inputValue) : ''

      if (isNaN(USValue)) {
        this.$emit('invalidInput', true)
      } else if (USValue !== '') {
        const usNumber = Number(USValue)
        if (this.min !== null && this.min !== undefined) {
          if (usNumber < this.min) {
            this.$emit('invalidInput', true)
            return
          }
        }

        if (this.max !== null && this.max !== undefined) {
          if (usNumber > this.max) {
            this.$emit('invalidInput', true)
            return
          }
        }

        this.$emit('invalidInput', false)
        this.$emit('alertMessage', '')
        this.$emit('input', usNumber)
      } else {
        this.$emit('invalidInput', false)
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  >>> .v-input__slot
    border 1px solid transparent
    border-radius 5px !important

  >>> .v-input--is-disabled .v-input__slot
    background $light-gray !important

  .bid-input.invalid >>> .v-input__slot {
    border 1px solid $red !important

    .v-text-field__slot input {
      color $red !important
    }
  }

  >>> .v-text-field__details
    display none
</style>
