<template>
  <v-chip class="file-chip">
    <a :href="file.url" @click.stop download>{{ file.fileName || file.name}}</a>
    <v-icon v-if="showIcon" class="show-icon" @click.stop="$emit('show')" :style="iconStyle">remove_red_eye</v-icon>
    <v-icon v-if="deleteIcon" class="delete-icon" @click.stop="$emit('delete')" :style="iconStyle">delete</v-icon>
  </v-chip>
</template>

<script>
export default {
  name: 'FileChip',
  props: {
    file: {
      type: [Object, File],
      required: true
    },

    // adds a delete icon
    deleteIcon: {
      type: Boolean,
      required: false,
      default: false
    },

    // adds a show icon
    showIcon: {
      type: Boolean,
      required: false,
      default: false
    },

    iconStyle: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .file-chip
    background-color $gold50 !important

    a
      color #000 !important

  .v-icon.show-icon,
  .v-icon.delete-icon
    font-size 15px !important
    color #000 !important
    margin-left 5px
</style>
