<template>
  <v-card>
    <h2>{{ `activeRFP.biddingProcessSection.biddingSummaryTitle` | t }} </h2>
    <v-divider class="divider"></v-divider>

    <section style="margin-bottom: 20px">
      <span class="label">&nbsp;{{ auctionNextActionLabel(currentRfp) | t }}</span>
      <div class="auction-date">
        <v-icon @click="triggerDynamicDemoBids" style="grid-column-start: 1; cursor: default">ic-calendar</v-icon>
        <template v-if="auctionProgress(currentRfp) > RfpProgress.ACTIVE_FIRST_BIDS_CLOSED && auctionProgress(currentRfp) < RfpProgress.ACTIVE_DECISION_PENDING">
          <h2 style="display: inline-block">&nbsp;{{ duration }}</h2>
          <br>  <span style="padding-left: 8px;" class="label">({{ formatDateTime(nextRfpDeadline(currentRfp)) }})</span>
        </template>
        <template v-else>
          <h2 v-if="!auctionIsClosed(currentRfp)" style="display: inline-block">&nbsp;{{ formatDateTime(nextRfpDeadline(currentRfp)) }}</h2>
          <h2 v-else style="display: inline-block">&nbsp;{{ isEAuction
            ? $t('activeRFP.biddingProcessSection.status.eAuctionClosed')
            : $t('activeRFP.biddingProcessSection.status.biddingClosed') }}</h2>
          <br>  <span v-if="!auctionIsClosed(currentRfp)" style="padding-left: 8px;" class="label">({{ duration }})</span>
        </template>
      </div>
    </section>

    <v-btn
      v-if="(!userIsCorporate && isFullDisclosureMode) || (userIsCorporate && currentRfp.status === 'ACTIVE_DECISION_PENDING')"
      @click="userIsCorporate ? $router.push({ name: 'activeRfp', params: { activeTab: 'decision' } }) : goToBidding()"
      class="main"
      flat
      block>
      {{ userIsCorporate ? 'activeRFP.chooseLawFirm.title' : `activeRFP.biddingProcessSection.goToBiddingBtn` | t }}
    </v-btn>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AuctionSummary',
  props: {
    displayBtn: {
      required: false,
      type: Boolean,
      default: false
    },
    duration: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters([
      'userIsCorporate',
      'isEAuction',
      'isAdvancedRfp',
      'auctionProgress',
      'isFullDisclosureMode',
      'nextRfpDeadline',
      'formatDateTime',
      'auctionNextActionLabel',
      'auctionIsClosed',
      'RfpProgress'
    ]),
    ...mapState({
      currentRfp: state => state.currentRfp
    })
  },
  methods: {
    ...mapActions(['triggerDynamicDemoBids']),
    lawFirmSelect () {
      // console.log(this.currentRfp.id)
      this.$router.push({
        name: 'chooseFirm',
        params: {
          id: this.currentRfp.id
        }
      })
    },
    goToBidding () {
      let biddingComponentPos = document.getElementById('biddingEstimate').getBoundingClientRect().top
      const scrollingPix = biddingComponentPos - 70
      window.scrollBy({ top: scrollingPix, left: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .header
    display grid
    grid-template-columns 80% auto
    .toggleBtn
      justify-self right
      margin 0px
      height 18px
      width 18px

  .v-card
    border-radius: 10px;
    background: #fff;
    padding: 30px;

  .grid
    display grid
    grid-template-columns 1fr 1px 1fr

  .status
    padding-left: 20px;
    display: grid
    grid-template-columns 120px 1fr

  .label
    font-weight bold
    color $icon-gray

  .status-label
    font-weight bold
    padding-left 50px
    position relative

    &:before
      content ''
      position absolute
      left 35px
      width 12px
      height 12px
      border-radius 6px
      top 3px

    &.pending:before
      background-color rgb(247, 228, 22)

    &.active:before
      background-color rgb(102, 174, 1)

    &.closed:before
      background-color rgb(199, 54, 57)

  .auction-date
    display grid
    grid-template-columns auto 1fr

  .divider
    margin 10px 0
    border-color $light-gray
</style>
