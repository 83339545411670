<template>
  <div
    v-if="showSectionSummary"
    style="margin-bottom: 40px"
    :class="{'summary-panel': readonly, readonly}">
    <div style="position: relative;">
      <p v-if="!readonly" class="not-applicable-checkbox">
        <v-checkbox
          :disabled="disabled"
          :input-value="isApplicable"
          @change="updateNotApplicableLocal"
          hide-details
          style="display: inline"></v-checkbox></p>
      <h1
        style="display: inline-block;"
        :style="readonly ? 'color: #ababb5; font-size: 18px; margin-top: 9px' : ''">{{ $t(`sections.${section.key}`) }}</h1>
    </div>
    <Panel
      :class="{ 'readonly-panel': readonly }"
      v-for="panel in filteredPanels"
      :key="panel.key"
      :ref="panel.key"
      :panel="panel"
      :subject="subject"
      :section-key="section.key"
      :root-object="rootObject"
      :update-mutation="updateMutation"
      :update-list-item-mutation="updateListItemMutation"
      :add-list-item-mutation="addListItemMutation"
      :readonly="readonly"
      :disabled="disabled" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Panel from './Panel'

export default {
  name: 'Section',
  components: { Panel },
  props: {
    section: {
      type: Object,
      required: true
    },
    rootObject: {
      type: Object,
      required: true
    },
    subject: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    updateMutation: {
      type: String,
      required: true
    },
    updateListItemMutation: {
      type: String,
      required: true
    },
    addListItemMutation: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['getValue', 'isEnabled', 'sectionIsApplicable', 'sectionShouldBeShownOnSummary', 'panelIsApplicable']),

    isApplicable () {
      return this.sectionIsApplicable(this.section.key, this.rootObject ? this.rootObject[this.subject.key] : null)
    },

    filteredPanels () {
      return this.section.panels.filter(this.showPanel)
    },

    showSectionSummary () {
      if (this.readonly) {
        return this.sectionShouldBeShownOnSummary(this.section, this.subject, this.rootObject)
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapMutations(['updateMandatoryViolations']),
    ...mapActions(['updateNotApplicable']),

    updateNotApplicableLocal (notApplicable, sectionKey) {
      this.updateNotApplicable({
        notApplicable: !notApplicable,
        rootObject: this.rootObject,
        subjectKey: this.subject.key,
        sectionKey: this.section.key,
        updateMutation: this.updateMutation
      })

      this.updateMandatoryViolations(this.subject.key)
    },

    showPanel (panel) {
      return (this.readonly ? this.panelIsApplicable(panel.key, this.rootObject ? this.rootObject[this.subject.key] : null) : true) && this.isEnabled(panel, null, this.subject.key, this.rootObject)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .readonly
    display grid
    grid-template-columns 200px 1fr

  .readonly-panel
    grid-column-start 2

    &:not(:nth-child(2))
      border-top 1px solid $light-gray

  .not-applicable-checkbox,
  >>> .not-applicable-checkbox
    display inline-block
    top 11px
    margin-bottom 0px

    .v-icon
      font-size 20px
      color $dark-gray !important

    .v-input--selection-controls__input
      margin-right 0
</style>
