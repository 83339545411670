<template>
  <div v-if="show">
    <h1>
      <v-btn style="text-transform: capitalize;" flat @click="leave"><v-icon>ic-chevron-left</v-icon>{{ 'scopeGeneratorAdminView.layout.previewTitle' | t }} {{ currentSubject.translatedLabel }}</v-btn>
    </h1>

    <ScopeGenerator />
    <v-footer class="elevation-15" fixed>
      <div class="footer-content">
        <div class="btn-wrapper" style="grid-column-start: 2;">
          <v-btn flat @click="leave">
            <span>{{ 'buttons.back' | t }} </span>
          </v-btn>
        </div>
        <div class="btn-wrapper">
          <v-btn flat @click="demoSave">
            <span>{{ 'scopeGeneratorAdminView.layout.previewSaving' | t }} </span>
          </v-btn>
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import ScopeGenerator from '../newRfpCorporate/scopeDefinition/ScopeGenerator'

export default {
  name: 'ScopeGeneratorLayoutPreview',
  components: { ScopeGenerator },
  props: {
    subjectKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    ...mapState({
      currentSubject: state => state.admin.scopeGenerator.currentSubject,
      currentRfp: state => state.currentRfp
    })
  },
  async created () {
    await this.loadSubjectWithLayout({ subjectKey: this.subjectKey, adminView: false })

    this.setCurrentRfp({
      scope: {},
      subjects: [
        {
          key: 'DRAFT',
          translatedLabel: 'Draft'
        },
        {
          key: 'ACTIVE',
          translatedLabel: 'Active'
        }
      ],
      scopeGeneratorLayoutsBySubject: {
        DRAFT: this.currentSubject.scopeGeneratorDraftLayout,
        ACTIVE: this.currentSubject.scopeGeneratorLayout
      }
    })

    this.show = true
  },
  beforeDestroy () {
    this.resetMandatoryViolations()
  },
  methods: {
    ...mapActions(['loadSubjectWithLayout']),
    ...mapMutations(['setCurrentRfp', 'validateMandatoryFields', 'validateConstraints', 'resetMandatoryViolations']),

    leave () {
      // this.resetMandatoryViolations()
      this.$router.push({
        name: 'scopeGeneratorLayoutView'
      })
    },

    demoSave () {
      this.currentRfp.subjects.forEach(subject => {
        this.validateMandatoryFields(subject.key)
        this.validateConstraints(subject.key)
      })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  footer
    background #ffffff !important
    height 74px !important

  .footer-content
    width $max-width
    margin 0 auto
    background #ffffff
    display grid
    height 74px !important
    grid-template-columns 1fr 200px 200px

    .btn-wrapper:not(:first-child)
      border-left 1px solid lightgray

    .v-btn
      height 100%
      margin 0
      width 100%
      font-weight normal !important
      .v-icon,
      .v-icon:before
        color $dark-gray !important

  .v-btn
    height 100%
    margin 0
    border-radius 5px
  h1
    display inline-block

    *,
    *:before
      font-size 28px
      color $dark-gray !important
      padding-left 0
</style>
