<template>
  <div
    v-show="!scrolledToBottom"
    class="gently-flashing-arrow"
    :class="{'position-bottom': bottom}"
    ref="genltyArrow">
    <section>
      <p>Scroll Down</p>
      <v-icon>ic-chevron-right</v-icon>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ScrollDownIndicator',
  props: {
    bottom: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      scrolledToBottom: false,
      interval: null
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scroll)
    this.interval = setInterval(() => {
      this.toggleArrowMovement()
    }, 2000)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scroll)
    clearInterval(this.interval)
  },
  methods: {
    scroll () {
      const bottomOfWindow = Math.ceil(window.pageYOffset + window.innerHeight) >= document.documentElement.offsetHeight - 100

      if (bottomOfWindow) {
        this.scrolledToBottom = true
      } else {
        this.scrolledToBottom = false
      }
    },

    toggleArrowMovement () {
      this.$refs.genltyArrow.classList.toggle('bottom')
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .gently-flashing-arrow
    position fixed
    bottom 95px
    right calc(calc(100vw - 1390px) / 2)
    opacity 1
    &.bottom
      transition: bottom 1s linear, opacity 1.5s linear
      bottom 75px
      opacity 0

    &.position-bottom
      bottom 35px

    section
      text-align center
    p
      color $icon-gray
      font-size 12px
      margin 0
    .v-icon
      transform: rotate(90deg)
      font-size 24px
</style>
