<template>
  <span style="position: absolute">
    <BetterTooltip
      :tooltip-text="text"
      width="200px"
      :top="top"
      left="-84px">
      <v-icon>warning</v-icon>
    </BetterTooltip>
  </span>
</template>

<script>
import BetterTooltip from '@/views/generalComponents/BetterTooltip'
export default {
  name: 'ChangeIcon',
  props: {
    section: Boolean,
    category: Boolean
  },
  components: { BetterTooltip },
  computed: {
    top () {
      if (this.section || this.category || this.$i18n.i18next.language === 'de') {
        return '-50px'
      }
      return '-32px'
    },

    text () {
      let key = 'field'
      if (this.section) {
        key = 'section'
      }
      if (this.category) {
        key = 'category'
      }
      return this.$t(`activeRFP.update.${key}`)
    }
  }
}
</script>

<style scoped lang="stylus">
:deep(.v-icon) {
  color: #a72c00 !important;
}
</style>
