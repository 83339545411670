import Vue from 'vue'

export default {

  setNotifications (state, newNotifications) {
    let actualNotifications = []
    const oldNotifications = state.notifications.notifications
    if (oldNotifications.length > 0) {
      const oldNotContainedNotifications = oldNotifications.filter(oldNotification => {
        return newNotifications.every(newNotification => newNotification.id !== oldNotification.id)
      })
      actualNotifications = [...oldNotContainedNotifications, ...newNotifications]
    } else {
      actualNotifications = [...newNotifications]
    }

    Vue.set(state.notifications, 'notifications', actualNotifications)
  },

  setNotificationsToRead (state) {
    state.notifications = state.notifications.map(notification => {
      return {
        ...notification,
        is_read: true
      }
    })
  },

  setNotificationsSubscription (state, subscription) {
    Vue.set(state.notifications, 'notificationSubscription', subscription)
  }
}
