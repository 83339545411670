// Validation functions
// They return true if the passed value is valid, and false if not
// Functions may return another function to be parametrized

export function isPresent (value) {
  if (Array.isArray(value)) {
    return !!value.length
  } else if (value !== null && value !== undefined && typeof value !== 'undefined' && value !== '') {
    return true
  } else {
    return false
  }
}

export function max (maxValue) {
  return (value) => {
    if (!isPresent(value)) {
      return true
    }

    return value <= maxValue
  }
}

export function min (minValue) {
  return (value) => {
    if (!isPresent(value)) {
      return true
    }

    return value >= minValue
  }
}

export function startsWith (start) {
  return (value) => {
    if (!isPresent(value)) {
      return false
    }

    return String(value).startsWith(start)
  }
}

export function exactLength (length) {
  return (value) => {
    if (!isPresent(value)) {
      return length === 0
    }

    return String(value).length === length
  }
}

export function maxLength (length) {
  return (value) => {
    if (!isPresent(value)) {
      return true
    }

    return String(value).length <= length
  }
}

export function int () {
  return (value) => {
    if (!isPresent(value)) {
      return true
    }
    console.log('IntCheck', value)
    return !isNaN(value) && !String(value).includes('.')
  }
}

export function maxCharacteristic (maxCount) {
  return (value) => {
    if (!isPresent(value)) {
      return true
    }

    if (isNaN(value)) {
      console.warn('Not a Number:', value)
    }

    const parts = String(value).split('.')
    const charecteristic = parts[0].length

    return charecteristic <= maxCount
  }
}

export function minCharacteristic (maxCount) {
  return (value) => {
    if (!isPresent(value)) {
      return true
    }

    if (isNaN(value)) {
      console.warn('Not a Number:', value)
      return false
    }

    const parts = String(value).split('.')
    const charecteristic = parts[0].length

    return charecteristic >= maxCount
  }
}

export function maxMantissa (maxCount) {
  return (value) => {
    if (!isPresent(value)) {
      return true
    }

    if (isNaN(value)) {
      console.warn('Not a Number:', value)
      return false
    }

    const parts = String(value).split('.')

    if (parts.length !== 2) {
      console.warn('No mantissa:', value)
      return true
    }

    const mantissa = parts[1].length

    return mantissa <= maxCount
  }
}
