var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-nav"},[_c('div',{class:{fixed: _vm.isOnPosition},on:{"scroll":_vm.handleScroll}},_vm._l((_vm.totalOptions),function(options,index){return _c('div',{key:index},[_c('a',{class:{
          subItem: options.isSubField,
          activeSide: (options.status && !options.isSubField),
          'elevation-2': (options.status && !options.isSubField),
          invalid: (options.classStatus === 'invalid' && !options.isSubField),
          finished: options.classStatus === 'finished'
        },attrs:{"id":options.idRef},on:{"click":function($event){return _vm.clickTab(options.idRef)}}},[(!options.isSubField)?_c('v-icon',[_vm._v(_vm._s(options.icon || 'info'))]):_vm._e(),_c('span',{staticClass:"labelSpan",class:{
            subItemLabel: options.isSubField,
            activeSubItem: options.status,
            activeInvalidSub: options.status && options.classStatus === 'invalid',
            invalidSubItem: (options.classStatus === 'invalid' && options.isSubField)
          }},[_vm._v(" "+_vm._s(_vm._f("t")(options.name))+" ")])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }