import Vue from 'vue'

export default {

  // TODO remove the callback mechanism, it should not be needed anymore
  addCallback (state, { callback, type }) {
    if (state.callbacks[type]) {
      const callbacks = state.callbacks[type]
      callbacks.push(callback)

      Vue.set(state.callbacks, type, callbacks)
    } else {
      callback()
    }
  },

  resetCallbacks (state, type) {
    Vue.set(state.callbacks, type, [])
  }

}
