<template>
  <div>
    <div style="display: grid; grid-template-columns: max-content 1fr; grid-column-gap: 10px">
      <h2 ref="title">{{ 'activeRFP.proposal.liability' | t }}</h2>
      <v-rating
        v-if="nonDraftLiability && nonDraftLiability.rating"
        small
        color="orange"
        class="raitings"
        background-color="orange lighten-3"
        readonly
        :value="nonDraftLiability.rating"
        half-increments hover />
    </div>
    <p
      v-if="requestedContent"
      style="max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%; margin-top: -10px; margin-bottom: 20px; ">{{ 'activeRFP.proposal.requestedInformationLiability' | t }} {{ requestedContent }}</p>

    <p
        v-if="nonDraftLiability && nonDraftLiability.ratingComment"
        style="margin-top: -10px; margin-bottom: 20px; grid-column: 1 / 3; max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%">
      Feedback from the client: {{ nonDraftLiability.ratingComment }}</p>

    <v-card class="grid" :class="{missingcard: missingMandatoryFields.includes('LIABILITY')}">

    <template v-if="(editLiability || newLiability) && updateMode">
      <div>
        <span class="mandatory light">{{ 'activeRFP.proposal.maximumLiability' | t }}</span>
        <NumberField
          :prefix="currencySymbol()"
          :disabled="!proposalIsEditable"
          class="date-field"
          style="margin-bottom: 8px;"
          :class="{missing: missingFields.maximumLiability}"
          @input="missingFields.maximumLiability = false; checkProposalUnsavedChanges()"
          v-model="liability.extendedFields.maximumLiability" />
      </div>

      <div style="grid-column-start: 1; grid-column-end: 3;">
        <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span>
        <v-textarea
          label=""
          :disabled="!proposalIsEditable"
          solo flat
          :class="{missing: missingFields.explanation}"
          @input="missingFields.explanation = false; checkProposalUnsavedChanges()"
          v-model="liability.extendedFields.explanation"></v-textarea>
      </div>

      <div class="actions">
        <v-btn flat v-if="proposalIsEditable && !isNewLiability" @click="cancel">{{ 'buttons.cancel' | t }}</v-btn>
        <v-btn class="main" v-if="proposalIsEditable" @click="save">{{ 'activeRFP.proposal.saveLiability' | t }}</v-btn>
      </div>
    </template>

    <template v-else>
      <div>
        <span class="light">{{ 'activeRFP.proposal.maximumLiability' | t }}</span>
        <br>
        <p v-if="liability.extendedFields.maximumLiability || liability.extendedFields.maximumLiability === 0">{{ formatNumber(liability.extendedFields.maximumLiability) }} {{currencySymbol()}}</p>
        <p v-else>{{ 'activeRFP.proposal.maximumLiabilityNotProvided' | t  }}</p>
      </div>

      <v-btn class="edit-liability" v-if="proposalIsEditable && updateMode" icon @click="editProposal"><v-icon style="font-size: 18px">ic-edit</v-icon></v-btn>

      <div v-if="liability.extendedFields.explanation" style="grid-column-start: 1; grid-column-end: 3;">
        <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span>
        <p>{{ liability.extendedFields.explanation }}</p>
      </div>
    </template>

    </v-card>

  </div>
</template>

<script>
import NumberField from '../../generalComponents/NumberField'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ProposalLiability',
  components: { NumberField },
  data () {
    return {
      missingFields: {
        maximumLiability: false,
        explanation: false
      }
    }
  },
  watch: {
    currentRfpLawFirmRelation () {
      this.assignLiability()
    }
  },
  computed: {
    ...mapGetters(['currentRfpLawFirmRelation', 'formatNumber', 'proposalIsEditable', 'currencySymbol']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      currentRfp: state => state.currentRfp,
      liabilityDraft: state => state.draftProposal.liability,
      updateMode: state => state.draftProposal.updateMode,
      editLiability: state => state.draftProposal.editLiability,
      newLiability: state => state.draftProposal.newLiability
    }),

    nonDraftLiability () {
      return this.currentRfpLawFirmRelation.proposal.find(category => (this.updateMode ? category.draft : !category.draft) && category.name === 'LIABILITY') || { extendedFields: {} }
    },

    liability () {
      if (this.updateMode) {
        return this.liabilityDraft
      }

      return this.nonDraftLiability
    },

    isNewLiability () {
      if (this.currentRfpLawFirmRelation.proposal) {
        return !this.currentRfpLawFirmRelation.proposal.some(category => category.name === 'LIABILITY')
      } else {
        return true
      }
    },

    requestedContent () {
      return this.currentRfp ? this.currentRfp.terms.requestedLiabilityContent : ''
    }
  },
  methods: {
    ...mapActions(['saveLiability', 'checkProposalUnsavedChanges']),
    ...mapMutations(['setDraftProposalItem']),

    async save () {
      let missing = false
      const requiredFields = ['maximumLiability']
      requiredFields.forEach(field => {
        if (!this.liability.extendedFields[field]) {
          this.missingFields[field] = true
          missing = true
        }
      })

      if (missing) {
        return
      }

      await this.saveLiability({
        liability: {
          name: this.liability.name,
          extendedFields: this.liability.extendedFields,
          draft: this.liability.draft
        }
      })

      this.setDraftProposalItem({
        key: 'editLiability',
        value: false
      })
      this.setDraftProposalItem({
        key: 'newLiability',
        value: false
      })
      this.checkProposalUnsavedChanges()
    },

    cancel () {
      // this.liability = this.lastLiability
      this.setDraftProposalItem({
        key: 'liability',
        value: this.currentRfpLawFirmRelation.proposal.find(category => category.draft && category.name === 'LIABILITY') || {
          name: 'LIABILITY',
          extendedFields: {
            maximumLiability: null,
            explanation: ''
          },
          draft: true
        }
      })

      this.setDraftProposalItem({
        key: 'editLiability',
        value: !this.editLiability
      })
      this.checkProposalUnsavedChanges()
    },

    editProposal () {
      this.lastLiability = JSON.parse(JSON.stringify(this.liability))
      this.setDraftProposalItem({
        key: 'editLiability',
        value: !this.editLiability
      })
      this.checkProposalUnsavedChanges()
    },

    assignLiability () {
      if (this.liabilityDraft) {
        return
      }

      this.setDraftProposalItem({
        key: 'liability',
        value: this.currentRfpLawFirmRelation.proposal.find(category => category.draft && category.name === 'LIABILITY') || {
          name: 'LIABILITY',
          extendedFields: {
            maximumLiability: null,
            explanation: ''
          },
          draft: true
        }
      })

      this.setDraftProposalItem({
        key: 'newLiability',
        value: this.liability.extendedFields.maximumLiability === null
      })
    }

  }
}
</script>

<style scoped lang="stylus">
  .grid
    display grid
    grid-template-columns 1fr 1fr

  .actions
    grid-column-start 1
    grid-column-end 3
    text-align right

    .liability-save
      margin-right 24px
      color rgb(102, 174, 4)

    button
      border-radius 5px

  .edit-liability
    grid-column-start: 2
    height 24px
    width 24px
    justify-self right
</style>
