import apollo from '../../apollo'
import gql from 'graphql-tag'

export default {

  async saveTeamMember ({ getters, state, commit }, { userId, permissionLevel, hideInProposal, isUpdate }) {
    const result = await apollo(state).mutate({
      mutation: gql`
        mutation addOrUpdateRfpTeamMember ($rfpId: ID!, $userId: ID!, $permissionLevel: String!, $hideInProposal: Boolean) {
          addOrUpdateRfpTeamMember (rfpId: $rfpId, userId: $userId, permissionLevel: $permissionLevel, hideInProposal: $hideInProposal)
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        userId: userId,
        permissionLevel: permissionLevel,
        hideInProposal: hideInProposal
      }
    })

    console.log('SaveTeamMember', result)
    if (!isUpdate) {
      commit('addTeamMember', { userId, permissionLevel, hideInProposal })
    }
  },

  async removeRfpTeamMember ({ getters, state, commit }, user) {
    await apollo(state).mutate({
      mutation: gql`
        mutation removeRfpTeamMember ($rfpId: ID!, $userId: ID!) {
          removeRfpTeamMember (rfpId: $rfpId, userId: $userId)
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        userId: user.id
      }
    })

    commit('removeRfpTeamMember', user)
  }

}
