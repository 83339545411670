import Vue from 'vue'

export default {

  setLawfirmRelationFeedback (state, lawFirmRelationsFeedbackArray) {
    let allRelations = state.currentRfp.lawFirmRelations
    allRelations[allRelations.findIndex(lawfirmRelation => lawfirmRelation.status === 'COMPLETED_WON')].feedback = lawFirmRelationsFeedbackArray
    allRelations[allRelations.findIndex(lawfirmRelation => lawfirmRelation.status === 'COMPLETED_WON')].feedbackSubmitted = false // check
    Vue.set(state.currentRfp, 'lawFirmRelations', allRelations)
  },

  setFeedbackSubmitted (state) {
    let allRelations = state.currentRfp.lawFirmRelations
    allRelations[allRelations.findIndex(lawfirmRelation => lawfirmRelation.status === 'COMPLETED_WON')].feedbackSubmitted = true
    // console.log(allRelations)
    Vue.set(state.currentRfp, 'lawFirmRelations', allRelations)
  },

  updateFeedbackItems (state, feedbackItems) {
    const allRelations = state.currentRfp.lawFirmRelations
    const changedRelation = allRelations[allRelations.findIndex(lawfirmRelation => lawfirmRelation.status === 'COMPLETED_WON')]
    changedRelation.feedbackRatings = feedbackItems.feedbackRatings
    changedRelation.feedbackRatingByCategory = feedbackItems.feedbackRatingByCategory
    changedRelation.overallFeedbackRating = feedbackItems.overallFeedbackRating
    Vue.set(state.currentRfp, 'lawFirmRelations', allRelations)
  },

  setDiscloseFeedback (state, { discloseFeedback }) {
    const allRelations = state.currentRfp.lawFirmRelations
    allRelations[allRelations.findIndex(lawfirmRelation => lawfirmRelation.status === 'COMPLETED_WON')].discloseFeedback = discloseFeedback
    Vue.set(state.currentRfp, 'lawFirmRelations', allRelations)
  }
}
