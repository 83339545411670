<template>
  <v-card>
    <div class="grid">
      <div>
        <span class="light">{{ 'newRFP.termsAndConditions.generalFees' | t }}</span>
        <v-select
          label=""
          :class="{missing: missingMandatoryFields.includes('terms/generalFees')}"
          :disabled="!isRfpFieldEditable()"
          :value="currentRfp.terms.generalFees"
          @input="handleGeneralFeesInput"
          :items="generalFeesOptions"
          :placeholder="'newRFP.termsAndConditions.placeHolders.generalFees' | t "
          solo
          flat />
      </div>

      <div v-if="currentRfp.terms.generalFees === 'LIMITED_BILLABLE'">
        <span class="light">{{ 'newRFP.termsAndConditions.maxPercent' | t }}</span>
        <NumberField
          :min="0"
          :max="100"
          @invalidInput="handleInvalidInput"
          :class="{missing: missingMandatoryFields.includes('terms/maximumFee') || invalidFields.includes('terms/maximumFee')}"
          :disabled="!isRfpFieldEditable()"
          :value="currentRfp.terms.maximumFee"
          @input="value => { updateCurrentRfpTerms({ field: 'maximumFee', value }); removeMissingMandatoryField('terms/maximumFee'); }" />
      </div>
    </div>
    <div :class="{general: true, missing: missingMandatoryFields.includes('terms/' + radio.field)}" v-for="radio in radios" :key="radio.title">
      <span class="light">{{ radio.title }}</span>
      <v-radio-group
        style="margin-top: 5px; padding-top: 0px;"
        :disabled="!isRfpFieldEditable()"
        :value="currentRfp.terms[radio.field]"
        @change="value => { updateCurrentRfpTerms({ field: radio.field, value }); removeMissingMandatoryField('terms/' + radio.field)}">
          <v-radio
            v-for="option in radio.options"
            :key="option.value"
            :value="option.value"
            color="#fff">
            <template v-slot:label>
              <div style="height: 32px;">
                <span style="margin-top: 5px; display: inline-block;">{{ option.text | t }}</span>
                <input

                  @click.stop="event => { event.stopPropagation(); }"
                  @focus="event => { event.stopPropagation(); }"
                  v-if="option.value === 'OTHER'"
                  :value="currentRfp.terms[radio.otherField]"
                  :class="{missing: missingMandatoryFields.includes('terms/' + radio.otherField)}"
                  @input="event => { updateCurrentRfpTerms({ field: radio.otherField, value: event.target.value });removeMissingMandatoryField('terms/' + radio.otherField) }"
                  class="inline-input"/>
              </div>
            </template>
          </v-radio>
      </v-radio-group>
    </div>

  </v-card>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import NumberField from '../../../generalComponents/inputFields/NumberField'

export default {
  name: 'ExpensesPanel',
  components: { NumberField },
  data () {
    return {
      radios: []
    }
  },
  created () {
    this.radios = [
      {
        title: this.$t('newRFP.termsAndConditions.flight'),
        field: 'flightExpenseType',
        otherField: 'customFlightExpense',
        options: this.flightExpenseTypeOptions
      },
      {
        title: this.$t('newRFP.termsAndConditions.train'),
        field: 'trainExpenseType',
        otherField: 'customTrainExpense',
        options: this.trainExpenseTypeOptions
      },
      {
        title: this.$t('newRFP.termsAndConditions.rentalCar'),
        field: 'rentalCarExpenseType',
        otherField: 'customRentalCarExpense',
        options: this.rentalCarExpenseTypeOptions
      },
      {
        title: this.$t('newRFP.termsAndConditions.hotel'),
        field: 'hotelExpenseType',
        otherField: 'customHotelExpense',
        options: this.hotelExpenseTypeOptions
      }
    ]
  },
  computed: {
    ...mapGetters([
      'flightExpenseTypeOptions',
      'trainExpenseTypeOptions',
      'rentalCarExpenseTypeOptions',
      'hotelExpenseTypeOptions',
      'generalFeesOptions',
      'isRfpFieldEditable'
    ]),
    ...mapState({
      currentRfp: state => state.currentRfp,
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields
    })
  },
  methods: {
    ...mapMutations(['updateCurrentRfp', 'updateCurrentRfpTerms', 'removeMissingMandatoryField', 'addInvalidField', 'removeInvalidField']),

    handleInvalidInput (isInvalid) {
      if (isInvalid) {
        this.addInvalidField('terms/maximumFee')
      } else {
        this.removeInvalidField('terms/maximumFee')
      }
    },

    handleGeneralFeesInput (value) {
      this.updateCurrentRfpTerms({ field: 'generalFees', value })
      this.removeMissingMandatoryField('terms/generalFees')
      this.removeInvalidField('terms/maximumFee')

      if (value === 'LIMITED_BILLABLE' && this.currentRfp.terms.maximumFee === null) {
        this.updateCurrentRfpTerms({ field: 'maximumFee', value: 3 })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap 10px;
  }

  .general {
    /*padding 7px 5px 0 5px;*/
    margin-top: 8px
    border: 2px solid white
    border-radius: 10px;
    &.missing {
      border-color: rgb(199, 54, 57);
    }
  }

  .v-card__text
    span
      color rgb(158, 158, 158)
      padding-left 12px

  >>> .v-text-field .v-input__slot {
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important
  }

  >>> .v-text-field .v-input__slot &.missing  {
    border 2px solid rgb(199, 54, 57)
  }

  .light
    color: #ababb5

  input.missing
    border 2px solid $red
</style>
