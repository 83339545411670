<template>
  <div>
    <div class="center-title">
      <h1>{{ 'newRFP.summary.summaryTitle' | t }}</h1>
      <TooltipHolder
        class="info-icon"
        :titleText="$t('newRFP.summary.summaryTitle')"
        :spanId="$t('newRFP.summary.summaryTitle')"
        :width="150"
        :moveTop="30"
        :icon="'info'"
        :tooltipText="$t('tooltips.summaryTitleTooltip')"
        right summaryTooltip/>
    </div>
    <Summary :isNewRfpSummary="true" />
  </div>
</template>

<script>
import Summary from './Summary'
import TooltipHolder from '../../generalComponents/TooltipHolder'

export default {
  name: 'SummaryPage',
  components: { Summary, TooltipHolder }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  h1
    display inline-block

  .center-title
    font-size 32px
    padding-bottom 1em
    margin-bottom .5em
    .info-icon
      font-size 20px
      margin-left 5px
      vertical-align middle

  >>> .info-icon
    font-size 20px
    margin-left 5px
    margin-bottom 4px
    vertical-align middle
    .v-icon
      font-size 20px !important
      color: #ababb5 !important
      vertical-align middle
</style>
