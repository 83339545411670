<template>
  <div class="wrapper">
    <div v-if="!showUpdateForm" class="grid">
      <div class="order-buttons">
        <v-btn :disabled="disableUpButton" small icon @click="$emit('moveUp')"><v-icon>keyboard_arrow_up</v-icon></v-btn>
        <v-btn :disabled="disableDownButton" small icon @click="$emit('moveDown')"><v-icon>keyboard_arrow_down</v-icon></v-btn>
      </div>

      <div>
        <span class="light">{{ 'scopeGeneratorAdminView.key' | t }}</span>
        <strong>{{ field.key }}</strong>
      </div>

      <div>
        <span class="light">{{ 'scopeGeneratorAdminView.layout.field.fieldType' | t }}</span>
        <strong>{{ 'dropDownOptions.FieldType.' + field.fieldType | t }}</strong>
      </div>

      <div>
        <v-btn flat @click="$emit('delete')" icon><v-icon>clear</v-icon></v-btn>
      </div>
      <div>
        <v-btn flat @click="edit" icon><v-icon>edit</v-icon></v-btn>
      </div>
    </div>

    <FieldDefinitionInput
      style="border: none; margin: 0; padding: 0;"
      v-if="showUpdateForm"
      @add="update"
      :is-update="true"
      :panel="panel"
      :is-list="isList"
      @cancel="showUpdateForm = false"
      :field="newValue" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FieldDefinitionInput from './FieldDefinitionInput'

export default {
  name: 'FieldDefinition',
  components: { FieldDefinitionInput },
  props: {
    field: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    isList: {
      type: Boolean,
      default: false
    },
    disableUpButton: {
      type: Boolean,
      default: false
    },
    disableDownButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newValue: null,
      showUpdateForm: false
    }
  },
  computed: {
    ...mapGetters(['allFields'])
  },
  methods: {

    getField (field) {
      return field ? this.allFields.find(_field => _field.value === field) : null
    },

    edit () {
      this.$emit('updateField')
      this.newValue = { ...this.field }

      this.newValue.enabledBy = this.newValue.enabledBy.map(enabledBy => ({
        absolutePath: this.getField(enabledBy.absolutePath),
        relativePath: this.getField(enabledBy.relativePath),
        values: enabledBy.values
      }))

      this.newValue.constraints = this.newValue.constraints.map(constraint => ({
        absolutePath: this.getField(constraint.absolutePath),
        relativePath: this.getField(constraint.relativePath),
        value: constraint.value,
        dtype: constraint.dtype
      }))

      this.showUpdateForm = true
    },

    update () {
      this.field.fieldType = this.newValue.fieldType
      this.field.optionListKey = this.newValue.optionListKey

      this.field.enabledBy = this.newValue.enabledBy.map(enabledBy => ({
        absolutePath: enabledBy.absolutePath ? enabledBy.absolutePath.value : null,
        relativePath: enabledBy.relativePath ? enabledBy.relativePath.value : null,
        values: enabledBy.values
      }))

      this.field.constraints = this.newValue.constraints.map(constraint => ({
        absolutePath: constraint.absolutePath ? constraint.absolutePath.value : null,
        relativePath: constraint.relativePath ? constraint.relativePath.value : null,
        value: constraint.value,
        dtype: constraint.dtype
      }))
      this.field.key = this.newValue.key

      this.showUpdateForm = false
    }

  }
}
</script>

<style scoped lang="stylus">
  .grid
    display grid
    grid-template-columns 50px 1fr 1fr 50px 50px
    align-items center

    > div
      span,
      strong
        display block

  .wrapper
    padding 10px 20px
    margin 5px
    border 1px solid gray
    border-radius 10px
</style>
