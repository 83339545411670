<template>
  <div>
    <div class="center-title">
      <h1>{{ 'newRFP.general.generalTitle' | t }}</h1>
      <!-- <v-icon class="info-icon1">info</v-icon> -->
      <TooltipHolder
        class="info-icon"
        :titleText="$t('newRFP.general.generalTitle')"
        :spanId="$t('newRFP.general.generalTitle')"
        :width="150"
        :moveTop="30"
        :icon="'info'"
        :tooltipText="$t('tooltips.generalTitleTooltip')"
        right summaryTooltip/>
    </div>
    <div class="main">
      <div  class="side-nav">
        <Sidebar
                 :optionsArray="sidebarArray"
                 :componentsArray="componentArray"/>
      </div>
      <div class="content">
        <div ref="general">
          <h2 style="display: inline-block;">{{ "newRFP.general.matterinfo" | t }}</h2>
        </div>
        <GeneralRfpPanel style="margin-top: 0.5em;"/>
        <div ref="team" style="margin-top: 2.5em;">
          <h2 style="display: inline-block; padding-top: 8px">{{ "newRFP.general.team" | t }}</h2>
        </div>
        <Team/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import GeneralRfpPanel from './GeneralRfpPanel'
import Team from '../../activeRfp/team/Team'
import Sidebar from '../../generalComponents/SideBar'

export default {
  name: 'GeneralInfo',
  components: { GeneralRfpPanel, TooltipHolder, Team, Sidebar },
  data () {
    return {
      componentArray: []
    }
  },
  mounted () {
    this.componentArray = [this.$refs.general, this.$refs.team]
  },
  computed: {
    ...mapGetters(['currentRfpOrDraft']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    }),

    currentRfp () {
      return this.currentRfpOrDraft
    },

    sidebarArray () {
      return [
        {
          name: 'newRFP.general.matterinfo',
          idRef: 'general',
          status: true,
          icon: 'info',
          top: 55,
          classStatus: false
        },
        {
          name: 'newRFP.general.team',
          idRef: 'team',
          status: false,
          icon: 'group',
          top: 115,
          classStatus: false
        }
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../../assets/css/variables.styl"

  h1
    display inline-block

  .center-title
    font-size 32px
    padding-bottom 1em
    margin-bottom .5em

    .info-icon
      font-size 20px
      margin-left 5px
      vertical-align middle

  >>> .info-icon
    font-size 20px
    margin-left 5px
    margin-bottom 4px
    vertical-align middle

    .v-icon
      font-size 20px !important
      color: #ababb5 !important
      vertical-align middle

    h2
      margin-top 1.5em
      margin-bottom 0.5em

    h2:first-child
      margin-top 0

  >>> .summary-panel
    .v-card
      padding 60px 100px
      border-radius 10px

  .main
    display grid
    grid-template-columns 200px minmax(0, 1fr)
    grid-column-gap 20px
    padding-top 1.5em
    border-top 1px solid rgb(211, 211, 211)

    .side-nav
      .fixed
        color white
        position fixed
        top 90px
        width 200px

      a
        display inline-block
        color gray
        font-weight bold
        width 100%
        padding 5px 10px
        border-radius 10px

        .v-icon
          font-size 16px
          color gray

        &.active
          color $dark-gray
          background #ffffff

          .v-icon
            color $dark-gray !important

    .content
      h2
        margin-top 1.5em
        margin-bottom 0em

      h2:first-child
        margin-top 0

</style>
