<template>
  <div>
    <h2 v-if="!proposalView" style="margin-bottom: 20px">{{ 'activeRFP.biddingProcessSection.capEstimateHourlyRates.submitNewBidTitle' | t }}</h2>
    <div class="table">
      <section class="row" :class="{'hourly-rates': !isCapOrFeeEstimate, 'cap-estimate': isCapOrFeeEstimate}">
        <span>{{ `activeRFP.biddingProcessSection.capEstimateHourlyRates.${ bidCaps.length > 1 ? 'memberHeader' : 'firstHeader' }` | t }}</span>
        <span>{{ $t('activeRFP.biddingProcessSection.capEstimateHourlyRates.rateHeader', { currency: $t(`dropDownOptions.Currency.${currentRfp.terms.currency || 'EUR'}`) }) }}</span>
        <span v-if="isCapOrFeeEstimate">{{ 'activeRFP.biddingProcessSection.capEstimateHourlyRates.hoursHeader' | t }}</span>
        <span v-if="isCapOrFeeEstimate" class="last-row">{{ `activeRFP.biddingProcessSection.capEstimateHourlyRates.${ bidCaps.length > 1 ? 'contributionHeader' : 'totalHeader' }` | t }}</span>
      </section>
      <v-divider></v-divider>

      <template v-for="(bidCap, index) in bidCaps" >
        <section :class="{'hourly-rates': !isCapOrFeeEstimate, 'cap-estimate': isCapOrFeeEstimate}" class="row input" :key="bidCap.key+index">
          <span class="bold-span">{{ bidCap.key }}</span>
          <v-text-field
            v-if="!proposalView || edit"
            v-model="bidCap.rate"
            @input="value => {calculateTotal(); validate(value, 'rate' + bidCap.key)}"
            :id="'rate' + bidCap.key"
            :class="{ invalid: invalidFields.includes('rate' + bidCap.key) || missingFields.includes('rate' + bidCap.key) }"
            :disabled="bidDisabled"
            autocomplete="off"
            reverse
            solo
            flat />
          <div v-else>{{ !!bidCap.rate || bidCap.rate === 0 ? formatNumber(bidCap.rate) : '-'}} {{currencySymbol()}}</div>

          <v-text-field
            v-model="bidCap.hours"
            v-if="isCapOrFeeEstimate && (!proposalView || edit)"
            @input="value => {calculateTotal(); validate(value, 'hours'+bidCap.key)}"
            :class="{ invalid: invalidFields.includes('hours'+bidCap.key) || missingFields.includes('hours' + bidCap.key) }"
            :id="'hours'+bidCap.key"
            :disabled="bidDisabled"
            autocomplete="off"
            reverse
            solo
            flat />
          <div v-else-if="isCapOrFeeEstimate">{{ bidCap.hours }}</div>
          <span v-if="isCapOrFeeEstimate" class="last-row bold-span">{{(bidCap.rate === null || bidCap.hours === null) ? '-' : formatBid(bidCap.rate * bidCap.hours)}}</span>
        </section>
        <v-divider :key="'divider'+index"></v-divider>
      </template>

      <section v-if="bidCaps.length > 0" class="bid-calculation">
        <span v-if="isFullDisclosureMode && !isSimpleRfp">{{ `activeRFP.biddingProcessSection.capEstimateHourlyRates.${currentRfp.auctionSubType === 'STRICT' ? 'currentLowest' : 'myCurrent'}` | t }} {{ `activeRFP.biddingProcessSection.capEstimateHourlyRates.${currentRfp.terms.feeStructure}` | t }}:</span>
        <span v-if="isFullDisclosureMode && !isSimpleRfp">{{ lowestBid ? formatBid(Math.round(lowestBid.relevant_display_value)) : '-' }}</span>
        <span v-if="!isSimpleRfp && isFullDisclosureMode && differenceToNextLawFirm !== 0">{{ 'activeRFP.biddingProcessSection.capEstimateHourlyRates.differenceToLowestLabel' | t }}:</span>
        <span :class="{lower: !lowerThanLowest}" v-if="!isSimpleRfp && isFullDisclosureMode && differenceToNextLawFirm !== 0">{{lowestBid ? formatBid(differenceToLowest) : '-'}}</span>
        <p>{{ `activeRFP.biddingProcessSection.capEstimateHourlyRates.${ isCapOrFeeEstimate ?  currentRfp.terms.feeStructure : 'averageRateLabel'}` | t }}:</p>
        <p :class="{invalidBid: bidIsInvalid}">{{formatBid(bidCapTotal)}}</p>
      </section>

      <div class="placebid-btn" v-if="!proposalView || (edit && updateMode)">
        <v-btn
          flat v-if="proposalView && edit && updateMode && currentRfpLawFirmRelation.firstBidToBePlacedDraft" @click="$emit('cancel')">
          {{ 'buttons.cancel' | t }}
        </v-btn>
        <v-btn :disabled="disabled || bidDisabled || bidIsInvalid || justPlaceBid" @click="$emit('placeBid', Math.round(bidCapTotal))" solo flat class="main btn-place" >{{ buttonTxt | t }}</v-btn>
      </div>

    </div>
</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'BidEstimateInput',
  props: {
    bidCaps: {
      required: true,
      type: Array
    },
    isCapOrFeeEstimate: {
      required: true,
      type: Boolean
    },
    bidDisabled: {
      required: true,
      type: Boolean
    },
    buttonTxt: {
      required: true
    },
    bidIsInvalid: {
      type: Boolean,
      required: false
    },
    justPlaceBid: {
      type: Boolean,
      required: false,
      default: false
    },
    proposalView: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bidCapTotal: 0,
      disabled: true,
      lowestBid: null,
      lowerThanLowest: true,
      differenceToLowest: 0,
      missingFields: []
    }
  },
  watch: {
    bidCaps () {
      this.calculateTotal()

      if (this.proposalView) {
        setTimeout(() => {
          this.validateAllInputs()
        }, 500)
      }
    },
    usersLowestComputedBid () {
      this.assignLowestBid()
    }
  },
  created () {
    this.calculateTotal()
    this.assignLowestBid()

    this.$nextTick(() => {
      if (this.proposalView) {
        setTimeout(() => {
          this.calculateTotal()
          this.validateAllInputs()
        }, 500)
      }
    })
  },
  computed: {
    ...mapGetters([
      'formatBid',
      'formatNumber',
      'isFullDisclosureMode',
      'orderedBids',
      'differenceToNextLawFirm',
      'isSpecialBidding',
      'usersLowestComputedBid',
      'isSimpleRfp',
      'auctionProgress',
      'RfpProgress',
      'currentRfpLawFirmRelation',
      'currencySymbol'
    ]),
    ...mapState({
      invalidFields: state => state.validation.invalidFields,
      currentRfp: state => state.currentRfp,
      updateMode: state => state.draftProposal.updateMode
    })
  },
  methods: {
    ...mapMutations(['addInvalidField', 'removeInvalidField']),
    calculateTotal () {
      if (this.isCapOrFeeEstimate) {
        this.bidCapTotal = this.bidCaps.reduce((prev, current) => {
          return prev + ((!isNaN(parseFloat(current.rate)) && !isNaN(parseFloat(current.hours))) ? parseFloat(current.rate) * parseFloat(current.hours) : 0)
        }, 0)
      } else {
        this.bidCapTotal = this.bidCaps.reduce((prev, current) => {
          return prev + ((current.rate !== null && !isNaN(parseFloat(current.rate))) ? parseFloat(current.rate) : 0)
        }, 0) / this.bidCaps.filter(rowVal => !isNaN(parseFloat(rowVal.rate))).length
      }

      if (this.lowestBid) {
        if (this.bidCapTotal > this.lowestBid.relevant_display_value) {
          this.lowerThanLowest = false
        }
        this.differenceToLowest = Math.abs(Math.round(this.bidCapTotal - this.lowestBid.relevant_display_value))
      }
    },

    validate (value, field) {
      // console.log(event.target.id, event.target.value)
      // let field = event.target.id
      let number = parseInt(value)
      if (isNaN(value) || (field.startsWith('rate') && number === 0) || (field.startsWith('rate') && number > 5000)) {
        if (value !== '') {
          this.addInvalidField(field)
        } else {
          this.removeInvalidField(field)
        }

        this.disabled = true
      } else {
        this.removeInvalidField(field)
        this.missingFields = this.missingFields.filter(item => item !== field)
        this.validateAllInputs()
      }
    },

    validateAllInputs () {
      let reviewArr = this.bidCaps.map(bidRow => {
        if (this.isCapOrFeeEstimate) {
          return !isNaN(parseInt(bidRow.hours)) && !isNaN(parseInt(bidRow.rate)) && parseInt(bidRow.rate) !== 0 && parseInt(bidRow.rate) < 5001
        } else {
          return !isNaN(parseInt(bidRow.rate)) && parseInt(bidRow.rate) !== 0 && parseInt(bidRow.rate) < 5001
        }
      })
      let areHoursZero = this.bidCaps.every(bidRow => parseInt(bidRow.hours) === 0)
      // console.log(areHoursZero)
      if (reviewArr.includes(false) || areHoursZero) {
        this.disabled = true
      } else {
        this.disabled = false
        this.validateTotal()
        this.$emit('inputChange', this.bidCapTotal)
      }
    },

    validateMandatory () {
      this.bidCaps.forEach(bidRow => {
        if (this.isCapOrFeeEstimate) {
          if (!bidRow.hours && !this.missingFields.includes('hours' + bidRow.key)) {
            this.missingFields.push('hours' + bidRow.key)
          }
        }

        if (!bidRow.rate && !this.missingFields.includes('rate' + bidRow.key)) {
          this.missingFields.push('rate' + bidRow.key)
        }
      })
      console.log(this.disabled, this.bidDisabled, this.bidIsInvalid, this.justPlaceBid)
      return this.disabled || this.bidDisabled || this.bidIsInvalid || this.justPlaceBid
    },

    validateTotal () {
      if (!this.lowestBid) {
        return
      }

      if (this.bidCapTotal < this.lowestBid.relevant_display_value) {
        this.lowerThanLowest = true
      } else {
        this.lowerThanLowest = false
      }
    },

    assignLowestBid () {
      this.lowestBid = (this.isSpecialBidding && this.orderedBids && this.auctionProgress(this.currentRfp) >= this.RfpProgress.ACTIVE_SECOND_BIDS_OR_AUCTION) ? this.orderedBids[0] : this.usersLowestComputedBid
      this.calculateTotal()
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../../assets/css/variables.styl"

  .row
    display grid
    grid-column-gap 20px
    align-items center
    &.hourly-rates
      grid-template-columns 2fr 1fr

    &.cap-estimate
      grid-template-columns repeat(2, 1.5fr) repeat(2, 1fr)

  .last-row
    justify-self end

  span
    color $icon-gray
    &.lower
      color $red !important

  .bold-span
    color $dark-gray
    font-weight bold

  .input
    margin 10px 0

  p
    margin-bottom 0px

  .bid-calculation
    margin-top 10px
    margin-left 57%
    display grid
    grid-row-gap 5px
    grid-template-columns repeat(2, 0.5fr)
    justify-items right
    font-weight bold
    span
      color $dark-gray

  .invalidBid
    color $red

  .placebid-btn
    display flex
    justify-content flex-end
    margin-top 15px

  .btn-place
    border 1px solid lightgray
    border-radius 5px

  :deep(.v-input__slot)
    border 1px solid $icon-gray
    border-radius 5px !important
    margin-bottom 0px

  :deep(.v-text-field__details)
    display none

  :deep(.v-btn)
    padding 0 70px

  .invalid :deep(.v-input__slot)
    border 1px solid $red !important

    .v-text-field__slot input
      color $red !important
</style>
