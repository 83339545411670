import apollo from '../../apollo'
import gql from 'graphql-tag'

export default {

  async loadOptionLists ({ commit, getters, state }, includeLabelTranslations) {
    const response = await apollo(state).query({
      query: gql`
        query optionLists {
          optionLists {
            key
            isReserved
            options {
              orderId
              key
              translatedLabel
              ${includeLabelTranslations ? `
                labelTranslations
                active
              ` : ''}
            }
          }
        }
      `
    })

    commit('setOptionLists', response.data.optionLists)
  }

}
