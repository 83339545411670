import moment from 'moment'
import { camelToSnakeCase } from '../../util/camelSnakeConversion'
import dotProp from 'dot-prop'

function getInitialBidForLawFirm (orderedBids, lawFirmId) {
  for (let i = orderedBids.length - 1; i >= 0; i--) {
    if (orderedBids[i].law_firm_id === lawFirmId) {
      return orderedBids[i].relevant_display_value
    }
  }

  return '-'
}

export default {

  isEAuction (state) {
    return state.currentRfp && state.currentRfp.auctionType === 'E_AUCTION'
  },

  relevantBidKey (state) {
    if (state.currentRfp.terms.feeStructure === 'FIXED') {
      return 'amountFixed'
    } else if (state.currentRfp.terms.feeStructure === 'RATE_CARD' && state.currentRfp.terms.feeStructureSubType === 'BLENDED') {
      return 'amountBlendedRate'
    }
    return 'null'
  },

  isSimpleRfp (state) {
    return state.currentRfp && state.currentRfp.auctionType === 'SIMPLE'
  },

  isAdvancedRfp (state) {
    return state.currentRfp && state.currentRfp.auctionType === 'ADVANCED'
  },

  isFullDisclosureMode (state) {
    return state.currentRfp && state.currentRfp.disclosureMode === 'FULL_DISCLOSURE'
  },

  isRankAndNumberMode (state) {
    return state.currentRfp && state.currentRfp.disclosureMode === 'RANK_AND_NUMBER_OF_LAWFIRMS'
  },

  isRankOnlyMode (state) {
    return state.currentRfp && state.currentRfp.disclosureMode === 'RANK_ONLY'
  },

  isSpecialBidding (state) {
    return state.currentRfp && state.currentRfp.auctionSubType === 'STRICT'
  },

  userLastBidValue (state, getters) {
    if (!getters.userIsLawFirm) {
      return
    }
    if (state.auction.usersLastBid === null || state.currentRfp === null) {
      return
    }
    return getters.computedBidFromAuctionBid(state.auction.usersLastBid).relevant_display_value
  },

  computedBidFromAuctionBid: (state) => auctionBid => {
    const { feeStructure, feeStructureSubType } = state.currentRfp.terms
    const result = {}
    for (const [ camelAttribute, value ] of Object.entries(auctionBid)) {
      result[camelToSnakeCase(camelAttribute)] = value
    }

    const calculateDisplayValue = () => {
      if (feeStructure === 'FIXED') {
        return auctionBid['amountFixed']
      } else if (feeStructureSubType === 'BLENDED') {
        return auctionBid['amountBlendedRate'] * (auctionBid.hoursLevel1 !== null ? auctionBid.hoursLevel1 : 1)
      } else {
        let value = 0
        for (let i = 0; i < 4; i++) {
          if (feeStructure === 'RATE_CARD_CAP' || feeStructure === 'ESTIMATE_RATE_CARD') {
            value += (auctionBid['amountRateLevel' + (i + 1)] * auctionBid['hoursLevel' + (i + 1)])
          } else {
            value += (auctionBid['amountRateLevel' + (i + 1)] * 0.25)
          }
        }
        return Math.round(value)
      }
    }
    result.relevant_display_value = calculateDisplayValue()
    return result
  },

  isBlendedCapEstimate (state) {
    return dotProp.get(state, 'currentRfp.terms.feeStructureSubType') === 'BLENDED' &&
      ['RATE_CARD_CAP', 'ESTIMATE_RATE_CARD'].includes(dotProp.get(state, 'currentRfp.terms.feeStructure'))
  },

  isSeniorityFeeSubstructure (state) {
    if (!state.currentRfp || !state.currentRfp.terms || state.currentRfp.terms.feeStructure === 'FIXED') {
      return false
    }
    return state.currentRfp.terms.feeStructureSubType === 'SENIOR_LEVELS'
  },

  isCapOrFeeEstimate (state) {
    if (!state.currentRfp || !state.currentRfp.terms) {
      return false
    }
    return state.currentRfp.terms.feeStructure === 'RATE_CARD_CAP' || state.currentRfp.terms.feeStructure === 'ESTIMATE_RATE_CARD'
  },

  /* Computed bids ordered by bid amount from low to high  */
  orderedBids (state, getters) {
    if (!state.auction.bids || !state.currentRfp) {
      return []
    }
    return state.auction.bids.slice().sort((a, b) => a.relevant_display_value - b.relevant_display_value)
  },

  usersLowestComputedBid (state, getters) {
    if (getters.userIsCorporate) {
      return null
    }

    const bid = getters.orderedBids.find(bid => bid.law_firm_id === getters.currentLawFirmHash)

    return bid
  },

  differenceToNextLawFirm (state, getters) {
    const otherLowestBid = getters.orderedBids.find(bid => bid.law_firm_id !== getters.currentLawFirmHash)

    if (getters.usersLowestComputedBid && otherLowestBid) {
      return getters.usersLowestComputedBid.relevant_display_value - otherLowestBid.relevant_display_value
    }

    return null
  },

  currentRfpLawFirmRelation (state, getters) {
    if (getters.userIsLawFirm && state.currentRfp && state.currentRfp.lawFirmRelations && state.currentRfp.lawFirmRelations.length) {
      return state.currentRfp.lawFirmRelations[0]
    }
    return []
  },

  currentLawFirmHash (state, getters) {
    return getters.currentRfpLawFirmRelation ? getters.currentRfpLawFirmRelation.lawFirmHash : null
  },

  nextRfpDeadline: (state, getters) => (rfp) => {
    let timestamp = null
    if (rfp.status === 'ACTIVE_QA') {
      timestamp = rfp.qaEnd
    } else if (rfp.status === 'ACTIVE_FIRST_BIDS') {
      timestamp = rfp.firstBidDeadline || rfp.auctionEnd
    } else if (rfp.status === 'ACTIVE_FIRST_BIDS_CLOSED') {
      timestamp = rfp.auctionStart
    } else if (['ACTIVE_SECOND_BIDS_OR_AUCTION'].includes(rfp.status)) {
      timestamp = rfp.auctionEnd
    }

    // override timestamp according to law firm specific status
    if (getters.userIsLawFirm) {
      if (['NEW_CONFLICT_CHECK', 'NEW_NO_CONFLICTS'].includes(rfp.lawFirmRelations[0].status)) {
        timestamp = rfp.firstBidDeadline || rfp.auctionEnd
        timestamp = timestamp ? moment(timestamp).subtract(1, 'hours') : null
      } else if (rfp.lawFirmRelations[0].status !== 'ACTIVE') {
        timestamp = null
      }
      // all other states don't have a law firm specific deadline
    }

    return timestamp
  },

  // Sets the bid Value as currency in € (US)
  formatBid: (state, getters) => (bid, rfp) => {
    if (bid) {
      // const cents = (bid % 100) + ''
      const euros = Math.round(bid).toString()
      return euros.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ` ${getters.currencySymbol(rfp)}` // + ',' + (cents.length === 1 ? cents + '0' : cents) + ' €'
    } else {
      return '-'
    }
  },

  formatBidAccordingly: (state, getters) => (bid, rfp) => {
    let divider
    let precision
    if (bid) {
      // const cents = (bid % 100) + ''
      switch (true) {
        case (bid >= 10000 && bid < 1000000):
          divider = 1000
          precision = 10
          break
        case (bid >= 1000000):
          divider = 1000000
          precision = 100
          break
        default:
          divider = 1
          precision = 1
          break
      }

      const euros = (Math.round((bid / divider) * precision) / precision).toString()
      return euros.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + `${bid >= 10000 ? (bid >= 1000000 ? 'M' : 'K') : ''} ${getters.currencySymbol(rfp)}` // + ',' + (cents.length === 1 ? cents + '0' : cents) + ' €'
    } else {
      return `- ${getters.currencySymbol(rfp)}`
    }
  },

  standardBidsArray (state, getters) {
    const bids = state.auction.bids
    const currentRfp = state.currentRfp

    if (!currentRfp || !bids) {
      return []
    }

    if (currentRfp.auctionSubType === 'LOOSE') {
      const standardArray = []
      getters.bidsWithSubBids.forEach((bid, index, bidsWithSub) => {
        if (bid.law_firm_id === getters.currentLawFirmHash) {
          standardArray.push(bid)
          if (bid.subBids.length > 0) {
            let subBids = bid.subBids.sort((a, b) => a.relevant_display_value - b.relevant_display_value)
            standardArray.push(subBids[subBids.length - 1])
          }
        }
        if (index === 0 && bid.law_firm_id !== getters.currentLawFirmHash) {
          standardArray.push(bid)
        }
        if (index === 1 && bid.law_firm_id !== getters.currentLawFirmHash && bidsWithSub[0].law_firm_id === getters.currentLawFirmHash) {
          standardArray.push(bid)
        }
      })

      standardArray.sort((a, b) => a.relevant_display_value - b.relevant_display_value)
      return standardArray
    } else {
      return -1
    }
  },

  bidsWithSubBids (state, getters) {
    const firstBidMap = new Map()
    const result = []

    getters.orderedBids.forEach(bid => {
      if (firstBidMap.has(bid.law_firm_id)) {
        const bestBid = firstBidMap.get(bid.law_firm_id)
        bestBid.subBids.push(bid)
      } else {
        bid.subBids = []
        bid.initialBid = getInitialBidForLawFirm(getters.orderedBids, bid.law_firm_id)
        firstBidMap.set(bid.law_firm_id, bid)
        result.push(bid)
      }
    })

    return result
  },

  tableDisplayBids (state, getters) {
    if (getters.userIsLawFirm) {
      let bids = []
      if (!getters.isFullDisclosureMode) {
        bids = getters.orderedBids.filter(bid => {
          return bid.law_firm_id === getters.currentLawFirmHash
        })
      } else {
        bids = getters.orderedBids
      }
      return bids
    } else {
      return getters.bidsWithSubBids
    }
  },

  userPosition (state, getters) {
    if (getters.userIsCorporate) {
      return '-'
    }

    const index = getters.orderedBids.findIndex(bid => bid.law_firm_id === getters.currentLawFirmHash)

    return index > -1 ? index + 1 : '-'
  },

  initialBid (state, getters) {
    const bids = state.auction.bids
    if (getters.userIsLawFirm) {
      const bidKey = state.draftProposal.updateMode ? 'firstBidToBePlacedDraft' : 'firstBidToBePlaced'
      if (state.currentRfp) {
        return getters.currentRfpLawFirmRelation[bidKey]
      }

      return bids.find(bid => bid.law_firm_id === getters.currentLawFirmHash)
    }

    return null
  },

  numberOfInitialBids (state, getters) {
    if (!state.currentRfp) {
      return
    }

    const endOfInitialBids = moment.utc(state.currentRfp.firstBidDeadline)
    return getters.orderedBids.filter(bid => moment.utc(bid.time_of_placement).isBefore(endOfInitialBids)).length
  }

}
