<template>
  <div class="main">
    <div style="display: flex; justify-content: right">
      <v-btn v-if="canExtendQaDeadline" flat class="main" style="margin-top: 20px" @click="$router.push({name: 'billingTypeCorporate'})">
        <v-icon>restore</v-icon>&nbsp;{{ 'newRFP.biddingType.qaExtend' | t}}
      </v-btn>
    </div>
    <v-card v-if="filteredQuestions.length === 0 && !showNewQuestionEditor" class="no-result-card">
      <h3>{{ `activeRFP.q&a.${filter === 'all' ? 'noQuestions' : (filter === 'answered' ? 'noAnsweredQuestions' : 'noUnansweredQuestions')}` | t }}</h3>
    </v-card>
    <v-card
      v-for="question in filteredQuestions"
      :key="question.id"
      :style="question.type === 'CHANGE' ? 'background: none !important; border: none; offset: none; border-radius: none; box-shadow: none; margin-top: 15px' : ''"
      :class="question.type === 'CHANGE' ? '' : 'question-card'">
      <div v-if="question.type === 'CHANGE'" style="display: grid; grid-template-columns: 1fr max-content 1fr; align-items: center">
        <v-divider></v-divider>
        <span style="font-size: 16px">{{ $t('activeRFP.q&a.newRfpVersion') }}</span>
        <v-divider></v-divider>
      </div>
      <div v-else class="question-content">
        <v-avatar :size="30">{{ renderLawFirm(question.lawFirmId) ? renderLawFirm(question.lawFirmId)[0] : '' }}</v-avatar>

        <div class="sub-grid">
          <div>
            <span class="law-firm-name">{{ renderLawFirm(question.lawFirmId) }}</span>
            <span class="timestamp">{{ formatDateTime(question.timestamp) }}</span>
          </div>
          <span :class="{answered: !!question.answer, unanswered: !question.answer}">{{ `activeRFP.q&a.${question.answer ? 'answered' : 'unanswered'}` | t }}</span>

          <p class="question reset-style" v-html="question.text"></p>
          <div class="document-wrapper" v-if="question.attachments && question.attachments.length">
            <span class="documents-title">{{ 'activeRFP.q&a.attachedDocuments' | t }}</span><br>
            <FileChip v-for="file in question.attachments" :key="file.id" :file="file" showIcon></FileChip>
          </div>

          <v-btn flat class="answer-btn" v-if="!question.answer && !question.showEditor && userIsCorporate && !disabled" @click="question.showEditor = true">{{ 'activeRFP.q&a.answerQuestion' | t }}</v-btn>
          <QuickScopeEditor
            v-if="!question.answer && question.showEditor && userIsCorporate"
            v-model="question.newAnswer"
            class="new-answer-editor"
            :missing="newAnswerMissing[question.id]"
            :id="question.id"
            :placeholder="'activeRFP.q&a.placeholderAnswer' | t"
            :documents="question.newFiles"
            :buttonDisable="isButtonExecuting"
            @input="$set(newAnswerMissing, question.id, false)"
            @submit="waitButtonToEnd(submitAnswer, question)"
            @uploadDocument="handleDocumentUpload(question)"
            @cancel="question.showEditor = false; $nextTick(() => {question.newAnswer = ''}); $set(newAnswerMissing, question.id, false)"
            @deleteFile="file => deleteFile(question, file)"
            showQaActions />
          <input
            hidden
            :ref="'fileUpload_' + question.id"
            type="file"
            @change="(event) => { onFileChange(question, event) }"
            accept=".pdf,.docx">

          <div
            v-if="question.answer"
            v-show="question.showAnswer"
            class="answer-grid">
            <v-avatar class="answer-icon" :size="30">{{ currentRfp.clientName[0] }}</v-avatar>

            <div class="answer-sub-grid">
              <div>
                <span class="law-firm-name">{{ currentRfp.clientName }}</span>
                <span class="timestamp">{{ formatDateTime(question.answer.timestamp) }} <i class="edited-info">{{ question.answer.timestamp !== question.answer.lastChange ? renderLastChange(question.answer.lastChange) : '' }}</i></span>
              </div>
              <div v-if="userIsCorporate && !disabled && !question.updateAnswer" class="answer-actions">
                <span class="edit-answer" @click="question.updateAnswer = true; question.newAnswer = question.answer.text"><v-icon>edit</v-icon> {{ 'activeRFP.q&a.edit' | t }}</span>
                <span class="delete-answer" @click="showDeleteDialog = true; questionToDelete = question"><v-icon>delete</v-icon> {{ 'activeRFP.q&a.delete' | t }}</span>
              </div>

              <p class="question reset-style" v-if="!question.updateAnswer" v-html="question.answer.text"></p>
              <QuickScopeEditor
                v-else
                v-model="question.newAnswer"
                class="new-answer-editor"
                :placeholder="'activeRFP.q&a.placeholderAnswer' | t"
                :id="question.answer.id"
                :userIsCorporate="userIsCorporate"
                :missing="newAnswerMissing[question.id]"
                :documents="question.answer.attachments.concat(question.newFiles)"
                :buttonDisable="isButtonExecuting"
                @input="$set(newAnswerMissing, question.id, false)"
                @uploadDocument="handleDocumentUpload(question)"
                @submit="waitButtonToEnd(submitAnswer, question)"
                @cancel="cancelUpdate(question)"
                @deleteFile="file => deleteFileFromAnswer(question, file)"
                showQaActions
                is-update
                />
              <div class="document-wrapper" v-if="!question.updateAnswer && question.answer.attachments.length">
                <span class="documents-title">{{ 'activeRFP.q&a.attachedDocuments' | t }}</span><br>
                <FileChip v-for="file in question.answer.attachments" :key="file.id" :file="file" showIcon></FileChip>
              </div>
            </div>
          </div>
        </div>

        <v-btn
          v-if="question.answer"
          fab
          flat
          @click="question.showAnswer = !question.showAnswer"
          class="toggle-btn">
          <v-icon v-if="!question.showAnswer">keyboard_arrow_down</v-icon>
          <v-icon v-else class="hide-answer-btn">keyboard_arrow_up</v-icon>
        </v-btn>

      </div>
    </v-card>

    <div v-if="userIsLawFirm && !disabled" class="new-question-wrapper">
      <v-btn v-if="!showNewQuestionEditor" @click="showNewQuestionEditor = true" class="main elevation-3 add-question-btn">{{ 'activeRFP.q&a.addQuestion' | t }}</v-btn>
      <QuickScopeEditor
        v-if="showNewQuestionEditor"
        v-model="newQuestion.text"
        id="new"
        class="question reset-style"
        :missing="newQuestionMissing"
        :placeholder="'activeRFP.q&a.placeholderQuestion' | t"
        :userIsCorporate="userIsCorporate"
        :documents="newQuestion.newFiles"
        :buttonDisable="isButtonExecuting"
        @input="newQuestionMissing = false"
        @uploadDocument="handleDocumentUpload(newQuestion)"
        @submit="showAddDialog = true"
        @cancel="resetNewQuestionEditor"
        question-texts
        showQaActions />
      <input
        hidden
        ref="fileUpload_new"
        type="file"
        @change="(event) => { onFileChange(newQuestion, event) }"
        accept=".pdf,.docx">
    </div>

    <ConfirmDialog
      v-if="showDeleteDialog"
      :title="'activeRFP.q&a.deleteDialogTitle' | t"
      :text="'activeRFP.q&a.deleteDialogText' | t"

      acceptButtonTextOrKey="buttons.delete"
      cancelButtonTextOrKey="buttons.cancel"

      @close="showDeleteDialog = false; questionToDelete = null"
      @submit="deleteAnswerLocal"
    />
    <ConfirmDialog
      v-if="showAddDialog"
      :title="'activeRFP.q&a.addDialogTitle' | t"
      :text="'activeRFP.q&a.addDialogText' | t"

      acceptButtonTextOrKey="buttons.submit"
      cancelButtonTextOrKey="buttons.cancel"

      @close="showAddDialog = false"
      @submit="waitButtonToEnd(saveNewQuestion)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import QuickScopeEditor from '../../generalComponents/QuickScopeEditor'
import FileChip from '../../generalComponents/FileChip'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'

export default {
  name: 'QAndA',
  props: {
    filter: {
      type: String,
      default: 'all'
    }
  },
  components: {
    ConfirmDialog,
    FileChip,
    QuickScopeEditor
  },
  data () {
    return {
      showEditor: false,
      showNewQuestionEditor: false,
      newQuestion: {
        id: 'new',
        text: '',
        newFiles: []
      },
      showDeleteDialog: false,
      showAddDialog: false,
      questionToDelete: null,
      newQuestionMissing: false,
      newAnswerMissing: [],
      corporateQADeadlinePassed: true,
      interval: null
    }
  },
  created () {
    this.updateCorporateQaFinished()
    this.interval = setInterval(this.updateCorporateQaFinished, 1000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters(['userIsLawFirm', 'userIsCorporate', 'renderLawFirm', 'formatDateTime', 'corporateQandAFinished', 'getValueFromPreviousVersion', 'MAX_UPLOAD_SIZE_IN_BYTE']),
    ...mapState({
      lawFirms: state => state.lawFirms,
      currentRfp: state => state.currentRfp,
      history: state => state.history
    }),

    filteredQuestions () {
      let result
      if (this.filter === 'all') {
        result = [...this.currentRfp.qaQuestions]
      } else if (this.filter === 'answered') {
        result = [...this.currentRfp.qaQuestions].filter(question => !!question.answer)
      } else {
        result = [...this.currentRfp.qaQuestions].filter(question => !question.answer)
      }

      if (this.history.length >= 3 && result.length) {
        const history = this.history.slice(1, this.history.length - 2)
        for (const rfp of history) {
          result.push({
            timestamp: rfp.submission,
            type: 'CHANGE'
          })
        }
      }

      return result.sort((a, b) => {
        /* if (a.answer && !b.answer) {
         return 1
         } else if (!a.answer && b.answer) {
         return -1
         } */

        return moment(a.timestamp).isAfter(moment(b.timestamp)) ? -1 : 1
      })
    },

    canExtendQaDeadline () {
      if (!this.currentRfp.allowQA) {
        return false
      }

      if (moment().isAfter(moment(this.getValueFromPreviousVersion('firstBidDeadline')))) {
        return false
      }

      return moment().isAfter(moment(this.getValueFromPreviousVersion('qaEnd')))
    },

    disabled () {
      if (this.userIsLawFirm) {
        return this.currentRfp.status !== 'ACTIVE_QA'
      } else {
        return this.corporateQADeadlinePassed
      }
    }
  },
  methods: {
    ...mapActions(['addQuestion', 'answerQuestion', 'deleteAnswer', 'uploadQaFile']),
    ...mapMutations(['addQuestionToRfp']),

    updateCorporateQaFinished () {
      this.corporateQADeadlinePassed = this.corporateQandAFinished(this.currentRfp)
    },

    handleDocumentUpload (question) {
      console.log(this.$refs['fileUpload_' + question.id])
      if (Array.isArray(this.$refs['fileUpload_' + question.id])) {
        this.$refs['fileUpload_' + question.id][0].click()
      } else {
        this.$refs['fileUpload_' + question.id].click()
      }
    },

    onFileChange (question, event) {
      const file = event.target.files[0]
      if (file) {
        if (file.size > this.MAX_UPLOAD_SIZE_IN_BYTE) {
          this.$store.state.showFileSizeDialog = true
          return
        }

        question.newFiles.push(file)
      }
    },

    deleteFile (question, file) {
      const index = question.newFiles.indexOf(file)

      if (index > -1) {
        question.newFiles.splice(index, 1)
      }
    },

    deleteFileFromAnswer (question, file) {
      if (question.answer.attachments && question.answer.attachments.includes(file)) {
        question.answer.oldAttachments = question.answer.oldAttachments || []
        question.answer.oldAttachments.push(file)
      }

      const index = question.answer.attachments.indexOf(file)

      if (index > -1) {
        question.answer.attachments.splice(index, 1)
      }
    },

    cancelUpdate (question) {
      question.newAnswer = ''
      question.updateAnswer = false
      this.$set(this.newAnswerMissing, question.id, false)
      question.newFiles = []
      if (question.answer.oldAttachments) {
        question.answer.attachments = question.answer.oldAttachments
      }
    },

    renderLastChange (timestamp) {
      return this.$t('activeRFP.q&a.edited', { time: moment(timestamp).fromNow() })
    },

    resetNewQuestionEditor () {
      this.showNewQuestionEditor = false
      this.newQuestionMissing = false
      this.$nextTick(() => {
        this.newQuestion = {
          id: 'new',
          text: '',
          newFiles: []
        }
      })
    },

    async saveNewQuestion () {
      this.showAddDialog = false
      if (!this.newQuestion.text) {
        this.newQuestionMissing = true
        return
      }

      const newQuestionInput = {
        rfp: {
          id: this.currentRfp.id
        },
        lawFirm: {
          id: this.lawFirms[0].id
        },
        text: this.newQuestion.text
      }

      const question = await this.addQuestion(newQuestionInput)

      if (this.newQuestion.newFiles && this.newQuestion.newFiles.length) {
        const attachmentsPromises = []
        for (let file of this.newQuestion.newFiles) {
          attachmentsPromises.push(this.uploadQaFile({ file, question, type: 'QUESTION' }))
        }

        let attachments = await Promise.all(attachmentsPromises)
        attachments = attachments.filter(file => !!file)
        question.attachments = question.attachments ? question.attachments.concat(attachments) : attachments
      }

      this.resetNewQuestionEditor()
    },

    async submitAnswer (question) {
      if (!question.newAnswer) {
        this.$set(this.newAnswerMissing, question.id, true)
        return
      }

      const answerInput = {
        text: question.newAnswer,
        question: {
          id: question.id
        }
      }

      const oldId = question.answer ? question.answer.id : null
      if (oldId) {
        answerInput.id = oldId
        answerInput.attachments = question.answer.attachments.map(attachment => {
          return {
            id: attachment.id,
            rfp: {
              id: this.currentRfp.id
            }
          }
        })
      }

      const updatedQuestion = await this.answerQuestion(answerInput)
      if (question.newFiles && question.newFiles.length) {
        const attachmentsPromises = []
        for (let file of question.newFiles) {
          attachmentsPromises.push(this.uploadQaFile({ file, question: updatedQuestion, type: 'ANSWER' }))
        }

        let attachments = await Promise.all(attachmentsPromises)
        attachments = attachments.filter(file => !!file)
        updatedQuestion.answer.attachments = updatedQuestion.answer.attachments ? updatedQuestion.answer.attachments.concat(attachments) : attachments
      }
    },

    deleteAnswerLocal () {
      this.showDeleteDialog = false
      this.deleteAnswer(this.questionToDelete.answer)
      this.questionToDelete = null
    }

  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .question-card
    border-radius 10px
    margin-top 20px

  .question-content
    padding 30px 25px 25px 25px
    display grid
    grid-template-columns 65px 1fr 100px
    position relative

  .sub-grid
    display grid
    grid-template-columns 1fr 200px
    grid-row-gap 5px

  .answer-grid
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 90px 1fr
    border-top 1px solid $icon-gray
    padding-top 25px

    .answer-sub-grid
      display grid
      grid-template-columns 1fr 200px
      grid-row-gap 5px

  .question
    overflow-wrap break-word
    grid-column-start 1
    grid-column-end 3
    text-align justify
    :deep(&.reset-style)
      font-size 16px !important
      p
        margin-bottom 5px
      span
        color inherit
        font-size 16px !important
        em, strong
          color inherit !important

  .new-answer-editor
    grid-column-start 1
    grid-column-end 3
    padding 0
    border-radius 0
    box-shadow none !important
    outline none

  .v-avatar
    background-color $maroon50
    color #fff

    &.answer-icon
     margin-left 25px

  .law-firm-name
    margin-right 20px
    font-weight bold
    font-size 14px
    color $gold100

  .timestamp
    color $icon-gray
    font-size 13px

  .answered, .unanswered
    text-align right

  .unanswered
    color $orange
    font-weight bold

  .answered
    color $green
    font-weight bold

  .toggle-btn
    position absolute
    right 10px

  .answer-btn
    border 1px solid $gold100
    border-radius 5px
    color $gold100
    max-width 200px
    text-transform none
    grid-column-start 2
    grid-column-end 3

  .answer-actions
    text-align right

  .edit-answer, .delete-answer
    font-weight bold

    .v-icon
      font-size 14px

    &:hover
      border-radius 5px
      cursor pointer

  .edit-answer
    color $green

    .v-icon
      color $green !important

  .delete-answer
    color $red
    margin-left 20px

    .v-icon
      color $red !important

  .hide-answer-btn
    color $gray70 !important

  .documents-title
    color $icon-gray
    font-size 12px

  .add-question-btn
    display block
    margin 0 auto
    border-radius 5px

  .new-question-wrapper
    margin-top 20px

  .no-result-card
    border-radius 10px
    margin-top 20px
    padding 10px
    text-align center

  .edited-info
    margin-left 20px
    color $icon-gray
</style>
