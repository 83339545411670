<template>
  <v-card class="grid">
    <div>
      <span class="light">{{ 'newRFP.termsAndConditions.liabilityType' | t }}</span>
      <v-select
        label=""
        :class="{missing: missingMandatoryFields.includes('terms/liabilityType')}"
        :value="currentRfp.terms.liabilityType"
        @input="value => { updateCurrentRfpTerms({ field: 'liabilityType', value }); removeMissingMandatoryField('terms/liabilityType'); removeInvalidField('terms/liabilityAmount'); }"
        :items="liabilityTypeOptions"
        :placeholder="'newRFP.termsAndConditions.placeHolders.liabilityType' | t "
        solo
        flat />
    </div>

    <div v-if="currentRfp.terms.liabilityType === 'MINIMUM_AMOUNT'">
      <span class="light">{{ 'newRFP.termsAndConditions.liabilityMinAmount' | t }}</span>
      <NumberField
        label=""
        :class="{missing: missingMandatoryFields.includes('terms/liabilityAmount') || invalidFields.includes('terms/liabilityAmount')}"
        :value="currentRfp.terms.liabilityAmount"
        @input="value => { updateCurrentRfpTerms({ field: 'liabilityAmount', value }); removeMissingMandatoryField('terms/liabilityAmount'); }"
        :prefix="currencySymbol()"
        :invalid="maxAmountInvalid"
        @invalidInput="handleInvalitInput"
        solo
        flat/>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import NumberField from '../../../generalComponents/inputFields/NumberField'

export default {
  name: 'LiabilityPanel',
  components: { NumberField },
  data () {
    return {
      maxAmountInvalid: false
    }
  },
  computed: {
    ...mapGetters(['liabilityTypeOptions', 'currencySymbol']),
    ...mapState({
      currentRfp: state => state.currentRfp,
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields
    })
  },
  methods: {
    ...mapMutations(['updateCurrentRfpTerms', 'removeMissingMandatoryField', 'addInvalidField', 'removeInvalidField']),

    handleInvalitInput (isInvalid) {
      if (isInvalid) {
        this.addInvalidField('terms/liabilityAmount')
      } else {
        this.removeInvalidField('terms/liabilityAmount')
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap 10px;
  }

  .v-card__text
    span
      color rgb(158, 158, 158)
      padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important
</style>
