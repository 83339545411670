<template>
  <div>
    <h2>{{ $t('settings.organizationConfig.title') }}</h2>
    <v-card class="profile">
      <MultiLineField
        :translatedName="$t('settings.organizationConfig.diversity')"
        :placeholder="$t('newRFP.feeDefinition.teamDiversity')"
        :class="{tooltipSettings: !userIsAdmin}"
        v-model="corporateConfig.defaultTerms.teamDiversity"
      ></MultiLineField>

      <div class="action">
        <v-btn class="main" @click="updateProfile">{{ 'settings.organizationConfig.save' | t }}</v-btn>
      </div>
    </v-card>

    <v-snackbar
      v-model="savedSuccessfully">
      <p style="color: white; margin-bottom: 0;">{{ $t('settings.organizationConfig.successfullySaved') }}</p>
      <v-btn
        icon
        @click="savedSuccessfully = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import MultiLineField from '@/views/generalComponents/inputFields/MultiLineField'

export default {
  name: 'OrganizationConfig',
  components: { MultiLineField },
  data () {
    return {
      savedSuccessfully: false,
      corporateConfig: {
        defaultTerms: {
          teamDiversity: ''
        }
      }
    }
  },
  async created () {
    console.log(this.corporateConfig = await this.loadOrganizationConfig())
  },
  computed: {
    ...mapState({
      userData: state => state.userData,
      allUsers: state => state.admin.allUsers
    }),
    ...mapGetters(['userIsAdmin', 'userIsCorporate', 'lawfirmUserPositionOptions', 'corporateUserPositionOptions'])
  },
  methods: {
    ...mapActions(['updateCorporateConfig', 'loadOrganizationConfig']),

    async updateProfile () {
      this.updateCorporateConfig({
        corporateConfig: this.corporateConfig
      })
      this.savedSuccessfully = true
      setTimeout(() => {
        this.savedSuccessfully = false
      }, 4000)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"
  .tooltipSettings :deep(.v-icon)
    color $icon-gray !important
    font-size 15px !important

  .profile
    border-radius 10px
    padding 20px
    margin 20px 0px
    display grid
    grid-template-columns 1fr
    grid-row-gap 10px

  .action
    text-align right

  .fade-enter-active,
  .fade-leave-active
    transition opacity .5s

  .fade-enter,
  .fade-leave-to
    opacity 0

  :deep(.v-input__slot)
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  :deep(.v-text-field__details)
    display none
</style>
