<template>
  <v-dialog
    :value="visible"
    @input="value => { $emit('visibilityChange', value); }"
    width="700">
    <v-card>
      <v-card-title
        class="title"
        primary-title
      >
        <h2>{{ 'changeMfaMethodDialog.title' | t }}</h2>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text style="padding-right: 90px; padding-left: 90px;">
        <SelectField
          :translatedName="'changeMfaMethodDialog.mfaMethod' | t "
          :options="[{ key: 'EMAIL', translatedLabel: 'Email' }, { key: 'AUTHENTICATOR', translatedLabel: 'Authenticator' }]"
          v-model="localMfaMethod"
        />
        <div v-show="localMfaMethod === 'AUTHENTICATOR' && mfaMethod !== 'AUTHENTICATOR'" style="margin-top: 16px">
          <h3 style="font-size: 16px; font-weight: normal;">{{ 'changeMfaMethodDialog.authenticatorInstructions' | t }}</h3>
          <v-img :src="generateAuthQRCode()" style="margin-top: 16px; margin-left: auto; margin-right: auto;" width="200" />
        </div>
        <div v-if="newMfaMethodVerificationRequired" style="margin-top: 16px">
          <h3 style="font-size: 16px; font-weight: normal;">{{ (localMfaMethod === 'EMAIL' ? 'login.mfaCodeEmail' : 'login.mfaCodeAuthenticator') | t }}</h3>
          <SingleLineField
            v-model="code"
            @input="wrongCode = false"
            style="height: 70px; margin-top: 10px"
          />
          <p v-if="wrongCode !== false" class="alertText" style="padding-bottom: 16px">{{ "login.mfaAlert" | t }}</p>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          flat
          style="align-self: start; border-radius: 5px;"
          @click="$emit('visibilityChange', false)">{{ 'buttons.cancel' | t }}</v-btn>
        <v-btn
          v-if="!newMfaMethodVerificationRequired"
          flat
          @click="saveNewMfaMethod"
          class="main elevation-3"
          :disabled="localMfaMethod === mfaMethod"
          style="margin-right: 82px; margin-bottom: 30px;border-radius: 5px">
          {{ 'changeMfaMethodDialog.save' | t }}
        </v-btn>
        <v-btn
          v-else
          flat
          @click="verifyNewMfaMethod"
          class="main elevation-3"
          :disabled="localMfaMethod === mfaMethod"
          style="margin-right: 82px; margin-bottom: 30px;border-radius: 5px">
          {{ 'buttons.submit' | t}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import qrcode from 'qrcode-generator'
import { mapActions, mapMutations, mapState } from 'vuex'
import SelectField from '../..//generalComponents/inputFields/SelectField'
import SingleLineField from '@/views/generalComponents/inputFields/SingleLineField'

export default {
  name: 'ChangeMfaMethodDialog',
  components: { SingleLineField, SelectField },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localMfaMethod: null,
      newMfaMethodVerificationRequired: false,
      secret: null,
      code: null,
      wrongCode: false
    }
  },
  created () {
    this.localMfaMethod = this.mfaMethod
    this.secret = this.generateString(8)
  },
  computed: {
    ...mapState({
      mfaMethod: state => state.mfaMethod,
      userData: state => state.userData
    })
  },
  methods: {
    ...mapActions(['updatePassword', 'updateMfaMethod', 'verifyMfa']),
    ...mapMutations(['setMfaMethod']),

    async saveNewMfaMethod () {
      const mfaMethodUpdatedSuccessfully = await this.updateMfaMethod({ mfaMethod: this.localMfaMethod, secret: this.localMfaMethod === 'EMAIL' ? null : this.secret })
      if (mfaMethodUpdatedSuccessfully) {
        this.newMfaMethodVerificationRequired = true
      } else {
        // todo error handling
      }
    },

    async verifyNewMfaMethod () {
      const mfaVerificationSuccessful = await this.verifyMfa({ code: this.code, tempMfa: true })
      if (mfaVerificationSuccessful) {
        this.newMfaMethodVerificationRequired = false
        this.code = null
        this.secret = this.generateString(8)
        this.setMfaMethod(this.localMfaMethod)
        this.$emit('visibilityChange', false)
      } else {
        this.wrongCode = true
      }
    },

    generateAuthQRCode () {
      const code = qrcode(0, 'M')
      code.addData(`otpauth://totp/lintum:${this.userData.username}?secret=${this.secret}`)
      code.make()
      return code.createDataURL(6, 0)
    },

    generateString (length) {
      const characters = 'abcdefghijklmnopqrstuvwxyz'

      let result = ''
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../../../assets/css/variables.styl"

.v-card
  border-radius 10px 0 0 10px

:deep(.v-input__slot)
  border 1px solid rgb(158, 158, 158)
  border-radius 5px !important

:deep(.v-dialog)
  overflow auto
  border-radius 10px

:deep(.v-dialog::-webkit-scrollbar) {
  border-radius 0 10px 10px 0
  background-color #ffffff
  width 10px
}
:deep(.v-dialog::-webkit-scrollbar-thumb) {
  background-color #aaa
  border-radius 5px
}
:deep(.v-dialog::-webkit-scrollbar-button) {
  display none
}

:deep(.v-text-field__details)
  display none

:deep(.v-input__slot)
  margin-bottom 0px !important

:deep(.v-icon)
  font-size 20px

.inputLabel
  margin-bottom 5px

.alertText
  justify-self end
  margin-bottom 0
  padding-top 4px
  color: $red
</style>
