<template>
  <div class="update-grid" :style="userIsLawFirm ? '' : 'grid-template-columns: 1fr 1fr 1fr !important;'">
    <div :style="userIsLawFirm ? '' : 'grid-column: 1 / 3'">
      <span class="light mandatory">{{ 'activeRFP.team.name' | t }}</span>
      <v-select
        :style="userIsLawFirm ? '' : 'max-width: 400px'"
        label=""
        :disabled="isUpdate"
        v-model="value.user"
        :item-text="formatUser"
        @input="gotToSettingsPageIfSelected"
        return-object
        :items="options"
        solo
        flat />
    </div>

    <!-- div>
      <span class="light mandatory">{{ 'activeRFP.team.permissionLevel' | t }}</span>
      <v-select
        label=""
        v-model="value.permissionLevel"
        :items="['READ', 'WRITE', 'ADMIN']"
        solo
        flat />
    </div-->

    <div v-if="userIsLawFirm">
      <span class="light">{{ 'activeRFP.team.hideInProposal' | t }}</span>
      <v-checkbox
        style="margin-top: 8px"
        :value="value.hideInProposal"
        v-model="value.hideInProposal"
        solo
        flat />
    </div>

    <div style="grid-column-start: 3">
      <span>&nbsp;</span>
      <div class="actions">
        <v-btn flat @click="$emit('cancel')">{{ 'buttons.cancel' | t }}</v-btn>

        <AwaitButton
          emit-click=""
          :custom-loading="buttonDisable"
          @click="$emit('save')"
          class="main" >
          {{ 'activeRFP.team.saveUser' | t }}
        </AwaitButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AwaitButton from '../../generalComponents/AwaitButton'

export default {
  name: 'TeamInputForm',
  components: { AwaitButton },
  props: {
    value: {
      type: Object,
      required: true
    },

    isUpdate: Boolean,
    options: Array,
    // prop that controls the button for asyn execution of the Submit action
    buttonDisable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['userIsLawFirm', 'formatUser'])
  },
  methods: {
    gotToSettingsPageIfSelected (selectedUser) {
      if (selectedUser.id === 'NEW_USER') {
        this.$router.push({ name: 'userManagement' })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  .update-grid
    padding-top 10px !important
    grid-column-start 1
    grid-column-end 7
    display grid
    grid-template-columns 1fr 1fr 250px !important
    grid-column-gap 20px

    strong,
    span
      line-height initial

  .actions
    text-align right

    button
      border-radius 5px
</style>
