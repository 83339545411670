import moment from 'moment'

export function formattedDurationUntil (momentTimestamp, { hideSecondsUntil3m = false } = {}) {
  const duration = moment.duration(momentTimestamp.diff(moment()))
  const day = duration.days() > 0 ? Math.floor(duration.asDays()) : 0
  const hour = duration.hours() > 0 ? duration.hours() : 0
  const minute = duration.minutes() > 0 ? duration.minutes() : 0
  const second = duration.seconds() > 0 ? duration.seconds() : 0
  const durationValueArr = [
    { value: minute, key: 'm' }
  ]
  if ((day !== 0 && hour !== 0) || (day !== 0 && hour === 0)) {
    durationValueArr.splice(0, 0, { value: hour, key: 'h' })
    durationValueArr.splice(0, 0, { value: day, key: 'd' })
  } else if (day === 0 && hour !== 0) {
    durationValueArr.splice(0, 0, { value: hour, key: 'h' })
  } else {
    if (!hideSecondsUntil3m || minute < 3) {
      durationValueArr.push({ value: second, key: 's' })
    }
  }
  return durationValueArr.map(({ key, value }) => `${value}${key}`).join(' ')
}
