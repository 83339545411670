import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import i18next from './langs/i18next'
import VueI18Next from '@panter/vue-i18next'

Vue.config.productionTip = false

Vue.use(VueI18Next)
const i18n = new VueI18Next(i18next)

// General directive created to change the behaviour of the dialog click
Vue.directive('click-outside-dialog', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Spreading the names of the classes of the clicked compinent into a new array
      const classListArr = [...event.target.classList]
      /* Name of the different components classes that are inside of the dialogs but because of the vuetify nature they are
      technicaly renderd outside of the dialog so are detected as an outside click (Selectables and Comboboxes) */
      const exclusions = [
        'v-input--selection-controls__ripple',
        'v-list__tile__content',
        'v-list__tile__title',
        'v-dialog',
        'v-list',
        'theme--light',
        'v-list__tile__action'
      ]
      let containsAtLeastOneClass = classListArr.some(className => exclusions.includes(className))
      // here it checks  if the click was outside the element and his childrens
      if (!(el === event.target || el.contains(event.target) || exclusions.includes(event.target.className) || containsAtLeastOneClass)) {
        // and if was outside, it'll call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('mousedown', el.clickOutsideEvent)
  },

  unbind: function (el) {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent)
  }
})

let v
Vue.filter('t', value => {
  if (!value) return ''
  return v ? v.$t(value) : i18n.t(value)
})
// Method created for all components that will wait for a function to end it's execution mainly used for waitin async buttons.
Vue.mixin({
  data () {
    return {
      isButtonExecuting: false
    }
  },
  methods: {
    async waitButtonToEnd (_callBack, events, otherAttr) {
      this.isButtonExecuting = true
      try {
        await _callBack(events, otherAttr)
      } finally {
        this.isButtonExecuting = false
      }
    }
  }
})

v = new Vue({
  router,
  store,
  i18n: i18n,
  render: h => h(App)
}).$mount('#app')
export const vue = v
