<template>
  <v-footer class="elevation-15" fixed>
    <div class="footer-content">
      <!-- div v-if="!isFeedback" class="timestamps" :style="noNonDraftProposal ? 'grid-column-start: 3;' : ''">
        <span>{{ $t('activeRFP.proposal.lastProposalUpdate') }}</span>
        <span>{{ currentRfpLawFirmRelation.proposalUpdate ? formatTimestamp(currentRfpLawFirmRelation.proposalUpdate, 'DD.MM.YYYY HH:mm') : '-' }}</span>
        <span>{{ $t('activeRFP.proposal.lastProposalSubmission') }}</span>
        <span>{{ currentRfpLawFirmRelation.proposalSubmission ? formatTimestamp(currentRfpLawFirmRelation.proposalSubmission, 'DD.MM.YYYY HH:mm') : '-' }}</span>
      </div -->
      <v-btn v-if="currentRfpLawFirmRelation && currentRfpLawFirmRelation.requiresResubmission" :style="`grid-column-start: ${!(!isFeedback && !updateMode) ? 3 : 4}`" flat @click="resubmitProposal()">
        <span>{{ $t('activeRFP.proposal.resubmit') }}</span>
      </v-btn>
      <v-btn v-if="!isFeedback && !noNonDraftProposal" :style="`grid-column-start: ${!(!isFeedback && !updateMode) ? 4 : 5}`" flat @click="toggleUpdateMode()">
        <span>{{ updateMode ? $t('activeRFP.proposal.showSubmittedProposal') : $t('activeRFP.proposal.updateProposal') }}</span>
      </v-btn>
      <v-btn style="grid-column-start: 5;" flat @click="checkMissingFields" v-if="!(!isFeedback && !updateMode)">
        <span style="white-space: break-spaces;  width: 80%;" class="alertLabel" v-if="isFeedback && missingMandatoryFields.includes('feedback')">{{ 'activeRFP.feedback.missingRaitings' | t}}</span>
        <span style="white-space: break-spaces;  width: 80%;" v-else>{{ isFeedback ? 'activeRFP.feedback.submitFeedbackLabel' : 'activeRFP.proposal.submit' | t }} </span>

        <v-icon>ic-chevron-right</v-icon>
      </v-btn>
    </div>
    <ConfirmDialog
      v-if="showDistributeDialog"
      :text=" !isFeedback ? $t('activeRFP.proposal.distributeText', { corporate: currentRfp.corporate.name }) : $t('activeRFP.feedback.feedbackDistributeText', {lawfirm: lawFirmName })"
      :title="!isFeedback ? 'activeRFP.proposal.distributeTitle' : 'activeRFP.feedback.feedbackTitle' | t"
      :feedbackDialog="isFeedback"
      :buttonDisable="isButtonExecuting"
      @close="showDistributeDialog = false"
      @submit="waitButtonToEnd(value => submit(value))"
      acceptButtonTextOrKey="activeRFP.proposal.distribute"
      cancelButtonTextOrKey="buttons.cancel" />
  </v-footer>
</template>

<script>
import bus from '../../../bus'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
  name: 'ProposalFooter',
  components: { ConfirmDialog },
  props: {
    // Prop that help the component diferenciate if it is used for the proposal or the feedback
    isFeedback: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      showDistributeDialog: false,
      firstBid: null,
      noNonDraftProposal: false
    }
  },
  computed: {
    ...mapGetters(['currentRfpLawFirmRelation', 'formatTimestamp']),
    ...mapState({
      currentRfp: state => state.currentRfp,
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      updateMode: state => state.draftProposal.updateMode
    }),

    lawFirmName () {
      if (this.isFeedback) {
        return this.currentRfp.lawFirmRelations[this.currentRfp.lawFirmRelations.findIndex(lawFirmRelation => lawFirmRelation.status === 'COMPLETED_WON')].lawFirm.name
      } else {
        return false
      }
    }

  },
  created () {
    if (!this.currentRfpLawFirmRelation.proposal.filter(p => p.name !== 'QA' && p.name !== 'INTERVIEW').some(proposal => !proposal.draft)) {
      this.noNonDraftProposal = true
      this.setDraftProposalItem({ key: 'updateMode', value: true })
    }

    bus.$on('submitBidReturn', (bid) => {
      console.log('bid to place', bid)
      if (bid) {
        this.firstBid = bid
        this.showDistributeDialog = true
      }
    })
  },
  methods: {
    ...mapActions(['submitProposal', 'loadProposal', 'refreshDraftProposal', 'submitFeedback', 'submitFirstBid', 'resubmitProposal']),
    ...mapMutations(['addMissingMandatoryField', 'setDraftProposalItem', 'setUsersLastBid']),

    toggleUpdateMode () {
      if (this.updateMode) {
        this.setUsersLastBid(this.currentRfpLawFirmRelation.firstBidToBePlaced)
      } else {
        this.setUsersLastBid(this.currentRfpLawFirmRelation.firstBidToBePlacedDraft)
      }

      this.setDraftProposalItem({ key: 'updateMode', value: !this.updateMode })
    },

    checkMissingFields () {
      if (!this.isFeedback) {
        this.checkProposalMissingFields()
      } else {
        this.checkFeedbackMissingFields()
      }
    },

    checkProposalMissingFields () {
      bus.$emit('triggerSubmitBid')
      /*
      const filledCategories = this.currentRfpLawFirmRelation.proposal.map(category => category.name)

      let missing = false
      this.currentRfp.terms.qualityScoreCriteria.forEach(criteria => {
        if (!filledCategories.includes(criteria)) {
          this.addMissingMandatoryField(criteria)
          missing = true
        }
      })

      // check if all users have seniority level
      if (this.currentRfp.terms.qualityScoreCriteria.includes('TEAM')) {
        const team = this.currentRfpLawFirmRelation.proposal.find(item => item.name === 'TEAM')
        if (team) {
          for (const teamMember of team.subItems) {
            if (!teamMember.extendedFields || !teamMember.extendedFields.seniorityLevel) {
              this.addMissingMandatoryField('TEAM_' + teamMember.id + '_SENIORITY_LEVEL')
              missing = true
            }

            if (this.currentRfp.terms.isCVMandatory &&
              (!teamMember.extendedFields || !teamMember.extendedFields.hasDocumentUpload
                ? !teamMember.extendedFields || !teamMember.extendedFields.cvLink
                : !teamMember.attachments || !teamMember.attachments.length)) {
              this.addMissingMandatoryField('TEAM_' + teamMember.id + '_CV')
              missing = true
            }
          }
        }
      }

      if (missing) {
        return
      }

      this.showDistributeDialog = true */
    },

    checkFeedbackMissingFields () {
      let feedbackRelation = this.currentRfp.lawFirmRelations.filter(lawFirmRelation => lawFirmRelation.status === 'COMPLETED_WON')[0]
      if (feedbackRelation.feedbackRatings.length > 0) {
        this.showDistributeDialog = true
      } else {
        this.addMissingMandatoryField('feedback')
      }
    },

    async submit (discloseFeedbackToLawfirm) {
      if (!this.isFeedback) {
        await this.submitFirstBid({ bid: this.firstBid })
        await this.submitProposal()
        await this.loadProposal()
        await this.refreshDraftProposal()
        this.noNonDraftProposal = false
        this.setDraftProposalItem({ key: 'updateMode', value: false })
      } else {
        this.submitFeedback({ discloseFeedback: !discloseFeedbackToLawfirm })
        this.$emit('submitFeedback')
      }
      this.showDistributeDialog = false
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .timestamps
    display grid
    grid-template-rows 1fr 1fr
    grid-template-columns 1fr 1fr
    grid-column-gap 10px
    align-items center
    grid-column 2

  footer
    background #ffffff !important
    height 74px !important
    // width 100vw
    // margin-left calc(-0.5 * calc(100vw - 1200px))

  .footer-content
    width $max-width
    margin 0 auto
    background #ffffff
    display grid
    height 74px !important
    grid-template-columns 1fr auto 220px 220px 220px

  .v-btn
    height 100%
    margin 0
    font-weight normal !important
    border-left 1px solid lightgray
    .v-icon,
    .v-icon:before
      color $dark-gray !important

  .discloseFeedback
    grid-column-start 2
    display inline-flex
    align-items center
    margin-right 10px

  :deep(.v-messages)
    display none

  // .submitLabel
  //   display grid
  //   grid-template-rows auto auto
  //   text-align left

  .alertLabel
    color: $red
    font-weight bold

  .v-input--selection-controls
    margin 0px
    padding 0
    >>> .v-label
      color $dark-gray
      font-size 12px
    .v-input__control
    >>>  .v-input__slot
        margin-bottom 0

</style>
