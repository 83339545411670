<template>
  <v-card @click="onClick" :class="{selected: selected, small: small, tooltip: tooltip, paddings: paddings, disabled: disabled && !['coiCheckII', 'activeRfp', 'summaryCorporate'].includes($route.name)}">
    <div v-if="showCheckIcon" style="display: grid; grid-template-columns: max-content 1fr; grid-column-gap: 10px; align-items: center;">
      <v-checkbox
        class="check-box"
        @click.stop="disabled ? '' : $emit('selected')"
        :disabled="disabled"
        :input-value="selected"
        color="rgba(255, 0, 0, 0.5)"
        on-icon="check_circle"
        off-icon="panorama_fish_eye">
        <div style="display: inline-flex" slot="label">
          <h3>{{title}}</h3>
          <TooltipHolder
            v-if="hasTitleTooltip && !disabled"
            class="info-icon"
            :spanId="'liabilityBox1'"
            :tooltipText="titleTooltipText"
            :width="200"
            :moveTop="60"
            :icon="'info'"
            right
            summaryTooltip />
        </div>
      </v-checkbox>
      <!-- <v-icon v-if="selected">check_circle</v-icon>
      <v-icon v-else>panorama_fish_eye</v-icon> -->

      <slot name="header-content"></slot>
      <v-btn
        v-if="showFileIcon"
        flat icon fab
        style="position: absolute; right: 20px; top: 17px;"
        small
        @click.stop="$emit('fileIconClicked')">
        <v-icon>attach_file</v-icon>
      </v-btn>
      <v-btn
          v-if="showDeleteIcon"
          flat icon fab
          style="position: absolute; right: 20px; top: 17px;"
          small
          @click.stop="$emit('deleteIconClicked')">
        <v-icon>delete</v-icon>
      </v-btn>
    </div>
    <h3 v-else>
      {{title}}
      <TooltipHolder
        v-if="hasTooltip"
        class="info-icon"
        style="margin-bottom: 0; font-size: 15px; font-weight: normal; vertical-align: top"
        :tooltipText="tooltipText"
        :spanId="title"
        :width="200"
        :moveTop="60"
        :icon="'info'"
        right
        summaryTooltip />

    </h3>
    <span class="tooltipText" v-if="tooltip">{{ 'newRFP.feeDefinition.toolTipDisplay' | t }}</span>
    <div v-if="!small" style="height: 95px; margin-bottom: 36px">
      <slot />
    </div>
    <slot v-else/>

    <v-btn v-if="!small && !hideSelect" flat @click="$emit('selected')" :disabled="selected"><h4>{{ selected ? 'Selected' : 'Select' }}</h4></v-btn>
  </v-card>
</template>

<script>
import TooltipHolder from '../generalComponents/TooltipHolder'

export default {
  name: 'SelectableCard',
  components: {
    TooltipHolder
  },
  props: {

    // the cards title
    title: {
      type: String,
      default: '',
      required: false
    },

    // the text shown in the card
    // only shown when this is not a small card
    text: {
      type: String,
      default: '',
      required: false
    },

    // Whether this card is selected
    // Changes the color scheme and enables the scope
    selected: {
      type: Boolean,
      default: false,
      required: false
    },

    // Changes the component to a smaller card
    small: {
      type: Boolean,
      default: false,
      required: false
    },

    // shows the fee substructure radio
    handleFeeSubStructure: {
      type: Boolean,
      default: false,
      required: false
    },

    tooltip: {
      type: Boolean,
      default: false,
      required: false
    },

    hideSelect: {
      type: Boolean,
      default: false,
      required: false
    },

    showCheckIcon: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false,
      required: false
    },

    paddings: {
      type: Boolean,
      default: false,
      required: false
    },

    hasTooltip: {
      type: Boolean,
      default: false,
      required: false
    },

    tooltipText: {
      type: String,
      default: '',
      required: false
    },

    hasTitleTooltip: {
      type: Boolean,
      default: false,
      required: false
    },

    titleTooltipText: {
      type: String,
      default: '',
      required: false
    },

    showFileIcon: {
      type: Boolean,
      default: false,
      required: false
    },
    showDeleteIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('selected')
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .v-card
    padding 20px 10px !important
    border-radius 10px

    h3
      padding 0 16px

    p
      padding 16px
      margin-top 10px
      line-height 1.5em

    &.paddings
      padding 30px 10px !important

  .v-btn
    margin 0
    font-size 14px

  .check-box
    margin 0 0 0 15px
    h3
      padding 0px
      line-height 24px

  .v-card
    >>> .v-icon
      color $maroon100 !important

  .v-card.selected
    color white
    background $maroon100

    &.disabled
      background #837c7d
    >>> .v-icon
      color white !important
    >>> .v-label
      color white
      display block

    h3, h4, p
      color white !important
    .v-btn
      color white !important
</style>
