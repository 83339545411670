<template>
  <div class="main-area">
    <template v-if="userIsLawFirm && currentRfpLawFirmRelation.requestedLawyers && currentRfpLawFirmRelation.requestedLawyers.length">
      <h2>{{ 'dashboard.createNewRfp.requestedLawyers' | t }}</h2>
      <v-card
        style="display: grid; grid-template-columns: 1fr; padding: 23px 130px">
        <strong
          v-for="requestedLawyer in currentRfpLawFirmRelation.requestedLawyers"
          :key="requestedLawyer.id">{{ requestedLawyer.invitePending ? requestedLawyer.email + ' ' + $t('dashboard.createNewRfp.invitePendingAppendix')  : requestedLawyer.fullName }}</strong>
      </v-card>

      <h2 style="margin-top: 10px">{{ 'activeRFP.linkTabs.projectTeam' | t }}</h2>
    </template>
    <v-card class="user-grid" v-for="user in users" :key="user.id">
      <template v-if="!user.update">
        <v-icon class="member-icon">ic-user</v-icon>
        <strong style="grid-column: 2 / 5">{{ user.user.invitePending ? user.user.email + ' ' + $t('dashboard.createNewRfp.invitePendingAppendix')  : formatUser(user.user) }}</strong>
        <v-btn v-if="userIsLawFirm" icon @click="updateUser(user)"><v-icon>ic-edit</v-icon></v-btn>
        <v-btn :disabled="user.user.id === userId" style="grid-column-start: 6;" icon @click="userToDelete = user.user; showDeleteDialog = true"><v-icon>ic-trashcan</v-icon></v-btn>
      </template>
      <TeamInputForm
        v-else
        is-update
        :value="user.newValue"
        :options="allUsers"
        @cancel="cancelUpdate(user)"
        @save="saveUpdate(user)" />
    </v-card>
    <v-card v-if="showNewUserDialog" class="user-grid">
      <TeamInputForm
        :value="newUser"
        :buttonDisable="isButtonExecuting"
        :options="options"
        @cancel="cancelNew"
        @save="waitButtonToEnd(save, newUser)" />
    </v-card>
    <div v-else style="text-align: center">
      <v-btn v-if="options.length" :disabled="!isRfpFieldEditable() && !userIsLawFirm" class="main" @click="showNewUserDialog = true">{{ 'activeRFP.team.addUser' | t }}</v-btn>
      <div v-else><h3>{{ 'activeRFP.team.allUsersArePart' | t }}</h3></div>
    </div>

    <ConfirmDialog
      v-if="showDeleteDialog"
      :text="$t('activeRFP.team.removeTeamMemberText', { name: userToDelete.fullName })"
      :title="'activeRFP.team.removeTeamMemberTitle'  | t"
      @close="showDeleteDialog = false"
      @submit="removeRfpTeamMember(userToDelete); showDeleteDialog = false"
      acceptButtonTextOrKey="buttons.delete"
      cancelButtonTextOrKey="buttons.cancel"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TeamInputForm from './TeamInputForm'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'

export default {
  name: 'Team',
  props: {
    userDidNotEnterInitialBid: {
      default: false,
      required: false
    }
  },
  components: {
    ConfirmDialog,
    TeamInputForm
  },
  data () {
    return {
      users: [],
      newUser: {
        user: null,
        permissionLevel: '',
        hideInProposal: false
      },
      showNewUserDialog: false,
      userToDelete: null,
      showDeleteDialog: false
    }
  },
  created () {
    this.users = [...this.currentValue]
  },
  computed: {
    ...mapGetters(['userId', 'userIsLawFirm', 'userIsCorporate', 'userIsAdmin', 'userIsOrganizationAdmin', 'currentRfpLawFirmRelation', 'formatUser', 'isRfpFieldEditable']),
    ...mapState({
      currentRfp: state => state.currentRfp,
      allUsers: state => state.admin.allUsers
    }),

    currentValue () {
      return this.currentRfp.team.map(entry => {
        return {
          permissionLevel: entry.level,
          hideInProposal: entry.hideInProposal,
          user: this.allUsers.find(user => user.id === entry.lintumUser.id)
        }
      })
    },

    options () {
      const usedIds = this.currentRfp.team.map(teamMember => teamMember.lintumUser.id)
      const result = [...this.allUsers.filter(user => !usedIds.includes(user.id) && user.active)]

      if (this.userIsAdmin || this.userIsOrganizationAdmin) {
        result.push({
          id: 'NEW_USER',
          fullName: this.$t('activeRFP.team.addNewUser')
        })
      }

      return result
    }
  },
  watch: {
    currentValue () {
      this.users = [...this.currentValue]
    }
  },
  methods: {
    ...mapActions(['saveTeamMember', 'removeRfpTeamMember']),

    updateUser (user) {
      this.$set(user, 'update', true)
      this.$set(user, 'newValue', {
        user: user.user,
        permissionLevel: user.permissionLevel,
        hideInProposal: user.hideInProposal
      })
    },

    cancelUpdate (user) {
      user.update = false
    },

    cancelNew () {
      this.newUser = {
        name: '',
        permissionLevel: '',
        hideInProposal: false
      }
      this.showNewUserDialog = false
    },

    async save (user) {
      // todo remove static admin assignment
      await this.saveTeamMember({ userId: user.user.id, permissionLevel: 'ADMIN' || user.permissionLevel, hideInProposal: user.hideInProposal })
      this.cancelNew()
    },

    saveUpdate (user) {
      // todo remove static admin assignment
      this.saveTeamMember({ userId: user.user.id, permissionLevel: 'ADMIN' || user.newValue.permissionLevel, hideInProposal: user.newValue.hideInProposal, isUpdate: true })
      user.permissionLevel = user.newValue.permissionLevel
      user.hideInProposal = user.newValue.hideInProposal
      this.cancelUpdate(user)
    }

  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .main-area
    padding-top 0.5em
    display grid
    grid-template-columns 1fr
    grid-column-gap 20px

  :deep(.v-card)
    padding 50px
    border-radius 10px

  :deep(h2)
    margin-bottom 5px

  .main-grid
    display grid
    grid-template-columns 1fr
    grid-row-gap 30px

  :deep(.v-input__slot)
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  :deep(.v-input--checkbox .v-input__slot)
    border none

  .user-grid
    display grid
    grid-template-columns 80px 200px 200px 1fr 40px 40px
    padding-top 10px !important
    padding-bottom 10px !important

    strong,
    span
      line-height 48px

  .update-grid
    padding-top 10px !important
    grid-column-start 1
    grid-column-end 7
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-column-gap 20px

    strong,
    span
      line-height initial

  .v-card:not(:last-child)
    margin-bottom 10px

  .v-icon:before
    font-size 15px

  .member-icon:before
    font-size 25px

  :deep(.v-messages)
    display none
</style>
