<template>
  <div v-if="showPanelSummary">
    <template v-if="!panel.isList">
      <div style="position: relative; display: grid; grid-template-columns: max-content 1fr; align-items: center;">
        <p v-if="!readonly" class="not-applicable-checkbox">
          <v-checkbox
            :input-value="isApplicable"
            :disabled="disabled || !isInApplicableSection"
            @change="updateNotApplicableLocal"
            hide-details
            style="display: inline"></v-checkbox></p>
        <h2
          v-if="panel.translatedLabel "
          style="margin-top: 10px; margin-bottom: 0.5em; display: inline-block"
          :style="readonly ? 'font-size: 17px;' : ''">{{ panel.translatedLabel }}</h2>
      </div>
      <v-card class="panel">
        <div
          v-for="row in panel.rows"
          :key="row.id"
          class="row"
          :class="{
            row1: row.fields.length === 1,
            row2: row.fields.length === 2,
            row3: row.fields.length >= 3
          }">
          <DynamicField
            v-for="field in row.fields"
            :key="field.key"
            :field="field"
            :readonly="readonly"
            :invalid="fieldIsInvalid(field)"
            @invalidInput="invalidInput(field)"
            @input="value => updateFieldLocal({ field, value })"
            :value="getValueLocal(field)"
            :disabled="isDisabled(field)" />
        </div>
      </v-card>
    </template>
    <template v-else>
      <div style="display: grid; grid-template-columns: 1fr 200px; margin-top: 10px; margin-bottom: 0.5em">
        <div style="position: relative; display: grid; grid-template-columns: max-content 1fr; align-items: center;">
          <p v-if="!readonly" class="not-applicable-checkbox">
            <v-checkbox
              :input-value="isApplicable"
              :disabled="disabled || !isInApplicableSection"
              @change="updateNotApplicableLocal"
              hide-details
              style="display: inline"></v-checkbox></p>
          <h2
            v-if="panel.translatedLabel "
            style="display: inline-block; line-height: 48px"
            :style="readonly ? 'font-size: 17px;' : ''">{{ panel.translatedLabel }}</h2>
        </div>
        <v-btn
          v-if="!readonly"
          :disabled="disabled || !isInApplicableSection || !isApplicable"
          flat
          @click="addListItem"
          style="grid-column-start: 2; margin-top: 8px; margin-bottom: 4px," class="add-list-item-btn">
          <v-icon>ic-plus-circle</v-icon>&nbsp;{{ $t('newRFP.scopeDefinition.addListItem', { itemName: panel.translatedItemName }) }}
        </v-btn>
      </div>

      <v-card
        v-if="(!listItems || !listItems.length) && !readonly"
        class="add-item-card">
        <v-btn
          :disabled="disabled || !isInApplicableSection || !isApplicable"
          flat
          @click="addListItem"
          class="add-list-item-btn">
          <v-icon>ic-plus-circle</v-icon>&nbsp;{{ $t('newRFP.scopeDefinition.addListItem', { itemName: panel.translatedItemName }) }}
        </v-btn>
      </v-card>

      <v-card
        v-for="(listItem) in listItems"
        :key="listItem.id"
        class="panel"
        :class="{'list-summary-panel': readonly}">
        <v-btn
          v-if="!readonly"
          flat icon
          :disabled="disabled || !isInApplicableSection || !isApplicable"
          @click="removeListItem(listItem.id)"
          class="remove-list-item-btn">
          <v-icon>ic-trashcan</v-icon>
        </v-btn>
        <div
          v-for="row in panel.rows"
          :key="row.id"
          class="row"
          :class="{
            row1: row.fields.length === 1,
            row2: row.fields.length === 2,
            row3: row.fields.length >= 3
          }">
          <DynamicField
            v-for="field in row.fields"
            :key="field.key"
            :field="field"
            :readonly="readonly"
            :invalid="fieldIsInvalid(field, listItem)"
            @invalidInput="invalidInput(field, true, listItem.id)"
            @input="value => updateFieldLocal({ isListItem: true, listItemId: listItem.id, field, value })"
            :value="listItem[field.key]"
            :disabled="isDisabled(field, listItem)" />
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import DynamicField from './DynamicField'
import dotProp from 'dot-prop'
import { isPresent } from '../../../vuex/validation/validation'

export default {
  name: 'Panel',
  components: { DynamicField },
  props: {
    panel: {
      type: Object,
      required: true
    },
    rootObject: {
      type: Object,
      required: true
    },
    sectionKey: {
      type: String,
      required: true
    },
    subject: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    updateMutation: {
      type: String,
      required: true
    },
    updateListItemMutation: {
      type: String,
      required: true
    },
    addListItemMutation: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      newListItem: {
        __update: true,
        newValue: {}
      },
      showNewListItem: false
    }
  },
  computed: {
    ...mapGetters(['getValue', 'isEnabled', 'sectionIsApplicable', 'sectionIsApplicable', 'panelIsApplicable']),
    ...mapState({
      constraintViolations: state => state.validation.constraintViolations,
      mandatoryViolations: state => state.validation.mandatoryViolations,
      internalViolations: state => state.validation.internalViolations
    }),

    isInApplicableSection () {
      return this.sectionIsApplicable(this.sectionKey, this.rootObject ? this.rootObject[this.subject.key] : null)
    },

    isApplicable () {
      return this.panelIsApplicable(this.panel.key, this.rootObject ? this.rootObject[this.subject.key] : null)
    },

    showPanelSummary () {
      if (this.readonly) {
        if (this.panel.rows.length > 0) {
          return this.panel.rows.some(row => row.fields.some(field => {
            if (field.type === 'STRING') {
              return this.rootObject[this.subject.key][field.key].length > 0
            } else {
              return this.rootObject[this.subject.key][field.key] !== null && this.rootObject[this.subject.key][field.key] !== undefined
            }
          }))
        } else {
          return false
        }
      } else {
        return true
      }
    },

    listItems () {
      if (this.panel.isList) {
        return this.rootObject[this.subject.key]
          ? (this.rootObject[this.subject.key][this.panel.key] ? this.rootObject[this.subject.key][this.panel.key].filter(Boolean) : [])
          : []
      }

      return false
    }
  },
  methods: {
    ...mapMutations([
      'updateField',
      'validateConstraints',
      'removeMandatoryViolation',
      'addInternalViolation',
      'removeInternalViolation',
      'updateMandatoryViolations',
      'removeAllInternalViolationsForListItem'
    ]),
    ...mapActions(['updateNotApplicable']),

    updateNotApplicableLocal (notApplicable, sectionKey) {
      this.updateNotApplicable({
        notApplicable: !notApplicable,
        rootObject: this.rootObject,
        subjectKey: this.subject.key,
        sectionKey: this.panel.key,
        updateMutation: this.updateMutation,
        subSection: true
      })

      this.updateMandatoryViolations(this.subject.key)
    },

    invalidInput (field, isList, listItemId) {
      this.addInternalViolation({
        subjectKey: this.subject.key,
        sectionKey: this.sectionKey,
        panelKey: this.panel.key,
        field: field.key,
        isList,
        listItemId
      })
    },

    fieldIsInvalid (field, listItem) {
      const path = listItem
        ? `${this.subject.key}.${this.sectionKey}.${this.panel.key}.${listItem.id}.${field.key}`
        : `${this.subject.key}.${this.sectionKey}.${this.panel.key}.${field.key}`

      return !!dotProp.get(this.constraintViolations, path) ||
        !!dotProp.get(this.mandatoryViolations, path) ||
        !!dotProp.get(this.internalViolations, path)
    },

    addListItem () {
      this.$store.commit(this.addListItemMutation, {
        subjectKey: this.subject.key,
        panelKey: this.panel.key
      })
    },

    removeListItem (listItemId) {
      this.$store.commit('removeScopeListItem', {
        subjectKey: this.subject.key,
        panelKey: this.panel.key,
        listItemId
      })

      this.validateConstraints(this.subject.key)
      this.updateMandatoryViolations(this.subject.key)
      this.removeAllInternalViolationsForListItem({
        subjectKey: this.subject.key,
        sectionKey: this.sectionKey,
        panelKey: this.panel.key,
        listItemId
      })
    },

    getValueLocal (field) {
      const key = typeof field === 'object' ? field.key : field
      return this.rootObject[this.subject.key] ? this.rootObject[this.subject.key][key] : null
    },

    updateFieldLocal ({ field, value, isListItem, listItemId }) {
      const mutation = isListItem ? this.updateListItemMutation : this.updateMutation
      const parameter = isListItem
        ? {
          subjectKey: this.subject.key,
          panelKey: this.panel.key,
          listItemId,
          field: field.key,
          value
        }
        : {
          root: this.rootObject,
          subjectKey: this.subject.key,
          field: field.key,
          value
        }

      this.$store.commit(mutation, parameter)

      if (isPresent(value)) {
        this.removeMandatoryViolation({
          subjectKey: this.subject.key,
          sectionKey: this.sectionKey,
          panelKey: this.panel.key,
          fieldKey: field.key,
          isListObject: isListItem,
          listObjectId: listItemId
        })
      }

      this.removeInternalViolation({
        subjectKey: this.subject.key,
        sectionKey: this.sectionKey,
        panelKey: this.panel.key,
        fieldKey: field.key,
        isListObject: isListItem,
        listObjectId: listItemId
      })

      this.validateConstraints(this.subject.key)
      this.updateMandatoryViolations(this.subject.key)
    },

    isDisabled (field, listObject) {
      return this.disabled || !this.isEnabled(field, this.sectionKey, this.subject.key, this.rootObject, listObject, this.panel.key)
    }

  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .panel
    border-radius 10px
    padding 13px 26px
    margin-bottom 20px

  .row
    display grid
    grid-column-gap 20px

    &.row1
      grid-template-columns 1fr
    &.row2
      grid-template-columns 1fr 1fr
    &.row3
      grid-template-columns 1fr 1fr 1fr

  .edit-btn
    position absolute
    top 0px
    right 20px

  .list-summary-panel:not(:nth-child(2))
    border-top 1px solid $light-gray

  .add-list-item-btn
    .v-icon:before
      font-size 15px
      color $dark-gray !important

    &:disabled
      .v-icon:before
        color $icon-gray !important

  .remove-list-item-btn
    position absolute
    right 15px
    top -4px

    .v-icon:before
      font-size 20px
      color $dark-gray !important

    &:disabled
      .v-icon:before
        color $icon-gray !important

  .add-item-card
    border-radius 10px
    text-align center

    .add-list-item-btn
      font-size 14px
      border 1px solid $dark-gray
      border-radius 5px

      .v-icon:before
        font-size 18px

  >>> .light
    color: #ababb5
    padding-left 12px
</style>
