<template>
  <v-footer v-if="isNewRfpHeader" class="elevation-15" app fixed>
    <div class="footer-content">
      <v-stepper alt-labels non-linear v-model="step">
        <v-stepper-header>

          <v-stepper-step
            :step="1"
            editable
            complete
            @click="step = 1; handleStepperInput()"
            edit-icon="check"
            :class="{completed: !(invalid && step === 0)}">{{ 'newRFP.footer.step0PD' | t }}
            </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :step="2"
            :editable="!invalid"
            complete
            @click="step = 2; handleStepperInput()"
            :edit-icon="scopeCompleted && !(invalid && step === 1) ? 'check' : 'gps_fixed'"
            :class="{completed: scopeCompleted && !(invalid && step === 1)}">
            {{ 'newRFP.footer.step1SD' | t }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :editable="(scopeCompleted || lawFirmSelectionCompletedLocal) && !invalid"
            :step="3"
            @click="step = 3; handleStepperInput()"
            complete
            :complete-icon="lawFirmSelectionCompletedLocal && !(invalid && step === 2)  ? 'check' : 'ic-users'"
            :edit-icon="lawFirmSelectionCompletedLocal && !(invalid && step === 2)  ? 'check' : 'ic-users'"
            :class="{completed: lawFirmSelectionCompletedLocal && !(invalid && step === 2)}">
            {{ 'newRFP.footer.step2LS' | t }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :step="4"
            @click="step = 4; handleStepperInput()"
            :editable="((scopeCompleted) || feesCompleted) && !invalid"
            complete
            :complete-icon="feesCompleted && !(invalid && step === 3)  ? 'check' : 'ic-clipboard'"
            :edit-icon="feesCompleted && !(invalid && step === 3)  ? 'check' : 'ic-clipboard'"
            :class="{completed: feesCompleted && !(invalid && step === 3)}">
            {{ 'newRFP.footer.step3Fe' | t }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :step="5"
            @click="step = 5; handleStepperInput()"
            :editable="((scopeCompleted && feesCompleted) || termsAndConditionsCompleted) && !invalid"
            complete
            :complete-icon="termsAndConditionsCompleted && !(invalid && step === 4)  ? 'check' : 'ic-pen-tool'"
            :edit-icon="termsAndConditionsCompleted && !(invalid && step === 4)  ? 'check' : 'ic-pen-tool'"
            :class="{completed: termsAndConditionsCompleted && !(invalid && step === 4)}">
            {{ 'newRFP.footer.step4TC' | t }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :step="6"
            @click="step = 6; handleStepperInput()"
            :editable="((scopeCompleted && feesCompleted && termsAndConditionsCompleted) || billingTypeCompleted) && !invalid"
            complete
            :complete-icon="billingTypeCompleted && !(invalid && step === 5)  ? 'check' : 'ic-gavel'"
            :edit-icon="billingTypeCompleted && !(invalid && step === 5)  ? 'check' : 'ic-gavel'"
            :class="{completed: billingTypeCompleted && !(invalid && step === 5)}">
            {{ 'newRFP.footer.step5BT' | t }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :step="7"
            @click="step = 7; handleStepperInput()"
            :editable="scopeCompleted && feesCompleted && termsAndConditionsCompleted && billingTypeCompleted && !invalid"
            complete
            :complete-icon="step > 6 ? 'check' : 'ic-list'"
            :edit-icon="step > 6 ? 'check' : 'ic-list'"
            :class="{completed: step > 6}">
            {{ 'newRFP.footer.step6Sm' | t }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <BetterTooltip
        class="btn-tooltip"
        top="-50px"
        :disabled="!(step === 7 && !canDistributeRfpChange)"
        :tooltip-text="$t('newRFP.footer.distributeRfpChangeToolTip')">
        <v-btn flat :disabled="isButtonExecuting || (step === 7 && !canDistributeRfpChange)"  @click="waitButtonToEnd(saveAndContinue)">
          <section class="mini-grid">
            <div :style="step === 7 ? 'font-weight: bold' : ''" :class="{'top-label': (step !== 7 && step !== 3)}">{{ buttonLabel }}</div>
            <span v-if="step === 7 || step === 3" class="sub-label">{{ buttonSubLabel }}</span>
          </section>
          <v-icon :style="(step !== 7 && step !== 3) ? 'padding-top: 1px' : ''">ic-chevron-right</v-icon>
        </v-btn>
      </BetterTooltip>
    </div>
    <ConfirmDialog
      acceptButtonTextOrKey='buttons.distributeRfp'
      :text="$t('dashboard.createNewRfp.confirmText1', {rfpName: currentRfp.name})"
      :title="$t('dashboard.createNewRfp.distribute')"
      :buttonDisable="isButtonExecuting"
      v-if="showConfirmationDialog"
      @close="showConfirmationDialog = false"
      @submit="waitButtonToEnd(submitRfp)">
      <template v-slot:custom-input>
        <div v-if="currentRfp.status !== 'DRAFT'">
          <MultiLineField
            :translatedName="$t('dashboard.createNewRfp.redistributeNoteTitle')"
            :placeholder="$t('dashboard.createNewRfp.redistributeNotePlaceholder')"
            v-model="submissionMessage"
          ></MultiLineField>
        </div>
      </template>
    </ConfirmDialog>
  </v-footer>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ConfirmDialog from '../generalComponents/ConfirmDialog'
import moment from 'moment'
import MultiLineField from '@/views/generalComponents/inputFields/MultiLineField'
import BetterTooltip from '@/views/generalComponents/BetterTooltip'

export default {
  name: 'NewRfpFooter',
  components: { BetterTooltip, MultiLineField, ConfirmDialog },
  data () {
    return {
      step: 1,
      showConfirmationDialog: false,
      submissionMessage: ''
    }
  },
  created () {
    if (this.$route.meta.newRfpStepperIndex !== null) {
      this.step = this.$route.meta.newRfpStepperIndex
    }
  },
  watch: {
    $route () {
      if (this.$route.meta.newRfpStepperIndex !== null) {
        this.step = this.$route.meta.newRfpStepperIndex
      }
    }
  },
  computed: {
    ...mapGetters([
      'scopeCompleted',
      'lawFirmSelectionCompleted',
      'feesCompleted',
      'termsAndConditionsCompleted',
      'billingTypeCompleted',
      'requiredFields',
      'scopeMandatoryFields',
      'lawFirmSelectionMandatoryFields',
      'termsConditionsMandatoryFields',
      'biddingParametersMandatoryFields',
      'feesMandatoryFields',
      'objectIsEmpty',
      'loadRfpStatusTab',
      'checkFieldsSelected',
      'currentRfpOrDraft',
      'mandateSubTypeOptions',
      'getValueFromPreviousVersion',
      'scopeChanged',
      'requestedInformationChanged',
      'biddingChanged',
      'lawFirmsHaveChanged',
      'termsChanged',
      'generalChanged'
    ]),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields,
      currentSubject: state => state.currentSubject,
      mandatoryViolations: state => state.validation.mandatoryViolations,
      constraintViolations: state => state.validation.constraintViolations,
      internalViolations: state => state.validation.internalViolations,
      subjects: state => state.admin.scopeGenerator.subjects,
      hasChangedSubject: state => state.hasChangedSubject,
      historyRfpVersion: state => state.historyRfpVersion,
      _currentRfp: state => state.currentRfp
    }),

    currentRfp () {
      return this.currentRfpOrDraft
    },

    lawFirmSelectionCompletedLocal () {
      return this.checkFieldsSelected(null, ['lawFirmRelations'])
    },

    isNewRfpHeader () {
      return this.$route.meta.newRfpHeader
    },

    buttonLabel () {
      return this.step < 7 ? this.$t('buttons.saveContinue') : this.$t('buttons.distributeRfp')
    },

    buttonSubLabel () {
      if (this.step === 7 && this.currentRfp) {
        return this.currentRfp.lawFirmRelations.length >= 2 ? this.$t('newRFP.footer.toLawFirms', { numberOfLawFirms: this.currentRfp.lawFirmRelations.length }) : this.$t('newRFP.footer.selectLawFirms')
      }

      return this.step === 3 && this.currentRfp ? this.$t('newRFP.footer.' + (this.currentRfp.lawFirmRelations.length === 1 ? 'lawFirmSelected' : 'lawFirmsSelected'), { numberOfLawFirms: this.currentRfp.lawFirmRelations.length }) : ''
    },

    invalid () {
      return this.invalidFields.length > 0
    },

    mandateSubTypeOptionsFiltered () {
      if (!this.mandateSubTypeOptions) {
        return []
      }
      return this.mandateSubTypeOptions.filter(subType => this.currentRfp.mandateType && subType.value.startsWith(this.currentRfp.mandateType))
    },

    canDistributeRfpChange () {
      // Check if this is a new rfp
      if (this.historyRfpVersion <= 1) {
        return true
      }
      // Check if anything was changed in the change rfp
      return this.generalChanged ||
        this.scopeChanged ||
        this.lawFirmsHaveChanged ||
        this.requestedInformationChanged ||
        this.termsChanged ||
        this.biddingChanged
    }
  },
  methods: {
    ...mapActions(['saveCurrentRfp', 'loadRfp', 'validate', 'distributeRfp', 'redistributeRfp', 'loadAllUsers', 'reloadScopeGeneratorLayoutsBySubject']),
    ...mapMutations(['updateCurrentRfp', 'setAlert', 'setAlertVisible', 'resetMandatoryFields', 'validateMandatoryFields', 'validateConstraints', 'addInvalidField', 'addMissingMandatoryField']),

    handleStepperInput () {
      this.resetMandatoryFields()
      const matchingRoute = this.$router.options.routes.find(route => route.meta && route.meta.newRfpStepperIndex === this.step)
      if (matchingRoute && this.$route.name !== 'newRfp') {
        this.$router.replace({
          name: matchingRoute.name
        })
      }
    },

    async saveAndContinue () {
      if (this.$route.name === 'generalInfoCorporate') {
        const requiredFields = ['name', 'mandateType', 'description', 'clientName']
        if (this.mandateSubTypeOptionsFiltered.length) {
          requiredFields.push('mandateSubType')
        }

        this.validate({ requiredFields, object: this.currentRfp })

        this.currentRfp.subjects.forEach(subject => {
          if (!subject.key) {
            this.addMissingMandatoryField('subject_' + subject.id)
          }
        })

        // other parties can not be validated with current methods
        let otherPartiesValid = true

        this.currentRfp.coi1.otherParties.forEach((party) => {
          if (!party.name) {
            otherPartiesValid = false
            this.addMissingMandatoryField(('coi1.otherParties.' + party.id + '.name'))
          }

          if (!party.role) {
            otherPartiesValid = false
            this.addMissingMandatoryField(('coi1.otherParties.' + party.id + '.role'))
          }
        })

        if (!otherPartiesValid) {
          this.addMissingMandatoryField('coi1.otherParties')
        }

        if (this.missingMandatoryFields.length > 0) {
          return
        }
      } else if (this.$route.name === 'scopeDetailsCorporate' && this.currentRfp.usesScopeGenerator) {
        this.currentRfp.subjects.forEach(subject => {
          this.validateMandatoryFields(subject.key)
          this.validateConstraints(subject.key)
        })

        if (!this.objectIsEmpty(this.mandatoryViolations) ||
          !this.objectIsEmpty(this.constraintViolations) ||
          !this.objectIsEmpty(this.internalViolations)) {
          return
        }
      } else {
        const requiredFields = this.requiredFields
        this.validate({ requiredFields })

        if (this.missingMandatoryFields.length > 0 || this.invalidFields.length > 0) {
          return
        }
      }

      if (this.step < 7) {
        if (this.currentRfp.auctionType === 'SIMPLE' && this.step === 5) {
          this.updateCurrentRfp({ field: 'auctionStart', value: null })
          this.updateCurrentRfp({ field: 'firstBidDeadline', value: null })
        } else if (this.currentRfp.auctionType === 'ADVANCED' && this.step === 5) {
          this.updateCurrentRfp({ field: 'auctionStart', value: null })
        }

        try {
          await this.saveCurrentRfp()
          await this.reloadScopeGeneratorLayoutsBySubject()
          if (this.$route.query.backToSummary) {
            this.step = 7
          } else {
            this.step++
          }
          this.handleStepperInput()
        } catch (error) {
          console.error('Error saving RFP:', error)
        }
      } else {
        if (this.timeConstraintsValid() && this.checkLawFirmSelection()) {
          this.showConfirmationDialog = true
        }
      }
    },

    checkLawFirmSelection () {
      this.validate({ requiredFields: ['lawFirmRelations'] })

      if (this.missingMandatoryFields.length > 0 || this.invalidFields.length > 0) {
        this.$router.push({
          name: 'lawFirmSelectionCoprporate',
          query: {
            backToSummary: true
          }
        })
        return false
      }

      return true
    },

    timeConstraintsValid () {
      if (this._currentRfp.status !== 'DRAFT') {
        return true
      }

      const firstDeadlineField = this.currentRfp.allowQA ? 'qaEnd' : (this.currentRfp.auctionType === 'SIMPLE' ? 'auctionEnd' : 'firstBidDeadline')
      const firstDeadline = this.currentRfp[firstDeadlineField]
      const deadlineMoment = this.currentRfp.status === 'DRAFT' ? moment.utc(firstDeadline).subtract(20, 'hours') : moment.utc(this.getValueFromPreviousVersion(firstDeadlineField))

      const isValid = moment.utc().isBefore(deadlineMoment)
      if (!isValid) {
        this.addInvalidField(firstDeadlineField)
        this.$router.push({ name: 'billingTypeCorporate' })
      }

      return isValid
    },

    async submitRfp () {
      await this.saveCurrentRfp()
      if (this.currentRfp.status === 'DRAFT') {
        await this.distributeRfp(this.currentRfp.id)
      } else {
        await this.redistributeRfp({
          rfpId: this.currentRfp.rfpId,
          submissionMessage: this.submissionMessage
        })
      }

      this.showConfirmationDialog = false
      this.setAlert({
        type: 'submitted',
        visible: true,
        rfpName: this.currentRfp.name,
        to: {
          name: 'activeRfp',
          params: {
            id: this.currentRfp.id,
            activeTab: 'Summary'
          }
        }
      })

      await this.loadAllUsers()
      this.$router.push({
        name: 'dashboardRfps'
      })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  footer
    background #ffffff !important
    height 74px !important

  .footer-content
    width $max-width
    margin 0 auto
    background #ffffff
    display grid
    height 74px !important
    grid-template-columns 1fr 200px

  .btn-tooltip
    height 100%
    border-left 1px solid lightgray

  .v-btn
    height 74px
    width 100%
    margin auto
    .v-icon,
    .v-icon:before
      color $dark-gray !important
    &:disabled
      >>> *
        color $icon-gray
      .v-icon:before
        color $icon-gray !important

  >>> .v-stepper
    box-shadow none !important

    .v-stepper__header
      box-shadow none !important
      height 74px !important

      .v-divider
        margin-top 27px

      .v-stepper__label
        font-size 12px

      .v-stepper__step
        padding-top 15px
        padding-bottom 6px

      .v-stepper__step__step.primary
        background-color #fff !important
        margin-bottom 5px

      .v-stepper__step--active
        color $dark-gray !important
        .v-icon
          color $icon-gray !important

      .completed
        .v-stepper__step__step.primary
          background-color rgb(102, 174, 4) !important
          .v-icon
            color white !important

  .mini-grid
    display Grid
    grid-template-rows 1fr 1fr

  .top-label {
    grid-row-end: span 2;
  }

  .sub-label {
    color $icon-gray
    font-size 12px
    text-transform none
    text-align center
  }

  /* >>> .v-stepper__step--editable {
    &:hover {
      background: rgba(0,0,0,0) !important
      cursor: default
    }

    .v-stepper__label {
      text-shadow: none !important
    }

    &.completed {
      &:hover {
        cursor: pointer
      }

      .v-stepper__label {
        text-shadow: 0px 0px 0px #000 !important
      }
    }
  } */
</style>
