<template>
  <div>
    <component v-if="rfpLoaded" :is="currentComponent" />
    <NewRfpFooter ref="footer" />
  </div>
</template>

<script>
import BillingType from './billingType/BillingType'
import LawFirmSelection from './lawFirmSelection/LawFirmSelection'
import ScopeDefinition from './scopeDefinition/ScopeDefinition'
import Proposal from './proposal/Proposal'
import TermsConditions from './termsConditions/TermsCondition'
import SummaryPage from './summary/SummaryPage'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import NewRfpFooter from '../generalComponents/NewRfpFooter'
import generalRfpInfo from '../newRfpCorporate/general/GeneralRfpInfo'

export default {
  name: 'NewRfpCorporate',
  props: {
    // the id of the current rfp
    rfpId: {
      type: String,
      required: true
    }
  },
  components: {
    BillingType,
    LawFirmSelection,
    ScopeDefinition,
    TermsConditions,
    Proposal,
    SummaryPage,
    NewRfpFooter,
    generalRfpInfo
  },
  data () {
    return {
      rfpLoaded: false,
      step: 0
    }
  },
  computed: {
    ...mapGetters(['userIsLawFirm']),
    ...mapState({
      currentRfp: state => state.currentRfp
    }),

    currentComponent () {
      if (this.$route.meta && this.$route.meta.component) {
        return this.$route.meta.component
      }

      return ''
    }
  },
  async created () {
    await this.loadRfp({ rfpId: this.rfpId })

    if (this.userIsLawFirm) {
      await this.$router.replace('/rfp/' + this.currentRfp.id)
      return
    }

    if (!this.$route.meta.component) {
      const footer = this.$refs.footer
      if (this.currentRfp.draftStep) {
        switch (this.currentRfp.draftStep) {
          case 'step1SD':
            this.$router.replace({ name: 'scopeDetailsCorporate' })
            footer.step = 2
            break
          case 'step2LS':
            this.$router.replace({ name: 'lawFirmSelectionCoprporate' })
            footer.step = 3
            break
          case 'step3Fe':
            this.$router.replace({ name: 'proposal' })
            footer.step = 4
            break
          case 'step4TC':
            this.$router.replace({ name: 'termsAndConditionsCorporate' })
            footer.step = 5
            break
          case 'step5BT':
            this.$router.replace({ name: 'billingTypeCorporate' })
            footer.step = 6
            break
          case 'step6Sm':
            this.$router.replace({ name: 'summaryCorporate' })
            footer.step = 7
            break
        }

        footer.handleStepperInput()
      } else {
        this.$router.replace({ name: 'generalInfoCorporate' })

        footer.step = 1
      }
    }

    this.rfpLoaded = true
  },
  methods: {
    ...mapActions(['loadRfp']),
    ...mapMutations(['setCurrentRfp'])
  },
  beforeRouteLeave (to, from, next) {
    if (!to.meta.newRfpHeader) {
      this.setCurrentRfp(null)
    }
    next()
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  >>> .v-divider {
    border-color: lightgray !important
  }
</style>
