<template>
  <div>
    <h1>
      <v-btn style="text-transform: capitalize;" flat @click="leaving()"><v-icon>ic-chevron-left</v-icon> {{ 'scopeGeneratorAdminView.inputView.title' | t }}</v-btn>
    </h1>

    <v-card class="filter-card">
      <SelectField
        v-model="selectedKey"
        @input="search"
        :translated-name="'scopeGeneratorAdminView.inputView.keyFilter' | t"
        :options="rfpStatisticsFieldNames"
      />
    </v-card>

      <v-data-table
        v-if="selectedKey"
        must-sort
        :headers="headers"
        :items="rfpStatistics"
        :pagination.sync="pagination"
        :hide-actions="true"
        class="styled-table"
        :no-data-text="'scopeGeneratorAdminView.inputView.noData' | t">
        <template slot="headerCell" slot-scope="props">
          <span>{{ (props.header.text ? `scopeGeneratorAdminView.inputView.${props.header.text}` : '') | t }}</span>
        </template>

        <template v-slot:items="props">
          <tr>
            <td>
              {{ formatValue(props.item) }}
            </td>
            <td>
              {{ formatNumber(props.item.count) }}
            </td>
          </tr>
        </template>
      </v-data-table>

  </div>
</template>

<script>
import SelectField from '../generalComponents/inputFields/SelectField'
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ScopeGeneratorAdminInputView',
  components: { SelectField },
  data () {
    return {
      selectedKey: '',
      headers: [
        { text: 'value', align: 'left', sortable: false, value: 'value' },
        { text: 'count', align: 'left', sortable: false, value: 'count' }
      ],
      pagination: {
        sortBy: 'count',
        rowsPerPage: -1
      }
    }
  },
  created () {
    this.loadRfpStatisticsFieldNames()
  },
  computed: {
    ...mapGetters(['formatDateTime', 'formatNumber']),
    ...mapState({
      rfpStatisticsFieldNames: state => state.admin.scopeGenerator.rfpStatisticsFieldNames,
      rfpStatistics: state => state.admin.scopeGenerator.rfpStatistics,
      optionLists: state => state.optionLists
    }),

    allOptions () {
      return this.optionLists.reduce((result, optionList) => {
        result.push(...optionList.options)
        return result
      }, [])
    }
  },
  methods: {
    ...mapActions(['loadRfpStatistics', 'loadRfpStatisticsFieldNames']),

    search () {
      this.loadRfpStatistics(this.selectedKey)
    },

    leaving () {
      this.$router.push({ name: 'scopeGeneratorAdminView' })
    },

    formatValue (statistic) {
      switch (statistic.type) {
        case 'TEXT':
        case 'MULTILINE_TEXT':
        case 'CHECKBOX_INDENTED':
        case 'CHECKBOX': return statistic.value
        case 'RADIO':
        case 'MULTI_SELECT':
        case 'TAGGABLE_SINGLE_SELECT':
        case 'SINGLE_SELECT': return this.formatSelect(statistic.value)
        case 'DATE': return this.formatDate(statistic.value)
        case 'PERCENT':
        case 'NUMBER': return this.formatFloat(statistic.value)
        case 'DATETIME': return this.formatDateTime(statistic.value)
        default: return statistic.value
      }
    },

    formatFloat (value) {
      if (value) {
        const [euros, cents] = String(value).split('.')
        if (cents) {
          return this.formatNumber(euros) + ',' + cents
        } else {
          return this.formatNumber(euros)
        }
      } else {
        return ''
      }
    },

    formatSelect (value) {
      const optionValue = this.allOptions.find(option => option.key === value)

      return optionValue ? optionValue.translatedLabel : value
    },

    formatDate (value) {
      const valueMoment = moment(value)

      if (valueMoment.isValid()) {
        return valueMoment.format('DD.MM.YYYY')
      }

      return value
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  h1
    display inline-block

    *,
    *:before
      font-size 28px
      color $dark-gray !important
      padding-left 0

  .filter-card
    padding 20px
    margin 20px 0px
    border-radius 10px
    display grid
    grid-template-columns 1fr 1fr 1fr
</style>
