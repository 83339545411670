<template>
  <v-dialog
    :value="visible"
    @input="value => { $emit('visibilityChange', value); resetSubject() }"
    width="700"
    persistent
    no-click-animation
  >
    <v-card v-click-outside-dialog="closeEvent">
      <v-card-title
        class="headline"
        primary-title
      >
        {{ `scopeGeneratorAdminView.subjectDialog.title.${title}` | t }}

      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <div class="translations">
          <div v-for="(translation) in ['en', 'de']"  :key="translation">
            <span  :class="{mandatory: translation === 'en'}">{{ 'translations.' + translation | t }}</span>
            <v-text-field
              label=""
              solo flat
              :class="{ missing: labelMissing }"
              @input="labelMissing = false"
              v-model="subject.labelTranslations[translation]"
            ></v-text-field>
          </div>
        </div>

        <span>{{ 'scopeGeneratorAdminView.subjectDialog.mandateTypes' | t }}</span>
        <v-select
          label=""
          multiple
          v-model="subject.mandateTypes"
          :items="mandateTypeOptions"
          :placeholder="`scopeGeneratorAdminView.subjectDialog.placeholder.mandateTypes` | t "
          solo
          flat></v-select>

        <span>{{ 'scopeGeneratorAdminView.subjectDialog.mandateSubTypes' | t }}</span>
        <v-select
          label=""
          multiple
          v-model="subject.mandateSubTypes"
          :items="mandateSubTypeOptionsFiltered"
          :placeholder="`scopeGeneratorAdminView.subjectDialog.placeholder.mandateSubTypes` | t "
          solo
          flat></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="cancel">{{ 'buttons.cancel' | t }}</v-btn>
        <AwaitButton
          class="main"
          :callback="updateSubjectLocal">
          {{ 'scopeGeneratorAdminView.subjectDialog.submit' | t }}
        </AwaitButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import AwaitButton from '../../generalComponents/AwaitButton'

export default {
  name: 'UpdateSubjectDialog',
  components: { AwaitButton },
  model: {
    prop: 'visible',
    event: 'visibilityChange'
  },
  props: {
    // Wether the dialog should be visible
    // Used with v-model (see model definition above)
    visible: {
      type: Boolean,
      required: false,
      default: false
    },

    subjectToUpdate: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      title: 'add',
      labelMissing: false,
      subject: {
        labelTranslations: { en: '' },
        mandateTypes: [],
        mandateSubTypes: []
      }
    }
  },
  watch: {
    subjectToUpdate () {
      if (this.subjectToUpdate) {
        this.title = 'update'
        this.subject = JSON.parse(JSON.stringify(this.subjectToUpdate))
      } else {
        this.resetSubject()
      }
    }
  },
  computed: {
    ...mapGetters(['mandateTypeOptions', 'mandateSubTypeOptions']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    }),

    mandateSubTypeOptionsFiltered () {
      if (!this.mandateSubTypeOptions) {
        return []
      }
      return this.mandateSubTypeOptions
        .filter(subType => this.subject.mandateTypes.some(mandateType => subType.value.startsWith(mandateType)))
        .map(subType => {
          const mainType = this.mandateTypeOptions.find(mandateType => subType.value.startsWith(mandateType.value))

          return {
            value: subType.value,
            text: mainType.text + ': ' + subType.text
          }
        })
    }
  },
  methods: {
    ...mapActions(['validate', 'updateSubject']),
    ...mapMutations(['removeMissingMandatoryField', 'resetMandatoryFields']),

    async updateSubjectLocal () {
      if (!this.subject.labelTranslations.en) {
        this.labelMissing = true
        return
      }

      const subject = { ...this.subject }

      const key = await this.updateSubject({ subject })

      this.$emit('saved', key)
      this.$emit('visibilityChange', false)
      this.resetSubject()
    },

    cancel () {
      this.resetSubject()
      this.resetMandatoryFields()
      this.$emit('visibilityChange', false)
    },

    resetSubject () {
      this.title = 'add'
      this.subject = {
        labelTranslations: { en: '' },
        mandateTypes: [],
        mandateSubTypes: []
      }
    },

    closeEvent (event) {
      if (this.visible) {
        this.$emit('visibilityChange', false)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .v-card
    border-radius 10px
    .v-card__actions
      padding 16px 45px 24px 45px
    .v-card__title
      padding 24px 45px 16px 45px

  .v-card__text
    padding 16px 45px
    span
      color rgb(158, 158, 158)
      padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input--checkbox .v-input__slot
    border none !important

  .translation-headers
    display grid
    grid-template-columns 1fr 150px
    height 36px

    span
      line-height 36px

    button
      margin-top 0

    .v-icon
      font-size 15px
      &:before
        color $dark-gray !important

  .translations
    display grid
    grid-template-columns 1fr 1fr 60px
    grid-column-gap 10px

    >>> .v-input__slot
      margin-bottom 10px !important
</style>
