<template>
  <div v-if="rfpLoaded">
    <h1>{{ 'coi2LawFirm.title' | t }}</h1>
    <Summary :isCOICheck="true" />
  </div>
</template>

<script>
import Summary from '../../newRfpCorporate/summary/Summary'
import { mapActions } from 'vuex'

export default {
  name: 'ConflictCheckII',
  components: { Summary },
  props: {
    rfpId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rfpLoaded: false
    }
  },
  async created () {
    await this.loadRfp({ rfpId: this.rfpId })
    this.rfpLoaded = true
  },
  methods: {
    ...mapActions(['loadRfp'])
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  h1
    display inline-block
    margin-bottom 1.5em

  .info-icon
    font-size 20px
    margin-left 5px

  >>> .info-icon
    font-size 20px
    margin-left 5px
    margin-bottom 4px
    vertical-align middle
    .v-icon
      font-size 20px !important
      color: #ababb5 !important
      vertical-align middle
</style>
