<template>
    <pop-up :value="value" @input="e => $emit('input', e)" class="notifications elevation-10">

      <v-list three-line v-if="notifications.length">
        <div class="notification-title">
          <h2>{{ 'settings.navigation.notifications' | t }}</h2>
          <v-btn v-if="numberOfUnreadNotifications" flat round @click="setNotficationsToRead([])">{{ 'notifications.markAllAsRead' | t }}</v-btn>
        </div>
        <template v-for="(item, index) in orderedNotifications">
          <v-divider
            v-if="index > 0"
            divider
            :key="item.id + '_divider'"
          ></v-divider>

          <v-list-tile
            :key="item.id"
            avatar
            :to="{path: handleClick(item)}"
            @click="setNotficationsToRead([item.id]);$emit('close')"
          >
            <div class="notification-container">
              <section class="red-dot"><span :class="{color: !item.is_read}"></span></section>
              <v-list-tile-content :style="{'font-weight': item.is_read ? '' : 'bold'}">
                <v-list-tile-title style="padding-top: 3px">{{ getMessageText(item) }}</v-list-tile-title>
                <v-list-tile-sub-title v-html="formatDateTime(item.created_at)"></v-list-tile-sub-title>
              </v-list-tile-content>
              <section class="notif-btn"><v-btn flat round small>{{ 'buttons.view' | t }}</v-btn></section>
            </div>

          </v-list-tile>
          <v-divider :key="item.id + '_addDivider'" v-if="index === orderedNotifications.length - 1 && index > 18" divider></v-divider>
          <v-list-tile
            :key="item.id + '_add'"
            v-if="index === orderedNotifications.length - 1 && index > 18 && item.message_key !== lastNotification"
            :disabled="isButtonExecuting"
            @click="waitButtonToEnd(loadMoreNotif)">
            <div class="notification-container">
              <v-list-tile-content style="grid-column-start: 2; text-align: center">
                <v-list-tile-title style="padding-top: 3px; text-align: center">{{ 'buttons.loadMoreNotifications' | t }}</v-list-tile-title>
                <!-- <v-list-tile-sub-title v-html="'load more notification'"></v-list-tile-sub-title> -->
              </v-list-tile-content>
            </div>
          </v-list-tile>

        </template>
      </v-list>
      <h3 style="height: 80px; display: grid; align-content: center; justify-content: start; padding-left: 40px;" v-else>{{ 'notifications.noNotifications' | t }}</h3>
    </pop-up>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState, mapActions } from 'vuex'
import PopUp from './PopUp'

export default {
  name: 'Notifications',
  components: { PopUp },
  props: {
    /**
     * V-model compatible visibility flag
     */
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['formatDateTime', 'numberOfUnreadNotifications']),
    ...mapState({
      notifications: state => state.notifications.notifications
    }),

    orderedNotifications () {
      const orderedNotifications = [...this.notifications].sort((a, b) => {
        // console.log(moment(a.created_at).isSameOrAfter(b.created_at))
        return moment(a.created_at).isSameOrAfter(b.created_at) ? -1 : 1
      })
      return orderedNotifications
    }
  },
  data () {
    return {
      lastNotification: 'Welcome to the Lintum platform. In the future you will receive important updates here.'
    }
  },
  methods: {
    ...mapActions(['subscribeToNotifications', 'setNotficationsToRead']),
    handleClick (item) {
      let path = item.rfp_id ? '/rfp/' + item.rfp_id : '/'
      if (item.url_path) {
        path += item.url_path
      }
      // this.$router.push({ path: path })
      // console.log(path)
      return path
    },

    async loadMoreNotif () {
      await this.subscribeToNotifications(this.notifications.length)
    },

    getMessageText (notification) {
      const key = `_notifications.messages.${notification.message_key}.notificationText`
      if (this._i18n.i18next.exists(key)) {
        return this.$t(key, { rfp: notification.rfp_notification, interpolation: { escapeValue: false } })
      } else {
        return notification.message_key
      }
    }
  }
}
</script>

<style scoped>
  .notifications {
    position: absolute;
    top: 32px;
    right: 0;
    min-width: 500px;
    width: 30%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 120px);
  }

  .v-list__tile__title, .v-list__tile__sub-title {
    white-space: normal;
  }

  .v-list__tile__title {
    height: unset;
    line-height: 20px;
  }

  .red-dot {
    padding-right: 16px;
    padding-top: 2px
  }
  .red-dot span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block
  }
  .color {
    background: rgb(199, 54, 57);
  }

  .notif-btn {
    width: 60px;
  }
  .v-btn {
    color: rgb(174, 150, 100);
    padding: 0;
    margin: 0;
    min-width: 50px;
  }
  .notification-container {
    display: grid;
    grid-template-columns: 26px 380px auto
  }

  .notification-title {
    display: grid;
    grid-template-columns: 1fr 120px;
    padding: 0 26px 0 42px;
  }
</style>
