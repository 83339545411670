import Vue from 'vue'

export default {

  addQuestionToRfp (state, question) {
    const questions = state.currentRfp.qaQuestions
    const index = questions.findIndex(_question => _question.id === question.id)

    if (index > -1) {
      Vue.set(state.currentRfp.qaQuestions, index, question)
    } else {
      questions.push(question)
      Vue.set(state.currentRfp, 'qaQuestions', questions)
    }
  }

}
