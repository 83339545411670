<template>
  <v-dialog
    :value="visible"
    @input="value => { $emit('visibilityChange', value); }"
    :persistent="!closeable"
    :no-click-animation="!closeable"
    width="700">
    <v-card>
      <v-card-title
        class="title"
        primary-title
      >
        <h2>{{ 'passwordReset.title' | t }}</h2>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text style="padding-right: 90px; padding-left: 90px;">
        <SingleLineField
          autocomplete="current-password"
          :translated-name="'passwordReset.oldPassword' | t"
          :append-icon="showOld ? 'visibility' : 'visibility_off'"
          :type="showOld ? 'text' : 'password'"
          @clickAppend="showOld = !showOld"
          v-model="oldPassword"
          @input="oldPasswordMissing = false; oldPasswordInvalid = false"
          :invalid="oldPasswordMissing || oldPasswordInvalid"
        />
        <span v-if="oldPasswordInvalid" class="missing">{{ 'passwordReset.oldPasswordNotCorrect' | t }}</span>

        <SingleLineField
          autocomplete="new-password"
          :translated-name="'passwordReset.newPassword' | t"
          :append-icon="showNew1 ? 'visibility' : 'visibility_off'"
          :type="showNew1 ? 'text' : 'password'"
          @clickAppend="showNew1 = !showNew1"
          @input="validatePassword(); passwordInvalid = false; newPasswordEqualsOld = false"
          :invalid="passwordInvalid || newPasswordEqualsOld"
          v-model="newPassword"
          style="margin-top: 10px"
        />
        <span v-if="newPasswordEqualsOld" class="missing">{{ 'passwordReset.newPasswordEqualsOld' | t }}</span>

        <SingleLineField
          autocomplete="new-password"
          :translated-name="'passwordReset.verifyNew' | t"
          :append-icon="showNew2 ? 'visibility' : 'visibility_off'"
          :type="showNew2 ? 'text' : 'password'"
          @clickAppend="showNew2 = !showNew2"
          @input="passwordsDoNotMatch = false"
          :invalid="passwordsDoNotMatch"
          v-model="newPassword2"
          style="height: 70px; margin-top: 10px"
        />
        <span
          v-if="passwordsDoNotMatch"
          class="missing">{{ 'passwordReset.explanation.passwordsDoNotMatch' | t }}</span>

        <br>
        <p style="margin-bottom: 0">{{ 'passwordReset.explanation.title' | t }}</p>
        <ul>
          <li :class="{ finished: finished('min'), missing: !finished('min') && saved }">{{ 'passwordReset.explanation.numberOfChars' | t }}</li>
          <li :class="{ finished: finished('uppercase'), missing: !finished('uppercase') && saved }">{{ 'passwordReset.explanation.uppercase' | t }}</li>
          <li :class="{ finished: finished('lowercase'), missing: !finished('lowercase') && saved }">{{ 'passwordReset.explanation.lowercase' | t }}</li>
          <li :class="{ finished: finished('digits'), missing: !finished('digits') && saved }">{{ 'passwordReset.explanation.digit' | t }}</li>
          <li :class="{ finished: finished('symbols'), missing: !finished('symbols') && saved }">{{ 'passwordReset.explanation.symbol' | t }}</li>
          <!-- li :class="{ finished: finished('has'), missing: !finished('has') && saved }">{{ 'passwordReset.explanation.validSymbols' | t }}</li -->
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="closeable"
          flat
          style="align-self: start; border-radius: 5px;"
          @click="$emit('visibilityChange', false)">{{ 'buttons.cancel' | t }}</v-btn>
        <v-btn
          flat
          @click="saveNewPassword"
          class="main elevation-3"
          style="margin-right: 82px; margin-bottom: 30px;border-radius: 5px">
          {{ 'passwordReset.save' | t }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import SingleLineField from '../../generalComponents/inputFields/SingleLineField'
import PasswordValidator from 'password-validator'

export default {
  name: 'PasswordResetDialog',
  components: { SingleLineField },
  model: {
    prop: 'visible',
    event: 'visibilityChange'
  },
  props: {
    // Wether the dialog should be visible
    // Used with v-model (see model definition above)
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    closeable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      showOld: false,
      showNew1: false,
      showNew2: false,
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      schema: null,
      failedValidations: [],
      saved: false,
      passwordInvalid: false,
      oldPasswordMissing: false,
      passwordsDoNotMatch: false,
      oldPasswordInvalid: false,
      newPasswordEqualsOld: false
    }
  },
  created () {
    this.schema = new PasswordValidator()

    this.schema
      .is().min(8)
      .has().uppercase()
      .has().lowercase()
      .has().digits()
      .has().symbols()
      .has(/^[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@\\[\]^_`{|}~]*$/)
  },
  methods: {
    ...mapActions(['updatePassword']),
    ...mapMutations(['handleGraphQlErrors', 'setErrorOperation']),

    validatePassword () {
      this.failedValidations = this.schema.validate(this.newPassword, { list: true })
    },

    finished (rule) {
      if (this.newPassword) {
        return !this.failedValidations.includes(rule)
      }

      return false
    },

    async saveNewPassword () {
      this.saved = true
      this.passwordInvalid = !!this.failedValidations.length

      if (!this.oldPassword) {
        this.oldPasswordMissing = true
        return
      }

      this.newPasswordEqualsOld = this.oldPassword === this.newPassword
      this.passwordsDoNotMatch = this.newPassword !== this.newPassword2

      if (this.passwordInvalid || this.passwordsDoNotMatch || this.newPasswordEqualsOld) {
        return
      }

      try {
        await this.updatePassword({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        })
        this.$emit('visibilityChange', false)
      } catch ({ graphQLErrors, networkError }) {
        if (graphQLErrors) {
          const error = graphQLErrors[0]

          if (error.extensions.httpStatusEquivalent === 400) {
            this.oldPasswordInvalid = true
          } else {
            this.setErrorOperation('changePassword')
            this.handleGraphQlErrors(graphQLErrors)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .finished
    color $green

  .missing
    color $red !important

  .v-card
    border-radius 10px 0 0 10px

  .v-card__text
    span
      color rgb(158, 158, 158)
      padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-dialog
    overflow auto
    border-radius 10px

  >>> .v-dialog::-webkit-scrollbar {
    border-radius 0 10px 10px 0
    background-color #ffffff
    width 10px
  }
  >>> .v-dialog::-webkit-scrollbar-thumb {
    background-color #aaa
    border-radius 5px
  }
  >>> .v-dialog::-webkit-scrollbar-button {
    display none
  }

  .other-parties
    display grid
    grid-template-columns 1fr 1fr 60px
    grid-column-gap 10px

    >>> .v-input__slot
      margin-bottom 10px !important

  .other-parties-header
    display grid
    grid-template-columns 1fr 150px
    height 36px

    span
      line-height 36px

    button
      margin-top 0

    .v-icon
      font-size 15px
      &:before
        color $dark-gray !important

  >>> .v-text-field__details
    display none

  >>> .v-input__slot
    margin-bottom 0px !important

  >>> .v-icon
    font-size 20px
</style>
