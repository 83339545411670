<template>
  <div class="analytics">
    <div class="colapse-title">
      <h1>{{ 'dashboard.analytics.heading' | t }}</h1>
      <v-btn @click="isAnalyticsCollapsed = !isAnalyticsCollapsed; maxPage = 0; currentPage = 0" depressed icon><v-icon :style="isAnalyticsCollapsed ? 'transform: rotate(270deg)' : 'transform: rotate(90deg)'" >ic-chevron-left</v-icon></v-btn>
    </div>
    <v-divider></v-divider>
    <div  v-if="!isAnalyticsCollapsed" :class="userIsCorporate ? 'card-carousel' : 'card-container'" style="position: relative;">
      <template v-if="!userIsCorporate">
        <AnalyticsCard :analyticsInfo="sortedAnalytics" cardType="rfp" :userIsCorporate="userIsCorporate" />
        <AnalyticsCard :analyticsInfo="sortedAnalytics" cardType="revenue" :userIsCorporate="userIsCorporate" />
        <AnalyticsCard :analyticsInfo="sortedAnalytics" cardType="fee" :userIsCorporate="userIsCorporate" />
      </template>
      <template v-else>
        <div style="z-index: 1; top: 40%; left: -50px; position: absolute;">
          <v-btn fab small v-if="currentPage !== 0 && userData.mainContactPerson" class="elevation-10" @click="moveBack">
            <v-icon>chevron_left</v-icon>
          </v-btn>
        </div>

        <carousel :perPage="3" style="overflow-x: hidden;" ref="carousel" @pageChange="updatePage" :paginationEnabled="false" :mouse-drag="false">
          <slide>
            <AnalyticsCard :analyticsInfo="sortedAnalytics" :userIsCorporate="userIsCorporate" cardType="rfp" />
          </slide>
          <slide>
            <AnalyticsCard :analyticsInfo="sortedAnalytics" :userIsCorporate="userIsCorporate" cardType="revenue" />
          </slide>
          <slide>
            <AnalyticsCard :analyticsInfo="sortedAnalytics" :userIsCorporate="userIsCorporate" cardType="fee" />
          </slide>
          <slide v-if="userData.mainContactPerson">
            <AnalyticsCard
              :analyticsInfo="sortedAnalytics"
              :userIsCorporate="userIsCorporate"
              cardType="transactionVolume"
              :corporate="corporate"
            />
          </slide>
        </carousel>

        <div style="z-index: 1; top: 40%; right: -50px; position: absolute;">
          <v-btn fab small v-if="currentPage !== maxPage - 1 && userData.mainContactPerson" class="elevation-10" @click="moveForward">
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AnalyticsCard from './AnalyticsCard'
import { Carousel, Slide } from 'vue-carousel'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'Analytics',
  data () {
    return {
      isAnalyticsCollapsed: false,
      maxPage: 0,
      currentPage: 0,
      corporate: null
    }
  },
  components: { AnalyticsCard, Carousel, Slide },
  async created () {
    if (!this.userIsCorporate) {
      await this.loadLawFirmAnalyticsInfo()
    } else {
      await this.loadCorporateAnalyticsInfo()
      await this.loadAllUsers()
      const user = this.allUsers.find(user => user.id === this.userData.userId)
      this.corporate = user.corporate
    }
  },
  watch: {
    analyticsCurrency () {
      if (!this.userIsCorporate) {
        this.loadLawFirmAnalyticsInfo()
      } else {
        this.loadCorporateAnalyticsInfo()
      }
    }
  },
  computed: {
    ...mapGetters(['userIsCorporate']),
    ...mapState({
      analytics: state => state.analytics,
      userData: state => state.userData,
      allUsers: state => state.admin.allUsers,
      analyticsCurrency: state => state.analyticsCurrency
    }),

    sortedAnalytics () {
      const orederValuesArray = ['LIFETIME', 'YTD', 'QTD', 'MTD']
      const sortedAnalyticsArray = [...this.analytics]
      return sortedAnalyticsArray.sort((a, b) => {
        return orederValuesArray.indexOf(a.period) - orederValuesArray.indexOf(b.period)
      })
    }
  },
  methods: {
    ...mapActions(['loadLawFirmAnalyticsInfo', 'loadCorporateAnalyticsInfo', 'loadAllUsers']),

    updatePage () {
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    },

    moveBack () {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    },

    moveForward () {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    }
  }
}
</script>

<style scoped lang="stylus">

  .analytics
    margin-bottom 60px
    .colapse-title
      display grid
      grid-template-columns 1fr auto
    .card-container
      display grid
      grid-template-columns repeat(3, 1fr)
      grid-column-gap 10px
      margin-top 1em

    >>>.v-btn
      margin 0

  .card-carousel
    margin-top 1em
    height 250px

    .analytics-card
      margin 0 2px
      height 240px

    .VueCarousel,
    >>> .VueCarousel-wrapper
      height 245px

  .slider-nav
    height 100%
    display flex
    align-items center
    justify-content  center
    background #fff
    border-radius 5px

    &:hover
      box-shadow 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)
      cursor pointer

</style>
