import { render, staticRenderFns } from "./RadioField.vue?vue&type=template&id=4cf9e3a1&scoped=true&"
import script from "./RadioField.vue?vue&type=script&lang=js&"
export * from "./RadioField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cf9e3a1",
  null
  
)

export default component.exports