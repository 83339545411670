import moment from 'moment-timezone'
import i18next from 'i18next'
import { vue } from '../../main'

function userHasAuthority (state, authority) {
  return state.userData.authorities ? state.userData.authorities.some(_authority => _authority.authority === authority) : false
}

export default {

  userId (state) {
    return state.userData.userId
  },

  userIsLawFirm (state) {
    return state.userData.authorities ? state.userData.authorities.some(authority => authority.authority === 'ROLE_LAWFIRM') : false
  },

  userIsCorporate (state) {
    return state.userData.authorities ? state.userData.authorities.some(authority => authority.authority === 'ROLE_CORPORATE') : false
  },

  authToken (state) {
    return state.authToken
  },

  isSessionExpired (state) {
    return !state.authToken
  },

  userIsAdmin (state) {
    return userHasAuthority(state, 'ROLE_LINTUM_ADMIN')
  },

  userIsOrganizationAdmin (state) {
    return userHasAuthority(state, 'ADMIN')
  },

  currentLawFirm (state, getters) {
    if (getters.userIsLawFirm) {
      return state.lawFirms[0]
    } else {
      return null
    }
  },

  /**
   * Returns the display name of a law firm given its id or hash.
   * Hashes are used whenever the current user is a law firm user to pseudonymize other law firm names.
   */
  renderLawFirm: (state, getters) => (lawFirmIdOrHash) => {
    if (getters.userIsLawFirm) {
      if (lawFirmIdOrHash === getters.currentLawFirmHash) {
        return getters.currentLawFirm.name
      } else {
        return vue.$t('general.lawFirm') + ' ' + lawFirmIdOrHash // 'Law firm ****' + lawFirmIdOrHash.substr(lawFirmIdOrHash.length - 4).toLowerCase()
      }
    } else {
      const lawFirm = state.lawFirms.find(lawFirm => lawFirm.id === lawFirmIdOrHash)
      return lawFirm ? lawFirm.name : ''
    }
  },

  /**
   * timestamp either a ISO formatted string or a moment object
   */
  formatDateTime: () => (timestamp) => {
    // moment can actually detect the local utc difference and convert automatically. The only thing that is not working
    // without the moment-timezone module is the timezone abbrevation ("CET" vs "CEST"). So the following line would format the time in the user's computer time:
    // moment.utc(timestamp).format('DD MMMM YYYY HH:mm')
    return timestamp ? moment.utc(timestamp).tz('Europe/Berlin').format(`DD${vue.$i18n.i18next.language === 'de' ? '.' : ''} MMMM YYYY | HH:mm z`) : '-'
  },

  showSampleDataButton () {
    return [
      'https://lintum-dev.azurewebsites.net',
      'https://lintum-dev-de.azurewebsites.net',
      'https://app-test.lintum.com',
      'https://demo.lintum.com'].includes(window.location.origin) || window.location.origin.startsWith('http://localhost')
  }
}
