<template>
  <v-dialog
    :value="visible"
    @input="value => { $emit('visibilityChange', value); resetLawFirm() }"
    width="700"
    persistent
    no-click-animation
  >
    <v-card v-click-outside-dialog="closeEvent">
      <v-card-title
        class="headline"
        primary-title
      >
        {{ `userManagement.lawFirmDialog.title.${title}` | t }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <span class="mandatory">{{ 'userManagement.lawFirmDialog.name' | t }}</span>
        <v-text-field
          label=""
          v-model="lawFirm.name"
          @input="removeMissingMandatoryField('name')"
          :placeholder="'userManagement.lawFirmDialog.placeholder.name' | t "
          :class="{missing: missingMandatoryFields.includes('name')}"
          solo
          flat
        ></v-text-field>

        <span class="mandatory">{{ 'userManagement.lawFirmDialog.street' | t }}</span>
        <v-text-field
          label=""
          v-model="lawFirm.street"
          @input="removeMissingMandatoryField('street')"
          :placeholder="'userManagement.lawFirmDialog.placeholder.street' | t "
          :class="{missing: missingMandatoryFields.includes('street')}"
          solo
          flat
        ></v-text-field>

        <span class="mandatory">{{ 'userManagement.lawFirmDialog.city' | t }}</span>
        <v-text-field
          label=""
          v-model="lawFirm.city"
          @input="removeMissingMandatoryField('city')"
          :items="primaryRoleOptions"
          :placeholder="'userManagement.lawFirmDialog.placeholder.city' | t "
          :class="{missing: missingMandatoryFields.includes('city')}"
          solo
          flat
        >
        </v-text-field>

        <span class="mandatory">{{ 'userManagement.lawFirmDialog.country' | t }}</span>
        <v-text-field
          label=""
          v-model="lawFirm.country"
          @input="removeMissingMandatoryField('country')"
          :items="primaryRoleOptions"
          :placeholder="'userManagement.lawFirmDialog.placeholder.country' | t "
          :class="{missing: missingMandatoryFields.includes('country')}"
          solo
          flat
        >
        </v-text-field>

        <span class="mandatory">{{ 'userManagement.lawFirmDialog.taxId' | t }}</span>
        <v-text-field
          label=""
          v-model="lawFirm.taxId"
          @input="removeMissingMandatoryField('taxId')"
          :items="primaryRoleOptions"
          :placeholder="'userManagement.lawFirmDialog.placeholder.taxId' | t "
          :class="{missing: missingMandatoryFields.includes('taxId')}"
          solo
          flat
        >
        </v-text-field>

        <v-checkbox v-model="lawFirm.active" class="check" :label="'userManagement.lawFirmDialog.active' | t"></v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="cancel">{{ 'buttons.cancel' | t }}</v-btn>
         <AwaitButton
          class="main"
          :callback="createLawFirmLocal">
          {{ 'userManagement.lawFirmDialog.submit' | t }}
        </AwaitButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import AwaitButton from '../../generalComponents/AwaitButton'

export default {
  name: 'AddLawFirmDialog',
  components: { AwaitButton },
  model: {
    prop: 'visible',
    event: 'visibilityChange'
  },
  props: {
    // Wether the dialog should be visible
    // Used with v-model (see model definition above)
    visible: {
      type: Boolean,
      required: false,
      default: false
    },

    lawFirmToUpdate: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      title: 'add',
      lawFirm: {
        name: '',
        street: '',
        city: '',
        country: '',
        taxId: '',
        active: false
      }
    }
  },
  watch: {
    lawFirmToUpdate () {
      if (this.lawFirmToUpdate) {
        this.title = 'update'
        this.lawFirm = { ...this.lawFirmToUpdate }
      } else {
        this.resetLawFirm()
      }
    }
  },
  computed: {
    ...mapGetters(['primaryRoleOptions']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    })
  },
  methods: {
    ...mapActions(['validate', 'createLawFirm', 'updateLawFirm']),
    ...mapMutations(['removeMissingMandatoryField', 'resetMandatoryFields']),

    async createLawFirmLocal () {
      const requiredFields = ['name', 'street', 'city', 'country', 'taxId']
      this.validate({ requiredFields, object: this.lawFirm })

      if (this.missingMandatoryFields.length > 0) {
        return
      }

      if (typeof this.lawFirm.id === 'undefined') {
        await this.createLawFirm(this.lawFirm)
      } else {
        await this.updateLawFirm(this.lawFirm)
      }

      this.$emit('visibilityChange', false)
      this.resetLawFirm()
    },

    cancel () {
      this.resetLawFirm()
      this.resetMandatoryFields()
      this.$emit('visibilityChange', false)
    },

    resetLawFirm () {
      this.title = 'add'
      this.lawFirm = {
        name: '',
        street: '',
        city: '',
        country: '',
        taxId: '',
        active: false
      }
    },

    closeEvent (event) {
      if (this.visible) {
        this.cancel()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .v-card
    border-radius 10px
    .v-card__actions
      padding 16px 45px 24px 45px
    .v-card__title
      padding 24px 45px 16px 45px

  .v-card__text
    padding 16px 45px
    span
      color rgb(158, 158, 158)
      padding-left 12px

  :deep(.v-input__slot)
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  .check {
    margin-top: 0px;

    :deep(.v-input__slot) {
      border: none;
    }

    :deep(.v-icon) {
      color: $dark-gray !important;
    }
  }

  :deep(.v-text-field__details),
  :deep(.v-messages)
    display none
</style>
