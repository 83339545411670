<template>
  <div class="main-area">
    <div style="display: grid; grid-template-columns: 200px 20px 30px 1fr 30px;">

      <div class="legend">
        <div v-if="hasAnyQualityScore" class="quality">

          <span>{{ 'activeRFP.decision.overallScore' | t }}</span>

          <div class="weighing">
            <div>
              <span class="light">{{ 'activeRFP.decision.proposalScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                class="small-input"
                :disabled="disabled"
                v-model="proposalScoreLocal"
                @input="updateLocalWeight('proposal')"
                :min="0"
                :max="100" />
            </div>

            <div>
              <span class="light">{{ 'activeRFP.decision.priceScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                :disabled="disabled"
                class="small-input"
                v-model="priceScoreLocal"
                @input="updateLocalWeight('price')"
                :min="0"
                :max="100" />
            </div>
            <v-btn :disabled="disabled" class="main update" @click="updateWeight">{{ 'activeRFP.decision.applyWeights' | t }}</v-btn>

          </div>
        </div>

        <div v-if="hasAnyQualityScore" class="proposal" :style="proposalBoxStyle">
          <span>{{ 'activeRFP.decision.proposalScore' | t }}</span>

          <div class="weighing" v-if="!isNonMigratedRfp">
            <div v-if="hasQualityScore('TEAM')">
              <span class="light">{{ 'activeRFP.decision.teamScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                class="small-input"
                :disabled="disabled"
                :invalid="triedToSaveProposalWeighing"
                v-model="teamScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>
            <div v-if="hasQualityScore('CREDENTIALS')">
              <span class="light">{{ 'activeRFP.decision.credentialsScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                class="small-input"
                :disabled="disabled"
                :invalid="triedToSaveProposalWeighing"
                v-model="credentialsScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>
            <div v-if="hasQualityScore('PITCH_DOCUMENT')">
              <span class="light">{{ 'activeRFP.decision.pitchDocumentScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                class="small-input"
                :disabled="disabled"
                :invalid="triedToSaveProposalWeighing"
                v-model="pitchDocumentScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>

            <div v-if="hasQualityScore('LIABILITY')">
              <span class="light">{{ 'activeRFP.decision.liabilityScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                :disabled="disabled"
                :invalid="triedToSaveProposalWeighing"
                class="small-input"
                v-model="liabilityScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>
            <div v-if="hasQualityScore('QA')">
              <span class="light">{{ 'activeRFP.decision.qaScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                :disabled="disabled"
                :invalid="triedToSaveProposalWeighing"
                class="small-input"
                v-model="qaScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>
            <div v-if="hasQualityScore('INTERVIEW')">
              <span class="light">{{ 'activeRFP.decision.interviewScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                :invalid="triedToSaveProposalWeighing"
                :disabled="disabled"
                class="small-input"
                v-model="interviewScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>
            <div v-if="hasQualityScore('STRATEGY')">
              <span class="light">{{ 'activeRFP.decision.strategyScoreWeight' | t }}</span>
              <NumberField
                suffix="%"
                :invalid="triedToSaveProposalWeighing"
                :disabled="disabled"
                class="small-input"
                v-model="strategyScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>
            <div v-if="hasQualityScore('CUSTOM1')">
              <span class="light">{{ currentRfpCustomQualityScoreTitle('CUSTOM1') }}</span>
              <NumberField
                suffix="%"
                :invalid="triedToSaveProposalWeighing"
                :disabled="disabled"
                class="small-input"
                v-model="custom1ScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>
            <div v-if="hasQualityScore('CUSTOM2')">
              <span class="light">{{ currentRfpCustomQualityScoreTitle('CUSTOM2') }}</span>
              <NumberField
                suffix="%"
                :invalid="triedToSaveProposalWeighing"
                :disabled="disabled"
                class="small-input"
                v-model="custom2ScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>
            <div v-if="hasQualityScore('CUSTOM3')">
              <span class="light">{{ currentRfpCustomQualityScoreTitle('CUSTOM3') }}</span>
              <NumberField
                suffix="%"
                :invalid="triedToSaveProposalWeighing"
                :disabled="disabled"
                class="small-input"
                v-model="custom3ScoreWeightLocal"
                @input="triedToSaveProposalWeighing = false"
                :min="0"
                :max="100" />
            </div>

            <span
              class="error--text"
              style="font-size: 14px"
              v-if="triedToSaveProposalWeighing">{{ $t('activeRFP.decision.error') }}</span>
            <v-btn :disabled="disabled" class="main update" @click="changeQualityScoreWeightLocal">{{ 'activeRFP.decision.applyWeights' | t }}</v-btn>

          </div>
        </div>

        <div class="price" :class="{quality: !hasAnyQualityScore}">
          <span>{{ 'activeRFP.decision.price' | t }}</span>
        </div>

      </div>

      <div></div>

      <div style="z-index: 1">
        <div v-if="currentPage !== 0" class="slider-nav elevation-10" @click="moveBack" :style="hasAnyQualityScore ? 'height: 100%;' : 'height: 335px;'">
          <v-icon>chevron_left</v-icon>
        </div>
      </div>

      <carousel :perPage="3" style="overflow-x: hidden;" ref="carousel" :paginationEnabled="false" @pageChange="updatePage" :mouse-drag="false">
        <slide v-for="(card, idx) in cards" :key="card.lawFirmName" :style="hasAnyQualityScore ? 'height: ' + (823 + proposalHeight - 258) + 'px;' : 'height: 350px;'">
          <DecisionCard
            :lawFirmId="card.lawFirmId"
            :lawFirmName="card.lawFirmName"
            :hasAnyQualityScore="hasAnyQualityScore"
            :overallScore="card.overallScore"
            :proposalScore="card.proposalScore"
            :qualityScoreCriteria="card.qualityScoreCriteria"
            :proposalHeight="proposalHeight"
            @choose="id => { selectedLawFirmId = id; showConfirmationDialog = true }"
            @rate="rate"
            :disabled="disabled"
            :bestPrice="card.bestPrice"
            :bestRating="card.bestRating"
            :rank="idx+1"
            :finalBid="card.finalBid" />
        </slide>
      </carousel>

      <div style="z-index: 1">
        <div v-if="currentPage !== maxPage - 1" class="slider-nav elevation-10" @click="moveForward" :style="hasAnyQualityScore ? 'height: 100%;' : 'height: 335px;'">
          <v-icon>chevron_right</v-icon>
        </div>
      </div>

    </div>

    <h2>{{ 'activeRFP.decision.messages' | t }}</h2>
    <v-card class="grid">
      <template v-if="editingMessages">
        <div style="grid-column-start: 1; grid-column-end: 3;">
          <span class="light">{{ 'activeRFP.decision.notes' | t }}</span>
          <v-textarea
            label=""
            v-model="decisionNotes"
            solo flat></v-textarea>
        </div>

        <div style="grid-column-start: 1; grid-column-end: 3;">
          <span class="light">{{ 'activeRFP.decision.winnerMessage' | t }}</span>
          <v-textarea
            label=""
            v-model="winnerMessage"
            solo flat></v-textarea>
        </div>

        <div style="grid-column-start: 1; grid-column-end: 3;">
          <span class="light">{{ 'activeRFP.decision.loserMessage' | t }}</span>
          <v-textarea
            label=""
            v-model="loserMessage"
            solo flat></v-textarea>
        </div>

        <div class="actions">
          <v-btn flat @click="cancel" >{{ 'buttons.cancel' | t }}</v-btn>
          <v-btn class="main" @click="save" >{{ 'activeRFP.proposal.saveLiability' | t }}</v-btn>
        </div>
      </template>

      <template v-else>

        <div :style="`grid-column-start: 1; grid-column-end: 2`">
          <span class="light">{{ 'activeRFP.decision.notes' | t }}</span>
          <p class="show-whitespace">{{(!currentRfp.decisionNotes || currentRfp.decisionNotes.length === 0)? $t('activeRFP.decision.decisionNotesPlaceholder') : currentRfp.decisionNotes}}</p>
        </div>
        <v-btn class="edit-messages" icon @click="editMessages" v-if="!currentRfp.winner && !disabled"><v-icon style="font-size: 18px">ic-edit</v-icon></v-btn>
        <div style="grid-column-start: 1; grid-column-end: 3">
          <span class="light">{{ 'activeRFP.decision.winnerMessage' | t }}</span>
          <p class="show-whitespace">{{(!currentRfp.winnerMessage || currentRfp.winnerMessage.length === 0)? $t('activeRFP.decision.lawfirmWonMessagePlaceholder') : currentRfp.winnerMessage}}</p>
        </div>
        <div style="grid-column-start: 1; grid-column-end: 3">
          <span class="light">{{ 'activeRFP.decision.loserMessage' | t }}</span>
          <p class="show-whitespace">{{(!currentRfp.loserMessage || currentRfp.loserMessage.length === 0)? $t('activeRFP.decision.lawfirmLostMessagePlaceholder'): currentRfp.loserMessage}}</p>
        </div>
      </template>
    </v-card>

    <ConfirmDialog
      :btnText="'buttons.chooseFirm'"
      :text="$t('activeRFP.chooseLawFirm.chooseFirmQuestion', { lawFirmName: getLawFirm(selectedLawFirmId).name })"
      :title="$t('activeRFP.chooseLawFirm.confirmLawformDialog')"
      v-if="showConfirmationDialog"
      @close="showConfirmationDialog = false"
      @submit="confirmSelection" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import DecisionCard from './DecisionCard'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'
import NumberField from '../../generalComponents/NumberField'
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Decision',
  components: { NumberField, ConfirmDialog, DecisionCard, Carousel, Slide },
  data () {
    return {
      selectedLawFirmId: null,
      showConfirmationDialog: false,
      currentPage: 0,
      maxPage: 1,
      proposalScoreLocal: null,
      priceScoreLocal: null,
      displayTable: true,
      editingMessages: false,
      decisionNotes: '',
      winnerMessage: '',
      loserMessage: '',
      teamScoreWeightLocal: 0,
      credentialsScoreWeightLocal: 0,
      pitchDocumentScoreWeightLocal: 0,
      liabilityScoreWeightLocal: 0,
      qaScoreWeightLocal: 0,
      interviewScoreWeightLocal: 0,
      strategyScoreWeightLocal: 0,
      custom1ScoreWeightLocal: 0,
      custom2ScoreWeightLocal: 0,
      custom3ScoreWeightLocal: 0,
      triedToSaveProposalWeighing: false
    }
  },
  created () {
    this.proposalScoreLocal = this.proposalScoreWeight
    this.priceScoreLocal = this.priceScoreWeight

    this.teamScoreWeightLocal = this.hasQualityScore('TEAM') ? this.currentRfp.teamScoreWeight * 100 : 0
    this.credentialsScoreWeightLocal = this.hasQualityScore('CREDENTIALS') ? this.currentRfp.credentialsScoreWeight * 100 : 0
    this.pitchDocumentScoreWeightLocal = this.hasQualityScore('PITCH_DOCUMENT') ? this.currentRfp.pitchDocumentScoreWeight * 100 : 0
    this.liabilityScoreWeightLocal = this.hasQualityScore('LIABILITY') ? this.currentRfp.liabilityScoreWeight * 100 : 0
    this.qaScoreWeightLocal = this.hasQualityScore('QA') ? this.currentRfp.qaScoreWeight * 100 : 0
    this.interviewScoreWeightLocal = this.hasQualityScore('INTERVIEW') ? this.currentRfp.interviewScoreWeight * 100 : 0
    this.strategyScoreWeightLocal = this.hasQualityScore('STRATEGY') ? this.currentRfp.strategyScoreWeight * 100 : 0
    this.custom1ScoreWeightLocal = this.hasQualityScore('CUSTOM1') ? this.currentRfp.custom1ScoreWeight * 100 : 0
    this.custom2ScoreWeightLocal = this.hasQualityScore('CUSTOM2') ? this.currentRfp.custom2ScoreWeight * 100 : 0
    this.custom3ScoreWeightLocal = this.hasQualityScore('CUSTOM3') ? this.currentRfp.custom3ScoreWeight * 100 : 0
  },
  computed: {
    ...mapGetters(['currentRfpCustomQualityScoreTitle', 'userIsLawFirm', 'userIsCorporate', 'currentRfpLawFirmRelation', 'formatNumber']),
    ...mapState({
      currentRfp: state => state.currentRfp,
      lawFirms: state => state.lawFirms
    }),

    isNonMigratedRfp () {
      return this.currentRfp.teamScoreWeight === null &&
        this.currentRfp.credentialsScoreWeight === null &&
        this.currentRfp.pitchDocumentScoreWeight === null &&
        this.currentRfp.liabilityScoreWeight === null &&
        this.currentRfp.qaScoreWeight === null &&
        this.currentRfp.interviewScoreWeight === null
    },

    scoresSumUp () {
      return (
        this.teamScoreWeightLocal +
        this.credentialsScoreWeightLocal +
        this.pitchDocumentScoreWeightLocal +
        this.liabilityScoreWeightLocal +
        this.qaScoreWeightLocal +
        this.interviewScoreWeightLocal +
        this.strategyScoreWeightLocal +
        this.custom1ScoreWeightLocal +
        this.custom2ScoreWeightLocal +
        this.custom3ScoreWeightLocal
      ) === 100
    },

    disabled () {
      return !!this.currentRfp.winner || this.currentRfp.status === 'COMPLETED_ABORTED_BY_CORPORATE'
    },

    hasAnyQualityScore () {
      if (this.currentRfp.terms.qualityScoreCriteria.length) {
        return true
      } else {
        return false
      }
    },

    proposalBoxStyle () {
      return 'height: min-content' // `height: ${this.proposalHeight}px`
    },

    proposalHeight () {
      const size = this.currentRfp.terms.qualityScoreCriteria.length
      const addiotion = !this.scoresSumUp ? 55 : 55
      switch (size) {
        case 3: return 308 + addiotion
        case 4: return 328 + addiotion
        case 5: return 378 + addiotion
        case 6: return 428 + addiotion
        case 7: return 478 + addiotion
        case 8: return 528 + addiotion
        case 9: return 578 + addiotion
        case 10: return 628 + addiotion
      }
      return 258 + addiotion
    },

    proposalScoreWeight () {
      return this.currentRfp.proposalScoreWeight * 100
    },

    priceScoreWeight () {
      return 100 - this.proposalScoreWeight
    },

    sortedLawFirmRelations () {
      return [...this.currentRfp.lawFirmRelations].sort((a, b) => { return a.lawFirm.name < b.lawFirm.name ? -1 : 1 })
    },

    cards () {
      let cards = this.currentRfp.lawFirmRelations
        .filter(lawFirmRelation => lawFirmRelation.currentDisplayBid && (lawFirmRelation.proposalSubmitted || this.currentRfp.terms.qualityScoreCriteria.filter(x => x !== 'INTERVIEW' && x !== 'QA').length === 0))
        .map(lawFirmRelation => {
          return {
            lawFirmId: lawFirmRelation.lawFirm.id,
            lawFirmName: this.getLawFirm(lawFirmRelation.lawFirm.id).name,
            overallScore: lawFirmRelation.overallStarRating,
            proposalScore: lawFirmRelation.proposalRating,
            priceRating: lawFirmRelation.priceRating,
            qualityScoreCriteria: this.relationRated(lawFirmRelation) ? {
              team: this.team(lawFirmRelation),
              credentials: this.credentials(lawFirmRelation),
              pitchDocument: this.pitchDocument(lawFirmRelation),
              liability: this.liability(lawFirmRelation),
              qa: this.qa(lawFirmRelation),
              interview: this.interview(lawFirmRelation),
              strategy: this.strategy(lawFirmRelation),
              custom1: this.custom(lawFirmRelation, 'CUSTOM1'),
              custom2: this.custom(lawFirmRelation, 'CUSTOM2'),
              custom3: this.custom(lawFirmRelation, 'CUSTOM3')
            } : null,
            finalBid: lawFirmRelation.currentDisplayBid
          }
        })
      const cardsWithOverallScore = cards.filter(card => card.overallScore).sort((a, b) => b.overallScore - a.overallScore)
      const cardsWithoutOverallScore = cards.filter(card => !card.overallScore).sort((a, b) => b.priceRating - a.priceRating)
      const orderedCards = [...cardsWithOverallScore, ...cardsWithoutOverallScore]

      if (orderedCards.length > 0) {
        orderedCards[orderedCards.findIndex((card, index) => card.lawFirmId === this.bestFieldId(cards, 'finalBid'))].bestPrice = true
        if (this.isAnyFirmRated) {
          let bestRated = orderedCards[0]
          for (const card of orderedCards) {
            if (card.proposalScore > bestRated.proposalScore) {
              bestRated = card
            }
          }
          bestRated.bestRating = true
          // orderedCards[orderedCards.findIndex((card, index) => card.lawFirmId === this.bestFieldId(cards, 'proposalScore'))].bestRating = true
        }
      }
      return orderedCards
    },

    isAnyFirmRated () {
      return this.currentRfp.lawFirmRelations
        .filter(lawFirmRelation => lawFirmRelation.currentDisplayBid && lawFirmRelation.proposalSubmitted)
        .some(lawFirmRelation => lawFirmRelation.proposal.every(proposal => proposal.rating !== null))
    }

  },
  mounted () {
    this.decisionNotes = this.currentRfp.decisionNotes
    this.winnerMessage = this.currentRfp.winnerMessage
    this.loserMessage = this.currentRfp.loserMessage
    this.maxPage = this.$refs.carousel.pageCount
    this.$nextTick(() => {
      if (this.currentRfp.winner) {
        this.$refs.carousel.goToPage(Math.round(this.cards.findIndex((it) => { return it.lawFirmId === this.currentRfp.winner.id }) / 2.95))
        this.currentPage = this.$refs.carousel.currentPage
      }
    })
  },
  methods: {
    ...mapActions(['chooseRfpWinner', 'updateProposalScoreWeight', 'changeQualityScoreWeight', 'setRfpDecisionMessages']),
    ...mapMutations(['openLawFirmRelationInRating', 'changeStatus']),

    updatePage () {
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    },

    moveBack () {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    },

    moveForward () {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    },

    editMessages () {
      this.editingMessages = true
    },

    async confirmSelection () {
      await this.chooseRfpWinner({ rfpId: this.currentRfp.id, lawFirmId: this.selectedLawFirmId })
      this.showConfirmationDialog = false
      this.changeStatus({ rfpId: this.currentRfp.id, status: 'COMPLETED_MATTER_IN_PROGRESS' })
      this.$router.push('/matters')
    },

    rate (lawFirmId) {
      this.openLawFirmRelationInRating(lawFirmId)
      this.$emit('changeCardView')
      this.$router.push({
        name: 'activeRfp',
        params: {
          activeTab: 'proposal'
        }
      })
    },

    cancel () {
      this.editingMessages = false
      this.decisionNotes = this.currentRfp.decisionNotes
      this.winnerMessage = this.currentRfp.winnerMessage
      this.loserMessage = this.currentRfp.loserMessage
    },

    save () {
      this.editingMessages = false
      this.currentRfp.decisionNotes = this.decisionNotes
      this.currentRfp.winnerMessage = this.winnerMessage
      this.currentRfp.loserMessage = this.loserMessage

      this.setRfpDecisionMessages()
    },

    updateLocalWeight (type) {
      if (type === 'price') {
        this.proposalScoreLocal = 100 - this.priceScoreLocal
      } else {
        this.priceScoreLocal = 100 - this.proposalScoreLocal
      }
    },

    changeQualityScoreWeightLocal () {
      if (!this.scoresSumUp) {
        this.triedToSaveProposalWeighing = true
        return
      }

      const {
        teamScoreWeightLocal,
        credentialsScoreWeightLocal,
        pitchDocumentScoreWeightLocal,
        liabilityScoreWeightLocal,
        qaScoreWeightLocal,
        interviewScoreWeightLocal,
        strategyScoreWeightLocal,
        custom1ScoreWeightLocal,
        custom2ScoreWeightLocal,
        custom3ScoreWeightLocal
      } = this
      this.changeQualityScoreWeight({
        teamScoreWeight: (teamScoreWeightLocal) || 0,
        credentialsScoreWeight: (credentialsScoreWeightLocal) || 0,
        pitchDocumentScoreWeight: (pitchDocumentScoreWeightLocal) || 0,
        liabilityScoreWeight: (liabilityScoreWeightLocal) || 0,
        qaScoreWeight: (qaScoreWeightLocal) || 0,
        interviewScoreWeight: (interviewScoreWeightLocal) || 0,
        strategyScoreWeight: (strategyScoreWeightLocal) || 0,
        custom1ScoreWeight: (custom1ScoreWeightLocal) || 0,
        custom2ScoreWeight: (custom2ScoreWeightLocal) || 0,
        custom3ScoreWeight: (custom3ScoreWeightLocal) || 0
      })
    },

    updateWeight () {
      this.updateProposalScoreWeight(this.proposalScoreLocal / 100)
    },

    relationRated (relation) {
      const team = this.team(relation)
      let teamRated = false

      if (team) {
        teamRated = team.subItems.some(member => member.rating !== null)
      }

      const credentials = this.credentials(relation)
      const liability = this.liability(relation)
      const pitchDocument = this.pitchDocument(relation)
      const strategy = this.strategy(relation)
      const custom1 = this.custom(relation, 'CUSTOM1')
      const custom2 = this.custom(relation, 'CUSTOM2')
      const custom3 = this.custom(relation, 'CUSTOM3')

      return (!credentials || credentials.rating !== null) &&
        (!liability || liability.rating !== null) &&
        (!strategy || strategy.rating !== null) &&
        (!custom1 || custom1.rating !== null) &&
        (!custom2 || custom2.rating !== null) &&
        (!custom3 || custom3.rating !== null) &&
        (!pitchDocument || pitchDocument.rating !== null) /* &&
        (!team || teamRated) */
    },

    hasQualityScore (qualityScoreCriteria) {
      return this.currentRfp.terms.qualityScoreCriteria.includes(qualityScoreCriteria)
    },

    getLawFirm (lawFirmId) {
      return this.lawFirms.find(lawfirm => lawfirm.id === lawFirmId)
    },

    getLocation (lawFirmId) {
      const lawFirm = this.getLawFirm(lawFirmId)
      return lawFirm.city + ', ' + lawFirm.country
    },

    toggle (relation) {
      if (
        !this.team(relation) &&
        !this.credentials(relation) &&
        !this.liability(relation) &&
        !this.pitchDocument(relation)) {
        return
      }

      if (relation.showProposal) {
        relation.showProposal = !relation.showProposal
      } else {
        this.$set(relation, 'showProposal', true)
      }
    },

    credentials (relation) {
      if (this.hasQualityScore('CREDENTIALS')) {
        return relation.proposal.find(category => !category.draft && category.name === 'CREDENTIALS')
      }

      return null
    },

    liability (relation) {
      if (this.hasQualityScore('LIABILITY')) {
        return relation.proposal.find(category => !category.draft && category.name === 'LIABILITY')
      }

      return null
    },

    team (relation) {
      if (this.hasQualityScore('TEAM')) {
        return relation.proposal.find(category => !category.draft && category.name === 'TEAM')
      }

      return null
    },

    pitchDocument (relation) {
      if (this.hasQualityScore('PITCH_DOCUMENT')) {
        return relation.proposal.find(category => !category.draft && category.name === 'PITCH_DOCUMENT')
      }

      return null
    },

    qa (relation) {
      if (this.hasQualityScore('QA')) {
        return relation.proposal.find(category => !category.draft && category.name === 'QA')
      }

      return null
    },

    interview (relation) {
      if (this.hasQualityScore('INTERVIEW')) {
        return relation.proposal.find(category => !category.draft && category.name === 'INTERVIEW')
      }

      return null
    },

    strategy (relation) {
      if (this.hasQualityScore('STRATEGY')) {
        return relation.proposal.find(category => !category.draft && category.name === 'STRATEGY')
      }

      return null
    },

    custom (relation, name) {
      if (this.hasQualityScore(name)) {
        return relation.proposal.find(category => !category.draft && category.name === name)
      }

      return null
    },

    rateProposalLocal ({ categoryId, subItemId, value }) {
      this.rateProposal({ categoryId, subItemId, value })
    },

    bestFieldId (lawsArray, field) {
      let bestFieldId = [...lawsArray]
      let sort
      console.log('bestFieldId', bestFieldId)
      if (bestFieldId.length >= 1) {
        sort = bestFieldId.sort((a, b) => {
          if (field === 'proposalScore') {
            return a[field] < b[field] ? 1 : -1
          } else if (field === 'finalBid') {
            return a[field].relevantDisplayValue > b[field].relevantDisplayValue ? 1 : -1
          } else {
            return a[field] > b[field] ? 1 : -1
          }
        })
      }
      console.log(sort[0], sort[0].lawFirmId)
      return sort[0].lawFirmId
    }

  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .main-area
    padding-top 21px
    display grid
    grid-template-columns 1fr
    grid-column-gap 20px

  .weighing
    display grid
    grid-template-columns 1fr
    margin-top 10px

  .legend
    width 200px

  .quality,
  .proposal,
  .price
    border-top 1px solid lightgray

    span
      font-size 18px

      &.light
        font-size 16px

  .quality
    margin-top 121px
    height 220px

  .proposal
    height 258px

  .update
    margin-top 12px
    margin-right 0
    margin-left 0

  .slider-nav
    height 100%
    display flex
    align-items center
    justify-content  center
    background #fff
    border-radius 5px

    &:hover
      box-shadow 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)
      cursor pointer

  .small-input :deep(.v-input__control)
    height 32px
    min-height 32px

  .to-matter
    background-color $gold100
    color #fff
    border-radius 5px
    width: 200px

  .v-card
    border-radius 10px
    padding 26px
    :deep(.v-textarea)
      padding-top 0
      margin-top 0
      .v-input__slot
        border 1px solid $icon-gray
        margin 0
        border-radius 5px !important
      .v-input__slot:before
        border 0

  .edit-messages
    grid-column-start: 2
    height 24px
    width 24px
    justify-self right

  .grid
    display grid
    grid-template-columns 4fr auto

  h2
    margin-bottom: 12px
</style>
