<template>
  <div>
  <div class="summary-panel">
    <v-card class="grid">
      <template v-if="currentRfp.terms.usesTermsGenerator">
        <h3 v-if="feesApplicable">
          {{ 'newRFP.termsAndConditions.fees' | t }}
          <ChangeIcon v-if="termsFeesChanged" category></ChangeIcon>
        </h3>
        <div v-if="feesApplicable" class="sub-grid">
          <p class="dark" style="grid-column-start: 1; grid-column-end: 3;" v-if="currentRfp.terms.hasAlertThreshold && currentRfp.terms.feeStructure === 'FIXED'">
            {{ $t('newRFP.termsAndConditions.hasAlertThreshold', { FeeStructure: $t(`newRFP.termsAndConditions.alertThresholdFeeStructure.${currentRfp.terms.feeStructure}`) }) }} {{  currentRfp.terms.alertThreshold + ' %' }}
          </p>

          <p class="dark"
              v-if="currentRfp.terms.needsConsentToLeaveScope"
             style="grid-column-start: 1; grid-column-end: 3;">
            {{ 'newRFP.termsAndConditions.needsConsentToLeaveScope' | t }}
          </p>

          <template v-if="currentRfp.terms.feeStructure === 'RATE_CARD'">
            <span class="light"
                  v-if="isApplicable('internalCoordinationType')">
              {{ 'newRFP.termsAndConditions.internalCoordination'| t }}
            </span>
            <span class="light"
                  v-if="isApplicable('associatesExpenseType')">
              {{ 'newRFP.termsAndConditions.associatesInFirstYear'| t }}
            </span>

            <p class="dark"
               v-if="isApplicable('internalCoordinationType')"
               style="grid-column-start: 1;">
              {{ `dropDownOptions.InternalCoordinationType.${currentRfp.terms.internalCoordinationType}` | t }}
            </p>
            <p class="dark"
               v-if="isApplicable('associatesExpenseType')"
               :style="!isApplicable('internalCoordinationType') ? 'grid-column-start: 1' : ''">
              {{ `dropDownOptions.AssociatesExpenseType.${currentRfp.terms.associatesExpenseType}` | t }}
            </p>

            <span class="light"
                  v-if="isApplicable('travelExpenseType')"
                  style="grid-column-start:span 2">
              {{ 'newRFP.termsAndConditions.travelTime'| t }}
            </span>
            <p class="dark" v-if="isApplicable('travelExpenseType')">
              {{ `dropDownOptions.TravelExpenseType.${currentRfp.terms.travelExpenseType}` | t }}{{ currentRfp.terms.travelExpenseType === 'BILLABLE_TO_THRESHOLD' ? ': ' + currentRfp.terms.travelExpenseCap + '%': ''}}
            </p>

            <span
              v-if="isApplicable('traineesExpenseType')"
              class="light"
              style="grid-column-start:span 2">
              {{ 'newRFP.termsAndConditions.activitiesOfTrainees'| t }}
            </span>
            <p v-if="isApplicable('traineesExpenseType')"
               class="dark">
              {{ `dropDownOptions.TraineesExpenseType.${currentRfp.terms.traineesExpenseType}` | t }}
            </p>

            <span v-if="isApplicable('trainingExpenseType')"
                  class="light"
                  style="grid-column-start:span 2">
              {{ 'newRFP.termsAndConditions.trainingOfNewMembers'| t }}
            </span>
            <p v-if="isApplicable('trainingExpenseType')"
               class="dark">
              {{ `dropDownOptions.TrainingExpenseType.${currentRfp.terms.trainingExpenseType}` | t }}
            </p>
          </template>
        </div>

        <h3 v-if="expensesApplicable">
          {{ 'newRFP.termsAndConditions.expenses' | t }}
          <ChangeIcon v-if="termsExpensesChanged" category></ChangeIcon>
        </h3>
        <div class="sub-grid" v-if="expensesApplicable">
          <div v-if="isApplicable('generalFees')">
            <span class="light">{{ 'newRFP.termsAndConditions.generalFees'| t }}</span>
            <p v-if="currentRfp.terms.generalFees === 'LIMITED_BILLABLE'" class="dark">{{ $t(`newRFP.termsAndConditions.maxPercent`, {amount: currentRfp.terms.maximumFee})  }}</p>
            <p v-else class="dark">{{ `dropDownOptions.GeneralFees.${currentRfp.terms.generalFees}` | t }}</p>
          </div>

          <!-- <div v-if="currentRfp.terms.generalFees === 'LIMITED_BILLABLE'">
            <span class="light">{{ 'newRFP.termsAndConditions.maxPercent'| t }}</span>
            <p class="dark">{{ currentRfp.terms.maximumFee }}%</p>
          </div> -->

          <div v-if="isApplicable('flightExpenseType')" style="grid-column-start: 1">
            <span class="light">{{ 'newRFP.termsAndConditions.flight'| t }}</span>
            <p class="dark">
              {{ `dropDownOptions.FlightExpenseType.${currentRfp.terms.flightExpenseType}` | t }}{{ currentRfp.terms.flightExpenseType === 'OTHER' ? ': ' + currentRfp.terms.customFlightExpense : ''}}
            </p>
          </div>

          <div v-if="isApplicable('trainExpenseType')">
            <span class="light">{{ 'newRFP.termsAndConditions.train'| t }}</span>
            <p class="dark">{{ `dropDownOptions.TrainExpenseType.${currentRfp.terms.trainExpenseType}` | t }}{{ currentRfp.terms.trainExpenseType === 'OTHER' ? ': ' + currentRfp.terms.customTrainExpense : ''}}</p>
          </div>

          <div v-if="isApplicable('rentalCarExpenseType')">
            <span class="light">{{ 'newRFP.termsAndConditions.rentalCar'| t }}</span>
            <p class="dark">{{ `dropDownOptions.RentalCarExpenseType.${currentRfp.terms.rentalCarExpenseType}` | t }}{{ currentRfp.terms.rentalCarExpenseType === 'OTHER' ? ': ' + currentRfp.terms.customRentalCarExpense : ''}}</p>
          </div>

          <div v-if="isApplicable('hotelExpenseType')">
            <span class="light">{{ 'newRFP.termsAndConditions.hotel'| t }}</span>
            <p class="dark">{{ `dropDownOptions.HotelExpenseType.${currentRfp.terms.hotelExpenseType}` | t }}{{ currentRfp.terms.hotelExpenseType === 'OTHER' ? ': ' + currentRfp.terms.customHotelExpense : ''}}</p>
          </div>
        </div>

        <h3 v-if="isApplicable('billingType')">
          {{ 'newRFP.termsAndConditions.billing' | t }}
          <ChangeIcon v-if="termsBillingTypeChanged"></ChangeIcon>
        </h3>
        <p v-if="isApplicable('billingType')">{{ `dropDownOptions.BillingType.${currentRfp.terms.billingType}` | t }}{{ currentRfp.terms.billingType === 'OTHER' ? ': ' + currentRfp.terms.billingTypeSpecification : '' }}</p>

        <h3 v-if="isApplicable('liabilityType') && includeLiabilityCriteria()">
          {{ 'newRFP.termsAndConditions.liability' | t }}
          <ChangeIcon v-if="termsLiabilityChanged"></ChangeIcon>
        </h3>
        <div class="sub-grid" v-if="isApplicable('liabilityType') && includeLiabilityCriteria()">
          <div>
            <span class="light">{{ 'newRFP.termsAndConditions.liabilityType'| t }}</span>
            <p class="dark">{{ `dropDownOptions.LiabilityType.${currentRfp.terms.liabilityType}` | t }}</p>
          </div>

          <div v-if="currentRfp.terms.liabilityType === 'MINIMUM_AMOUNT'">
            <span class="light">{{ 'newRFP.termsAndConditions.liabilityMinAmount'| t }}</span>
            <p class="dark">{{ `${currentRfp.terms.liabilityAmount ? formatNumber(currentRfp.terms.liabilityAmount) : ''} ${currencySymbol()}` }}</p>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="override" style="grid-column: 1 / 3" v-html="currentRfp.terms.quickTermsAndConditions"></p>

        <template v-if="filteredAttachements.length">
          <h3 class="border-top">
            {{ "activeRFP.summarySection.termsAndConditions.documents" | t }}
            <ChangeIcon v-if="quickTermsFilesChanged"></ChangeIcon>
          </h3>
          <ul>
            <li v-for="attachment in filteredAttachements" :key="attachment.url">
              <FileChip :file="attachment" />
            </li>
          </ul>
        </template>
      </template>
    </v-card>
  </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import splitDateTime from '../../../../util/splitDateTime'
import FileChip from '../../../generalComponents/FileChip'
import ChangeIcon from '@/views/generalComponents/ChangeIcon'

export default {
  name: 'TermsConditionsPanel',
  components: { ChangeIcon, FileChip },
  props: {
    isNewRfpSummary: {
      type: Boolean,
      default: false,
      required: false
    },

    expensesApplicable: {
      type: Boolean,
      required: true
    },

    feesApplicable: {
      type: Boolean,
      required: true
    },

    isApplicable: {
      type: Function,
      required: true
    },

    currentRfp: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['quickTermsFilesChanged', 'formatNumber', 'currencySymbol', 'termsBillingTypeChanged', 'termsFeesChanged', 'termsLiabilityChanged', 'termsExpensesChanged']),
    filteredAttachements () {
      return this.currentRfp.attachments.filter(document => document.rfpFileType === 'TERMS')
    }
  },
  methods: {
    splitDateTime,

    includeLiabilityCriteria () {
      return !(this.currentRfp.terms.qualityScoreCriteria.length > 0 && this.currentRfp.terms.qualityScoreCriteria.includes('LIABILITY'))
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  .icon
    .v-icon
      color $dark-gray !important
      font-size 22px

  .name
    font-size 18px
    color $dark-gray
    text-align left
    font-weight bold

  .time-grid
    display grid
    grid-template-columns 1fr 1fr 1fr 1fr
    margin-bottom 25px

  .light
    color $icon-gray

  .dark
    color $dark-gray !important
    font-size 21px

  .strong
    color $dark-gray
    font-size 16px
    font-weight bold

  .sub-grid
    display: grid;
    grid-template-columns 1fr 1fr
    grid-column-gap 10px

  .sub-row-grid
    grid-template-rows repeat(10, 0.5fr)

  a
    text-decoration none
    color inherit

  li
    list-style none
</style>
