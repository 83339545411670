<template>
  <div>
    <div class="center-title" style="margin-bottom: 1.5em;">
      <h1>{{ "newRFP.scopeDefinition.scopeTitle" | t }}</h1>
    </div>

    <Tabs v-if="scopeGeneratorAvailable">
      <a :class="{active: currentRfp.usesScopeGenerator}" @click="updateCurrentRfp({ field: 'usesScopeGenerator', value: true })">{{ 'newRFP.scopeDefinition.scopeGeneratorLink' | t }}</a>
      <a :class="{active: !currentRfp.usesScopeGenerator}" @click="updateCurrentRfp({ field: 'usesScopeGenerator', value: false })">{{ "newRFP.scopeDefinition.quickScopeLink" | t }}</a>
    </Tabs>
    <v-divider v-else style="margin-bottom: 30px;"></v-divider>

    <ScopeGenerator v-if="currentRfp.usesScopeGenerator && scopeGeneratorAvailable" />
    <div class="main" v-else :style="rfpHasOldScope ? 'grid-template-columns: 1fr' : ''">
      <Sidebar
        v-if="!rfpHasOldScope"
        :components-array="components"
        :options-array="options"
        :alert-offset="false"
        :terms="true"
        :customOffset="scopeGeneratorAvailable ? -45 : 0"
      />
      <div class="content" >
        <template v-if="!rfpHasOldScope">
          <h2 id="facts" ref="facts" style="margin-bottom: 0.5em; font-size: 28px">{{ 'newRFP.scopeDefinition.facts' | t }}</h2>
          <QuickScopeEditor
            style="padding: 13px 26px;"
            :disabled="!isRfpFieldEditable()"
            :placeholder="'newRFP.scopeDefinition.placeholder.facts' | t"
            :value="currentRfp.freeScope.facts"
            id="editor1"
            @input="value =>{ removeMissingMandatoryField('freeScope/facts'); updateCurrentRfpFreeScope({ field: 'facts', value: value }) }"
            :class="{missing: missingMandatoryFields.includes('freeScope/facts')}"/>

          <h2 id="requestedServices" ref="requestedServices" style="margin-bottom: 0.5em; font-size: 28px">{{ 'newRFP.scopeDefinition.requestedServices' | t }}</h2>
          <QuickScopeEditor
            style="padding: 13px 26px;"
            :disabled="!isRfpFieldEditable()"
            :placeholder="'newRFP.scopeDefinition.placeholder.requestedServices' | t"
            :value="currentRfp.freeScope.requestedServices"
            id="editor2"
            @input="value =>{ removeMissingMandatoryField('freeScope/requestedServices'); updateCurrentRfpFreeScope({ field: 'requestedServices', value: value }) }"
            :class="{missing: missingMandatoryFields.includes('freeScope/requestedServices')}"/>

          <h2 id="governingLaw" ref="governingLaw" style="margin-bottom: 0.5em; font-size: 28px">{{ 'newRFP.scopeDefinition.governingLaw' | t }}</h2>
          <QuickScopeEditor
            style="padding: 13px 26px;"
            :disabled="!isRfpFieldEditable()"
            :placeholder="'newRFP.scopeDefinition.placeholder.governingLaw' | t"
            :value="currentRfp.freeScope.governingLaw"
            id="editor3"
            @input="value =>{ removeMissingMandatoryField('freeScope/governingLaw'); updateCurrentRfpFreeScope({ field: 'governingLaw', value: value }) }"
            :class="{missing: missingMandatoryFields.includes('freeScope/governingLaw')}"/>

          <h2 id="areasOfLaw" ref="areasOfLaw" style="margin-bottom: 0.5em; font-size: 28px">{{ 'newRFP.scopeDefinition.areasOfLaw' | t }}</h2>
          <QuickScopeEditor
            style="padding: 13px 26px;"
            :disabled="!isRfpFieldEditable()"
            :placeholder="'newRFP.scopeDefinition.placeholder.areasOfLaw' | t"
            :value="currentRfp.freeScope.areasOfLaw"
            id="editor4"
            @input="value =>{ removeMissingMandatoryField('freeScope/areasOfLaw'); updateCurrentRfpFreeScope({ field: 'areasOfLaw', value: value }) }"
            :class="{missing: missingMandatoryFields.includes('freeScope/areasOfLaw')}"/>

          <h2 id="timeline" ref="timeline" style="margin-bottom: 0.5em; font-size: 28px">{{ 'newRFP.scopeDefinition.timeline' | t }}</h2>
          <QuickScopeEditor
            style="padding: 13px 26px;"
            :disabled="!isRfpFieldEditable()"
            :placeholder="'newRFP.scopeDefinition.placeholder.timeline' | t"
            :value="currentRfp.freeScope.timeline"
            id="editor5"
            @input="value =>{ removeMissingMandatoryField('freeScope/timeline'); updateCurrentRfpFreeScope({ field: 'timeline', value: value }) }"
            :class="{missing: missingMandatoryFields.includes('freeScope/timeline')}"/>

          <h2 id="languages" ref="languages" style="margin-bottom: 0.5em; font-size: 28px">{{ 'newRFP.scopeDefinition.languages' | t }}</h2>
          <v-card
            style="padding: 13px 26px; border-radius: 10px; border: 2px solid rgba(0,0,0,0);" :class="{missing: missingMandatoryFields.includes('freeScope/languages')}">
            <v-select
              class="select"
              :disabled="!isRfpFieldEditable()"
              :value="currentRfp.freeScope.languages"
              @input="value =>{ removeMissingMandatoryField('freeScope/languages'); updateCurrentRfpFreeScope({ field: 'languages', value: value }) }"
              multiple
              outlined
              item-text="language"
              item-value="code"
              solo
              hide-details
              flat
              :items="languageItems"></v-select>
          </v-card>
        </template>
        <template v-else>
          <div>
            <QuickScopeEditor
              :placeholder="'newRFP.scopeDefinition.quickScopePlaceholder' | t"
              :disabled="!isRfpFieldEditable()"
              :value="currentRfp.quickScope"
              @input="value =>{ removeMissingMandatoryField('quickScope'); updateCurrentRfp({ field: 'quickScope', value: value }) }"
              :class="{missing: missingMandatoryFields.includes('quickScope')}"/>
          </div>
        </template>

        <h2 id="documentUpload" ref="documentUpload" style="margin-bottom: 0.25em; font-size: 28px">{{ 'newRFP.scopeDefinition.generatorDocuments' | t }}</h2>
        <DocumentUpload style="margin-bottom: 150px; margin-top: 20px" type="SCOPE" :disabled="!isRfpFieldEditable()" :documents="currentRfp.attachments" :deleteIcon="isRfpFieldEditable()" @deleteFile="value => deleteFiles(value)" />
      </div>
    </div>

    <ScrollDownIndicator v-if="currentRfp.usesScopeGenerator && scopeGeneratorAvailable" />
  </div>
</template>

<script>
import QuickScopeEditor from '../../generalComponents/QuickScopeEditor'
import DocumentUpload from '../../generalComponents/DocumentUpload'
import Tabs from '../../generalComponents/Tabs'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import ScopeGenerator from './ScopeGenerator'
import ScrollDownIndicator from '../../generalComponents/ScrollDownIndicator'
import Sidebar from '../../generalComponents/SideBar'
import languages from 'language-list'

export default {
  name: 'ScopeDefinition',
  components: {
    Sidebar,
    ScrollDownIndicator,
    ScopeGenerator,
    Tabs,
    QuickScopeEditor,
    DocumentUpload
  },
  data () {
    return {
      isOnPosition: false,
      quickScopeView: true,
      docUploadView: false,
      components: []
    }
  },
  computed: {
    ...mapGetters(['scopeGeneratorAvailable', 'rfpHasOldScope', 'currentRfpOrDraft', 'isRfpFieldEditable']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    }),

    currentRfp () {
      return this.currentRfpOrDraft
    },

    languageItems () {
      const lang = languages()
      const langSorted = lang.getData().sort((a, b) => a.language < b.language ? -1 : 1)
      const langDe = langSorted.find(item => item.code === 'de')
      if (langDe) langSorted.unshift(langDe)
      const langEn = langSorted.find(item => item.code === 'en')
      if (langEn) langSorted.unshift(langEn)

      return langSorted
    },

    quickScopeIcon () {
      if (this.currentRfp.quickScope) {
        return { icon: 'check_circle', status: true }
      } else {
        return { icon: 'ic-edit', status: false }
      }
    },

    options () {
      return [
        {
          name: this.$t('newRFP.scopeDefinition.facts'),
          idRef: 'facts',
          status: true,
          icon: this.missingMandatoryFields.includes('freeScope/facts') ? 'error' : (this.currentRfp.freeScope.facts ? 'check_circle' : 'ic-document'),
          classStatus: this.missingMandatoryFields.includes('freeScope/facts') ? 'invalid' : (this.currentRfp.freeScope.facts ? 'finished' : false),
          top: 85,
          subItemsIdRefs: []
        },
        {
          name: this.$t('newRFP.scopeDefinition.requestedServices'),
          idRef: 'requestedServices',
          status: false,
          icon: this.missingMandatoryFields.includes('freeScope/requestedServices') ? 'error' : (this.currentRfp.freeScope.requestedServices ? 'check_circle' : 'ic-layers'),
          classStatus: this.missingMandatoryFields.includes('freeScope/requestedServices') ? 'invalid' : (this.currentRfp.freeScope.requestedServices ? 'finished' : false),
          top: 85,
          subItemsIdRefs: []
        },
        {
          name: this.$t('newRFP.scopeDefinition.governingLaw'),
          idRef: 'governingLaw',
          status: false,
          icon: 'check_circle',
          classStatus: 'finished',
          top: 85,
          subItemsIdRefs: []
        },
        {
          name: this.$t('newRFP.scopeDefinition.areasOfLaw'),
          idRef: 'areasOfLaw',
          status: false,
          icon: 'check_circle',
          classStatus: 'finished',
          top: 85,
          subItemsIdRefs: []
        },
        {
          name: this.$t('newRFP.scopeDefinition.timeline'),
          idRef: 'timeline',
          status: false,
          icon: this.missingMandatoryFields.includes('freeScope/timeline') ? 'error' : (this.currentRfp.freeScope.timeline ? 'check_circle' : 'ic-calendar'),
          classStatus: this.missingMandatoryFields.includes('freeScope/timeline') ? 'invalid' : (this.currentRfp.freeScope.timeline ? 'finished' : false),
          top: 85,
          subItemsIdRefs: []
        },
        {
          name: this.$t('newRFP.scopeDefinition.languages'),
          idRef: 'languages',
          status: false,
          icon: this.missingMandatoryFields.includes('freeScope/languages') ? 'error' : (!!this.currentRfp.freeScope.languages && this.currentRfp.freeScope.languages.length ? 'check_circle' : 'ic-calendar'),
          classStatus: this.missingMandatoryFields.includes('freeScope/languages') ? 'invalid' : (!!this.currentRfp.freeScope.languages && this.currentRfp.freeScope.languages.length ? 'finished' : false),
          top: 85,
          subItemsIdRefs: []
        },
        {
          name: this.$t('newRFP.scopeDefinition.generatorDocuments'),
          idRef: 'documentUpload',
          status: false,
          icon: 'ic-attach',
          top: 85,
          subItemsIdRefs: []
        }
      ]
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    this.resetMandatoryViolations()
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    const result = []

    for (const option of this.options) {
      if (this.$refs[option.idRef] && (this.$refs[option.idRef][0] || this.$refs[option.idRef].id === option.idRef)) {
        if (this.$refs[option.idRef].id === option.idRef) {
          result.push(this.$refs[option.idRef])
        } else {
          this.$refs[option.idRef][0].$el.refVal = option.idRef
          result.push(this.$refs[option.idRef][0].$el)
        }
      }
    }

    this.components = result
  },
  methods: {
    ...mapActions(['deleteFile']),
    ...mapMutations(['updateCurrentRfp', 'updateCurrentRfpFreeScope', 'removeMissingMandatoryField', 'resetMandatoryViolations']),
    handleScroll (event) {
      const generalHeight = 198
      if (window.scrollY >= (generalHeight)) {
        this.isOnPosition = true
      } else {
        this.isOnPosition = false
      }
    },
    goIntoView (event) {
      const clickRef = event.target.id
      const actualPos = this.$refs[clickRef].getBoundingClientRect().top
      const scrollingPix = actualPos - 70
      window.scrollBy({ top: scrollingPix, left: 0, behavior: 'smooth' })
    },

    deleteFiles (event) {
      this.deleteFile({ rfpId: this.currentRfpOrDraft.id, fileId: event.id, type: 'SCOPE' })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"
  h1
    display inline-block

  .select
    >>> .v-input__slot
      border 1px solid rgb(158, 158, 158)
      border-radius 5px !important

    >>> .v-text-field__details
      display none

    >>> .v-input--checkbox .v-input__slot
      border none !important

    >>> .v-input--is-disabled .v-input__slot
      background $light-gray !important

    >>> .v-icon
      color $dark-gray !important
      font-size 20px !important
      width 24px

  .center-title
    font-size 32px
    margin-bottom 1.5em

  >>> .info-icon
    font-size 20px
    margin-left 5px
    margin-bottom 4px
    vertical-align middle
    .v-icon
      font-size 20px !important
      color: #ababb5 !important
      vertical-align middle

  .main
    display grid
    grid-template-columns 200px 1fr
    grid-column-gap 20px
    margin-top 1.5em

    .side-nav
      .fixed
          color white
          position fixed
          top 90px
          width 200px
      a
        display inline-block
        color gray
        font-weight bold
        width 100%
        padding 5px 10px
        border-radius 10px

        .v-icon
          font-size 16px
          color gray

        &.finished
            .v-icon
              color rgb(102, 174, 4) !important

        &.activeSide
          color $dark-gray
          background #ffffff
          &.finished
            .v-icon
              color rgb(46, 121, 2) !important

          .v-icon
            color $dark-gray !important

    .content
      h2
        margin-top 1.5em
        margin-bottom 0.5em
      h2:first-child
        margin-top 0
</style>
