<template>
  <div style="position: relative">
    <v-text-field
      solo
      flat
      class="date-field"
      :disabled="disabled"
      :class="{ invalidInput : invalidInput }"
      @keyup.enter="validateDateString(parseDate(inputValue))"
      @blur="datepickerVisible = false; validateDateString(parseDate(inputValue))"
      @focus="datepickerVisible = true"
      v-model="inputValue"
      :placeholder="placeholder"
      :append-icon="icon ? 'ic-calendar' : ''" />

    <datepicker
      v-show="datepickerVisible"
      class="datepicker"
      :inline="true"
      style="position: absolute; z-index: 10; top: 50px; margin-bottom: 20px"
      :full-month-name="true"
      :format="'dd.MM.yyyy'"
      :language="languages[language]"
      :monday-first="true"
      :value="valueForDatepicker"
      @input="dateInput"
      :bootstrap-styling="true" />
  </div>
</template>

<script>
/* Thrown events:

focus: thrown when a user clicks in the component to replace the standard focus event, which can not be caught in the parent otherwise
@payload: -

input: thrown when a valid input is made. Required for v-model implementation.
@payload: {string} the transformed date input

validationChange: thrown when the validation status changes
@payload: {boolean} true if the component is invalid, false otherwise
*/
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import * as lang from 'vuejs-datepicker/src/locale'

export default {
  name: 'DateField',
  mixins: [],
  components: {
    Datepicker
  },
  props: {
    /* needed for v-model */
    value: {
      type: String,
      default: ''
    },
    /* the placeholder for the input field */
    placeholder: {
      type: String,
      default: ''
    },
    /* whether this field should be marked as a missing mandatory field */
    disabled: {
      type: Boolean,
      default: false
    },
    // Marked as true to show the calendar icon inside the field
    icon: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      languages: lang,
      language: 'en',
      inputValue: '',
      invalidInput: false,
      datepickerVisible: false,
      valueForDatepicker: null,
      formatOptions: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
    }
  },
  created: function () {
    this.inputValue = this.value

    const dateParts = this.value.split('.')
    if (dateParts.length === 3) {
      this.valueForDatepicker = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
    }
  },
  watch: {
    /* Needed for v-model implementation. Sets the value of the inputValue to the 'value' property */
    value () {
      this.inputValue = this.value

      const dateParts = this.value.split('.')
      this.valueForDatepicker = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
    }
  },
  methods: {
    /* Adds an event listener to the document, so the calendar overlay is closed when the user clicks outside */
    addOutsideClickListener () {
      // timeout needed for correct behaviour
      setTimeout(() => {
        document.addEventListener('click', this.clickOutside, false)
      }, 150)
    },
    /* Close the calendar if clicked outside the datepicker */
    clickOutside (event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.datepickerVisible = false
        document.removeEventListener('click', this.clickOutside, false)
      }
    },
    /* Handles an input in the calendar overlay. Updates the input value and closes the overlay. */
    dateInput (value) {
      this.inputValue = new Date(value).toLocaleDateString(
        'de-DE',
        this.formatOptions
      )

      this.datepickerVisible = false
      document.removeEventListener('click', this.clickOutside, false)

      this.inputValue = value.toLocaleDateString(
        'de-DE',
        this.formatOptions
      )

      this.$emit('input', this.inputValue)

      this.valueForDatepicker = value
    },
    /* Parses the input, if valid, to the format DD.MM.YYYY */
    parseDate (dateString) {
      if (!dateString || dateString.length < 4) return ''

      if (
        !dateString.includes('-') &&
        !dateString.includes('.') &&
        !dateString.includes('/')
      ) {
        if (dateString.length === 4) {
          // input: DMYY
          dateString =
          '0' +
          dateString[1] +
          '/0' +
          dateString[0] +
          '/20' +
          dateString[2] +
          dateString[3]
        } else if (dateString.length === 5) {
          // input: D[D|M]MYY
          if (
            parseInt(dateString[0] + dateString[1]) > 31 ||
            dateString[2] === '0' ||
            (parseInt(dateString[0] + dateString[1]) > 29 &&
            dateString[2] === '2')
          ) {
            dateString =
            dateString[1] +
            dateString[2] +
            '/0' +
            dateString[0] +
            '/20' +
            dateString[3] +
            dateString[4]
          } else if (
            parseInt(dateString[1] + dateString[2]) > 12 ||
            dateString[1] === '0'
          ) {
            dateString =
            '0' +
            dateString[2] +
            '/' +
            dateString[0] +
            dateString[1] +
            '/20' +
            dateString[3] +
            dateString[4]
          }
        } else if (dateString.length === 6) {
          // input: DDMMYY
          dateString =
            dateString[2] +
            dateString[3] +
            '/' +
            dateString[0] +
            dateString[1] +
            '/20' +
            dateString[4] +
            dateString[5]
        } else if (dateString.length === 8) {
          // input: DDMMYYYY
          dateString =
            dateString[2] +
            dateString[3] +
            '/' +
            dateString[0] +
            dateString[1] +
            '/' +
            dateString[4] +
            dateString[5] +
            dateString[6] +
            dateString[7]
        }
      } else {
        // input: day, month and year seperated by . or - or /
        dateString = dateString
          .replace(new RegExp('-', 'g'), '.')
          .replace(new RegExp('/', 'g'), '.')

        var res = dateString.split('.')
        if (res.length === 3) {
          res[0] = res[0].length === 1 ? '0' + res[0] : res[0]
          res[1] = res[1].length === 1 ? '0' + res[1] : res[1]

          if (res[2].length === 2) res[2] = '20' + res[2]

          dateString = res[1] + '/' + res[0] + '/' + res[2]
        }
      }
      return dateString
    },
    /* Validates the passed date. Checks if the date is in the valid range (defined by date from and date to of the main contract)
        trhows the input event */
    validateDateString (dateString) {
      this.$emit('disableSave')
      if (dateString === '' || this.inputValue === '' || this.inputValue === null) {
        this.valueForDatepicker = ''
        this.$emit('input', null)

        return
      }

      // validate date
      var inputIsValidDate = this.dateIsValid(dateString)

      var dateInput = new Date(dateString)

      if (inputIsValidDate) {
        this.inputValue = dateInput.toLocaleDateString(
          'de-DE',
          this.formatOptions
        )

        this.$emit('input', this.inputValue)

        this.valueForDatepicker = dateString
      } else {
        this.invalidInput = this.inputValue
      }
    },
    /* Validates a date string of the following pattern: MM/DD/YYYY */
    dateIsValid (dateString) {
      const parts = dateString.split('/')

      const month = parseInt(parts[0], 10) - 1
      const date = parseInt(parts[1], 10)
      const year = parseInt(parts[2], 10)

      const jsDate = new Date(dateString)

      const isValid =
        jsDate.getFullYear() === year &&
        jsDate.getMonth() === month &&
        jsDate.getDate() === date

      return isValid
    }
  }
}
</script>

<style scoped lang="stylus">
/* Adds border radius to the datepicker components.
The >>> operator is needed, because the styles are scoped and would not affect a
sub-component otherwise. See https://vue-loader.vuejs.org/en/features/scoped-css.html
part 'Deep Selectors' for more information. */
.vdp-datepicker,
.vdp-datepicker>>>.vdp-datepicker__calendar {
  border-radius: 4px;
}

/* The hightlighted calender entry (first day of new planning year) is marked in yellow*/
.datepicker >>> span.highlighted {
  background: yellow !important;
  border-color: yellow !important;
  border-width: 3px !important;
}
/* The current selected date will always be marked in blue,
even though it may also be the first day of a new planning year.*/
.datepicker >>> span.selected {
  background: #4bd !important;
}

>>> .v-text-field .v-input__slot
  border 1px solid rgb(158, 158, 158)
  border-radius 5px !important

>>> .v-input--is-disabled .v-input__slot
  background $light-gray !important
</style>
