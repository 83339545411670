<template>
  <v-card class="elevation-2" :class="{ missing }">
    <div class="wrapper">
      <div class="content-wrapper">
        <editor
          :disabled="disabled"
          :value="value"
          @input="value => $emit('input', value)"
          :init="editorConfig"
          class="editor"
          style="max-height: 200px; overflow-y: auto"
          :placeholder="placeholder"></editor>
      </div>

      <div v-if="showQaActions" class="document-wrapper">
        <FileChip v-for="file in documents" :key="file.id" :file="file" deleteIcon @delete="$emit('deleteFile', file)"></FileChip>
      </div>

      <div class="toolbar-wrapper">
        <div :id="'toolbar' + id" class="toolbar" :class="{'full-width': !showQaActions}"></div>
        <div v-if="showQaActions">
          <v-btn style="float: left" flat fab :ripple="false" class="attach-btn" @click="$emit('uploadDocument')">
            <v-icon class="attach-icon">ic-attach</v-icon>
          </v-btn>

          <section style="float: right">
            <v-btn style="min-width: 60px" flat class="cancel-btn" @click="$emit('cancel')">{{ 'activeRFP.q&a.cancelAnswer' | t }}</v-btn>
            <AwaitButton
              :custom-loading="buttonDisable"
              class="submit-btn main elevation-3"
              emit-click
              @click="$emit('submit')"
              :circularWidth="'2'"
              :marginFromText="5"
              :circularSize="15" >
              {{ (questionTexts ? 'activeRFP.q&a.submitQuestion' : (isUpdate ? 'activeRFP.q&a.saveChanges' : 'activeRFP.q&a.submitAnswer')) | t }}
            </AwaitButton>
          </section>
        </div>
        <div class="q-a-actions" v-else />
      </div>

    </div>
  </v-card>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { mapState } from 'vuex'
import FileChip from './FileChip'
import AwaitButton from './AwaitButton'

export default {
  name: 'QuickScopeEditor',
  props: {
    // value of the editor
    // used with v-model
    value: {
      required: true
    },

    showQaActions: {
      type: Boolean,
      default: false,
      required: false
    },

    questionTexts: {
      type: Boolean,
      default: false,
      required: false
    },

    documents: {
      type: [Array, FileList],
      default: () => ([/* { id: 123, fileName: 'Sample.docx', url: '' } */]),
      required: false
    },

    isUpdate: {
      type: Boolean,
      default: false,
      required: false
    },

    id: {
      type: String,
      default: '',
      required: false
    },

    placeholder: {
      type: String,
      default: '',
      required: false
    },
    userIsCorporate: {
      type: Boolean,
      required: false,
      default: false
    },

    missing: {
      type: Boolean,
      default: false
    },

    // prop that controls the button for asyn execution of the Submit action
    buttonDisable: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FileChip,
    Editor,
    AwaitButton
  },
  computed: {
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    })
  },
  data () {
    return {
      content: '',
      editorConfig: {
        menubar: false,
        language: 'en',
        plugins: 'lists placeholder table textcolor colorpicker paste',
        paste_as_text: true,
        min_height: '350',
        resize: true,
        toolbar: this.showQaActions ? [
          (this.userIsCorporate ? '' : 'undo redo |') + ' fontsizeselect | bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table'
        ] : [
          'undo redo | fontsizeselect | bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | table | outdent indent'
        ],
        inline: true,
        fixed_toolbar_container: '#toolbar' + this.id,
        toolbar_items_size: 'large',
        init_instance_callback: function (editor) {
          // This will trick the editor into thinking it was focused
          // without actually focusing it (causing the toolbar to appear)
          editor.fire('focus')
        },
        setup: function (editor) {
          // This prevents the blur event from hiding the toolbar
          editor.on('blur', function () {
            return false
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
   @import "../../assets/css/variables.styl"

  .v-card
    padding 50px
    border-radius 10px
    border 2px solid rgba(0,0,0,0)
    &.missing
      border: 2px solid $red !important

  >>> .mce-statusbar
    display none

  >>> .mce-tinymce
    box-shadow none

  >>> .editor
    otuline none
    p, span
     font-size inherit

    ul > li, ol > li
      font-size 16px

    > p
      margin-bottom 5px
      font-size 16px
    > span
      font-size 16px

  .wrapper
    border 1px solid $gray40
    border-radius 0 !important

  .content-wrapper,
  >>> .content-wrapper > div
    padding 10px
    min-height 200px
    outline none
    line-break anywhere

  // .toolbar
  //   border none
  //   height 85px
  //   padding-top 0px
  //   padding-bottom 0px

    &.small
      height 43px

    // >>> .mce-toolbar:last-child
    //   border-top 1px solid $gray40

    >>> .mce-btn-large:hover
      border-color white

  >>> .mce-widget.mce-tooltip
    display none !important

  >>> .toolbar > div
    border none
    display inline-block

  .toolbar-wrapper
    border-top 1px solid $gray40
    display grid
    grid-template-columns 0.8fr auto

  .q-a-actions
    justify-self end
    display inline-flex

  //   display grid
  //   grid-template-columns 1fr 90px 150px
  //   height 43px
  //   border-bottom 1px solid $gray40

  .submit-btn,
  .cancel-btn
    border-radius 5px
    margin-top 3px

  .attach-btn
    height 48px
    margin 0
    margin-top -2px
    width 30px

    >>> .v-icon
      margin-top 0

    &:hover,
    &:active,
    &:focus
      &:before
        background none

  .attach-icon
    width 44px
    height 42px
    font-size 20px
    border-left 1px solid rgb(217, 217, 217)
    margin 2px 0
    padding-left 10px

    &:before
      color $maroon100 !important

  >>> .mce-ico:before
    color $maroon100 !important

  .document-wrapper
    padding 5px 10px

  >>> .plugin-placeholder.empty:before
    top -6px
    font-size 16px
    color $gray40

  >>> .mce-menubtn.mce-fixed-width span
    width 44px

  >>> .mce-btn-large button:not(.mce-splitbtn):not(.mce-open)
    padding 9px 10px
</style>
