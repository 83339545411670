import { render, staticRenderFns } from "./PasswordResetDialog.vue?vue&type=template&id=34920b8b&scoped=true&"
import script from "./PasswordResetDialog.vue?vue&type=script&lang=js&"
export * from "./PasswordResetDialog.vue?vue&type=script&lang=js&"
import style0 from "./PasswordResetDialog.vue?vue&type=style&index=0&id=34920b8b&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34920b8b",
  null
  
)

export default component.exports