<template>
  <v-card class="analytics-card">
    <div style="display: grid; grid-template-columns: 1fr auto">
      <h4>{{ cardTitle | t }}</h4>
      <div v-if="cardType === 'revenue' || cardType === 'transactionVolume'" class="currency-toggle">
        <a :class="{active: currencyFormat.terms.currency === 'USD'}" @click="setAnalyticsCurrency('USD')">{{ 'dropDownOptions.Currency.USD' | t }}</a>
        /
        <a :class="{active: currencyFormat.terms.currency === 'EUR'}" @click="setAnalyticsCurrency('EUR')">{{ 'dropDownOptions.Currency.EUR' | t }}</a>
      </div>
    </div>
    <v-divider></v-divider>

    <template v-if="cardType === 'rfp' && !userIsCorporate">
      <section class="dougnught-rfp" style="height: 175px;">
        <div id="rfp-container" style="height: 175px;"></div>
        <div class="ordered-items">
          <div class="grid-info" v-for="analyticData in analyticsInfo" :key="analyticData.period">
            <span><b>{{ `dashboard.analytics.AnalyticsPeriod.${analyticData.period}` | t }}</b></span>
            <span>{{ analyticData.rfpsWon }}/{{ analyticData.rfpsParticipated }} ({{ analyticData.rfpsParticipated > 0 ? Math.round(analyticData.rfpsWon / analyticData.rfpsParticipated * 100) : 0 }}%)</span>
          </div>
        </div>
      </section>
    </template>

    <template v-else-if="cardType === 'rfp' && userIsCorporate">
      <section class="time-periods">
        <div v-for="rfpHistory in analyticsInfo" :key="rfpHistory.period" :class="{'lifetime': rfpHistory.period === 'LIFETIME'}" :style="rfpHistory.period === 'LIFETIME' ? 'grid-column: 1 / span 3;' : ''">
          <template v-if="rfpHistory.period === 'LIFETIME'">
            <h1>{{ rfpHistory.totalRfps }}</h1>
            <span>{{ $t(`dashboard.analytics.${rfpHistory.period.toLowerCase()}Count`) }}</span>
          </template>
          <template v-else>
            <section class="subtimes">
              <span><b>{{ `dashboard.analytics.AnalyticsPeriod.${rfpHistory.period}` | t }}</b></span>
              <span>{{ rfpHistory.totalRfps }}</span>
            </section>
          </template>
        </div>
      </section>
    </template>

    <template v-if="cardType === 'revenue'">
      <section class="time-periods">
        <div v-for="time in analyticsInfo" :key="time.period" :class="{'lifetime': time.period === 'LIFETIME'}" :style="time.period === 'LIFETIME' ? 'grid-column: 1 / span 3;' : ''">
          <template v-if="time.period === 'LIFETIME'">
            <h1>{{ formatBidAccordingly(userIsCorporate ? time.savings : time.agreedRevenue, currencyFormat) }}</h1>
            <span>{{ $t(`dashboard.analytics.${time.period.toLowerCase()}${userIsCorporate ? 'Savings' : 'Revenue'}`) }}</span>
          </template>
          <template v-else>
            <section class="subtimes">
              <span><b>{{ `dashboard.analytics.AnalyticsPeriod.${time.period}` | t }}</b></span>
              <span>{{ formatBidAccordingly(userIsCorporate ? time.savings : time.agreedRevenue, currencyFormat) }}</span>
            </section>
          </template>
        </div>
      </section>
    </template>

    <template v-if="cardType === 'fee'">
      <section class="dougnught-rfp" style="max-height: 175px;">
        <div id="fee-container" style="max-height: 175px;"></div>
        <div class="ordered-items">
          <div class="breakdown-info" v-for="(breakdown, index) in cardBreakdown" :key="breakdown.name">
            <span v-if="breakdown.name !== 'total'" class="dot" :style="`background-color: ${colors[index]}`"></span>
            <span v-if="breakdown.name !== 'total'"><b>{{ $t( `dashboard.analytics.FeeStructure.${breakdown.name}`) }}</b></span>
            <span style="justify-self: end" v-if="breakdown.name !== 'total'">{{ totalRfps > 0 ? Math.round(breakdown.value / totalRfps * 100) : 0 }}%</span>
          </div>
        </div>
      </section>
    </template>

    <template v-if="cardType === 'transactionVolume'">
      <section class="transaction-volume">
        <div class="lifetime" style="grid-column: 1 / span 3; padding-bottom: 30px">
          <h1>{{ corporate ? formatBidAccordingly(corporate.transactionVolume[0]['volume' + analyticsCurrency], currencyFormat) : '' }}</h1>
          <!-- span>{{ $t('dashboard.analytics.transactionVolume') }}</span -->
          <span>{{ corporate ? formatTimestamp(corporate.transactionVolume[0].periodStart, 'DD.MM.YYYY') + ' - ' + formatTimestamp(corporate.transactionVolume[0].periodEnd, 'DD.MM.YYYY') : ''  }}</span>
        </div>

        <div>
          <section class="subtimes volume">
            <!-- span><b>{{ corporate ? formatTimestamp(corporate.transactionVolume[2].periodStart, 'YYYY') : '' }}</b></span -->
            <span><b>{{ corporate ? formatBidAccordingly(corporate.transactionVolume[2]['volume' + analyticsCurrency], currencyFormat) : '' }}</b></span>
            <span>
              {{ corporate ? formatTimestamp(corporate.transactionVolume[2].periodStart, 'DD.MM.YYYY') + ' - ' + formatTimestamp(corporate.transactionVolume[2].periodEnd, 'DD.MM.YYYY') : ''  }}
            </span>
          </section>
        </div>

        <div>
          <section class="subtimes volume">
            <!-- span><b>{{ corporate ? formatTimestamp(corporate.transactionVolume[1].periodStart, 'YYYY') : '' }}</b></span -->
            <span><b>{{ corporate ? formatBidAccordingly(corporate.transactionVolume[1]['volume' + analyticsCurrency], currencyFormat) : '' }}</b></span>
            <span>
              {{ corporate ? formatTimestamp(corporate.transactionVolume[1].periodStart, 'DD.MM.YYYY') + ' - ' + formatTimestamp(corporate.transactionVolume[1].periodEnd, 'DD.MM.YYYY') : ''  }}
            </span>
          </section>
        </div>

        <!-- div v-for="time in analyticsInfo" :key="time.period" :class="{'lifetime': time.period === 'LIFETIME'}" :style="time.period === 'LIFETIME' ? 'grid-column: 1 / span 3;' : ''">
          <template v-if="time.period === 'LIFETIME'">
            <h1>{{ formatBidAccordingly(userIsCorporate ? time.savings : time.agreedRevenue, currencyFormat) }}</h1>
            <span>{{ $t(`dashboard.analytics.${time.period.toLowerCase()}${userIsCorporate ? 'Savings' : 'Revenue'}`) }}</span>
          </template>
          <template v-else>
            <section class="subtimes">
              <span><b>{{ `dashboard.analytics.AnalyticsPeriod.${time.period}` | t }}</b></span>
              <span>{{ formatBidAccordingly(userIsCorporate ? time.savings : time.agreedRevenue, currencyFormat) }}</span>
            </section>
          </template>
        </div -->
      </section>
    </template>
  </v-card>
</template>

<script>
import Chart from 'chart.js'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'AnalyticsCard',
  data () {
    return {
      chart: null
    }
  },
  props: {
    cardType: {
      required: true,
      type: String,
      default: 'rfp',
      validator (value) {
        return ['rfp', 'revenue', 'fee', 'transactionVolume'].includes(value)
      }
    },

    userIsCorporate: {
      required: true,
      default: true,
      type: Boolean
    },

    analyticsInfo: {
      required: false,
      type: Array
    },

    corporate: {
      type: Object
    }
  },
  watch: {
    analyticsInfo (newValue, oldValue) {
      console.log(newValue, oldValue)
      switch (this.cardType) {
        case 'rfp':
          if (!this.userIsCorporate && newValue.some(a => {
            const o = oldValue.find(b => b.period === a.period)
            return !o || a.rfpsWon !== o.rfpsWon || a.rfpsParticipated !== o.rfpsParticipated
          })) {
            this.drawRfpHistoryDiagram()
          }
          break
        case 'fee':
          const newLifetime = newValue.find(analytics => analytics.period === 'LIFETIME')
          const oldLifetime = oldValue.find(analytics => analytics.period === 'LIFETIME')

          if (!newLifetime || !oldLifetime ||
            oldLifetime.rfpsByFeeStructure.RATE_CARD_CAP !== newLifetime.rfpsByFeeStructure.RATE_CARD_CAP ||
            oldLifetime.rfpsByFeeStructure.RATE_CARD !== newLifetime.rfpsByFeeStructure.RATE_CARD ||
            oldLifetime.rfpsByFeeStructure.FIXED !== newLifetime.rfpsByFeeStructure.FIXED ||
            oldLifetime.rfpsByFeeStructure.ESTIMATE_RATE_CARD !== newLifetime.rfpsByFeeStructure.ESTIMATE_RATE_CARD) {
            this.drawFeeDiagram()
          }
          break
        case 'revenue':
        default:
          break
      }
    }
  },
  mounted () {
    switch (this.cardType) {
      case 'rfp':
        if (!this.userIsCorporate) {
          this.drawRfpHistoryDiagram()
        }
        break
      case 'fee':
        this.drawFeeDiagram()
        break
      case 'revenue':
      default:
        break
    }
  },
  computed: {
    ...mapGetters(['formatBid', 'formatBidAccordingly', 'formatTimestamp']),
    ...mapState({
      analyticsCurrency: state => state.analyticsCurrency,
      corporates: state => state.corporates
    }),

    currencyFormat () {
      return {
        terms: {
          currency: this.analyticsCurrency
        }
      }
    },

    cardTitle () {
      const result = 'dashboard.analytics.titles.'
      switch (this.cardType) {
        case 'rfp': return result + 'rfpHistory'
        case 'revenue': return result + (this.userIsCorporate ? 'savings' : 'revenueHistory')
        case 'fee': return result + 'feeBreakdown'
        case 'transactionVolume': return result + 'transactionVolume'
        default: return 'title'
      }
    },

    cardBreakdown () {
      if (this.cardType === 'fee' && this.analyticsInfo && this.analyticsInfo.length) {
        const breakdown = this.analyticsInfo[this.analyticsInfo.findIndex(data => data.period === 'LIFETIME')].rfpsByFeeStructure
        const breakdownArr = []
        for (let [key, value] of Object.entries(breakdown)) {
          breakdownArr.push({
            name: key,
            value
          })
        }
        const capOrderArray = ['FIXED', 'RATE_CARD', 'RATE_CARD_CAP', 'ESTIMATE_RATE_CARD']
        breakdownArr.sort((a, b) => capOrderArray.indexOf(a.name) > capOrderArray.indexOf(b.name) ? 1 : -1)
        return breakdownArr
      } else {
        return false
      }
    },

    totalRfps () {
      if (this.cardType === 'fee' && this.analyticsInfo && this.analyticsInfo.length) {
        const breakdown = this.analyticsInfo[this.analyticsInfo.findIndex(data => data.period === 'LIFETIME')].rfpsByFeeStructure
        let totalFeeStructures = 0
        // eslint-disable-next-line
        for (let [key, value] of Object.entries(breakdown)) {
          totalFeeStructures += value
        }
        return totalFeeStructures
      } else {
        return null
      }
    },

    colors () {
      return ['#AE9664', 'rgb(67, 45, 50)', 'rgb(214, 202, 177)', 'rgb(239, 234, 224)']
    }
  },
  methods: {
    ...mapMutations(['setAnalyticsCurrency']),

    chartInfo (cardType) {
      if (this.analyticsInfo && this.analyticsInfo.length) {
        switch (cardType) {
          case 'rfp':
            const totalRfps = this.analyticsInfo[this.analyticsInfo.findIndex(data => data.period === 'LIFETIME')].rfpsParticipated
            const rfpsWon = this.analyticsInfo[this.analyticsInfo.findIndex(data => data.period === 'LIFETIME')].rfpsWon

            return {
              data: [rfpsWon, (totalRfps - rfpsWon)],
              labels: ['RFPs Won', 'RFPs Lost'],
              color: ['#AE9664', 'rgb(239, 234, 224)']
            }
          case 'fee':
            const breakdowns = this.cardBreakdown
            return {
              data: breakdowns.map(breakdown => breakdown.value),
              labels: breakdowns.map(breakdown => this.$t(`dashboard.analytics.FeeStructure.${breakdown.name}`)),
              color: ['#AE9664', 'rgb(67, 45, 50)', 'rgb(214, 202, 177)', 'rgb(239, 234, 224)']
            }
        }
      } else {
        return {
          data: [90, 10],
          labels: ['one', 'two'],
          color: ['#AE9664', 'rgb(239, 234, 224)']
        }
      }
    },

    setTotalvalue (value) {
      this.totalRfps = value
    },

    chartCenterElements (dataValues) {
      const winningPercentage = (dataValues.data[0] + dataValues.data[1]) < 1 ? 0 : (dataValues.data[0] / (dataValues.data[0] + dataValues.data[1]))
      return this.cardType === 'rfp' ? {
        center: {
          text: (Math.round(winningPercentage * 100)).toString() + '%',
          text2: (dataValues.data[0] + '/' + (dataValues.data[0] + dataValues.data[1])),
          color: 'rgb(78, 78, 78)', // Default is #000000
          color2: 'rgb(171, 171, 181)',
          fontStyle: 'Source Sans Pro', // Default is Arial
          sidePadding: 40 // Defualt is 20 (as a percentage)
        }
      } : ''
    },

    drawRfpHistoryDiagram () {
      const container = document.getElementById('rfp-container')
      let child = container.lastElementChild
      while (child) {
        container.removeChild(child)
        child = container.lastElementChild
      }

      const canvas = document.createElement('canvas')
      container.appendChild(canvas)
      canvas.style = 'height: 175px; width: 175px;'
      const context = canvas.getContext('2d')
      const dataValues = this.chartInfo(this.cardType)
      // console.log(dataValues)
      const elements = this.chartCenterElements(dataValues)

      Chart.pluginService.register({
        beforeDraw: function (chart) {
          if (chart.config.options.elements.center) {
            // Get ctx from string
            const ctx = chart.chart.ctx

            // Get options from the center object in options
            const centerConfig = chart.config.options.elements.center
            const fontStyle = centerConfig.fontStyle || 'Arial'
            const txt = centerConfig.text
            const txt2 = centerConfig.text2
            const color = centerConfig.color || '#000'
            const sidePadding = centerConfig.sidePadding || 20
            const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            ctx.font = '25px ' + fontStyle

            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            const stringWidth = ctx.measureText(txt).width
            const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated

            // Find out how much the font can grow in width.
            const widthRatio = elementWidth / stringWidth
            const newFontSize = Math.floor(20 * widthRatio)
            const elementHeight = (chart.innerRadius * 2)

            // Pick a new font size so it will not be larger than the height of label.
            const fontSizeToUse = Math.min(newFontSize, elementHeight)

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2)
            const centerYTxt1 = ((chart.chartArea.top + chart.chartArea.bottom) / 2.2)
            const centerYTxt2 = ((chart.chartArea.top + chart.chartArea.bottom) / 1.7)
            ctx.font = 'bold ' + fontSizeToUse + 'px ' + fontStyle
            ctx.fillStyle = color

            // Draw text in center
            ctx.fillText(txt, centerX, centerYTxt1)

            ctx.fillStyle = centerConfig.color2
            ctx.font = '15px ' + fontStyle
            ctx.fillText(txt2, centerX, centerYTxt2)
          }
        }
      })

      this.chart = new Chart(context, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: dataValues.data[1] > 0 ? dataValues.data : [0, 0.01],
            backgroundColor: dataValues.color,
            borderWidth: 1,
            borderAlign: 'inner',
            hoverBorderColor: 'transparent',
            hoverBorderWidth: 1
          }],
          labels: dataValues.labels
        },
        options: {
          responsive: false,
          cutoutPercentage: 55,
          legend: {
            display: false
          },
          elements: elements
        }
      })
    },

    drawFeeDiagram () {
      const container2 = document.getElementById('fee-container')
      let child = container2.lastElementChild
      while (child) {
        container2.removeChild(child)
        child = container2.lastElementChild
      }

      const canvas2 = document.createElement('canvas')
      container2.appendChild(canvas2)
      canvas2.style = 'height: 175px; width: 175px;'
      canvas2.height = '175px'
      canvas2.width = '175px'
      const context2 = canvas2.getContext('2d')

      const dataValues = this.chartInfo(this.cardType)
      // console.log(dataValues)
      // console.log(dataValues)
      const comparison = dataValues.data.reduce((prevVal, currentVal) => {
        return prevVal + currentVal
      }, 0)

      this.chart = new Chart(context2, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: comparison > 0 ? dataValues.data : [0, 0, 0, 1],
            backgroundColor: dataValues.color,
            borderWidth: 1,
            borderAlign: 'inner',
            hoverBorderColor: 'transparent',
            hoverBorderWidth: 1
          }],
          labels: dataValues.labels
        },
        options: {
          cutoutPercentage: 55,
          legend: {
            display: false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .currency-toggle a
    color $maroon100

    &.active
      color $gold100 !important
      text-decoration none

  .v-card
    border-radius 10px
    padding 20px

  h4
    color $dark-gray

   hr
    margin 5px 0

  .dougnught-rfp
    display grid
    grid-template-columns 50% 1fr

  .ordered-items
    padding 20% 0
    padding-left 20px
    display grid
    grid-template-rows repeat(4, auto)

  .grid-info
    display grid
    grid-template-columns 0.6fr 0.5fr

  .time-periods
    display grid
    grid-template-columns repeat(3, 1fr)
    text-align center

  .transaction-volume
    display grid
    grid-template-columns repeat(2, 1fr)
    text-align center

  .lifetime
    grid-column 1 / span 3
    padding 40px 0
    h1
      color $gold100
    span
      color $icon-gray
      font-weight bold

  .subtimes
    display grid
    grid-template-columns repeat(2, 1fr)

    &.volume
      grid-template-columns 1fr

  .breakdown-info
    display grid
    grid-column-gap 5px
    grid-template-columns auto 1fr auto

  .dot
    width 15px
    height 15px
    border-radius 50%
    display inline-block
    background-color $icon-gray
  // .heading
  //   text-align center
  //   height 180px

  //   h1
  //     height 115px
  //     font-size 3em
  //     padding-top 1.25em

  //   h2
  //     font-size 1.2em
  //     color gray

  //   h3
  //     font-size 0.8em
  //     color lightgray
  //     padding-bottom 2em

  // .details
  //   display grid
  //   grid-template-columns 1fr 70px
  //   grid-template-rows 24px
  //   height 90px
  //   padding-top 10px
  //   padding-bottom 10px

  //   .detail
  //     margin-left 20px

  //     &:before
  //       height 12px
  //       width 12px
  //       border-radius 50%
  //       content ''
  //       display inline-block
  //       position relative
  //       right 5px

  //   .detail-1
  //     &:before
  //       background-color: rgb(68, 67, 68)

  //   .detail-2
  //     &:before
  //       background-color: rgb(163, 163, 174)

  //   .detail-3
  //     &:before
  //       background-color: rgb(244, 243, 248)

  //   .detail-value
  //     text-align right
  //     padding-right 20px

  // .footer
  //   width 100%
  //   height 60px
  //   padding-top 12px

  //   .v-btn
  //     margin 0 auto
  //     display block
</style>
