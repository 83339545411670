import i18next from 'i18next'
import en from './en'
import de from './de'
import enNotifications from '../../../backend/src/main/resources/mailNotifications/en'
import deNotifications from '../../../backend/src/main/resources/mailNotifications/de'

i18next.init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: {
      label: 'English',
      translation: {
        ...en,
        _notifications: enNotifications
      }
    },
    de: {
      label: 'Deutsch',
      translation: {
        ...de,
        _notifications: deNotifications
      }
    }
  }
})

export default i18next
