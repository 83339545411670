<template>
  <div id="chart-container" ></div>
</template>

<script>
import Chart from 'chart.js'
import { mapGetters } from 'vuex'

export default {
  name: 'FullDisclosureDiagram',
  props: {
    // Array of Options that will be displayed
    dataset: {
      type: Array,
      required: true
    },
    // max axis value in the chart
    maxAxis: {
      type: Number,
      required: true
    },
    // min axis value in the chart
    minAxis: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      chart: null,
      visitedAreas: []
    }
  },
  mounted () {
    this.create()
  },
  watch: {
    dataset () {
      this.create()
    }
  },
  computed: {
    ...mapGetters(['formatBid', 'isEAuction', 'currencySymbol'])
  },
  methods: {
    handleHover (hoverArea, context, areaArray) {
      // if (!this.visitedAreas.includes(hoverArea.areaVal)) {
      // console.log(hoverArea.areaVal)
      context.fillStyle = 'rgb(255, 255, 255)'
      context.fillRect(0, hoverArea.y - 46, 1000, hoverArea.y - 122)

      context.fillStyle = 'rgb(78, 78, 78)'
      context.textAlign = 'left' // hoverArea.areaVal.endsWith('0') ? 'right' : 'left'
      let offsetValue = 3 // (hoverArea.areaVal.endsWith('0')) ? -3 : 3
      context.fillText(hoverArea.leftSideLabel, hoverArea.leftSide + offsetValue, hoverArea.y - 65)
      context.fillText(this.formatBid(hoverArea.leftSideValue), hoverArea.leftSide + offsetValue, hoverArea.y - 50)

      // context.textAlign = spread > 10 ? 'right' : 'left'
      // context.fillText(hoverArea.rightSideLabel, hoverArea.rightSide + offsetValue, hoverArea.y - 65)
      // context.fillText(hoverArea.rightSideValue.toLocaleString('de-DE') + ' €', hoverArea.rightSide + offsetValue, hoverArea.y - 50)

      this.visitedAreas.push(hoverArea.areaVal)
      // }
    },
    create () {
      let dataValues = this.dataset

      const container = document.getElementById('chart-container')
      let child = container.lastElementChild
      while (child) {
        container.removeChild(child)
        child = container.lastElementChild
      }

      const canvas = document.createElement('canvas')
      container.appendChild(canvas)
      canvas.style = 'height: 200px; width: 100%;'
      const context = canvas.getContext('2d')

      /* hoverMethod that create the areas for hovering and detects if is on one of them. The binding for 'this'
      is made so whenever is executed it still has the scope to access to the component methods 'this.handleHover' */
      let onHoverFunction = function (e, elements) {
        e.stopPropagation()
        if (elements && elements.length) {
          let newAreasArray = []
          for (let i = 0; i < elements.length; i++) {
            if (i < elements.length - 1) {
              let newArea = {
                areaVal: 'area' + i,
                leftSideValue: elements[i]._chart.config.data.datasets.filter(dataset => dataset.label === elements[i]._model.datasetLabel)[0].realBidValue,
                leftSide: elements[i]._model.x,
                leftSideLabel: elements[i]._model.datasetLabel,
                rightSide: i > 0 ? elements[i - 1]._model.x : elements[i]._xScale.right,
                y: elements[i]._model.y
              }
              newAreasArray.push(newArea)
            }
          }
          var ctx = this.chart.ctx
          newAreasArray.forEach((area, index, areaArray) => {
            if (e.offsetX > area.leftSide && e.offsetX < area.rightSide) {
              this.handleHover(area, ctx, areaArray)
            }
          })
        }
      }.bind(this)

      // method that formats the numbers in the ticks and the difference to first or last
      const self = this
      let callBackFunction = function (value) {
        var ranges = [
          { divider: 1e6, suffix: 'M', compare: 1e6 },
          { divider: 1e3, suffix: 'k', compare: 1e4 }
        ]
        function formatNumber (n) {
          for (var i = 0; i < ranges.length; i++) {
            if (n >= ranges[i].compare) {
              const precision = i === 0 ? 1000 : 100
              return ((Math.round((n / ranges[i].divider) * precision)) / precision).toString() + ranges[i].suffix
            }
          }
          return Math.round(n)
        }
        return formatNumber(value) + ' ' + self.currencySymbol()
      }

      /* onCompleteAnimation is the method that creates the fixed labels that are always to be shown and resets the visited areas
      the method of visited areas is called inside this function but the binding to the component is made in that mehtod */
      let onCompleteAnimationFunction = function (e) {
        var chartInstance = this.chart
        var ctx = chartInstance.ctx
        ctx.textAlign = 'left'

        var lastVal = 0
        Chart.helpers.each(this.data.datasets.forEach(function (dataset, i, array) {
          var meta = chartInstance.controller.getDatasetMeta(i)
          Chart.helpers.each(meta.data.forEach(function (bar, index) {
            var data = dataset.data[index]
            if (dataset.myBid) {
              ctx.fillStyle = 'rgb(255, 255, 255)'
              ctx.fillRect(0, bar._model.y - 70, 1000, 22)

              ctx.fillStyle = 'rgb(78, 78, 78)'
              // ctx.textAlign = i === 0 ? 'right' : 'left'
              let value = callBackFunction(Math.abs(dataset.differenceToNextLawFirm))
              let differenceString = dataset.differenceToNextLawFirm > 0 ? `${value} to First` : `${value} to Second`
              let offsetVal = 3 // dataset.differenceToNextLawFirm > 0 ? +3 : -3
              ctx.fillText(dataset.label, bar._model.x + offsetVal, bar._model.y - 65)
              if (dataset.differenceToNextLawFirm !== null && dataset.differenceToNextLawFirm !== undefined) {
                ctx.fillText(differenceString, bar._model.x + offsetVal, bar._model.y - 50)
              }
            }
            if (i === 0 || i === array.length - 2) {
              ctx.fillStyle = 'rgb(78, 78, 78)'
              ctx.textAlign = i === 0 ? 'left' : 'right'
              const xOffset = i === 0 ? chartInstance.width - 35 : 50
              ctx.fillText(dataset.position, xOffset, bar._model.y + 5)
            }
            lastVal = lastVal + data
          }), this)
        }), this)
        resetAreas()
      }
      // method with the binding to make sure it has access to component data.
      const resetAreas = function () {
        this.visitedAreas = []
      }.bind(this)

      // actual Chart Painting
      this.chart = new Chart(context, {
        type: 'horizontalBar',
        data: {
          labels: [''],
          datasets: dataValues
        },
        options: {
          tooltips: {
            enabled: false
          },
          legend: {
            display: false
          },
          animation: {
            onComplete: onCompleteAnimationFunction
          },
          hover: {
            animationDuration: 0,
            onHover: onHoverFunction
          },
          layout: {
            padding: {
              left: 40,
              right: 40,
              top: 40,
              bottom: 40
            }
          },
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                fontSize: 11,
                reverse: true,
                display: true,
                maxRotation: 0,
                min: this.minAxis, // 197000
                max: this.maxAxis, // 200000
                stepSize: (this.maxAxis - this.minAxis) / 4,
                callback: callBackFunction
              },
              scaleLabel: {
                display: false
              },
              gridLines: {
                drawOnChartArea: false,
                color: 'rgba(171, 171, 181, 1)',
                zeroLineColor: 'rgba(255, 255, 255, 1)'
              }
            }],
            yAxes: [{
              barPercentage: 0.8,
              categoryPercentage: 1.3,
              stacked: true,
              gridLines: {
                color: 'rgba(255, 255, 255, 1)'
              },
              ticks: {
                fontFamily: '"Open Sans Bold", sans-serif',
                fontSize: 11
              }
            }]
          }
        }
      })
    }
  }
}
</script>
