<template>
  <div class="rfp-panel" >
    <div class="header">
      <h2>{{ title }}</h2>
      <v-btn style="border-radius: 5px" flat class="main" v-if="rfpStatus === 'draft' && userIsCorporate" @click="$emit('showCreateNewRfpDialog')">{{ 'buttons.newRFP' | t }}</v-btn>
       <!-- <v-btn style="border-radius: 5px" flat class="main" v-if="rfpStatus === 'matter-completed'" @click="'asd'">{{ 'dashboard.rfpInformation.viewCompletedMattersButton' | t }}</v-btn> -->
    </div>
    <v-data-table
      must-sort
      class="styled-table"
      :headers="headers"
      :items="filteredRfps"
      :no-data-text="noDataText"
      :pagination.sync="pagination"
      :custom-sort="customSort"
      hide-actions >

      <template  slot="headerCell" slot-scope="props">
       <span style="color: #ababb5">{{ props.header.text | t }}</span>
      </template>

      <template v-slot:items="props">
        <template v-if="userIsLawFirm">
          <td>
            <TooltipHolder
              v-if="props.item.mandateSubType !== null"
              :spanId="props.item.id"
              :width="130"
              :moveLeft="-20"
              :size="1"
              :tableDataWidth="100"
              :tooltipText="props.item.mandateSubType !== null ? $t(`dropDownOptions.MandateSubType.${props.item.mandateSubType}`) : $t('dashboard.rfpInformation.noSubArea')"
              :text="$t(`dropDownOptions.MandateType.${props.item.mandateType}`)"
              tableData />
            <!-- div v-else>{{ }}</div -->
            <TooltipHolder
              v-else
              :spanId="props.item.id"
              :width="200"
              :moveLeft="-20"
              :size="1"
              :tableDataWidth="140"
              :tooltipText="$t(`dropDownOptions.MandateType.${props.item.mandateType}`) "
              :text="$t(`dropDownOptions.MandateType.${props.item.mandateType}`) "
              tableData />
          </td>
          <td class="bold">
            <TooltipHolder
            :spanId="'c'+props.item.clientName + props.item.id"
            :width="130"
            :moveLeft="-20"
            :size="21"
            :tableDataWidth="100"
            :text="props.item.clientName"
            tableData />
          </td>
          <td class="bold">
            <TooltipHolder
            :spanId="'m'+props.item.name + props.item.id"
            :moveLeft="-10"
            :size="21"
            :tableDataWidth="100"
            :text="props.item.name"
            tableData />
          </td>

          <template v-if="rfpStatus === 'draft' || rfpStatus === 'active'">
            <td>{{ props.item.auctionType && `dropDownOptions.AuctionType.${props.item.auctionType}` | t }}</td>
            <td>{{ formatDateTime(props.item.submission) }}</td>
            <td>{{ renderStatus(props.item) }}</td>
            <td>{{ formatDateTime(nextRfpDeadline(props.item)) }}</td>
          </template>
          <template v-if="rfpStatus === 'completed'">
            <td>{{ props.item.auctionType && `dropDownOptions.AuctionType.${props.item.auctionType}` | t }}</td>
            <td>{{ formatDateTime(props.item.auctionEnd) }}</td>
            <td>{{ renderStatus(props.item) }}</td>
            <td></td>
          </template>

          <template v-if="rfpStatus.startsWith('matter-')">
            <td>{{ props.item.auctionType && `dropDownOptions.AuctionType.${props.item.auctionType}` | t }}</td>
            <td>{{ formatDateTime(props.item.auctionEnd) }}</td>
            <td>{{ renderStatus(props.item) }}</td>
          </template>
        </template>

        <template v-if="userIsCorporate">
          <td>
            <TooltipHolder
              v-if="props.item.mandateSubType !== null"
              :spanId="props.item.id"
              :width="150"
              :moveLeft="-20"
              :size="1"
              :tableDataWidth="160"
              :tooltipText="props.item.mandateSubType !== null ? $t(`dropDownOptions.MandateSubType.${props.item.mandateSubType}`) : $t('dashboard.rfpInformation.noSubArea')"
              :text="$t(`dropDownOptions.MandateType.${props.item.mandateType}`)"
              tableData />
            <!-- div v-else>{{ $t(`dropDownOptions.MandateType.${props.item.mandateType}`) }}</div -->
            <TooltipHolder
              v-else
              :spanId="props.item.id"
              :width="200"
              :moveLeft="-20"
              :size="1"
              :tableDataWidth="140"
              :tooltipText="$t(`dropDownOptions.MandateType.${props.item.mandateType}`) "
              :text="$t(`dropDownOptions.MandateType.${props.item.mandateType}`) "
              tableData />
          </td>
          <td class="bold">
            <TooltipHolder
            :spanId="props.item.name + props.item.id"
            :width="130"
            :moveLeft="-20"
            :size="21"
            :tableDataWidth="120"
            :text="props.item.name"
            tableData />
          </td>
          <td v-if="rfpStatus === 'draft'">{{ renderStatus(props.item) }}</td>

          <template v-if="rfpStatus === 'active'">
            <td>{{ props.item.auctionType && `dropDownOptions.AuctionType.${props.item.auctionType}` | t }}</td>
            <td>{{ formatDateTime(props.item.submission) }}</td>
            <td>{{ renderStatus(props.item) }}</td>
            <td>{{ formatDateTime(nextRfpDeadline(props.item)) }}</td>
            <td>{{ formatBid(props.item.minBid, props.item) }}</td>
          </template>

          <template v-if="rfpStatus === 'completed'">
            <td>{{ props.item.winner ? props.item.winner.name : '-'}}</td>
            <td>{{ renderStatus(props.item) }}</td>
            <td>{{ formatDateTime(props.item.auctionEnd) }}</td>
            <td>{{ formatBid(props.item.finalBid, props.item) }}</td>
          </template>

          <template v-if="rfpStatus.startsWith('matter-')">
            <td>{{ props.item.winner ? props.item.winner.name : '-'}}</td>
            <td>{{ renderStatus(props.item) }}</td>
            <td>{{ formatDateTime(props.item.invoiceUploadDate) }}</td>
            <td>{{ formatBid(props.item.finalBid, props.item) }}</td>
          </template>
        </template>
        <td style="padding: 0 10px 0 0;" v-if="rfpStatus === 'matter-completed'"></td>
        <td v-if="rfpStatus === 'draft' && userIsCorporate" class="table-button">
          <v-btn
            @click="showDeleteDialog = true; rfpToDelete = props.item"
            class="elevation-0 view-btn action-btn"
          >
            {{ `buttons.delete` | t }}
          </v-btn>
        </td>
        <td class="table-button" :class="{'matter-button': rfpStatus === 'matter-completed'}" :style="rfpStatus === 'matter-active' ? 'padding: 0 0 0 10px !important; text-align: right;' : ''" v-if="renderViewButton">
          <v-btn
            v-if="!isConflictedOrMissedCOI(props.item)"
            class="elevation-0 view-btn action-btn"
            @click="openRfp(props.item)" >
            {{ `buttons.${actionButtonText}` | t }}
          </v-btn>
        </td>
        <td class="table-button" v-else>
          <v-btn
            class="elevation-0 view-btn action-btn"
            @click="showAcceptPanel(props.item)" >
            {{'buttons.view' | t }}
          </v-btn>
        </td>
        <td style="padding: 0 10px 0 0;" v-if="rfpStatus === 'matter-active'">
          <v-btn
            class="elevation-0 view-btn main"
            @click="uploadInvoice({rfpId: props.item.id})" >
            {{ `buttons.invoice` | t }}
          </v-btn>
        </td>
      </template>
    </v-data-table>

    <ConfirmDialog
      v-if="showAcceptPanelForRfp"
      @close="showAcceptPanelForRfp = null; $router.push({path: '/rfps'})"
      @rejectCOI="rfpId => { showAcceptPanelForRfp = null; updateCOI1(rfpId, false) }"
      @accept="rfpId => waitButtonToEnd(updateCOI1, rfpId, true)"
      :rfp="showAcceptPanelForRfp"
      :text="$t('coi1LawFirm.conflictReject', {matterName: showAcceptPanelForRfp.name})"
      :title="showAcceptPanelForRfp.name"
      :buttonDisable="isButtonExecuting"
      COI
      showCloseBtn />

    <ConfirmDialog
      v-if="showUploadInvoice"
      @close="showUploadInvoice = false"
      @submitDocuments="(attachedDocuments, rfpId) => { waitButtonToEnd(submitInvoice, attachedDocuments, rfpId)}"
      @submit="(rfpId) => { waitButtonToEnd(closeMatter, rfpId)}"
      :buttonDisable="isButtonExecuting"
      :rfp="showInvoicePanel"
      :title="$t('dashboard.rfpInformation.finalizeTitle')"
      :text="$t('dashboard.rfpInformation.finalizeText')"
      :cancelButtonTextOrKey="$t('buttons.cancel')"
      :acceptButtonTextOrKey="$t('buttons.finalizeMatter')"
      documentUpload />

    <ConfirmDialog
      v-if="showDeleteDialog"
      @close="showDeleteDialog = false"
      @cancel="showDeleteDialog = false"
      @submit="deleteRfpLocal()"
      :cancelButtonTextOrKey="$t('buttons.cancel')"
      :acceptButtonTextOrKey="$t('dashboard.rfpInformation.deleteDialog.deletBtn')"
      :disableWithoutProgress="verificationName !== (rfpToDelete ? rfpToDelete.name : '')"
      :title="$t('dashboard.rfpInformation.deleteDialog.title')"
      :text="$t('dashboard.rfpInformation.deleteDialog.text', { rfpName: rfpToDelete ? rfpToDelete.name : '' })">
      <template v-slot:custom-input>
        <div>
          <v-text-field
            style="padding-top: 0;"
            v-model="verificationName"
          ></v-text-field>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { corporateActiveHeaders, corporateCompleteHeaders, corporateDraftHeaders } from './tableHeaders/corporateHeaders'
import { lawFirmNewHeaders, lawFirmCompleteHeaders, lawFirmActiveHeaders } from './tableHeaders/lawFirmHeaders'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import moment from 'moment'

export default {
  name: 'RfpPanel',
  components: { ConfirmDialog, TooltipHolder },
  props: {
    // determines the RFPs shown in this table
    // can be: 'draft', 'active' or 'completed'. Caution: 'draft' for law firms means 'new'
    rfpStatus: {
      required: false,
      default: 'draft',
      type: String,
      validator (value) {
        return ['draft', 'active', 'completed', 'matter-active', 'matter-completed'].includes(value)
      }
    },
    /* Prop that is passed from the parent when needs to load the Confirm dialog because of a notification
    was clicked */
    notificationRfp: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  // watcher that sets the rfp to be displayed when the notifications is clicked
  watch: {
    notificationRfp () {
      if (this.notificationRfp) {
        this.setShowAcceptPanelForRfp()
      }
    }
  },
  data () {
    return {
      showAcceptPanelForRfp: null,
      showUploadInvoice: false,
      showInvoicePanel: null,
      pagination: {
        sortBy: '',
        rowsPerPage: -1,
        descending: false
      },
      showDeleteDialog: false,
      rfpToDelete: null,
      verificationName: ''
    }
  },
  created: function () {
    this.pagination.sortBy = this.sortOption()
    // when created not from the dashboard needs to check if it was created for the purpose of showing the coi 1
    if (this.notificationRfp) {
      this.setShowAcceptPanelForRfp()
    }
  },
  computed: {
    ...mapGetters(['userIsLawFirm', 'userIsCorporate', 'nextRfpDeadline', 'formatDateTime', 'formatBid', 'loadRfpStatusTab']),
    ...mapState({
      allRfps: state => state.allRfps,
      userData: state => state.userData,
      currentRfp: state => state.currentRfp,
      usersLastBid: state => state.auction.usersLastBid
    }),

    activeCompletedMatterHeader () {
      let activeMatterHeadersArray = this.userIsLawFirm ? [...lawFirmCompleteHeaders] : [...corporateCompleteHeaders]
      if (this.userIsCorporate) {
        activeMatterHeadersArray.push({
          text: '',
          sortable: false,
          value: 'action',
          width: '130px'
        })
      }
      return activeMatterHeadersArray
    },

    headers () {
      switch (this.rfpStatus) {
        case 'draft':
          return this.userIsLawFirm ? lawFirmNewHeaders : corporateDraftHeaders
        case 'completed':
          return this.userIsLawFirm ? lawFirmCompleteHeaders : corporateCompleteHeaders
        case 'active':
          return this.userIsLawFirm ? lawFirmActiveHeaders : corporateActiveHeaders
        case 'matter-active':
          return this.activeCompletedMatterHeader
        case 'matter-completed':
          return this.activeCompletedMatterHeader
      }

      return []
    },

    filteredRfps () {
      if (this.userIsLawFirm) {
        if (this.rfpStatus === 'draft') {
          return this.allRfps.filter(rfp => rfp.lawFirmRelations[0].status.startsWith('NEW_'))
        } else if (this.rfpStatus === 'completed') {
          return this.allRfps.filter(rfp => rfp.lawFirmRelations[0].status.startsWith('COMPLETED_'))
        } else if (this.rfpStatus === 'matter-active') {
          return this.allRfps.filter(rfp => rfp.lawFirmRelations[0].status === 'COMPLETED_WON' && (rfp.status === 'COMPLETED_MATTER_IN_PROGRESS'))
        } else if (this.rfpStatus === 'matter-completed') {
          return this.allRfps.filter(rfp => rfp.lawFirmRelations[0].status === 'COMPLETED_WON' && (rfp.status === 'COMPLETED_MATTER_CLOSED' || rfp.status === 'COMPLETED_FEEDBACK_REQUIRED'))
        } else {
          return this.allRfps.filter(rfp => rfp.lawFirmRelations[0].status === 'ACTIVE')
        }
      }

      if (this.userIsCorporate) {
        if (this.rfpStatus === 'draft') {
          return this.allRfps.filter(rfp => rfp.status === 'DRAFT')
        } else if (this.rfpStatus === 'completed') {
          return this.allRfps.filter(rfp => rfp.status.startsWith('COMPLETED_'))
        } else if (this.rfpStatus === 'matter-active') {
          return this.allRfps.filter(rfp => rfp.status === 'COMPLETED_MATTER_IN_PROGRESS')
        } else if (this.rfpStatus === 'matter-completed') {
          return this.allRfps.filter(rfp => rfp.status === 'COMPLETED_MATTER_CLOSED' || rfp.status === 'COMPLETED_FEEDBACK_REQUIRED')
        } else {
          return this.allRfps.filter(rfp => rfp.status !== 'DRAFT' && !rfp.status.startsWith('COMPLETED_'))
        }
      }

      return []
    },

    actionButtonText () {
      return this.rfpStatus === 'draft' ? 'continue' : 'view'
    },

    title () {
      switch (this.rfpStatus) {
        case 'draft':
          return this.userIsLawFirm ? this.$t('dashboard.rfpInformation.new') : this.$t('dashboard.rfpInformation.draft')
        case 'completed':
          return this.$t('dashboard.rfpInformation.completed')
        case 'active':
          return this.$t('dashboard.rfpInformation.active')
        case 'matter-active':
          return this.$t('dashboard.rfpInformation.activeMatter')
        case 'matter-completed':
          return this.$t('dashboard.rfpInformation.completedMatter')
      }

      return 'Default Text'
    },

    noDataText () {
      if (this.userIsLawFirm && this.rfpStatus === 'draft') {
        return this.$t('dashboard.rfpInformation.noRfps')
      }

      let key
      switch (this.rfpStatus) {
        case 'draft':
          key = 'dashboard.rfpInformation.noDraftRfps'
          break
        case 'completed':
          key = 'dashboard.rfpInformation.noCompletedRfps'
          break
        case 'active':
          key = 'dashboard.rfpInformation.noActiveRfps'
          break
        case 'matter-active':
          key = 'dashboard.rfpInformation.noActiveMatters'
          break
        case 'matter-completed':
          key = 'dashboard.rfpInformation.noCompletedMatters'
          break
      }

      return this.$t(key)
    },

    renderViewButton () {
      return this.userIsCorporate || this.rfpStatus !== 'draft'
    }
  },
  methods: {
    ...mapActions(['deleteRfp', 'acceptRejectCoi1', 'loadRfp', 'loadUsersLastBid', 'uploadInnvoiceFiles', 'finaliseMatter']),

    async deleteRfpLocal () {
      await this.deleteRfp({ rfpId: this.rfpToDelete.id })
      this.showDeleteDialog = false
      this.verificationName = ''
    },

    async updateCOI1 (rfpId, coiValue) {
      await this.acceptRejectCoi1({ rfpId, decision: coiValue })
      if (coiValue) {
        this.showCoiII(rfpId)
      }
    },

    showCoiII (rfpId) {
      this.$router.push({
        name: 'coiCheckII',
        params: {
          id: rfpId
        }
      })
    },
    toCamelCase (stringArr) {
      // console.log(stringArr)
      if (stringArr.length <= 1) {
        return stringArr[0]
      } else {
        let camelCase = stringArr.reduce((acc, cur, idx) => {
          // console.log(acc,cur,idx)
          if (idx === 0) {
            return cur
          } else {
            return acc + cur.charAt(0).toUpperCase() + cur.slice(1)
          }
        })
        return camelCase
      }
    },
    async openRfp (rfp) {
      console.log(rfp)
      if (rfp.status === 'DRAFT') {
        console.log('DRAFT')
        this.$router.push({
          name: 'newRfp',
          params: {
            id: rfp.id
          }
        })
      } else {
        // console.log(rfp.status)
        this.$router.push({
          name: 'activeRfp',
          params: {
            id: rfp.id,
            activeTab: this.loadRfpStatusTab(rfp)
          }
        })
      }
    },

    async showAcceptPanel (rfp) {
      const userAcceptedCoi1 = rfp.lawFirmRelations.find(relation => relation.lawFirm.id === this.userData.lawFirmId).coi1Confirmed

      if (userAcceptedCoi1) {
        this.$router.push({
          name: 'coiCheckII',
          params: {
            id: rfp.id
          }
        })
      } else {
        await this.loadRfp({ rfpId: rfp.id })
        this.setShowAcceptPanelForRfp()
      }
    },

    setShowAcceptPanelForRfp () {
      this.showAcceptPanelForRfp = this.currentRfp
    },

    renderStatus (rfp) {
      const status = rfp.status

      if (this.userIsLawFirm) {
        const lawFirmRelation = rfp.lawFirmRelations[0]

        if (lawFirmRelation && lawFirmRelation.status !== 'ACTIVE' && lawFirmRelation.status !== 'COMPLETED_WON') {
          return this.$t('general.rfp.lawFirmSpecificStatus.' + lawFirmRelation.status)
        }

        if (status === 'COMPLETED_FEEDBACK_REQUIRED') {
          return this.$t('general.rfp.status.COMPLETED_MATTER_CLOSED')
        }
      }

      if (status === 'DRAFT') {
        return this.$t('newRFP.footer.' + (rfp.draftStep || 'step1SD'))
      }

      if (status === 'ACTIVE_SECOND_BIDS_OR_AUCTION' || status === 'ACTIVE_FIRST_BIDS') {
        return this.$t('general.rfp.status.' + status + '.' + rfp.auctionType)
      }

      return this.$t('general.rfp.status.' + status)
    },

    isConflictedOrMissedCOI (check) {
      return this.userIsLawFirm && (check.lawFirmRelations[0].status === 'COMPLETED_CONFLICTED' || (check.lawFirmRelations[0].status === 'COMPLETED_MISSED_FIRST_BID_DEADLINE' && !check.lawFirmRelations[0].coi2Confirmed) || (check.lawFirmRelations[0].statusPreAbortion && !check.lawFirmRelations[0].coi2Confirmed))
    },

    sortOption () {
      switch (this.rfpStatus) {
        case 'draft':
          return 'name'
        case 'completed':
          return this.userIsLawFirm ? 'clientName' : 'name'
        case 'active':
          return 'status'
        default:
          return 'name'
      }
    },

    customSort (items, index, isDesc) {
      // console.log(isDesc)
      items.sort((a, b) => {
        let realA
        let realB
        if (!isDesc) {
          realA = a
          realB = b
        } else {
          realA = b
          realB = a
        }
        switch (index) {
          case 'auctionEnd':
            let dateA = this.rfpStatus === 'active' ? this.nextRfpDeadline(realA) : realA[index]
            let dateB = this.rfpStatus === 'active' ? this.nextRfpDeadline(realB) : realB[index]
            // console.log(date)
            return this.compareDates(dateA, dateB, isDesc)
          case 'status':
            return realA[index] === 'DRAFT' ? this.compareDraftStatus(realA['draftStep'], realB['draftStep']) : this.compareStatus(realA[index], realB[index])
          case 'minBid':
            return this.compareMoney(realA[index], realB[index], isDesc)
          default:
            // console.log(realA[index].toLowerCase(), realB[index].toLowerCase(), realA[index].toLowerCase() < realB[index].toLowerCase())
            return realA[index].toLowerCase() < realB[index].toLowerCase() ? -1 : 1
        }
      })
      return items
    },

    compareDates (firstDate, secondDate, isDesc) {
      if (!firstDate || firstDate === '-') {
        return isDesc ? -1 : 1
      } else if (!secondDate || secondDate === '-') {
        return isDesc ? 1 : -1
      } else {
        return moment(firstDate).isSameOrAfter(secondDate) ? 1 : -1
      }
    },

    compareDraftStatus (statusA, statusB) {
      if (!statusA || !statusB) {
        return -1
      }
      // console.log(parseInt(statusA[4]), parseInt(statusB[4]))
      return parseInt(statusA[4]) > parseInt(statusB[4]) ? 1 : -1
    },

    compareStatus (statusA, statusB) {
      const statusArr = [
        'DRAFT',
        'ACTIVE_QA',
        'ACTIVE_FIRST_BIDS',
        'ACTIVE_FIRST_BIDS_CLOSED',
        'ACTIVE_SECOND_BIDS_OR_AUCTION',
        'ACTIVE_DECISION_PENDING',
        'COMPLETED_MATTER_IN_PROGRESS',
        'COMPLETED_FEEDBACK_REQUIRED',
        'COMPLETED_MATTER_CLOSED'
      ]
      // console.log('status A: ', statusA, statusArr.indexOf(statusA), 'status B: ', statusB, statusArr.indexOf(statusB))
      return statusArr.indexOf(statusA) >= statusArr.indexOf(statusB) ? 1 : -1
    },

    compareMoney (moneyA, moneyB, isDesc) {
      if (moneyA === null) {
        return 1 * (isDesc ? -1 : 1)
      } else if (moneyB === null) {
        return -1 * (isDesc ? -1 : 1)
      } else {
        return moneyA > moneyB ? 1 : -1
      }
    },

    async submitInvoice (attachedDocuments, rfpId) {
      // console.log(attachedDocuments, rfpId)
      const attachmentsPromises = []
      for (let file of attachedDocuments) {
        attachmentsPromises.push(this.uploadInnvoiceFiles({ file, rfpId, type: 'INVOICE' }))
      }
      await Promise.all(attachmentsPromises)
      this.uploadInvoice({ rfpId })
      // this.$router.push({ name: 'activeRfp', params: { id: this.showInvoicePanel.id, activeTab: this.userIsLawFirm ? 'summary' : 'feedback' } })
    },

    async closeMatter (rfpId) {
      await this.finaliseMatter({ rfpId })
      this.showInvoicePanel = null
      this.showUploadInvoice = false
    },

    async uploadInvoice ({ rfpId }) {
      await this.loadRfp({ rfpId })
      this.showInvoicePanel = this.currentRfp
      this.showUploadInvoice = true
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../../assets/css/variables.styl"
  $border-radius = 5px

  td.bold
    font-weight bold

  .table-button
    padding 0 10px !important
    width 150px

  .view-btn
    height 30px
    text-transform capitalize
    color grey

  >>> .v-table__overflow
    overflow unset

  .action-btn
    border-radius 5px
    background transparent !important
    border: 1px solid lightgray

  .matter-button
    padding 0 10px 0 0 !important
</style>
