<template>
  <v-card>
    <template v-if="!userIsCorporate">
      <div class="ranks">
        <section class="sections">
          <span class="label">{{ `activeRFP.biddingProcessSection.${isSimpleRfp ? 'bidAmount' : 'myLowestBid' }` | t }}</span>
          <div class="bidDiv">
            <h2>{{ usersLastBid ? formatBid(userLastBidValue) : '-' }}</h2><br>
            <CapBreakdown :computed-bid="computedBidFromAuctionBid(usersLastBid)" v-if="usersLastBid" :showPercentage="false" />
          </div>
          <b v-if="usersLastBid && isBlendedCapEstimate">{{ `(${formatBid(usersLastBid.amountBlendedRate)} per hour)`}}</b>
        </section>

        <section class="sections" v-if="!(isSimpleRfp && auctionProgress(currentRfp) < RfpProgress.ACTIVE_DECISION_PENDING)">
          <span :style="auctionProgress(currentRfp) < RfpProgress.ACTIVE_FIRST_BIDS_CLOSED ? '' : (currentRfp.lawFirmRelations[0].rank === 1 ? 'color: rgb(102, 174, 4);' : 'color: rgb(199, 54, 57);')" class="label">{{ `activeRFP.biddingProcessSection.${ auctionProgress(currentRfp) < RfpProgress.ACTIVE_DECISION_PENDING ? 'currentPosition' : 'finalPosition'}` | t }}</span>
          <div v-if="auctionProgress(currentRfp) > RfpProgress.ACTIVE_FIRST_BIDS">
            <h2
              :style="auctionProgress(currentRfp) < RfpProgress.ACTIVE_FIRST_BIDS ? '' : (currentRfp.lawFirmRelations[0].rank === 1 ? 'color: rgb(102, 174, 4);' : 'color: rgb(199, 54, 57);')" >
              {{ currentRfp.lawFirmRelations[0].rank || '0' }}
            </h2>
            <h2 v-if="!isRankOnlyMode">&nbsp;of&nbsp;{{ currentRfp.activeLawFirms || '0' }}</h2>
          </div>
          <h2 v-else>-</h2>
        </section>
      </div>
    </template>
    <template v-else>
      <div class="ranks">
        <section class="sections">
          <span class="label">{{ 'activeRFP.biddingProcessSection.lowestBid' | t }}</span>
          <div class="bidDiv">
            <h2>{{ orderedBids.length > 0 ? formatBid(orderedBids[0].relevant_display_value) : '-' }} </h2>
            <CapBreakdown :computed-bid="orderedBids[0]" v-if="orderedBids.length" />
          </div>
          <b v-if="orderedBids.length && isBlendedCapEstimate">{{ `(${formatBid(orderedBids[0].amount_blended_rate)} per hour  )` }}</b>
        </section>

        <section class="sections">
          <span class="label">{{ 'activeRFP.biddingProcessSection.totalSavingsLabel' | t }}</span>
          <h2>{{ orderedBids.length > 0 ? Math.round(((orderedBids[orderedBids.length - 1].relevant_display_value - orderedBids[0].relevant_display_value) / orderedBids[orderedBids.length - 1].relevant_display_value) * 100) + '%': '-' }}</h2>

        </section>
      </div>
    </template>

  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import CapBreakdown from './CapBreakdown'

export default {
  name: 'AuctionRankInfo',
  components: { CapBreakdown },
  computed: {
    ...mapGetters([
      'currentLawFirmHash',
      'userIsCorporate',
      'isRankOnlyMode',
      'userLastBidValue',
      'computedBidFromAuctionBid',
      'isSimpleRfp',
      'isBlendedCapEstimate',
      'isSeniorityFeeSubstructure',
      'userBidBreakdown',
      'formatBid',
      'auctionProgress',
      'RfpProgress'
    ]),
    ...mapState({
      currentRfp: state => state.currentRfp,
      bids: state => state.auction.bids,
      usersLastBid: state => state.auction.usersLastBid
    }),

    orderedBids () {
      return this.bids.slice().sort((a, b) => a.relevant_display_value - b.relevant_display_value)
    },

    bidsWithSubBids () {
      const firstBidMap = new Map()
      const result = []

      this.orderedBids.forEach(bid => {
        if (firstBidMap.has(bid.law_firm_id)) {
          const bestBid = firstBidMap.get(bid.law_firm_id)
          bestBid.subBids.push(bid)
        } else {
          bid.subBids = []
          bid.initialBid = this.getInitialBidForLawFirm(bid.law_firm_id)
          firstBidMap.set(bid.law_firm_id, bid)
          result.push(bid)
        }
      })

      return result
    },

    userPosition () {
      if (this.userIsCorporate) {
        return '-'
      }

      const index = this.bidsWithSubBids.findIndex(bid => bid.law_firm_id === this.currentLawFirmHash)

      return index > -1 ? index + 1 : '-'
    },

    numberOfInititalBids () {
      const endOfInitialBids = moment.utc(this.currentRfp.firstBidDeadline)
      return this.orderedBids.filter(bid => moment.utc(bid.time_of_placement).isBefore(endOfInitialBids)).length
    }
  },
  methods: {
    // formatBid (bid) {
    //   if (bid) {
    //     // const cents = (bid % 100) + ''
    //     const euros = Math.trunc(bid).toString()
    //     return euros.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €' // + ',' + (cents.length === 1 ? cents + '0' : cents) + ' €'
    //   } else {
    //     return '0 €'
    //   }
    // },

    getInitialBidForLawFirm (lawFirmId) {
      for (let i = this.orderedBids.length - 1; i >= 0; i--) {
        if (this.orderedBids[i].law_firm_id === lawFirmId) {
          return this.orderedBids[i].relevant_display_value
        }
      }

      return '-'
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../../assets/css/variables.styl"

  h2
    display inline-flex

  .bidDiv
    display inline-flex
    align-items center

  .v-card
    border-radius: 10px;
    background: #fff;
    padding: 30px;

  .ranks
    display grid
    grid-template-columns 1fr 1fr
    margin 5px 0

  .sections
    display grid
    grid-template-rows 0.5fr 1fr 0.5fr

  .label
    font-weight bold
    color $icon-gray

  p
    margin 0
    font-weight bold
    display inline-flex
</style>
