export function usersHeaders (userIsAdmin) {
  const result = [
    {
      text: 'active',
      align: 'left',
      sortable: false,
      value: '',
      width: '80px'
    },
    {
      text: 'email',
      align: 'left',
      sortable: 'true',
      value: 'email'
    },
    {
      text: 'name',
      align: 'left',
      sortable: true,
      value: 'fullName'
    }
  ]

  if (userIsAdmin) {
    result.push(
      {
        text: 'role',
        align: 'left',
        sortable: 'true',
        value: 'primaryRole'
      },
      {
        text: 'organisation',
        align: 'left',
        sortable: false,
        value: 'corporate'
      })
  }

  result.push(
    {
      text: 'position',
      align: 'left',
      sortable: false,
      value: 'position'
    },
    {
      text: 'mainContactPerson',
      align: 'left',
      sortable: false,
      value: 'mainContactPerson'
    },
    {
      text: 'add',
      addUserBtn: true,
      sortable: false,
      value: 'lawfirm',
      width: userIsAdmin ? '170px' : '170px'
    })

  return result
}

export const lawFirmHeaders = [
  {
    text: 'active',
    align: 'left',
    sortable: false,
    value: '',
    width: '80px'
  },
  {
    text: 'name',
    align: 'left',
    sortable: true,
    value: 'fullName'
  },
  {
    text: 'street',
    align: 'left',
    sortable: 'true',
    value: 'street'
  },
  {
    text: 'city',
    align: 'left',
    sortable: false,
    value: 'city'
  },
  {
    text: 'country',
    sortable: false,
    value: 'country'
  },
  {
    text: 'taxId',
    addUser: true,
    sortable: false,
    value: 'taxId'
  },
  {
    text: 'add',
    addUser: true,
    sortable: false,
    addLawFirmBtn: true,
    value: '',
    width: '335px'
  }
]

export const corporateHeaders = [
  {
    text: 'active',
    align: 'left',
    sortable: false,
    value: '',
    width: '80px'
  },
  {
    text: 'name',
    align: 'left',
    sortable: true,
    value: 'name',
    width: '290px'
  },
  {
    text: 'transactionVolume',
    align: 'left',
    sortable: false,
    width: '145px',
    value: 'rfpsAvailable'
  },
  {
    text: 'paymentPeriodStart',
    align: 'left',
    sortable: false,
    width: '95px',
    value: 'rfpsAvailable'
  },
  {
    text: 'panel',
    align: 'left',
    sortable: false,
    value: 'name'
  },
  {
    text: 'add',
    addCorporateBtn: true,
    sortable: false,
    value: '',
    width: '255px'
  }
]
