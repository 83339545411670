<template>
  <div class="credential-input">
    <div class="full-width">
      <span class="mandatory light">{{ 'activeRFP.proposal.description' | t }}</span>
      <v-textarea
        label=""
        solo flat
        :class="{missing: missingMandatoryFields.includes('description-' + credential.id) }"
        @input="removeMissingMandatoryField('description-' + credential.id)"
        v-model="credential.extendedFields.description"></v-textarea>
    </div>
    <div>
      <span class="mandatory light">{{ 'activeRFP.proposal.year' | t }}</span>
      <v-text-field
        label=""
        solo flat
        :class="{missing: missingMandatoryFields.includes('year-' + credential.id) || invalidFields.includes('year-' + (!credential.id ? 'newCredential' : credential.id)) }"
        @input="removeMissingMandatoryField('year-' + credential.id) ; removeInvalidField('year-' + (!credential.id ? 'newCredential' : credential.id))"
        v-model="credential.extendedFields.year"></v-text-field>
    </div>
    <div
      v-if="currentRfp.terms.qualityScoreCriteria && currentRfp.terms.qualityScoreCriteria.includes('TEAM')"
      class="full-width">
      <div class="other-parties-header">
        <span class="light">{{ 'activeRFP.proposal.involvementOfTeamMembers' | t }}</span>
        <v-btn flat @click="addInvolvement"><v-icon>ic-user-plus</v-icon>&nbsp;{{ 'activeRFP.proposal.addInvolvementOfTeamMember' | t }}</v-btn>
      </div>
      <div
        :key="involvement.id"
        v-for="(involvement, index) in credential.extendedFields.involvementOfProposedTeamMembers"
        class="other-parties">

        <div>
          <span class="light mandatory">{{ 'activeRFP.proposal.teamMember' | t }}</span>
          <v-select
            label=""
            :class="{missing: missingMandatoryFields.includes('CREDENTIAL_' + credential.id + '_USER_ID_' + involvement.id)}"
            @input="removeMissingMandatoryField('CREDENTIAL_' + credential.id + '_USER_ID_' + involvement.id)"
            v-model="involvement.userId"
            item-text="fullName"
            item-value="id"
            :items="teamMembers"
            :placeholder="'activeRFP.proposal.placeholders.teamMember' | t "
            solo
            flat
          ></v-select>
        </div>

        <div>
          <span class="light mandatory">{{ 'activeRFP.proposal.role' | t }}</span>
          <v-select
            :items="credentialRoleOptions"
            label=""
            :class="{missing: missingMandatoryFields.includes('CREDENTIAL_' + credential.id + '_ROLE_' + involvement.id)}"
            @input="removeMissingMandatoryField('CREDENTIAL_' + credential.id + '_ROLE_' + involvement.id)"
            solo flat
            v-model="involvement.role"
            :placeholder="'activeRFP.proposal.placeholders.role' | t "
          ></v-select>
        </div>

        <div>
          <v-btn
            style="margin-top: 15px;"
            @click="removeInvolvement(involvement, index)" flat fab icon>
            <v-icon>clear</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="full-width new-actions">
      <v-btn flat @click="$emit('cancel')">{{ 'buttons.cancel' | t }}</v-btn>
      <v-btn class="main" @click="$emit('save')">{{ 'activeRFP.proposal.saveCredential' | t }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import shortid from 'shortid'

export default {
  name: 'ProposalCredentialInput',
  props: {
    credential: {
      type: Object,
      required: true
    }
  },
  created () {
    if ((this.currentRfp.terms.qualityScoreCriteria && this.currentRfp.terms.qualityScoreCriteria.includes('TEAM') && !this.credential.extendedFields.involvementOfProposedTeamMembers) || !this.credential.extendedFields.involvementOfProposedTeamMembers.length) {
      this.addInvolvement()
    }
  },
  computed: {
    ...mapGetters(['credentialRoleOptions', 'currentRfpLawFirmRelation']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields,
      currentRfp: state => state.currentRfp,
      updateMode: state => state.draftProposal.updateMode
    }),

    teamMembers () {
      const team = this.currentRfpLawFirmRelation.proposal.find(category => category.name === 'TEAM')

      if (team) {
        return team.subItems.map(item => item.linkedUser)
      }

      return []
    }
  },
  methods: {
    ...mapMutations(['removeMissingMandatoryField', 'removeInvalidField']),

    addInvolvement () {
      if (!(this.currentRfp.terms.qualityScoreCriteria && this.currentRfp.terms.qualityScoreCriteria.includes('TEAM'))) {
        return
      }

      if (!this.credential.extendedFields.involvementOfProposedTeamMembers) {
        this.credential.extendedFields.involvementOfProposedTeamMembers = []
      }

      this.credential.extendedFields.involvementOfProposedTeamMembers.push({
        id: shortid.generate(),
        userId: '',
        role: ''
      })
    },

    removeInvolvement (involvement, index) {
      this.removeMissingMandatoryField('CREDENTIAL_' + this.credential.id + '_USER_ID_' + involvement.id)
      this.removeMissingMandatoryField('CREDENTIAL_' + this.credential.id + '_ROLE_' + involvement.id)
      this.credential.extendedFields.involvementOfProposedTeamMembers.splice(index, 1)

      if (!this.credential.extendedFields.involvementOfProposedTeamMembers.length) {
        this.addInvolvement()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .credential-input
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 1fr 1fr

  .full-width
    grid-column-start 1
    grid-column-end 3

  .new-actions
    text-align right

  button
    border-radius 5px

  .other-parties
    display grid
    grid-template-columns 1fr 1fr 60px
    grid-column-gap 10px

    >>> .v-input__slot
      margin-bottom 10px !important

  .other-parties-header
    display grid
    grid-template-columns 1fr 150px
    height 36px

    span
      line-height 36px

    button
      margin-top 0

    .v-icon
      font-size 15px
      &:before
        color $dark-gray !important
</style>
