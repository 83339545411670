import apollo from '../../apollo'
import gql from 'graphql-tag'

import { completeRfp, question, rfpForListView, terms, attachment } from '../graphqlTags/rfpTags'

export default {

  async loadAllRfps ({ commit, getters, state }) {
    const response = await apollo(state).query({
      query: gql`
        query allRfps {
          allRfps {
            ${rfpForListView(getters.userIsLawFirm, getters.userIsAdmin)}
          }
        }
      `
    })

    const allRfps = response.data.allRfps

    commit('setAllRfps', allRfps)
  },
  async reloadScopeGeneratorLayoutsBySubject ({ commit, state }) {
    let rfp
    if (state.currentRfp.status === 'DRAFT') {
      const response = await apollo(state).query({
        query: gql`
        query rfp($id: ID!) {
          rfp (id: $id) {
              usesScopeGenerator
              scopeGeneratorLayoutsBySubject
          }
        }
      `,
        variables: {
          id: state.currentRfp.id
        }
      })
      rfp = response.data.rfp
    } else {
      const response = await apollo(state).query({
        query: gql`
        query history($id: ID!) {
          history (rfpId: $id) {
              usesScopeGenerator
              scopeGeneratorLayoutsBySubject
          }
        }
      `,
        variables: {
          id: state.rfpDraft.rfpId
        }
      })
      rfp = response.data.history[0]
    }

    commit('updateCurrentRfp', { field: 'usesScopeGenerator', value: rfp.usesScopeGenerator })
    commit('updateCurrentRfp', { field: 'scopeGeneratorLayoutsBySubject', value: rfp.scopeGeneratorLayoutsBySubject })
  },

  async loadRfp ({ commit, getters, state, dispatch }, { rfpId, includeQA, includeProposal }) {
    const response = await apollo(state).query({
      query: gql`
        query rfp($id: ID!) {
          rfp (id: $id) {
            ${completeRfp(getters.userIsLawFirm)}
            qaQuestions {
              ${question(getters.userIsLawFirm)}
            }
          }
        }
      `,
      variables: {
        id: rfpId
      }
    })

    const rfp = response.data.rfp
    if (rfp) {
      // add some fields for UI puposes (show or hide elemts etc in the Q&A page)
      rfp.qaQuestions = rfp.qaQuestions ? rfp.qaQuestions.map(question => ({
        ...question,
        showEditor: false,
        showAnswer: false,
        newAnswer: '',
        updateAnswer: false,
        newFiles: []
      })) : []
      await dispatch('loadRfpHistory', { rfpId: rfp.id })

      if (!rfp.terms) {
        rfp.terms = {}
      }
      if (rfp.status === 'DRAFT') {
        rfp.lawFirmRelations.forEach(lawFirms => {
          delete lawFirms.status
        })
      }

      rfp.scope = rfp.scope || {}

      commit('setCurrentRfp', rfp)
    } else {
      commit('setCurrentRfp', null)
    }
  },

  async loadRfpHistory ({ state, commit }, { rfpId }) {
    const response = await apollo(state).query({
      query: gql`
        query history($id: ID!) {
          history (rfpId: $id) {
            id
            version
            rfpId
            name
            description
            mandateType
            mandateSubType
            submission
            submissionMessage
            corporate {
              id
            }
            attachments {
              ${attachment}
            }
            scopeGeneratorLayoutsBySubject
            subjects {
              key
              translatedLabel
            }
            scope
            quickScope
            lawFirmRelations {
              historyRfp {
                rfpId
              }
              lawFirm {
                id
              }
              disabled
              requestedLawyers {
                id
                fullName
              }
              disabledRequestedLawyerIds
            }
            activeLawFirms
            terms {
              ${terms}
            }
            auctionType
            auctionSubType
            allowQA
            newBidExtendsAuction
            qaEnd
            firstBidDeadline
            auctionStart
            auctionEnd
            clientName
            coi1
            draftStep
            usesScopeGenerator
            disclosureMode
            nextSteps
            freeScope {
              facts
              requestedServices
              governingLaw
              areasOfLaw
              timeline
              languages
            }
          }
        }
      `,
      variables: {
        id: rfpId
      }
    })
    const history = response.data.history
    commit('setRfpDraft', history[0])
    commit('setRfpHistory', history)
    commit('setHistoryRfpVersion', history.length ? history[0].version : 0)
  },

  async uploadInnvoiceFiles ({ state, commit }, { rfpId, file, type }) {
    const data = new FormData()
    data.append('file', file)
    data.append('type', type)

    const request = {
      method: 'POST',
      body: data,
      enctype: 'multipart/form-data',
      headers: { 'x-auth-token': state.authToken }
    }

    // console.log('UploadingFile', request, rfpId)
    const response = await fetch('/rest/rfp/' + rfpId + '/files/upload', request)
    if (!response.ok) {
      commit('setSpecialErrorMessage', 'Please note that files may only take up to 20MB')
      commit('setShowDefaultError', true)
      return
    }
    const newAttachments = await response.json()
    return newAttachments// return 'some'
  },

  async finaliseMatter ({ state, getters, commit }, { rfpId }) {
    await apollo(state).mutate({
      mutation: gql`
      mutation finaliseMatter($rfpId: ID!) {
        finaliseMatter(rfpId: $rfpId)
      }
      `,
      variables: {
        rfpId
      }
    })

    commit('changeStatus', { rfpId, status: 'COMPLETED_FEEDBACK_REQUIRED' })
  },

  async deleteRfp ({ state, getters, commit }, { rfpId }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation deleteRfp($rfpId: ID!) {
          deleteRfp(rfpId: $rfpId)
        }
      `,
      variables: {
        rfpId
      }
    })

    commit('deleteRfp', { rfpId })
  },

  async abortRfp ({ state, getters, commit }, { rfpId, abortionMessage }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation abortRfp($rfpId: ID!, $abortionMessage: String) {
          abortRfp(rfpId: $rfpId, abortionMessage: $abortionMessage)
        }
      `,
      variables: {
        rfpId,
        abortionMessage
      }
    })

    // commit('deleteRfp', { rfpId })
  },

  async toggleLiveFeedback ({ state, commit }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation setRfpShareLiveFeedback($rfpId: ID!, $value: Boolean) {
          setRfpShareLiveFeedback(rfpId: $rfpId, value: $value)
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        value: !state.currentRfp.shareLiveFeedback
      }
    })

    commit('toggleLiveFeedback')
  }

}
