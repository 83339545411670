<template>
  <v-card class="card">
    <span class="rank">{{rank}}</span>
    <v-chip v-if="bestPrice" class="best-card first">{{ 'activeRFP.decision.bestPriceLabel' | t }}</v-chip>
    <v-chip v-if="bestRating && hasAnyQualityScore" class="best-card" :class="{first: (!bestPrice && bestRating) ,second: (bestPrice && bestRating)}">{{ 'activeRFP.decision.bestRatingLabel' | t }}</v-chip>
    <h2 class="title">
      <TooltipHolder
        :text="lawFirmName"
        :tooltipText="lawFirmName"
        :tableDataWidth="240"
        :moveLeft="-30"
        tableData
        changeHeight
        bold />
    </h2>
    <div v-if="!qualityScoreCriteria" class="placeholder" :style="'height: ' + (477 + proposalHeight -258) + 'px'">
      <strong class="not-rated">{{ 'activeRFP.decision.notRated' | t }}</strong>
      <v-icon class="not-rated-icon">ic-alert-octagon</v-icon>
      <v-btn v-if="!currentRfp.winner && !disabled" class="main rate" style="margin-top: 12px" @click="$emit('rate', lawFirmId)">{{ 'activeRFP.decision.rateNow' | t }}</v-btn>
      <div style="margin-top: 20px" v-else><strong>{{ 'activeRFP.decision.notRatedLabel' | t }}</strong></div>
    </div>
    <template v-else-if="hasAnyQualityScore">
      <h2>{{ overallScore.toFixed(1) }}</h2>
      <p class="out-of-five">{{ 'activeRFP.decision.outOfFive' | t }}</p>
      <v-btn class="main rate view" @click="$emit('rate', lawFirmId)">{{ 'activeRFP.decision.view' | t }}</v-btn>
      <div class="proposal-score" :style="'height: ' + proposalHeight + 'px'">
        <h3>{{ proposalScore.toFixed(1) }}</h3>
        <p class="out-of-five">{{ 'activeRFP.decision.outOfFive' | t }}</p>

        <div class="proposal-item" v-if="qualityScoreCriteria">
          <span v-if="qualityScoreCriteria.team" class="proposal team">{{ 'activeRFP.proposal.team' | t}}</span>
          <span v-if="qualityScoreCriteria.team">{{ qualityScoreCriteria.team.rating ? qualityScoreCriteria.team.rating.toFixed(1) : 'n/a' }}</span>

          <span v-if="qualityScoreCriteria.credentials" class="proposal credentials">{{ 'activeRFP.proposal.credentials' | t }}</span>
          <span v-if="qualityScoreCriteria.credentials">{{ qualityScoreCriteria.credentials.rating.toFixed(1) }}</span>

          <span v-if="qualityScoreCriteria.pitchDocument" class="proposal pitch-document">{{ 'activeRFP.proposal.pitchDocument' | t }}</span>
          <span v-if="qualityScoreCriteria.pitchDocument">{{ qualityScoreCriteria.pitchDocument.rating.toFixed(1) }}</span>
          <span v-if="qualityScoreCriteria.liability" class="proposal liability">{{ 'activeRFP.proposal.liability' | t }}</span>
          <span v-if="qualityScoreCriteria.liability">{{ qualityScoreCriteria.liability.rating.toFixed(1) }}</span>

          <span v-if="qualityScoreCriteria.qa" class="proposal qa">{{ 'activeRFP.proposal.qa' | t }}</span>
          <span v-if="qualityScoreCriteria.qa">{{ qualityScoreCriteria.qa.rating.toFixed(1) }}</span>

          <span v-if="qualityScoreCriteria.interview" class="proposal interview">{{ 'activeRFP.proposal.interview' | t }}</span>
          <span v-if="qualityScoreCriteria.interview">{{ qualityScoreCriteria.interview.rating.toFixed(1) }}</span>

          <span v-if="qualityScoreCriteria.strategy" class="proposal strategy">{{ 'activeRFP.proposal.strategy' | t }}</span>
          <span v-if="qualityScoreCriteria.strategy">{{ qualityScoreCriteria.strategy.rating.toFixed(1) }}</span>

          <span v-if="qualityScoreCriteria.custom1" class="proposal custom1">{{ currentRfpCustomQualityScoreTitle('CUSTOM1') }}</span>
          <span v-if="qualityScoreCriteria.custom1">{{ qualityScoreCriteria.custom1.rating.toFixed(1) }}</span>

          <span v-if="qualityScoreCriteria.custom2" class="proposal custom2">{{ currentRfpCustomQualityScoreTitle('CUSTOM2') }}</span>
          <span v-if="qualityScoreCriteria.custom2">{{ qualityScoreCriteria.custom2.rating.toFixed(1) }}</span>

          <span v-if="qualityScoreCriteria.custom3" class="proposal custom3">{{ currentRfpCustomQualityScoreTitle('CUSTOM3') }}</span>
          <span v-if="qualityScoreCriteria.custom3">{{ qualityScoreCriteria.custom3.rating.toFixed(1) }}</span>
        </div>
      </div>
    </template>

    <div class="final-bid">
      <h3>{{ renderedBid }} </h3>
      <span>{{ $t('activeRFP.decision.currency', { currency: $t(`dropDownOptions.Currency.${currentRfp.terms.currency || 'EUR'}`) }) }}</span>
    </div>
    <div class="action">
      <v-btn
        v-if="!currentRfp.winner && !disabled"
        @click="$emit('choose', lawFirmId)"
        class="choose" flat>{{ 'buttons.chooseWinner' | t }}</v-btn>
      <h2 style="margin-top: 0; height: 48px;" class="winner-text" v-else-if="!disabled && currentRfp.winner.id === lawFirmId">{{ 'activeRFP.decision.winner' | t }}</h2>
      <div v-else style="height: 48px"/>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TooltipHolder from '../../generalComponents/TooltipHolder'

export default {
  name: 'DecisionCard',
  components: { TooltipHolder },
  props: {
    lawFirmId: String,
    lawFirmName: String,
    overallScore: Number,
    proposalScore: Number,
    qualityScoreCriteria: Object,
    finalBid: Object,
    bestPrice: Boolean,
    bestRating: Boolean,
    hasAnyQualityScore: Boolean,
    rank: Number,
    proposalHeight: Number,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      currentRfp: state => state.currentRfp
    }),
    ...mapGetters(['currentRfpCustomQualityScoreTitle', 'computedBidFromAuctionBid', 'roundBidToThousands', 'formatNumber']),

    renderedBid () {
      if (this.finalBid !== null) {
        return this.finalBid.relevantDisplayValue > 10000 ? this.roundBidToThousands(this.finalBid.relevantDisplayValue) + 'k' : this.formatNumber(Math.round(this.finalBid.relevantDisplayValue))
      }

      return ''
    }
  }
  // methods: {
  //   roundBidToThousands (bid) {
  //     return Math.trunc(bid / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  //   },
  //   setBidDisplayToEuro (bid) {
  //     return (bid).toLocaleString('de-DE')
  //   }
  // }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .card
    width 300px
    border-radius 10px
    padding 30px

  h2
    margin 49px 0 0px 0
    text-align center
    font-size 40px
    line-height 40px

    &.title
      margin 40px 0 0 0
      padding-bottom 28px
      font-size 28px
      border-bottom 1px solid lightgray

  .proposal-score
    border-top 1px solid lightgray
    padding 20px 10px 10px 10px

    h3
      text-align center
      font-size 38px
      line-height 38px

  .proposal-item
    display grid
    grid-template-columns 1fr 30px
    margin-left 20px
    height 149px

    span, strong
      font-size 15px

  .proposal
    position relative

    &:before
      content ''
      position absolute
      left -20px
      width 12px
      height 12px
      border-radius 6px
      top 3px

  .team:before
    background-color #402e32

  .credentials:before
    background-color #564648

  .pitch-document:before
    background-color #6c5d5e

  .liability:before
    background-color #827574

  .qa:before
    background-color #8d817f

  .interview:before
    background-color #978c8a

  .strategy:before
    background-color #ada4a0

  .custom1:before
    background-color #c3bbb6

  .custom2:before
    background-color #d9d3cc

  .custom3:before
    background-color #eeeae1

  .choose
    background-color $gold100
    color white
    border-radius 5px
    width 200px

  .action
    text-align center
    margin 20px 0

  .final-bid
    border-top 1px solid lightgray
    text-align center
    padding 20px 0

    h3
      font-size 38px
      line-height 38px

  .placeholder
    height 417px
    text-align center
    padding 40px 0

  .not-rated
    display block
    font-size 16px

  .not-rated-icon:before
    margin-top 20px
    font-size 60px

  .rate
    display block
    margin 36px auto
    margin-top: 15px !important

  .view
    margin-bottom 23px
    margin-top: 47px !important

  .out-of-five
    text-align center

  .rank
    position absolute
    left: 0
    float left
    margin-left 10px
    font-size: 20px
    top 7px

  .best-card
    position absolute
    background-color $green !important
    color white
    right 0px
    float right
    margin-right 10px
    &.first
      top 5px
    &.second
      top 35px

  .winner-text
    color $gold100
</style>
