<template>
  <div class="grid">
    <div style="display: grid; grid-template-columns: max-content 1fr; grid-column-gap: 10px">
      <h2 ref="title">{{ 'activeRFP.proposal.team' | t }}</h2>
      <v-rating
        v-if="team && team.rating"
        small
        color="orange"
        class="raitings"
        background-color="orange lighten-3"
        readonly
        :value="team.rating"
        half-increments hover />
    </div>

    <v-btn v-if="proposalIsEditable && updateMode" class="add-btn" @click="openTeamPage" flat><v-icon>ic-user-plus</v-icon>&nbsp;{{ 'activeRFP.proposal.addTeamMember' | t}}</v-btn>
    <p
      v-if="requestedContent"
      style="margin-top: -10px; margin-bottom: 20px; grid-column: 1 / 3; max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%">{{ 'activeRFP.proposal.requestedTeamDiversity' | t }} {{ requestedContent }}</p>

    <p
      v-if="team && team.ratingComment"
      style="margin-top: -10px; margin-bottom: 20px; grid-column: 1 / 3; max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%">Feedback from the client: {{ team.ratingComment }}</p>

    <v-card v-if="!teamMembers || teamMembers.length === 0" class="no-credentials-card" :class="{missing: missingMandatoryFields.includes('TEAM')}">
      <h3>{{ 'activeRFP.proposal.noTeamMembers' | t }}</h3>
    </v-card>
    <v-card class="member" :class="{'member-card': !member.update, missingcard: missingMandatoryFields.some(field => field.startsWith('TEAM_' + member.id + '_'))}" v-for="member in teamMembers" :key="member.id">
      <template v-if="!member.update">
        <v-icon class="member-icon">ic-user</v-icon>
        <strong style="font-size: 16px;">{{ member.linkedUser.fullName }}</strong>
        <span style="font-size: 16px;">{{member.extendedFields.seniorityLevel ? $t(`dropDownOptions.SeniorityLevel.${member.extendedFields.seniorityLevel}`) : ''}}</span>
        <span v-if="member.extendedFields && member.extendedFields.hasDocumentUpload" class="attachments-label">
          <FileChip v-for="file in member.attachments" :key="file.id" :file="file"></FileChip>
        </span>
        <span v-else style="font-size: 16px;" class="attachments-label">
          {{ member.extendedFields ? member.extendedFields.cvLink : '' }}
        </span>
        <v-btn v-if="proposalIsEditable && updateMode" icon @click="updateMember(member)"><v-icon>ic-edit</v-icon></v-btn>
      </template>
      <div v-else class="update">
        <div>
          <!-- <strong style="display: block">{{ member.linkedUser.fullName }}</strong> -->
          <span class="light">{{ 'activeRFP.proposal.name' | t}}</span>
          <div class="alignment">
            <v-text-field label=""
              solo
              flat
              :value="member.linkedUser.fullName"
              color="rgb(78, 78, 78)"
              class="align"></v-text-field>
          </div>
        </div>
        <div>
          <span class="light mandatory">{{ 'activeRFP.proposal.seniorityLevelPlaceholder' | t }}</span>
          <div class="alignment">
            <v-select
            label=""
            :class="{ missing: missingMandatoryFields.includes('TEAM_' + member.id + '_SENIORITY_LEVEL') }"
            @input="removeMissingMandatoryField('TEAM_' + member.id + '_SENIORITY_LEVEL')"
            v-model="member.newValue.extendedFields.seniorityLevel"
            :items="seniorityLevelOptions"
            :placeholder="$t('activeRFP.proposal.seniorityLevelPlaceholder')"
            solo
            attach="grid"
            flat >
              <template v-slot:item="data">
                <TooltipHolder
                v-if="data.item.value !== 'ASSOCIATE'"
                :text="data.item.text"
                :tooltipText="$t(`tooltips.proposalTooltip${data.item.value}`)"
                :right="true"
                :moveTop="0"
                :width="200"
                class="custom-tooltip"
                :moveLeft="10"
                :spanId="data.item.value" />
                <div v-else>{{data.item.text}}</div>
              </template>
            </v-select>
          </div>
        </div>
        <div v-if="currentRfp.terms.isCVMandatory">
          <span>&nbsp;</span><br>
          <a @click="$set(member.newValue.extendedFields, 'hasDocumentUpload', !member.newValue.extendedFields.hasDocumentUpload); removeMissingMandatoryField('TEAM_' + member.id + '_CV')"> {{ `activeRFP.proposal.${member.newValue.extendedFields.hasDocumentUpload ? 'useCvLink' : 'useDocumentUpload'}` | t }}</a>
        </div>

        <div v-if="currentRfp.terms.isCVMandatory" class="upload-component">
          <DocumentUpload
            v-if="member.newValue.extendedFields.hasDocumentUpload"
            style="margin-top: 17px"
            :missing="missingMandatoryFields.includes('TEAM_' + member.id + '_CV')"
            type="PROPOSAL_CATEGORY_SUB_ITEM"
            :proposalCategorySubItemId="member.id"
            delete-icon
            mandatory
            @deleteFile="file => deleteFileLocal(member, file)"
            @uploaded="file => { member.newValue.attachments.push(file); removeMissingMandatoryField('TEAM_' + member.id + '_CV') }"
            :documents="member.newValue.attachments"
            labelKey="newRFP.documentUpload.documentLabelProposalTeamCV" />
          <template v-else>
            <span class="light mandatory">{{ 'activeRFP.proposal.linkToCV' | t }}</span>
            <v-text-field
              label=""
              :class="{ missing: missingMandatoryFields.includes('TEAM_' + member.id + '_CV') }"
              @input="removeMissingMandatoryField('TEAM_' + member.id + '_CV')"
              :placeholder="'activeRFP.proposal.linkToCvPlcaceholder' | t "
              solo
              flat
              v-model="member.newValue.extendedFields.cvLink"></v-text-field>
          </template>
        </div>

        <div class="actions">
          <v-btn flat @click="cancelUpdate(member)">{{ 'buttons.cancel' | t }}</v-btn>
          <v-btn class="main" @click="save(member)">{{ 'activeRFP.proposal.saveTeamMember' | t }}</v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import DocumentUpload from '../../generalComponents/DocumentUpload'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import FileChip from '../../generalComponents/FileChip'

export default {
  name: 'ProposalTeam',
  components: { DocumentUpload, TooltipHolder, FileChip },
  computed: {
    ...mapGetters(['currentRfpLawFirmRelation', 'seniorityLevelOptions', 'proposalIsEditable']),
    ...mapState({
      currentRfp: state => state.currentRfp,
      allUsers: state => state.admin.allUsers,
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      teamMembersDraft: state => state.draftProposal.teamMembers,
      updateMode: state => state.draftProposal.updateMode
    }),

    teamMembers () {
      if (this.updateMode) {
        return this.teamMembersDraft
      }

      return this.team.subItems ? this.team.subItems.filter(subItem => !!subItem.linkedUser).map(subItem => {
        return {
          id: subItem.id,
          extendedFields: subItem.extendedFields || {},
          attachments: subItem.attachments,
          linkedUser: subItem.linkedUser
        }
      }) : []
    },

    team () {
      if (!this.currentRfpLawFirmRelation.proposal) {
        return
      }
      return this.currentRfpLawFirmRelation.proposal.find(category => (this.updateMode ? category.draft : !category.draft) && category.name === 'TEAM') || {}
    },

    requestedContent () {
      return this.currentRfp.terms.teamDiversity
    }
  },
  created () {
    this.setLocalValue()
  },
  watch: {
    team () {
      this.setLocalValue()
    },
    currentRfpLawFirmRelation () {
      if (this.teamMembersDraft) {
        const draftTeam = this.currentRfpLawFirmRelation.proposal.find(category => category.draft && category.name === 'TEAM') || {}
        this.addOrRemoveDraftTeamMembers(draftTeam.subItems ? draftTeam.subItems.filter(subItem => !!subItem.linkedUser).map(subItem => {
          return {
            id: subItem.id,
            extendedFields: subItem.extendedFields || {},
            attachments: subItem.attachments,
            linkedUser: subItem.linkedUser
          }
        }) : [])
      }
    }
  },
  methods: {
    ...mapActions(['saveProposalTeamMember', 'saveTeamMember', 'deleteFile', 'checkProposalUnsavedChanges']),
    ...mapMutations(['removeProposalTeamMember', 'removeMissingMandatoryField', 'addMissingMandatoryField', 'setDraftProposalItem', 'addOrRemoveDraftTeamMembers']),

    attachmentNames (attachments) {
      return attachments.map(attachment => attachment.fileName).join(', ')
    },

    setLocalValue () {
      if (!this.team) {
        return
      }

      const teamMembers = this.team.subItems ? this.team.subItems.filter(subItem => !!subItem.linkedUser).map(subItem => {
        return {
          id: subItem.id,
          extendedFields: subItem.extendedFields || {},
          attachments: subItem.attachments,
          linkedUser: subItem.linkedUser
        }
      }) : []

      this.setDraftProposalItem({
        key: 'teamMembers',
        value: teamMembers
      })
    },

    updateMember (member) {
      this.$set(member, 'update', true)
      this.$set(member, 'newValue', {
        extendedFields: { ...member.extendedFields },
        attachments: [ ...member.attachments ]
      })
      this.checkProposalUnsavedChanges()
    },

    cancelUpdate (member) {
      this.removeMissingMandatoryField('TEAM_' + member.id + '_SENIORITY_LEVEL')
      this.removeMissingMandatoryField('TEAM_' + member.id + '_CV')
      member.update = false
      this.checkProposalUnsavedChanges()
    },

    openTeamPage () {
      this.$router.push({ name: 'activeRfp', params: { activeTab: 'team' } })
    },

    save (member) {
      let missing
      if (!member.newValue.extendedFields || !member.newValue.extendedFields.seniorityLevel) {
        this.addMissingMandatoryField('TEAM_' + member.id + '_SENIORITY_LEVEL')
        missing = true
      }

      if (this.currentRfp.terms.isCVMandatory &&
        (!member.newValue.extendedFields || !member.newValue.extendedFields.hasDocumentUpload ? !member.newValue.extendedFields || !member.newValue.extendedFields.cvLink : !member.newValue.attachments || !member.newValue.attachments.length)) {
        this.addMissingMandatoryField('TEAM_' + member.id + '_CV')
        missing = true
      }

      if (missing) {
        return
      }

      member.update = false

      const teamMember = {
        id: member.id,
        extendedFields: member.newValue.extendedFields,
        attachments: member.newValue.attachments.map(attachment => ({
          id: attachment.id,
          rfp: {
            id: this.currentRfp.id
          }
        })),
        linkedUser: {
          id: member.linkedUser.id,
          fullName: member.linkedUser.fullName
        }
      }

      this.saveProposalTeamMember({ teamMember })
      member.extendedFields = member.newValue.extendedFields
      member.attachments = member.newValue.attachments
    },

    deleteFileLocal (member, file) {
      const index = member.newValue.attachments.findIndex(_file => _file.id === file.id)

      if (index > -1) {
        member.newValue.attachments.splice(index, 1)
        this.deleteFile({
          rfpId: this.currentRfp.id,
          fileId: file.id
        })
      }
    },

    getPermissionLevel (user) {
      const userId = user.linkedUser.id
      return this.currentRfp.team.find(teamMember => teamMember.lintumUser.id === userId).level
    },

    async deleteMember (user) {
      await this.saveTeamMember({ userId: user.linkedUser.id, permissionLevel: this.getPermissionLevel(user), hideInProposal: true, isUpdate: true })
      console.log('Remove', user)
      this.removeProposalTeamMember(user)
      const index = this.teamMembers.findIndex(subItem => subItem.id === user.id)

      if (index > -1) {
        this.teamMembers.splice(index, 1)
      }
    }

  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .v-card:not(:last-child)
    margin-bottom 10px

  .v-icon:before
    font-size 15px

  .member-icon
    justify-content start

  .member-icon:before
    font-size 25px

  .grid
    display grid
    grid-template-columns 1fr 200px

  .add-btn
    border-radius 5px

  :deep(.v-icon:before)
      color $dark-gray !important

  .member
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 80px 200px auto 1fr 40px

  .member-card
    padding-top 10px !important
    padding-bottom 10px !important

    strong,
    span
      line-height 48px

  .update
    padding-top 10px !important
    grid-column-start 1
    grid-column-end 7
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-column-gap 20px

    strong,
    span
      line-height initial

  .upload-component
    grid-column-start 1
    grid-column-end 4
    margin-bottom 15px

  .actions
    grid-column-start 1
    grid-column-end 4
    text-align right

    button
      border-radius 5px

  >>> .v-messages
    display none

  .no-credentials-card
    grid-column-start 1
    grid-column-end 3
    border-radius 10px
    padding 10px !important
    text-align center

  .attachments-label
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    display inline-block
    text-align end

  .align
    color $dark-grey !important
    pointer-events none
    font-weight bold
    font-size 14px
    padding-left 0
    margin-left 0

  .align >>> .v-text-field__slot input
    color $dark-grey !important
    border 0px solid white

  .align >>> .v-input__slot
    color $dark-grey !important
    border 0px solid white
    padding-left 0 !important
    margin-left 0

  >>> .v-menu__content
    overflow-x unset !important
    overflow-y unset !important
    contain unset !important
    .v-card
      padding 0px

  .custom-tooltip
    width 100%
    height 48px

    >>> div
      line-height 48px
</style>
