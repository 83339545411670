<template>
  <div class="tabs-panel" :class="{small: small}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {

    // Reduces the size of a tab from 200px to 120px
    small: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .tabs-panel
    border-top 1px solid lightgray
    border-bottom 1px solid lightgray
    display grid
    grid-template-columns repeat(4, auto) 1fr
    grid-column-gap 15px

    &.small
      grid-template-columns 120px 120px 120px 120px 1fr

    >>> a
      font-size 18px
      color gray
      font-weight bold
      display block
      padding 20px
      border 1px solid transparent

      &.invalid,
      &.invalid div
        color $red

      &.active,
      &.active div
        color $dark-gray
        text-decoration underline
      &.active.invalid,
      &.active.invalid div
        color $red

    >>> div.disabled a
      color #bfbbbb
</style>
