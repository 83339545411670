<template>
  <div>
    <h2>{{ 'settings.legalDocuments.title' | t }}</h2>
    <v-card class="profile">
      <ul>
        <li v-if="!userIsCorporate" v-html="$t('settings.legalDocuments.lawFirmsToU')"></li>
        <li v-if="!userIsCorporate" v-html="$t('settings.legalDocuments.lawFirmsPricing')"></li>
        <li v-if="userIsCorporate" v-html="$t('settings.legalDocuments.corporatesToU')"></li>
        <li v-html="$t('settings.legalDocuments.dataProtection')"></li>
        <li v-html="$t('settings.legalDocuments.cookieDeclaration')"></li>
      </ul>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LegalDocuments',
  computed: {
    ...mapGetters(['userIsCorporate'])
  }
}
</script>

<style scoped lang="stylus">
  .profile
    border-radius 10px
    padding 20px
    margin 20px 0px
    display grid
    grid-template-columns 1fr
    grid-row-gap 10px
    font-size 16px
</style>
