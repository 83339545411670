import Vue from 'vue'

export default {

  updateCorporateBilled (state, { id, corporateBilled, corporateBilledDate }) {
    Vue.set(state, 'allRfps', state.allRfps.map(rfp => {
      if (rfp.id === id) {
        rfp.corporateBilled = corporateBilled
        rfp.corporateBilledDate = corporateBilledDate
      }

      return rfp
    }))
  },

  updateCorporateBilledDate (state, { id, corporateBilledDate }) {
    Vue.set(state, 'allRfps', state.allRfps.map(rfp => {
      if (rfp.id === id) {
        rfp.corporateBilledDate = corporateBilledDate
      }

      return rfp
    }))
  },

  updateInvoiceAmount (state, { id, invoiceAmount }) {
    Vue.set(state, 'allRfps', state.allRfps.map(rfp => {
      if (rfp.id === id) {
        rfp.invoiceAmount = invoiceAmount
      }

      return rfp
    }))
  },

  updateLawFirmBilled (state, { id, lawFirmRelations }) {
    Vue.set(state, 'allRfps', state.allRfps.map(rfp => {
      if (rfp.id === id) {
        rfp.lawFirmRelations = lawFirmRelations
      }

      return rfp
    }))
  }

}
