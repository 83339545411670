import Vue from 'vue'
import moment from 'moment'

export default {

  addNewRfpToList (state, newRfp) {
    state.allRfps.push(newRfp)
  },

  updateCurrentRfp (state, { field, value }) {
    if (state.currentRfp.status === 'DRAFT') {
      Vue.set(state.currentRfp, field, value)
    } else {
      Vue.set(state.rfpDraft, field, value)
    }
  },

  setRfpHistory (state, history) {
    Vue.set(state, 'history', history)
  },

  setRfpDraft (state, draft) {
    Vue.set(state, 'rfpDraft', draft)
  },

  updateCurrentRfpTerms (state, { field, value }) {
    if (state.currentRfp.status === 'DRAFT') {
      Vue.set(state.currentRfp.terms, field, value)
    } else {
      Vue.set(state.rfpDraft.terms, field, value)
    }
  },

  updateCurrentRfpFreeScope (state, { field, value }) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    Vue.set(currentRfp.freeScope, field, value)
  },

  updateCurrentRfpLawFirmIds (state, lawFirmIds) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    const existingLawFirmRelationsMap = currentRfp.lawFirmRelations.reduce((map, relation) => {
      map.set(relation.lawFirm.id, relation)
      return map
    }, new Map())

    if (lawFirmIds) {
      Vue.set(currentRfp, 'lawFirmRelations', lawFirmIds.map(id => {
        if (existingLawFirmRelationsMap.has(id)) {
          return existingLawFirmRelationsMap.get(id)
        } else {
          return {
            rfp: {
              id: currentRfp.id
            },
            lawFirm: {
              id: id
            },
            requestedLawyers: []
          }
        }
      }))
    } else {
      Vue.set(currentRfp, 'lawFirmRelations', [])
    }
  },

  updateCurrentRfpDateTime (state, { field, value }) {
    if (value && moment(value).isValid()) {
      const newValue = moment(value)
      const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
      Vue.set(currentRfp, field, newValue.toISOString())
    }
  },

  updateCurrentRfpDate (state, { field, value }) {
    if (value && moment(value, 'DD.MM.YYYY').isValid()) {
      const newDate = moment(value, 'DD.MM.YYYY')
      const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
      const oldValue = currentRfp[field] ? moment(currentRfp[field]) : moment().add(10, 'm')
      oldValue.milliseconds(0)
      oldValue.seconds(0)

      oldValue.date(newDate.date())
      oldValue.month(newDate.month())
      oldValue.year(newDate.year())

      Vue.set(currentRfp, field, oldValue.toISOString())
    }
  },

  updateCurrentRfpTime (state, { field, value }) {
    const newTime = moment(value, 'HH:mm')
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    const oldTime = currentRfp[field] ? moment(currentRfp[field]) : moment().add(1, 'd')

    oldTime.milliseconds(0)
    oldTime.seconds(0)
    oldTime.minutes(newTime.minutes())
    oldTime.hours(newTime.hours())

    Vue.set(currentRfp, field, oldTime.toISOString())
  },

  addRfpAttachment (state, attachment) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    currentRfp.attachments.push(attachment)
  },

  removeRfpAttachment (state, attachment) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    const index = currentRfp.attachments.findIndex(file => file.id === attachment.id)

    if (index > -1) {
      Vue.delete(currentRfp.attachments, index)
    }
  },

  updateLawFirmRelation (state, lawFirmRelation) {
    // const rfpsIndex = state.allRfps.findIndex(rfp => rfp.id === lawFirmRelation.rfp.id)
    const updatedLawFirmRelations = (oldLawFirmRelations) => {
      return oldLawFirmRelations.map(oldLawFirmRelation => {
        const newRfpId = lawFirmRelation.rfp ? lawFirmRelation.rfp.id : lawFirmRelation.historyRfp.id
        const oldRfpId = oldLawFirmRelation.rfp ? oldLawFirmRelation.rfp.id : oldLawFirmRelation.historyRfp.id
        if (lawFirmRelation.lawFirmId === oldLawFirmRelation.lawFirmId && newRfpId === oldRfpId) {
          return lawFirmRelation
        } else {
          return oldLawFirmRelation
        }
      })
    }

    const updatedRfps = state.allRfps.map(rfp => {
      rfp.lawFirmRelations = updatedLawFirmRelations(rfp.lawFirmRelations)
      return rfp
    })

    Vue.set(state, 'allRfps', updatedRfps)
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    if (currentRfp) {
      Vue.set(currentRfp, 'lawFirmRelations', updatedLawFirmRelations(currentRfp.lawFirmRelations))
    }
  },

  setCurrentSubjectUser (state, subject) {
    Vue.set(state, 'currentSubject', subject)
  },

  setRequestLawyerSearchResult (state, searchResult) {
    Vue.set(state, 'requestLawyerSearchResult', searchResult)
  },

  addRequestedLawyer (state, { lawFirmId, lawyer }) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    const { lawFirmRelations } = currentRfp
    const index = lawFirmRelations.findIndex(lawFirmRelation => lawFirmRelation.lawFirm.id === lawFirmId)
    const lawFirmRelation = lawFirmRelations[index]

    if (!lawFirmRelation.requestedLawyers.some(existingLawyer => existingLawyer.id === lawyer.id)) {
      lawFirmRelation.requestedLawyers.push(lawyer)
    }

    Vue.set(currentRfp.lawFirmRelations, index, lawFirmRelation)
  },

  removeRequestedLawyer (state, { lawFirmId, lawyerId }) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    const { lawFirmRelations } = currentRfp
    const index = lawFirmRelations.findIndex(lawFirmRelation => lawFirmRelation.lawFirm.id === lawFirmId)
    const lawFirmRelation = lawFirmRelations[index]

    const lawyerIndex = lawFirmRelation.requestedLawyers.findIndex(lawyer => lawyer.id === lawyerId)

    if (lawyerIndex > -1) {
      Vue.delete(lawFirmRelation.requestedLawyers, lawyerIndex)
      Vue.set(currentRfp.lawFirmRelations, index, lawFirmRelation)
    }
  },

  deleteDocument (state, fileId) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    const newAttachments = currentRfp.attachments.filter(attachment => attachment.id !== fileId)
    Vue.set(currentRfp, 'attachments', newAttachments)
  }

}
