// import { dateTimeMaxWidthPixels } from '../../../layoutConstants'

const typeWidth = '145px'
const clientWidth = '145px'
const titleWidth = '145px'
const deadlineWidth = '145px'
const bidTypeWidth = '145px'

export const lawFirmNewHeaders = [
  {
    text: 'dashboard.rfpInformation.tableHeaders.type',
    align: 'left',
    sortable: true,
    value: 'mandateType',
    width: typeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.client',
    align: 'left',
    sortable: true,
    value: 'clientName',
    width: clientWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.matterName',
    align: 'left',
    sortable: 'true',
    value: 'name',
    width: titleWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.bidType',
    align: 'left',
    sortable: false,
    value: 'auctionType',
    width: bidTypeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.submissionDate',
    sortable: true,
    value: 'submission',
    width: bidTypeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.status',
    align: 'left',
    sortable: false,
    width: bidTypeWidth,
    value: 'status'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.responseDeadline',
    align: 'left',
    sortable: false,
    value: 'responseDeadline',
    width: bidTypeWidth
  },
  {
    text: '',
    sortable: false,
    value: 'action',
    width: '120px'
  }
]

export const lawFirmActiveHeaders = [
  {
    text: 'dashboard.rfpInformation.tableHeaders.type',
    align: 'left',
    sortable: true,
    value: 'mandateType',
    width: typeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.client',
    align: 'left',
    sortable: true,
    value: 'clientName',
    width: clientWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.matterName',
    align: 'left',
    sortable: 'true',
    value: 'name',
    width: titleWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.bidType',
    align: 'left',
    sortable: false,
    value: 'auctionType',
    width: bidTypeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.submissionDate',
    align: 'left',
    sortable: true,
    width: bidTypeWidth,
    value: 'submission'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.status',
    align: 'left',
    sortable: true,
    width: bidTypeWidth,
    value: 'status'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.nextDeadline',
    align: 'left',
    sortable: true,
    value: 'auctionEnd',
    width: deadlineWidth
  },
  {
    text: '',
    sortable: false,
    value: 'action',
    width: '120px'
  }
]

export const lawFirmCompleteHeaders = [
  {
    text: 'dashboard.rfpInformation.tableHeaders.type',
    align: 'left',
    sortable: true,
    value: 'mandateType',
    width: typeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.client',
    align: 'left',
    sortable: true,
    value: 'clientName',
    width: clientWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.matterName',
    align: 'left',
    sortable: 'true',
    value: 'name',
    width: titleWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.bidType',
    align: 'left',
    sortable: false,
    value: 'auctionType',
    width: bidTypeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.completion',
    align: 'left',
    sortable: true,
    value: 'auctionEnd',
    width: deadlineWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.status',
    align: 'left',
    sortable: true,
    value: 'status',
    width: deadlineWidth
  },
  {
    text: '',
    sortable: false,
    value: 'action',
    width: deadlineWidth
  },
  {
    text: '',
    sortable: false,
    value: 'action',
    width: '120px'
  }
]
