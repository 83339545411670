export default {

  proposalIsSubmitted (state, getters) {
    if (getters.userIsLawFirm && getters.currentRfpLawFirmRelation) {
      return getters.currentRfpLawFirmRelation.proposalSubmitted
    }

    return false
  },

  proposalIsEditable (state, getters) {
    return state.currentRfp &&
      getters.auctionProgress(state.currentRfp) < getters.RfpProgress.ACTIVE_FIRST_BIDS_CLOSED &&
      getters.currentRfpLawFirmRelation &&
      getters.currentRfpLawFirmRelation.status &&
      !getters.currentRfpLawFirmRelation.status.startsWith('COMPLETED')
  },

  getTeamMemberNameFromProposalTeam (state, getters) {
    return (teamMemberId, lawFirmRelation) => {
      const proposalTeam = (lawFirmRelation || getters.currentRfpLawFirmRelation).proposal.find(category => category.name === 'TEAM')

      if (proposalTeam) {
        const teamMember = proposalTeam.subItems.find(subItem => subItem.linkedUser.id === teamMemberId)

        return teamMember ? teamMember.linkedUser.fullName : ''
      }

      return ''
    }
  },

  formattedUrl: () => (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url
    }

    return 'http://' + url
  },

  interviewSelected: () => (rfp) => {
    return rfp.terms.qualityScoreCriteria.indexOf('INTERVIEW') > -1
  },

  relevantQualityScoreCriteriaSelected: () => (rfp) => {
    return rfp.terms.qualityScoreCriteria.filter(x => x !== 'INTERVIEW' && x !== 'QA').length > 0
  },

  getCustomQualityScoreConfig: () => (rfp, customName) => {
    return rfp.terms.customQualityScoreCriteria[customName.toLowerCase()]
  },

  currentRfpCustomQualityScoreTitle: (state, getters) => (customName) => {
    return state.currentRfp ? getters.getCustomQualityScoreConfig(state.currentRfp, customName).title : ''
  }

}
