import Vue from 'vue'

export default {

  setCurrentProposal (state, proposal) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]

    const oldProposalMap = lawFirmRelation.proposal.reduce((result, category) => {
      result[category.name] = category
      return result
    }, {})

    const newProposalMap = proposal.reduce((result, category) => {
      result[category.name] = category
      return result
    }, {})

    const result = {
      ...oldProposalMap,
      ...newProposalMap
    }

    lawFirmRelation.proposal = Object.values(result)

    Vue.set(state.currentRfp, 'lawFirmRelations', [lawFirmRelation])
  },

  removePitchDocument (state, attachment) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]
    const pitchDocument = lawFirmRelation.proposal.find(category => category.draft && category.name === 'PITCH_DOCUMENT') && lawFirmRelation.proposal.find(category => category.name === 'PITCH_DOCUMENT')
    const index = pitchDocument.attachments.findIndex(_attachment => _attachment.id === attachment.id)
    if (index > -1) {
      pitchDocument.attachments.splice(index, 1)
    }
  },

  addOrUpdateCredential (state, credential) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]
    let credentialProposal = lawFirmRelation.proposal.find(category => category.name === 'CREDENTIALS' && category.draft) || lawFirmRelation.proposal.find(category => category.name === 'CREDENTIALS')

    if (credentialProposal) {
      const oldIndex = credentialProposal.subItems.findIndex(_credential => _credential.id === credential.id)
      if (oldIndex > -1) {
        Vue.set(credentialProposal.subItems, oldIndex, credential)
        Vue.set(state.draftProposal.credentials.subItems, oldIndex, credential)
      } else {
        credentialProposal.subItems.push(credential)
        state.draftProposal.credentials.subItems.push(credential)
        // Vue.set(state.currentRfp, 'lawFirmRelations', [lawFirmRelation])
      }
    } else {
      credentialProposal = {
        name: 'CREDENTIALS',
        subItems: [credential],
        draft: true
      }
      lawFirmRelation.proposal.push(credentialProposal)
      Vue.set(state.currentRfp, 'lawFirmRelations', [lawFirmRelation])
      Vue.set(state.draftProposal, 'credentials', JSON.parse(JSON.stringify(credentialProposal)))
    }
  },

  updateTeamMember (state, teamMember) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]
    const teamProposal = lawFirmRelation.proposal.find(category => category.draft && category.name === 'TEAM') || lawFirmRelation.proposal.find(category => category.name === 'TEAM')

    if (teamProposal) {
      const oldIndex = teamProposal.subItems.findIndex(_teamMember => _teamMember.id === teamMember.id)
      if (oldIndex > -1) {
        Vue.set(teamProposal.subItems, oldIndex, teamMember)
      }
    }
  },

  setLawFirmRelations (state, lawFirmRelations) {
    lawFirmRelations.forEach(lawFirmRelation => {
      lawFirmRelation.showProposal = false
    })
    Vue.set(state.currentRfp, 'lawFirmRelations', lawFirmRelations)
  },

  removeProposalTeamMember (state, teamMember) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]
    const teamCategory = lawFirmRelation.proposal.find(category => category.draft && category.name === 'TEAM') && lawFirmRelation.proposal.find(category => category.name === 'TEAM')
    console.log('TeamCategory', teamCategory)
    const index = teamCategory.subItems.findIndex(subItem => subItem.id === teamMember.id)

    if (index > -1) {
      teamCategory.subItems.splice(index, 1)
      Vue.set(teamCategory, 'subItems', teamCategory.subItems)
    }
  },

  addPitchDocumentAttachment (state, { attachment }) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]
    let pitchDocumentCategory = lawFirmRelation.proposal.find(category => category.draft && category.name === 'PITCH_DOCUMENT') || lawFirmRelation.proposal.find(category => category.name === 'PITCH_DOCUMENT')

    if (pitchDocumentCategory) {
      pitchDocumentCategory.attachments.push(attachment)
    } else {
      pitchDocumentCategory = {
        name: 'PITCH_DOCUMENT',
        attachments: [attachment]
      }
      lawFirmRelation.proposal.push(pitchDocumentCategory)
      Vue.set(state.currentRfp, 'lawFirmRelations', [lawFirmRelation])
    }
  },

  submitProposal (state, lawFirmRelation) {
    Vue.set(state.currentRfp, 'lawFirmRelations', [lawFirmRelation])
  },

  resubmitProposal (state) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]
    Vue.set(lawFirmRelation, 'requiresResubmission', false)
  },

  updateLawFirmRelationRating (state, newRelation) {
    const relation = state.currentRfp.lawFirmRelations.find(relation => relation.lawFirm.id === newRelation.lawFirm.id)

    relation.proposalRating = newRelation.proposalRating
    relation.overallStarRating = newRelation.overallStarRating
    relation.proposal.forEach(proposalCategory => {
      const matchingCategory = newRelation.proposal.find(category => category.id === proposalCategory.id)
      proposalCategory.rating = matchingCategory.rating

      proposalCategory.subItems.forEach(subItem => {
        const matchingSubItem = matchingCategory.subItems.find(_subItem => _subItem.id === subItem.id)
        subItem.rating = matchingSubItem.rating
      })
    })
  },

  deleteCredential (state, credential) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]
    const credentials = lawFirmRelation.proposal.find(category => category.draft && category.name === 'CREDENTIALS') || lawFirmRelation.proposal.find(category => category.name === 'CREDENTIALS')
    const index = credentials.subItems.findIndex(_credential => _credential.id === credential.id)

    if (index > -1) {
      credentials.subItems.splice(index, 1)
    }

    Vue.set(state.currentRfp, 'lawFirmRelations', [lawFirmRelation])
    if (credentials.draft) {
      Vue.set(state.draftProposal, 'credentials', JSON.parse(JSON.stringify(credentials)))
    }
  },

  setViewRatingCard (state, relationId) {
    const allRelation = state.currentRfp.lawFirmRelations
    if (allRelation[allRelation.findIndex(relation => relation.lawFirm.id === relationId)].showProposal) {
      allRelation[allRelation.findIndex(relation => relation.lawFirm.id === relationId)].showProposal = !allRelation[allRelation.findIndex(relation => relation.lawFirm.id === relationId)].showProposal
    } else {
      allRelation[allRelation.findIndex(relation => relation.lawFirm.id === relationId)].showProposal = true
    }
    Vue.set(state.currentRfp, 'lawFirmRelations', allRelation)
  },

  setDraftProposalItem (state, { key, value }) {
    Vue.set(state.draftProposal, key, value ? JSON.parse(JSON.stringify(value)) : value)
  },

  addOrRemoveDraftTeamMembers (state, teamMembers) {
    const indexToRemove = []
    for (let i = 0; i < state.draftProposal.teamMembers.length; i++) {
      if (!teamMembers.find(t => t.id === state.draftProposal.teamMembers[i].id)) {
        indexToRemove.unshift(i)
      }
    }
    for (let index of indexToRemove) {
      state.draftProposal.teamMembers.splice(index, 0)
    }

    for (const teamMember of teamMembers) {
      if (!state.draftProposal.teamMembers.find(t => t.id === teamMember.id)) {
        state.draftProposal.teamMembers.push(teamMember)
      }
    }
  },

  setProposalHasUnsavedChanges (state, value) {
    state.proposalHasUnsavedChanges = value
  },

  setDraftProposal (state, value) {
    Vue.set(state, 'draftProposal', value)
  }

}
