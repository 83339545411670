<template>
  <v-dialog
    :value="visible"
    @input="value => { $emit('visibilityChange', value); resetSubject() }"
    width="700"
    persistent
    no-click-animation
  >
    <v-card v-click-outside-dialog="closeEvent">
      <v-card-title
        class="headline"
        primary-title
      >
        {{ `scopeGeneratorAdminView.optionListDialog.title.${title}` | t }}

      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <span>{{ 'scopeGeneratorAdminView.optionListDialog.key' | t }}</span>
        <v-text-field
          label=""
          multiple
          :disabled="title === 'update'"
          v-model="optionList.key"
          solo
          flat></v-text-field>

        <div class="translation-headers">
          <span>{{ 'scopeGeneratorAdminView.optionListDialog.options' | t }}</span>
          <v-btn flat @click="hideInactiveOptions = !hideInactiveOptions">{{ hideInactiveOptions ? 'Show inactive options' : 'Hide inactive options' }}</v-btn>
          <v-btn flat @click="addOption"><v-icon>ic-plus-circle</v-icon>&nbsp;{{ 'scopeGeneratorAdminView.optionListDialog.addOption' | t }}</v-btn>
        </div>
        <div v-for="(option, index) in filteredOptions" class="option" :key="option.key || option.tempId">
          <div class="order-buttons">
            <v-btn :disabled="index === 0" small icon @click="move(index, 1)"><v-icon>keyboard_arrow_up</v-icon></v-btn>
            <v-btn :disabled="index >= filteredOptions.length - 1" small icon @click="move(index, -1)"><v-icon>keyboard_arrow_down</v-icon></v-btn>
          </div>
          <div>
            <div v-for="(translation) in ['en', 'de']" :key="translation">
              <span>{{ 'translations.' + translation  | t }}</span>
              <v-text-field
                label=""
                solo flat
                v-model="option.labelTranslations[translation]"
              ></v-text-field>
            </div>
          </div>

          <div>
            <span>{{ 'scopeGeneratorAdminView.optionListDialog.isActive' | t }}</span>
            <v-checkbox v-model="option.active" style="margin: 5px 12px;"/>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="cancel">{{ 'buttons.cancel' | t }}</v-btn>
        <v-btn flat class="main" @click="updateOptionListLocal">
          {{ 'scopeGeneratorAdminView.optionListDialog.save' | t }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import shortid from 'shortid'

export default {
  name: 'UpdateOptionListDialog',
  model: {
    prop: 'visible',
    event: 'visibilityChange'
  },
  props: {
    // Wether the dialog should be visible
    // Used with v-model (see model definition above)
    visible: {
      type: Boolean,
      required: false,
      default: false
    },

    optionListUpdate: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      title: 'add',
      optionList: {
        key: '',
        options: []
      },
      hideInactiveOptions: false
    }
  },
  watch: {
    optionListUpdate () {
      if (this.optionListUpdate) {
        this.title = 'update'
        this.optionList = JSON.parse(JSON.stringify(this.optionListUpdate))
        this.optionList.options.sort((a, b) => a.orderId - b.orderId)
      } else {
        this.resetSubject()
      }
    }
  },
  computed: {
    ...mapGetters(['mandateTypeOptions', 'mandateSubTypeOptions']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    }),

    filteredOptions () {
      if (this.hideInactiveOptions) {
        return this.optionList.options.filter(option => option.active)
      }

      return this.optionList.options
    }
  },
  methods: {
    ...mapActions(['validate', 'updateOptionList']),
    ...mapMutations(['removeMissingMandatoryField', 'resetMandatoryFields']),

    move (index, direction = 1) {
      const selectedIndex = this.optionList.options.indexOf(this.filteredOptions[index])
      const targetIndex = this.optionList.options.indexOf(this.filteredOptions[index - direction])

      const target = this.optionList.options[targetIndex]
      const selected = this.optionList.options[selectedIndex]

      this.$set(this.optionList.options, targetIndex, selected)
      this.$set(this.optionList.options, selectedIndex, target)
    },

    async updateOptionListLocal () {
      const optionList = { ...this.optionList }
      delete optionList.isReserved
      delete optionList.translatedLabel

      optionList.options.forEach(option => {
        delete option.translatedLabel
        delete option.tempId
      })

      console.log('Saving optionList:', optionList)
      this.updateOptionList({ optionList })

      this.$emit('visibilityChange', false)
      this.resetSubject()
    },

    cancel () {
      this.resetSubject()
      this.resetMandatoryFields()
      this.$emit('visibilityChange', false)
    },

    resetSubject () {
      this.title = 'add'
      this.optionList = {
        key: '',
        options: []
      }
    },

    addOption () {
      this.optionList.options.push({
        labelTranslations: { en: '' },
        active: true,
        tempId: shortid.generate()
      })
    },

    closeEvent (event) {
      if (this.visible) {
        this.cancel()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .v-card
    border-radius 10px
    .v-card__actions
      padding 16px 45px 24px 45px

    .v-card__title
      padding 24px 45px 16px 45px

  .v-card__text
    padding 16px 45px
    span
      color rgb(158, 158, 158)
      padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input--checkbox .v-input__slot
    border none !important

  >>> .v-text-field__details
    display none

  .translation-headers
    display grid
    grid-template-columns 1fr 150px 150px
    height 36px

    span
      line-height 36px

    button
      margin-top 0

    .v-icon
      font-size 15px
      &:before
        color $dark-gray !important

  div.option
    border-top 1px solid $icon-gray
    display grid
    grid-template-columns 50px 1fr 1fr

  >>> .v-icon
    color $icon-gray !important
    &:before
      color $icon-gray !important

  .order-buttons
    display grid
    grid-template-rows 30px 30px

    >>> .v-icon
      color $dark-gray !important
      &:before
        color $dark-gray !important
</style>
