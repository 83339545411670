<template>
  <v-dialog
    :value="visible"
    @input="value => { resetNewRfp(); $emit('visibilityChange', value) }"
    width="700"
    class="scroll-mod"
    persistent
    no-click-animation >
    <v-card v-click-outside-dialog="closeEvent">
      <v-card-title
        class="title"
        primary-title
      >
        <h2>{{ 'dashboard.createNewRfp.projectTitle' | t }}</h2>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text style="padding-right: 90px; padding-left: 90px; padding-bottom: 0;">
        <template v-if="allRfps.length">
          <span>{{ 'dashboard.createNewRfp.template' | t }}</span>
          <v-select
            v-model="templateRfp"
            label=""
            :items="orderedRfps"
            @input="rfp => selectTemplateRfp(rfp)"
            return-object
            :item-text="rfp => `${rfp.name}`"
            :placeholder="'dashboard.createNewRfp.placeHolders.template' | t "
            solo
            clearable
            flat
          ></v-select>
        </template>
        <p v-else v-html="$t('dashboard.createNewRfp.help')"></p>

        <span class="mandatory">{{ 'dashboard.createNewRfp.projectName' | t }}</span>
        <v-text-field
          label=""
          :class="{missing: missingMandatoryFields.includes('name')}"
          v-model="newRfp.name"
          @input="removeMissingMandatoryField('name')"
          :placeholder="'dashboard.createNewRfp.placeHolders.namePH' | t "
          solo
          flat
        ></v-text-field>

        <span class="mandatory">{{ 'dashboard.createNewRfp.projectDescription' | t }}</span>
        <v-textarea
          label=""
          :class="{missing: missingMandatoryFields.includes('description')}"
          v-model="newRfp.description"
          @input="removeMissingMandatoryField('description')"
          :placeholder="'dashboard.createNewRfp.placeHolders.descriptionPH' | t "
          solo
          flat
        ></v-textarea>

        <span class="mandatory">{{ 'dashboard.createNewRfp.projectMatter' | t }}</span>
        <v-select
          label=""
          :class="{missing: missingMandatoryFields.includes('mandateType')}"
          v-model="newRfp.mandateType"
          @input="removeMissingMandatoryField('mandateType'); newRfp.mandateSubType = null"
          :items="mandateTypeOptions"
          :placeholder="'dashboard.createNewRfp.placeHolders.matterPH' | t "
          solo
          flat
        >
        </v-select>

        <span v-if="mandateSubTypeOptionsFiltered.length" class="mandatory">{{ 'dashboard.createNewRfp.projectSubMatter' | t }}</span>
        <v-select
          v-if="mandateSubTypeOptionsFiltered.length"
          label=""
          :class="{missing: missingMandatoryFields.includes('mandateSubType')}"
          v-model="newRfp.mandateSubType"
          @input="removeMissingMandatoryField('mandateSubType')"
          :items="mandateSubTypeOptionsFiltered"
          :placeholder="'dashboard.createNewRfp.placeHolders.subMatterPH' | t "
          solo
          flat
        >
        </v-select>

        <span class="mandatory" style="display: block; margin-top: 10px">{{ 'dashboard.createNewRfp.keySubject' | t }}</span>
        <v-select
          label=""
          :class="{missing: missingMandatoryFields.includes('subject_' + newRfp.subjects[0].id)}"
          v-model="newRfp.subjects[0].key"
          @input="removeMissingMandatoryField('subject_' + newRfp.subjects[0].id)"
          :items="applicableSubjects(newRfp.subjects[0])"
          item-text="translatedLabel"
          item-value="key"
          :placeholder="'dashboard.createNewRfp.placeHolders.keySubject' | t "
          solo
          flat
        >
        </v-select>

        <div
          class="other-parties-header"
          style="height: 45px"
          v-if="newRfp.subjects.length > 1 || (newRfp.subjects.length === 1 && newRfp.subjects[0].key)">
          <span v-if="newRfp.subjects.length > 1">{{ 'dashboard.createNewRfp.otherSubjects' | t }}</span>
          <v-btn
            flat @click="addOtherSubject"
            :style="newRfp.subjects.length > 1 ? '' : 'width: 134px'"
            :disabled="newRfp.subjects.length >= 5"><v-icon>ic-plus-circle</v-icon>&nbsp;{{ 'dashboard.createNewRfp.addOtherSubject' | t }}</v-btn>
        </div>

        <div
          v-for="(subject, index) in newRfp.subjects.slice(1)"
          :key="subject.id"
          style="display: grid; grid-template-columns: 1fr 60px; grid-column-gap: 10px; height: 70px">

          <div>
            <v-select
              label=""
              v-model="subject.key"
              @input="removeMissingMandatoryField('subject_' + subject.id)"
              :class="{missing: missingMandatoryFields.includes('subject_' + subject.id)}"
              :items="applicableSubjects(subject)"
              item-text="translatedLabel"
              item-value="key"
              :placeholder="'dashboard.createNewRfp.placeHolders.otherSubject' | t "
              solo
              flat
            >
            </v-select>
          </div>

          <div>
            <v-btn
              style="margin-top: -5px;"
              @click="removeSubject(subject.id, index)" flat fab icon>
              <v-icon>clear</v-icon>
            </v-btn>
          </div>
        </div>

        <span class="mandatory">{{ 'dashboard.createNewRfp.projectClientName' | t }}</span>
        <v-text-field
          label=""
          :class="{missing: missingMandatoryFields.includes('clientName')}"
          v-model="newRfp.clientName"
          @input="removeMissingMandatoryField('clientName')"
          :placeholder="'dashboard.createNewRfp.placeHolders.clientNamePH' | t "
          solo
          flat
        ></v-text-field>

        <div class="other-parties-header">
          <span v-if="newRfp.coi1.otherParties.length">{{ 'dashboard.createNewRfp.projectOtherParties' | t }}</span>
          <v-btn
            flat @click="addParty"
            :style="newRfp.coi1.otherParties.length ? '' : 'width: 125px'"><v-icon>ic-user-plus</v-icon>&nbsp;{{ 'dashboard.createNewRfp.addOtherParty' | t }}</v-btn>
        </div>
        <div v-for="(party, index) in newRfp.coi1.otherParties" class="other-parties" :key="party.id">

          <div>
            <span class="mandatory">{{ 'dashboard.createNewRfp.otherPartyName' | t }}</span>
            <v-text-field
              label=""
              :class="{missing: missingMandatoryFields.includes('coi1.otherParties.' + party.id + '.name')}"
              v-model="party.name"
              @input="removeMissingMandatoryField('coi1.otherParties.' + party.id + '.name')"
              :placeholder="'dashboard.createNewRfp.placeHolders.otherPartyName' | t "
              solo
              flat
            ></v-text-field>
          </div>

          <div>
            <span class="mandatory">{{ 'dashboard.createNewRfp.otherPartyRole' | t }}</span>
            <v-combobox
              :items="otherPartyRolesOptions"
              label=""
              solo flat
              :item-text="itemText"
              :class="{missing: missingMandatoryFields.includes('coi1.otherParties.' + party.id + '.role')}"
              :value="otherPartyRolesOptions.find(option => option.value === party.role) || party.role"
              @input="value => { comboboxInput(value, party); removeMissingMandatoryField('coi1.otherParties.' + party.id + '.role'); }"
              :placeholder="'dashboard.createNewRfp.placeHolders.otherPartyRole' | t "
            ></v-combobox>
          </div>

          <div>
            <v-btn
              style="margin-top: 15px;"
              @click="removeParty(party, index)" flat fab icon>
              <v-icon>clear</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click="cancel" style="margin-bottom: 30px">{{ 'buttons.cancel' | t }}</v-btn>
          <AwaitButton
            class="main elevation-3"
            style="margin-right: 90px; margin-bottom: 30px; width: 120px; border-radius: 5px"
            :callback="createRfp">
            {{ 'buttons.createRPF' | t }}
          </AwaitButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import shortid from 'shortid'
import AwaitButton from '../../generalComponents/AwaitButton'

export default {
  name: 'NewRfpDialog',
  components: { AwaitButton },
  model: {
    prop: 'visible',
    event: 'visibilityChange'
  },
  props: {
    // Wether the dialog should be visible
    // Used with v-model (see model definition above)
    visible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      templateRfp: null,
      newRfp: {
        name: '',
        description: '',
        mandateType: null,
        mandateSubType: null,
        clientName: '',
        coi1: {
          otherParties: []
        },
        subjects: [{ key: '', id: shortid.generate() }]
      }
    }
  },
  computed: {
    ...mapGetters(['mandateTypeOptions', 'mandateSubTypeOptions', 'otherPartyRolesOptions']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      subjects: state => state.admin.scopeGenerator.subjects,
      allRfps: state => state.allRfps
    }),

    orderedRfps () {
      const allRfpsCopy = JSON.parse(JSON.stringify(this.allRfps))
      return allRfpsCopy.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        return 0
      })
    },

    mandateSubTypeOptionsFiltered () {
      if (!this.mandateSubTypeOptions) {
        return []
      }
      return this.mandateSubTypeOptions.filter(subType => this.newRfp.mandateType && subType.value.startsWith(this.newRfp.mandateType))
    }
  },
  methods: {
    ...mapActions(['createNewRfp', 'validate']),
    ...mapMutations(['removeMissingMandatoryField', 'resetMandatoryFields', 'addMissingMandatoryField']),

    selectTemplateRfp (rfp) {
      if (!rfp) {
        return
      }
      const input = { ...rfp }
      input.name = this.newRfp.name

      if (!rfp.coi1) {
        input.coi1 = this.newRfp.coi1
      }

      if (!rfp.subjects || !rfp.subjects.length) {
        input.subjects = this.newRfp.subjects
      }

      this.newRfp = input
    },

    applicableSubjects (currentValue) {
      return this.subjects
        .filter(subject => !this.newRfp.subjects.some(_subject => _subject.key === subject.key && subject.key !== currentValue.key))
        .filter(subject =>
          subject.mandateTypes.includes(this.newRfp.mandateType) &&
          (subject.mandateSubTypes.includes(this.newRfp.mandateSubType) || (!this.newRfp.mandateSubType)))
    },

    async createRfp () {
      const requiredFields = ['name', 'mandateType', 'description', 'clientName']
      if (this.mandateSubTypeOptionsFiltered.length) {
        requiredFields.push('mandateSubType')
      }

      this.validate({ requiredFields, object: this.newRfp })

      this.newRfp.subjects.forEach(subject => {
        if (!subject.key) {
          this.addMissingMandatoryField('subject_' + subject.id)
        }
      })

      // other parties can not be validated with current methods
      let otherPartiesValid = true

      this.newRfp.coi1.otherParties.forEach((party) => {
        if (!party.name) {
          otherPartiesValid = false
          this.addMissingMandatoryField(('coi1.otherParties.' + party.id + '.name'))
        }

        if (!party.role) {
          otherPartiesValid = false
          this.addMissingMandatoryField(('coi1.otherParties.' + party.id + '.role'))
        }
      })

      if (!otherPartiesValid) {
        this.addMissingMandatoryField('coi1.otherParties')
      }

      if (this.missingMandatoryFields.length > 0) {
        return
      }

      const input = JSON.parse(JSON.stringify(this.newRfp))
      input.subjects = input.subjects.map(({ key }) => ({ key }))

      const newRfpId = await this.createNewRfp({
        newRfp: input,
        templateRfpId: this.templateRfp ? this.templateRfp.id : null
      })
      this.$router.push({
        name: 'generalInfoCorporate',
        params: {
          id: newRfpId
        }
      })
    },

    cancel () {
      this.resetNewRfp()
      this.$emit('visibilityChange', false)
    },

    resetNewRfp () {
      this.templateRfp = null
      this.resetMandatoryFields()
      this.newRfp = {
        name: '',
        description: '',
        mandateType: null,
        coi1: {
          otherParties: []
        },
        subjects: [{ key: '', id: shortid.generate() }]
      }
    },

    addParty () {
      this.newRfp.coi1.otherParties.push({
        id: shortid.generate(),
        name: '',
        role: ''
      })
    },

    removeParty (party, index) {
      this.removeMissingMandatoryField('coi1.otherParties.' + party.id + '.name')
      this.removeMissingMandatoryField('coi1.otherParties.' + party.id + '.role')
      this.newRfp.coi1.otherParties.splice(index, 1)
    },

    removeSubject (id, index) {
      this.removeMissingMandatoryField('subject_' + id)
      this.newRfp.subjects.splice(index + 1, 1)
    },

    itemText (option) {
      const key = `dropDownOptions.OtherPartyRoles.${option.value}`
      if (this._i18n.i18next.exists(key)) {
        return this.$t(key)
      } else {
        return option.value
      }
    },

    comboboxInput (value, party) {
      if (typeof value === 'string') {
        party.role = value
      } else {
        party.role = value.value
      }
    },

    addOtherSubject () {
      if (this.newRfp.subjects.length < 5) {
        this.newRfp.subjects.push({ key: '', id: shortid.generate() })
      }
    },

    closeEvent: function (event) {
      if (this.visible) {
        this.$emit('visibilityChange', false)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .button-grid
    display grid

  .v-card
    border-radius 10px 0 0 10px

  .v-card__text
    span
      color rgb(158, 158, 158)
      padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-dialog
    overflow auto
    border-radius 10px

  >>> .v-dialog::-webkit-scrollbar {
    border-radius 0 10px 10px 0
    background-color #ffffff
    width 10px
  }
  >>> .v-dialog::-webkit-scrollbar-thumb {
    background-color #aaa
    border-radius 5px
  }
  >>> .v-dialog::-webkit-scrollbar-button {
    display none
  }

  .other-parties
    display grid
    grid-template-columns 1fr 1fr 60px
    grid-column-gap 10px

    >>> .v-input__slot
      margin-bottom 10px !important

  .other-parties-header
    display grid
    grid-template-columns 1fr 150px
    height 36px

    span
      line-height 36px

    button
      margin-top 0
      width 150px

    .v-icon
      font-size 15px
      &:before
        color $dark-gray !important

  >>> .v-text-field__details
    display none

  >>> .v-input__slot
    margin-bottom 25px !important
</style>
