<template>
  <v-data-table
    must-sort
    :headers="headers"
    :items="lawFirmOptions"
    :hide-actions="true"
    :pagination.sync="pagination"
    :hide-headers="hideHeaders"
    class="styled-table"
    :no-data-text="'No law firms selected.'"
    v-model="selected">

    <template v-slot:items="props">
      <tr :active="props.selected">
        <td >
          <v-checkbox v-if="!hideIcons"
                      v-model="props.selected"
                      @change="value => {updateRfpLawFirmRelation(value)}"
                      hide-details
                      :disabled="disabled || disabledByHistory(props.item.id)"
                      on-icon="check_circle"
                      off-icon="panorama_fish_eye">
          </v-checkbox>
        </td>
        <td :style="hideIcons ? 'width: 276px;' : ''"><h3>{{ props.item.name }}</h3></td>
        <td style="position: relative;">
          <a
            v-if="!getRequestedLawyers(props.item).length && getLawFirmRelation(props.item.id)"
            @click="showSelectLawyerPanel = props.item.id"
            class="lawyer-selection"
            :disabled="disabled"
            :class="{ underline: !disabled }">{{ 'newRFP.lawfirmSelection.notSpecified' | t  }}</a>
          <a
            @click="showSelectLawyerPanel = props.item.id"
            class="lawyer-selection"
            :class="{ underline: !disabled }"
            :disabled="disabled"
            v-else>{{ getRequestedLawyers(props.item).map(lawyer => lawyer.invitePending ? lawyer.email : lawyer.fullName).join(', ') }}</a>

          <LawyerRequestingPopUp
            style="position: absolute; top: 40px"
            :law-firm-relation="getLawFirmRelation(props.item.id)"
            :value="showSelectLawyerPanel === props.item.id"
            v-if="showSelectLawyerPanel === props.item.id && !disabled"
            @input="showSelectLawyerPanel = null" />
        </td>
        <!-- td>{{ props.item.specialization }}</td> -->
        <!-- <td>{{ props.item.location }}</td> -->
        <td>{{ props.item.city }}{{(props.item.city && props.item.country) ? ', ': ' '}} {{ props.item.country }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import i18next from 'i18next'
import LawyerRequestingPopUp from './LawyerRequestingPopUp'

export default {
  name: 'LawFirmTable',
  components: { LawyerRequestingPopUp },
  props: {
    onlySelected: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hideHeaders: {
      type: Boolean,
      required: false,
      default: false
    },
    hideIcons: {
      type: Boolean,
      required: false,
      default: false
    },
    currentRfp: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      pagination: {
        sortBy: 'name',
        rowsPerPage: -1
      },
      selected: [],
      showSelectLawyerPanel: false,
      fullHeaders: [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'select',
          width: '200'
        },
        {
          text: i18next.t('newRFP.lawfirmSelection.lawfirmHeader1'),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: i18next.t('newRFP.lawfirmSelection.lawfirmHeader2'),
          align: 'left',
          sortable: 'true',
          value: 'lawyer'
        },
        // {
        //   text: i18next.t('newRFP.lawfirmSelection.lawfirmHeader3'),
        //   align: 'left',
        //   sortable: false,
        //   value: 'specialization',
        // },
        {
          text: i18next.t('newRFP.lawfirmSelection.lawfirmHeader4'),
          sortable: true,
          value: 'city'
        }
      ]
    }
  },
  created () {
    if (this.selectedLawFirmIds.length > 0) {
      this.selected = this.lawFirmOptions.filter((completeLawFirm) => {
        return this.selectedLawFirmIds.includes(completeLawFirm.id) ? completeLawFirm : ''
      })
    }
  },
  computed: {
    ...mapGetters(['currentRfpOrDraft']),
    ...mapState({
      lawFirms: state => state.lawFirms
    }),

    selectedLawFirmIds () {
      return this.currentRfp.lawFirmRelations.filter(relation => relation.lawFirm).map(relation => relation.lawFirm.id)
    },

    selectedLawFirms () {
      return this.currentRfp.lawFirmRelations.map(lawFirmRelation => {
        return this.lawFirms.find(lawfirm => lawfirm.id === lawFirmRelation.lawFirm.id)
      })
    },

    localSelectedLawFirmsIds () {
      return this.selected.map(lawfirm => lawfirm.id)
    },

    lawFirmOptions () {
      if (this.onlySelected) {
        return this.lawFirms.filter(lawFirm => this.selectedLawFirmIds.includes(lawFirm.id))
      }
      return this.lawFirms
    },

    headers () {
      return this.hideIcons ? this.fullHeaders.map(header => {
        if (header.value === 'select') {
          header.width = '70'
          // header.align = 'center'
        }
        return header
      }) : this.fullHeaders
    }
  },
  methods: {
    ...mapMutations(['updateCurrentRfpLawFirmIds', 'removeMissingMandatoryField']),
    disabledByHistory (lawFirmId) {
      const { lawFirmRelations } = this.currentRfp
      const relation = lawFirmRelations.find(relation => relation.lawFirm.id === lawFirmId)
      return relation ? relation.disabled : false
    },

    updateRfpLawFirmRelation (value) {
      this.updateCurrentRfpLawFirmIds(this.localSelectedLawFirmsIds)
      this.removeMissingMandatoryField('lawFirmRelations')
    },

    getLawFirmRelation (lawFirmId) {
      const { lawFirmRelations } = this.currentRfp
      return lawFirmRelations.find(relation => relation.lawFirm.id === lawFirmId)
    },

    getRequestedLawyers (lawFirm) {
      const lawFirmRelation = this.getLawFirmRelation(lawFirm.id)

      if (lawFirmRelation && lawFirmRelation.requestedLawyers && lawFirmRelation.requestedLawyers.length) {
        return lawFirmRelation.requestedLawyers
      }

      return []
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  >>> th .v-input--checkbox
    display none

  >>> td .v-input--checkbox .v-icon
    color $gray70 !important

  >>> table {
    border: 2px solid rgba(0,0,0,0)
    border-radius: 10px
  }

  .missing >>> table {
    border: 2px solid $red
    border-radius: 10px
  }

  .lawyer-selection {
    color $dark-gray
  }

  .underline {
    text-decoration underline
  }

  >>> .v-table__overflow {
    overflow-y visible !important
    overflow-x visible !important
  }

</style>
