import apollo from '../../apollo'
import gql from 'graphql-tag'

export default {

  async submitFeedback ({ state, getters, commit }, { discloseFeedback }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation closeRfp($rfpId: ID!, $discloseFeedback: Boolean) {
          closeRfp(rfpId: $rfpId, discloseFeedback: $discloseFeedback)
        }
        `,
      variables: {
        rfpId: state.currentRfp.id,
        discloseFeedback
      }
    })

    commit('setFeedbackSubmitted')
    commit('setDiscloseFeedback', { discloseFeedback })
  },

  async rateLawFirm ({ state, getters, commit }, { questionKey, value, category, extendedFields, userId, deleteRating }) {
    const result = await apollo(state).mutate({
      mutation: gql`
        mutation rateLawFirm($rfpId: ID!, $questionKey: String, $userId: ID, $rating: Float, $category: String!, $extendedFields: JSON, $deleteRating: Boolean) {
          rateLawFirm(rfpId: $rfpId, questionKey: $questionKey, userId: $userId, category: $category, rating: $rating, extendedFields: $extendedFields, deleteRating: $deleteRating) {
            overallFeedbackRating
            feedbackRatingByCategory
            feedbackRatings {
              questionKey
              rating
              category
              extendedFields
              linkedUser {
                id
                fullName
              }
            }
          }
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        questionKey: questionKey,
        userId: userId,
        rating: value,
        category: category,
        extendedFields: extendedFields,
        deleteRating
      }
    })
    const updatedFeedback = result.data.rateLawFirm
    // console.log(updatedFeedback)
    commit('updateFeedbackItems', updatedFeedback)
  }
}
