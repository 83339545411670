<template>
  <div>
    <v-alert
    :value="showSubscriptionError"
    class="lintum-alert"
    icon="error"
    style="grid-column-start: 1; grid-column-end: 3; margin-top: -1px; margin-bottom: 20px;">
      <span v-html="subscriptionError"></span>
    </v-alert>
    <v-alert
      :value="showSubscriptionLostError"
      class="lintum-alert"
      icon="error"
      style="grid-column-start: 1; grid-column-end: 3; margin-top: -1px; margin-bottom: 20px; margin-top: 20px">
      <span v-html="$t('error.subscriptionConnectionLost')"></span>
    </v-alert>

    <div class="main">
      <div class="notifContainer" :class="{'open-grid': showNotificationPanel, 'close-grid': !showNotificationPanel}">
        <AuctionSummary :duration="date" />
        <AuctionSummaryInfo />
        <AuctionRankInfo />
        <AuctionNotifications style="height: 100%"  @toggle="toggleNotification()" />
      </div>
      <div class="content">
        <AuctionBidPanel :duration="date" :relevantBidKey="relevantBidKey" ref="bidPanel"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import AuctionNotifications from './components/AuctionNotifications'
import AuctionSummary from './components/AuctionSummary'
import AuctionBidPanel from './components/AuctionBidPanel'
import AuctionSummaryInfo from './components/AuctionSummaryInfo'
import AuctionRankInfo from './components/AuctionRankInfo'

export default {
  name: 'EAuctionCorporate',
  components: { AuctionBidPanel, AuctionSummary, AuctionNotifications, AuctionSummaryInfo, AuctionRankInfo },
  props: {
    userDidNotEnterInitialBid: {
      type: Boolean,
      required: true
    },
    date: {
      required: true,
      type: String
    }
  },
  watch: {
    userDidNotEnterInitialBid () {
      if (this.userDidNotEnterInitialBid) {
        this.$emit('openSummary')
      }
    }
  },
  computed: {
    ...mapGetters(['userIsCorporate', 'isEAuction', 'isSimpleRfp', 'relevantBidKey']),
    ...mapState({
      currentRfp: state => state.currentRfp,
      showNotificationPanel: state => state.auction.showNotificationPanel,
      showSubscriptionError: state => state.error.showSubscriptionError,
      showSubscriptionLostError: state => state.error.showSubscriptionLostError
    }),

    subscriptionError () {
      return this.$t('error.subscription')
    }
  },
  methods: {
    ...mapMutations(['toggleNotification'])
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"
  h1 {
    display: inline-block;
    margin-bottom: 1.5em;
  }

  .main {
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-column-gap: 20px;
    margin-top: 1.5em;
  }

  .notifContainer
    display grid
    grid-column-start 1
    grid-row-gap 15px
    &.open-grid
      grid-template-rows min-content 95px 120px auto
    &.close-grid
      grid-template-rows min-content 95px 120px 93px

</style>
