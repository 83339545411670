<template>
  <v-card>
    <div :class="{general: true, missing: missingMandatoryFields.includes('terms/' + radio.field)}" v-for="radio in radioFeeOptions" :key="radio.title">
      <span class="light">{{ radio.title }}</span>
      <v-radio-group
        style="margin-top: 0px; padding-top: 0px;"
        :disabled="!isRfpFieldEditable()"
        :value="currentRfp.terms[radio.field]"
        @change="value => handleChange(value, radio)">
        <v-radio
          v-for="option in radio.options"
          :key="option.value"
          :label="option.text | t"
          :value="option.value"
          color="#fff" >
          <template v-slot:label>
            <div style="height: 32px;">
            <span style="margin-top: 5px; display: inline-block;" >{{ option.text | t }}</span>
            <NumberField
              :min="0"
              :max="100"
              :percentage-input="true"
              @invalidInput="isInvalid => handleInvalidInput(isInvalid, radio)"
              @click.stop="event => { event.stopPropagation(); }"
              @focus="event => { event.stopPropagation(); }"
              v-if="option.value === 'BILLABLE_TO_THRESHOLD' || (option.value && radio.field === 'hasAlertThreshold')"
              :value="currentRfp.terms[radio.otherField] === '' ? null : currentRfp.terms[radio.otherField]"
              :class="{missing: missingMandatoryFields.includes('terms/' + radio.otherField) || invalidFields.includes('terms/' + radio.otherField)}"
              @input="value => handleNumberInput(value, radio)"
              class="inline-input"/>
            <!-- span v-if="option.value === 'BILLABLE_TO_THRESHOLD' || (option.value && radio.field === 'hasAlertThreshold')"> %</span -->
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </div>

  </v-card>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import NumberField from '../../../generalComponents/inputFields/NumberField'

export default {
  name: 'FeesPanel',
  components: { NumberField },
  data () {
    return {
      radios: []
    }
  },
  created () {
    this.radios = [
      {
        title: this.$t('newRFP.termsAndConditions.hasAlertThreshold', { FeeStructure: this.$t(`newRFP.termsAndConditions.alertThresholdFeeStructure.${this.currentRfp.terms.feeStructure}`) }),
        field: 'hasAlertThreshold',
        otherField: 'alertThreshold',
        key: true,
        options: [{ value: true, text: 'Yes' }, { value: false, text: 'No' }]
      },
      {
        title: this.$t('newRFP.termsAndConditions.needsConsentToLeaveScope'),
        field: 'needsConsentToLeaveScope',
        options: [{ value: true, text: 'Yes' }, { value: false, text: 'No' }]
      },
      {
        title: this.$t('newRFP.termsAndConditions.internalCoordination'),
        field: 'internalCoordinationType',
        options: this.internalCoordinationTypeOptions
      },
      {
        title: this.$t('newRFP.termsAndConditions.travelTime'),
        field: 'travelExpenseType',
        otherField: 'travelExpenseCap',
        options: this.travelExpenseTypeOptions
      },
      {
        title: this.$t('newRFP.termsAndConditions.associatesInFirstYear'),
        field: 'associatesExpenseType',
        options: this.associatesExpenseTypeOptions
      },
      {
        title: this.$t('newRFP.termsAndConditions.activitiesOfTrainees'),
        field: 'traineesExpenseType',
        options: this.traineesExpenseTypeOptions
      },
      {
        title: this.$t('newRFP.termsAndConditions.trainingOfNewMembers'),
        field: 'trainingExpenseType',
        options: this.trainingExpenseTypeOptions
      }
    ]
  },
  computed: {
    ...mapGetters([
      'internalCoordinationTypeOptions',
      'travelExpenseTypeOptions',
      'associatesExpenseTypeOptions',
      'traineesExpenseTypeOptions',
      'trainingExpenseTypeOptions',
      'isRfpFieldEditable'
    ]),
    ...mapState({
      currentRfp: state => state.currentRfp,
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields
    }),
    radioFeeOptions () {
      if (this.currentRfp.terms.feeStructure === 'FIXED') {
        const radioOptions = this.radios.slice(0, 2)
        return radioOptions
      } else if (this.currentRfp.terms.feeStructure === 'RATE_CARD') {
        const radioOptions = this.radios.filter(radio => {
          return radio.field !== 'hasAlertThreshold'
        })
        return radioOptions
      } else {
        return this.radios
      }
    }
  },
  methods: {
    ...mapMutations(['updateCurrentRfp', 'updateCurrentRfpTerms', 'removeMissingMandatoryField', 'addInvalidField', 'removeInvalidField', 'addMissingMandatoryField']),
    handleChange (value, radio) {
      if (radio.field === 'hasAlertThreshold' && value && this.currentRfp.terms.alertThreshold === null) {
        this.updateCurrentRfpTerms({ field: 'alertThreshold', value: 80 })
      }

      if (radio.field === 'hasAlertThreshold' || radio.field === 'travelExpenseType') {
        this.removeInvalidField('terms/' + radio.otherField)
        this.removeMissingMandatoryField('terms/' + radio.otherField)
      }

      this.updateCurrentRfpTerms({ field: radio.field, value })
      this.removeMissingMandatoryField('terms/' + radio.field)
    },

    handleNumberInput (value, radio) {
      this.removeMissingMandatoryField('terms/' + radio.otherField)
      this.removeInvalidField('terms/' + radio.otherField)
      this.updateCurrentRfpTerms({ field: radio.otherField, value })
    },

    handleInvalidInput (isInvalid, radio) {
      if (isInvalid) {
        this.addInvalidField('terms/' + radio.otherField)
      } else {
        this.removeInvalidField('terms/' + radio.otherField)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  .general {
    /*padding 7px 5px 0 5px;*/
    margin-top: 8px
    border: 2px solid white
    border-radius: 10px;
    &.missing {
      border-color: $red;
    }
  }

  .inline-input
    width 55px !important
    text-align center
    display inline-block
    height 26px

    >>> .v-input__control, >>> .v-input__slot
      min-height 0
      height 24px
      padding 0 !important
      border none !important

    >>> input
      padding-top 5px

    &.missing
      border 2px solid $red
</style>
