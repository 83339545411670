<template>
  <div class="grid">
    <div style="grid-column-start: 1; grid-column-end: 3; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px">
      <div>
        <span class="mandatory light">{{ 'scopeGeneratorAdminView.key' | t }}</span>
        <v-text-field
          label=""
          solo flat
          @blur="validateKey"
          @keyup.enter="validateKey"
          :disabled="isUpdate"
          :class="{ missing: keyIsInvalid || missingMandatoryFields.includes('key') }"
          @input="removeMandatory('key')"
          v-model="field.key"></v-text-field>
      </div>
      <div>
        <span class="mandatory light">{{ 'scopeGeneratorAdminView.layout.field.fieldType' | t }}</span>
        <v-select
          label=""
          solo flat
          :items="fieldTypeOptions"
          :class="{ missing: missingMandatoryFields.includes('fieldType') }"
          @input="removeMandatory('fieldType')"
          v-model="field.fieldType"></v-select>
      </div>
      <div v-if="fieldHasOptionList">
        <span class="mandatory light">{{ 'scopeGeneratorAdminView.layout.field.optionListKey' | t }}</span>
        <v-select
          label=""
          solo flat
          item-text="key"
          :class="{ missing: missingMandatoryFields.includes('optionListKey') }"
          :items="customOptionLists"
          @input="removeMandatory('optionListKey')"
          v-model="field.optionListKey"></v-select>
      </div>
    </div>

    <div class="list-attribute-option translation" style="grid-column-start: 1; grid-column-end: 3; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 10px">
      <div v-for="(translation, index) in ['en', 'de']" v-bind:key="index">
        <span class="light" :class="{mandatory: translation === 'en'}">{{ 'translations.' + translation | t }}</span>
        <v-text-field
          label=""
          solo flat
          :class="{ missing: missingMandatoryFields.includes('translation.' + translation) }"
          @input="removeMandatory('translation.' + translation)"
          v-model="field.labelTranslations[translation]"></v-text-field>
      </div>
    </div>

    <div class="list-attribute">
      <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledBy' | t }}</span>
      <v-btn flat @click="addEnabledByRelation">
        <v-icon>ic-plus-circle</v-icon>&nbsp;
        {{ 'scopeGeneratorAdminView.layout.field.addEnabledBy' | t }}
      </v-btn>
    </div>
    <div v-for="(enabledBy, index) in field.enabledBy" v-bind:key="index" class="list-attribute-option enabled-by" :class="{'is-list': isList}">
      <div>
        <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByField' | t }}</span>
        <v-select
          :items="enabledByApplicableFields(allOtherFields(field))"
          label=""
          return-object
          solo flat
          @input="enabledBy.relativePath = null; enabledBy.values =  ['CHECKBOX', 'CHECKBOX_INDENTED'].includes(enabledBy.absolutePath.type) ? [false] : []"
          v-model="enabledBy.absolutePath"></v-select>
      </div>

      <div v-if="isList">
        <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByOwnListField' | t }}</span>
        <v-select
          :items="enabledByApplicableFields(allOtherFieldsInPanel(panel, field))"
          label=""
          solo flat
          return-object
          @input="enabledBy.absolutePath = null; enabledBy.values =  ['CHECKBOX', 'CHECKBOX_INDENTED'].includes(enabledBy.relativePath.type) ? [false] : []"
          v-model="enabledBy.relativePath"></v-select>
      </div>

      <div>
        <template v-if="hasBooleanValue(enabledBy)">
          <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByCheckbox' | t }}</span>
          <v-checkbox
            :input-value="enabledBy.values[0]"
            @change="value => enabledBy.values = [value]"
          ></v-checkbox>
        </template>
        <template v-else>
          <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByValue' | t }}</span>
          <v-select
            label=""
            solo flat
            multiple
            item-text="translatedLabel"
            item-value="key"
            :items="optionListForEnabledBy(enabledBy)"
            v-model="enabledBy.values"
          ></v-select>
        </template>
      </div>

      <div>
        <span></span>
        <v-btn icon @click="field.enabledBy.splice(index, 1)"><v-icon>clear</v-icon></v-btn>
      </div>
    </div>

    <template v-if="!['CHECKBOX', 'CHECKBOX_INDENTED'].includes(field.fieldType)">
      <div class="list-attribute">
        <span class="light">{{ 'scopeGeneratorAdminView.layout.field.constraints' | t }}</span>
        <v-btn flat @click="addConstraint">
          <v-icon>ic-plus-circle</v-icon>&nbsp;
          {{ 'scopeGeneratorAdminView.layout.field.addConstraint' | t }}
        </v-btn>
      </div>
      <div v-for="(constraint, index) in field.constraints" v-bind:key="index" class="list-attribute-option constraint" :class="{'is-list': isList}">
        <div>
          <span class="mandatory light">{{ 'scopeGeneratorAdminView.layout.field.constraintDtype' | t }}</span>
          <v-select
            :items="applicableConstraints"
            label=""
            solo flat
            :class="{ missing: missingMandatoryFields.includes('constraint_' + constraint.key) }"
            @input="removeMandatory('constraint_' + constraint.key)"
            v-model="constraint.dtype"></v-select>
        </div>

        <div v-if="constraintHasFieldRelation(constraint)">
          <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByField' | t }}</span>
          <v-select
            :items="applicableConstraintFields(allOtherFields(field)).filter(field => !isList || !allOtherFieldsInPanel(panel, field).includes(field))"
            label=""
            solo flat
            return-object
            @input="constraint.relativePath = null"
            v-model="constraint.absolutePath"></v-select>
        </div>

        <div v-if="isList && constraintHasFieldRelation(constraint)">
          <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByOwnListField' | t }}</span>
          <v-select
            :items="applicableConstraintFields(allOtherFieldsInPanel(panel, field))"
            label=""
            solo flat
            return-object
            @input="constraint.absolutePath = null"
            v-model="constraint.relativePath"></v-select>
        </div>

        <div v-if="constraintHasValue(constraint)">
          <span class="light">{{ 'scopeGeneratorAdminView.layout.field.constraintValue' | t }}</span>
          <DateField
            v-if="constraintHasDateValue(constraint)"
            v-model="constraint.value" />
          <NumberField
            v-else-if="constraintHasDoubleValue(constraint)"
            v-model="constraint.value" />
          <v-text-field
            v-else
            label=""
            solo flat
            v-model="constraint.value"></v-text-field>
        </div>

        <div :style="'grid-column-start: ' + (isList ? 4 : 3)">
          <span></span>
          <v-btn icon @click="removeMandatory('constraint_' + constraint.key); field.constraints.splice(index, 1)"><v-icon>clear</v-icon></v-btn>
        </div>
      </div>
    </template>

    <div class="actions">
      <div></div>
      <v-btn flat @click="$emit('cancel')">{{ 'buttons.cancel' | t }}</v-btn>
      <v-btn flat class="main" @click="validateAndAdd">{{ `scopeGeneratorAdminView.layout.${isUpdate ? 'update' : 'add'}` | t }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DateField from '../../generalComponents/inputFields/DateField'
import NumberField from '../../generalComponents/inputFields/NumberField'
import shortid from 'shortid'

export default {
  name: 'FieldDefinitionInput',
  components: { NumberField, DateField },
  props: {
    field: {
      type: Object,
      required: true
    },

    panel: {
      type: Object,
      required: true
    },

    isUpdate: {
      type: Boolean,
      default: false
    },

    isList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      keyIsInvalid: false,
      missingMandatoryFields: []
    }
  },
  computed: {
    ...mapGetters([
      'fieldTypeOptions',
      'constraintTypeOptions',
      'allOtherFields',
      'allOtherFieldsInPanel',
      'customOptionLists',
      'optionListByKey',
      'optionListForEnabledBy',
      'enabledByApplicableFields',
      'allFields',
      'keyIsValid'
    ]),

    fieldHasOptionList () {
      return ['RADIO', 'MULTI_SELECT', 'SINGLE_SELECT', 'TAGGABLE_SINGLE_SELECT'].includes(this.field.fieldType)
    },

    applicableConstraints () {
      if (this.field.fieldType === 'DATE') {
        return this.constraintTypeOptions.filter(constraint => ['Mandatory', 'MinDate', 'MaxDate', 'GreaterThan', 'SmallerThan'].includes(constraint.value))
      } else if (['NUMBER', 'PERCENT'].includes(this.field.fieldType)) {
        return this.constraintTypeOptions.filter(constraint => ['Mandatory', 'MinDouble', 'MaxDouble', 'GreaterThan', 'SmallerThan'].includes(constraint.value))
      } else if (['TIME'].includes(this.field.fieldType)) {
        return this.constraintTypeOptions.filter(constraint => ['Mandatory', 'GreaterThan', 'SmallerThan'].includes(constraint.value))
      }

      return this.constraintTypeOptions.filter(constraint => constraint.value === 'Mandatory')
    }
  },
  methods: {
    addEnabledByRelation () {
      this.field.enabledBy.push({
        absolutePath: '',
        relativePath: '',
        values: []
      })
    },

    addConstraint () {
      this.field.constraints.push({
        key: shortid.generate(),
        absolutePath: '',
        relativePath: '',
        value: '',
        dtype: ''
      })
    },

    constraintHasValue (constraint) {
      return ['MaxDate', 'MaxDouble', 'MinDate', 'MinDouble'].includes(constraint.dtype)
    },

    constraintHasDateValue (constraint) {
      return ['MaxDate', 'MinDate'].includes(constraint.dtype)
    },

    constraintHasDoubleValue (constraint) {
      return ['MaxDouble', 'MinDouble'].includes(constraint.dtype)
    },

    constraintHasFieldRelation (constraint) {
      return ['GreaterThan', 'SmallerThan'].includes(constraint.dtype)
    },

    applicableConstraintFields (fields) {
      return fields.filter(field => field.type === this.field.fieldType)
    },

    hasBooleanValue (enabledBy) {
      return enabledBy.relativePath
        ? (['CHECKBOX', 'CHECKBOX_INDENTED'].includes(enabledBy.relativePath.type))
        : (enabledBy.absolutePath ? ['CHECKBOX', 'CHECKBOX_INDENTED'].includes(enabledBy.absolutePath.type) : false)
    },

    validateKey () {
      this.keyIsInvalid = this.isUpdate
        ? false
        : this.allFields.some(field => field.value === this.field.key) || !this.keyIsValid(this.field.key)
    },

    checkMandatoryFields () {
      const mandatoryFields = ['key', 'fieldType']

      for (const field of mandatoryFields) {
        if (!this.field[field]) {
          this.addMandatory(field)
        }
      }

      if (this.fieldHasOptionList) {
        if (!this.field.optionListKey) {
          this.addMandatory('optionListKey')
        }
      } else {
        this.removeMandatory('optionListKey')
      }

      for (const lang of ['en']) {
        if (!this.field.labelTranslations[lang]) {
          this.addMandatory('translation.' + lang)
        }
      }

      if (!['CHECKBOX', 'CHECKBOX_INDENTED'].includes(this.field.fieldType)) {
        for (const constraint of this.field.constraints) {
          if (!constraint.dtype) {
            this.addMandatory('constraint_' + constraint.key)
          }
        }
      } else {
        for (const missingField of this.missingMandatoryFields) {
          if (missingField.startsWith('constraint_')) {
            this.removeMandatory(missingField)
          }
        }
      }
    },

    addMandatory (field) {
      const index = this.missingMandatoryFields.indexOf(field)

      if (index < 0) {
        this.missingMandatoryFields.push(field)
      }
    },

    removeMandatory (field) {
      const index = this.missingMandatoryFields.indexOf(field)

      if (index > -1) {
        this.missingMandatoryFields.splice(index, 1)
      }
    },

    validateAndAdd () {
      this.validateKey()
      this.checkMandatoryFields()

      if (!this.keyIsInvalid && !this.missingMandatoryFields.length) {
        this.$emit('add')
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  .grid
    display grid
    grid-template-columns 1fr 1fr !important
    grid-column-gap 20px
    padding 10px 20px
    margin 5px
    border 1px solid gray
    border-radius 10px

  .actions
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 1fr 200px 200px

  .list-attribute
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 1fr 200px

  .list-attribute-option
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 1fr 1fr 1fr 50px
    grid-column-gap  10px

    &.is-list
      grid-template-columns 1fr 1fr 1fr 1fr 50px

    &.constraint
      grid-template-columns 1fr 1fr 50px

      &.is-list
        grid-template-columns 1fr 1fr 1fr 50px

    &.translation
      grid-template-columns 1fr 1fr 50px

    &.enabled-by
      grid-template-columns 1fr 1fr 50px

      &.is-list
        grid-template-columns 1fr 1fr 1fr 50px
</style>
