import Vue from 'vue'

export default {

  setAllSubjects (state, subjects) {
    Vue.set(state.admin.scopeGenerator, 'subjects', subjects)
  },

  updateSubject (state, subject) {
    const subjects = state.admin.scopeGenerator.subjects ? [ ...state.admin.scopeGenerator.subjects ] : []
    const index = subjects.findIndex(_subject => _subject.key === subject.key)

    if (index > -1) {
      Vue.set(state.admin.scopeGenerator.subjects, index, subject)
    } else {
      subjects.push(subject)
      Vue.set(state.admin.scopeGenerator, 'subjects', [...subjects])
    }
  },

  updateOptionList (state, optionList) {
    const optionLists = state.optionLists
    const index = optionLists.findIndex(list => list.key === optionList.key)

    if (index > -1) {
      Vue.set(optionLists, index, optionList)
    } else {
      optionLists.push(optionList)
    }

    Vue.set(state, 'optionLists', optionLists)
  },

  setCurrentSubject (state, subject) {
    Vue.set(state.admin.scopeGenerator, 'currentSubject', subject)
  },

  currentSubjectToggleActive (state) {
    Vue.set(state.admin.scopeGenerator.currentSubject, 'active', !state.admin.scopeGenerator.currentSubject.active)
  },

  updateSection (state, section) {
    const index = state.admin.scopeGenerator.currentSubject.scopeGeneratorDraftLayout.sections.findIndex(_section => _section.key === section.key)

    if (index > -1) {
      Vue.set(state.admin.scopeGenerator.currentSubject.scopeGeneratorDraftLayout.sections, index, section)
    }
  },

  setRfpStatistics (state, rfpStatistics) {
    Vue.set(state.admin.scopeGenerator, 'rfpStatistics', rfpStatistics)
  },

  setRfpStatisticsFieldNames (state, fieldNames) {
    Vue.set(state.admin.scopeGenerator, 'rfpStatisticsFieldNames', fieldNames)
  }

}
