<template>
  <v-dialog
    :value="visible"
    @input="value => { $emit('visibilityChange', value); resetCorporate() }"
    width="700"
    persistent
    no-click-animation
  >
    <v-card v-click-outside-dialog="closeEvent">
      <v-card-title
        class="headline"
        primary-title
      >
        {{ `userManagement.corporateDialog.title.${title}` | t }}

      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <span class="mandatory">{{ 'userManagement.corporateDialog.name' | t }}</span>
        <v-text-field
          label=""
          v-model="corporate.name"
          @input="removeMissingMandatoryField('name')"
          :placeholder="'userManagement.corporateDialog.placeholder.name' | t "
          :class="{missing: missingMandatoryFields.includes('name')}"
          solo
          flat
        ></v-text-field>

        <span>{{ 'userManagement.corporateDialog.panelLawFirms' | t }}</span>
        <div style="max-height: 300px; overflow: scroll">
          <ul>
            <li v-for="relation of sortedPanelLawFirms"
                :key="relation.lawFirm.id">
              {{ relation.lawFirm.name }}
              <a v-if="!relation.active" @click="activePanelLawFirmRelation(relation)">(Activate relation)</a>
            </li>
          </ul>
        </div>
        <v-select
          label=""
          @input="lf => addPanelLawFirm(lf)"
          :items="nonSelectedLawFirms"
          :item-text="itemName"
          return-object
          :placeholder="`userManagement.corporateDialog.placeholder.panelLawFirms` | t "
          solo
          flat></v-select>

        <span class="mandatory">{{ 'userManagement.corporateDialog.paymentPeriodStart' | t }}</span>
        <DateField
          :value="paymentPeriodStart"
          @input="value => paymentPeriodStartInput(value)"
        />

        <v-checkbox v-model="corporate.active" class="check" :label="'userManagement.corporateDialog.active' | t"></v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="cancel">{{ 'buttons.cancel' | t }}</v-btn>
        <AwaitButton
          class="main"
          :callback="createCorporateLocal">
          {{ 'userManagement.corporateDialog.submit' | t }}
        </AwaitButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import AwaitButton from '../../generalComponents/AwaitButton'
import DateField from '../../generalComponents/inputFields/DateField'
import moment from 'moment'

export default {
  name: 'AddCorporateDialog',
  components: { DateField, AwaitButton },
  model: {
    prop: 'visible',
    event: 'visibilityChange'
  },
  props: {
    // Wether the dialog should be visible
    // Used with v-model (see model definition above)
    visible: {
      type: Boolean,
      required: false,
      default: false
    },

    corporateToUpdate: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      title: 'add',
      corporate: {
        name: '',
        panelLawFirms: [],
        active: false
      }
    }
  },
  watch: {
    corporateToUpdate () {
      if (this.corporateToUpdate) {
        this.title = 'update'
        this.corporate = { ...this.corporateToUpdate }
      } else {
        this.resetCorporate()
      }
    }
  },
  computed: {
    ...mapGetters(['primaryRoleOptions', 'inputDateToISO']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      lawFirms: state => state.lawFirms
    }),

    sortedPanelLawFirms () {
      return [...this.corporate.panelLawFirms].sort((a, b) => a.lawFirm.name === b.lawFirm.name ? 0 : (a.lawFirm.name < b.lawFirm.name ? -1 : 1))
    },

    nonSelectedLawFirms () {
      return this.lawFirms
        .filter(lf => !this.corporate.panelLawFirms.some(pl => pl.lawFirm.id === lf.id))
        .sort((a, b) => a.name === b.name ? 0 : (a.name < b.name ? -1 : 1))
    },

    paymentPeriodStart () {
      return this.corporate.paymentPeriodStart && moment(this.corporate.paymentPeriodStart).isValid() ? moment.utc(this.corporate.paymentPeriodStart).format('DD.MM.YYYY') : this.corporate.paymentPeriodStart
    }
  },
  methods: {
    ...mapActions(['validate', 'createCorporate', 'updateCorporate', 'activateCorporatePanelLawFirm']),
    ...mapMutations(['removeMissingMandatoryField', 'resetMandatoryFields', 'addMissingMandatoryField']),

    addPanelLawFirm (lawfirm) {
      console.log(lawfirm, this.corporate.panelLawFirms)
      this.corporate.panelLawFirms.push({
        active: true,
        lawFirm: {
          id: lawfirm.id,
          name: lawfirm.name
        }
      })
    },

    async activePanelLawFirmRelation (relation) {
      await this.activateCorporatePanelLawFirm({
        corporateId: this.corporate.id,
        lawFirmId: relation.lawFirm.id
      })
    },

    paymentPeriodStartInput (value) {
      this.corporate.paymentPeriodStart = this.inputDateToISO(value)
    },

    async createCorporateLocal () {
      const requiredFields = ['name']
      this.validate({ requiredFields, object: this.corporate })

      if (this.missingMandatoryFields.length > 0) {
        return
      }

      if (typeof this.corporate.id === 'undefined') {
        await this.createCorporate(this.corporate)
      } else {
        await this.updateCorporate(this.corporate)
      }

      this.$emit('visibilityChange', false)
      this.resetCorporate()
    },

    cancel () {
      this.resetCorporate()
      this.resetMandatoryFields()
      this.$emit('visibilityChange', false)
    },

    resetCorporate () {
      this.title = 'add'
      this.corporate = {
        name: '',
        panelLawFirms: [],
        active: false
      }
    },

    itemName (item) {
      const lawFirm = this.lawFirms.find(firm => firm.id === item.id)
      return lawFirm ? lawFirm.name : lawFirm
    },

    closeEvent (event) {
      if (this.visible) {
        this.cancel()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  :deep(.v-dialog)
    overflow visible !important

  .v-card
    border-radius 10px
    .v-card__actions
      padding 16px 45px 24px 45px
    .v-card__title
      padding 24px 45px 16px 45px

  .v-card__text
    padding 16px 45px
    span
      color rgb(158, 158, 158)
      padding-left 12px

  :deep(.v-input__slot)
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  :deep(.v-input--checkbox) .v-input__slot
    border none !important

  .check {
    margin-top: 0px;

  :deep(.v-input__slot) {
      border: none;
    }

    :deep(.v-icon) {
      color: $dark-gray !important;
    }
  }

  :deep(.v-text-field__details),
  :deep(.v-messages)
    display none

  :deep(.invalid),
  :deep(.invalid) .v-input__slot
    border-color $red
    border-width 2px
    color $red
    input
      color $red
</style>
