<template>
  <div
    v-if="userIsLawFirm && render"
    :style="proposalView ? 'background: none; padding: 0;' : ''"
    class="biddingInput" :class="{'law-firm-actions': (!isSeniorityFeeSubstructure && !isCapOrFeeEstimate)}">
    <v-btn style="position: absolute; right: 42px;" v-if="proposalView && updateMode && !edit" icon @click="setDraftProposalItem({ key: 'editFirstBid', value: true }); checkProposalUnsavedChanges()">
      <v-icon style="font-size: 18px">ic-edit</v-icon>
    </v-btn>

    <div v-if="!isSeniorityFeeSubstructure && !isCapOrFeeEstimate">
      <span :class="{light: proposalView, mandatory: edit || !proposalView}">{{ "activeRFP.biddingProcessSection.bidAmount" | t }}</span>
      <NumberField
        v-if="edit || !proposalView"
        v-model="newBidValue"
        @input="validateNumberInput"
        @invalidInput="value => { bidIsInvalid = value; alertMessage = ''; bidMissing = false }"
        @alertMessage="value => alertMessage = value"
        :invalid="bidIsInvalid || bidMissing"
        :placeholder="initialBid && (isInitialBidding(currentRfp) || initialBiddingClosed(currentRfp)) ? formatNumber(initialBid.relevant_display_value || initialBid.amountFixed) : formatNumber(placeholder)"
        :disabled="biddingDisabled || (!updateMode && proposalView)"
        :rules="[(value) => { return isNaN(value) ? 'Must be a number' : true }]"
        id="biddingEstimate"
        class="bid-input"
        live-updates
        :prefix="currencySymbol(currentRfp)" />
      <p v-else>{{ bidReadonlyView }}</p>
    </div>
    <div v-if="!isSeniorityFeeSubstructure && !isCapOrFeeEstimate" style="display: grid; grid-auto-columns: auto; grid-auto-flow: column;">
      <v-btn
        flat v-if="proposalView && edit && updateMode && currentRfpLawFirmRelation.firstBidToBePlacedDraft" @click="cancel">
        {{ 'buttons.cancel' | t }}
      </v-btn>
      <v-btn
        v-if="!((!updateMode || !edit) && proposalView)"
        :disabled="(isInitialBidding(currentRfp) && !newBidValue) || biddingDisabled || bidIsInvalid"
        class="bid-btn main"
        flat
        @click="proposalView ? saveBid() : showDialog=true">
        {{ proposalView ? $t('buttons.saveBid') : $t(buttonText) }}
      </v-btn>
    </div>
    <div v-else :style="proposalView && !edit ? 'width: calc(100% - 50px)' : ''">
      <BidEstimateInput
        :bidDisabled="biddingDisabled || (!updateMode && proposalView)"
        :isCapOrFeeEstimate="isCapOrFeeEstimate"
        :bidCaps="selectedArray"
        :buttonTxt="proposalView ? $t('buttons.saveBid') : buttonText"
        :bidIsInvalid="bidIsInvalid"
        :justPlaceBid="justPlaceBid"
        id="biddingEstimate"
        ref="biddingEstimate"
        :edit="edit"
        :proposalView="proposalView"
        @inputChange="bidTotal => {assignAndEvaluate(bidTotal)}"
        @cancel="cancel()"
        @placeBid="bidTotal => {proposalView ? saveBid() : showDialog=true}" />
    </div>
    <div :class="{seniority: (isSeniorityFeeSubstructure || isCapOrFeeEstimate)}" v-show="alertMessage">
    <span class="span-alert" :class="{notSeniority: (!isSeniorityFeeSubstructure && !isCapOrFeeEstimate)}" >{{ alertMessage }}</span>
    </div>
    <!-- showDialog = true -->
    <ConfirmDialog
      v-if="showDialog"
      :title="$t('activeRFP.biddingProcessSection.dialogTitle')"
      :text="$t('activeRFP.biddingProcessSection.dialogText', {currencySymbol: currencySymbol(), bidAmount: this.newBidValue ? formatNumber(this.newBidValue) : formatNumber(this.placeholder)})"
      :biddingTimeLeft="duration"
      @close="showDialog = false"
      @submit="placeBidAccordingly(); showDialog = false" />
  </div>
</template>

<script>
import bus from '../../../bus'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import NumberField from '../../generalComponents/NumberField'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'
import BidEstimateInput from './BidEstimateInput'

export default {
  name: 'AuctionSubmitBidPanel',
  components: { NumberField, ConfirmDialog, BidEstimateInput },
  props: {
    initialBid: {
      required: true
    },

    orderedBids: {
      required: true
    },

    relevantBidKey: {
      type: String,
      required: true
    },
    duration: {
      required: true,
      type: String
    },
    proposalView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newBidValue: '',
      alertMessage: '',
      bidIsInvalid: false,
      placeholder: '',
      showDialog: false,
      justPlaceBid: false,
      bidWithCap: [
        {
          key: 'Senior Partner',
          rate: null,
          hours: null
        },
        {
          key: 'Junior Partner',
          rate: null,
          hours: null
        },
        {
          key: 'Senior Associate',
          rate: null,
          hours: null
        },
        {
          key: 'Associate',
          rate: null,
          hours: null
        }
      ],
      blendedBidCap: [{ key: 'Blended', rate: null, hours: null }],
      selectedArray: [],
      bidMissing: false,
      render: false
    }
  },
  created () {
    if (this.proposalView) {
      bus.$on('triggerSubmitBid', () => {
        bus.$emit('submitBidReturn', this.placeBidAccordingly(false))
      })

      if (!this.currentRfpLawFirmRelation.firstBidToBePlacedDraft) {
        this.setDraftProposalItem({ key: 'editFirstBid', value: true })
        this.checkProposalUnsavedChanges()
      }
    }

    if (this.initialBid) {
      this.newBidValue = this.initialBid.relevant_display_value || this.initialBid.amountFixed || this.initialBid[this.relevantBidKey]
    }

    if (this.auctionIsInProgress(this.currentRfp) || this.usersLastBid) {
      this.placeholder = this.competingBid ? this.competingBid.relevant_display_value - this.biddingInterval : ''
    }

    this.selectedArray = (this.currentRfp.terms.feeStructureSubType === 'SENIOR_LEVELS') ? this.bidWithCap : this.blendedBidCap

    if (this.usersLastBid) {
      this.selectedArray = this.selectedArray.map((bidRow, index) => {
        let newVal = bidRow
        newVal.rate = this.isSeniorityFeeSubstructure ? this.usersLastBid['amountRateLevel' + (index + 1)] : this.usersLastBid['amountBlendedRate']
        newVal.hours = this.usersLastBid['hoursLevel' + (index + 1)]
        return newVal
      })
    }

    this.render = true
  },
  watch: {
    updateMode () {
      if (this.initialBid) {
        this.newBidValue = this.initialBid.relevant_display_value || this.initialBid.amountFixed || this.initialBid[this.relevantBidKey]
      }

      this.selectedArray = (this.currentRfp.terms.feeStructureSubType === 'SENIOR_LEVELS') ? [
        {
          key: 'Senior Partner',
          rate: null,
          hours: null
        },
        {
          key: 'Junior Partner',
          rate: null,
          hours: null
        },
        {
          key: 'Senior Associate',
          rate: null,
          hours: null
        },
        {
          key: 'Associate',
          rate: null,
          hours: null
        }
      ] : [{ key: 'Blended', rate: null, hours: null }]

      if (this.usersLastBid) {
        this.selectedArray = this.selectedArray.map((bidRow, index) => {
          let newVal = bidRow
          newVal.rate = this.isSeniorityFeeSubstructure ? this.usersLastBid['amountRateLevel' + (index + 1)] : this.usersLastBid['amountBlendedRate']
          newVal.hours = this.usersLastBid['hoursLevel' + (index + 1)]
          return newVal
        })
      }
    },

    orderedBids () {
      if (this.auctionIsInProgress(this.currentRfp) || this.usersLastBid) {
        this.placeholder = this.competingBid ? this.competingBid.relevant_display_value - this.biddingInterval : ''
      }
    },

    usersLastBid () {
      if (this.auctionIsInProgress(this.currentRfp) || this.usersLastBid) {
        this.placeholder = this.competingBid ? this.competingBid.relevant_display_value - this.biddingInterval : ''
      }

      if (this.usersLastBid) {
        this.selectedArray = this.selectedArray.map((bidRow, index) => {
          let newVal = bidRow
          newVal.rate = this.isSeniorityFeeSubstructure ? this.usersLastBid['amountRateLevel' + (index + 1)] : this.usersLastBid['amountBlendedRate']
          newVal.hours = this.usersLastBid['hoursLevel' + (index + 1)]
          return newVal
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'userIsLawFirm',
      'isEAuction',
      'isAdvancedRfp',
      'isSimpleRfp',
      'userLastBidValue',
      'isCapOrFeeEstimate',
      'isSeniorityFeeSubstructure',
      'formatNumber',
      'isSecondBidding',
      'initialBiddingClosed',
      'isInitialBidding',
      'auctionProgress',
      'isQandA',
      'auctionIsInProgress',
      'RfpProgress',
      'currencySymbol',
      'currentRfpLawFirmRelation'
    ]),
    ...mapState({
      currentRfp: state => state.currentRfp,
      userData: state => state.userData,
      usersLastBid: state => state.auction.usersLastBid,
      updateMode: state => state.draftProposal.updateMode,
      edit: state => state.draftProposal.editFirstBid
    }),

    bidReadonlyView () {
      console.log('this.newBidValue', this.newBidValue)
      if (this.initialBid && (this.isInitialBidding(this.currentRfp) || this.isQandA(this.currentRfp) || this.initialBiddingClosed(this.currentRfp))) {
        return this.formatNumber(
          this.initialBid.relevant_display_value || this.initialBid.amountFixed || this.initialBid[this.relevantBidKey]
        ) + this.currencySymbol()
      }

      return this.formatNumber(this.placeholder) + this.currencySymbol()
    },

    biddingDisabled () {
      if (this.isEAuction) {
        return this.currentRfp.status === 'ACTIVE_FIRST_BIDS_CLOSED' || this.auctionProgress(this.currentRfp) > 3
      } else if (this.isAdvancedRfp) {
        return (this.currentRfp.status === 'ACTIVE_SECOND_BIDS_OR_AUCTION' && this.initialBid && this.initialBid.relevant_display_value !== this.userLastBidValue) || this.auctionProgress(this.currentRfp) > 3
      } else if (this.isSimpleRfp) {
        return this.auctionProgress(this.currentRfp) > this.RfpProgress.ACTIVE_SECOND_BIDS_OR_AUCTION
      }

      return true
    },

    biddingInterval () {
      if (this.orderedBids[0]) {
        const currentMinAmount = this.orderedBids[0].relevant_display_value

        if (currentMinAmount >= 1000000) { // > 1 Mio.
          return 2500
        } else if (currentMinAmount >= 500000) {
          return 1000
        } else if (currentMinAmount >= 200000) {
          return 500
        } else if (currentMinAmount >= 50000) {
          return 250
        } else if (currentMinAmount >= 5000) {
          return 50
        } else if (currentMinAmount >= 1000) {
          return 10
        } else {
          return 5
        }
      } else {
        return 0
      }
    },

    buttonText () {
      if (this.isEAuction) {
        return this.isInitialBidding(this.currentRfp) || this.initialBiddingClosed(this.currentRfp) ? (this.biddingDisabled ? 'buttons.initialBidPlaced' : 'buttons.placeInitialBid') : (this.isSecondBidding(this.currentRfp) ? 'buttons.placeNewBid' : (this.isQandA(this.currentRfp) ? 'buttons.biddingNotYetStarted' : 'buttons.biddingClosed'))
      } else if (this.isAdvancedRfp) {
        return this.isInitialBidding(this.currentRfp) || this.initialBiddingClosed(this.currentRfp) ? (this.biddingDisabled ? 'buttons.initialBidPlaced' : 'buttons.placeInitialBid') : (this.isSecondBidding(this.currentRfp) ? (this.biddingDisabled ? 'buttons.secondBidPlaced' : 'buttons.placeSecondBid') : (this.isQandA(this.currentRfp) ? 'buttons.biddingNotYetStarted' : 'buttons.biddingClosed'))
      } else {
        return this.isInitialBidding(this.currentRfp) ? (this.biddingDisabled ? 'buttons.bidPlaced' : 'buttons.placeBid') : (this.isQandA(this.currentRfp) ? 'buttons.biddingNotYetStarted' : 'buttons.biddingClosed')
      }
    },

    competingBid () {
      if ((this.isEAuction || this.isAdvancedRfp) && this.currentRfp.auctionSubType === 'LOOSE' && this.usersLastBid) {
        const result = { ...this.usersLastBid }
        result.relevant_display_value = this.userLastBidValue
        return result
      } else {
        if (!this.usersLastBid) {
          return this.orderedBids[0]
        }

        const userLastBidModified = { ...this.usersLastBid }
        userLastBidModified.relevant_display_value = this.userLastBidValue

        return this.orderedBids[0] && this.usersLastBid
          ? (this.orderedBids[0].relevant_display_value < this.userLastBidValue ? this.orderedBids[0] : userLastBidModified)
          : this.orderedBids[0]
      }
    }
  },
  methods: {
    ...mapActions(['placeBid', 'submitFirstBid', 'checkProposalUnsavedChanges']),
    ...mapMutations(['setDraftProposalItem']),

    cancel () {
      this.setDraftProposalItem({ key: 'editFirstBid', value: false })
      this.checkProposalUnsavedChanges()
      if (this.initialBid) {
        this.newBidValue = this.initialBid.relevant_display_value || this.initialBid.amountFixed
      }

      this.selectedArray = (this.currentRfp.terms.feeStructureSubType === 'SENIOR_LEVELS') ? this.bidWithCap : this.blendedBidCap

      if (this.usersLastBid) {
        this.selectedArray = this.selectedArray.map((bidRow, index) => {
          let newVal = bidRow
          newVal.rate = this.isSeniorityFeeSubstructure ? this.usersLastBid['amountRateLevel' + (index + 1)] : this.usersLastBid['amountBlendedRate']
          newVal.hours = this.usersLastBid['hoursLevel' + (index + 1)]
          return newVal
        })
      }
    },

    async saveBid () {
      const firstBid = await this.placeBidAccordingly(false)
      await this.submitFirstBid({ bid: firstBid })
      this.setDraftProposalItem({ key: 'editFirstBid', value: false })
      this.checkProposalUnsavedChanges()
    },

    validateNumberInput () {
      this.bidIsInvalid = false
      this.justPlaceBid = false
      this.alertMessage = ''

      const input = this.newBidValue

      const maxBidAmount = this.currentRfp.terms.feeStructure === 'RATE_CARD' ? 5000 : 100000000
      if (input > maxBidAmount) {
        this.bidIsInvalid = true
        this.alertMessage = this.$t('activeRFP.biddingProcessSection.alertMessageAmmount', { maxBidAmount: this.formatNumber(maxBidAmount), currencySymbol: this.currencySymbol() })
      }

      // input cannot be negative
      if (input < 0) {
        this.bidIsInvalid = true
        this.alertMessage = this.$t('activeRFP.biddingProcessSection.alertMessageNegative')
      }

      if (this.isInitialBidding(this.currentRfp) || !this.competingBid || this.proposalView) {
        return
      }

      if (this.competingBid && (this.isEAuction || this.isAdvancedRfp)) {
        const competingBidIsUserBid = this.competingBid.relevant_display_value === this.userLastBidValue
        // console.log(input)

        if (input >= this.competingBid.relevant_display_value) {
          this.bidIsInvalid = true
          this.alertMessage = this.$t('activeRFP.biddingProcessSection.' + (this.currentRfp.auctionSubType === 'LOOSE' || competingBidIsUserBid ? 'alertBidMustBeLowerThanOwnLastBid' : 'alertBidMustBeLowerThanLowest'))
        } else if (input > this.competingBid.relevant_display_value - this.biddingInterval) {
          this.bidIsInvalid = true
          this.alertMessage = this.$t('activeRFP.biddingProcessSection.' + (this.currentRfp.auctionSubType === 'LOOSE' || competingBidIsUserBid ? 'alertMessageBiddingIntervalOwn' : 'alertMessageBiddingInterval'), { biddingInterval: this.biddingInterval, currencySymbol: this.currencySymbol() })
        }
      }
    },

    parseAndSetSeniorityBid (placeBid = true) {
      console.log(this.selectedArray)
      let placeAuctionBid = {
        rfp: {
          id: this.currentRfp.id
        },
        lawFirm: {
          id: this.userData.lawFirmId
        }
      }
      this.selectedArray.forEach((memberValue, index) => {
        if (this.currentRfp.terms.feeStructureSubType === 'SENIOR_LEVELS') {
          placeAuctionBid['amountRateLevel' + (index + 1)] = parseInt(memberValue.rate)
        } else {
          placeAuctionBid['amountBlendedRate'] = parseInt(memberValue.rate)
        }
        if (this.isCapOrFeeEstimate) {
          placeAuctionBid['hoursLevel' + (index + 1)] = parseInt(memberValue.hours)
        }
      })
      if (this.isCapOrFeeEstimate) {
        placeAuctionBid['amountFixed'] = this.selectedArray.reduce((prev, actual) => {
          return prev + actual.hours * actual.rate
        }, 0)
      }
      console.log(placeAuctionBid)
      if (placeBid) {
        this.placeBid(placeAuctionBid)
        this.justPlaceBid = true
      }

      return placeAuctionBid
    },

    checkAndPlaceBid (placeBid = true) {
      if (this.bidIsInvalid) {
        return
      }

      const lowestBid = this.competingBid

      const bid = this.newBidValue || this.placeholder
      if (!bid) {
        this.bidMissing = true
      }
      if (bid && (this.proposalView || !lowestBid || this.isInitialBidding(this.currentRfp) || bid <= lowestBid.relevant_display_value - this.biddingInterval)) {
        this.alertMessage = ''

        const placeAuctionBid = {
          rfp: {
            id: this.currentRfp.id
          },
          lawFirm: {
            id: this.userData.lawFirmId
          }
        }
        placeAuctionBid[this.relevantBidKey] = bid

        if (placeBid) {
          this.placeBid(placeAuctionBid)
        }

        if (placeBid) {
          this.newBidValue = ''
        }

        return placeAuctionBid
      }
    },

    // formatBid (bid) {
    //   if (bid) {
    //     const euros = Math.trunc(bid).toString()
    //     return euros.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    //   } else {
    //     return ''
    //   }
    // },

    placeBidAccordingly (placeBid = true) {
      if (!this.isSeniorityFeeSubstructure && !this.isCapOrFeeEstimate) { // if (this.currentRfp.terms.feeStructureSubType !== 'SENIOR_LEVELS' && !this.isCapOrFeeEstimate) {
        return this.checkAndPlaceBid(placeBid)
      } else {
        console.log(this.$refs)
        if (this.$refs.biddingEstimate.validateMandatory()) {
          return
        }
        return this.parseAndSetSeniorityBid(placeBid)
      }
    },

    assignAndEvaluate (value) {
      this.newBidValue = value
      this.validateNumberInput()
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .bid-input
    :deep(.v-input__slot)
      border 1px solid lightgray !important
      border 1px solid rgb(158, 158, 158)
      border-radius 5px !important

    :deep(.v-text-field__details)
      display none

  .bid-btn
    width: 100%
    border 1px solid lightgray
    border-radius 5px
    margin 0
    height 48px
    margin-bottom 8px

  .span-alert
    color $red
    &.notSeniority
      grid-column-start 1
      grid-column-end 3
  .seniority
    display flex
    justify-content flex-end

  .biddingInput {
    background: $light-gray
    border-radius: 10px
    padding: 20px
  }

  .law-firm-actions
    align-items end
</style>
