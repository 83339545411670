<template>
  <v-card
    :style="customCardStyle"
    :class="{'proposal-view': proposalView}"
    >
    <h2 v-if="!proposalView">{{'activeRFP.biddingProcessSection.bidTitle' | t }}</h2>
    <div  style="margin-top: 20px" v-if="userIsCorporate">
        <AuctionDiagram v-if="orderedBids.length"/>
    </div>
    <div style="margin-top: 20px" v-if="!userIsCorporate && isFullDisclosureMode && auctionProgress(currentRfp) > 1 && currentRfp.activeLawFirms > 1">
      <FullDisclosureLawDiagram :dataset="datasetArr" :maxAxis="xAxisMaxVal" :minAxis="xAxisMinVal" />
    </div>

    <div
      class="bid-table"
      :class="{ 'no-data-bid-table': !tableDisplayBids.length }"
      :style="userIsLawFirm && isQandA(currentRfp) && currentRfp.terms.feeStructureSubType !== 'BLENDED' ? 'height: 150px' : ''"
      v-if="!(isInitialBidding(currentRfp) && userIsLawFirm) && !proposalView">
      <v-data-table
        must-sort
        hide-actions
        :headers="headers"
        :items="tableDisplayBids"
        :expand="expand"
        :hide-headers="!tableDisplayBids.length"
        :pagination.sync="pagination"
        item-key="id">

        <template v-slot:no-data>
          <div class="noDataTable">
            <h3 v-if="auctionProgress(currentRfp) === RfpProgress.ACTIVE_QA">{{ $t('activeRFP.biddingProcessSection.noBidsPlacedTitle', { timestamp: rfpLabelsStatus(currentRfp).end }) }}</h3>
            <span class="light" style="font-weight: bold">{{ 'activeRFP.biddingProcessSection.noBidsPlacedLabel' | t }}</span>
          </div>
        </template>

        <template v-slot:expand="props">
          <table v-for="subBid in props.item.subBids" :key="subBid.id" style="width: 100%; border-spacing: 0">
            <tbody>
              <tr>
                <td style="width: 220px"><b style="color: rgb(171, 171, 181);">{{renderLawFirm(subBid.law_firm_id)}}</b></td>
                <td>
                  <section :class="{showIcon: isSeniorityFeeSubstructure}">
                    <div style="display: inline">{{ formatBid(subBid.relevant_display_value) }} {{ isBlendedCapEstimate ? `(${formatBid(props.item.amount_blended_rate)})` : ''}} </div>
                    <CapBreakdown :computed-bid="subBid" />
                  </section>
                </td>
                <td style="width: 210px">{{formatDateTime(subBid.time_of_placement)}}</td>
              </tr>
            </tbody>
          </table>
        </template>

        <template v-slot:items="props">
          <tr :class="{ best: isBestBid(props.item) }" @click="userIsCorporate ? props.expanded = !props.expanded : ''">
            <td><b>{{ renderLawFirm(props.item.law_firm_id) }}</b></td>
            <td>
              <section :class="{showIcon: isSeniorityFeeSubstructure}">
                <div style="display: inline">{{ formatBid(props.item.relevant_display_value) }} {{ isBlendedCapEstimate ? `(${formatBid(props.item.amount_blended_rate)})` : ''}} </div>
                <CapBreakdown :computed-bid="props.item" :showPercentage="false" />
                <!-- <img style="height: 24px; width: auto; margin-left: 15px" src='../../../assets/img/pieChart.svg' alt="pieChart"  /> -->
                <span style="margin-left: 15px; cursor: pointer" v-if="userIsCorporate && props.item.subBids && props.item.subBids.length > 0"><u>{{ $t('activeRFP.biddingProcessSection.showPreviousBidsLabel', {previousBids: props.item.subBids.length}) }}</u></span>
              </section>
            </td>
            <td>{{ formatDateTime(props.item.time_of_placement) }}</td>
          </tr>
        </template>

      </v-data-table>

      <v-btn v-if="tableDisplayBids.length > 6" @click="pagination.rowsPerPage = pagination.rowsPerPage === -1 ? 6 : -1" flat class="show-table">{{ `buttons.${pagination.rowsPerPage === -1 ? 'closeBidHistory': 'showBidHistory'}` | t }}</v-btn>
    </div>
    <AuctionSubmitBidPanel :proposalView="proposalView" :duration="duration" style="margin-top: 20px;" v-if="userIsLawFirm" :initialBid="initialBid" :orderedBids="orderedBids" :relevantBidKey="relevantBidKey"/>
    <ConfirmDialog
      :btnText="'buttons.chooseFirm'"
      :text="$t('activeRFP.chooseLawFirm.chooseFirmQuestion', {lawFirmName: renderLawFirm(selectedLawFirmId)})"
      :title="$t('activeRFP.chooseLawFirm.confirmLawformDialog')"
      v-if="showConfirmationDialog"
      @close="showConfirmationDialog = false"
      @submit="chooseRfpWinner({ rfpId: currentRfp.id, lawFirmId: selectedLawFirmId }); showConfirmationDialog = false" />
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import AuctionDiagram from './AuctionDiagram'
import FullDisclosureLawDiagram from './FullDisclosureLawDiagram'
import AuctionSubmitBidPanel from './AuctionSubmitBidPanel'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'
import i18next from 'i18next'
import { dateTimeMaxWidthPixels } from '../../layoutConstants'
import CapBreakdown from './CapBreakdown'

export default {
  name: 'AuctionBidPanel',
  components: { AuctionSubmitBidPanel, AuctionDiagram, ConfirmDialog, FullDisclosureLawDiagram, CapBreakdown },
  props: {
    relevantBidKey: {
      type: String,
      required: true
    },
    duration: {
      required: true,
      type: String
    },
    proposalView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expand: true,
      selectedLawFirmId: null,
      datasetArr: [],
      xAxisMaxVal: 0,
      xAxisMinVal: 0,
      // precision: 0,
      pagination: { descending: false, rowsPerPage: 6, sortBy: 'relevant_display_value' },
      headers: [
        {
          text: i18next.t('dashboard.rfpInformation.tableHeaders.lawFirm'), // Law Firm
          align: 'left',
          sortable: true,
          width: '220px',
          value: 'law_firm_id'
        },
        {
          text: i18next.t('dashboard.rfpInformation.tableHeaders.currentBid'), // 'Current Bid'
          align: 'left',
          sortable: true,
          value: 'relevant_display_value'
        },
        {
          text: i18next.t('dashboard.rfpInformation.tableHeaders.placedAt'), // 'Placed at'
          align: 'left',
          sortable: true,
          width: dateTimeMaxWidthPixels + 'px',
          value: 'time_of_placement'
        }
      ],
      showConfirmationDialog: false

    }
  },
  // this watchers for the case the rfp is loaded and the Bids or the Law Firm hash aren't available.
  watch: {
    // When the bids are updated through the subscription the whatcher executes the dataset Function to create the new diagram
    bidsWithSubBids () {
      this.datasetArray()
    },
    // when the Law Firm hash is finally loaded it re executes the datasetArray method to paint the own bids in other color.
    currentLawFirmHash () {
      this.datasetArray()
    },
    // Watch for the lawfirm rank, when it changes position from any to first the diagram is redraw with the green line.
    lawFirmRank () {
      this.datasetArray()
    }
  },
  created () {
    this.datasetArray()
  },
  computed: {
    ...mapGetters([
      'userIsLawFirm',
      'userIsCorporate',
      'isAdvancedRfp',
      'currentLawFirmHash',
      'renderLawFirm',
      'formatDateTime',
      'isFullDisclosureMode',
      'userLastBidValue',
      'isSeniorityFeeSubstructure',
      'isBlendedCapEstimate',
      'rfpLabelsStatus',
      'formatBid',
      'isInitialBidding',
      'auctionProgress',
      'RfpProgress',
      'orderedBids',
      'standardBidsArray',
      'bidsWithSubBids',
      'tableDisplayBids',
      'usersLowestComputedBid',
      'differenceToNextLawFirm',
      'userPosition',
      'initialBid',
      'numberOfInitialBids',
      'isQandA'
    ]),

    ...mapState({
      currentRfp: state => state.currentRfp,
      userData: state => state.userData,
      lawFirms: state => state.lawFirms,
      bids: state => state.auction.bids,
      usersLastBid: state => state.auction.usersLastBid,
      lawFirmRank: state => state.currentRfp.lawFirmRelations[0].rank
    }),

    customCardStyle () {
      if (this.userIsCorporate) {
        if (this.currentRfp.status === 'ACTIVE_DECISION_PENDING') {
          return 'min-height: 601px;'
        } else {
          return 'min-height: 556px;'
        }
      } else {
        if (!this.isFullDisclosureMode) {
          if (this.currentRfp.terms.feeStructure === 'FIXED') {
            return 'min-height: 248px;'
          } else if (this.currentRfp.terms.feeStructureSubType === 'BLENDED') {
            return 'min-height: 248px;'
          } else {
            return 'min-height: 598px;'
          }
        }

        if (this.currentRfp.status === 'ACTIVE_FIRST_BIDS') {
          return 'min-height: 248px;'
        } else if (this.currentRfp.terms.feeStructure === 'FIXED' && this.auctionProgress(this.currentRfp) <= 1) {
          return 'min-height: 248px;'
        } else if (this.currentRfp.activeLawFirms <= 1) {
          return 'min-height: 248px;'
        } else {
          return 'min-height: 598px;'
        }
      }
    }
  },
  methods: {
    ...mapActions(['placeBid', 'chooseRfpWinner']),

    shouldShowPercentage (lawFirmId) {
      return lawFirmId === this.currentLawFirmHash
    },

    isBestBid (bid) {
      return this.orderedBids &&
        this.orderedBids[0] &&
        this.orderedBids[0].id === bid.id &&
        ((this.currentRfp.disclosureMode === 'FULL_DISCLOSURE' && this.auctionProgress(this.currentRfp) >= this.RfpProgress.ACTIVE_FIRST_BIDS_CLOSED) || this.userIsCorporate)
    },

    chooseWinnerDialog (lawfirmName) {
      this.selectedLawFirm = lawfirmName
      this.showConfirmationDialog = true
    },

    chooseLawfirm () {
      // console.log('Choosen Lawfirm: ' + this.selectedLawFirm)
      this.showConfirmationDialog = false
    },

    datasetArray () {
      if (!this.bidsWithSubBids) {
        return
      }

      let bidsArray = this.currentRfp.auctionSubType === 'LOOSE' ? JSON.parse(JSON.stringify(this.standardBidsArray)) : JSON.parse(JSON.stringify(this.bidsWithSubBids))
      let otherLawFirmBidSum = 0
      let otherLawFirmCount = 0

      this.bidsWithSubBids.forEach((bid, index) => {
        otherLawFirmBidSum = bid.law_firm_id === this.currentLawFirmHash ? otherLawFirmBidSum : otherLawFirmBidSum + bid.relevant_display_value
        otherLawFirmCount = bid.law_firm_id === this.currentLawFirmHash ? otherLawFirmCount : otherLawFirmCount + 1
      })

      if (otherLawFirmCount > 1 || (this.currentRfp.auctionSubType === 'LOOSE' && otherLawFirmCount > 0)) {
        const averageOfOtherLawFirms = Math.round(otherLawFirmBidSum / otherLawFirmCount)
        const averageBid = { relevant_display_value: averageOfOtherLawFirms, law_firm_id: 'average' }

        const index = bidsArray.findIndex((bid) => {
          return bid.relevant_display_value > averageOfOtherLawFirms
        })

        bidsArray.splice((index > 0 ? index : bidsArray.length), 0, averageBid)
      }

      if (bidsArray.length > 0) {
        this.xAxisMinVal = bidsArray[0].relevant_display_value
        this.xAxisMaxVal = bidsArray[bidsArray.length - 1].relevant_display_value
      }

      let datasets = []
      let lastVal = 0

      const currentLawFirmHasMultipleBids = this.bidsWithSubBids.find(bid => bid.law_firm_id === this.currentLawFirmHash) &&
        this.bidsWithSubBids.find(bid => bid.law_firm_id === this.currentLawFirmHash).subBids.length

      bidsArray.forEach((bid, index) => {
        let bidColor = this.currentRfp.lawFirmRelations[0].rank === 1 ? 'rgb(102, 174, 4)' : 'rgb(199, 54, 57)'

        let newDataSet = {
          realBidValue: bid.relevant_display_value,
          backgroundColor: 'rgba(239, 234, 224, 1)',
          borderColor: bid.law_firm_id === 'average' ? 'rgba(174, 150, 100, 1)' : ((bid.law_firm_id === this.currentLawFirmHash && bid.initialBid) ? bidColor : 'rgba(171, 171, 181, 1)'),
          borderWidth: { left: (bid.law_firm_id === this.currentLawFirmHash ? 3 : 2), right: 1, top: 0, bottom: 0 },
          label: bid.law_firm_id === 'average'
            ? 'Average of Others'
            : (this.renderLawFirm(bid.law_firm_id) + (bid.law_firm_id === this.currentLawFirmHash && bid.is_intial_bid && currentLawFirmHasMultipleBids ? ' (First Bid)' : '')),
          position: index === 0 ? 'Low' : index === bidsArray.length - 1 ? 'High' : '',
          myBid: (bid.law_firm_id === this.currentLawFirmHash && bid.initialBid),
          differenceToNextLawFirm: this.differenceToNextLawFirm
        }
        // console.log(bid.relevant_display_value, lastVal, bid.relevant_display_value - lastVal)
        newDataSet.data = [bid.relevant_display_value - lastVal]
        lastVal = bid.relevant_display_value
        datasets.push(newDataSet)
      })
      this.calcAxisBorders()
      if (datasets.length > 0) {
        datasets.push({
          backgroundColor: 'rgba(239, 234, 224, 1)',
          borderColor: 'rgba(171, 171, 181, 1)',
          borderWidth: { left: 0, right: 0, top: 0, bottom: 0 },
          data: [this.xAxisMaxVal - datasets[datasets.length - 1].realBidValue]
        })
      }
      this.datasetArr = datasets
    },

    calcAxisBorders () {
      const maxBid = this.xAxisMaxVal // TODO make this a method parameter
      const minBid = this.xAxisMinVal // TODO make this a method parameter
      const range = Math.round(Math.max((maxBid - minBid) * 1.3, minBid * 0.2)) // we are trying to pick a meaningful range here
      const digits = (Math.log(range) * Math.LOG10E + 1 | 0)

      // axis edges and tick values will be rounded down to the nearest multiple of the scale factor to make for a nice scale
      const scaleFactor = Math.pow(10, digits - 2) * 5

      this.xAxisMinVal = Math.max(0, Math.floor((minBid - (range * 0.1)) / scaleFactor) * scaleFactor)

      // TODO make sure the axis min value is a multiple of the step -> otherwise, 2 additional ticks will be forced by chart.js
      const step = Math.ceil((range / 4) / scaleFactor) * scaleFactor
      this.xAxisMaxVal = this.xAxisMinVal + step * 4
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .noDataTable
    padding 60px 0
    margin-left auto
    margin-right auto
    text-align center

  .no-data-bid-table
    height 353px

    :deep(div:not(.noDataTable)),
    :deep(table)
      height 100%

    :deep(tr:hover),
    :deep(td:hover)
      background #fff !important

  .v-card
    border-radius: 10px
    background: #fff
    padding: 30px

  .grid
    display grid
    grid-template-columns 200px 1fr

    &.small
      grid-template-columns 1fr 1fr 1fr
      grid-column-gap 10px
      grid-row-gap 10px

  .side-information
    text-align: center
    display grid
    grid-template-rows 80px 80px 80px
    grid-row-gap 10px

    &.small
      grid-template-rows 80px

  .law-firm-actions
    display grid
    grid-template-columns  1fr 1fr
    grid-row-gap 10px
    grid-column-gap 10px

  .box {
    padding 15px
    background: $light-gray
    border-radius 10px
    text-align center
    height 80px
  }

  .bid-table {
    margin-top: 10px
  }

  .winner {
    background-color $gold100 !important
    color white
  }

  .show-table
    width 20%
    margin 10px auto 0 auto
    border 1px solid $dark-gray
    border-radius 5px
    display block
    font-weight 400 !important

  :deep(.showIcon)
    display flex
    align-items center

  :deep(.v-table__overflow)
    overflow unset

  td.best *,
  tr.best *
    color $gold100

  .proposal-view
    min-height 0 !important
    .biddingInput
      margin-top 0 !important
</style>
