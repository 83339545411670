<template>
<v-card ref="popUp" v-show="value" class="pop-up">
  <slot></slot>
</v-card>
</template>

<script>
export default {
  /**
   * Can be used as a wrapper for any v-card pop-up that should be closeable by clicking outside
   */
  name: 'PopUp',
  props: {
    /**
     * V-model compatible visibility flag
     */
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickOutside (event) {
      if (this.$refs.popUp && !this.$refs.popUp.$el.contains(event.target)) {
        this.$emit('input', false)
        document.removeEventListener('click', this.clickOutside, false)
      }
    }
  },
  created () {
    if (this.value) {
      setTimeout(() => {
        document.addEventListener('click', this.clickOutside, false)
      }, 150)
    }
  },
  watch: {
    value: function (newValue) {
      if (newValue) {
        // timeout needed for correct behaviour
        setTimeout(() => {
          document.addEventListener('click', this.clickOutside, false)
        }, 150)
      } else {
        document.removeEventListener('click', this.clickOutside, false)
      }
    }
  }
}
</script>

<style scoped>
  .pop-up {
    z-index: 11;
  }
</style>
