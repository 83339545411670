<script>
import { max, min } from '../../../vuex/validation/validation'

export default {
  name: 'InputFieldMixin',
  props: {
    fieldKey: {
      type: String
    },
    translatedName: {
      type: String
    },
    type: {
      type: String
    },
    validations: {
      type: Array,
      required: false,
      default: () => ([])
    },
    enabledBy: {
      type: Array
    },
    options: {
      type: Array,
      required: false,
      default: () => ([])
    },
    value: {
      required: true
    },
    disabled: {
      type: Boolean
    },
    mandatory: {
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    sortedOptions () {
      return this.options.slice().sort((a, b) => a.orderId - b.orderId)
    }
  },
  methods: {
    getValidationValue (validation) {
      if (validation.value) {
        return validation.value
      } else if (validation.relativePath) {

      } else if (validation.absolutePath) {

      }
    },

    getValidationFunction (validation) {
      const value = this.getValidationValue(validation)

      switch (validation.type) {
        case 'MAXIMUM': return max(value)
        case 'MINIMUM': return min(value)
        default: return null
      }
    },

    validate (value) {
      const validationFunctions = this.validations.map(validation => this.getValidationFunction(validation)).filter(Boolean)

      return validationFunctions.every(validation => validation(value))
    }
  }
}
</script>
