<template>
  <!-- eslint-disable -->
  <div class="main-grid">
    <div class="login-grid" style="overflow-y: auto" :class="{signUpGrid: !isLoginPage}" >
      <img src="../../assets/img/logo.png" width="200" style="display: block;" />

      <template v-if="isCookieDeclaration">
        <div style="display: grid; grid-template-columns: 1fr auto;" class="login-form">
          <h1>{{ 'login.cookieDeclaration' | t }}</h1>
          <v-btn @click="goToSignup()" flat>{{ "buttons.back" | t }}</v-btn>
        </div>
        <div ref="cookieDeclaration" class="cookie-consent-wrapper"></div>
      </template>
      <template v-else-if="isLoginPage">
      <div class="login-title" v-if="!isResetPassword">
        <h1 :class="{sessionExpired: sessionExpired}">{{ (sessionExpired ? 'login.sessionExpiredTitle' : "login.welcomeTitle") | t }}</h1>
        <label>{{ (sessionExpired ? 'login.sessionExpiredPhrase' : "login.signInPhrase") | t }}</label>
      </div>
      <div v-else></div>

        <v-form v-if="!isResetPassword && !isMfaCodeRequested" class="login-form" @submit.prevent="waitButtonToEnd(handleLogin)">
          <!-- <h2 class="form-signin-heading">{{ "login.signInTitle" | t }}</h2> -->

            <p class="inputLabel"><label for="username" class="sr-only">{{ "login.username" | t }}</label></p>
            <div style="margin-bottom: 16px" class="v-input v-text-field v-text-field--single-line v-text-field--solo v-text-field--solo-flat v-text-field--enclosed v-text-field--placeholder theme--light">
              <div class="v-input__control">
                <div class="v-input__slot">
                  <div class="v-text-field__slot">
                    <input :disabled="isButtonExecuting" v-model="email" type="text" id="username" name="username" class="form-control" :placeholder="'login.username' | t " required autofocus autocomplete="email" />
                  </div>
                </div>
              </div>
            </div>


            <p class="inputLabel"><label for="password" class="sr-only">{{ "login.password" | t }}</label></p>
            <div class="v-input v-text-field v-text-field--single-line v-text-field--solo v-text-field--solo-flat v-text-field--enclosed v-text-field--placeholder theme--light">
              <div class="v-input__control">
                <div class="v-input__slot">
                  <div class="v-text-field__slot">
                    <input :disabled="isButtonExecuting" v-model="password" type="password" id="password" name="password" class="form-control" :placeholder="'login.password' | t" required autocomplete="current-password" />
                  </div>
                </div>
              </div>
            </div>

          <p v-if="error !== false" class="alertText" style="padding-bottom: 16px">{{ "login.loginAlert" | t }}</p>
          <div class="sub-grid">
            <span></span>
            <router-link :to="{ name: 'resetPassword' }" style="margin-bottom: 12px">{{ "login.passwordHelp" | t }}</router-link>
          </div>

          <AwaitButton style="margin-left: 50px; color: white !important" class="main" type="submit" color="secondary" :custom-loading="isButtonExecuting" emit-click>
            {{ (isButtonExecuting ? '' : "buttons.login") | t }}
          </AwaitButton>
          <v-btn @click="signUpToggle()" flat>{{ "buttons.signUp" | t }}</v-btn>

        </v-form>

        <MfaCodeForm v-if="isMfaCodeRequested && !isResetPassword" :mfa-method="mfaMethod"></MfaCodeForm>

        <div v-if="isResetPassword">
          <h2 class="form-signin-heading">{{ "login.resetTitle" | t }}</h2>
          <p class="inputLabel" style="margin-top: 15px">
            <label for="username" class="sr-only">{{ "login.username" | t }}</label>
          </p>
            <div
              class="v-input v-text-field v-text-field--single-line v-text-field--solo v-text-field--solo-flat v-text-field--enclosed v-text-field--placeholder theme--light">
              <div class="v-input__control">
                <div class="v-input__slot">
                  <div class="v-text-field__slot">
                    <input v-model="email" type="text" id="mail" name="username" class="form-control" :placeholder="'login.username' | t " required autofocus />
                  </div>
                </div>
              </div>
            </div>

            <div style="text-align: right">
              <v-btn @click="$router.push('login')" flat>{{ 'buttons.cancel' | t }}</v-btn>
              <v-btn color="secondary" class="main" @click="resetPasswordLocal" style="border-radius: 5px; margin-top: 16px">{{ "login.resetPassword" | t }}</v-btn>
            </div>
        </div>
      </template>

      <template v-else>
        <div class="login-title" v-if="showSigninPage">
          <h1 v-if="!singInFormSubmited">{{ 'login.signUpSection.headingTitle' | t }}</h1>
          <label v-if="!singInFormSubmited">{{ 'login.signUpSection.spanText' | t }}</label>

          <template v-if="!singInFormSubmited">
          <v-form style="margin-bottom: 7em" class="login-form" @submit.prevent="handleSignUp">
            <div  style="display: inline-flex; width: 100%; margin: 5px 0">
              <v-btn
                @click="handleCustomerType('ROLE_CORPORATE'); removeMissingMandatoryField('primaryRole')"
                :class="{active: isNewCopororateSelected, missingRole: missingMandatoryFields.includes('primaryRole')}"
                :outline="!isNewCopororateSelected"
                class="customerType leftBtn"
                depressed >{{ "login.signUpSection.corporateBtn" | t }}</v-btn>
              <v-btn
                @click="handleCustomerType('ROLE_LAWFIRM'); removeMissingMandatoryField('primaryRole')"
                :class="{active: isNewLawFirmSelected, missingRole: missingMandatoryFields.includes('primaryRole')}"
                :outline="!isNewLawFirmSelected"
                class="customerType rightBtn"
                depressed >{{ "login.signUpSection.lawFirmBtn" | t }}</v-btn>
            </div>

            <section style="margin-bottom: 20px" v-for="(form, index) in newUserForm" :key="index">
              <div v-if="index === 2">
                <p class="inputLabel">
                  <label class="sr-only mandatory">
                    {{ `login.signUpSection.position` | t }}
                  </label>
                </p>
                <v-select
                  label=""
                  :class="{missing: missingMandatoryFields.includes('position')}"
                  v-model="newUser.position"
                  @input="removeMissingMandatoryField('position')"
                  :items="newUser.primaryRole ? (newUser.primaryRole === 'ROLE_CORPORATE' ? corporateUserPositionOptions : lawfirmUserPositionOptions) : []"
                  solo
                  flat
                ></v-select>
              </div>
              <template v-else>
                <p class="inputLabel">
                  <label :for="form.name" :class="{mandatory: form.mandatory}" class="sr-only">
                    {{ `login.signUpSection.${(form.name === 'company' && (isNewLawFirmSelected || isNewCopororateSelected)) ? (isNewLawFirmSelected ? 'lawfirmName' : 'corporateName' ) : form.name}` | t }}
                  </label>
                </p>
                <v-text-field
                  v-model="newUser[form.name]"
                  :class="{missing: (missingMandatoryFields.includes(form.name) || invalidFields.includes(form.name))}"
                  :name="form.name"
                  @input="handleFieldValidation(form.name); removeMissingMandatoryField(form.name)"
                  type="text"
                  required solo flat />
              </template>
            </section>

            <div v-if="newUser.primaryRole" class="sub-grid" style="grid-template-columns: 1fr;">
              <p>
                <label v-html="$t(`login.signUpSection.${ isNewCopororateSelected ? 'termsAndConditionsCorporates' : 'termsAndConditionsLawFirms' }`)"></label>&nbsp;
                <label v-html="$t(`login.signUpSection.dataProtection`)"></label>
              </p>
              <p v-if="alertText && (missingMandatoryFields.length > 0 || invalidFields.length > 0)" class="alertText">{{ alertText }}</p>
            </div>

            <v-btn :disabled="disableSingUp" class="main" color="secondary" type="submit" style="margin-left: 50px">{{ "login.signUpSection.submitbtn" | t }}</v-btn>
            <v-btn @click="signUpToggle()" flat>{{ "buttons.back" | t }}</v-btn>
          </v-form>
          </template>

          <template v-else>
            <section class="login-form" style="margin: 20% 0 55% 0">
              <h2 style="margin-bottom: 10px">{{ 'login.signUpSection.submittedFormTitle' | t }}</h2>
              <p >{{ 'login.signUpSection.submittedFormText' | t }}</p>
              <v-btn @click="signUpToggle()" class="main">{{ 'buttons.back' | t }}</v-btn>
            </section>
          </template>

        </div>
      </template>
    </div>
    <div>
      <SelectField
        style="position: fixed; right: 10px; top: 10px;"
        :translatedName="''"
        v-model="newUser.language"
        @input="changeLang"
        :options="languageOptions" />
      <img src="../../assets/img/login.jpg" style="max-height: 100vh;">
    </div>
    <ConfirmDialog
      v-if="showMailSentNotification"
      @submit="showMailSentNotification = false"
      hide-cancel
      acceptButtonTextOrKey="login.close"
      :title="'login.mailSentTitle' | t"
      :text="'login.mailSent' | t" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import ConfirmDialog from '../generalComponents/ConfirmDialog'
import AwaitButton from '../generalComponents/AwaitButton'
import SelectField from '../generalComponents/inputFields/SelectField'
import MfaCodeForm from './MfaCodeForm'
export default {
  name: 'Login',
  components: { MfaCodeForm, SelectField, ConfirmDialog, AwaitButton },
  props: {
    sessionExpired: {
      type: Boolean,
      default: false,
      required: false
    },
    isLoginPage: {
      type: Boolean,
      required: true
    },
    isResetPassword: {
      type: Boolean,
      required: true
    },
    isCookieDeclaration: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      email: '',
      password: '',
      error: false,
      showSigninPage: true,
      showMailSentNotification: false,
      alertText: null,
      singInFormSubmited: false,
      newUser: {
        primaryRole: '',
        company: '',
        fullName: '',
        position: '',
        email: '',
        phone: '',
        mobile: '',
        language: 'en'
      },
      newUserForm: [
        { name: 'company', mandatory: true },
        { name: 'fullName', mandatory: true },
        { name: 'position', mandatory: true },
        { name: 'email', mandatory: true },
        { name: 'phone', mandatory: false },
        { name: 'mobile', mandatory: false }
      ],
      isMfaCodeRequested: false
    }
  },
  computed: {
    ...mapGetters(['corporateUserPositionOptions', 'lawfirmUserPositionOptions', 'languageOptions']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields,
      mfaMethod: state => state.mfaMethod
    }),

    isNewLawFirmSelected () {
      return this.newUser.primaryRole === 'ROLE_LAWFIRM'
    },

    isNewCopororateSelected () {
      return this.newUser.primaryRole === 'ROLE_CORPORATE'
    },

    disableSingUp () {
      return this.invalidFields.length > 0
    }
  },
  created () {
    if (this.$router.currentRoute.query.mfa) {
      this.isMfaCodeRequested = true
    }
    this.newUser.language = this.$i18n.i18next.language || 'en'
    this.addCookieDeclaration()
  },
  watch: {
    isCookieDeclaration () {
      this.addCookieDeclaration()
    }
  },
  methods: {
    ...mapActions(['login', 'resetPassword', 'signUpUser']),
    ...mapMutations(['addMissingMandatoryField', 'removeMissingMandatoryField', 'addInvalidField', 'removeInvalidField']),

    changeLang (lang) {
      this.$i18n.i18next.changeLanguage(lang)
    },

    async addCookieDeclaration () {
      if (this.isCookieDeclaration) {
        const script = document.createElement('script')
        script.setAttribute('id', 'CookieDeclaration')
        script.setAttribute('src', 'https://consent.cookiebot.com/a8ca9df7-2a18-4487-877d-512878e4d970/cd.js')
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('async', 'async')

        await this.$nextTick()
        this.$refs.cookieDeclaration.appendChild(script)
      }
    },

    alert (message) {
      alert(message)
    },

    async handleLogin () {
      const loginResponse = await this.login({ email: this.email, password: this.password })
      if (loginResponse.success && loginResponse.requiresMfaVerification) {
        this.isMfaCodeRequested = true
      } else if (loginResponse.success) {
        this.$router.push({
          name: 'dashboardRfps'
        })
      } else {
        this.error = true
      }
      /*
      if (loginSuccessful) {
        this.$router.push({
          name: 'dashboardRfps'
        })
      } else {
        this.error = true
      }
       */
    },

    async resetPasswordLocal () {
      await this.resetPassword(this.email)
      this.showMailSentNotification = true
      this.isMfaCodeRequested = false
    },

    signUpToggle () {
      if (this.isLoginPage) {
        this.$router.push({ name: 'signUp' })
      } else {
        this.$router.push({ name: 'login' })
        this.singInFormSubmited = false
      }
    },

    goToSignup () {
      this.$router.push({ name: 'signUp' })
    },

    async handleSignUp () {
      this.alertText = null
      this.signUpValidation()
      if (this.missingMandatoryFields.length > 0) {
        this.setAlert(true)
      } else {
        const createUserInput = {}
        for (let [key, value] of Object.entries(this.newUser)) {
          if (key !== 'company') {
            createUserInput[key] = value
          }
        }
        const organizationName = this.newUser.company
        // console.log(createUserInput, organizationName)
        this.signUpUser({ createUserInput, organizationName, organizationId: this.$route.query.id })
        this.resetNewUserObj()
        this.singInFormSubmited = true
      }
    },

    resetNewUserObj () {
      for (let [key] of Object.entries(this.newUser)) {
        this.newUser[key] = key === 'acceptToS' ? false : (key === 'language' ? this.newUser[key] : '')
      }
    },

    handleCustomerType (customerType) {
      this.newUser.position = ''
      this.newUser.primaryRole = customerType
    },

    signUpValidation () {
      for (let [key, value] of Object.entries(this.newUser)) {
        if (!value && key !== 'mobile' && key !== 'phone') {
          this.addMissingMandatoryField(key)
        }
      }
    },

    setAlert (isMissingFieldAlert) {
      if (isMissingFieldAlert) {
        this.alertText = this.$t('login.signUpSection.missingFieldsAlert')
      } else {
        this.alertText = this.$t('login.signUpSection.invalidInputsAlert')
      }
    },

    handleFieldValidation (field) {
      switch (field) {
        case 'email':
          const validationREGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g
          if (!validationREGEX.test(this.newUser[field])) {
            this.alertText = this.$t('login.signUpSection.validEmailAddressAlert')
            this.addInvalidField(field)
          } else {
            this.alertText = null
            this.removeInvalidField(field)
          }
          break
        case 'mobile':
        case 'phone' :
          if (isNaN(this.newUser[field])) {
            this.alertText = this.$t('login.signUpSection.validPhoneNumberAlert')
            this.addInvalidField(field)
          } else {
            this.alertText = null
            this.removeInvalidField(field)
          }
          break
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .cookie-consent-wrapper
    text-align: justify

  :deep(#CookieDeclarationUserStatusPanel) *
      font-size 16px

  .main-grid {
    display: grid;
    grid-template-columns: 800px 1fr
    height 100vh
    overflow hidden
  }

  .login-form :deep(button) {
    margin-right: 0;
    margin-left: 0;
    width 200px
    float right
    border-radius 5px
    border solid grey 1px
    &.customerType {
      width 50%
      height 40px
      border-radius 5px
      &.leftBtn {
        border-top-right-radius 0px
        border-bottom-right-radius 0px
      }
      &.rightBtn {
        border-top-left-radius 0px
        border-bottom-left-radius 0px
        border-left: 0
      }
      &.active {
        color white
        background-color: $maroon100 !important
      }
      &.missingRole {
        color: $red !important
        border-width 2px
        border-color $red
      }
    }
  }

  :deep(.v-messages)
    display none

  :deep(.v-input__slot)
    margin-bottom 0 !important

  .missingTerms
    color $red

  .alertText
    justify-self end
    margin-bottom 0
    padding-top 4px
    color: $red

  .login-grid {
    grid-template-rows auto
    display grid
    padding 80px 100px
    background #fff
    width 100%
    grid-row-gap 2em
    &.signUpGrid {
      grid-row-gap 1em
    }
  }

  :deep(.v-text-field)
    .v-input__slot
      border 1px solid rgb(158, 158, 158)
      border-radius 5px !important

  :deep(.v-text-field__details)
    display none

  a
    color $dark-gray
    font-size 16px
    height 20px
    padding-top 4px
    text-decoration none
    float right
    text-align right

  .sub-grid
    display grid
    grid-template-columns 1fr 200px
    margin-bottom 8px

    .v-input
      margin-top 0

  .sessionExpired
    color $red

  .v-alert
    padding 8px
    width 100% !important

  .inputLabel
    margin-bottom 5px

  :deep(.v-progress-circular)
    svg, div, circle
      color $maroon50 !important
</style>
