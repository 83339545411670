<template>
  <div class="row">
    <div class="header">
      <div class="order-buttons">
        <v-btn :disabled="disableUpButton" small icon @click="$emit('moveUp')"><v-icon>keyboard_arrow_up</v-icon></v-btn>
        <v-btn :disabled="disableDownButton" small icon @click="$emit('moveDown')"><v-icon>keyboard_arrow_down</v-icon></v-btn>
      </div>
      <h4></h4>
      <v-btn flat icon @click="$emit('delete'); $emit('changeNewField')"><v-icon>clear</v-icon></v-btn>
      <v-btn flat @click="newFieldInputVisible = true; $emit('changeNewField')" :disabled="row.fields.length >= 3">
        <v-icon>ic-plus-circle</v-icon>
        &nbsp;
        {{ 'scopeGeneratorAdminView.layout.row.addField' | t }}
      </v-btn>
    </div>

    <FieldDefinition
      v-for="(field, index) in row.fields"
      :key="field.key"
      :is-list="isList"
      @moveUp="move(index, 1)"
      @moveDown="move(index, -1)"
      :disable-up-button="index === 0"
      :disable-down-button="index === row.fields.length - 1"
      @delete="row.fields.splice(index, 1); $emit('changeNewField')"
      @updateField="$emit('changeNewField')"
      :panel="panel"
      :field="field" />
    <FieldDefinitionInput
      v-if="newFieldInputVisible"
      :field="newField"
      :panel="panel"
      :is-list="isList"
      @add="add"
      @updateField="$emit('changeNewField')"
      @cancel="cancel" />
  </div>
</template>

<script>
import FieldDefinition from './FieldDefinition'
import FieldDefinitionInput from './FieldDefinitionInput'

export default {
  name: 'RowDefinition',
  components: { FieldDefinitionInput, FieldDefinition },
  props: {
    row: {
      type: Object,
      required: true
    },
    isList: {
      type: Boolean,
      default: false
    },
    panel: {
      type: Object,
      required: true
    },
    disableUpButton: {
      type: Boolean,
      default: false
    },
    disableDownButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newFieldInputVisible: false,
      newField: {
        key: '',
        fieldType: '',
        labelTranslations: { en: '' },
        optionListKey: '',
        enabledBy: [],
        constraints: []
      }
    }
  },
  methods: {
    add () {
      const newField = { ...this.newField }

      newField.enabledBy = newField.enabledBy.map(enabledBy => ({
        absolutePath: enabledBy.absolutePath ? enabledBy.absolutePath.value : null,
        relativePath: enabledBy.relativePath ? enabledBy.relativePath.value : null,
        values: enabledBy.values
      }))

      newField.constraints = newField.constraints.map(constraint => ({
        absolutePath: constraint.absolutePath ? constraint.absolutePath.value : null,
        relativePath: constraint.relativePath ? constraint.relativePath.value : null,
        value: constraint.value,
        dtype: constraint.dtype
      }))

      this.row.fields.push(newField)
      this.cancel()
    },

    cancel () {
      this.newField = {
        key: '',
        fieldType: '',
        labelTranslations: { en: '' },
        optionListKey: null,
        enabledBy: [],
        constraints: []
      }

      this.newFieldInputVisible = false
    },

    move (index, direction = 1) {
      const selectedIndex = index
      const targetIndex = index - direction

      const target = this.row.fields[targetIndex]
      const selected = this.row.fields[selectedIndex]

      this.$set(this.row.fields, targetIndex, selected)
      this.$set(this.row.fields, selectedIndex, target)
    }
  }
}
</script>

<style scoped lang="stylus">
  .header
    display grid
    grid-template-columns 50px 1fr 50px 200px
    align-items center

    >>> .v-icon:before
      font-size 20px
      color $dark-gray !important

  .row
    border 1px solid gray
    border-radius 10px !important
    margin-bottom 10px

  .order-buttons
    margin-left 15px
</style>
