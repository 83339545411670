<template>
  <div class="card-grid" :class="{small: small}">
    <SelectableCard
      v-for="option in filteredOptions"
      :key="option.value"
      :title="option.value.startsWith('CUSTOM') && disabled ? customTitle(option.value) : option.text"
      text=""
      :disabled="disabled"
      :selected="value ? value.includes(option.value) : false"
      @selected="handleSelected(option)"
      :has-title-tooltip="hasTitleTooltip(option)"
      :title-tooltip-text="titleTooltipText(option)"
      :small="small"
      :showFileIcon="showFileIcon(option)"
      :showDeleteIcon="option.value.startsWith('CUSTOM') && !disabled && currentRfp.status === 'DRAFT'"
      @fileIconClicked="$emit('fileIconClicked', option)"
      @deleteIconClicked="$emit('deleteIconClicked', option)"
      hide-select
      show-check-icon>

      <template v-slot:header-content>
        <v-checkbox
          style="margin-top: 0; justify-self: end; margin-right: 20px;"
          @click.stop="$emit('checkBoxInput', { value: !additionalCheckBoxValue(option), option })"
          v-if="hasAdditionalCheckBox(option) && !disabled"
          :label="additionalCheckBoxText(option) | t"
          :input-value="additionalCheckBoxValue(option)">
        </v-checkbox>

        <v-text-field
          v-if="!disabled && option.value.startsWith('CUSTOM')"
          @click.stop
          hide-details
          :placeholder="$t('newRFP.feeDefinition.customTitle')"
          :value="customTitle(option.value)"
          @input="v => setCustomTitle(option.value, v)"
          dense
          flat
          style="background: #fff; padding-left: 5px; padding-top: 0; width: calc(100% - 55px); border-radius: 3px"
        >

        </v-text-field>
      </template>

      <div class="multiSelectOptions">
        <template v-if="option.value === 'TEAM' || option.value === 'LIABILITY'">
          <div v-if="hasTextField(option) && !(disabled && !textFieldValue(option)) && finishedTransition.upperTransition" style="padding: 10px 20px 0 20px;">
            <p v-if="disabled" style="color: #fff; line-break: anywhere">{{ textFieldValue(option) }}</p>

            <v-textarea
              v-else-if="!disabled"
              style="min-height: 0;"
              @input="value => $emit('textFieldInput', { value, option })"
              @click.stop
              :readonly="disabled"
              :rows="3"
              :value="textFieldValue(option)"
              :placeholder="textFieldPlaceholder(option) | t"
              solo
              flat />
          </div>
          <div class="divGrow" :class="{divGrowApply: growCards.upperCards}"></div>
        </template>

        <template v-else-if="option.value === 'CUSTOM1' || option.value === 'STRATEGY'">
          <div v-if="hasTextField(option) && !(disabled && !textFieldValue(option) && !files(option).length) && finishedTransition.thirdTransition" style="padding: 10px 20px 0 20px;">
            <span v-if="textFieldTitle(option)" style="color: #fff">{{ textFieldTitle(option) | t }}</span>

            <p v-if="disabled" v-show="textFieldValue(option)" style="color: #fff; line-break: anywhere">{{ textFieldValue(option) }}</p>
            <v-textarea
              v-else
              style="min-height: 0;"
              @input="value => $emit('textFieldInput', { value, option })"
              @click.stop
              :readonly="disabled"
              :rows="3"
              :value="textFieldValue(option)"
              :placeholder="textFieldPlaceholder(option) | t"
              solo
              flat />

            <div v-if="files(option).length" style="margin-top: 5px">
              <FileChip
                v-for="file in files(option)"
                @delete="$emit('deleteFile', file)"
                :deleteIcon="showDeleteIconForFiles"
                iconStyle="color: #000 !important"
                style="height: 24px; color: #000"
                :key="file.id"
                :file="file" />
            </div>
          </div>
          <div class="divGrow" :class="{divGrowApply: growCards.thirdCards}"></div>
        </template>

        <template v-else-if="option.value.includes('CUSTOM')">
          <div v-if="hasTextField(option) && !(disabled && !textFieldValue(option) && !files(option).length) && finishedTransition.fourthTransition" style="padding: 10px 20px 0 20px;">
            <span v-if="textFieldTitle(option)" style="color: #fff">{{ textFieldTitle(option) | t }}</span>

            <p v-if="disabled" v-show="textFieldValue(option)" style="color: #fff; line-break: anywhere">{{ textFieldValue(option) }}</p>
            <v-textarea
                v-else
                style="min-height: 0;"
                @input="value => $emit('textFieldInput', { value, option })"
                @click.stop
                :readonly="disabled"
                :rows="3"
                :value="textFieldValue(option)"
                :placeholder="textFieldPlaceholder(option) | t"
                solo
                flat />

            <div v-if="files(option).length" style="margin-top: 5px">
              <FileChip
                  v-for="file in files(option)"
                  @delete="$emit('deleteFile', file)"
                  :deleteIcon="showDeleteIconForFiles"
                  iconStyle="color: #000 !important"
                  style="height: 24px; color: #000"
                  :key="file.id"
                  :file="file" />
            </div>
          </div>
          <div class="divGrow" :class="{divGrowApply: growCards.fourdCards}"></div>
        </template>

        <template v-else>
          <div v-if="hasTextField(option) && !(disabled && !textFieldValue(option) && !files(option).length) && finishedTransition.downTransition" style="padding: 10px 20px 0 20px;">
            <span v-if="textFieldTitle(option)" style="color: #fff">{{ textFieldTitle(option) | t }}</span>

            <p v-if="disabled" v-show="textFieldValue(option)" style="color: #fff; line-break: anywhere">{{ textFieldValue(option) }}</p>
            <v-textarea
              v-else
              style="min-height: 0;"
              @input="value => $emit('textFieldInput', { value, option })"
              @click.stop
              :readonly="disabled"
              :rows="3"
              :value="textFieldValue(option)"
              :placeholder="textFieldPlaceholder(option) | t"
              solo
              flat />

            <div v-if="files(option).length" style="margin-top: 5px">
              <FileChip
                v-for="file in files(option)"
                @delete="$emit('deleteFile', file)"
                :deleteIcon="showDeleteIconForFiles"
                iconStyle="color: #000 !important"
                style="height: 24px; color: #000"
                :key="file.id"
                :file="file" />
            </div>
          </div>
          <div v-if="option.value === 'PITCH_DOCUMENT' || option.value === 'CREDENTIALS'" class="divGrow" :class="{divGrowApply: growCards.downCards}"></div>
        </template>
        <!-- <div v-if="option.value === 'TEAM' || option.value === 'LIABILITY'" class="divGrow" :class="{divGrowApply: growUpperCards}"></div> -->
      </div>

    </SelectableCard>
  </div>
</template>

<script>
import SelectableCard from './SelectableCard'
import FileChip from '../generalComponents/FileChip'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SelectableCardMultiSelect',
  components: {
    SelectableCard,
    FileChip
  },
  props: {
    // the current value
    // use with v-model
    value: {
      required: true
    },

    // an array of {value: String, text: String} objects, representing the selectable options
    options: {
      type: Array,
      required: true
    },

    // Changes the component to a smaller card
    small: {
      type: Boolean,
      default: false,
      required: false
    },

    onlySelected: {
      type: Boolean,
      default: false,
      required: false
    },

    disabled: {
      type: Boolean,
      default: false,
      required: false
    },

    hasTextField: {
      type: Function,
      default: () => { return false }
    },

    showFileIcon: {
      type: Function,
      default: () => { return false }
    },

    showDeleteIconForFiles: {
      type: Boolean,
      default: false
    },

    customTitle: {
      type: Function,
      default: () => {}
    },

    setCustomTitle: {
      type: Function,
      default: () => {}
    },

    files: {
      type: Function,
      default: () => { return [] }
    },

    textFieldTitle: {
      type: Function,
      default: () => { return '' }
    },

    textFieldValue: {
      type: Function,
      default: () => { return '' }
    },

    textFieldPlaceholder: {
      type: Function,
      default: () => { return '' }
    },

    hasAdditionalCheckBox: {
      type: Function,
      default: () => false
    },

    additionalCheckBoxText: {
      type: Function,
      default: () => { return '' }
    },

    additionalCheckBoxValue: {
      type: Function,
      default: () => { return '' }
    },

    // Object that controls if the cars should start grown or not
    shouldStartShowingSubOptions: {
      type: Object,
      default: () => { return { upperCards: false, downCards: false, thirdCards: false } }
    },

    hasTitleTooltip: {
      type: Function,
      default: () => false,
      required: false
    },

    titleTooltipText: {
      type: Function,
      default: () => '',
      required: false
    }
  },
  data () {
    return {
      finishedTransition: {
        upperTransition: false,
        downTransition: false,
        thirdTransition: false,
        fourthTransition: false
      },
      growCards: {
        upperCards: false,
        downCards: false,
        thirdCards: false,
        fourdCards: false
      }
    }
  },
  created () {
    if (this.shouldStartShowingSubOptions.upperCards) {
      this.finishedTransition.upperTransition = true
      this.growCards.upperCards = true
    }
    if (this.shouldStartShowingSubOptions.downCards) {
      this.finishedTransition.downTransition = true
      this.growCards.downCards = true
    }
    if (this.shouldStartShowingSubOptions.thirdCards) {
      this.finishedTransition.thirdTransition = true
      this.growCards.thirdCards = true
    }
    if (this.shouldStartShowingSubOptions.fourdCards) {
      this.finishedTransition.fourthTransition = true
      this.growCards.fourdCards = true
    }
    if (this.disabled) {
      this.finishedTransition.upperTransition = true
      this.finishedTransition.downTransition = true
      this.finishedTransition.thirdTransition = true
      this.finishedTransition.fourthTransition = true
    }
  },
  computed: {
    ...mapGetters(['feeStructureSubOptions', 'qualityScoreOptions']),
    ...mapState({
      currentRfp: state => state.currentRfp
    }),

    filteredOptions () {
      if (this.onlySelected) {
        return this.options.filter(option => this.value.includes(option.value))
      }

      return this.options
    }
  },
  methods: {
    async handleSelected (option) {
      this.handleSelection(option)
      // console.log(option, this.value)
      // console.log(['PITCH_DOCUMENT', 'CREDENTIALS'].includes(option.value), this.value.includes('CREDENTIALS'), this.value.includes('PITCH_DOCUMENT'))
      // console.log(this.growCards)
      if (['PITCH_DOCUMENT', 'CREDENTIALS'].includes(option.value)) {
        if (this.value.includes('CREDENTIALS') || this.value.includes('PITCH_DOCUMENT')) {
          this.growCards.downCards = true
          await new Promise((resolve) => {
            setTimeout((setfinishedTransition) => {
              setfinishedTransition('down')
            }, 1000, this.setfinishedTransition)
            resolve()
          })
        } else {
          this.finishedTransition.downTransition = false
          this.growCards.downCards = false
        }
      } else if (['TEAM', 'LIABILITY'].includes(option.value)) {
        if (this.value.includes('TEAM') || this.value.includes('LIABILITY')) {
          this.growCards.upperCards = true
          await new Promise((resolve) => {
            setTimeout((setfinishedTransition) => {
              setfinishedTransition('upper')
            }, 1000, this.setfinishedTransition)
            resolve()
          })
        } else {
          this.finishedTransition.upperTransition = false
          this.growCards.upperCards = false
        }
      } else if (['STRATEGY', 'CUSTOM1'].includes(option.value)) {
        if (this.value.includes('STRATEGY') || this.value.includes('CUSTOM1')) {
          this.growCards.thirdCards = true
          await new Promise((resolve) => {
            setTimeout((setfinishedTransition) => {
              setfinishedTransition('third')
            }, 1000, this.setfinishedTransition)
            resolve()
          })
        } else {
          this.finishedTransition.thirdTransition = false
          this.growCards.thirdCards = false
        }
      } else {
        if (this.value.includes('CUSTOM2') || this.value.includes('CUSTOM3')) {
          this.growCards.fourdCards = true
          await new Promise((resolve) => {
            setTimeout((setfinishedTransition) => {
              setfinishedTransition('fourth')
            }, 1000, this.setfinishedTransition)
            resolve()
          })
        } else {
          this.finishedTransition.fourthTransition = false
          this.growCards.fourdCards = false
        }
      }
    },

    handleSelection (option) {
      if (this.disabled) {
        return
      }

      let newValue
      if (Array.isArray(this.value)) {
        newValue = this.value
      } else {
        newValue = this.value ? [this.value] : []
      }

      const index = newValue.indexOf(option.value)
      if (index > -1) {
        newValue.splice(index, 1)
      } else {
        newValue.push(option.value)
      }

      this.$emit('input', newValue)
    },

    setfinishedTransition (field) {
      this.finishedTransition[`${field}Transition`] = true
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .multiSelectOptions
    display grid
    grid-template-columns minmax(0, 1fr) auto

  .divGrow
    height 0px
    transition height .9s
    &.divGrowApply
      height 102px

  .card-grid
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-column-gap 15px
    grid-row-gap 15px
    border: 1px solid rgba(0,0,0,0)

  .small
    grid-template-columns 1fr 1fr 1fr 1fr

  .large
    grid-template-columns 1fr 1fr

  .missing {
    border-color: $red
    border-radius: 10px
  }

  .missing-sub-item .selected >>> .v-input--selection-controls{
    border-color: $red
    border-radius: 10px
  }

  >>> .v-input--selection-controls {
    border: 1px solid rgba(0,0,0,0)

    .v-messages {
      display none
    }
  }

  >>> .v-icon {
    font-size: 25px !important;
  }

  >>> .v-input__slot {
    margin-bottom 0 !important
  }

  .fade-enter-active
    height 102px;
    transition: height 0.5s, border 0s, padding 0s, margin 0s, opacity 0.5s linear 0.5s

  .fade-leave-active
    height 102px;
    transition: height 1s, border 0s, padding 0s, margin 0s, opacity 0.3s

  .fade-enter, .fade-leave-to
    opacity 0
    height: 0px !important;
    padding 0px 20px !important
    margin 0px !important
    border 0px !important

  >>> .v-text-field__details
    display none

  h3
    color inherit !important

  >>> .v-textarea textarea
    margin-top 6px !important
    margin-bottom 6px !important
</style>
