<template>
  <div>
    <span :class="{ mandatory: mandatory && !readonly, light: readonly }">{{ translatedName }}</span>
    <v-text-field
      v-if="!readonly"
      solo
      flat

      :class="{ invalid }"
      @keyup.enter="validateTimeString(parseTime(inputValue))"
      @blur="validateTimeString(parseTime(inputValue))"
      v-model="inputValue"

      :placeholder="placeholder"
      :disabled="disabled"
      :append-icon="icon ? 'ic-calendar' : ''" />
    <p v-else>{{ value }}</p>
  </div>

</template>

<script>
import moment from 'moment'
import InputFieldMixin from './InputFieldMixin'

export default {
  name: 'TimeField',
  mixins: [InputFieldMixin],
  props: {
    /* Required for the v-model implementation */
    value: {
      type: String
    },
    /* Placeholder for the input field */
    placeholder: {
      type: String
    },
    /* decides if the input field is disabled */
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputValue: ''
    }
  },
  created () {
    this.inputValue = this.value
  },
  watch: {
    /* Needed for v-model implementation. Sets the value of the inputValue to the 'value' property */
    value (newVal, oldVal) {
      this.inputValue = this.value
    }
  },
  methods: {

    setLocalValue () {
      this.inputValue = this.value
    },

    /* Formats the input to HH:MM if it is valid */
    parseTime: function (timeString) {
      var time = timeString ? timeString.replace(/\s/g, '') : timeString

      if (!time) return

      if (time.includes('.') || time.includes(':') || time.includes(' ')) {
        time = time.replace(new RegExp('\\.', 'g'), ':').replace(new RegExp(' ', 'g'), ':')
        var res = time.split(':')

        res[0] = res[0].length === 1 ? '0' + res[0] : res[0]

        if (res[1]) {
          res[1] = res[1].length === 1 ? res[1] + '0' : res[1]
        } else {
          res[1] = '00'
        }

        time = res[0] + ':' + res[1]
      } else {
        if (time.length === 1) {
          time = '0' + time + ':00'
        } else if (time.length === 2) {
          time += ':00'
        } else if (time.length === 4) {
          time = time[0] + time[1] + ':' + time[2] + time[3]
        }
      }

      return time
    },
    /* Checks if a string is a valid time */
    validateTimeString: function (timeString) {
      if (this.validateTime(timeString)) {
        this.inputValue = timeString

        if (timeString !== this.value) {
          this.$emit('input', timeString)
        }
      } else {
        if (!timeString) {
          this.$emit('input', '')
          this.inputValue = ''
        } else {
          this.$emit('invalidInput')
        }
      }
    },
    /* Checks if the input is a valid time */
    validateTime: function (time) {
      // moment.js will check if it is a valid time but we need to check explicitly that it is not 24:00, which cant be done with moment.js
      if (moment(time, 'HH:mm', true).isValid() && time !== '24:00') {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input--is-disabled .v-input__slot
    background $light-gray !important

  >>> .v-text-field__details
    display none
</style>
