<template>
  <div>
    <v-card>
      <PanelDefinitionInput
        v-if="showUpdateForm"
        :update="true"
        :panel="newValue"
        @cancel="showUpdateForm = false"
        @add="update"
      />

      <div
        v-else
        class="header">
        <div class="order-buttons">
          <v-btn :disabled="disableUpButton" small icon @click="$emit('moveUp')"><v-icon>keyboard_arrow_up</v-icon></v-btn>
          <v-btn :disabled="disableDownButton" small icon @click="$emit('moveDown')"><v-icon>keyboard_arrow_down</v-icon></v-btn>
        </div>
        <h4 style="line-height: 48px;">{{ panel.key }}</h4>
        <span style="line-height: 48px;">{{ (panel.isList ? 'scopeGeneratorAdminView.layout.panel.isList' : '') | t }}</span>
        <v-btn icon @click="$emit('delete')"><v-icon>clear</v-icon></v-btn>
        <v-btn icon @click="edit"><v-icon>edit</v-icon></v-btn>
        <v-btn flat @click="add">
          <v-icon>ic-plus-circle</v-icon>
          &nbsp;
          {{ 'scopeGeneratorAdminView.layout.panel.addRow' | t }}
        </v-btn>
      </div>

      <RowDefinition
        v-for="(row, index) in panel.rows"
        :key="row.key"
        @moveUp="move(index, 1)"
        @moveDown="move(index, -1)"
        :disable-up-button="index === 0"
        :disable-down-button="index === panel.rows.length - 1"
        ref="row"
        :is-list="panel.isList"
        @changeNewField="$emit('addRowChange')"
        @delete="panel.rows.splice(index, 1)"
        :panel="panel"
        :row="row" />
    </v-card>
  </div>
</template>

<script>
import RowDefinition from './RowDefinition'
import PanelDefinitionInput from './PanelDefinitionInput'

export default {
  name: 'PanelDefinition',
  components: { PanelDefinitionInput, RowDefinition },
  props: {
    panel: {
      type: Object,
      required: true
    },
    disableUpButton: {
      type: Boolean,
      default: false
    },
    disableDownButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newRow: {
        fields: []
      },
      newRowInputVisible: false,
      newValue: {
        key: '',
        isList: false,
        itemNameTranslations: { en: '' },
        labelTranslations: { en: '' }
      },
      showUpdateForm: false
    }
  },
  methods: {
    add () {
      this.$emit('addRowChange')
      this.panel.rows.push({ ...this.newRow })
      const index = this.panel.rows.length - 1

      this.$nextTick(() => {
        this.$refs.row[index].newFieldInputVisible = true
      })

      this.cancel()
    },

    cancel () {
      this.newRowInputVisible = false
      this.newRow = {
        fields: []
      }
    },

    edit () {
      this.newValue = { ...this.panel }

      // this.newValue.labelTranslations = objectToArray(this.newValue.labelTranslations)
      this.showUpdateForm = true
    },

    update () {
      this.panel.key = this.newValue.key

      this.panel.enabledBy = this.panel.enabledBy.map(enabledBy => ({
        absolutePath: enabledBy.absolutePath ? enabledBy.absolutePath.value : null,
        relativePath: enabledBy.relativePath ? enabledBy.relativePath.value : null,
        values: enabledBy.values
      }))

      this.$set(this.panel, 'isList', this.newValue.isList)

      this.$emit('update')
      this.showUpdateForm = false
    },

    move (index, direction = 1) {
      const selectedIndex = index
      const targetIndex = index - direction

      const target = this.panel.rows[targetIndex]
      const selected = this.panel.rows[selectedIndex]

      this.$set(this.panel.rows, targetIndex, selected)
      this.$set(this.panel.rows, selectedIndex, target)
    }
  }
}
</script>

<style scoped lang="stylus">
  .header
    display grid
    grid-template-columns 50px 1fr 50px 50px 50px 200px
    align-items center

    >>> .v-icon:before
      font-size 20px
      color $dark-gray !important

  .order-buttons,
  >>> .order-buttons
    display grid
    grid-template-rows 30px 30px

    button
      margin 0
</style>
