<template>
  <div class="rfp-panel matter-active">
    <h2 style="margin-bottom: 15px">{{ 'dashboard.rfpInformation.invoiceHistoryTitle' | t }}</h2>
    <v-data-table
      must-sort
      hide-actions
      class="styled-table"
      :items="filteredRfps"
      :headers="adminHeaders"
      :custom-sort="customSort"
      :pagination.sync="pagination" >

      <template  slot="headerCell" slot-scope="props">
       <span style="color: #ababb5">{{ props.header.text | t }}</span>
      </template>

      <template v-slot:items="props">
        <tr @click="selectedRfp = props.item; showLawFirmDialog = true">
        <td @click.stop>
          <v-checkbox
            :value="props.item.corporateBilled"
            :input-value="props.item.corporateBilled"
            @change="setCorporateBilled({ rfpId: props.item.id, corporateBilled: !props.item.corporateBilled })"></v-checkbox>
        </td>
        <td>
          <v-icon v-if="props.item.lawFirmRelations.filter(relation => relation.firstBidSubmission).length && props.item.lawFirmRelations.filter(relation => relation.firstBidSubmission).every(lawFirm => lawFirm.lawfirmBilled)">check_circle</v-icon>
          <v-icon v-else>panorama_fish_eye</v-icon>
        </td>
        <td>
          <TooltipHolder
            :spanId="'m'+props.item.name + props.item.id"
            :moveLeft="-10"
            :size="20"
            :tableDataWidth="130"
            :text="props.item.name"
            tableData />
          <!-- {{ props.item.name }} -->
        </td>
        <td>{{ props.item.corporate.name }}</td>
        <td>{{ props.item.winner ? props.item.winner.name : '' }}</td>
        <td>{{ renderStatus(props.item) }}</td>
        <td>{{ auctionProgress(props.item) > RfpProgress.COMPLETED_FEEDBACK_REQUIRED ? 'Submitted' : 'Not yet Submitted' }}</td>
        <td @click.stop>
          <!-- v-text-field
            :value="props.item.invoiceAmount"
            @input="value => setInvoiceAmount({ rfpId: props.item.id, invoiceAmount: value })"
            label=""
            solo flat
          ></v-text-field -->
          <NumberField
            :value="props.item.invoiceAmount"
            :placeholder="'(' + (props.item.finalBid || '-') + ')'"
            @input="value => setInvoiceAmount({ rfpId: props.item.id, invoiceAmount: value })" />
        </td>
        <td>
          {{ props.item.terms.currency || 'EUR' }}
        </td>
        <td @click.stop>
          <DateField
            :placeholder="props.item.winnerSelectionDate ? formatTimestamp(props.item.winnerSelectionDate) : ''"
            :value="splitDateTime({ timestamp: props.item.corporateBilledDate, part: 'date' })"
            @input="value => setCorporateBilledDate({ rfpId: props.item.id, corporateBilledDate: value })"
          />
        </td>
        <td class="actionTableButtons">
          <v-btn
            v-if="props.item.invoiceUploadDate !== null && invoices(props.item).length === 1"
            :href="invoices(props.item)[0].url"
            @click.stop
            target="_blank"
            rel="noopener"
            fab depressed small >
            <v-icon style="font-size: 16px">ic-download</v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="props.item.invoiceUploadDate !== null && invoices(props.item).length !== 1"
                v-on="on"
                @click.stop
                fab depressed small >
                <v-icon style="font-size: 16px">ic-download</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile
                v-for="(invoice) in invoices(props.item)"
                :href="invoice.url"
                target="_blank"
                rel="noopener"
                :key="invoice.id">
                <v-list-tile-title>{{ invoice.fileName }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog
      v-if="showLawFirmDialog"
      @close="showLawFirmDialog = false"
      @submit="showLawFirmDialog = false"
      :showCloseBtn="false"
      :hideCancel="true"
      cancelButtonTextOrKey=""
      acceptButtonTextOrKey="dashboard.rfpInformation.lawfirmDialog.ok"
      :title="$t('dashboard.rfpInformation.lawfirmDialog.title')"
      >
      <template v-slot:custom-input>
        <v-data-table
          hide-actions
          class="styled-table"
          :items="selectedRfpLawfirmRelations"
          :headers="lawfirmRelationHeader"
          >
          <template v-slot:items="props">
            <td>
              <v-checkbox
                hide-details
                :value="props.item.lawfirmBilled"
                @change="setLawFirmBilled({
                  rfpId: selectedRfp.id,
                  lawFirmId: props.item.lawFirm.id,
                  lawFirmBilled: !props.item.lawfirmBilled
                })"
                :input-value="props.item.lawfirmBilled"></v-checkbox>
            </td>
            <td>
              {{ props.item.lawFirm.name }}
            </td>
            <td>
              {{ formatBid(props.item.participationFee) }}
            </td>
            <td>
              {{ lawFirmStatus(props.item) }}
            </td>
          </template>
        </v-data-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { adminHeaders } from './tableHeaders/adminHeaders'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import DateField from '../../generalComponents/inputFields/DateField'
import splitDateTime from '../../../util/splitDateTime'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'
import NumberField from '../../generalComponents/inputFields/NumberField'

export default {
  name: 'RfpAdminPanel',
  components: {
    NumberField,
    ConfirmDialog,
    DateField,
    TooltipHolder
  },
  data () {
    return {
      pagination: {
        sortBy: 'status',
        rowsPerPage: -1,
        descending: true
      },
      selectedRfp: null,
      showLawFirmDialog: false
    }
  },
  computed: {
    ...mapGetters(['formatBid', 'formatDateTime', 'auctionProgress', 'RfpProgress', 'formatTimestamp']),
    ...mapState({
      allRfps: state => state.allRfps
    }),

    adminHeaders () {
      return adminHeaders
    },

    filteredRfps () {
      return this.allRfps.filter(rfp => this.auctionProgress(rfp) || rfp.status === 'ACTIVE_QA')
    },

    selectedRfpLawfirmRelations () {
      return this.selectedRfp ? this.selectedRfp.lawFirmRelations.filter(relation => relation.firstBidSubmission || relation.coi2Confirmed) : []
    },

    lawfirmRelationHeader () {
      return [
        {
          text: this.$t('dashboard.rfpInformation.lawfirmDialog.table.billed'),
          align: 'left',
          sortable: false,
          value: ''
        },
        {
          text: this.$t('dashboard.rfpInformation.lawfirmDialog.table.lawfirm'),
          align: 'left',
          sortable: false,
          value: ''
        },
        {
          text: this.$t('dashboard.rfpInformation.lawfirmDialog.table.fee'),
          align: 'left',
          sortable: false,
          value: ''
        },
        {
          text: this.$t('dashboard.rfpInformation.lawfirmDialog.table.proposalTimestamp'),
          align: 'left',
          sortable: false,
          value: ''
        }
      ]
    }
  },
  methods: {
    splitDateTime,
    ...mapActions(['setCorporateBilled', 'setInvoiceAmount', 'setCorporateBilledDate', 'setLawFirmBilled']),

    lawFirmStatus (lawFirmRelation) {
      if (lawFirmRelation.status === 'COMPLETED_CONFLICTED') {
        return 'COI 1 rejected'
      }
      if (lawFirmRelation.status === 'COMPLETED_NO_CAPACITY') {
        return 'COI 2 rejected'
      }

      if (lawFirmRelation.status === 'NEW_CONFLICT_CHECK') {
        return 'OPEN'
      }

      if (lawFirmRelation.status === 'NEW_NO_CONFLICTS') {
        return 'COI 1 accepted'
      }

      if (lawFirmRelation.requiresResubmission) {
        return 'Requires Proposal Resubmission'
      }

      if (lawFirmRelation.proposalSubmitted) {
        return 'Proposal / first bid submitted'
      }

      return 'COI 2 accepted'
    },

    renderStatus (rfp) {
      const status = rfp.status
      if (status === 'ACTIVE_SECOND_BIDS_OR_AUCTION' || status === 'ACTIVE_FIRST_BIDS') {
        return this.$t('general.rfp.status.' + status + '.' + rfp.auctionType)
      }

      return this.$t('general.rfp.status.' + status)
    },

    attachmentUrl (rfp) {
      return rfp.attachments.find(attachment => attachment.rfpFileType === 'INVOICE').url
    },

    invoices (rfp) {
      return rfp.attachments.filter(attachment => attachment.rfpFileType === 'INVOICE')
    },

    viewRfp (rfp) {
      this.$router.push({
        name: 'activeRfp',
        params: {
          id: rfp.id,
          activeTab: 'Summary'
        }
      })
    },

    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        let realA
        let realB
        if (!isDesc) {
          realA = a
          realB = b
        } else {
          realA = b
          realB = a
        }
        switch (index) {
          case 'status':
            return realA[index] === 'DRAFT' ? this.compareDraftStatus(realA['draftStep'], realB['draftStep']) : this.compareStatus(realA[index], realB[index])
          case 'minBid':
            return this.compareMoney(realA[index], realB[index], isDesc)
          default:
            // console.log(realA[index].toLowerCase(), realB[index].toLowerCase(), realA[index].toLowerCase() < realB[index].toLowerCase())
            return realA[index].toLowerCase() < realB[index].toLowerCase() ? -1 : 1
        }
      })
      return items
    },

    compareStatus (statusA, statusB) {
      const statusArr = [
        'DRAFT',
        'ACTIVE_QA',
        'ACTIVE_FIRST_BIDS',
        'ACTIVE_FIRST_BIDS_CLOSED',
        'ACTIVE_SECOND_BIDS_OR_AUCTION',
        'ACTIVE_DECISION_PENDING',
        'COMPLETED_MATTER_IN_PROGRESS',
        'COMPLETED_FEEDBACK_REQUIRED',
        'COMPLETED_MATTER_CLOSED'
      ]
      // console.log('status A: ', statusA, statusArr.indexOf(statusA), 'status B: ', statusB, statusArr.indexOf(statusB))
      return statusArr.indexOf(statusA) >= statusArr.indexOf(statusB) ? 1 : -1
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../../assets/css/variables.styl"

  >>> th .v-input--checkbox
    display none

  >>> td .v-input--checkbox .v-icon
    color $gray70 !important

  .v-icon,
  >>> .v-icon {
    font-size: 24px !important;
  }

  .v-btn--floating
    height 30px
    width 30px

  .v-btn
    margin 0

  >>> tr
    height 60px

  >>> td,
  >>> th
    padding 0 12px !important

  .actionTableButtons
    max-width: 48px
    padding: 0 5px !important

  a
    text-decoration none

  >>> .v-table__overflow
    overflow unset

  >>> .v-text-field .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important
</style>
