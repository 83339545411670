<template>
  <div>
    <span class="light" :class="{ mandatory: mandatory && !readonly, light: readonly  }">{{ translatedName }}</span>
    <v-combobox
      v-if="!readonly"
      label=""
      :value="options.find(option => option.key === value) || value"
      @input="handleInput"
      :items="sortedOptions"
      :disabled="disabled"
      item-text="translatedLabel"
      item-value="key"
      :class="{ invalid }"
      return-object
      solo
      flat
    ></v-combobox>
    <p v-else>{{ readonlyValue }}</p>
  </div>
</template>

<script>
import InputFieldMixin from './InputFieldMixin'

export default {
  name: 'TagField',
  mixins: [InputFieldMixin],
  computed: {
    readonlyValue () {
      const option = this.options.find(option => this.value === option.key)
      return option ? option.translatedLabel : this.value
    }
  },
  methods: {
    handleInput (newValue) {
      const value = typeof newValue === 'string' ? newValue : newValue.key
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  span:not(.light)
    padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-text-field__details
    display none

  >>> .v-input--checkbox .v-input__slot
    border none !important

  >>> .v-input--is-disabled .v-input__slot
    background $light-gray !important

  >>> .v-icon
    color $dark-gray !important
    font-size 20px !important
    width 24px
</style>
