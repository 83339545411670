<template>
  <v-card>
    <div>
      <span class="mandatory light">{{ 'scopeGeneratorAdminView.key' | t }}</span>
      <v-text-field
        label=""
        solo flat
        :disabled="update"
        :class="{ missing: keyIsInvalid }"
        @blur="validateKey"
        @keyup.enter="validateKey"
        v-model="panel.key"></v-text-field>
    </div>
    <div style="display: grid; grid-template-columns: 50px 1fr;">
      <div>
        <span class="light">{{ 'scopeGeneratorAdminView.layout.panel.isList' | t }}</span>
        <v-checkbox
          label=""
          solo flat
          v-model="panel.isList"></v-checkbox>
      </div>
      <div v-if="panel.isList">
        <span class="light">{{ 'scopeGeneratorAdminView.layout.panel.itemNameTranslations' | t }}</span>
        <v-text-field
          label=""
          solo flat
          v-model="panel.itemNameTranslations.en"></v-text-field>
      </div>
    </div>
    <div v-for="language in ['en', 'de']" :key="language">
      <span class="light">{{ 'translations.' + language | t }}</span>
      <v-text-field
        label=""
        solo flat
        v-model="panel.labelTranslations[language]" />
    </div>

    <div class="list-attribute">
      <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledBy' | t }}</span>
      <v-btn flat @click="addEnabledByRelation">
        <v-icon>ic-plus-circle</v-icon>&nbsp;
        {{ 'scopeGeneratorAdminView.layout.field.addEnabledBy' | t }}
      </v-btn>
    </div>
    <div v-for="(enabledBy, index) in panel.enabledBy" v-bind:key="index" class="list-attribute-option enabled-by">
      <div>
        <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByField' | t }}</span>
        <v-select
          :items="enabledByApplicableFields(allFieldsInOtherPanels(panel))"
          label=""
          return-object
          solo flat
          @input="enabledBy.relativePath = null; enabledBy.values = ['CHECKBOX', 'CHECKBOX_INDENTED'].includes(enabledBy.absolutePath.type) ? [false] : []"
          v-model="enabledBy.absolutePath"></v-select>
      </div>

      <div>
        <template v-if="enabledBy.absolutePath && ['CHECKBOX', 'CHECKBOX_INDENTED'].includes(enabledBy.absolutePath.type)">
          <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByCheckbox' | t }}</span>
          <v-checkbox
            :input-value="enabledBy.values[0]"
            @change="value => enabledBy.values = [value]"
          ></v-checkbox>
        </template>
        <template v-else>
          <span class="light">{{ 'scopeGeneratorAdminView.layout.field.enabledByValue' | t }}</span>
          <v-select
            label=""
            solo flat
            multiple
            item-text="translatedLabel"
            item-value="key"
            :items="optionListForEnabledBy(enabledBy)"
            v-model="enabledBy.values"
          ></v-select>
        </template>
      </div>

      <div>
        <span></span>
        <v-btn icon @click="panel.enabledBy.splice(index, 1)"><v-icon>clear</v-icon></v-btn>
      </div>
    </div>

    <div class="actions">
      <div></div>
      <v-btn flat @click="$emit('cancel')">{{ 'buttons.cancel' | t }}</v-btn>
      <v-btn flat class="main" @click="validateAndAdd">{{ 'scopeGeneratorAdminView.layout.add' | t }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PanelDefinitionInput',
  props: {
    panel: {
      type: Object,
      required: true
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      keyIsInvalid: false
    }
  },
  computed: {
    ...mapGetters([
      'allFieldsInOtherPanels',
      'enabledByApplicableFields',
      'optionListForEnabledBy',
      'allPanels',
      'keyIsValid',
      'allFields'
    ])
  },
  methods: {
    addEnabledByRelation () {
      this.panel.enabledBy.push({
        absolutePath: '',
        relativePath: '',
        values: []
      })
    },

    validateKey () {
      this.keyIsInvalid = this.update
        ? false
        : this.allPanels.some(panel => panel.key === this.panel.key) || this.allFields.some(field => field.value === this.panel.key) || !this.keyIsValid(this.panel.key)
    },

    validateAndAdd () {
      this.validateKey()
      if (!this.panel.key) {
        this.keyIsInvalid = true
        return
      }

      if (!this.keyIsInvalid) {
        this.$emit('add')
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  .v-card
    grid-template-columns 1fr 1fr !important
    grid-column-gap 20px

  .actions
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 1fr 200px 200px

  .list-attribute
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 1fr 200px

  .list-attribute-option
    grid-column-start 1
    grid-column-end 3
    display grid
    grid-template-columns 1fr 1fr 1fr 50px
    grid-column-gap  10px

    &.is-list
      grid-template-columns 1fr 1fr 1fr 1fr 50px

    &.constraint
      grid-template-columns 1fr 1fr 50px

      &.is-list
        grid-template-columns 1fr 1fr 1fr 50px

    &.translation
      grid-template-columns 1fr 1fr 50px

    &.enabled-by
      grid-template-columns 1fr 1fr 50px

      &.is-list
        grid-template-columns 1fr 1fr 1fr 50px
</style>
