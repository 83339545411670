import { render, staticRenderFns } from "./AwaitButton.vue?vue&type=template&id=4d37f7c4&scoped=true&"
import script from "./AwaitButton.vue?vue&type=script&lang=js&"
export * from "./AwaitButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d37f7c4",
  null
  
)

export default component.exports