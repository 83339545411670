<template>
  <div>
    <div class="center-title">
      <h1>{{ 'newRFP.biddingType.title' | t }}</h1>
    </div>

    <v-divider style="margin-bottom: 67px;"></v-divider>

    <div class="content">
      <h2>{{ 'newRFP.biddingType.biddingType' | t }}</h2>

      <SelectableCardRadio
        paddings
        :disabled="!isRfpFieldEditable(true)"
        :class="{missing: missingMandatoryFields.includes('auctionType')}"
        :options="auctionTypeOptions"
        :has-tooltip="() => true"
        :tooltip-text="tooltipTextAuctionType"
        :value="currentRfp.auctionType"
        @input="handleAuctionTypeInput"
        small
        style="grid-template-columns: 1fr 1fr 1fr" />

      <h2>{{ 'newRFP.biddingType.biddingRules' | t }}</h2>
      <BiddingParametersPanel :auctionParam="auctionType" @qaChanged="toggleQA"/>

      <h2>{{ 'newRFP.biddingType.disclosureRulesTitle' | t }}</h2>
      <TooltipHolder
        class="info-icon"
        :spanId="$t('tooltips.disclosureSubtitleTooltip')"
        :width="250"
        :moveTop="30"
        :icon="'info'"
        :tooltipText="$t('tooltips.disclosureSubtitleTooltip')"
        right summaryTooltip />
      <SelectableCardRadio
        :disabled="!isRfpFieldEditable()"
        :value="currentRfp.disclosureMode"
        :options="disclosureRulesOptions"
        small
        :only-selected="currentRfp.auctionType === 'SIMPLE'"
        paddings
        @input="value =>{updateCurrentRfp({ field: 'disclosureMode', value })}"
        style="grid-template-columns: 1fr 1fr 1fr" />

      <h2>{{ 'newRFP.biddingType.nextStepsTitle' | t }}</h2>
      <section>
        <v-card>
          <v-textarea
            :disabled="!isRfpFieldEditable()"
            :value="currentRfp.nextSteps"
            :placeholder=" 'newRFP.biddingType.nextStepsPlaceholder' | t"
            @input="value => updateCurrentRfp({ field: 'nextSteps', value })"
            solo flat>
          </v-textarea>
        </v-card>

        <v-checkbox
          :label="$t('activeRFP.summarySection.interviews')"
          hide-details
          color="rgb(163, 163, 174)"
          @change="toggleInterview"
          :input-value="interviewValue"
          solo flat />
      </section>

    </div>
    <ScrollDownIndicator />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import SelectableCardRadio from '../../generalComponents/SelectableCardRadio'
import BiddingParametersPanel from './components/BiddingParametersPanel'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import ScrollDownIndicator from '../../generalComponents/ScrollDownIndicator'

export default {
  name: 'BillingType',
  components: {
    ScrollDownIndicator,
    BiddingParametersPanel,
    SelectableCardRadio,
    TooltipHolder
  },
  data () {
    return {
      billingType: '',
      auctionType: ''
    }
  },
  computed: {
    ...mapGetters(['billingTypeOptions', 'auctionTypeOptions', 'auctionSubTypeOptions', 'isAdvancedRfp', 'disclosureRulesOptions', 'currentRfpOrDraft', 'isRfpFieldEditable']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    }),
    currentRfp () {
      return this.currentRfpOrDraft
    },
    interviewValue () {
      return this.currentRfp.terms.qualityScoreCriteria.indexOf('INTERVIEW') > -1
    }
  },
  methods: {
    ...mapMutations(['updateCurrentRfp', 'updateCurrentRfpTerms', 'removeMissingMandatoryField']),

    toggleQA () {
      this.toggleQualityScoreCriterium('QA')
    },

    toggleInterview () {
      this.toggleQualityScoreCriterium('INTERVIEW')
    },

    toggleQualityScoreCriterium (criterium) {
      const value = [...this.currentRfp.terms.qualityScoreCriteria]
      const index = value.indexOf(criterium)
      if (index > -1) {
        value.splice(index, 1)
        this.updateCurrentRfpTerms({ field: 'qualityScoreCriteria', value })
      } else {
        value.push(criterium)
        this.updateCurrentRfpTerms({ field: 'qualityScoreCriteria', value })
      }
    },

    handleAuctionTypeInput (value) {
      if (value === 'SIMPLE') {
        this.updateCurrentRfp({ field: 'disclosureMode', value: 'RANK_ONLY' })
      }

      this.updateAuctionType(value)
      this.updateCurrentRfp({ field: 'auctionType', value })
      this.removeMissingMandatoryField('auctionType')
    },

    shouldShowSubOptions (option) {
      return (option.value === 'E_AUCTION' || option.value === 'ADVANCED') && this.currentRfp.auctionType === option.value
    },

    transitionConditionOptions (option) {
      return !['E_AUCTION', 'ADVANCED'].includes(option.value) && this.currentRfp.auctionType !== 'SIMPLE' && this.currentRfp.auctionType !== null
    },

    hasSubOptions (option) {
      return (option.value === 'E_AUCTION' || option.value === 'ADVANCED')
    },

    updateAuctionType (auctionVal) {
      this.auctionType = auctionVal
    },

    tooltipTextAuctionType (option) {
      switch (option.value) {
        case 'SIMPLE':
          return this.$t('tooltips.simpleRfpExplanationTooltip')
        case 'ADVANCED':
          return this.$t('tooltips.advancedRfpExplanationTooltip')
        case 'E_AUCTION':
          return this.$t('tooltips.eAuctionRfpExplanationTooltip')
      }
    },

    tooltipText (option) {
      if (option.value === 'STRICT') {
        return this.$t('newRFP.biddingType.strictTypeInfo' + (this.isAdvancedRfp ? 'Advanced' : 'EAuction'))
      } else {
        return this.$t('newRFP.biddingType.looseTypeInfo' + (this.isAdvancedRfp ? 'Advanced' : 'EAuction'))
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"
  .v-card
    border-radius 10px
    padding 26px

  h1
    display inline-block

  .center-title
    font-size 32px
    margin-bottom 1.5em
    .info-icon
      font-size 20px
      margin-left 5px
      vertical-align middle

  >>> .info-icon
    font-size 20px
    margin-left 5px
    margin-bottom 4px
    .v-icon
      font-size 20px !important
      color: #ababb5 !important
      vertical-align middle

  .content
    h2
      margin-top 2.5em
      margin-bottom 0.5em
      display inline-block
    h2:first-child
      margin-top 0

  >>> .v-textarea
    padding-top 0
    margin-top 0
    .v-input__slot
      border 1px solid $icon-gray
      margin 0
      border-radius 5px !important
    .v-input__slot:before
      border 0px

    .v-messages,.v-text-field__details
      display none

  >>> .v-input--checkbox .v-icon
    color $gray70 !important
</style>
