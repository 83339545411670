import apollo from '../../apollo'
import gql from 'graphql-tag'

import { completeRfp, lawFirmRelationJustId } from '../graphqlTags/rfpTags'

function stripRfpForUpdate (currentRfp) {
  // remove unnessecary data
  // @todo check if there is a better solution
  // delete currentRfp.__typename
  // delete currentRfp.terms.__typename

  if (currentRfp.status !== 'DRAFT') {
    delete currentRfp.name
    delete currentRfp.description
    delete currentRfp.clientName
    delete currentRfp.coi1
  }

  delete currentRfp.winner
  delete currentRfp.qaQuestions
  delete currentRfp.scopeGeneratorLayoutsBySubject
  delete currentRfp.proposalScoreWeight
  delete currentRfp.activeLawFirms
  delete currentRfp.corporate
  delete currentRfp.decisionNotes
  delete currentRfp.winnerMessage
  delete currentRfp.loserMessage
  delete currentRfp.winnerSelectionDate
  delete currentRfp.abortionMessage
  delete currentRfp.statusPreAbortion
  delete currentRfp.minBid
  delete currentRfp.version
  delete currentRfp.submissionMessage
  delete currentRfp.teamScoreWeight
  delete currentRfp.credentialsScoreWeight
  delete currentRfp.liabilityScoreWeight
  delete currentRfp.pitchDocumentScoreWeight
  delete currentRfp.qaScoreWeight
  delete currentRfp.interviewScoreWeight
  delete currentRfp.strategyScoreWeight
  delete currentRfp.custom1ScoreWeight
  delete currentRfp.custom2ScoreWeight
  delete currentRfp.custom3ScoreWeight

  delete currentRfp.team
  delete currentRfp.shareLiveFeedback
  for (let attachment of currentRfp.attachments) {
    delete attachment.url
    delete attachment.fileName
    delete attachment.subject
    delete attachment.createdAt
    delete attachment.__typename
    delete attachment.rfpFileType
    delete attachment.originalFileId

    attachment[currentRfp.status === 'DRAFT' ? 'rfp' : 'historyRfp'] = {
      id: currentRfp.id
    }
  }

  currentRfp.subjects = currentRfp.subjects.map(({ key }) => ({ key }))

  currentRfp.lawFirmRelations = currentRfp.lawFirmRelations.map(relation => {
    console.log('relation', relation)
    if (currentRfp.status === 'DRAFT') {
      return {
        rfp: {
          id: relation.rfp.id
        },
        lawFirm: {
          id: relation.lawFirm.id
        },
        requestedLawyers: relation.requestedLawyers.map(requestedLawyer => ({ id: requestedLawyer.id }))
      }
    } else {
      return {
        historyRfp: {
          id: currentRfp.id
        },
        lawFirm: {
          id: relation.lawFirm.id
        },
        requestedLawyers: relation.requestedLawyers.map(requestedLawyer => ({ id: requestedLawyer.id })),
        disabled: relation.disabled,
        disabledRequestedLawyerIds: relation.disabledRequestedLawyerIds
      }
    }
  })
}
export default {

  async createNewRfp ({ commit, getters, state }, { newRfp, templateRfpId }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation createRfp($createRfpInput: CreateRfpInput!, $templateRfpId: ID) {
          createRfp (createRfpInput: $createRfpInput, templateRfpId: $templateRfpId) {
            ${completeRfp(getters.userIsLawFirm)}
          }
        }
      `,
      variables: {
        createRfpInput: {
          name: newRfp.name,
          mandateType: newRfp.mandateType,
          mandateSubType: newRfp.mandateSubType,
          subjects: newRfp.subjects,
          description: newRfp.description,
          clientName: newRfp.clientName,
          coi1: newRfp.coi1
        },
        templateRfpId: templateRfpId
      }
    })

    const result = response.data.createRfp
    commit('addNewRfpToList', result)

    return result.id
  },

  async saveCurrentRfp ({ state, getters, commit }) {
    commit('updateCurrentRfp', { field: 'draftStep', value: getters.draftStep })
    let currentRfp
    if (state.currentRfp.status === 'DRAFT') {
      currentRfp = JSON.parse(JSON.stringify(state.currentRfp))
    } else {
      currentRfp = JSON.parse(JSON.stringify(state.rfpDraft))
    }
    stripRfpForUpdate(currentRfp)

    if (currentRfp.status === 'DRAFT') {
      await apollo(state).mutate({
        mutation: gql`
          mutation updateRfp($rfpInput: UpdateRfpInput!) {
            updateRfp (rfpInput: $rfpInput) {
              id
            }
          }
        `,
        variables: {
          rfpInput: currentRfp
        }
      })
    } else {
      // delete currentRfp.lawFirmRelations
      await apollo(state).mutate({
        mutation: gql`
          mutation updateRfpDraft($rfpInput: UpdateRfpHistoryInput!) {
            updateRfpDraft (rfpInput: $rfpInput) {
              id
            }
          }
        `,
        variables: {
          rfpInput: currentRfp
        }
      })
    }
    if (state.setHasChangedSubject === true) {
      commit('setHasChangedSubject', false)
    }
  },

  async distributeRfp ({ getters, state }, rfpId) {
    await apollo(state).mutate({
      mutation: gql`
        mutation distributeRfp($id: ID!) {
          distributeRfp (id: $id) {
            id
          }
        }
      `,
      variables: {
        id: rfpId
      }
    })
  },

  async redistributeRfp ({ getters, state }, { rfpId, submissionMessage }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation redistributeRfp($id: ID!, $submissionMessage: String) {
          redistributeRfp (id: $id, submissionMessage: $submissionMessage)
        }
      `,
      variables: {
        id: rfpId,
        submissionMessage: submissionMessage
      }
    })
  },

  async uploadFile ({ state, commit, getters }, { file, type, qualityScoreCriterium, proposalCategorySubItemId, subject }) {
    const currentRfpId = state.currentRfp.id
    const data = new FormData()
    data.append('file', file)
    data.append('type', type)

    if (type === 'PROPOSAL_CATEGORY') {
      data.append('qualityScoreCriterium', qualityScoreCriterium)
    } else if (type === 'PROPOSAL_CATEGORY_SUB_ITEM') {
      data.append('proposalCategorySubItemId', proposalCategorySubItemId)
    }

    if (subject) {
      data.append('subject', subject)
    }

    const request = {
      method: 'POST',
      body: data,
      enctype: 'multipart/form-data',
      headers: { 'x-auth-token': state.authToken }
    }

    let url = '/rest/rfp/' + currentRfpId + '/files/upload'
    if (state.currentRfp.status !== 'DRAFT' && ['PITCH_DOCUMENT_DEFINITION', 'TERMS', 'SCOPE', 'SCOPE_GENERATOR'].includes(type)) {
      url = '/rest/rfp/history/' + getters.currentRfpOrDraft.id + '/files/upload'
    }
    const response = await fetch(url, request)
    if (!response.ok) {
      commit('setSpecialErrorMessage', 'Please note that files may only take up to 20MB')
      commit('setShowDefaultError', true)
      throw new Error()
    }
    const newAttachments = await response.json()
    if (type === 'PROPOSAL_CATEGORY') {
      commit('addPitchDocumentAttachment', { attachment: newAttachments })
      commit('removeMissingMandatoryField', 'PITCH_DOCUMENT')
    } else if (type === 'PROPOSAL_CATEGORY_SUB_ITEM') {
      return newAttachments // commit('addTeamMemberAttachment', { attachment: newAttachments, teamMemberId: proposalCategorySubItemId })
    } else {
      commit('addRfpAttachment', newAttachments)
    }
    return newAttachments
  },

  async acceptRejectCoi1 ({ state, commit, dispatch }, { rfpId, decision }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation acceptDeclineCoi1 ($rfpId: ID!, $accept: Boolean) {
          acceptDeclineCoi1 (rfpId: $rfpId, accept: $accept) {
            ${lawFirmRelationJustId(true)}
          }
        }
      `,
      variables: {
        rfpId: rfpId,
        accept: decision
      }
    })

    commit('updateLawFirmRelation', response.data.acceptDeclineCoi1)
  },

  async acceptDeclineCoi2 ({ commit, dispatch, state }, { rfpId, decision, rejectReason }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation acceptDeclineCoi2 ($rfpId: ID!, $accept: Boolean, $coi2RejectionReason: String) {
          acceptDeclineCoi2 (rfpId: $rfpId, accept: $accept, coi2RejectionReason: $coi2RejectionReason) {
            ${lawFirmRelationJustId(true)}
          }
        }
      `,
      variables: {
        rfpId: rfpId,
        accept: decision,
        rejectReason: rejectReason
      }
    })
  },

  async searchRequestedLawyers ({ commit, state }, { search, lawFirmId }) {
    const response = await apollo(state).query({
      query: gql`
        query lawFirmUsers ($lawFirmId: ID!, $search: String!) {
          lawFirmUsers (lawFirmId: $lawFirmId, search: $search) {
            id
            fullName
            invitePending
          }
        }
      `,
      variables: {
        search,
        lawFirmId
      }
    })

    commit('setRequestLawyerSearchResult', response.data.lawFirmUsers)
  },

  async deleteFile ({ commit, state, getters }, { rfpId, fileId, type }) {
    let url = `/rest/rfp/${rfpId}/files/${fileId}/delete`
    if (state.currentRfp.status !== 'DRAFT' && type && ['PITCH_DOCUMENT_DEFINITION', 'TERMS', 'SCOPE', 'SCOPE_GENERATOR'].includes(type)) {
      url = '/rest/rfp/history/' + getters.currentRfpOrDraft.id + `/files/${fileId}/delete`
    }
    const request = {
      method: 'POST',
      enctype: 'multipart/form-data',
      headers: {
        'x-auth-token': state.authToken
      }
    }

    await fetch(url, request)

    commit('deleteDocument', fileId)
  }

}
