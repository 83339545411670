import Vue from 'vue'
import Vuex from 'vuex'

import state from './vuex/state'

import dropDownListActions from './vuex/actions/dropDownListActions'
import dropDownListMutations from './vuex/mutations/dropDownListMutations'
import dropDownListGetters from './vuex/getters/dropDownListGetters'

import newRfpActions from './vuex/actions/newRfpActions'
import newRfpMutations from './vuex/mutations/newRfpMutations'

import generalRfpActions from './vuex/actions/generalRfpActions'
import generalRfpMutations from './vuex/mutations/generalRfpMutations'

import lawFirmMutations from './vuex/mutations/lawFirmMutations'
import lawFirmActions from './vuex/actions/lawFirmActions'

import loginActions from './vuex/actions/loginActions'
import loginMutations from './vuex/mutations/loginMutations'
import loginGetters from './vuex/getters/loginGetters'
import biddingActions from './vuex/actions/biddingActions'
import biddingMutations from './vuex/mutations/biddingMutations'
import generalUiMutations from './vuex/mutations/generalUiMutations'
import callbackMutations from './vuex/mutations/callbackMutations'
import validationMutations from './vuex/mutations/validationMutations'
import validationActions from './vuex/actions/validationActions'
import validationGetters from './vuex/getters/validationGetters'
import userActions from './vuex/actions/userActions'
import userMutations from './vuex/mutations/userMutations'
import corporateMutations from './vuex/mutations/corporateMutations'
import corporateActions from './vuex/actions/corporateActions'
import biddingGetters from './vuex/getters/biddingGetters'
import errorMutations from './vuex/mutations/errorMutations'
import qaActions from './vuex/actions/qaActions'
import qaMutations from './vuex/mutations/qaMutations'
import proposalActions from './vuex/actions/proposalActions'
import proposalMutations from './vuex/mutations/proposalMutations'
import teamActions from './vuex/actions/teamActions'
import teamMutations from './vuex/mutations/teamMutations'
import proposalGetters from './vuex/getters/proposalGetters'
import numberGetters from './vuex/getters/numberGetters'
import notificationMutations from './vuex/mutations/notificationMutations'
import notificationActions from './vuex/actions/notificationActions'
import decisionMutations from './vuex/mutations/decisionMutations'
import decisionActions from './vuex/actions/decisionActions'
import inputMutations from './vuex/mutations/inputMutations'
import inputActions from './vuex/actions/inputActions'
import inputGetters from './vuex/getters/inputGetters'
import scopeGeneratorAdminActions from './vuex/actions/scopeGeneratorAdminActions'
import scopeGeneratorAdminMutations from './vuex/mutations/scopeGeneratorAdminMutations'
import scopeGeneratorAdminGetters from './vuex/getters/scopeGeneratorAdminGetters'
import statusGetters from './vuex/getters/statusGetters'
import feedbackActions from './vuex/actions/feedbackActions'
import feedbackMutations from './vuex/mutations/feedbackMutations'
import analyticsActions from './vuex/actions/analyticsActions'
import analyticsMutations from './vuex/mutations/analyticsMutations'
import notificationGetters from './vuex/getters/notificationGetters'
import displayGetters from './vuex/getters/displayGetters'
import adminActions from './vuex/actions/adminActions'
import adminMutations from './vuex/mutations/adminMutations'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: state.defaultState(),
  mutations: {
    ...dropDownListMutations,
    ...newRfpMutations,
    ...generalRfpMutations,
    ...lawFirmMutations,
    ...loginMutations,
    ...biddingMutations,
    ...generalUiMutations,
    ...callbackMutations,
    ...validationMutations,
    ...userMutations,
    ...corporateMutations,
    ...errorMutations,
    ...qaMutations,
    ...proposalMutations,
    ...teamMutations,
    ...notificationMutations,
    ...decisionMutations,
    ...inputMutations,
    ...scopeGeneratorAdminMutations,
    ...feedbackMutations,
    ...analyticsMutations,
    ...adminMutations
  },
  actions: {
    ...dropDownListActions,
    ...newRfpActions,
    ...generalRfpActions,
    ...lawFirmActions,
    ...loginActions,
    ...biddingActions,
    ...validationActions,
    ...userActions,
    ...corporateActions,
    ...qaActions,
    ...proposalActions,
    ...teamActions,
    ...notificationActions,
    ...decisionActions,
    ...inputActions,
    ...scopeGeneratorAdminActions,
    ...feedbackActions,
    ...analyticsActions,
    ...adminActions
  },
  getters: {
    ...dropDownListGetters,
    ...loginGetters,
    ...validationGetters,
    ...biddingGetters,
    ...proposalGetters,
    ...numberGetters,
    ...inputGetters,
    ...scopeGeneratorAdminGetters,
    ...statusGetters,
    ...notificationGetters,
    ...displayGetters
  }
})

store.commit('setShowNotOnlineError', !navigator.onLine)
if (navigator.connection) {
  navigator.connection.onchange = function () {
    store.commit('setShowNotOnlineError', !navigator.onLine)
  }
}

export default store
