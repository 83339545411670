import Vue from 'vue'

export default {

  currentUser (state, currentUser) {
    Vue.set(state, 'currentUser', currentUser)
  },

  setAllUsers (state, allUsers) {
    Vue.set(state.admin, 'allUsers', allUsers)
  },

  setRequiresPasswordChange (state, value) {
    Vue.set(state.userData, 'requiresPasswordChange', value)
  }

}
