import { render, staticRenderFns } from "./SelectableCard.vue?vue&type=template&id=a95207ee&scoped=true&"
import script from "./SelectableCard.vue?vue&type=script&lang=js&"
export * from "./SelectableCard.vue?vue&type=script&lang=js&"
import style0 from "./SelectableCard.vue?vue&type=style&index=0&id=a95207ee&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a95207ee",
  null
  
)

export default component.exports