export default {
  // format the number as a number separated by points
  formatNumber: () => (numberString) => {
    if (numberString || numberString === 0) {
      return String(numberString).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return ''
    }
  },

  // Parse Currency Values into comma separated e.g 1,000 K
  roundBidToThousands: () => (bid) => {
    if (bid) {
      return Math.trunc(bid / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return '-'
    }
  }

}
