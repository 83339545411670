export default {
  defaultState () {
    return {
      authToken: null, // token to authenticate backend calls

      showFileSizeDialog: false,
      hasChangedSubject: false,

      proposal: {
        teamMemberLoaded: false,
        teamMember: []
      },

      alert: {
        type: 'submitted',
        visible: false,
        rfpName: '',
        to: ''
      },

      analytics: [],
      analyticsCurrency: 'EUR',

      optionLists: [],
      allRfps: [],
      currentRfp: null,
      currentSubject: null,
      rfpDraft: null,
      history: [],

      lawFirms: [],
      corporates: [],

      userData: {},
      currentUser: {},
      mfaMethod: null,

      auction: {
        bids: [],
        subscription: null,
        usersLastBid: null,
        showNotificationPanel: false
      },

      callbacks: {
        afterUserDataLoaded: []
      },

      validation: {
        missingMandatoryFields: [],
        invalidFields: [],
        /**
         * Object that tracks the validation status of the fields in the current view and can be used to display
         * warnings and red borders around fields, panels and the sidebar. This contains all contraint types except
         * "Mandatory" and can be updated live (on blur). Structure:
         * { SubjectKey.SectionKey.PanelKey.Field : ['GreaterThan'],
         *   SubjectKey.SectionKey.ListPanelKey.ListItemShortId.Field: ['MaxDouble']
         * }
         *
         * Currently only used by the scope generator.
         */
        constraintViolations: {},

        /**
         * Same structure as above but only contains mandatory violations. Should only be populated upon clicking
         * "Save and Continue" and can be selectively reduced when single fields are filled.
         */
        mandatoryViolations: {},

        /**
         * Same structure as above but only contains invalid fields with internal validations e.g. number or date fields
         */
        internalViolations: {}
      },

      admin: {
        allUsers: [],
        scopeGenerator: {
          subjects: [],
          currentSubject: null,
          rfpStatistics: [],
          rfpStatisticsFieldNames: []
        }
      },

      error: {
        showDefaultError: false,
        specialErrorMessage: null,
        showNotOnlineError: false,
        showSubscriptionError: false,
        showSubscriptionLostError: false,
        noWebsocketSupport: false,
        operation: null,
        httpStatusEquivalent: null
      },

      notifications: {
        notifications: [],
        notificationSubscription: null
      },

      openLawFirmRelation: null,

      requestLawyerSearchResult: [],

      bidding: {

      },

      proposalHasUnsavedChanges: false,
      draftProposal: {
        updateMode: false,
        credentials: null,
        newCredential: null,
        showNewCredentialInputForm: false,
        team: null,
        liability: null,
        editLiability: false,
        newLiability: false,
        editFirstBid: false
      },
      historyRfpVersion: 0
    }
  }
}
