<template>
  <div class="summary-panel">
    <LawFirmTable
      only-selected
      disabled
      hide-icons
      :currentRfp="currentRfp"
      class="law-firm-table break-after-print"
      style="margin-top: -5px;" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LawFirmTable from '../../../newRfpCorporate/lawFirmSelection/LawFirmTable'

export default {
  name: 'LawFirmPanel',
  components: {
    LawFirmTable
  },
  props: {
    currentRfp: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      lawFirms: state => state.lawFirms
    }),

    selectedLawFirms () {
      return this.currentRfp.lawFirmRelations.map(lawFirmRelation => {
        return this.lawFirms.find(lawFirm => lawFirm.id === lawFirmRelation.lawFirm.id)
      })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  .icon
    width 35px

    .v-icon
      color $gold100 !important
      font-size 20px

  .name
    font-size 17px
    color $dark-gray
    text-align left
    font-weight bold

  table
    padding-left: 200px
    tr
      td
        padding 8px 0px
        width 200px
        text-transform: capitalize

  .law-firm-table >>> h3
    color $gray70 !important
    font-size 16px !important
    font-weight bold

</style>
