import Vue from 'vue'

export default {

  addTeamMember (state, { userId, permissionLevel, hideInProposal }) {
    let newTeam = state.currentRfp.team
    newTeam.push({
      rfp: {
        id: state.currentRfp.id
      },
      lintumUser: {
        id: userId
      },
      level: permissionLevel,
      hideInProposal: hideInProposal
    })
    Vue.set(state.currentRfp, 'team', newTeam)
  },

  removeRfpTeamMember (state, user) {
    const newTeam = state.currentRfp.team
    const index = newTeam.findIndex(member => member.lintumUser.id === user.id)

    if (index > -1) {
      newTeam.splice(index, 1)
      Vue.set(state.currentRfp, 'team', newTeam)
    }
  }

}
