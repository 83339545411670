<template>
  <v-app v-if="userData.username || isLoginPage()">
    <ErrorDialog />
    <ConfirmDialog
      v-if="showFileSizeDialog"
      :title="$t('general.fileUpload.title')"
      :text="$t('general.fileUpload.text')"
      hide-cancel
      :accept-button-text-or-key="$t('general.fileUpload.ok')"
      @submit="$store.state.showFileSizeDialog = false"
      @close="$store.state.showFileSizeDialog = false"
    />
    <Header v-if="!isLoginPage()"/>
    <v-content :class="{login: isLoginPage() || isPageNotFound()}">
      <v-alert
        :value="(showNotOnlineError || noWebsocketSupport) && $route.name !== 'login'"
        class="lintum-alert"
        icon="error"
        style="margin-top: -15px; margin-bottom: 15px;">
        <span v-html="$t(showNotOnlineError ? 'error.offline' : 'error.noWebsocketSupport')"></span>
      </v-alert>
      <router-view :key="key"/>
    </v-content>
    <PasswordResetDialog v-if="userData.requiresPasswordChange && $route.name !== 'login'" visible />
  </v-app>
</template>

<script>
import Header from './views/generalComponents/Header'
import { mapActions, mapState } from 'vuex'
import ErrorDialog from './views/generalComponents/ErrorDialog'
import PasswordResetDialog from './views/dashboard/components/PasswordResetDialog'
import ConfirmDialog from './views/generalComponents/ConfirmDialog'
import debounce from 'lodash.debounce'

require('./assets/css/lintum-icons.css')

export default {
  name: 'App',
  components: {
    ConfirmDialog,
    PasswordResetDialog,
    ErrorDialog,
    Header
  },
  data () {
    return {
      key: Date.now()
    }
  },
  created () {
    window.addEventListener('message', async (event) => {
      if (event.data === 'LIN_LOGIN_RESTORED') {
        await this.restoreTokenFromCookie()

        if (['rfp', 'allRfps'].includes(this.errorOperation)) {
          this.key = Date.now()
        }
      }
    })

    if (!this.isLoginPage()) {
      this.restoreTokenFromCookie()
    }
    // keep session alive
    const debouncedKeepAlive = debounce(this.keepLoginAlive, 10000, { maxWait: 10000, leading: true })
    document.addEventListener('mousemove', debouncedKeepAlive)
    document.addEventListener('keydown', debouncedKeepAlive)

    this.loadOptionLists(true)
  },
  computed: {
    ...mapState({
      userData: state => state.userData,
      isOnline: state => state.isOnline,
      showNotOnlineError: state => state.error.showNotOnlineError,
      noWebsocketSupport: state => state.error.noWebsocketSupport,
      errorOperation: state => state.error.operation,
      showFileSizeDialog: state => state.showFileSizeDialog
    })
  },
  methods: {
    ...mapActions(['restoreTokenFromCookie', 'loadOptionLists', 'keepLoginAlive']),
    isLoginPage () {
      return window.location.pathname === '/login' || (this.$route.name && this.$route.name === 'login') || window.location.pathname === '/signup' || (this.$route.name && this.$route.name === 'signUp') || window.location.pathname === '/reset-password' || (this.$route.name && this.$route.name === 'resetPassword') || window.location.pathname === '/cookie-declaration' || (this.$route.name && this.$route.name === 'cookieDeclaration')
    },
    isPageNotFound () {
      return window.location.pathname === '/pageNotFound' || (this.$route.name && this.$route.name === 'pageNotFound')
    }
  }
}
</script>

<style>
  @import "../src/assets/css/global-style.css";

  span.light {
    color: #ababb5
  }

  @media print {
    .breadcrumbs,
    .full-title,
    .rfpProgress,
    .tabs-panel.tabs,
    .side-nav,
    nav.v-toolbar,
    .gently-flashing-arrow {
      display: none !important;
    }

    .main {
      grid-template-columns: 1fr !important;
    }

    main.v-content {
      padding: 0 !important;
    }
  }
</style>

<style lang="stylus">
  @import "/assets/css/variables.styl"

  $border-radius = 5px

  .application a[disabled]
    cursor auto !important

  *
    color $dark-gray
    font-family: 'Source Sans Pro', sans-serif

  h1 {
    font-size 32px
    font-weight bold
  }

  h2 {
    font-size 22px
    font-weight bold
  }

  h3 {
    font-size 18px
    font-weight bold
  }

  h4 {
    font-size 14px
    font-weight bold
  }

  p, label {
    font-size 16px

    &.description {
      font-size 12px
    }
  }

  span {
    font-size 12px
  }

  .show-whitespace {
    white-space pre
  }

  div a.active
    color $dark-gray !important
    text-decoration underline

  .v-toolbar
    background-color #ffffff !important

  .v-toolbar__content
    max-width $max-width
    margin 0 auto

  .v-content__wrap
    max-width $max-width
    margin 0 auto
    padding-top 2.5em
    padding-bottom 6em

  .login > .v-content__wrap
    max-width none !important
    padding 0

  .v-content
    background $light-gray !important

  .v-icon
    color $icon-gray !important
    font-size 30px

    &:before
      color $icon-gray !important

  .v-btn.main:not([disabled])
    background-color $maroon100 !important
    color #ffffff
    border-radius 5px

  .mandatory:after
    content '\0000a0*'
    color red

  .styled-table table
    background none !important
    border-collapse separate
    border-spacing 0 5px
    table-layout: fixed;

    tbody
      tr
        margin-top 10px

        td
          background #ffffff

          &:first-child
            border-top-left-radius $border-radius
            border-bottom-left-radius $border-radius

          &:last-child
            border-top-right-radius $border-radius
            border-bottom-right-radius $border-radius

  .missing .v-input__slot {
    border-color: $red !important
    border-width: 2px !important
  }

  .breadcrumbs
    padding-left 15px

    span:first-child
      color $icon-gray

      a
        color: $icon-gray
        text-decoration none

        &:hover {
          text-decoration underline
        }

    span:not(:first-child)
      color $dark-gray
      font-weight bold

      &.project-name
        text-decoration underline

  .lintum-alert {
    &.error {
      background-color: $icon-gray !important
    }

    background-color: $icon-gray !important
    border-radius: 10px
    border: none
    width: 70% !important

    > div {
      background-color: $icon-gray !important
      color: white !important
      margin-left: 3em;

      * {
        color: white !important
      }

      span {
        font-size: 16px
      }

      a {
        text-decoration none
        color: white !important
      }
    }

    .v-icon {
      color: white !important
    }
  }

  .mce-widget.mce-tooltip {
    display: none !important;
  }

  table.v-table thead tr {
    height: 20px;
  }

  table.v-table tbody tr td
    max-width 135px

  .v-btn {
    text-transform none !important
  }

</style>
