<template>
  <div class="dashboard">
    <v-alert
      :value="alert.visible"
      @input="setAlertVisible"
      class="lintum-alert"
      dismissible
      icon="check_circle">
      <span v-if="alert.type === 'submitted'">
        <span v-if="submittedNotificationStrings[0]">{{ submittedNotificationStrings[0] }}</span>
        <b><router-link :to="alert.to">{{alert.rfpName}}</router-link></b>
        <span v-if="submittedNotificationStrings[1]">{{ submittedNotificationStrings[1] }}</span>
      </span>
      <span v-if="alert.type === 'coiIIaccepted'">
        <span v-if="coiIIacceptedStrings[0]">{{ coiIIacceptedStrings[0] }}</span>
        <b><router-link :to="alert.to">{{alert.rfpName}}</router-link></b>
        <span v-if="coiIIacceptedStrings[1]">{{ coiIIacceptedStrings[1] }}</span>
      </span>
      <span v-if="alert.type === 'coiIIdeclined'">
        {{ $t('dashboard.alerts.coiDeclinedAlert', { rfpName: alert.rfpName }) }}
      </span>
    </v-alert>
    <template v-if="!userIsAdmin">
        <Analytics />
        <section class="rfp-toggle">
          <h1>{{ `dashboard.header.${showMatter ? 'mattersLabel' : 'requestTitle'}` | t }}</h1>
          <div class="btn-toggle" style="display: flex; justify-content: flex-end" >
            <v-btn
              class="leftBtn"
              :class="{active: !showMatter}"
              @click="$router.push({ name: 'dashboardRfps'})"
              depressed>
              {{ 'dashboard.header.rfpsLabel' | t }}
            </v-btn>
            <v-btn
              class="rightBtn"
              :class="{active: showMatter}"
              @click="$router.push({ name: 'dashboardMatters'})"
              depressed>
              {{ 'dashboard.header.mattersLabel' | t }}
            </v-btn>
          </div>
        </section>
        <v-divider></v-divider>
      <template v-if="!showMatter">
        <NewRfpDialog v-model="createNewRfpVisible"/>
        <RfpPanel rfp-status="draft" :notificationRfp="showNotificationRfp" @showCreateNewRfpDialog="createNewRfpVisible = true"/>
        <RfpPanel rfp-status="active" />
        <RfpPanel rfp-status="completed" />
      </template>
      <template v-else>
        <RfpPanel rfp-status="matter-active" />
        <RfpPanel rfp-status="matter-completed" />
      </template>
    </template>
    <template v-else>
      <RfpAdminPanel style="margin-left: -75px" />
    </template>
  </div>
</template>

<script>
import RfpPanel from './components/RfpPanel'
import RfpAdminPanel from './components/RfpAdminPanel'
import Analytics from './components/Analytics'
import NewRfpDialog from './components/NewRfpDialog'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import shortid from 'shortid'

export default {
  name: 'DashboardVue',
  components: { NewRfpDialog, Analytics, RfpPanel, RfpAdminPanel },
  data () {
    return {
      createNewRfpVisible: false,
      showNotificationRfp: false
    }
  },
  watch: {
    /* Watcher for the route in the case the notification is clicked in the dashboard component */
    $route (to, from) {
      if (to.query.id) {
        this.showNotificationRfp = true
      } else {
        this.showNotificationRfp = false
      }
    }
  },
  created () {
    this.setCurrentRfp(null)
    if (this.$route.name === 'index') {
      this.$router.push({ name: 'dashboardRfps' })
    }
    this.loadAllRfps()
    // loads the rfp in the case the component was created from the notification
    if (this.$route.query.id) {
      this.setAndLoadRfp(this.$route.query.id)
    }
  },
  computed: {
    ...mapGetters(['userIsAdmin', 'getStringsArray']),
    ...mapState({
      alert: state => state.alert
    }),

    showMatter () {
      return this.$route.meta.type === 'matters'
    },

    submittedNotificationStrings () {
      const id = shortid.generate()
      return this.getStringsArray('dashboard.alerts.rfpSubmitted', { rfpName: id }, id)
    },

    coiIIacceptedStrings () {
      const id = shortid.generate()
      return this.getStringsArray('dashboard.alerts.coiAcceptedAlert', { rfpName: id }, id)
    }
  },
  methods: {
    ...mapActions(['loadAllRfps', 'loadRfp']),
    ...mapMutations(['setAlertVisible', 'setCurrentRfp']),

    async setAndLoadRfp (rfpId) {
      await this.loadRfp({ rfpId })
      this.showNotificationRfp = true
    }
  },
  destroyed () {
    this.setAlertVisible(false)
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  >>> .rfp-panel
    margin-top 2em
    margin-bottom 60px
    &.matter-active
      margin-top 0px

    .header
      display grid
      grid-template-columns 1fr 200px
      height 48px

  >>>.v-input--selection-controls
    margin 0
    padding 0
    display flex
    align-items center
    justify-content flex-end

  >>> .v-input__slot
    margin-bottom 0 !important

  >>>.v-input--selection-controls__input
    margin-left 18px
    margin-right 14px

  >>> .v-messages
    display none

  .rfp-toggle
    display grid
    grid-template-columns repeat(2, auto)
    padding-bottom 15px

  .btn-toggle >>> button
    margin-right 0
    margin-left 0
    border-radius 5px
    border solid grey 1px
    width 125px
    &.leftBtn
      border-top-right-radius 0px
      border-bottom-right-radius 0px

    &.rightBtn
      border-top-left-radius 0px
      border-bottom-left-radius 0px
      border-left: 0

    &.active
      color white
      background-color: $maroon100 !important
</style>
