<template>
  <div class="side-nav">
    <div :class="{fixed: isOnPosition}" v-on:scroll="handleScroll">
      <div v-for="(options, index) in totalOptions" :key="index">
        <a
          @click="clickTab(options.idRef)"
          :class="{
            subItem: options.isSubField,
            activeSide: (options.status && !options.isSubField),
            'elevation-2': (options.status && !options.isSubField),
            invalid: (options.classStatus === 'invalid' && !options.isSubField),
            finished: options.classStatus === 'finished'
          }"
          :id="options.idRef" >
          <v-icon v-if="!options.isSubField">{{ options.icon || 'info' }}</v-icon>
          <span
            class="labelSpan"
            :class="{
              subItemLabel: options.isSubField,
              activeSubItem: options.status,
              activeInvalidSub: options.status && options.classStatus === 'invalid',
              invalidSubItem: (options.classStatus === 'invalid' && options.isSubField)
            }">
            {{ options.name | t }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'sidebar',
  data () {
    return {
      isOnPosition: false,
      totalOptions: [],
      totalComponents: []
    }
  },
  props: {
    isNewRfpSummary: {
      type: Boolean,
      default: false,
      required: false
    },
    isCOICheck: {
      type: Boolean,
      default: false,
      required: false
    },
    alertOffset: {
      type: Boolean,
      default: false,
      required: false
    },
    optionsArray: {
      required: false,
      type: Array
    },
    componentsArray: {
      required: true
    },
    terms: {
      required: false,
      default: false,
      type: Boolean
    },
    customOffset: {
      type: Number,
      default: 0
    }
  },
  watch: {
    optionsArray () {
      if (this.totalOptions.length === this.optionsArray.length) {
        this.totalOptions = this.optionsArray.map((sideObj, index) => {
          sideObj.status = this.totalOptions[index].status
          return sideObj
        })
      } else {
        this.totalOptions = this.optionsArray
      }
    },

    componentsArray () {
      this.totalComponents = this.componentsArray
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    this.totalOptions = this.optionsArray
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapGetters(['userIsCorporate', 'currentRfpOrDraft']),

    currentRfp () {
      return this.currentRfpOrDraft
    }
  },
  methods: {
    handleScroll (event) {
      const alertHeight = this.alertOffset ? 54 : 0
      const generalHeight = (this.isNewRfpSummary || this.isCOICheck) ? 130 : (this.terms ? 195 : 224)
      let totalHeight = generalHeight + alertHeight + this.customOffset
      if (window.scrollY >= (totalHeight)) {
        this.isOnPosition = true
      } else {
        this.isOnPosition = false
      }
      this.activeTab()
    },

    clickTab (id) {
      const tabIndex = this.optionsArray.findIndex(options => options.idRef === id)
      const actualPos = this.componentsArray[tabIndex].getBoundingClientRect().top
      const scrollingPix = actualPos - 70

      window.scrollBy({ top: scrollingPix, left: 0, behavior: 'smooth' })
    },

    activeTab () {
      this.totalComponents.forEach((component, index) => {
        if (index !== this.totalComponents.length - 1) {
          if (component.getBoundingClientRect().top < this.totalOptions[index].top && this.totalComponents[index + 1].getBoundingClientRect().top >= this.totalOptions[index + 1].top) {
            // console.log('this is', this.totalOptions[index].idRef, component.getBoundingClientRect().top, this.totalOptions[index].top, this.totalComponents[index + 1].getBoundingClientRect().top)
            this.setTab(this.totalOptions[index])
          }
        } else {
          if (component.getBoundingClientRect().top < this.totalOptions[index].top) {
            // console.log('last child', this.totalOptions[index].idRef)
            this.setTab(this.totalOptions[index])
          }
        }
      })
    },

    setTab (tab) {
      this.totalOptions = this.totalOptions.map(compObj => {
        if (compObj.idRef === tab.idRef) {
          compObj.status = true
        } else if (tab.isSubField && compObj.subItemsIdRefs) {
          if (compObj.subItemsIdRefs.some(subItems => subItems === tab.idRef)) {
            compObj.status = true
          } else {
            compObj.status = false
          }
        } else if (tab.subItemsIdRefs && tab.subItemsIdRefs[0] === compObj.idRef) {
          compObj.status = true
        } else {
          compObj.status = false
        }
        return compObj
      })
    }
  }

}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"
  .labelSpan
    margin-left 5px
    font-size 14px
    color inherit

  .subItemLabel
    display block
    padding-left 8px
    margin-left 0
    border-left 2px solid rgba(78, 78, 78, 0)
    &.activeSubItem
      border-left 2px solid $dark-gray
      color $dark-gray
    &.activeInvalidSub
      border-left-color rgb(168, 18, 35)
      color rgb(168, 18, 35) !important
    &.invalidSubItem
      color $red

  h1
    display inline-block
    margin-bottom 1.5em

  .info-icon
    font-size 20px
    margin-left 5px

  .side-nav
    .fixed
      color white
      position fixed
      top 90px
      width 200px

    a
      display inline-block
      color gray
      font-weight bold
      width 100%
      padding 5px 10px
      border-radius 10px
      border: 1px solid rgba(0,0,0,0)
      margin 2px 0

      .v-icon
          font-size 16px
          color gray

      &.subItem
        padding: 0 0 0 40px

        &.subItemActive
          border-left 1px solid grey

      &.finished
        .v-icon
          color rgb(102, 174, 4) !important
      &.invalid
        border-color $red !important
        .v-icon
          color: $red  !important
      &.activeSide
        color $dark-gray
        background #ffffff
        &.finished
          .v-icon
            color rgb(46, 121, 2) !important

        .v-icon,
        .v-icon:before
          color $dark-gray !important

        &.invalid
            border-color rgb(168, 18, 35) !important
            .v-icon
              color: rgb(168, 18, 35) !important
</style>
