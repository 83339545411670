export default {

  updateProposalScoreWeight (state, weight) {
    state.currentRfp.proposalScoreWeight = weight
  },

  updateQualityScoreWeight (state, {
    teamScoreWeight,
    credentialsScoreWeight,
    pitchDocumentScoreWeight,
    liabilityScoreWeight,
    qaScoreWeight,
    interviewScoreWeight,
    strategyScoreWeight,
    custom1ScoreWeight,
    custom2ScoreWeight,
    custom3ScoreWeight
  }) {
    state.currentRfp.teamScoreWeight = teamScoreWeight
    state.currentRfp.credentialsScoreWeight = credentialsScoreWeight
    state.currentRfp.pitchDocumentScoreWeight = pitchDocumentScoreWeight
    state.currentRfp.liabilityScoreWeight = liabilityScoreWeight
    state.currentRfp.qaScoreWeight = qaScoreWeight
    state.currentRfp.interviewScoreWeight = interviewScoreWeight
    state.currentRfp.strategyScoreWeight = strategyScoreWeight
    state.currentRfp.custom1ScoreWeight = custom1ScoreWeight
    state.currentRfp.custom2ScoreWeight = custom2ScoreWeight
    state.currentRfp.custom3ScoreWeight = custom3ScoreWeight
  },

  openLawFirmRelationInRating (state, lawFirmId) {
    state.openLawFirmRelation = lawFirmId
  }

}
