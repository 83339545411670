<template>
  <div class="date-time-field">
    <span
      style="grid-column-start: 1; grid-column-end: 3"
      class="light"
      :class="{ mandatory: mandatory && !readonly, light: readonly }">{{ translatedName }}</span>
    <template v-if="!readonly">
      <DateField
        :value="splitDateTime({ timestamp: value, part: 'date' })"
        ref="dateField"
        @input="updateDateValue"
        @invalidInput="$emit('invalidInput')"
        :invalid="invalid"
        :disabled="disabled"
      />
      <TimeField
        :value="splitDateTime({ timestamp: value, part: 'time' })"
        ref="timeField"
        @input="updateTimeValue"
        @invalidInput="$emit('invalidInput')"
        :invalid="invalid"
        :disabled="disabled"
      />
    </template>
    <p v-else style="grid-column-start: 1; grid-column-end: 3">{{ formatDateTime(value) }}</p>
  </div>
</template>

<script>
import DateField from './DateField'
import TimeField from './TimeField'
import splitDateTime from '../../../util/splitDateTime'
import InputFieldMixin from './InputFieldMixin'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'DateTimeField',
  components: { TimeField, DateField },
  mixins: [InputFieldMixin],
  computed: {
    ...mapGetters(['formatDateTime'])
  },
  watch: {
    disabled () {
      if (this.disabled) {
        this.$refs.timeField.setLocalValue()
      }
    }
  },
  methods: {
    splitDateTime,

    updateDateValue (newDate) {
      if (!newDate) {
        this.$emit('input', null)
        return
      }

      const newDateMoment = moment(newDate, 'DD.MM.YYYY')

      if (newDateMoment.isValid()) {
        const value = this.value ? moment(this.value) : moment()

        value.milliseconds(0)
        value.seconds(0)

        value.date(newDateMoment.date())
        value.month(newDateMoment.month())
        value.year(newDateMoment.year())

        this.$emit('input', value.toISOString())
      }
    },

    updateTimeValue (newTime) {
      if (!newTime) {
        newTime = '00:00'
      }

      const newTimeMoment = moment(newTime, 'HH:mm')

      if (newTimeMoment.isValid()) {
        const value = this.value ? moment(this.value) : moment()

        value.milliseconds(0)
        value.seconds(0)
        value.minutes(newTimeMoment.minutes())
        value.hours(newTimeMoment.hours())

        this.$emit('input', value.toISOString())
      }
    }
  }
}
</script>

<style scoped lang="stylus">

  .date-time-field
    display grid
    grid-template-columns 2fr 1fr
    grid-column-gap 10px

</style>
