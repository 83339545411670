import moment from 'moment'

export default {

  getValue () {
    return ({ root, subject, field }) => {
      return root[subject][field]
    }
  },

  inputDateToISO: () => (value) => {
    value = moment.utc(value, 'DD.MM.YYYY')
    value.milliseconds(0)
    value.seconds(0)
    value.minutes(0)
    value.hours(0)
    return value.toISOString()
  },

  MAX_UPLOAD_SIZE_IN_BYTE: () => 20 * 1024 * 1024,

  currentRfpOrDraft (state) {
    if (!state.currentRfp) {
      return state.currentRfp
    }
    return state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
  }

}
