import apollo from '../../apollo'
import gql from 'graphql-tag'
import { questionWithRfp } from '../graphqlTags/rfpTags'

export default {
  async addQuestion ({ getters, commit, state }, newQuestion) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation addQuestion($questionInput: QuestionInput!) {
          addQuestion(questionInput: $questionInput) {
            ${questionWithRfp(getters.userIsLawFirm)}
          }
        }
      `,
      variables: {
        questionInput: newQuestion
      }
    })

    newQuestion = response.data.addQuestion
    newQuestion.showAnswer = false
    commit('addQuestionToRfp', newQuestion)
    return newQuestion
  },

  async answerQuestion ({ getters, commit, state }, answer) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation answerQuestion($answerInput: AnswerInput!) {
          answerQuestion(answerInput: $answerInput) {
            ${questionWithRfp(getters.userIsLawFirm)}
          }
        }
      `,
      variables: {
        answerInput: answer
      }
    })

    const updatedQuestion = response.data.answerQuestion
    updatedQuestion.showAnswer = true
    updatedQuestion.updateAnswer = false
    updatedQuestion.newFiles = []
    commit('addQuestionToRfp', updatedQuestion)
    return updatedQuestion
  },

  async deleteAnswer ({ getters, commit, state }, answer) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation deleteAnswer ($id: ID!) {
          deleteAnswer (id: $id) {
            ${questionWithRfp(getters.userIsLawFirm)}
          }
        }
      `,
      variables: {
        id: answer.id
      }
    })

    const updatedQuestion = response.data.deleteAnswer
    updatedQuestion.newAnswer = ''
    updatedQuestion.newFiles = []
    updatedQuestion.showEditor = false
    commit('addQuestionToRfp', updatedQuestion)
  },

  async uploadQaFile ({ state, commit }, { file, question, type }) {
    const currentRfpId = state.currentRfp.id
    const data = new FormData()
    data.append('file', file)
    data.append('type', type)

    if (type === 'ANSWER') {
      data.append('answerId', question.answer.id)
    } else if (type === 'QUESTION') {
      data.append('questionId', question.id)
    } else {
      throw new Error('invalid type')
    }

    const request = {
      method: 'POST',
      body: data,
      enctype: 'multipart/form-data',
      headers: { 'x-auth-token': state.authToken }
    }

    console.log('UploadingFile', request)

    const response = await fetch('/rest/rfp/' + currentRfpId + '/files/upload', request)
    if (!response.ok) {
      commit('setSpecialErrorMessage', 'Please note that files may only take up to 20MB')
      commit('setShowDefaultError', true)
      return
    }
    const newAttachments = await response.json()

    console.log('newAttachments', newAttachments)
    return newAttachments
  }

}
