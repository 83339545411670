import moment from 'moment'

export default function splitDateTime ({ timestamp, part }) {
  if (!timestamp) {
    return ''
  }
  const dateTime = moment(timestamp)

  if (part === 'date') {
    return dateTime.format('DD.MM.YYYY')
  } else if (part === 'time') {
    return dateTime.format('HH:mm')
  }
}
