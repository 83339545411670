<template>
  <div>
    <span class="light" :class="{ mandatory: mandatory && !readonly, light: readonly }">{{ translatedName }}</span>
    <v-text-field
      v-if="!readonly"
      solo
      flat

      @blur="validateInput"
      @keyup.enter="validateInput"

      :value="localValue"
      :class="{ invalid }"

      :placeholder="placeholder"
      :prefix="prefix"
      :suffix="percentageInput ? '%' : suffix"
      :disabled="disabled"
    ></v-text-field>
    <p v-else>{{ UStoGER(value) }}</p>
  </div>
</template>

<script>
import InputFieldMixin from './InputFieldMixin'

export default {
  name: 'NumberField',
  mixins: [InputFieldMixin],
  props: {
    prefix: {
      type: String,
      default: '',
      required: false
    },
    suffix: {
      type: String,
      default: '',
      required: false
    },
    min: {
      type: Number,
      default: null,
      required: false
    },
    max: {
      type: Number,
      default: null,
      required: false
    },
    percentageInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localValue: null
    }
  },
  created () {
    this.setLocalValue()
  },
  watch: {
    value () {
      this.setLocalValue()
    },

    disabled () {
      this.$emit('input', this.value)
      this.setLocalValue()
    }
  },
  methods: {

    setLocalValue () {
      this.localValue = this.UStoGER(this.value)
    },

    formatGerman (value) {
      value = String(value)

      return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    /* E.g. 1234.56 -> 1.234,56 */
    UStoGER (value) {
      if (value || value === 0) {
        return typeof value === 'number' ? this.formatGerman(value) : value
      } else {
        return null
      }
    },
    /* E.g. 1.234,56 -> 1234.56 */
    GERtoUS (value) {
      if (value || value === 0) {
        const euros = String(value).split('.').join('')
        return euros
      } else {
        return null
      }
    },
    /* Checks if the input is valid. If so, emits an input event with the input
    in american format. */
    validateInput (event) {
      if (event.target.value === '' || event.target.value === null || event.target.value === undefined) {
        this.$emit('input', null)
        return
      }

      const inputValue = event.target.value === '' ? this.placeholder : event.target.value
      const USValue = inputValue !== null ? this.GERtoUS(inputValue) : ''

      if (isNaN(USValue)) {
        this.$emit('invalidInput')
      } else if (USValue !== '') {
        const usNumber = Number(USValue)

        const isValid = this.validate(usNumber)
        console.log('IsValid', isValid)

        if (this.min !== null && this.min !== undefined) {
          if (usNumber < this.min) {
            this.$emit('invalidInput')
            return
          }
        }

        if (this.max !== null && this.max !== undefined) {
          if (usNumber > this.max) {
            this.$emit('invalidInput')
            return
          }
        }

        if (this.percentageInput) {
          if (String(usNumber).includes('.') && String(usNumber).split('.')[1].length) {
            this.$emit('invalidInput')
            return
          }
        }

        this.$emit('invalidInput', false)
        this.$emit('alertMessage', '')
        this.$emit('input', usNumber)
      } else {
        this.$emit('input', null)
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input--is-disabled .v-input__slot
    background $light-gray !important

  >>> .v-text-field__details
    display none
</style>
