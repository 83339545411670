<template>
  <pop-up
    :value="value"
    @input="$emit('input')"
    style="border-radius: 5px; min-width: 380px">
    <ConfirmDialog
      v-if="showInviteDialog"
      :text="$t('newRFP.lawfirmSelection.inviteConfirmationDialogText', { email: userToInvite })"
      :title="'newRFP.lawfirmSelection.inviteConfirmationDialogTitle' | t"
      :acceptButtonTextOrKey="'newRFP.lawfirmSelection.invite' | t"
      :cancelButtonTextOrKey="'buttons.cancel' | t"
      @close="showInviteDialog = false"
      @submit="inviteNewUser()"
      />
    <v-card-title>
      <strong>{{ 'newRFP.lawfirmSelection.requestLawyer' | t  }}</strong>
    </v-card-title>
    <v-card-text>
      <v-combobox
        solo flat
        ref="input"
        :placeholder="'newRFP.lawfirmSelection.requestLawyerHint' | t "
        :search-input.sync="searchValue"
        :items="options"
        item-text="fullName"
        item-value="id"
        :item-disabled="item => item.id === 'INVITE_HINT'"
        return-object
        v-model="input"
        @input="handleLawyerInput"
        no-filter
      ></v-combobox>
      <span v-if="userInvited" style="display: block">{{ $t('newRFP.lawfirmSelection.invitedUser', { mail: userInvited }) }}</span>
      <span v-else style="display: block">&nbsp;</span>

      <strong
        v-if="requestedLawyers.length">
        {{ 'newRFP.lawfirmSelection.requestedLawyers' | t }}
      </strong>
      <div
        v-for="requestedLawyer in requestedLawyers"
        :key="requestedLawyer.id"
        class="selected-lawyers">
        <p>
          {{ requestedLawyer.fullName }}
        </p>
        <v-btn
          v-if="requestedLawyerCanBeRemoved(requestedLawyer.id)"
          @click.stop="removeRequestedLawyer({ lawFirmId: lawFirmRelation.lawFirm.id, lawyerId: requestedLawyer.id })"
          flat icon><v-icon>clear</v-icon></v-btn>
      </div>

      <strong
        v-if="requestedLawyersInvitePending.length">
        {{ 'newRFP.lawfirmSelection.requestedLawyersInvitePending' | t }}
      </strong>
      <div
        v-for="requestedLawyer in requestedLawyersInvitePending"
        :key="requestedLawyer.id"
        class="selected-lawyers">
        <p>
          {{ requestedLawyer.email }}
        </p>
        <v-btn
          v-if="requestedLawyerCanBeRemoved(requestedLawyer.id)"
          @click.stop="removeRequestedLawyer({ lawFirmId: lawFirmRelation.lawFirm.id, lawyerId: requestedLawyer.id })"
          flat icon><v-icon>clear</v-icon></v-btn>
      </div>
    </v-card-text>
  </pop-up>
</template>

<script>
import PopUp from '../../generalComponents/PopUp'
import { mapActions, mapState, mapMutations } from 'vuex'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'

export default {
  name: 'LawyerRequestingPopUp',
  components: { ConfirmDialog, PopUp },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    lawFirmRelation: {
      type: Object
    }
  },
  data () {
    return {
      input: null,
      searchValue: '',
      userInvited: false,
      showInviteDialog: false,
      userToInvite: false
    }
  },
  computed: {
    ...mapState({
      requestLawyerSearchResult: state => state.requestLawyerSearchResult
    }),

    options () {
      const result = [...this.requestLawyerSearchResult]

      if (this.searchValue && this.searchValue.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g)) {
        result.push({
          fullName: this.$t('newRFP.lawfirmSelection.addByMail', { mail: this.searchValue }),
          id: 'INVITE'
        })
      } else if (!result.length && this.searchValue) {
        result.push({
          fullName: this.$t('newRFP.lawfirmSelection.enterMailToAddUser'),
          id: 'INVITE_HINT'
        })
      }

      return result
    },

    requestedLawyers () {
      return this.lawFirmRelation.requestedLawyers.slice().filter(lawyer => !lawyer.invitePending)
    },

    requestedLawyersInvitePending () {
      return this.lawFirmRelation.requestedLawyers.slice().filter(lawyer => lawyer.invitePending)
    }
  },
  watch: {
    searchValue () {
      if (this.searchValue) {
        this.searchRequestedLawyers({
          search: this.searchValue,
          lawFirmId: this.lawFirmRelation.lawFirm.id
        })
      } else {
        this.setRequestLawyerSearchResult([])
      }
    }
  },
  methods: {
    ...mapActions(['searchRequestedLawyers', 'inviteUser']),
    ...mapMutations(['setRequestLawyerSearchResult', 'addRequestedLawyer', 'removeRequestedLawyer']),

    requestedLawyerCanBeRemoved (id) {
      console.log(this.lawFirmRelation, this.lawFirmRelation.disabledRequestedLawyerIds)
      if (this.lawFirmRelation.disabledRequestedLawyerIds) {
        return !this.lawFirmRelation.disabledRequestedLawyerIds.includes(id)
      }
      return true
    },

    async inviteNewUser () {
      this.showInviteDialog = false
      const invitedUser = await this.inviteUser({ email: this.userToInvite, lawFirmId: this.lawFirmRelation.lawFirm.id })
      this.addRequestedLawyer({ lawyer: invitedUser, lawFirmId: this.lawFirmRelation.lawFirm.id })
      this.userInvited = this.userToInvite
    },

    async handleLawyerInput (lawyer) {
      const searchValue = this.searchValue
      this.$nextTick(() => {
        this.input = null
      })

      if (typeof lawyer === 'object') {
        if (lawyer.id === 'INVITE') {
          this.userToInvite = searchValue
          this.showInviteDialog = true
          this.$refs.input.reset()
        } else {
          this.addRequestedLawyer({ lawyer, lawFirmId: this.lawFirmRelation.lawFirm.id })
          this.userInvited = false
          this.$refs.input.reset()
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  >>> .v-input:not(.v-input--selection-controls) .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input__slot
    margin-bottom 4px !important

  >>> .v-text-field__details
    display none

  .v-card__text
    padding-top 0

  .selected-lawyers
    display grid
    grid-template-columns 1fr 50px

    p
      margin-bottom 0
      line-height 48px
</style>
