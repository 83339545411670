<template>
  <v-dialog
    :value="true"
    @input="$emit('close')"
    :width='700'
    persistent
    no-click-animation>
    <v-card v-click-outside-dialog="closeEvent">
      <v-card-text>
        <div class="header">
          <div class="title">
            <h2 style="margin-top: 0;">
              <!-- {{title}} -->
              <TooltipHolder
                style="vertical-align: middle"
                :spanId="title"
                :text="title"
                :tooltipText="title"
                :tableDataWidth="400"
                :size="39"
                :moveTop="-24"
                :width="250"
                bottom />

              <TooltipHolder
                class="info-icon"
                style="vertical-align: text-top; font-weight: normal"
                v-if="COI && page === 1"
                :spanId="$t('tooltips.coiITitleTooltip')"
                :width="150"
                :moveTop="50"
                :icon="'info'"
                :tooltipText="$t('tooltips.coiITitleTooltip')"
                right
                summaryTooltip />
            </h2>

            <v-chip style="margin-top: 0" v-if="COI">{{ `dropDownOptions.MandateType.${rfp.mandateType}` | t }}</v-chip>
            <br>
            <div>
              <span v-if="COII.status" class="subtitle">
                <!-- {{ rfp.clientName }} -->
              <TooltipHolder
                class="coiIISubtitle"
                :spanId="rfp.clientName+'clientName'"
                :text="rfp.clientName"
                :tooltipText="rfp.clientName"
                :tableDataWidth="200"
                :size="39"
                :moveTop="-30"
                :width="300"
                coi2
                bottom />
              </span>
              <span v-if="COII.status">
                <!-- {{ rfp.name }} -->
                <TooltipHolder
                  class="coiIISubtitle"
                  :spanId="rfp.name+'name'"
                  :text="rfp.name"
                  :tooltipText="rfp.name"
                  :tableDataWidth="200"
                  :moveTop="-30"
                  :size="39"
                  :width="300"
                  coi2
                  bottom />
              </span>
            </div>
          </div>
          <div>
            <v-btn flat icon :class="{vAlign: COI}" class="close-button" @click="$emit('close')"><v-icon style="font-size: 24px">ic-close-circle</v-icon></v-btn>
          </div>
        </div>

        <v-divider style="margin-top: 20px;" v-if="(COI || COII.status)"></v-divider>

        <h2 v-if="rejected">{{ 'coi1LawFirm.rejectMatter' | t }}</h2>

        <p v-if="showTextField" class="text-holder" v-html="text">  </p>
        <slot name="custom-input"></slot>
        <v-checkbox
          v-if="feedbackDialog"
          v-model="discloseFeedbackToLawfirm"
          color="#4e4e4e"
          :label="$t('activeRFP.feedback.discloseFeedbackLabel')"
          solo flat >
        </v-checkbox>

        <div v-if="COII.status && !COII.approve">
          <span>{{ $t('coi2LawFirm.dialog.rejectLabel') }}</span>
          <v-textarea
            solo
            flat
            :placeholder="$t('coi2LawFirm.dialog.rejectPlaceholder')"
            :class="{missingTextArea: true}"
            @input="value => updateTextAreaValue(value)"
          ></v-textarea>
        </div>

        <div v-if="COI && !rejected" class="grid" :class="{gridRows: page === 1}">
          <template v-if="page === 1">

            <span class="coi1GridSpan">{{ 'dashboard.createNewRfp.projectDescription' | t }}</span>
            <p class="binfo" style="grid-column-start: 1; grid-column-end: 3; margin-top: 0px; font-size: 14px;">{{ rfp.description }}</p>

            <span>{{ 'dashboard.createNewRfp.projectMatter' | t }}</span>
            <span v-show="rfp.mandateSubType">{{ 'dashboard.createNewRfp.projectSubMatter' | t }}</span>

            <span v-show="!rfp.mandateSubType"></span>
            <b class="binfo">{{ `dropDownOptions.MandateType.${rfp.mandateType}` | t }}</b>

            <b class="binfo" v-show="rfp.mandateSubType">{{ `dropDownOptions.MandateSubType.${rfp.mandateSubType}` | t }}</b>
            <b class="binfo" v-show="!rfp.mandateSubType"></b>

            <span class="coi1GridSpan">{{ 'dashboard.createNewRfp.keySubject' | t }}</span>
            <TooltipHolder
              class="coi1GridSpan binfo"
              :text="rfp.subjects[0].translatedLabel"
              :tooltipText="rfp.subjects[0].translatedLabel"
              :tableDataWidth="195"
              :moveLeft="-20"
              tableData
              bold />

            <template v-if="rfp.subjects.length > 1">
              <span class="coi1GridSpan" style="margin-top: 10px;">{{ 'dashboard.createNewRfp.otherSubjects' | t }}</span>
              <TooltipHolder
                class="coi1GridSpan"
                v-for="(subject, index) in rfp.subjects.slice(1)"
                :key="'subject' + index"
                :text="subject.translatedLabel"
                :tooltipText="subject.translatedLabel"
                :tableDataWidth="95"
                :moveLeft="-20"
                tableData
                bold />
            </template>

            <span class="coi1GridSpan">{{ 'dashboard.createNewRfp.projectClientName' | t }}</span>
            <b class="binfo coi1GridSpan">{{ rfp.clientName }}</b>

            <span v-if="rfp.coi1.otherParties.length" class="coi1GridSpan">{{ 'dashboard.createNewRfp.projectOtherParties' | t }}</span>
            <template v-for="party in rfp.coi1.otherParties"  >
              <b :key="'name'+party.id" class="binfo other-parties" style="grid-column: 1">{{ party.name }}</b>
              <b :key="party.id" class="binfo other-parties" style="grid-column: 2">{{ otherPartyRole(party.role) }}</b>
            </template>

            <template v-if="rfp.lawFirmRelations[0].requestedLawyers && rfp.lawFirmRelations[0].requestedLawyers.length">
              <span class="coi1GridSpan" style="margin-top: 10px">{{ 'dashboard.createNewRfp.requestedLawyers' | t }}</span>
              <b v-for="requestedLawyer in rfp.lawFirmRelations[0].requestedLawyers" :key="requestedLawyer.id" class="coi1GridSpan">{{ requestedLawyer.invitePending ? requestedLawyer.email + ' ' + $t('dashboard.createNewRfp.invitePendingAppendix')  : requestedLawyer.fullName }}</b>
            </template>
          </template>

          <template v-else>
            <h2 class="coi1GridSpan">{{ 'coi1LawFirm.conflictCheckTitle' | t }}</h2>
            <v-checkbox class="coi1GridSpan" v-model="checkedConflicts" color="rgb(163, 163, 174)" :label="'coi1LawFirm.conflictCheck' | t"></v-checkbox>
            <span class="coi1GridSpan" style="line-height: 20px; font-size: 16px; padding-left: 4px; color: rgba(0,0,0,0.54);" v-html="$t('coi2LawFirm.dialog.approveConsent')"></span>
          </template>
        </div>
        <div v-if="documentUpload">
          <template v-if="page === 1">
            <DocumentUpload
              type="INVOICE"
              :documents="attachedDocuments"
              @pendingUpload="value => handleDocumentUpload(value)"
              @deleteFile="file => handleDeleteDocument(file)"
              deleteIcon
              uploadInnvoice
              labelKey="newRFP.documentUpload.documentLabel2"/>
              <div style="margin-top: 10px" v-if="previousAttachments.length">
                <p style="margin: 0">{{ 'dashboard.rfpInformation.previousDocumentsLabel' | t }}</p>
                <FileChip
                  v-for="document in previousAttachments"
                  :file="document"
                  :key="document.id"
                  @delete="deleteDocument(document)"
                  :deleteIcon="rfp.status === 'COMPLETED_MATTER_IN_PROGRESS'"
                  >
                </FileChip>
              </div>
          </template>
          <template v-else>
            <p class="text-holder" >{{ "dashboard.rfpInformation.finaliseAlertText" | t }}</p>
          </template>
        </div>
      </v-card-text>

      <div v-if="biddingTimeLeft" style="text-align: end; padding: 0 10px">
        <span style="margin: 0"><b>{{ 'activeRFP.biddingProcessSection.timeLeftLabel' | t }}&nbsp;</b>{{ biddingTimeLeft }}</span>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!hideCancel" flat style="border-radius: 5px" @click="clickBackButton()">{{ page > 1 ? 'coi1LawFirm.back' : cancelButtonTextOrKey | t }}</v-btn>
        <v-btn
          v-if="showSecondButton"
          :disabled="disableSecondButton || disableWithoutProgress"
          flat
          style="border-radius: 5px;"
          :color=" COI ? 'error' : ''"
          @click="clickSecondButton()">
          {{ COI ? "buttons.reject" : 'buttons.submit' | t}}
          <v-progress-circular
            v-if="buttonDisable"
            indeterminate
            width="3"
            class="progress"
            :size="24"
            style="margin-left: 10px">
          </v-progress-circular>
        </v-btn>
        <v-btn v-if="!rejected" flat class="main" @click="waitButtonToEnd(clickAcceptButton)" :disabled="disableWithoutProgress || disableAcceptButton" style="border-radius: 5px">
          {{ COI ? 'coi1LawFirm.continue' : acceptButtonTextOrKey | t }}
          <v-progress-circular
            v-if="(documentUpload) ? (page !== 1 && buttonDisable) : buttonDisable"
            indeterminate
            width="3"
            class="progress"
            :size="24"
            style="margin-left: 10px">
          </v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TooltipHolder from '../generalComponents/TooltipHolder'
import DocumentUpload from '../generalComponents/DocumentUpload'
import FileChip from '../generalComponents/FileChip'
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmDialog',
  components: { TooltipHolder, DocumentUpload, FileChip },
  data () {
    return {
      checkedConflicts: false,
      page: 1,
      rejected: false,
      coi2RejectReason: '',
      attachedDocuments: [],
      discloseFeedbackToLawfirm: true
    }
  },
  props: {
    text: {
      required: false,
      type: String
    },
    disableWithoutProgress: {
      required: false,
      type: Boolean,
      default: false
    },
    title: {
      required: true,
      type: String
    },
    /** Should be a translation key. If a parametrized translation is needed, an already translated text can also be passed. */
    acceptButtonTextOrKey: {
      required: false,
      default: 'buttons.accept',
      type: String
    },
    /** Should be a translation key. If a parametrized translation is needed, an already translated text can also be passed. */
    cancelButtonTextOrKey: {
      required: false,
      default: 'buttons.back',
      type: String
    },
    /* Prop that shows "x" Close button */
    showCloseBtn: {
      required: false,
      default: true,
      type: Boolean
    },
    mandateType: {
      required: false,
      type: String
    },
    COI: {
      required: false,
      type: Boolean,
      default: false
    },
    COII: {
      required: false,
      type: Object,
      default: () => { return { status: false, approve: false } }
    },
    rfp: {
      type: Object,
      required: false
    },
    biddingTimeLeft: {
      type: String,
      required: false
    },
    // Prop That displays the Document Upload component
    documentUpload: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false
    },

    // Prop to show the checkbox
    feedbackDialog: {
      type: Boolean,
      default: false
    },
    // prop that controls the button for asyn execution of the Submit action
    buttonDisable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    disableAcceptButton () {
      if ((this.COI && this.page === 2 && !this.checkedConflicts) || (this.coi2RejectReason.length < 3 && this.COII.status && !this.COII.approve) || (this.documentUpload && this.previousAttachments.length === 0) || this.buttonDisable) {
        return true
      } else {
        return false
      }
    },

    disableSecondButton () {
      if (this.documentUpload) {
        return !this.attachedDocuments.length > 0
      } else {
        return false
      }
    },

    showTextField () {
      if (this.documentUpload) {
        return this.page === 1
      } else {
        return this.rejected || !this.COI
      }
    },

    previousAttachments () {
      if (this.documentUpload) {
        return this.rfp.attachments.filter(attachment => attachment.rfpFileType === 'INVOICE')
      } else {
        return []
      }
    },

    showSecondButton () {
      return this.page === 1 && (this.COI || this.documentUpload)
    }
  },
  methods: {
    ...mapActions(['deleteFile']),

    clickAcceptButton () {
      if (this.COI && this.page === 1) {
        this.page = 2
      } else if (this.COI) {
        this.$emit('accept', this.rfp.id)
      } else if (this.COII.status) {
        this.$emit('submit', this.coi2RejectReason)
      } else if (this.documentUpload) {
        if (this.page === 1) {
          this.page = 2
        } else {
          this.$emit('submit', this.rfp.id)
        }
      } else if (this.feedbackDialog) {
        this.$emit('submit', this.discloseFeedbackToLawfirm)
      } else {
        this.$emit('submit')
      }
    },

    clickBackButton () {
      if ((this.COI || this.documentUpload) && this.page === 2) {
        this.page = 1
      } else if (this.rejected === true) {
        this.rejected = false
      } else {
        this.$emit('close')
      }
    },

    updateTextAreaValue (value) {
      this.coi2RejectReason = value
    },

    otherPartyRole (role) {
      const key = `dropDownOptions.OtherPartyRoles.${role.value}`
      if (this._i18n.i18next.exists(key)) {
        return this.$t(key)
      } else {
        return role.value
      }
    },

    handleDocumentUpload (document) {
      document.id = document.lastModified + '' + Date.now()
      document.rfpFileType = 'INVOICE'
      this.attachedDocuments.push(document)
    },

    closeEvent (event) {
      this.$emit('close')
    },

    clickSecondButton () {
      if (this.documentUpload) {
        this.$emit('submitDocuments', this.attachedDocuments, this.rfp.id)
        this.attachedDocuments = []
      } else if (this.rejected === false) {
        this.rejected = true
      } else {
        this.$emit('rejectCOI', this.rfp.id)
      }
    },

    async deleteDocument (event) {
      this.deleteFile({ rfpId: this.rfp.id, fileId: event.id })
    },

    handleDeleteDocument (file) {
      this.attachedDocuments.splice(this.attachedDocuments.findIndex(document => document.id === file.id), 1)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  h2
    margin-top 20px

  .v-card
    padding 20px
    border-radius 10px
    .v-card__text
      padding 8px;

  .header
    display grid
    grid-template-columns 1fr 110px
    height 35px
    .title
      .subtitle
        border-right: 1.2px solid rgba(0,0,0,0.4)
        margin-right: 10px
        padding-right: 10px

      .v-chip
        margin-left: 15px

      *
        display inline-block

  .text-holder
    margin-top 23px

  .close-button
    float: right
    margin 0px
    height 24px
    width 22px

  .vAlign
    padding-top 5px;
    &.v-btn:before
      top: 5px

  p
    margin-top 10px
  .v-card__text
    span
      color rgb(158, 158, 158)

  >>> .v-text-field .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-text-field__details
    display none

  .grid {
    display grid
    grid-template-columns 1fr 1fr
    grid-column-gap 15px

    &.gridRows {
      margin-top 20px
      grid-template-rows repeat(10, auto)
    }

    .binfo {
      margin-bottom 10px
    }
  }

  .coi1GridSpan
    grid-column-start 1
    grid-column-end 3
    >>> .v-input--selection-controls__input
      margin-right 20px

    .subjects
      min-width 80px

  .subject-grid
    display grid
    grid-template-columns 200px repeat(4, 1fr)
    grid-column-gap 10px

  .other-parties
    hyphens auto

  .coiIISubtitle
    color #9e9e9e;
    vertical-align middle

  >>> .v-progress-circular
    svg, div, circle
      color $gold100 !important
</style>
