import { dateTimeMaxWidthPixels } from '../../../layoutConstants'

const titleWidth = '150px'
const bidTypeWidth = '150px'
const deadlineWidth = dateTimeMaxWidthPixels + 'px'

export const adminHeaders = [
  {
    text: 'dashboard.rfpInformation.tableHeaders.corporateBilled',
    align: 'left',
    sortable: false,
    value: '',
    width: '90px'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.lawfirmBilled',
    sortable: false,
    value: 'action',
    width: '120px'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.title',
    align: 'left',
    sortable: true,
    value: 'name',
    width: titleWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.corporate',
    align: 'left',
    sortable: true,
    value: 'corporate',
    width: bidTypeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.firm',
    align: 'left',
    sortable: true,
    value: 'firm',
    width: bidTypeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.status',
    align: 'left',
    sortable: true,
    value: 'status',
    width: '150px'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.feedback',
    align: 'left',
    sortable: true,
    value: 'feedback',
    width: '150px'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.amount',
    align: 'left',
    sortable: false,
    value: 'amount',
    width: '150px'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.currency',
    align: 'left',
    sortable: false,
    value: 'currency',
    width: '50px'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.invoiceDate',
    align: 'left',
    sortable: false,
    value: 'invoiceDate',
    width: '150px'
  },
  {
    text: '',
    sortable: false,
    value: 'action',
    width: '40px'
  }
]
