<template>
  <div class="tooltip">
    <div
      :class="{'name-holder': tableData || tableDataWidth, 'bold': bold, 'rfp-title': rfpTitle, 'changeHeight': ((bottom || changeHeight) && !coi2)}"
      v-if="!isLink && icon === '' && imgSrc === ''"
      style="color: inherit;"
      :style="tableDataWidth ? `max-width: ${tableDataWidth}px` : ''" >
      {{ renderText }}
    </div>
    <a v-if="isLink" :style="bold ? 'font-weight: bold;': '' ">{{ renderText }}</a>
    <v-icon :class="{tablePopup: tablePopup}" v-if="icon !== '' && renderText === '' && imgSrc === ''" >{{icon}}</v-icon>
    <img class="image-size" v-if="renderText === '' && icon === '' && imgSrc" :src="require(`../../assets/img/${imgSrc}`)" />
    <span
      v-if="(renderText !== '' || icon !== ''|| imgSrc !== '') && tableDataRender"
      ref="span"
      class="tooltipText"
      :class="{default: noOtherClasses, right: (right && !summaryTooltip), left: left, bottom: bottom, 'tableData': tableData, popUp: tablePopup, summaryTooltip: summaryTooltip, 'fixed-arrow': fixedArrow}"
      :id="spanId ? spanId : 'modify-place'"  >
      <div v-if="titleText !== ''"><v-icon v-if="!tablePopup" class="title-icon">info</v-icon><h4 style="display: inline-block">{{ titleText }}</h4></div>
      <span v-if="breakdownInfo.length === 0 && tooltipList.length === 0" v-html="tooltipText ? tooltipText : renderText"></span>
      <template v-else-if="tooltipList.length > 0">
        <p style="font-size: 12px; margin-bottom: 0" v-for="(list, index) in tooltipList" :key="list+spanId+index">-&nbsp;{{ list }}</p>
      </template>
      <template v-else v-for="breakdownRow in breakdownInfo">
        <section :key="breakdownRow.key" class="breakdown" :class="{showPercentaje: breakdownRow.showPercentage, noPercentaje: !breakdownRow.showPercentage }">
          <span class="dot" :style="`background-color: ${breakdownRow.color}`"></span>
          <b><span>{{ breakdownRow.key }}</span></b>
          <span class="light" style="justify-self: end; font-weight: bold">{{ breakdownRow.rate }}</span>
          <span v-if="breakdownRow.showPercentage" class="light" style="justify-self: end; font-weight: bold">{{ Math.round(breakdownRow.percentage * 100) }}%</span>
        </section>
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'TooltipHolder',
  props: {
    // Text to display in the component
    text: {
      required: false,
      default: ''
    },
    // The text shown in the tooltip, if this prop isn't passed through the parent the text 'prop' will be shown in the tooltip
    tooltipText: {
      required: false,
      type: String
    },
    // Prop for using an icon instead of text values
    icon: {
      required: false,
      default: ''
    },
    // Prop for setting the title and sending the text to display in the title
    titleText: {
      required: false,
      type: String,
      default: ''
    },
    // Bold the Shown text
    bold: {
      type: Boolean,
      required: false,
      default: false
    },
    // Set to <a></a> the tooltip
    isLink: {
      type: Boolean,
      required: false,
      default: false
    },
    // Sets the Tooltip to be shown on the right side of the component
    right: {
      required: false,
      type: Boolean
    },
    // Sets the Tooltip to be shown on the bottom side of the component
    bottom: {
      required: false,
      type: Boolean
    },
    // Sets the Tooltip to be shown on the left side of the component
    left: {
      required: false,
      type: Boolean
    },
    // move the tooltip to the left, use negative values to move to right; values from 0 to 100
    moveLeft: {
      required: false,
      type: Number
    },
    // move the tooltip to the top, use negative values to move to bottom; values from 0 to 100
    moveTop: {
      required: false,
      type: Number
    },
    // Defines the tooltip size (messes with the position)
    width: {
      required: false,
      type: [Number, String]
    },
    // use for loop generated tooltips with changes
    spanId: {
      required: false
    },
    // Prop for table data tooltips with logic
    tableData: {
      required: false,
      default: false,
      type: Boolean
    },
    // Default number of characters to show the Tooltip
    size: {
      type: Number,
      required: false,
      default: 20
    },
    tableDataWidth: {
      type: Number,
      required: false
    },
    // Prop that tells the tooltip that it should render as the pop up for the seniority breakdown
    tablePopup: {
      type: Boolean,
      required: false,
      default: false
    },
    // Array to set the values in the seniority Breakdown
    breakdownInfo: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
    // prop to set up as an image
    imgSrc: {
      type: String,
      default: ''
    },
    rfpTitle: {
      type: Boolean,
      default: false
    },

    // Render list Values
    tooltipList: {
      type: Array,
      default: () => { return [] }
    },
    // tooltip for summary
    summaryTooltip: {
      type: Boolean,
      default: false
    },

    coi2: {
      type: Boolean,
      default: false
    },

    changeHeight: {
      type: Boolean,
      default: false
    },

    fixedArrow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      renderText: ''
    }
  },
  watch: {
    text () {
      this.renderText = this.text !== null ? this.text : ''
    }
  },
  created () {
    this.renderText = this.text !== null ? this.text : ''
    // console.log(this.spanId)
  },
  mounted: function () {
    this.assignSpanId()
  },
  computed: {
    noOtherClasses () {
      if (this.right || this.left || this.bottom || this.tableData || this.summaryTooltip) {
        return false
      } else {
        return true
      }
    },
    initialPosValue () {
      if (this.right) {
        return { top: 0, left: 120 }
      } else if (this.left) {
        return { top: 0, left: -105 }
      } else if (this.bottom) {
        return { top: 100, left: 0 }
      } else if (this.tableData) {
        return { top: 105, left: -30 }
      } else {
        return { top: -110, left: 0 }
      }
    },
    tableDataRender () {
      if (this.tableData || this.bottom) {
        if (this.renderText.length >= this.size) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  },
  methods: {
    assignSpanId () {
      if ((this.moveTop || this.moveLeft) && this.tableDataRender) {
        const newTopValue = this.initialPosValue.top - (this.moveTop ? this.moveTop : 0)
        const newTopPosition = this.tableData ? `bottom: ${newTopValue}%;` : `top: ${newTopValue}%;`

        const newLeftValue = this.initialPosValue.left - (this.moveLeft ? this.moveLeft : 0)
        const newLeftPosition = `left: ${newLeftValue}%;`

        const newWidth = isNaN(this.width) ? `width: ${this.width};` : (this.width ? `width: ${this.width}px;` : '')

        this.$refs.span.setAttribute('style', newTopPosition + newLeftPosition + newWidth)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .v-icon
    transition opacity 0.3s
    color rgb(78, 78, 78) !important
    font-size 18px
    &.tablePopup
      font-size 26px
      color #ababb5 !important
    &.title-icon
      font-size 12px
      vertical-align middle
      margin-bottom 2px
      padding-right: 2px

  .tooltip
    position relative
    display inline-block

  .tooltipText
    visibility hidden
    background #fff
    color rgb(78, 78, 78)
    padding 5px
    z-index 1000
    position absolute
    opacity 0
    border 1px solid rgb(78, 78, 78)
    transition opacity 0.3s
    border-radius 4px
    width auto
    white-space initial
    &.default
      text-align center
      top -110%
      left 0%

    &.right
      top 0%
      left 120%

    &.bottom
      top 100%
      left 0%

    &.left
      top 0%
      left -105%

    &.tableData
      bottom 105%
      left -30%

    &.popUp
      width 230px
      top -215%

  .tooltip .tooltipText::after
    content ""
    position absolute
    border-width 5px
    border-style solid

  .tooltipText.default::after
    border-color #555 transparent transparent transparent
    margin-left -5px
    top: 100%
    left 50%

  .tooltipText.right::after
    border-color: transparent #555 transparent transparent
    margin-top: -5px
    top: 50%
    right: 100%

  .tooltipText.summaryTooltip::after
    border-color: transparent #555 transparent transparent
    margin-top: -5px
    top: 26px
    right: 100%

  .tooltipText.bottom::after
    border-color: transparent transparent #555 transparent
    margin-left: -5px
    bottom: 100%
    left: 10%

  .tooltipText.left::after
    border-color: transparent transparent transparent #555
    margin-top: -5px;
    top: 50%
    left: 100%

  .tooltipText.tableData::after
    border-color #555 transparent transparent transparent
    margin-left -5px
    top: 100%
    left 25%

  .tooltipText.fixed-arrow.tableData::after
    left 40px

  .tooltip:hover .tooltipText
    visibility visible
    opacity 1

  .bold
    font-weight bold

  .name-holder
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
    max-width 120px
    &.changeHeight
      height 24px

  .breakdown
    display grid
    align-items center
    grid-column-gap 5px
    margin 5px 0
    &.showPercentaje
      grid-template-columns 15px 1fr 0.3fr 0.3fr
    &.noPercentaje
      grid-template-columns 15px 1fr 0.5fr

  .dot
    width 15px
    height 15px
    border-radius 50%
    display inline-block
    // background-color #ababb5

  .image-size
    height 24px
    width auto
    display flex

  .rfp-title
    font-family 'Roboto', sans-serif !important
    height 30px
</style>
