import Vue from 'vue'

export default {

  setAllRfps (state, allRfps) {
    Vue.set(state, 'allRfps', allRfps || [])
  },

  setCurrentRfp (state, rfp) {
    if (rfp !== null) {
      if (rfp.lawFirmRelations && rfp.lawFirmRelations.length > 0) {
        rfp.lawFirmRelations.forEach(lawFirmRelation => {
          lawFirmRelation.showProposal = false
        })
      }
    }
    Vue.set(state, 'currentRfp', rfp)
  },

  changeStatus (state, { rfpId, status }) {
    const allRfps = state.allRfps

    allRfps[allRfps.findIndex(rfp => rfp.id === rfpId)].status = status
    Vue.set(state, 'allRfps', allRfps)
  },

  deleteRfp (state, { rfpId }) {
    Vue.set(state, 'allRfps', state.allRfps.filter(rfp => rfp.id !== rfpId))
  },

  toggleLiveFeedback (state) {
    state.currentRfp.shareLiveFeedback = !state.currentRfp.shareLiveFeedback
  },

  setHistoryRfpVersion (state, version) {
    Vue.set(state, 'historyRfpVersion', version)
  }
}
