import apollo from '../../apollo'
import gql from 'graphql-tag'

import { lawFirmAnalytics, corporateAnalytics } from '../graphqlTags/rfpTags'

export default {

  async loadLawFirmAnalyticsInfo ({ commit, getters, state }) {
    const response = await apollo(state).query({
      query: gql`
        query lawFirmDashboardMetrics($currency: String!) {
          lawFirmDashboardMetrics (currency: $currency) {
            ${lawFirmAnalytics}
          }
        }
      `,
      variables: {
        currency: state.analyticsCurrency
      }
    })
    const lawFirmDashboardMetrics = response.data.lawFirmDashboardMetrics

    commit('setAnalytics', lawFirmDashboardMetrics)
  },

  async loadCorporateAnalyticsInfo ({ commit, getters, state }) {
    const response = await apollo(state).query({
      query: gql`
        query corporateDashboardMetrics($currency: String!) {
          corporateDashboardMetrics (currency: $currency) {
            ${corporateAnalytics}
          }
        }
      `,
      variables: {
        currency: state.analyticsCurrency
      }
    })
    const corporateDashboardMetrics = response.data.corporateDashboardMetrics

    commit('setAnalytics', corporateDashboardMetrics)
  }
}
