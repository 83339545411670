<template>
  <div class="grid">
    <div style="display: grid; grid-template-columns: max-content 1fr; grid-column-gap: 10px">
      <h2 ref="title">{{ 'activeRFP.proposal.credentials' | t }}</h2>
      <v-rating
        v-if="credentials && credentials.rating"
          small
          color="orange"
          class="raitings"
          background-color="orange lighten-3"
          readonly
          :value="credentials.rating"
          half-increments hover />
    </div>

    <v-btn v-if="proposalIsEditable && updateMode" @click="showNewInput" class="add-btn"  :disabled="disableAddCredentials()" flat><v-icon>ic-plus-circle</v-icon>&nbsp;{{ 'activeRFP.proposal.addCredential' | t }}</v-btn>

    <p
        v-if="requestedContent"
        style="max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%; margin-top: -10px; margin-bottom: 20px; grid-column: 1 / 3;">{{ 'activeRFP.proposal.requestedInformationCredentials' | t }} {{ requestedContent }}</p>

    <p
        v-if="credentials && credentials.ratingComment"
        style="margin-top: -10px; margin-bottom: 20px; grid-column: 1 / 3; max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%">
      Feedback from the client: {{ credentials.ratingComment }}</p>

    <v-card
      v-if="!(showNewInputForm && updateMode) && (!credentials || credentialsLength === 0)"
      class="no-credentials-card"
      :class="{missing: missingMandatoryFields.includes('CREDENTIALS')}">
      <h3>{{ (noTeamMemberAdded ? 'activeRFP.proposal.noTeamMember' : 'activeRFP.proposal.noCredentials') | t }}</h3>
    </v-card>

    <template v-else-if="credentials">
      <v-card  class="credential" v-for="credential in credentials.subItems" :key="credential.id">
        <template v-if="!credential.update">
          <div class="title-grid">
            <section >
              <span class="light">{{ 'activeRFP.proposal.description' | t }}</span>
              <p>{{ credential.extendedFields.description }}</p>
            </section>
            <v-btn class="credential-btn" v-if="proposalIsEditable && updateMode" icon @click="openUpdateEditor(credential)"><v-icon>ic-edit</v-icon></v-btn>
            <v-btn class="credential-btn" v-if="proposalIsEditable && updateMode" icon @click="deleteCredentialLocal(credential)"><v-icon>ic-trashcan</v-icon></v-btn>
          </div>

          <section>
            <span class="light">{{ 'activeRFP.proposal.year' | t }}</span>
            <p>{{ credential.extendedFields.year }}</p>
          </section>

          <section v-if="currentRfp.terms.qualityScoreCriteria && currentRfp.terms.qualityScoreCriteria.includes('TEAM')">
          <span class="light">{{ 'activeRFP.proposal.involvementOfTeamMembers' | t }}</span>
            <div v-if="credential.extendedFields.involvementOfProposedTeamMembers && credential.extendedFields.involvementOfProposedTeamMembers.length && credential.extendedFields.involvementOfProposedTeamMembers.some(x => x.userId)">
              <div
                style="display: grid; grid-template-columns: 220px 1fr;"
                v-for="teamMember in credential.extendedFields.involvementOfProposedTeamMembers"
                :key="teamMember.userId"
              >
                <p>{{ getTeamMemberNameFromProposalTeam(teamMember.userId) }}</p>
                <p>{{ renderRole(teamMember) }}</p>
              </div>
            </div>
            <p v-else>{{ 'activeRFP.proposal.clientAdvisedByOtherLawyers' | t }}</p>
          </section>
        </template>
        <ProposalCredentialInput
          style="padding-top: 10px"
          v-else
          :credential="credential.newValue"
          @cancel="cancelUpdate(credential)"
          @save="save(credential)"/>
      </v-card>
    </template>

    <v-card v-if="showNewInputForm && updateMode" id="new_credential_input" style="grid-column-start: 1; grid-column-end: 3;">
      <ProposalCredentialInput
        :credential="newCredential"
        @cancel="cancel"
        @save="save(newCredential)"/>
    </v-card>
    <ConfirmDialog
      v-if="showDeleteDialog"
      :text="'activeRFP.proposal.deleteCredentialText' | t"
      :title="'activeRFP.proposal.deleteCredentialTitle'  | t"
      @close="showDeleteDialog = false"
      @submit="deleteSelectedCredential"
      acceptButtonTextOrKey="buttons.delete"
      cancelButtonTextOrKey="buttons.cancel"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ProposalCredentialInput from './ProposalCredentialInput'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'

export default {
  name: 'ProposalCredentials',
  components: { ConfirmDialog, ProposalCredentialInput },
  data () {
    return {
      showDeleteDialog: false,
      credentialToDelete: null
    }
  },
  created () {
    if (!this.credentialsDraft) {
      this.reset()
    }
  },
  computed: {
    ...mapGetters(['currentRfpLawFirmRelation', 'proposalIsEditable', 'getTeamMemberNameFromProposalTeam']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields,
      currentRfp: state => state.currentRfp,
      credentialsDraft: state => state.draftProposal.credentials,
      newCredential: state => state.draftProposal.newCredential,
      showNewInputForm: state => state.draftProposal.showNewCredentialInputForm,
      updateMode: state => state.draftProposal.updateMode
    }),

    credentials () {
      if (this.updateMode) {
        return this.credentialsDraft
      }

      return this.credentialsFromRfpLawFirmRelation
    },

    credentialsFromRfpLawFirmRelation () {
      return this.currentRfpLawFirmRelation.proposal
        ? (this.currentRfpLawFirmRelation.proposal.find(category => (this.updateMode ? category.draft : !category.draft) && category.name === 'CREDENTIALS'))
        : []
    },

    credentialsLength () {
      if (this.currentRfpLawFirmRelation.proposal && this.credentials) {
        return this.credentials.subItems.length
      } else {
        return 0
      }
    },

    team () {
      if (!this.currentRfpLawFirmRelation.proposal) {
        return {}
      }
      return this.currentRfpLawFirmRelation.proposal.find(category => category.name === 'TEAM') || {}
    },

    noTeamMemberAdded () {
      if (this.currentRfp.terms.qualityScoreCriteria.includes('TEAM')) {
        return !(this.team && this.team.subItems && this.team.subItems.length)
      }

      return false
    },

    requestedContent () {
      return this.currentRfp.terms.requestedCredentialType
    }
  },
  methods: {
    ...mapActions(['saveCredential', 'deleteCredential', 'checkProposalUnsavedChanges']),
    ...mapMutations(['addMissingMandatoryField', 'addInvalidField', 'removeInvalidField', 'setDraftProposalItem']),

    reset (setNewCredential = true) {
      this.setDraftProposalItem({
        key: 'credentials',
        value: (this.currentRfpLawFirmRelation.proposal && this.currentRfpLawFirmRelation.proposal.some(category => category.draft && category.name === 'CREDENTIALS'))
          ? (this.currentRfpLawFirmRelation.proposal.find(category => category.draft && category.name === 'CREDENTIALS'))
          : {
            attachments: [],
            draft: true,
            extendedFields: {},
            id: null,
            name: 'CREDENTIALS',
            subItems: []
          }
      })
      if (setNewCredential) {
        this.setDraftProposalItem({
          key: 'newCredential',
          value: {
            extendedFields: {
              description: '',
              year: '',
              involvementOfProposedTeamMembers: []
            }
          }
        })
      }
    },

    renderRole (teamMember) {
      if (teamMember.role) {
        return this.$t('dropDownOptions.CredentialRole.' + teamMember.role)
      }

      return ''
    },

    disableAddCredentials () {
      if (this.currentRfpLawFirmRelation.proposal) {
        return this.credentials && this.credentials.subItems.length === 10
      } else {
        return false
      }
    },

    showNewInput () {
      this.setDraftProposalItem({
        key: 'showNewCredentialInputForm',
        value: true
      })

      this.checkProposalUnsavedChanges()
      this.$nextTick(() => {
        const inputCard = document.querySelector('#new_credential_input')
        const top = inputCard.getBoundingClientRect().top - 70
        window.scrollBy({ top: top, left: 0, behavior: 'smooth' })
      })
    },

    deleteCredentialLocal (credential) {
      this.credentialToDelete = credential
      this.showDeleteDialog = true
    },

    deleteSelectedCredential () {
      this.deleteCredential(this.credentialToDelete)
      this.showDeleteDialog = false
    },

    cancelUpdate (credential) {
      if (this.invalidFields.includes('year-' + credential.id)) {
        this.removeInvalidField('year-' + credential.id)
      }

      credential.update = false
      this.checkProposalUnsavedChanges()
    },

    cancel () {
      this.setDraftProposalItem({
        key: 'newCredential',
        value: {
          extendedFields: {
            description: '',
            year: '',
            involvementOfProposedTeamMembers: []
          }
        }
      })

      if (this.invalidFields.includes('year-newCredential')) {
        this.removeInvalidField('year-newCredential')
      }

      this.setDraftProposalItem({
        key: 'showNewCredentialInputForm',
        value: false
      })
      this.checkProposalUnsavedChanges()
    },

    openUpdateEditor (credential) {
      this.$set(credential, 'newValue', {
        id: credential.id,
        extendedFields: {
          description: credential.extendedFields.description,
          year: credential.extendedFields.year,
          involvementOfProposedTeamMembers: credential.extendedFields.involvementOfProposedTeamMembers
        }
      })
      this.$set(credential, 'update', true)
      this.checkProposalUnsavedChanges()
    },

    async save (credential) {
      const extendedFields = !credential.id ? credential.extendedFields : credential.newValue.extendedFields
      const requiredFields = ['year', 'description']

      let isMissing = false
      requiredFields.forEach(field => {
        if (!extendedFields[field]) {
          this.addMissingMandatoryField(field + '-' + credential.id)
          isMissing = true
        }
      })

      /* for (const involvement of extendedFields.involvementOfProposedTeamMembers) {
        if (!involvement.userId) {
          this.addMissingMandatoryField('CREDENTIAL_' + credential.id + '_USER_ID_' + involvement.id)
          isMissing = true
        }

        if (!involvement.role) {
          this.addMissingMandatoryField('CREDENTIAL_' + credential.id + '_ROLE_' + involvement.id)
          isMissing = true
        }
      } */

      const isValidDateNumber = this.numberValidation(extendedFields.year)

      if (!isValidDateNumber) {
        this.addInvalidField('year-' + (!credential.id ? 'newCredential' : credential.id))
        return
      } else {
        this.removeInvalidField('year-' + (!credential.id ? 'newCredential' : credential.id))
      }

      if (isMissing) {
        return
      }

      if (!credential.id) {
        await this.saveCredential({ credential })
        await this.cancel()
        await this.reset()
      } else {
        await this.saveCredential({ credential: credential.newValue })
        credential.update = false
      }
      this.checkProposalUnsavedChanges()
    },

    numberValidation (value) {
      let actualYear = (new Date()).getFullYear()
      if (!isNaN(value)) {
        if (value > 1800 && value <= actualYear) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .v-card:not(:last-child)
    margin-bottom 10px

  >>> .v-messages
    display none

  .credential.v-card
    grid-column-start 1
    grid-column-end 3

  .title-grid
    display grid
    grid-template-columns 1fr 40px 40px

  .v-icon:before
    font-size 15px

  .grid
    display grid
    grid-template-columns 1fr 200px

  .add-btn
    border-radius 5px

    &:not(:disabled) >>> .v-icon:before
      color $dark-gray !important

  .no-credentials-card
    grid-column-start 1
    grid-column-end 3
    border-radius 10px
    padding 10px !important
    text-align center

  .credential-btn
    justify-self center
    margin 0px
    margin-top 2px
    height 24px
    width 22px

</style>
