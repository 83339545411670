<template>
  <div>
    <h2>{{ $t('settings.title') }}</h2>
    <v-card class="profile">
      <SingleLineField
        mandatory
        @input="nameMissing = false"
        :invalid="nameMissing"
        :translatedName="'settings.profile.fullName' | t"
        v-model="user.fullName" />

      <div>
        <span style="padding-left: 12px">{{ 'settings.profile.position' | t }}</span>
        <v-select
          label=""
          v-model="user.position"
          :items="userIsCorporate ? corporateUserPositionOptions : lawfirmUserPositionOptions"
          solo
          flat
        ></v-select>
      </div>

      <SingleLineField
        mandatory
        :translatedName="'settings.profile.email' | t"
        @input="mailMissing = false"
        :invalid="mailMissing"
        :disabled="!userIsAdmin"
        :class="{tooltipSettings: !userIsAdmin}"
        :infoTootltip="!userIsAdmin"
        v-model="user.email" />

      <SingleLineField
        :translatedName="'settings.profile.phone' | t"
        v-model="user.phone" />

      <SingleLineField
        :translatedName="'settings.profile.mobile' | t"
        v-model="user.mobile" />

      <SingleLineField
        :translatedName="'settings.profile.companyName' | t"
        v-model="user.companyName" />

      <SelectField
        :translatedName="'settings.profile.language' | t"
        :options="languageOptions"
        v-model="user.language" />

      <div class="action">
        <v-btn @click="showChangeMfaMethodDialog = true" flat style="border-radius: 5px">{{ 'settings.profile.changeMfaMethod' | t }}</v-btn>
        <v-btn @click="showChangePasswordDialog = true" flat style="border-radius: 5px">{{ 'settings.profile.changePassword' | t }}</v-btn>
        <v-btn class="main" @click="updateProfile">{{ 'settings.profile.saveProfile' | t }}</v-btn>
      </div>
    </v-card>

    <v-snackbar
      v-model="savedSuccessfully">
      <p style="color: white; margin-bottom: 0;">{{ 'settings.profile.successfullySaved' | t  }}</p>
      <v-btn
        icon
        @click="savedSuccessfully = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>

    <PasswordResetDialog
      v-if="showChangePasswordDialog"
      @visibilityChange="showChangePasswordDialog = false"
      visible
      closeable />
    <ChangeMfaMethodDialog
      :visible="showChangeMfaMethodDialog"
      @visibilityChange="showChangeMfaMethodDialog = false"
    />
  </div>
</template>

<script>
import SingleLineField from '../../generalComponents/inputFields/SingleLineField'
import SelectField from '../../generalComponents/inputFields/SelectField'
import { mapState, mapActions, mapGetters } from 'vuex'
import { emailRegEx } from '../../../util/constants'
import PasswordResetDialog from '../../dashboard/components/PasswordResetDialog'
import ChangeMfaMethodDialog from './ChangeMfaMethodDialog'

export default {
  name: 'Profile',
  components: { ChangeMfaMethodDialog, PasswordResetDialog, SingleLineField, SelectField },
  data () {
    return {
      nameMissing: false,
      mailMissing: false,
      savedSuccessfully: false,
      showChangePasswordDialog: false,
      showChangeMfaMethodDialog: false,
      user: {
        fullName: '',
        position: '',
        email: '',
        phone: '',
        mobile: '',
        companyName: ''
      }
    }
  },
  async created () {
    await this.loadAllUsers()
    const user = this.allUsers.find(user => user.id === this.userData.userId)

    if (user) {
      this.user = { ...user }
    }
  },
  computed: {
    ...mapState({
      userData: state => state.userData,
      allUsers: state => state.admin.allUsers
    }),
    ...mapGetters(['userIsAdmin', 'userIsCorporate', 'lawfirmUserPositionOptions', 'corporateUserPositionOptions', 'languageOptions'])
  },
  methods: {
    ...mapActions(['loadAllUsers', 'updateUser']),

    async updateProfile () {
      if (!this.user.fullName) {
        this.nameMissing = true
      }

      if (!this.user.email) {
        this.mailMissing = true
      } else {
        this.mailMissing = !this.user.email.match(emailRegEx)
      }

      if (this.mailMissing || this.nameMissing) {
        return
      }

      await this.updateUser(this.user)
      const user = this.allUsers.find(user => user.id === this.userData.userId)

      if (user) {
        this.user = { ...user }
      }

      this.$i18n.i18next.changeLanguage(this.user.language)

      this.savedSuccessfully = true
      setTimeout(() => {
        this.savedSuccessfully = false
      }, 4000)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"
  .tooltipSettings :deep(.v-icon)
    color $icon-gray !important
    font-size 15px !important

  .profile
    border-radius 10px
    padding 20px
    margin 20px 0px
    display grid
    grid-template-columns 1fr
    grid-row-gap 10px

  .action
    text-align right

  .fade-enter-active,
  .fade-leave-active
    transition opacity .5s

  .fade-enter,
  .fade-leave-to
    opacity 0

  :deep(.v-input__slot)
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  :deep(.v-text-field__details)
    display none
</style>
