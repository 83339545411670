<template>
  <div class="grid" :class="{small: small, large: large}" :style="small && smallSpace ? `width: ${filteredOptions.length / 4 * 100}%; grid-template-columns: repeat(${filteredOptions.length}, 1fr)` : ''">
    <SelectableCard
      v-for="option in filteredOptions"
      :paddings="paddings"
      :disabled="disabled"
      :key="option.value"
      :title="option.text"
      text=""
      :selected="value === option.value"
      @selected="handleSelected(option)"
      :has-tooltip="hasTooltip(option)"
      :tooltip-text="tooltipText(option)"
      :small="small"
      :hideSelect="true" >

      <div class="selectableSubOptions">
      <v-radio-group
        v-show="shouldShowSubOptions(option) && finishedTransition"
        style="margin-top: 0px"
        class="opacity"
        :class="{missing: subOptionsMissing, 'opacity-apply': opacityFlag}"
        :disabled="value !== option.value || disabled"
        :value="value === option.value ? subOptionValue : ''"
        @change="value => $emit('update:subOptionValue', value)">
        <v-radio
          v-for="radioOption in subOptions"
          :key="radioOption.value"
          :value="radioOption.value"
          color="#fff"
          style="margin-left: 12px;">
            <template slot="label">
              {{radioOption.text}}&nbsp;
              <TooltipHolder
                class="info-icon"
                v-if="radioOption.value === 'SENIOR_LEVELS'"
                :spanId="radioOption.value + option.value"
                :width="400"
                :moveTop="60"
                :icon="'info'"
                :tooltipList="[
                  $t('tooltips.seniorityLevelSeniorPartnerTooltip'),
                  $t('tooltips.seniorityLevelJuniorPartnerTooltip'),
                  $t('tooltips.seniorityLevelSeniorAssociateTooltip'),
                  $t('tooltips.seniorityLevelAssociateTooltip')
                ]"
                right
                summaryTooltip />
            </template>
          </v-radio>
      </v-radio-group>
      <div class="divGrow" :class="{divGrowApply: growCards}"></div>
    </div>

    </SelectableCard>
  </div>
</template>

<script>
import SelectableCard from './SelectableCard'
import { mapGetters, mapState } from 'vuex'
import TooltipHolder from '../generalComponents/TooltipHolder'

export default {
  name: 'SelectableCardRadio',
  components: {
    SelectableCard,
    TooltipHolder
  },
  props: {
    // the current value
    // use with v-model
    value: {
      required: true,
      validator (value) {
        return typeof value === 'string' || !value
      }
    },

    // the value of the sub option
    subOptionValue: {
      type: [String, Array],
      required: false,
      default: ''
    },

    smallSpace: {
      type: Boolean,
      default: false
    },
    // an array of {value: String, text: String} objects, representing the selectable options
    options: {
      type: Array,
      required: true
    },

    // Changes the component to a smaller card
    small: {
      type: Boolean,
      default: false,
      required: false
    },

    large: {
      type: Boolean,
      required: false,
      default: false
    },

    /* props that should receive a function that evaluates the card type. If its a card that need to show sub options
    the function needs to resolve to true so the sub options are shown when selected */
    shouldShowSubOptions: {
      type: Function,
      default: () => false,
      required: false
    },

    /* prop that recieves a function to evaluate if a card will have sub options, the difference between this and shouldShowSubOptions
    is this evaluates if the component has sub options and the other is if they should be displayed in that moment in the view */
    hasSubOptions: {
      type: Function,
      default: () => false,
      required: false
    },

    subOptions: {
      type: Array,
      required: false,
      default: () => ([])
    },

    subOptionsMissing: {
      type: Boolean,
      default: false,
      required: false
    },

    hasTooltip: {
      type: Function,
      default: () => false,
      required: false
    },

    tooltipText: {
      type: Function,
      default: () => '',
      required: false
    },

    // Prop for the Card status, if the rfp has a selected option that requiere the suboption should pass as true
    shouldStartShowingOptions: {
      type: Boolean,
      default: false,
      required: false
    },
    // Prop for adding paddings between cards
    paddings: {
      type: Boolean,
      default: false,
      required: false
    },

    onlySelected: {
      type: Boolean,
      default: false,
      required: false
    },

    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      finishedTransition: false,
      opacityFlag: false,
      growCards: false
    }
  },
  created () {
    if (this.shouldStartShowingOptions) {
      this.finishedTransition = true
      this.opacityFlag = true
      this.growCards = true
    }
  },
  computed: {
    ...mapGetters(['feeStructureSubOptions', 'qualityScoreOptions']),
    ...mapState({
      currentRfp: state => state.currentRfp
    }),

    filteredOptions () {
      if (this.onlySelected) {
        return this.options.filter(option => this.value === option.value)
      }

      return this.options
    }
  },
  methods: {
    async handleSelected (option) {
      this.emitInput(option)
      if (['RATE_CARD_CAP', 'ESTIMATE_RATE_CARD', 'RATE_CARD'].includes(option.value)) {
        if (!this.growCards) {
          this.growCards = true
          await new Promise((resolve) => {
            setTimeout((setfinishedTransition, setOpacityFlag) => {
              setfinishedTransition()

              setTimeout((setOpacityFlag) => {
                setOpacityFlag()
              }, 40, setOpacityFlag)
            }, 1000, this.setfinishedTransition, this.setOpacityFlag)
          })
        }
      } else {
        this.growCards = false
        this.opacityFlag = false
        this.finishedTransition = false
      }
    },

    emitInput (option) {
      this.$nextTick(() => {
        this.$emit('input', option.value)
      })
    },

    setOpacityFlag () {
      this.opacityFlag = true
    },

    setfinishedTransition () {
      this.finishedTransition = true
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"
  .selectableSubOptions
    display grid
    grid-template-columns 1fr auto

  .divGrow
    height 0px
    transition height .9s
    &.divGrowApply
      height 70px

  .grid
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-column-gap 15px
    border: 2px solid rgba(0,0,0,0)

  .small
    grid-template-columns 1fr 1fr 1fr 1fr

  .large
    grid-template-columns 1fr 1fr

  .missing {
    border-color: $red
    border-radius: 10px
  }

  .missing-sub-item .selected >>> .v-input--selection-controls{
    border-color: $red
    border-radius: 10px
  }

  >>> .v-input--selection-controls {
    border: 1px solid rgba(0,0,0,0)
    margin-top 5px

    .v-messages {
      display none
    }
  }

  >>> .v-icon {
    font-size: 25px !important;
  }

  >>> .v-input__slot {
    margin-bottom 0 !important
  }

  .opacity
    opacity 0
    transition: opacity .3s ease-in-out
    &.opacity-apply
      opacity 1

  .fade-enter-to, .fade-leave
    height 70px

  .fade-enter-active
    height 70px
    transition: height .3s linear .3s

  .fade-leave-active
    height 70px
    transition: height .3s linear .3s

  .fade-enter, .fade-leave-to
    height: 0px !important;

  >>> .v-input--radio-group
    border 2px solid rgba(0,0,0,0)

    &.missing
      border 2px solid $red !important
      border-radius 10px !important

    .v-radio:last-child
      margin-bottom 6px
</style>
