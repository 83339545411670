<template>
  <div>
    <div style="display: grid; grid-template-columns: 1fr 200px;">
      <h2>{{ 'scopeGeneratorAdminView.subject' | t }}</h2>
      <v-btn
        class="main"
        @click="$router.push({ name: 'scopeGeneratorInputView' })">{{ 'scopeGeneratorAdminView.openInputView' | t }}</v-btn>
    </div>
    <v-data-table
      must-sort
      :pagination.sync="sorting"
      :headers="subjectHeaders"
      :items="subjects"
      :hide-actions="true"
      class="styled-table"
      :no-data-text="'scopeGeneratorAdminView.noDataTexts.subject' | t">

      <template slot="headerCell" slot-scope="props">
        <span
          @click.stop="addSubjectVisible = (props.header.addSubjectButton ? true : addSubjectVisible) "
          :class="{'add-btn': props.header.addSubjectButton}">
          <v-icon v-if="props.header.addSubjectButton" style="font-size: 18px">add_circle</v-icon>
          <span>{{ (props.header.text ? `scopeGeneratorAdminView.tableHeaders.subject.${props.header.text}` : '') | t }}</span>
        </span>
      </template>
      <template v-slot:items="props">
        <tr class="clickable" @click="$router.push({ name: 'scopeGeneratorLayoutView', params: { key: props.item.key } })">
          <td>
            <v-icon>{{ props.item.active ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
          </td>
          <td>
            {{ props.item.translatedLabel }}
          </td>
          <td>
            {{ props.item.mandateTypes.map(type => $t('dropDownOptions.MandateType.' + type)).join(', ') }}
          </td>
          <td>
            {{ props.item.mandateSubTypes.map(type => $t('dropDownOptions.MandateSubType.' + type)).join(', ') }}
          </td>
          <td>
            <v-btn flat @click.stop="subjectToUpdate = props.item; addSubjectVisible = true"><v-icon>edit</v-icon></v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <h2 style="margin-top: 20px">{{ 'scopeGeneratorAdminView.optionLists' | t }}</h2>
    <v-data-table
      must-sort
      :headers="optionListHeaders"
      :items="customOptionLists"
      :hide-actions="true"
      class="styled-table"
      :no-data-text="'scopeGeneratorAdminView.noDataTexts.subject' | t">

      <template slot="headerCell" slot-scope="props">
        <span
          @click.stop="addOptionListVisible = (props.header.addOptionListButton ? true : addOptionListVisible) "
          :class="{'add-btn': props.header.addOptionListButton}">
          <v-icon v-if="props.header.addOptionListButton" style="font-size: 18px">add_circle</v-icon>
          <span>{{ (props.header.text ? `scopeGeneratorAdminView.tableHeaders.optionList.${props.header.text}` : '') | t }}</span>
        </span>
      </template>
      <template v-slot:items="props">
        <tr>
          <td>
            {{ props.item.key }}
          </td>
          <td>
            {{ props.item.options.map(option => option.translatedLabel).join(', ') }}
          </td>
          <td>
            <v-btn flat @click.stop="optionListToUpdate = props.item; addOptionListVisible = true"><v-icon>edit</v-icon></v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <UpdateSubjectDialog
      v-model="addSubjectVisible"
      @saved="key => $router.push({ name: 'scopeGeneratorLayoutView', params: { key } })"
      @visibilityChange="subjectToUpdate = null"
      :subject-to-update="subjectToUpdate" />

    <UpdateOptionListDialog
      v-model="addOptionListVisible"
      @visibilityChange="optionListToUpdate = null"
      :option-list-update="optionListToUpdate" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import UpdateSubjectDialog from './components/UpdateSubjectDialog'
import UpdateOptionListDialog from './components/UpdateOptionList'

export default {
  name: 'ScopeGeneratorAdminView',
  components: { UpdateOptionListDialog, UpdateSubjectDialog },
  data () {
    return {
      subjectHeaders: [
        {
          text: 'active',
          align: 'left',
          sortable: 'true',
          value: 'active'
        },
        {
          text: 'subject',
          align: 'left',
          sortable: 'true',
          value: 'translatedLabel'
        },
        {
          text: 'mandateTypes',
          align: 'left',
          sortable: 'true',
          value: 'mandateTypes'
        },
        {
          text: 'mandateSubTypes',
          align: 'left',
          sortable: 'true',
          value: 'mandateSubTypes'
        },
        {
          text: 'addSubjectButton',
          align: 'left',
          sortable: false,
          addSubjectButton: true,
          width: 150
        }
      ],
      optionListHeaders: [
        {
          text: 'key',
          align: 'left',
          sortable: 'true',
          value: 'key'
        },
        {
          text: 'options',
          align: 'left',
          sortable: 'true',
          value: 'options'
        },
        {
          addOptionListButton: true,
          text: 'addOptionListButton',
          align: 'left',
          sortable: false,
          value: 'edit',
          width: 150
        }
      ],
      addSubjectVisible: false,
      addOptionListVisible: false,
      subjectToUpdate: null,
      optionListToUpdate: null,
      sorting: {
        sortBy: 'translatedLabel',
        rowsPerPage: -1
      }
    }
  },
  created () {
    this.loadAllSubjects(true)
  },
  computed: {
    ...mapGetters(['customOptionLists']),
    ...mapState({
      subjects: state => state.admin.scopeGenerator.subjects
    })
  },
  methods: {
    ...mapActions(['loadAllSubjects'])
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .v-icon,
  >>> .v-icon {
    font-size: 24px !important;
  }

  .add-btn {
    height: 24px;
    width: 122px;
    display: inline-block;
    position: relative;

    span {
      position: absolute
      top: 4px;
      left: 30px
    }

    &:hover {
      cursor: pointer
    }
  }

  .styled-table >>> .v-input--checkbox .v-icon {
    color: $dark-gray !important
  }

  .scroll {
    max-width 1008px
    overflow-x: auto
  }

  >>> table.v-table tbody td:not(:nth-child(1)) {
    padding 0 5px 0 12px
    word-break: break-word !important
  }

  >>> table.v-table thead tr th {
    padding 0 12px
    max-width 150px
  }

  tr.clickable:hover
    cursor pointer
</style>
