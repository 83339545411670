<template>
  <v-form class="login-form" @submit.prevent="waitButtonToEnd(handleMfaVerification)">
    <p class="inputLabel"><label class="sr-only">{{ (mfaMethod === 'EMAIL' ? 'login.mfaCodeEmail' : 'login.mfaCodeAuthenticator') | t }}</label></p>

    <div class="field-wrapper">
      <div v-for="i in [0,1,2,3,4,5]" v-bind:key="i" class="v-input v-text-field v-text-field--single-line v-text-field--solo v-text-field--solo-flat v-text-field--enclosed theme--light"><div class="v-input__control"><div class="v-input__slot"><div class="v-text-field__slot">
        <input autocomplete="one-time-code" type="text" @paste="event => paste(event)" @input="e => keyboardInput(e, i)" v-model="code[i]" ref="input"  maxlength="2" pattern="[0-9]*" inputmode="numeric">
      </div></div><div class="v-text-field__details"><div class="v-messages theme--light"><div class="v-messages__wrapper"></div></div></div></div></div>
    </div>

    <p v-if="error !== false" class="alertText" style="padding-bottom: 16px">{{ "login.mfaAlert" | t }}</p>
    <div class="sub-grid">
      <span></span>
      <router-link :to="{ name: 'resetPassword' }" style="margin-bottom: 12px">{{ "login.passwordHelp" | t }}</router-link>
    </div>

    <AwaitButton style="margin-left: 50px; color: white !important" class="main" type="submit" color="secondary" :custom-loading="isButtonExecuting" emit-click>
      {{ (isButtonExecuting ? '' : "buttons.login") | t }}
    </AwaitButton>
  </v-form>
</template>

<script>
import AwaitButton from '../generalComponents/AwaitButton'
import { mapActions } from 'vuex'
export default {
  name: 'MfaCodeForm',
  components: { AwaitButton },
  props: {
    mfaMethod: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      error: false,
      code: ['', '', '', '', '', '']
    }
  },
  mounted () {
    this.$refs.input[0].focus()
  },
  methods: {
    ...mapActions(['verifyMfa']),
    async handleMfaVerification () {
      this.isButtonExecuting = true
      try {
        const results = await Promise.all([
          this.verifyMfa({ code: this.code.join(''), tempMfa: false }),
          new Promise((resolve) => { setTimeout(resolve, 1000) })
        ])
        const mfaVerificationSuccessful = results[0]
        if (mfaVerificationSuccessful) {
          this.$router.push({
            name: 'dashboardRfps'
          })
        } else {
          this.error = true
        }
      } finally {
        this.isButtonExecuting = false
      }
    },

    paste (event) {
      const text = event.clipboardData.getData('text').replace(/\s+/g, '')

      if (text.length === 6) {
        this.code = text.split('')
        this.handleMfaVerification()
      }
    },

    keyboardInput (event, index) {
      console.log('keyboardInput', event, index)
      if (event.inputType === 'deleteContentBackward') {
        if (index > 0) {
          this.$refs.input[index - 1].focus()
        }
      } else {
        if (this.code[index].length > 1) {
          this.$set(this.code, index, this.code[index].substring(1) === event.data ? this.code[index].substring(1) : this.code[index].charAt(0))
        }

        if (index === 5 && this.code.every(x => !!x)) {
          this.handleMfaVerification()
        }

        if (index < 5) {
          this.$refs.input[index + 1].focus()
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../../assets/css/variables.styl"

.field-wrapper {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;

  :deep(input) {
    text-align: center !important;
    font-size: 24px !important;
  }
}

.login-form :deep(button) {
  margin-right: 0;
  margin-left: 0;
  width 200px
  float right
  border-radius 5px
  border solid grey 1px
  &.customerType {
    width 50%
    height 40px
    border-radius 5px
    &.leftBtn {
      border-top-right-radius 0px
      border-bottom-right-radius 0px
    }
    &.rightBtn {
      border-top-left-radius 0px
      border-bottom-left-radius 0px
      border-left: 0
    }
    &.active {
      color white
      background-color: $maroon100 !important
    }
    &.missingRole {
      color: $red !important
      border-width 2px
      border-color $red
    }
  }
}

:deep(.v-messages)
  display none

:deep(.v-input__slot)
  margin-bottom 0 !important

.alertText
  justify-self end
  margin-bottom 0
  padding-top 4px
  color: $red

:deep(.v-text-field)
  .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

:deep(.v-text-field__details)
  display none

a
  color $dark-gray
  font-size 16px
  height 20px
  padding-top 4px
  text-decoration none
  float right
  text-align right

.sub-grid
  display grid
  grid-template-columns 1fr 200px
  margin-bottom 8px

  .v-input
    margin-top 0

.v-alert
  padding 8px
  width 100% !important

.inputLabel
  margin-bottom 5px

:deep(.v-progress-circular)
  svg, div, circle
    color $maroon50 !important
</style>
