<template>
  <div class="summary-panel">
    <v-card class="grid" style="grid-row-gap: 0">
      <template v-if="!rfpHasOldScope">
        <div style="grid-column: 1 / 3; ">
          <h3>
            {{ "newRFP.scopeDefinition.facts" | t }}
            <ChangeIcon v-if="freeScopeFieldChangedToLastVersion('facts')"></ChangeIcon>
          </h3>
          <p class="override" style="border-top: none;" v-html="currentRfp.freeScope.facts"></p>
        </div>

        <div style="grid-column: 1 / 3; padding-top: 0; border-top: none;">
          <h3 class="border-top">
            {{ "newRFP.scopeDefinition.requestedServices" | t }}
            <ChangeIcon v-if="freeScopeFieldChangedToLastVersion('requestedServices')"></ChangeIcon>
          </h3>
          <p class="override" style="grid-column: 1 / 3; border-top: none;" v-html="currentRfp.freeScope.requestedServices"></p>
        </div>

        <div v-if="currentRfp.freeScope.governingLaw" style="grid-column: 1 / 3; padding-top: 0; border-top: none;">
          <h3 class="border-top">
            {{ "newRFP.scopeDefinition.governingLaw" | t }}
            <ChangeIcon v-if="freeScopeFieldChangedToLastVersion('governingLaw')"></ChangeIcon>
          </h3>
          <p class="override" v-html="currentRfp.freeScope.governingLaw"></p>
        </div>

        <div v-if="currentRfp.freeScope.areasOfLaw" style="grid-column: 1 / 3; padding-top: 0; border-top: none;">
          <h3 class="border-top">
            {{ "newRFP.scopeDefinition.areasOfLaw" | t }}
            <ChangeIcon v-if="freeScopeFieldChangedToLastVersion('areasOfLaw')"></ChangeIcon>
          </h3>
          <p class="override" v-html="currentRfp.freeScope.areasOfLaw"></p>
        </div>

        <div style="grid-column: 1 / 3; padding-top: 0; border-top: none;">
          <h3 class="border-top">
            {{ "newRFP.scopeDefinition.timeline" | t }}
            <ChangeIcon v-if="freeScopeFieldChangedToLastVersion('timeline')"></ChangeIcon>
          </h3>
          <p class="override" v-html="currentRfp.freeScope.timeline"></p>
        </div>

        <div style="grid-column: 1 / 3; padding-top: 0; border-top: none;">
          <h3 class="border-top">
            {{ "newRFP.scopeDefinition.languages" | t }}
            <ChangeIcon v-if="freeScopeFieldChangedToLastVersion('languages')"></ChangeIcon>
          </h3>
          <p class="override" v-html="formattedLanguages"></p>
        </div>
      </template>
      <p v-else class="override" style="grid-column: 1 / 3; border-top: none;" v-html="currentRfp.quickScope"></p>

      <template v-if="filteredAttachements.length">
        <h3 class="border-top">
          {{ "activeRFP.summarySection.scopeDetails.documents" | t }}
          <ChangeIcon v-if="scopeFilesChanged"></ChangeIcon>
        </h3>
        <ul class="border-top">
          <li v-for="attachment in filteredAttachements" :key="attachment.url">
            <FileChip :file="attachment" />
          </li>
        </ul>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FileChip from '../../../generalComponents/FileChip'
import languages from 'language-list'
import ChangeIcon from '@/views/generalComponents/ChangeIcon'

export default {
  name: 'ScopeDefinitionPanel',
  components: { ChangeIcon, FileChip },
  props: {
    currentRfp: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(['rfpHasOldScope', 'freeScopeFieldChangedToLastVersion', 'scopeFilesChanged']),

    filteredAttachements () {
      return this.currentRfp.attachments.filter(document => document.rfpFileType === 'SCOPE')
    },

    formattedLanguages () {
      if (!this.currentRfp || !this.currentRfp.freeScope || !this.currentRfp.freeScope.languages) {
        return ' - '
      }
      const lang = languages()
      return this.currentRfp.freeScope.languages.map(code => lang.getLanguageName(code)).sort().join(', ')
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  a
    text-decoration none
    color inherit

  li
    list-style none
</style>
