<template>
  <div style="display:grid;">
    <v-btn
      v-if="!disabled && !value && !editMode"
      @click="editMode = true; $emit('toggle')"
      style="justify-self: center; width: max-content"
      flat>
      <v-icon>edit</v-icon>
      Add a comment
    </v-btn>

    <div v-else-if="editMode">
      <v-textarea
        style="margin-top: 5px;"
        v-if="!disabled"
        :value="value"
        @input="v => $emit('input', v)"
        hide-details
        solo flat label="" rows="2"></v-textarea>
      <div style="width: 100%; display: grid;">
        <v-btn
          v-if="!disabled"
          @click="$emit('save'); editMode = false"
          right class="main" style="justify-self: end; width: max-content; margin-right: -15px;">{{ $t('activeRFP.proposal.saveComment') }}</v-btn>
      </div>
    </div>

    <p v-else-if="!disabled">
      {{ value }}<br>
      <strong style="font-size: 13px; margin-right: 10px">{{ updateTime ? formatTimestamp(updateTime, 'DD.MM.YYYY HH:mm') : '' }}</strong>
      <span class="edit-answer" @click="editMode = true; $emit('toggle')"><v-icon class="edit-answer">edit</v-icon> {{ 'activeRFP.q&a.edit' | t }}</span>
      <span class="delete-answer" @click="$emit('input', ''); $emit('save')"><v-icon class="delete-answer">delete</v-icon> {{ 'activeRFP.q&a.delete' | t }}</span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LawFirmRatingComment',
  props: {
    value: String,
    disabled: Boolean,
    updateTime: String
  },
  data () {
    return {
      editMode: false
    }
  },
  computed: {
    ...mapGetters(['formatTimestamp'])
  }
}
</script>

<style scoped lang="stylus">
@import "../../../assets/css/variables.styl"

.edit-answer, .delete-answer
  font-weight bold

  .v-icon
    font-size 14px

  &:hover
    border-radius 5px
    cursor pointer

.edit-answer
  color $dark-gray !important

.delete-answer
  color $red !important
  margin-left 10px

</style>
