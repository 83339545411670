<template>
  <div>
    <h2>{{'activeRFP.proposal.firstBid' | t }}</h2>
    <AuctionBidPanel ref="firstBid" :duration="duration" :relevantBidKey="relevantBidKey" proposalView />
  </div>
</template>

<script>
import AuctionBidPanel from './AuctionBidPanel'
export default {
  name: 'AuctionBidPanelWrapper',
  components: { AuctionBidPanel },
  props: {
    duration: {
      required: false
    },
    relevantBidKey: {
      required: false
    }
  }
}
</script>

<style scoped>

</style>
