import { render, staticRenderFns } from "./FileChip.vue?vue&type=template&id=276b35b2&scoped=true&"
import script from "./FileChip.vue?vue&type=script&lang=js&"
export * from "./FileChip.vue?vue&type=script&lang=js&"
import style0 from "./FileChip.vue?vue&type=style&index=0&id=276b35b2&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "276b35b2",
  null
  
)

export default component.exports