export default {

  validate ({ state, commit, dispatch, getters }, { requiredFields, object }) {
    object = object || getters.currentRfpOrDraft

    commit('resetMandatoryFields')

    requiredFields.forEach(field => {
      if (field.includes('/')) {
        const [subObject, fieldName] = field.split('/')

        if (!object[subObject] ||
          object[subObject][fieldName] === null ||
          object[subObject][fieldName] === '' ||
          (Array.isArray(object[subObject][fieldName]) && object[subObject][fieldName].length === 0)) {
          commit('addMissingMandatoryField', field)
        }
      } else {
        const isMissing = Array.isArray(object[field]) ? object[field].length < 2 : (object[field] === undefined || object[field] === null || object[field] === '')

        if (isMissing) {
          commit('addMissingMandatoryField', field)
        }
      }
    })
  }

}
