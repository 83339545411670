<template>
  <div>
    <span class="light" :class="{ mandatory: mandatory && !readonly, light: readonly  }">{{ translatedName }}</span>
    <TooltipHolder
      v-if="infoTootltip"
      :icon="'info'"
      :tooltipText="$t('tooltips.contactLintumMailTooltip')"
      :moveTop="60"
      :width="200"
      right />
    <v-text-field
      v-if="!readonly"
      label=""
      :value="value"
      :class="{ invalid }"
      @input="value => $emit('input', value)"
      :disabled="disabled"
      solo
      flat
      :autocomplete="autocomplete"
      @blur="$emit('blur')"
      :type="type"
      :append-icon="appendIcon"
      @click:append="$emit('clickAppend')"
    ></v-text-field>
    <p v-else>{{ value }}</p>
  </div>
</template>

<script>
import InputFieldMixin from './InputFieldMixin'
import TooltipHolder from '../TooltipHolder'

export default {
  name: 'SingleLineField',
  mixins: [InputFieldMixin],
  components: { TooltipHolder },
  props: {
    appendIcon: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    infoTootltip: {
      type: Boolean,
      required: false,
      default: false
    },
    autocomplete: {
      type: String
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"
  span:not(.light)
    padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input--is-disabled .v-input__slot
    background $light-gray !important

  >>> .v-text-field__details
    display none

  .invalid,
  .invalid >>> .v-input__slot
    color $red
    border-color $red

    input
      color $red
</style>
