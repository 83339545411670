<template>
  <div id="chart-container" />
</template>

<script>
import Chart from 'chart.js'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AuctionDiagram',
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.paint()
  },
  watch: {
    orderedBids () {
      this.paint()
    }
  },
  computed: {
    ...mapState({
      lawFirms: state => state.lawFirms,
      showNotificationPanel (state) { return state.auction.showNotificationPanel }
    }),
    ...mapGetters(['formatNumber', 'orderedBids'])
  },
  methods: {

    paint () {
      const container = document.getElementById('chart-container')
      let child = container.lastElementChild
      while (child) {
        container.removeChild(child)
        child = container.lastElementChild
      }

      const canvas = document.createElement('canvas')
      container.appendChild(canvas)
      canvas.style = 'height: 280px; width: 100%;'
      const context = canvas.getContext('2d')

      const bidsHighToLow = this.orderedBids.slice().reverse()
      const data = bidsHighToLow.map(bid => bid.relevant_display_value)
      const labels = Array(data.length).fill('')

      this.chart = new Chart(context, {
        // The type of chart we want to create
        type: 'bar',

        // The data for our dataset
        data: {
          labels: labels,
          datasets: [{
            label: '',
            backgroundColor: data.map((value, index) => {
              if (index === data.length - 1) {
                return '#AE9664'
              } else {
                return 'rgb(163, 163, 174)'
              }
            }),
            borderColor: 'rgb(163, 163, 174)',
            data: data
          }]
        },

        // Configuration options go here
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                maxBarThickness: 15
              }
            ],
            yAxes: [
              {
                ticks: {
                  suggestedMin: data[data.length - 1] * 0.95,
                  suggestedMax: data[0] * 1.05,
                  callback: (value, index, values) => {
                    return this.formatNumber(value)
                  }
                }
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const lawFirmId = bidsHighToLow[tooltipItem.index].law_firm_id
                const lawFirm = this.lawFirms.find(lawFirm => lawFirm.id.toString() === lawFirmId.toString())
                const label = lawFirm.name + ': ' + this.formatNumber(tooltipItem.yLabel)
                return label
              }
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
