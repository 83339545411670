import apollo from '../../apollo'
import gql from 'graphql-tag'

export default {

  async updateProposalScoreWeight ({ state, commit, getters }, proposalScoreWeight) {
    const result = await apollo(state).mutate({
      mutation: gql`
        mutation changeProposalScoreWeight ($rfpId: ID!, $weight: Float!) {
          changeProposalScoreWeight (rfpId: $rfpId, weight: $weight) {
            proposalScoreWeight
            lawFirmRelations {
              lawFirm {
                id
              }
              proposalRating
              overallStarRating
              proposal {
                id
                rating
                subItems {
                  id
                  rating
                }
              }
            }
          }
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        weight: proposalScoreWeight
      }
    })

    commit('updateProposalScoreWeight', result.data.changeProposalScoreWeight.proposalScoreWeight)

    const lawFirmRelations = result.data.changeProposalScoreWeight.lawFirmRelations
    lawFirmRelations.forEach(lawFirmRelation => commit('updateLawFirmRelationRating', lawFirmRelation))
  },

  async changeQualityScoreWeight ({ state, commit }, {
    teamScoreWeight,
    credentialsScoreWeight,
    pitchDocumentScoreWeight,
    liabilityScoreWeight,
    qaScoreWeight,
    interviewScoreWeight,
    strategyScoreWeight,
    custom1ScoreWeight,
    custom2ScoreWeight,
    custom3ScoreWeight
  }) {
    const result = await apollo(state).mutate({
      mutation: gql`
        mutation changeQualityScoreWeight (
          $rfpId: ID!, $teamScoreWeight: Float!,
          $credentialsScoreWeight: Float!, $pitchDocumentScoreWeight: Float!,
          $liabilityScoreWeight: Float!, $qaScoreWeight: Float!,
          $interviewScoreWeight: Float!,
          $strategyScoreWeight: Float!,
          $custom1ScoreWeight: Float!,
          $custom2ScoreWeight: Float!,
          $custom3ScoreWeight: Float!
        ) {
          changeQualityScoreWeight (
            rfpId: $rfpId, teamScoreWeight: $teamScoreWeight,
            credentialsScoreWeight: $credentialsScoreWeight,
            pitchDocumentScoreWeight: $pitchDocumentScoreWeight,
            liabilityScoreWeight: $liabilityScoreWeight,
            qaScoreWeight: $qaScoreWeight,
            interviewScoreWeight: $interviewScoreWeight,
            strategyScoreWeight: $strategyScoreWeight,
            custom1ScoreWeight: $custom1ScoreWeight,
            custom2ScoreWeight: $custom2ScoreWeight,
            custom3ScoreWeight: $custom3ScoreWeight,
          ) {
            proposalScoreWeight
            teamScoreWeight,
            credentialsScoreWeight,
            pitchDocumentScoreWeight,
            liabilityScoreWeight,
            qaScoreWeight,
            interviewScoreWeight,
            interviewScoreWeight,
            strategyScoreWeight,
            custom1ScoreWeight,
            custom2ScoreWeight,
            custom3ScoreWeight,
            lawFirmRelations {
              lawFirm {
                id
              }
              proposalRating
              overallStarRating
              proposal {
                id
                rating
                subItems {
                  id
                  rating
                }
              }
            }
          }
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        teamScoreWeight,
        credentialsScoreWeight,
        pitchDocumentScoreWeight,
        liabilityScoreWeight,
        qaScoreWeight,
        interviewScoreWeight,
        strategyScoreWeight,
        custom1ScoreWeight,
        custom2ScoreWeight,
        custom3ScoreWeight
      }
    })

    commit('updateQualityScoreWeight', result.data.changeQualityScoreWeight)

    const lawFirmRelations = result.data.changeQualityScoreWeight.lawFirmRelations
    lawFirmRelations.forEach(lawFirmRelation => commit('updateLawFirmRelationRating', lawFirmRelation))
  },

  async setRfpDecisionMessages ({ state, commit, getters }) {
    await apollo(state).mutate({
      mutation: gql`
          mutation setRfpDecisionMessages ($rfpId: ID!, $decisionNotes: String, $winnerMessage: String, $loserMessage: String) {
              setRfpDecisionMessages (rfpId: $rfpId, decisionNotes: $decisionNotes, winnerMessage: $winnerMessage, loserMessage: $loserMessage)
          }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        decisionNotes: state.currentRfp.decisionNotes,
        winnerMessage: state.currentRfp.winnerMessage,
        loserMessage: state.currentRfp.loserMessage
      }
    })
  }

}
