import $router from '../../router'
import dotProp from 'dot-prop'

import { isEnabled, validateMandatoryFieldsOnSubject, sectionApplicable, panelApplicable } from '../validation/constraints'

const checkFieldsSelected = (currentRfp, requiredFields) => {
  if (!currentRfp) {
    return false
  }

  for (let field of requiredFields) {
    if (field.includes('/')) {
      const [subObject, fieldName] = field.split('/')

      if (!currentRfp[subObject] ||
        currentRfp[subObject][fieldName] === null ||
        currentRfp[subObject][fieldName] === '' ||
        (Array.isArray(currentRfp[subObject][fieldName]) && currentRfp[subObject][fieldName].length === 0)) {
        return false
      }
    } else {
      const isMissing = Array.isArray(currentRfp[field]) ? currentRfp[field].length < 2 : (currentRfp[field] === null || currentRfp[field] === '')

      if (isMissing) {
        return false
      }
    }
  }

  return true
}

const sidebarIconHandler = ({ tcArray, field, icon, status }) => {
  let newTcArray = tcArray.map(optionsObj => {
    optionsObj.icon = optionsObj.idRef === field ? icon : optionsObj.icon
    optionsObj.classStatus = optionsObj.idRef === field ? status : optionsObj.classStatus
    return optionsObj
  })
  return newTcArray
}

export default {

  isEnabled: () => (fieldOrPanel, sectionKey, subjectKey, rfp, listObject, panelKey) => {
    return isEnabled(fieldOrPanel, sectionKey, subjectKey, rfp, listObject, panelKey)
  },

  sectionIsApplicable: () => (sectionKey, rootObject) => {
    return sectionApplicable(sectionKey, rootObject)
  },

  panelIsApplicable: () => (subSectionKey, rootObject) => {
    return panelApplicable(subSectionKey, rootObject)
  },

  sectionShouldBeShownOnSummary: (state, getters) => (section, subject, root) => {
    if (!getters.sectionIsApplicable(section.key, root ? root[subject.key] : null)) {
      return false
    }

    if (section.panels.length) {
      return section.panels.some(panel => {
        if (!getters.panelIsApplicable(panel.key, root ? root[subject.key] : null)) {
          return false
        }

        if (panel.isList) {
          const value = dotProp.get(root, `${subject.key}.${panel.key}`)
          if (!value || !value.length) {
            return false
          }
        }

        return panel.rows.some(row => row.fields.some(field => {
          if (!(field.enabledBy.length > 0)) {
            return !!dotProp.get(root, `${subject.key}.${field.key}`)
          } else {
            return false
          }
        }))
      })
    } else {
      return false
    }
  },

  scopeGeneratorIsCompleted (state, getters) {
    if (!getters.currentRfpOrDraft || !getters.currentRfpOrDraft.subjects || !getters.currentRfpOrDraft.scopeGeneratorLayoutsBySubject) {
      return false
    }

    for (const subject of getters.currentRfpOrDraft.subjects) {
      const violations = validateMandatoryFieldsOnSubject(getters.currentRfpOrDraft.scopeGeneratorLayoutsBySubject[subject.key], subject.key, getters.currentRfpOrDraft)
      if (!getters.objectIsEmpty(violations)) {
        return false
      }
    }
    return true
  },

  scopeGeneratorAvailable (state, getters) {
    return getters.currentRfpOrDraft && getters.currentRfpOrDraft.scopeGeneratorLayoutsBySubject
  },

  rfpHasOldScope (state, getters) {
    if (!getters.currentRfpOrDraft) {
      return false
    }

    return !getters.currentRfpOrDraft.freeScope
  },

  objectIsEmpty (state, getters) {
    return (obj) => {
      if (obj === null || obj === undefined) {
        return true
      }

      if (Array.isArray(obj)) {
        return obj.length === 0
      }

      const keys = Object.keys(obj)

      if (keys.length === 0) {
        return true
      } else {
        return keys.every(key => getters.objectIsEmpty(obj[key]))
      }
    }
  },

  keyIsValid: () => (key) => {
    return key.match(/^[a-zA-Z][a-zA-Z0-9_]*$/g)
  },

  scopeMandatoryFields (state, getters) {
    return getters.currentRfpOrDraft && !getters.currentRfpOrDraft.usesScopeGenerator ? getters.rfpHasOldScope ? ['quickScope'] : [
      'freeScope/facts',
      'freeScope/requestedServices',
      'freeScope/timeline',
      'freeScope/languages'
    ] : []
  },

  biddingParametersMandatoryFields (state, getters) {
    const requiredFields = ['auctionType', 'auctionEnd']

    if (getters.currentRfpOrDraft && getters.currentRfpOrDraft.allowQA) {
      // requiredFields.push('qaStart')
      requiredFields.push('qaEnd')
    }

    if (getters.currentRfpOrDraft && getters.currentRfpOrDraft.auctionType === 'ADVANCED') {
      requiredFields.push('firstBidDeadline')
      requiredFields.push('auctionSubType')
    }

    if (getters.currentRfpOrDraft && getters.currentRfpOrDraft.auctionType === 'E_AUCTION') {
      requiredFields.push('firstBidDeadline')
      requiredFields.push('auctionStart')
      requiredFields.push('auctionSubType')
    }

    return requiredFields
  },

  feesMandatoryFields (state, getters) {
    const requiredFields = ['terms/feeStructure', 'terms/currency']

    if (getters.currentRfpOrDraft && getters.currentRfpOrDraft.terms && ['RATE_CARD', 'RATE_CARD_CAP', 'ESTIMATE_RATE_CARD'].includes(getters.currentRfpOrDraft.terms.feeStructure)) {
      requiredFields.push('terms/feeStructureSubType')
    }
    return requiredFields
  },

  termsConditionsMandatoryFields (state, getters) {
    if (getters.currentRfpOrDraft && getters.currentRfpOrDraft.terms ? getters.currentRfpOrDraft.terms.usesTermsGenerator : false) {
      const requiredFields = [
        'terms/generalFees',
        'terms/flightExpenseType',
        'terms/trainExpenseType',
        'terms/rentalCarExpenseType',
        'terms/hotelExpenseType',
        'terms/billingType'
      ]

      if (getters.currentRfpOrDraft.terms.hotelExpenseType === 'OTHER') {
        requiredFields.push('terms/customHotelExpense')
      }
      if (getters.currentRfpOrDraft.terms.flightExpenseType === 'OTHER') {
        requiredFields.push('terms/customFlightExpense')
      }
      if (getters.currentRfpOrDraft.terms.trainExpenseType === 'OTHER') {
        requiredFields.push('terms/customTrainExpense')
      }
      if (getters.currentRfpOrDraft.terms.rentalCarExpenseType === 'OTHER') {
        requiredFields.push('terms/customRentalCarExpense')
      }

      if (!(getters.currentRfpOrDraft.terms.qualityScoreCriteria && getters.currentRfpOrDraft.terms.qualityScoreCriteria.includes('LIABILITY'))) {
        requiredFields.push('terms/liabilityType')
      }
      if (getters.currentRfpOrDraft.terms.feeStructure !== 'FIXED') {
        requiredFields.push(
          'terms/needsConsentToLeaveScope',
          'terms/internalCoordinationType',
          'terms/travelExpenseType',
          'terms/associatesExpenseType',
          'terms/traineesExpenseType',
          'terms/trainingExpenseType')

        if (getters.currentRfpOrDraft.terms.travelExpenseType === 'BILLABLE_TO_THRESHOLD') {
          requiredFields.push('terms/travelExpenseCap')
        }
      }

      if (getters.currentRfpOrDraft.terms.feeStructure === 'FIXED') {
        // requiredFields.push('terms/hasAlertThreshold')
        //
        // if (getters.currentRfpOrDraft.terms.hasAlertThreshold) {
        //   requiredFields.push('terms/alertThreshold')
        // }
      }

      if (['RATE_CARD_CAP', 'ESTIMATE_RATE_CARD'].includes(getters.currentRfpOrDraft.terms.feeStructure)) {
        requiredFields.push('terms/hasAlertThreshold')

        if (getters.currentRfpOrDraft.terms.hasAlertThreshold) {
          requiredFields.push('terms/alertThreshold')
        }
      }

      if (getters.currentRfpOrDraft.terms.liabilityType === 'MINIMUM_AMOUNT' && !(getters.currentRfpOrDraft.terms.qualityScoreCriteria && getters.currentRfpOrDraft.terms.qualityScoreCriteria.includes('LIABILITY'))) {
        requiredFields.push('terms/liabilityAmount')
      }

      if (getters.currentRfpOrDraft.terms.generalFees === 'LIMITED_BILLABLE') {
        requiredFields.push('terms/maximumFee')
      }

      if (getters.currentRfpOrDraft.terms.billingType === 'OTHER') {
        requiredFields.push('terms/billingTypeSpecification')
      }

      return requiredFields
    } else {
      return ['terms/quickTermsAndConditions']
    }
  },

  lawFirmSelectionMandatoryFields (state) {
    return [] // not mandatory any more, special check on distirbution see #672
  },

  requiredFields (state, getters) {
    switch ($router.app.$route.name) {
      case 'scopeDetailsCorporate':
        return getters.scopeMandatoryFields
      case 'billingTypeCorporate':
        return getters.biddingParametersMandatoryFields
      case 'termsAndConditionsCorporate':
        return getters.termsConditionsMandatoryFields
      case 'lawFirmSelectionCoprporate':
        return getters.lawFirmSelectionMandatoryFields
      case 'proposal':
        return getters.feesMandatoryFields
    }

    return []
  },

  scopeCompleted (state, getters) {
    return getters.currentRfpOrDraft && getters.currentRfpOrDraft.usesScopeGenerator ? getters.scopeGeneratorIsCompleted : checkFieldsSelected(getters.currentRfpOrDraft, getters.scopeMandatoryFields)
  },

  checkFieldsSelected: (state, getters) => (rfp, fields) => {
    return checkFieldsSelected(rfp || getters.currentRfpOrDraft, fields)
  },

  isRfpFieldEditable: (state, getters) => (isDraftRequired = false) => {
    if (isDraftRequired && state.currentRfp.status !== 'DRAFT') {
      return false
    }
    return ['DRAFT', 'ACTIVE_QA', 'ACTIVE_FIRST_BIDS', 'ACTIVE_FIRST_BIDS_CLOSED'].includes(state.currentRfp.status) && getters.userIsCorporate
  },

  lawFirmSelectionCompleted (state, getters) {
    return checkFieldsSelected(getters.currentRfpOrDraft, getters.lawFirmSelectionMandatoryFields)
  },

  feesCompleted (state, getters) {
    return checkFieldsSelected(getters.currentRfpOrDraft, getters.feesMandatoryFields)
  },

  termsAndConditionsCompleted (state, getters) {
    return checkFieldsSelected(getters.currentRfpOrDraft, getters.termsConditionsMandatoryFields)
  },

  billingTypeCompleted (state, getters) {
    return checkFieldsSelected(getters.currentRfpOrDraft, getters.biddingParametersMandatoryFields)
  },

  draftStep (state, getters) {
    if (!getters.scopeCompleted) {
      return 'step1SD'
    } else if (!getters.lawFirmSelectionCompleted) {
      return 'step2LS'
    } else if (!getters.feesCompleted) {
      return 'step3Fe'
    } else if (!getters.termsAndConditionsCompleted) {
      return 'step4TC'
    } else if (!getters.billingTypeCompleted) {
      return 'step5BT'
    } else {
      return 'step6Sm'
    }
  },

  termsAndContitionSidebarArray (state, getters) {
    if (getters.currentRfpOrDraft && getters.currentRfpOrDraft.terms) {
      let tcGeneratorArr = [
        { name: 'newRFP.termsAndConditions.fees', idRef: 'fees', status: true, icon: 'ic-credit-card', top: 55, classStatus: false },
        { name: 'newRFP.termsAndConditions.expenses', idRef: 'expenses', status: false, icon: 'ic-coin-euro', top: 115, classStatus: false },
        { name: 'newRFP.termsAndConditions.billing', idRef: 'billing', status: false, icon: 'ic-feed', top: 200, classStatus: false },
        { name: 'newRFP.termsAndConditions.liability', idRef: 'liability', status: false, icon: 'ic-lock', top: 200, classStatus: false }
      ]
      let editorArr = [
        { name: 'newRFP.termsAndConditions.quickConditionTitle', idRef: 'quickTitle', status: true, icon: 'ic-edit', top: 55, classStatus: false },
        { name: 'newRFP.termsAndConditions.documentUpload', idRef: 'docUpload', status: false, icon: 'ic-truck', top: 150, classStatus: false }
      ]

      if (getters.currentRfpOrDraft.terms.qualityScoreCriteria && getters.currentRfpOrDraft.terms.qualityScoreCriteria.includes('LIABILITY')) {
        tcGeneratorArr = tcGeneratorArr.filter(sideObj => sideObj.idRef !== 'liability')
      }

      if (getters.currentRfpOrDraft.terms.feeStructure === 'FIXED') {
        tcGeneratorArr = tcGeneratorArr.filter(sideObj => sideObj.idRef !== 'fees')
        tcGeneratorArr = tcGeneratorArr.map(sideObj => {
          if (sideObj.idRef === 'expenses') {
            sideObj.status = true
          }
          return sideObj
        })
      }

      if (getters.termsFeesSubsectionCompleted) {
        tcGeneratorArr = sidebarIconHandler({ tcArray: tcGeneratorArr, field: 'fees', icon: 'check_circle', status: 'finished' })
      }

      if (getters.termsExpensesSubsectionCompleted) {
        tcGeneratorArr = sidebarIconHandler({ tcArray: tcGeneratorArr, field: 'expenses', icon: 'check_circle', status: 'finished' })
      }

      if (getters.termsBillingSubsectionCompleted) {
        tcGeneratorArr = sidebarIconHandler({ tcArray: tcGeneratorArr, field: 'billing', icon: 'check_circle', status: 'finished' })
      }

      if (getters.termsLiabilitySubsectionCompleted && !(getters.currentRfpOrDraft.terms.qualityScoreCriteria && getters.currentRfpOrDraft.terms.qualityScoreCriteria.includes('LIABILITY'))) {
        tcGeneratorArr = sidebarIconHandler({ tcArray: tcGeneratorArr, field: 'liability', icon: 'check_circle', status: 'finished' })
      }

      if (getters.currentRfpOrDraft.terms.quickTermsAndConditions !== null && getters.currentRfpOrDraft.terms.quickTermsAndConditions !== '') {
        editorArr = sidebarIconHandler({ tcArray: editorArr, field: 'quickTitle', icon: 'check_circle', status: 'finished' })
      }

      const mandatoryExpensesArray = [
        'terms/generalFees',
        'terms/flightExpenseType',
        'terms/trainExpenseType',
        'terms/rentalCarExpenseType',
        'terms/hotelExpenseType']

      if (getters.currentRfpOrDraft.terms.generalFees === 'LIMITED_BILLABLE') {
        mandatoryExpensesArray.push('terms/maximumFee')
      }

      const mandatoryFeesArray = [
        'terms/needsConsentToLeaveScope',
        'terms/internalCoordinationType',
        'terms/travelExpenseType',
        'terms/associatesExpenseType',
        'terms/traineesExpenseType',
        'terms/trainingExpenseType',
        'terms/hasAlertThreshold',
        'terms/alertThreshold'
      ]
      if (getters.currentRfpOrDraft.terms.travelExpenseType === 'BILLABLE_TO_THRESHOLD') {
        mandatoryFeesArray.push('terms/travelExpenseCap')
      }

      const mandatoryBillingArray = ['terms/billingType']
      if (getters.currentRfpOrDraft.terms.billingType === 'OTHER') {
        mandatoryBillingArray.push('terms/billingTypeSpecification')
      }

      const mandatoryLiabilitArray = ['terms/liabilityType', 'terms/liabilityAmount']

      if (!mandatoryExpensesArray.every(field => state.validation.missingMandatoryFields.includes(field) === false) || state.validation.invalidFields.includes('terms/maximumFee')) {
        tcGeneratorArr = sidebarIconHandler({ tcArray: tcGeneratorArr, field: 'expenses', icon: 'error', status: 'invalid' })
      }

      if (!mandatoryFeesArray.every(field => state.validation.missingMandatoryFields.includes(field) === false) || state.validation.invalidFields.includes('terms/alertThreshold') || state.validation.invalidFields.includes('terms/travelExpenseCap')) {
        tcGeneratorArr = sidebarIconHandler({ tcArray: tcGeneratorArr, field: 'fees', icon: 'error', status: 'invalid' })
      }

      if (!mandatoryBillingArray.every(field => state.validation.missingMandatoryFields.includes(field) === false)) {
        tcGeneratorArr = sidebarIconHandler({ tcArray: tcGeneratorArr, field: 'billing', icon: 'error', status: 'invalid' })
      }

      if (!mandatoryLiabilitArray.every(field => state.validation.missingMandatoryFields.includes(field) === false) || state.validation.invalidFields.includes('terms/liabilityAmount')) {
        tcGeneratorArr = sidebarIconHandler({ tcArray: tcGeneratorArr, field: 'liability', icon: 'error', status: 'invalid' })
      }

      if (getters.currentRfpOrDraft.terms.quickTermsAndConditions === '') {
        editorArr = sidebarIconHandler({ tcArray: editorArr, field: 'quickTitle', icon: 'error', status: 'invalid' })
      }

      if (getters.currentRfpOrDraft.terms.usesTermsGenerator) {
        return tcGeneratorArr
      } else {
        return editorArr
      }
    } else {
      return null
    }
  },

  termsFeesSubsectionCompleted (state, getters) {
    let status
    const finishedFields = []
    finishedFields.push('needsConsentToLeaveScope')
    if (getters.currentRfpOrDraft && getters.currentRfpOrDraft.terms) {
      if (getters.currentRfpOrDraft.terms.feeStructure !== 'FIXED') {
        finishedFields.push(
          'internalCoordinationType',
          'travelExpenseType',
          'associatesExpenseType',
          'traineesExpenseType',
          'trainingExpenseType'
        )

        if (getters.currentRfpOrDraft.terms.travelExpenseType === 'BILLABLE_TO_THRESHOLD') {
          finishedFields.push('travelExpenseCap')
        }

        if (['RATE_CARD_CAP', 'ESTIMATE_RATE_CARD'].includes(getters.currentRfpOrDraft.terms.feeStructure)) {
          finishedFields.push('hasAlertThreshold')

          if (getters.currentRfpOrDraft.terms.hasAlertThreshold) {
            finishedFields.push('alertThreshold')
          }
        }
      } else {
        return true
        // finishedFields.push('hasAlertThreshold')
        // if (getters.currentRfpOrDraft.terms.hasAlertThreshold) {
        //   finishedFields.push('alertThreshold')
        // }
      }
      status = finishedFields.map(field => {
        return !(getters.currentRfpOrDraft.terms[field] === null || getters.currentRfpOrDraft.terms[field] === '')
      })

      return !status.includes(false)
    } else {
      return null
    }
  },

  termsExpensesSubsectionCompleted (state, getters) {
    const finishedFields = []
    finishedFields.push('generalFees', 'flightExpenseType', 'trainExpenseType', 'rentalCarExpenseType', 'hotelExpenseType')
    if (!getters.currentRfpOrDraft || !getters.currentRfpOrDraft.terms) {
      return null
    }

    if (getters.currentRfpOrDraft.terms.generalFees === 'LIMITED_BILLABLE') {
      finishedFields.push('maximumFee')
    }

    if (getters.currentRfpOrDraft.terms.hotelExpenseType === 'OTHER') {
      finishedFields.push('customHotelExpense')
    }
    if (getters.currentRfpOrDraft.terms.flightExpenseType === 'OTHER') {
      finishedFields.push('customFlightExpense')
    }
    if (getters.currentRfpOrDraft.terms.trainExpenseType === 'OTHER') {
      finishedFields.push('customTrainExpense')
    }
    if (getters.currentRfpOrDraft.terms.rentalCarExpenseType === 'OTHER') {
      finishedFields.push('customRentalCarExpense')
    }

    return finishedFields.every(field => {
      return !(getters.currentRfpOrDraft.terms[field] === null || getters.currentRfpOrDraft.terms[field] === '')
    })
  },

  termsBillingSubsectionCompleted (state, getters) {
    let status
    const finishedFields = []
    finishedFields.push('billingType')
    if (!getters.currentRfpOrDraft || !getters.currentRfpOrDraft.terms) {
      return null
    }
    if (getters.currentRfpOrDraft.terms.billingType === 'OTHER') {
      finishedFields.push('billingTypeSpecification')
    }
    status = finishedFields.map(field => {
      return !(getters.currentRfpOrDraft.terms[field] === null || getters.currentRfpOrDraft.terms[field] === '')
    })
    return !status.includes(false)
  },

  termsLiabilitySubsectionCompleted (state, getters) {
    let status
    const finishedFields = []
    finishedFields.push('liabilityType')
    if (!getters.currentRfpOrDraft || !getters.currentRfpOrDraft.terms) {
      return null
    }
    if (getters.currentRfpOrDraft.terms.liabilityType === 'MINIMUM_AMOUNT') {
      finishedFields.push('liabilityAmount')
    }
    status = finishedFields.map(field => {
      return !(getters.currentRfpOrDraft.terms[field] === null || getters.currentRfpOrDraft.terms[field] === '')
    })
    return !status.includes(false)
  },

  getValueFromPreviousVersion: (state) => (fieldName) => {
    const { historyRfpVersion } = state
    if (historyRfpVersion < 1) {
      return null
    }

    const prevVersion = state.history.find(h => h.version === historyRfpVersion - 1)
    return prevVersion ? prevVersion[fieldName] : null
  },

  getValueForCurrentVersion: (state) => (fieldName) => {
    const { historyRfpVersion } = state
    if (historyRfpVersion < 0) {
      return null
    }

    const prevVersion = state.history.find(h => h.version === historyRfpVersion)
    return prevVersion ? prevVersion[fieldName] : null
  },

  lawFirmsHaveChanged (state, getters) {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('lawFirmRelations')
    const curValue = getters.getValueForCurrentVersion('lawFirmRelations')
    if (prevValue) {
      for (const cur of curValue) {
        const old = prevValue.find(l => l.lawFirm.id === cur.lawFirm.id)
        if (old && old.requestedLawyers.length !== cur.requestedLawyers.length) {
          return true
        }
      }
    }

    return prevValue && prevValue.length !== curValue.length
  },

  fieldChangedToLastVersion: (state, getters) => (fieldName) => {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion(fieldName)
    const curValue = getters.getValueForCurrentVersion(fieldName)
    return prevValue !== curValue
  },

  freeScopeFieldChangedToLastVersion: (state, getters) => (fieldName) => {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('freeScope')
    const curValue = getters.getValueForCurrentVersion('freeScope')
    if ((!prevValue && curValue) || (prevValue && !curValue)) {
      return true
    }
    if ((!prevValue[fieldName] && curValue[fieldName]) || (prevValue[fieldName] && !curValue[fieldName])) {
      return true
    }
    if (!prevValue[fieldName] && !curValue[fieldName]) {
      return false
    }
    if (fieldName === 'languages') {
      return prevValue[fieldName].length !== curValue[fieldName].length
    }
    return prevValue[fieldName] !== curValue[fieldName]
  },

  filesChanged: (state, getters) => (type) => {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('attachments').filter(f => f.rfpFileType === type)
    const curValue = getters.getValueForCurrentVersion('attachments').filter(f => f.rfpFileType === type)
    if (prevValue.length !== curValue.length) {
      return true
    }

    // something added
    for (const curFile of curValue) {
      if (!curFile.originalFileId) {
        return true
      }
      if (!prevValue.some(v => v.originalFileId === curFile.originalFileId || v.id === curFile.originalFileId)) {
        return true
      }
    }

    // something removed
    for (const prevFile of prevValue) {
      if (!curValue.some(c => c.originalFileId === prevFile.originalFileId || c.originalFileId === prevFile.id)) {
        return true
      }
    }

    return false
  },
  quickTermsFilesChanged (state, getters) {
    return getters.filesChanged('TERMS')
  },
  scopeFilesChanged (state, getters) {
    return getters.filesChanged('SCOPE')
  },

  scopeChanged (state, getters) {
    return getters.freeScopeFieldChangedToLastVersion('facts') ||
      getters.freeScopeFieldChangedToLastVersion('requestedServices') ||
      getters.freeScopeFieldChangedToLastVersion('governingLaw') ||
      getters.freeScopeFieldChangedToLastVersion('areasOfLaw') ||
      getters.freeScopeFieldChangedToLastVersion('timeline') ||
      getters.freeScopeFieldChangedToLastVersion('languages') ||
      getters.scopeFilesChanged
  },

  interviewQualityScoreCriteriaChanged (state, getters) {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    let prevValue = getters.getValueFromPreviousVersion('terms')
    let curValue = getters.getValueForCurrentVersion('terms')

    if ((!prevValue && curValue) || (prevValue && !curValue)) {
      return true
    }

    const prevQualityScores = prevValue.qualityScoreCriteria.filter(x => x === 'INTERVIEW')
    const currentQualityScores = curValue.qualityScoreCriteria.filter(x => x === 'INTERVIEW')

    return prevQualityScores.length !== currentQualityScores.length
  },

  quylityScoreCriteriaChanged (state, getters) {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    let prevValue = getters.getValueFromPreviousVersion('terms')
    let curValue = getters.getValueForCurrentVersion('terms')

    if ((!prevValue && curValue) || (prevValue && !curValue)) {
      return true
    }

    const prevQualityScores = prevValue.qualityScoreCriteria.filter(x => x !== 'INTERVIEW')
    const currentQualityScores = curValue.qualityScoreCriteria.filter(x => x !== 'INTERVIEW')

    if (prevQualityScores.length !== currentQualityScores.length) {
      return true
    }

    // something removed
    for (const prev of prevQualityScores) {
      if (!currentQualityScores.includes(prev)) {
        return true
      }
    }
    // someting added
    for (const cur of currentQualityScores) {
      if (!prevQualityScores.includes(cur)) {
        return true
      }
    }

    return false
  },

  requestedInformationChanged (state, getters) {
    return getters.filesChanged('PITCH_DOCUMENT_DEFINITION') ||
      getters.termsFieldChanged('isCVMandatory') ||
      getters.termsFieldChanged('teamDiversity') ||
      getters.termsFieldChanged('requestedCredentialType') ||
      getters.termsFieldChanged('requestedPitchDocumentContent') ||
      getters.termsFieldChanged('requestedLiabilityContent') ||
      getters.quylityScoreCriteriaChanged
  },

  termsChanged (state, getters) {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('terms')
    const curValue = getters.getValueForCurrentVersion('terms')
    if ((!prevValue && curValue) || (prevValue && !curValue)) {
      return true
    }
    if (prevValue.usesTermsGenerator !== curValue.usesTermsGenerator) {
      return true
    }

    if (curValue.usesTermsGenerator) {
      return getters.termsFeesChanged ||
        getters.termsExpensesChanged ||
        getters.termsLiabilityChanged ||
        getters.termsBillingTypeChanged
    } else {
      if (prevValue.quickTermsAndConditions !== curValue.quickTermsAndConditions) {
        return true
      }
      return getters.quickTermsFilesChanged
    }
  },

  termsFieldChanged: (state, getters) => (field) => {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('terms')
    const curValue = getters.getValueForCurrentVersion('terms')

    if ((!prevValue && curValue) || (prevValue && !curValue)) {
      return true
    }

    return prevValue[field] !== curValue[field]
  },

  termsFeesChanged (state, getters) {
    return getters.termsFieldChanged('hasAlertThreshold') ||
      getters.termsFieldChanged('alertThreshold') ||
      getters.termsFieldChanged('needsConsentToLeaveScope') ||
      getters.termsFieldChanged('internalCoordinationType') ||
      getters.termsFieldChanged('travelExpenseType') ||
      getters.termsFieldChanged('travelExpenseCap') ||
      getters.termsFieldChanged('associatesExpenseType') ||
      getters.termsFieldChanged('traineesExpenseType') ||
      getters.termsFieldChanged('trainingExpenseType')
  },

  termsExpensesChanged (state, getters) {
    return getters.termsFieldChanged('generalFees') ||
      getters.termsFieldChanged('maximumFee') ||
      getters.termsFieldChanged('flightExpenseType') ||
      getters.termsFieldChanged('customFlightExpense') ||
      getters.termsFieldChanged('trainExpenseType') ||
      getters.termsFieldChanged('customTrainExpense') ||
      getters.termsFieldChanged('rentalCarExpenseType') ||
      getters.termsFieldChanged('customRentalCarExpense') ||
      getters.termsFieldChanged('hotelExpenseType') ||
      getters.termsFieldChanged('customHotelExpense')
  },

  termsLiabilityChanged (state, getters) {
    return getters.termsFieldChanged('liabilityType') ||
      getters.termsFieldChanged('liabilityAmount')
  },

  termsBillingTypeChanged (state, getters) {
    return getters.termsFieldChanged('billingType') ||
      getters.termsFieldChanged('billingTypeSpecification')
  },

  biddingRulesChanged (state, getters) {
    return getters.fieldChangedToLastVersion('qaEnd') ||
      getters.fieldChangedToLastVersion('firstBidDeadline') ||
      getters.fieldChangedToLastVersion('auctionStart') ||
      getters.fieldChangedToLastVersion('auctionEnd')
  },

  biddingDisclosureChanged (state, getters) {
    return getters.fieldChangedToLastVersion('disclosureMode')
  },

  biddingNextStepsChanged (state, getters) {
    return getters.fieldChangedToLastVersion('nextSteps')
  },

  biddingChanged (state, getters) {
    return getters.biddingNextStepsChanged ||
      getters.biddingDisclosureChanged ||
      getters.biddingRulesChanged ||
      getters.interviewQualityScoreCriteriaChanged
  },
  mandateTypeChangedToLastVersion (state, getters) {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('mandateType')
    const curValue = getters.getValueForCurrentVersion('mandateType')
    return prevValue !== curValue
  },
  mandateSubTypeChangedToLastVersion (state, getters) {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('mandateSubType')
    const curValue = getters.getValueForCurrentVersion('mandateSubType')
    return prevValue !== curValue
  },
  subjectsChangedToLastVersion (state, getters) {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('subjects')
    const curValue = getters.getValueForCurrentVersion('subjects')
    return (prevValue.length !== curValue.length) ||
      prevValue.some((element, index) => element.key !== curValue[index].key)
  },
  subjectChangedToLastVersion: (state, getters) => (subjectKey) => {
    const { historyRfpVersion } = state
    if (historyRfpVersion <= 1) { return false }
    const prevValue = getters.getValueFromPreviousVersion('subjects')
    const curValue = getters.getValueForCurrentVersion('subjects')
    const curSubjectIndex = curValue.findIndex(subject => subject.key === subjectKey)
    return curSubjectIndex === -1 || prevValue.length <= curSubjectIndex || prevValue[curSubjectIndex].key !== subjectKey
  },
  generalChanged (state, getters) {
    return getters.mandateTypeChangedToLastVersion ||
      getters.mandateSubTypeChangedToLastVersion ||
      getters.subjectsChangedToLastVersion
  },
  isValidEmail: () => (email) => {
    // eslint-disable-next-line
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
  }
}
