import Vue from 'vue'

export default {

  setBids (state, bids) {
    Vue.set(state.auction, 'bids', bids)
  },

  setAuctionSubscription (state, subscription) {
    Vue.set(state.auction, 'subscription', subscription)
  },

  setStatusSubscription (state, subscription) {
    Vue.set(state.auction, 'statusSubscription', subscription)
  },

  setCurrentRfpStatus (state, status) {
    Vue.set(state.currentRfp, 'status', status)
  },

  setCurrentRfpStatusPreAbortion (state, statusPreAbortion) {
    Vue.set(state.currentRfp, 'statusPreAbortion', statusPreAbortion)
  },

  setCurrentRfpAbortionMessage (state, abortionMessage) {
    Vue.set(state.currentRfp, 'abortionMessage', abortionMessage)
  },

  setCurrentRfpAuctionEnd (state, auctionEnd) {
    Vue.set(state.currentRfp, 'auctionEnd', auctionEnd)
  },

  setCurrentRfpActiveLawFirms (state, activeLawFirms) {
    Vue.set(state.currentRfp, 'activeLawFirms', activeLawFirms)
  },

  setLawFirmRelationStatusAndRank (state, { status, rank, statusPreAbortion }) {
    const lawFirmRelation = state.currentRfp.lawFirmRelations[0]
    if (lawFirmRelation) {
      lawFirmRelation.status = status
      lawFirmRelation.rank = rank
      lawFirmRelation.statusPreAbortion = statusPreAbortion
      Vue.set(state.currentRfp, 'lawFirmRelations', [lawFirmRelation])
    }
  },

  setUsersLastBid (state, bid) {
    Vue.set(state.auction, 'usersLastBid', bid)
  },

  toggleNotification (state) {
    Vue.set(state.auction, 'showNotificationPanel', !state.auction.showNotificationPanel)
  },

  hideNotification (state) {
    Vue.set(state.auction, 'showNotificationPanel', false)
  },

  setHasChangedSubject (state, value) {
    Vue.set(state, 'hasChangedSubject', value)
  }
}
