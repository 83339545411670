<template>
  <div v-if="userIsLawFirm" class="main">
    <Sidebar
      :optionsArray="options"
      :userDidNotEnterInitialBid="userDidNotEnterInitialBid"
      :components-array="components" />

    <div class="main-grid">
      <v-alert
        :value="currentRfpLawFirmRelation.proposalSubmitted"
        class="lintum-alert"
        style="margin-bottom: -20px; width: 100%"
        icon="info">
        <span>{{ $t('activeRFP.proposal.submissionInfo', { timestamp: formatDateTime(currentRfpLawFirmRelation.proposalSubmission) }) }}</span>
      </v-alert>

      <ProposalTeam ref="team" v-if="rfpHasQualityScore('TEAM')" />
      <ProposalCredentials ref="credentials" v-if="rfpHasQualityScore('CREDENTIALS')" />
      <ProposalPitchDocument ref="pitch-document" v-if="rfpHasQualityScore('PITCH_DOCUMENT')" />
      <ProposalLiability ref="liability" v-if="rfpHasQualityScore('LIABILITY')"  />
      <ProposalFreeText
        ref="strategy"
        quality-score-criterium="STRATEGY"
        v-if="rfpHasQualityScore('STRATEGY')" />

      <ProposalFreeText
        v-for="custom of applicableCustomQualityScores"
        :key="custom"
        :ref="custom"
        :quality-score-criterium="custom" />

      <AuctionBidPanelWrapper
        ref="firstBid"
        v-if="showBidding"
        style="margin-bottom: 25vh"
        :duration="date" :relevantBidKey="relevantBidKey" />
    </div>
    <ProposalFooter :isFeedback="false" v-if="proposalIsEditable" />
  </div>
  <div v-else>
    <LawFirmRating v-if="!proposalView" />
    <LawFirmComparisonRate v-else />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import ProposalPitchDocument from './ProposalPitchDocument'
import ProposalLiability from './ProposalLiability'
import ProposalCredentials from './ProposalCredentials'
import Sidebar from '../../generalComponents/SideBar'
import ProposalTeam from './ProposalTeam'
import ProposalFooter from './ProposalFooter'
import LawFirmRating from '../lawFirmRating/LawFirmRating'
import LawFirmComparisonRate from '../lawFirmRating/LawFirmComparisonRate'
import AuctionBidPanelWrapper from '../../eAuction/components/AuctionBidPanelWrapper'
import ProposalFreeText from '@/views/activeRfp/proposal/ProposalFreeText'

export default {
  name: 'Proposal',
  props: {
    userDidNotEnterInitialBid: {
      default: false,
      required: false
    },

    // prop for defining how the view for the law firm rating will be rendered
    proposalView: {
      type: Boolean
    },

    date: {
      required: false
    }
  },
  components: {
    ProposalFreeText,
    AuctionBidPanelWrapper,
    LawFirmRating,
    ProposalFooter,
    ProposalTeam,
    Sidebar,
    ProposalCredentials,
    ProposalLiability,
    ProposalPitchDocument,
    LawFirmComparisonRate
  },
  data () {
    return {
      components: [],
      refVal: null
    }
  },
  created () {
    if (this.userIsLawFirm) {
      this.loadProposal()
    }
  },
  async mounted () {
    await this.$nextTick()
    console.log(this.$refs)
    if (this.userIsLawFirm) {
      this.components = this.options.map(option => {
        const ref = this.$refs[option.idRef]
        if (Array.isArray(ref)) {
          return ref[0].$el
        } else {
          return ref.$el
        }
      })
    }
  },
  computed: {
    ...mapGetters(['getCustomQualityScoreConfig', 'userIsLawFirm', 'userIsCorporate', 'renderLawFirm', 'formatDateTime', 'currentRfpLawFirmRelation', 'proposalIsEditable', 'relevantBidKey']),
    ...mapState({
      currentRfp: state => state.currentRfp
    }),

    applicableCustomQualityScores () {
      return ['CUSTOM1', 'CUSTOM2', 'CUSTOM3'].filter(x => this.rfpHasQualityScore(x))
    },

    showBidding () {
      return this.currentRfp.status === 'ACTIVE_FIRST_BIDS' || this.currentRfp.status === 'ACTIVE_QA'
    },

    options () {
      const result = []

      if (this.rfpHasQualityScore('TEAM')) {
        result.push({ name: 'activeRFP.proposal.team', idRef: 'team', status: false, icon: 'ic-users', top: 60 })
      }

      if (this.rfpHasQualityScore('CREDENTIALS')) {
        result.push({ name: 'activeRFP.proposal.credentials', idRef: 'credentials', status: false, icon: 'ic-trophy', top: 70 })
      }

      if (this.rfpHasQualityScore('PITCH_DOCUMENT')) {
        result.push({ name: 'activeRFP.proposal.pitchDocument', idRef: 'pitch-document', status: false, icon: 'ic-attach', top: 90 })
      }

      if (this.rfpHasQualityScore('LIABILITY')) {
        result.push({ name: 'activeRFP.proposal.liability', idRef: 'liability', status: false, icon: 'ic-pen-tool', top: 100 })
      }

      if (this.rfpHasQualityScore('STRATEGY')) {
        result.push({ name: 'activeRFP.proposal.strategy', idRef: 'strategy', status: false, icon: 'assistant_navigation', top: 110 })
      }
      const icons = {
        CUSTOM1: 'description',
        CUSTOM2: 'article',
        CUSTOM3: 'assignment'
      }
      for (const custom of ['CUSTOM1', 'CUSTOM2', 'CUSTOM3']) {
        if (this.rfpHasQualityScore(custom)) {
          const { title } = this.getCustomQualityScoreConfig(this.currentRfp, custom)
          result.push({ name: title, idRef: custom, status: false, icon: icons[custom], top: 110 })
        }
      }

      if (this.showBidding) {
        result.push({ name: 'activeRFP.proposal.firstBid', idRef: 'firstBid', status: false, icon: 'ic-gavel', top: 120 })
      }

      result[0].status = true
      return result
    }
  },
  methods: {
    ...mapActions(['addQuestion', 'answerQuestion', 'deleteAnswer', 'uploadQaFile', 'loadProposal']),
    ...mapMutations(['addQuestionToRfp', 'resetMandatoryFields']),

    rfpHasQualityScore (name) {
      return this.currentRfp.terms.qualityScoreCriteria.includes(name)
    }
  },
  destroyed () {
    this.resetMandatoryFields()
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  :deep(.raitings i) {
    color: #ffcc80 !important;
    caret-color: #ffcc80 !important;
  }

  .main
    padding-top 21px
    display grid
    grid-template-columns 200px 1fr
    grid-column-gap 20px

  :deep(.v-card)
    padding 30px 50px
    border-radius 10px

    &.missing,
    &.missingcard
      border 2px solid $red

  :deep(h2)
    margin-bottom 20px

  .main-grid
    display grid
    grid-template-columns minmax(0, 1fr)
    grid-row-gap 30px

  :deep(.v-input__slot)
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  :deep(.v-input--checkbox) .v-input__slot
    border none

  :deep(.upload)
    height 250px

    p
      margin-top 50px !important

  :deep(p)
    text-align justify
</style>
