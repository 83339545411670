<template>
  <TooltipHolder
    v-if="isSeniorityFeeSubstructure"
    style="margin-left: 10px"
    :imgSrc="'pieChart.svg'"
    :titleText="$t(`activeRFP.biddingProcessSection.capEstimateHourlyRates.breakdown`, {feeStructure: $t(`activeRFP.biddingProcessSection.capEstimateHourlyRates.${currentRfp.terms.feeStructure}`)})"
    :breakdownInfo="capBreakdownArray(computedBid)"
    :spanId="'span'+ computedBid.id"
    right
    tablePopup />
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TooltipHolder from '../../generalComponents/TooltipHolder'

export default {
  name: 'CapBreakdown',
  components: { TooltipHolder },
  props: {
    // An AuctionComputedBid
    computedBid: {
      type: Object,
      required: true
    },

    // control the view of the Percentage in the tooltip
    showPercentage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'isCapOrFeeEstimate', 'formatBid', 'isSeniorityFeeSubstructure'
    ]),
    ...mapState({
      currentRfp: state => state.currentRfp
    })
  },
  methods: {
    capBreakdownArray (computedBid) {
      let breakdownArr = []

      if (!computedBid) {
        return []
      }

      for (let i = 0; i < 4; i++) {
        let obj = {}
        obj.key = this.breakdownKeyGen(i).key
        // let numerator = this.isCapOrFeeEstimate ? (computedBid['amount_rate_level' + (i + 1)] * computedBid['hours_level' + (i + 1)]) : computedBid['amount_rate_level' + (i + 1)] * 0.25
        obj.rate = this.formatBid(computedBid['amount_rate_level' + (i + 1)])
        // obj.percentage = numerator / computedBid['relevant_display_value']
        obj.showPercentage = false // (this.isCapOrFeeEstimate && this.showPercentage)
        obj.color = this.breakdownKeyGen(i).color
        breakdownArr.push(obj)
      }

      return breakdownArr
    },

    breakdownKeyGen (seniorityLevel) {
      switch (seniorityLevel) {
        case 0:
          return { key: 'Senior Partner', color: 'rgb(212, 202, 180)' }
        case 1:
          return { key: 'Junior Partner', color: 'rgb(238, 234, 225)' }
        case 2:
          return { key: 'Senior Associate', color: 'rgb(170, 150, 106)' }
        case 3:
          return { key: 'Associate', color: 'rgb(64, 46, 50)' }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
