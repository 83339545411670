<template>
  <v-btn
    @click.stop="click"
    :type="type"
    :color="color"
    :disabled="isLoading"
    flat>
    <slot></slot>
    <v-progress-circular
      v-if="isLoading"
      indeterminate
      :width="circularWidth"
      class="progress"
      :size="circularSize"
      :style="`margin-left: ${marginFromText}px`">
    </v-progress-circular>
  </v-btn>
</template>

<script>
export default {
  name: 'AwaitButton',
  props: {
    color: {
      required: false,
      type: String
    },

    circularWidth: {
      type: String,
      required: false,
      default: '3'
    },

    circularSize: {
      type: Number,
      required: false,
      default: 24
    },

    flat: {
      type: Boolean,
      required: false,
      default: false
    },

    type: {
      type: String,
      required: false
    },

    marginFromText: {
      type: Number,
      required: false,
      default: 10
    },

    emitClick: {
      type: Boolean,
      default: false
    },

    customLoading: {
      type: Boolean,
      default: false
    },

    callback: {
      type: Function,
      default: () => () => null
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    isLoading () {
      return this.emitClick ? this.customLoading : this.loading
    }
  },
  methods: {
    click () {
      if (this.emitClick) {
        this.$emit('click')
      } else {
        this.handleCallback()
      }
    },

    async handleCallback () {
      this.loading = true
      try {
        await this.callback()
      } finally {
        this.loading = false
      }
    }
  }

}
</script>

<style lang="stylus" scoped>

</style>
