<template>
  <v-list>
    <slot name="header">
    </slot>
    <div :class="{ 'list-container-horizontal' : horizontal }">
      <template v-for="(item, index) in ratings">
        <v-divider
          v-if="index > 0"
          divider
          :key="index + '_divider'"
          :vertical="horizontal ? true : false"
        ></v-divider>

        <v-list-tile
          :key="index"
        >
          <div class="list-item-container">
            <v-list-tile-content>
              <div class="list-item" :style="{'padding-top': '3px', 'width': itemWidth}">
                <v-list-tile-title
                  @click="inviteLawfirm({ lawFirmId: item.lawFirm.id })"
                  :style="{ 'width': textFixedWidth}" class="list-item-text">
                  {{ item.lawFirm.name }}
                </v-list-tile-title>
                <v-rating
                  :value="item.rate"
                  :readonly="true"
                  small
                  color="orange"
                  background-color="orange lighten-3"
                  half-increments hover
                />
              </div>
            </v-list-tile-content>
          </div>

        </v-list-tile>

      </template>
    </div>
  </v-list>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LawFirmList',
  props: {
    ratings: {
      type: [],
      required: true
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: false
    },
    // For the text before the rating
    textFixedWidth: {
      type: String,
      required: false,
      default: '100%'
    },
    itemWidth: {
      type: String,
      required: false,
      default: '100%'
    }
  },
  methods: {
    ...mapActions(['inviteLawfirm'])
  }
}
</script>

<style scoped>
>>> .list-item-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.list-item {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  width: 26rem;
  font-weight: bold;
  align-items: center;
}

.list-item >>> .v-icon {
  color: orange !important
}

.list-container-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list-item-text {
  height: 100%;
  line-height: 100%;
  white-space: normal;
  overflow: visible;
}

.list-item-container {
  width: 100%;
}

</style>
