// eslint-disable-next-line
export const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

export const RfpProgress = {
  'DRAFT': false,
  'ACTIVE_QA': 0,
  'ACTIVE_FIRST_BIDS': 1,
  'ACTIVE_FIRST_BIDS_CLOSED': 2,
  'ACTIVE_SECOND_BIDS_OR_AUCTION': 3,
  'ACTIVE_DECISION_PENDING': 4,
  'COMPLETED_MATTER_IN_PROGRESS': 5,
  'COMPLETED_FEEDBACK_REQUIRED': 6,
  'COMPLETED_MATTER_CLOSED': 7,
  'COMPLETED_ABORTED': false,
  'COMPLETED_ABORTED_BY_CORPORATE': false
}
