<template>
  <div class="main-area">
    <div
      v-for="(relation, index) in sortedLawFirmRelations"
      :key="relation.lawFirm.id"
      style="border: none; float: none; display: grid;"
      :class="{'break-before-print': index > 0}">
    <v-card
      :id="'rating-' + relation.lawFirm.id"
      style="position: relative"
      :class="{active: relation.showProposal, noProposal: !onlyInterviewOrQaSelected && (!proposalIsClosed() || (!relation.proposalSubmitted && !isAnyCoiRejected(relation))) }">
      <div class="table-row" :class="{disabled: !onlyInterviewOrQaSelected && (!proposalIsClosed() || (!relation.proposalSubmitted && !isAnyCoiRejected(relation)))}" @click="toggle(relation)">
        <strong class="lf-name">{{ getLawFirm(relation.lawFirm.id).name }}</strong>
        <section class="lf-location">{{ getLocation(relation.lawFirm.id) }}</section>
        <section>{{ relation.proposalSubmission ? formatTimestamp(relation.proposalSubmission, 'DD.MM.YYYY HH:mm') : ''}}</section>
        <!-- div v-if="!proposalIsClosed() && relation.proposalSubmitted && !onlyInterviewOrQaSelected" style="justify-self: end; width: max-content">
          <span>{{ 'activeRFP.decision.submittedDeadlinenotReached' | t }}</span>
        </div -->
        <div style="justify-self: end" v-if="relationRated(relation) && (relation.proposalSubmitted || (onlyInterviewOrQaSelected && currentRfp.terms.qualityScoreCriteria.length > 0))">
          <v-avatar size="30" class="rating">
            <span style="color: #fff;">{{ parseFloat(relation.proposalRating).toFixed(1) }}</span>
          </v-avatar>
          <v-btn v-if="relation.showProposal" flat icon><v-icon style="font-size: 20px; height: 24px; width: auto">close</v-icon></v-btn>
        </div>
        <div v-else-if="relation.proposalSubmitted && !relation.showProposal" style="text-align: right;">
          <v-btn v-if="!currentRfp.winner && !disabled" class="main rate-button">{{ 'activeRFP.lawFirmRating.rateProposal' | t }}</v-btn>
          <span v-else>{{ 'activeRFP.decision.notRatedLabel' | t }}</span>
        </div>
        <div style="text-align: right" v-else>
          <span class="lf-action">{{ (rejectedConflictedCoiText(relation)) | t }}</span>
          <v-btn style="float: right" v-if="relation.showProposal" flat icon><v-icon style="font-size: 20px; height: 24px; width: auto">close</v-icon></v-btn>
        </div>
      </div>

      <div v-if="relation.showProposal" class="proposal-holder">

        <div v-if="isAnyCoiRejected(relation)" class="proposal-item extend" >
          <div class="title">
            <h3>{{ ((!relation.coi2Confirmed && relation.coi2Confirmed !== null) ? 'activeRFP.proposal.rejectTitle' : 'activeRFP.proposal.conflictTitle') | t }}</h3>
          </div>

          <div class="credential-item">
            <!-- <span class="light">{{ 'activeRFP.proposal.description' | t }}</span> -->
            <p style="margin-top: 16px;">{{ (!relation.coi2Confirmed && relation.coi2Confirmed !== null) ? relation.coi2RejectionReason : $t('activeRFP.proposal.conflicted') }}</p>
          </div>
        </div>

        <div v-if="!isAnyCoiRejected(relation) && (hasQualityScore('TEAM') || hasQualityScore('LIABILITY') || hasQualityScore('STRATEGY'))" class="proposal">

          <div v-if="team(relation)" class="proposal-item">
            <div class="title">
              <h3>{{ 'activeRFP.proposal.team' | t}}</h3>
            </div>
            <div v-if="!team(relation).subItems.length">
              <span class="noSubmission">{{ $t('activeRFP.proposal.noTeamProvided') }}</span>
            </div>
            <div class="credential-item" v-for="member in team(relation).subItems" :key="member.id">
              <div class="team-title">
                <section style="display: inline-flex">
                  <v-avatar size="30" class="circle-span">
                    <span style="color: #fff;">{{ spanName(member.linkedUser ? member.linkedUser.fullName : '') }}</span>
                  </v-avatar>
                  <div>
                    <p :style=" !(member.extendedFields && member.extendedFields.seniorityLevel) ? 'margin-top: 10px' :''"><b>{{ member.linkedUser.fullName }}</b></p>
                    <p>{{ (member.extendedFields && member.extendedFields.seniorityLevel ? `dropDownOptions.SeniorityLevel.${member.extendedFields.seniorityLevel}` : '') | t }}</p>
                  </div>
                </section>
                <v-hover>
                  <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="member.rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: team(relation).id, subItemId: member.id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    :readonly="disabled"
                    small
                    :value="member.rating"
                    @input="value => rateProposalLocal({ categoryId: team(relation).id, subItemId: member.id, value })"
                    color="orange"
                    background-color="orange lighten-3"
                    half-increments hover />
                  </div>
                </v-hover>
              </div>

              <section v-if="member.extendedFields.hasDocumentUpload" style="margin-top: 7px">
                <FileChip v-for="attachment in member.attachments" :file="attachment" :key="attachment.id" showIcon/>
              </section>
              <section v-else-if="member.extendedFields.cvLink" style="display: inline-block; margin-left: 40px; font-size: 16px">
                <a :href="formattedUrl(member.extendedFields.cvLink)" target="_blank" rel="noopener">{{ member.extendedFields.cvLink }}</a>
              </section>
            </div>

            <LawFirmRatingComment
                :disabled="disabled"
                :update-time="team(relation).ratingCommentTime"
                @save="saveProposalComment({ categoryId: team(relation).id, ratingComment: team(relation).ratingComment || '' })"
                v-model="team(relation).ratingComment" />
          </div>

          <div v-if="liability(relation)" class="proposal-item">
            <div class="title">
              <h3>{{ 'activeRFP.proposal.liability' | t}}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="liability(relation).rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: liability(relation).id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    :readonly="disabled"
                    small
                    color="orange"
                    :value="liability(relation).rating"
                    @input="value => rateProposalLocal({ categoryId: liability(relation).id, value })"
                    background-color="orange lighten-3"
                    half-increments hover />
                </div>
              </v-hover>
            </div>

            <div v-if="!(liability(relation).extendedFields.maximumLiability === 0 || liability(relation).extendedFields.maximumLiability)">
              <span class="noSubmission">{{ $t('activeRFP.proposal.noLiabilityProvided') }}</span>
            </div>
            <div v-else class="credential-item">
              <span class="light">{{ 'activeRFP.proposal.maximumLiability' | t }}</span><br>
              <p class="dark">{{ formatNumber(liability(relation).extendedFields.maximumLiability) }} {{currencySymbol()}}</p>
              <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
              <p>{{ liabilityText(relation) }}</p>
            </div>

            <LawFirmRatingComment
                :disabled="disabled"
                @save="saveProposalComment({ categoryId: liability(relation).id, ratingComment: liability(relation).ratingComment || '' })"
                v-model="liability(relation).ratingComment" />
          </div>

          <div v-if="strategy(relation)" class="proposal-item">
            <div class="title">
              <h3>{{ 'activeRFP.proposal.strategy' | t}}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="strategy(relation).rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: strategy(relation).id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    :readonly="disabled"
                    small
                    color="orange"
                    :value="strategy(relation).rating"
                    @input="value => rateProposalLocal({ categoryId: strategy(relation).id, value })"
                    background-color="orange lighten-3"
                    half-increments hover />
                </div>
              </v-hover>
            </div>

            <div v-if="!strategy(relation).extendedFields.explanation">
              <span class="noSubmission">{{ $t('activeRFP.proposal.noExplanationProvided') }}</span>
            </div>
            <div v-else class="credential-item">
              <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
              <p>{{ strategyText(relation) }}</p>
            </div>

            <LawFirmRatingComment
                :disabled="disabled"
                @save="saveProposalComment({ categoryId: strategy(relation).id, ratingComment: strategy(relation).ratingComment || '' })"
                v-model="strategy(relation).ratingComment" />
          </div>

          <div v-if="custom(relation, 'CUSTOM2')" class="proposal-item">
            <div class="title">
              <h3>{{ customTitle('CUSTOM2') }}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="custom(relation, 'CUSTOM2').rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: custom(relation, 'CUSTOM2').id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    :readonly="disabled"
                    small
                    color="orange"
                    :value="custom(relation, 'CUSTOM2').rating"
                    @input="value => rateProposalLocal({ categoryId: custom(relation, 'CUSTOM2').id, value })"
                    background-color="orange lighten-3"
                    half-increments hover />
                </div>
              </v-hover>
            </div>

            <div v-if="!custom(relation, 'CUSTOM2').extendedFields.explanation">
              <span class="noSubmission">{{ $t('activeRFP.proposal.noExplanationProvided') }}</span>
            </div>
            <div v-else class="credential-item">
              <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
              <p>{{ customText(relation, 'CUSTOM2') }}</p>
            </div>

            <LawFirmRatingComment
                :disabled="disabled"
                @save="saveProposalComment({ categoryId: custom(relation,'CUSTOM2').id, ratingComment: custom(relation,'CUSTOM2').ratingComment || '' })"
                v-model="custom(relation, 'CUSTOM2').ratingComment" />
          </div>

        </div>

        <div v-if="!isAnyCoiRejected(relation)" class="proposal">

          <div v-if="credentials(relation)" class="proposal-item">
            <div class="title">
              <h3>{{ 'activeRFP.proposal.credentials' | t}}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="credentials(relation).rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: credentials(relation).id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    :readonly="disabled"
                    small
                    color="orange"
                    :value="credentials(relation).rating"
                    @input="value => rateProposalLocal({ categoryId: credentials(relation).id, value })"
                    background-color="orange lighten-3"
                    half-increments hover />
                </div>
              </v-hover>
            </div>

            <div v-if="!credentials(relation).subItems.length">
              <span class="noSubmission">{{ $t('activeRFP.proposal.noCredentialsProvided') }}</span>
            </div>
            <div class="credential-item" v-for="credential in credentials(relation).subItems" :key="credential.id">
              <span class="light">{{ 'activeRFP.proposal.description' | t }}</span>
              <p>{{ credential.extendedFields.description }}</p>
              <span class="light">{{ 'activeRFP.proposal.year' | t }}</span>
              <p>{{ credential.extendedFields.year }}</p>
              <template v-if="currentRfp.terms.qualityScoreCriteria && currentRfp.terms.qualityScoreCriteria.includes('TEAM')">
                <span class="light">{{ 'activeRFP.proposal.involvementOfTeamMembers' | t }}</span>
                <div v-if="credential.extendedFields.involvementOfProposedTeamMembers && credential.extendedFields.involvementOfProposedTeamMembers.length">
                  <div
                    style="display: grid; grid-template-columns: 220px 1fr;"
                    v-for="teamMember in credential.extendedFields.involvementOfProposedTeamMembers"
                    :key="teamMember.userId">
                    <span>{{ getTeamMemberNameFromProposalTeam(teamMember.userId, relation) }}</span>
                    <span>{{ renderRole(teamMember) }}</span>
                  </div>
                </div>
                <p v-else>{{ 'activeRFP.proposal.clientAdvisedByOtherLawyers' | t }}</p>
              </template>
            </div>

            <LawFirmRatingComment
                :disabled="disabled"
                @save="saveProposalComment({ categoryId: credentials(relation).id, ratingComment: credentials(relation).ratingComment || '' })"
                v-model="credentials(relation).ratingComment" />
          </div>

          <div v-if="pitchDocument(relation)" class="proposal-item">
            <div class="title">
              <h3>{{ 'activeRFP.proposal.pitchDocument' | t}}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="pitchDocument(relation).rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: pitchDocument(relation).id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    small
                    :readonly="disabled"
                    color="orange"
                    :value="pitchDocument(relation).rating"
                    @input="value => rateProposalLocal({ categoryId: pitchDocument(relation).id, value })"
                    background-color="orange lighten-3"
                    half-increments hover />
                </div>
              </v-hover>
            </div>

            <div v-if="!pitchDocument(relation).attachments.length">
              <span class="noSubmission">{{ $t('activeRFP.proposal.noPitchDocumentProvided') }}</span>
            </div>
            <div class="credential-item">
              <FileChip v-for="attachment in pitchDocument(relation).attachments" :file="attachment" :key="attachment.id" showIcon/>
            </div>

            <LawFirmRatingComment
                :disabled="disabled"
                @save="saveProposalComment({ categoryId: pitchDocument(relation).id, ratingComment: pitchDocument(relation).ratingComment || '' })"
                v-model="pitchDocument(relation).ratingComment" />
          </div>

          <div class="proposal-item" v-if="qaEnabled" ref="qa">
            <div class="title" style="border: none;">
              <h3>{{ 'activeRFP.proposal.qa' | t}}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="qa(relation).rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: qa(relation).id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    small
                    class="raitings"
                    color="orange"
                    background-color="orange lighten-3"
                    :readonly="disabled"
                    :value="qa(relation).rating"
                    @input="value => rateProposalLocal({ categoryId: qa(relation).id, value })"
                    half-increments hover />
                </div>
              </v-hover>
            </div>

            <LawFirmRatingComment
                :disabled="disabled"
                @save="saveProposalComment({ categoryId: qa(relation).id, ratingComment: qa(relation).ratingComment || '' })"
                v-model="qa(relation).ratingComment" />
          </div>

          <div class="proposal-item" v-if="interviewEnabled" ref="interview">
            <div class="title">
              <h3>{{ 'activeRFP.proposal.interview' | t}}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="interview(relation).rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: interview(relation).id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    small
                    class="raitings"
                    color="orange"
                    background-color="orange lighten-3"
                    :readonly="disabled"
                    :value="interview(relation).rating"
                    @input="value => rateProposalLocal({ categoryId: interview(relation).id, value })"
                    half-increments hover />
                </div>
              </v-hover>
            </div>
            <LawFirmRatingComment
              :disabled="disabled"
              @save="saveProposalComment({ categoryId: interview(relation).id, ratingComment: interview(relation).ratingComment || '' })"
              v-model="interview(relation).ratingComment" />
            <!-- v-textarea
              style="margin-top: 5px;"
              v-if="!disabled"
              v-model="interview(relation).ratingComment"
              solo flat label="" rows="2"></v-textarea>
            <p v-else>{{ interview(relation).ratingComment }}</p>
            <div style="width: 100%; display: grid;">
              <v-btn
                v-if="!disabled"
                @click="saveProposalComment({ categoryId: interview(relation).id, ratingComment: interview(relation).ratingComment || '' })"
                right class="main" style="justify-self: end; width: max-content; margin-right: -15px;">{{ $t('activeRFP.proposal.saveComment') }}</v-btn>
            </div -->
          </div>

          <div v-if="custom(relation, 'CUSTOM1')" class="proposal-item">
            <div class="title">
              <h3>{{ customTitle('CUSTOM1') }}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="custom(relation, 'CUSTOM1').rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: custom(relation, 'CUSTOM1').id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    :readonly="disabled"
                    small
                    color="orange"
                    :value="custom(relation, 'CUSTOM1').rating"
                    @input="value => rateProposalLocal({ categoryId: custom(relation, 'CUSTOM1').id, value })"
                    background-color="orange lighten-3"
                    half-increments hover />
                </div>
              </v-hover>
            </div>

            <div v-if="!custom(relation, 'CUSTOM1').extendedFields.explanation">
              <span class="noSubmission">{{ $t('activeRFP.proposal.noExplanationProvided') }}</span>
            </div>
            <div v-else class="credential-item">
              <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
              <p>{{ customText(relation, 'CUSTOM1') }}</p>
            </div>
            <LawFirmRatingComment
                :disabled="disabled"
                @save="saveProposalComment({ categoryId: custom(relation,'CUSTOM1').id, ratingComment: custom(relation,'CUSTOM1').ratingComment || '' })"
                v-model="custom(relation, 'CUSTOM1').ratingComment" />
          </div>

          <div v-if="custom(relation, 'CUSTOM3')" class="proposal-item">
            <div class="title">
              <h3>{{ customTitle('CUSTOM3') }}</h3>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    v-if="custom(relation, 'CUSTOM3').rating !== null && !disabled && hover"
                    flat
                    class="reset-rating"
                    @click="rateProposalLocal({ categoryId: custom(relation, 'CUSTOM3').id, value: 'DELETE' })"
                    icon><v-icon>close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    :readonly="disabled"
                    small
                    color="orange"
                    :value="custom(relation, 'CUSTOM3').rating"
                    @input="value => rateProposalLocal({ categoryId: custom(relation, 'CUSTOM3').id, value })"
                    background-color="orange lighten-3"
                    half-increments hover />
                </div>
              </v-hover>
            </div>

            <div v-if="!custom(relation, 'CUSTOM3').extendedFields.explanation">
              <span class="noSubmission">{{ $t('activeRFP.proposal.noExplanationProvided') }}</span>
            </div>
            <div v-else class="credential-item">
              <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
              <p>{{ customText(relation, 'CUSTOM3') }}</p>
            </div>

            <LawFirmRatingComment
                :disabled="disabled"
                @save="saveProposalComment({ categoryId: custom(relation,'CUSTOM3').id, ratingComment: custom(relation,'CUSTOM3').ratingComment || '' })"
                v-model="custom(relation, 'CUSTOM3').ratingComment" />
          </div>
        </div>

      </div>
    </v-card>
    </div>

    <v-snackbar
      v-model="showSuccessMessageForProposalComment">
      <p style="color: white; margin-bottom: 0;">{{ $t('activeRFP.proposal.commentSaved') }}</p>
      <v-btn
        icon
        @click="showSuccessMessageForProposalComment = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import LawFirmRatingMixin from './LawFirmRatingMixin'
import FileChip from '../../generalComponents/FileChip'
import LawFirmRatingComment from '@/views/activeRfp/lawFirmRating/LawFirmRatingComment'

export default {
  name: 'LawFirmRating',
  mixins: [ LawFirmRatingMixin ],
  components: {
    LawFirmRatingComment,
    FileChip
  },
  mounted () {
    if (this.openLawFirmRelation) {
      const lawFirmRelation = this.sortedLawFirmRelations.find(relation => relation.lawFirm.id === this.openLawFirmRelation)
      if (lawFirmRelation.proposalSubmitted) {
        this.setViewRatingCard(this.openLawFirmRelation)
        const div = document.getElementById('rating-' + this.openLawFirmRelation)
        window.scrollBy({ top: div.getBoundingClientRect().top, left: 0, behavior: 'smooth' })
      }
      this.openLawFirmRelationInRating(null)
    }
  },
  computed: {
    ...mapGetters(['formatTimestamp', 'relevantQualityScoreCriteriaSelected', 'proposalIsClosed', 'userIsLawFirm', 'userIsCorporate', 'currentRfpLawFirmRelation', 'formatNumber', 'getTeamMemberNameFromProposalTeam', 'formattedUrl', 'currencySymbol']),
    ...mapState({
      currentRfp: state => state.currentRfp,
      lawFirms: state => state.lawFirms,
      openLawFirmRelation: state => state.openLawFirmRelation
    }),

    sortedLawFirmRelations () {
      return [...this.currentRfp.lawFirmRelations].sort((a, b) => { return a.lawFirm.name.toLowerCase() < b.lawFirm.name.toLowerCase() ? -1 : 1 })
    },

    onlyInterviewOrQaSelected () {
      return !this.relevantQualityScoreCriteriaSelected(this.currentRfp)
    }
  },
  methods: {
    ...mapMutations(['openLawFirmRelationInRating', 'setViewRatingCard']),

    getLawFirm (lawFirmId) {
      return this.lawFirms.find(lawfirm => lawfirm.id === lawFirmId)
    },

    getLocation (lawFirmId) {
      const lawFirm = this.getLawFirm(lawFirmId)
      return (lawFirm.city || '') + ', ' + (lawFirm.country || '')
    },

    toggle (relation) {
      if (
        (!this.onlyInterviewOrQaSelected || this.currentRfp.terms.qualityScoreCriteria.length === 0) &&
        ((
          !this.team(relation) &&
        !this.credentials(relation) &&
        !this.liability(relation) &&
        !this.pitchDocument(relation) &&
          !this.strategy(relation) &&
        !this.isAnyCoiRejected(relation)))) {
        return
      }
      this.setViewRatingCard(relation.lawFirm.id)
    },

    isAnyCoiRejected (relation) {
      if ((!relation.coi2Confirmed && relation.coi2Confirmed !== null)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .main-area
    padding-top 21px
    display block
    grid-template-columns 1fr
    margin-bottom 20px

  .v-card
    padding 0 !important

    &:not(&first-child)
      margin-top 10px

    &:not(.noProposal):hover
      box-shadow 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12) !important

  .table-row
    display grid
    grid-template-columns 310px 1fr 1fr 220px
    grid-column-gap 5px
    height 28px
    padding-left 30px
    padding-right 30px
    margin-bottom 0
    height 48px

    *
      line-height 48px !important

    &:not(.disabled):hover
      cursor pointer

  .lf-name
    font-size 16px
    line-height 28px

  .lf-location
    line-height 28px

  .lf-action
    line-height 28px
    text-align right

  .proposal-holder
    display grid
    grid-template-columns 1fr 1fr
    border-top 1px solid lightgray
    grid-column-gap 50px
    margin-right 30px
    margin-left 30px
    margin-bottom 10px

  .proposal
    display grid
    grid-template-rows min-content
    grid-row-gap 20px
    margin-top 20px
    height min-content

  .proposal-item
    &.extend
      grid-column-end span 2
      margin-top 20px
      border-bottom 0 !important
      padding-bottom 0 !important

    .title
      display grid
      grid-template-columns 1fr 45px 150px
      border-bottom 1px solid lightgray
      height 40px

      >>> .v-icon
        color $gold100 !important

      h3
        color $icon-gray
        line-height 40px

  .proposal-item:not(:last-child)
    border-bottom 1px solid lightgray
    padding-bottom 10px

  .credential-item:not(:last-child)
    border-bottom 1px solid lightgray
    padding-bottom 10px

  .team-title
    display grid
    grid-template-columns 1fr 195px
    margin-top 5px

    p
      margin-bottom 0px

    .circle-span
      margin 8px 10px 0 0
      background-color $icon-gray

    >>> .v-icon
      color $gold100 !important

  .rating-lf
    display grid
    grid-template-columns 45px 150px

  .rating
    background-color $gold100
    position unset

  .reset-rating
    justify-self end
    height 30px
    width 30px
    margin 6px 0 0 0

    .v-icon
      font-size 18px

  .v-rating
    margin-top: 6px

  .rate-button
    margin-right 0
    margin-top 3px
    width 40px

    >>> div
      height 32px

  .noSubmission
    width 100%
    text-align center
    display block
    line-height 40px
</style>
