import unfetch from 'unfetch'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import Vue from 'vue'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { handleGraphQlErrors } from './vuex/mutations/errorMutations'

let wsUrl = ''
let graphqlUrl = (process.env.JEST_WORKER_ID ? (process.env.JENKINS_RUN ? 'http://localhost:9090' : 'http://localhost:8080') : '') + '/graphql'

if (['localhost'].includes(window.location.hostname)) {
  wsUrl = 'ws://' + process.env.VUE_APP_LOCAL_GRAPHQL_URL
} else if (['lintum-dev.azurewebsites.net'].includes(window.location.hostname)) {
  wsUrl = 'wss://hasura-dev-1.azurewebsites.net/v1alpha1/graphql'
} else if (['lintum-dev-de.azurewebsites.net'].includes(window.location.hostname)) {
  wsUrl = 'wss://hasura-dev-de.azurewebsites.net/v1/graphql'
} else if (['lintum-test-de.azurewebsites.net', 'app-test.lintum.com', 'lintum-test.azureedge.net'].includes(window.location.hostname)) {
  wsUrl = 'wss://hasura-test-de.azurewebsites.net/v1/graphql'
} else if (['lintum-demo-de.azurewebsites.net', 'demo.lintum.com', 'lintum-demo.azureedge.net'].includes(window.location.hostname)) {
  wsUrl = 'wss://hasura-demo-de.azurewebsites.net/v1/graphql'
} else if (['lintum-prod-de.azurewebsites.net', 'app.lintum.com', 'lintum.azureedge.net'].includes(window.location.hostname)) {
  wsUrl = 'wss://hasura-prod-de.azurewebsites.net/v1/graphql'
}

const httpLink = new HttpLink({
  uri: graphqlUrl,
  fetch: window && window.fetch ? window.fetch : unfetch // eslint-disable-line
  // credentials: 'include'
})

var client

function createSubscriptionClient (authToken) {
  return new SubscriptionClient(wsUrl, {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      // const token = await getToken();
      return {
        headers: {
          'x-auth-token': authToken
        }
      }
    }
  })
}

export default function (state, handleErrors = true) {
  if (client && !process.env.JEST_WORKER_ID && state.authToken === client.authToken && client.handleErrors === handleErrors) {
    return client
  }

  const authToken = state.authToken

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'x-auth-token': authToken
      }
    }
  })

  const subscriptionWsClient = createSubscriptionClient(authToken)
  const notificationWsClient = createSubscriptionClient(authToken)

  notificationWsClient.onError(function () {
    Vue.set(state.error, 'noWebsocketSupport', true)
  })

  notificationWsClient.onDisconnected(function () {
    Vue.set(state.error, 'noWebsocketSupport', true)
  })

  notificationWsClient.onConnected(function () {
    Vue.set(state.error, 'noWebsocketSupport', false)
  })

  notificationWsClient.onReconnected(function () {
    Vue.set(state.error, 'noWebsocketSupport', false)
  })

  subscriptionWsClient.onDisconnected(function () {
    Vue.set(state.error, 'showSubscriptionLostError', true)
  })

  subscriptionWsClient.onReconnected(function () {
    Vue.set(state.error, 'showSubscriptionLostError', false)
  })

  const subscriptionWsLink = new WebSocketLink(subscriptionWsClient)
  const notificationWsLink = new WebSocketLink(notificationWsClient)

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    console.error('GraphQlError:', graphQLErrors)
    console.error('NetworkError:', networkError)
    console.log('OPERATION', operation)

    if (state && state.error) {
      Vue.set(state.error, 'operation', operation ? operation.operationName : null)
    }

    if (graphQLErrors) {
      handleGraphQlErrors(state, graphQLErrors)
    }
  })

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)

      return kind === 'OperationDefinition' &&
        operation === 'subscription'
    },
    split(
      ({ query }) => {
        const { name } = getMainDefinition(query)
        return name.value === 'notification'
      },
      notificationWsLink,
      subscriptionWsLink
    ),
    httpLink
  )

  client = new ApolloClient({
    addTypename: false,
    link: handleErrors ? authLink.concat(errorLink).concat(link) : authLink.concat(link),
    cache: new InMemoryCache({
      addTypename: false
    }),
    connectToDevTools: ['localhost'].includes(window.location.hostname),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
      }
    }
  })

  client.authToken = state.authToken
  client.handleErrors = handleErrors

  return client
}
