<template>
  <div>
    <FileChip v-for="document in filteredDocuments" :key="document.id" :file="document" @delete="removeDocument(document)" :deleteIcon="deleteIcon"></FileChip>
    <div class="upload" :class="{normalUpload: !uploadInnvoice, missing }" v-if="!hideUpload">
      <p class="placeholder">
        <v-icon>ic-folder</v-icon><br>
        <span :class="{ mandatory }">{{ labelKey | t }}</span><br>
        <span>{{ 'general.fileUpload.maxSizeInfo' | t }}</span>
        <br>
        <AwaitButton
          emit-click
          :disabled="disabled"
          @click="openUploadDialog"
          :custom-loading="isButtonExecuting" >
          {{ `buttons.${uploadInnvoice ? 'selectInvoice' : 'selectFiles'}` | t }}
        </AwaitButton>
        <input
          :key="key"
          hidden
          id="file-upload-component"
          ref="fileUpload"
          type="file"
          @change="event => waitButtonToEnd(onFileChange, event)"
          accept=".pdf,.docx">
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FileChip from './FileChip'
import AwaitButton from '../generalComponents/AwaitButton'

export default {
  name: 'DocumentUpload',
  components: { FileChip, AwaitButton },
  props: {
    // the rfp wich can be accepted
    documents: {
      type: Array,
      required: false,
      default: () => []
    },

    type: {
      type: String,
      required: true
    },

    qualityScoreCriterium: {
      type: String,
      required: false
    },

    subject: {
      type: String,
      required: false
    },

    proposalCategorySubItemId: {
      type: [String, Number],
      required: false
    },

    deleteIcon: {
      type: Boolean,
      required: false,
      default: false
    },

    hideUpload: {
      type: Boolean,
      required: false,
      default: false
    },
    // prop used in the confirm dialog to only emit the file added instead of uploading instantly
    uploadInnvoice: {
      type: Boolean,
      required: false,
      default: false
    },

    labelKey: {
      type: String,
      default: 'newRFP.documentUpload.documentLabel1'
    },

    missing: {
      type: Boolean,
      default: false
    },

    mandatory: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: Date.now()
    }
  },
  computed: {
    ...mapGetters(['MAX_UPLOAD_SIZE_IN_BYTE']),
    filteredDocuments () {
      if (this.type === 'SCOPE_GENERATOR') {
        return this.documents.filter(document => document.rfpFileType === this.type && document.subject === this.subject)
      }
      return this.documents.filter(document => document.rfpFileType === this.type)
    }
  },
  methods: {
    ...mapActions(['uploadFile']),

    openUploadDialog () {
      document.getElementById('file-upload-component').click()
    },

    async onFileChange (event) {
      const file = event.target.files[0]
      if (file && file.size > this.MAX_UPLOAD_SIZE_IN_BYTE) {
        this.$store.state.showFileSizeDialog = true
        this.key = Date.now()
        return
      }

      if (!this.uploadInnvoice) {
        const proposalCategorySubItemId = this.proposalCategorySubItemId
        const qualityScoreCriterium = this.qualityScoreCriterium
        const subject = this.subject

        const newFile = await this.uploadFile({ file, type: this.type, proposalCategorySubItemId, qualityScoreCriterium, subject })
        if (newFile) {
          this.$emit('uploaded', newFile)
        }
      } else {
        this.$emit('pendingUpload', file)
      }
      this.key = Date.now()
    },

    removeDocument (file) {
      document.getElementById('file-upload-component').value = ''
      this.$emit('deleteFile', file)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .upload
    width 100%
    border-radius 5px
    color rgb(163, 163, 174)
    display flex
    justify-content center
    align-items center
    height 200px

    &.missing
      border-color $red !important

    span
      color rgb(163, 163, 174)
      font-size 16px

    &.normalUpload
      border 2px solid gray
      background rgb(238, 238, 238)

    .v-icon:before
      font-size 75px

    .placeholder
      margin 0
      color rgb(163, 163, 174)
      text-align center
      font-weight bold

      .v-btn
        background rgb(163, 163, 174)
        color white
        border-radius 5px
        margin-top 10px
</style>
