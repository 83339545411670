import apollo from '../../apollo'
import gql from 'graphql-tag'
import { scopeGeneratorLayout } from '../graphqlTags/rfpTags'

export default {

  async loadAllSubjects ({ getters, commit, state }, forAdminView) {
    const additionalFields = forAdminView ? `
      labelTranslations
      scopeGeneratorDraftLayout {
        ${scopeGeneratorLayout(true)}
      }
      scopeGeneratorLayout {
        ${scopeGeneratorLayout(true)}
      }
    ` : ''

    const response = await apollo(state).query({
      query: gql`
        query subjects($subjectIds: [ID]) {
          subjects (subjectIds: $subjectIds) {
            key
            ${additionalFields}
            translatedLabel
            mandateTypes
            mandateSubTypes
            active
          }
        }
      `,
      variables: {
        subjectIds: null
      }
    })

    console.log(response)
    commit('setAllSubjects', response.data.subjects)
  },

  async publishDraftSubject ({ getters, commit, state }, { subject }) {
    await apollo(state).mutate({
      mutation: gql`
        mutation publishDraftSubjectLayout($subjectId: ID!) {
          publishDraftSubjectLayout(subjectId: $subjectId)
        }
      `,
      variables: {
        subjectId: subject.key
      }
    })
  },

  async resetDraftSubject ({ getters, commit, state }, { subject }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation resetDraftSubjectLayout($subjectId: ID!) {
          resetDraftSubjectLayout(subjectId: $subjectId) {
              key
              labelTranslations
              scopeGeneratorDraftLayout {
                  ${scopeGeneratorLayout(true)}
              }
              scopeGeneratorLayout {
                  ${scopeGeneratorLayout(true)}
              }
              translatedLabel
              mandateTypes
              mandateSubTypes
          }
        }
      `,
      variables: {
        subjectId: subject.key
      }
    })

    commit('updateSubject', response.data.resetDraftSubjectLayout)
    commit('setCurrentSubject', response.data.resetDraftSubjectLayout)
  },

  async updateSubject ({ getters, commit, state }, { subject, loadLayout }) {
    const input = JSON.parse(JSON.stringify(subject))
    delete input.translatedLabel

    const layout = loadLayout ? `
      scopeGeneratorLayout {
        ${scopeGeneratorLayout(true)}
      }
      scopeGeneratorDraftLayout {
        ${scopeGeneratorLayout(true)}
      }` : ''

    const response = await apollo(state).mutate({
      mutation: gql`
        mutation addOrUpdateSubject ($updateSubjectInput: UpdateSubjectInput!) {
          addOrUpdateSubject (updateSubjectInput: $updateSubjectInput) {
            key
            active
            labelTranslations
            translatedLabel
            mandateSubTypes
            mandateTypes
            ${layout}
          }
        }
      `,
      variables: {
        updateSubjectInput: input
      }
    })

    commit('updateSubject', response.data.addOrUpdateSubject)
    commit('setCurrentSubject', response.data.addOrUpdateSubject)
    return response.data.addOrUpdateSubject.key
  },

  async updateOptionList ({ getters, commit, state }, { optionList }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation addOrUpdateOptionList ($updateOptionListInput: UpdateOptionListInput!) {
          addOrUpdateOptionList (updateOptionListInput: $updateOptionListInput) {
            key
            isReserved
            options {
              key
              active
              orderId
              labelTranslations
              translatedLabel
            }
          }
        }
      `,
      variables: {
        updateOptionListInput: optionList
      }
    })

    commit('updateOptionList', response.data.addOrUpdateOptionList)
  },

  async saveCurrentSubject ({ dispatch, state }) {
    await dispatch('updateSubject', { subject: state.admin.scopeGenerator.currentSubject, loadLayout: true })
  },

  async loadSubjectWithLayout ({ getters, commit, state }, { subjectKey, adminView }) {
    const response = await apollo(state).query({
      query: gql`
        query subjects($subjectIds: [ID]) {
          subjects (subjectIds: $subjectIds) {
            active
            key
            labelTranslations
            translatedLabel
            mandateTypes
            mandateSubTypes
            scopeGeneratorDraftLayout {
              ${scopeGeneratorLayout(adminView)}
            }
            scopeGeneratorLayout {
              ${scopeGeneratorLayout(adminView)}
            }
          }
        }
      `,
      variables: {
        subjectIds: [subjectKey]
      }
    })

    const subject = response.data.subjects[0]
    commit('setCurrentSubject', subject)
  },

  async loadRfpStatistics ({ state, commit }, fieldName) {
    const response = await apollo(state).query({
      query: gql`
        query rfpStatistics($fieldName: String!) {
          rfpStatistics(fieldName: $fieldName) {
            type
            value
            count
          }
        }
      `,
      variables: {
        fieldName: fieldName
      }
    })

    commit('setRfpStatistics', response.data.rfpStatistics)
  },

  async loadRfpStatisticsFieldNames ({ state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query statisticFieldNames {
          statisticFieldNames
        }
      `
    })

    commit('setRfpStatisticsFieldNames', response.data.statisticFieldNames)
  }

}
