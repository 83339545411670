import apollo from '../../apollo'
import gql from 'graphql-tag'
import { lawFirmRelationWithProposal, auctionBid } from '../graphqlTags/rfpTags'
import Vue from 'vue'

export default {

  async loadProposal ({ state, getters, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query rfp($id: ID!) {
          rfp (id: $id) {
            lawFirmRelations {
              ${lawFirmRelationWithProposal(getters.userIsLawFirm)}
            }
          }
        }
      `,
      variables: {
        id: state.currentRfp.id
      }
    })

    const lawFirmRelations = response.data.rfp.lawFirmRelations
    commit('setLawFirmRelations', lawFirmRelations)
  },

  async refreshDraftProposal ({ state, getters, commit }) {
    const lawFirmRelations = state.currentRfp.lawFirmRelations
    const draftProposal = state.draftProposal
    for (const item of lawFirmRelations[0].proposal) {
      if (item.name !== 'CREDENTIALS' && item.name !== 'LIABILITY' && item.name !== 'TEAM') {
        continue
      }
      if (!item.draft) {
        continue
      }
      draftProposal[item.name.toLowerCase()] = JSON.parse(JSON.stringify(item))
    }
    Vue.set(state, 'draftProposal', draftProposal)
  },

  async updateProposalCategories ({ state, getters }, proposalCategories) {
    const body = JSON.parse(JSON.stringify(proposalCategories))
    for (const proposalCategory of body) {
      if (!proposalCategory.draft) {
        delete proposalCategory.id
        proposalCategory.draft = true
      }
    }
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation updateProposal ($rfpId: ID!, $lawFirmId: ID!, $proposalCategories: [UpdateProposalCategoryInput]!) {
          updateProposal (rfpId: $rfpId, lawFirmId: $lawFirmId, proposalCategories: $proposalCategories) {
            name
            subItems {
              id
              extendedFields
            }
            extendedFields
            draft
          }
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        lawFirmId: getters.currentRfpLawFirmRelation.lawFirm.id,
        proposalCategories: body
      }
    })

    return response
  },

  async addOrUpdateProposalCategorySubItem ({ state, getters }, { qualityScoreCriterium, subItem }) {
    const body = JSON.parse(JSON.stringify(subItem))

    const response = await apollo(state).mutate({
      mutation: gql`
        mutation addOrUpdateProposalCategorySubItem ($rfpId: ID!, $lawFirmId: ID!, $qualityScoreCriterium: String!, $subItem: UpdateProposalCategorySubItemInput!) {
          addOrUpdateProposalCategorySubItem (rfpId: $rfpId, lawFirmId: $lawFirmId, qualityScoreCriterium: $qualityScoreCriterium, subItem: $subItem) {
            id
            extendedFields
          }
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        lawFirmId: getters.currentRfpLawFirmRelation.lawFirm.id,
        qualityScoreCriterium: qualityScoreCriterium,
        subItem: body
      }
    })

    return response
  },

  async submitFirstBid ({ state, commit, getters }, { bid }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation submitFirstBid ($jAuctionBidInput: JProposalBidInput!) {
          submitFirstBid (jAuctionBidInput: $jAuctionBidInput) {
            ${auctionBid}
          }
        }
      `,
      variables: {
        jAuctionBidInput: bid
      }
    })

    Vue.set(getters.currentRfpLawFirmRelation, 'firstBidToBePlacedDraft', response.data.submitFirstBid)
    commit('setUsersLastBid', response.data.submitFirstBid)
    return response
  },

  async saveCredential ({ getters, dispatch, commit }, { credential }) {
    console.log('SaveCredentialInput', credential)
    const result = await dispatch('addOrUpdateProposalCategorySubItem', { subItem: credential, qualityScoreCriterium: 'CREDENTIALS' })
    console.log('SaveCredential', result)
    await commit('addOrUpdateCredential', result.data.addOrUpdateProposalCategorySubItem)
  },

  async saveLiability ({ commit, dispatch, getters }, { liability }) {
    const result = await dispatch('updateProposalCategories', [liability])
    commit('removeMissingMandatoryField', 'LIABILITY')
  },

  async saveProposalCategory ({ commit, dispatch, getters }, { item, category }) {
    const result = await dispatch('updateProposalCategories', [item])
    commit('removeMissingMandatoryField', category)
  },

  async saveProposalTeamMember ({ commit, dispatch }, { teamMember }) {
    const result = await dispatch('addOrUpdateProposalCategorySubItem', { subItem: teamMember, qualityScoreCriterium: 'TEAM' })
    let updatedTeamMember = result.data.addOrUpdateProposalCategorySubItem
    updatedTeamMember.linkedUser = teamMember.linkedUser
    updatedTeamMember.attachments = teamMember.attachments
    commit('updateTeamMember', updatedTeamMember)
  },

  async submitProposal ({ getters, state, commit }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation submitProposal ($rfpId: ID!, $lawFirmId: ID!) {
          submitProposal (rfpId: $rfpId, lawFirmId: $lawFirmId) {
              ${lawFirmRelationWithProposal(true)}
          }
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        lawFirmId: getters.currentRfpLawFirmRelation.lawFirm.id
      }
    })

    commit('submitProposal', response.data.submitProposal)
  },

  async resubmitProposal ({ getters, state, commit }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation resubmitProposal ($rfpId: ID!) {
          resubmitProposal (rfpId: $rfpId) {
              ${lawFirmRelationWithProposal(true)}
          }
        }
      `,
      variables: {
        rfpId: state.currentRfp.id
      }
    })

    commit('resubmitProposal')
  },

  async rateProposal ({ getters, state, commit }, { categoryId, subItemId, value, ratingComment }) {
    const result = await apollo(state).mutate({
      mutation: gql`
        mutation rateProposalCategoryOrSubItem ($rfpId: ID!, $categoryId: ID!, $subItemId: ID, $rating: Float, $ratingComment: String, $deleteRating: Boolean) {
          rateProposalCategoryOrSubItem (rfpId: $rfpId, categoryId: $categoryId, subItemId: $subItemId, rating: $rating, ratingComment: $ratingComment, deleteRating: $deleteRating) {
            lawFirm {
              id
            }
            overallStarRating
            proposalRating
            proposal {
              id
              rating
              subItems {
                id
                rating
              }
            }
          }
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        categoryId: categoryId,
        subItemId: subItemId,
        rating: value !== 'DELETE' ? value : null,
        deleteRating: value === 'DELETE',
        ratingComment: ratingComment || ratingComment === '' ? ratingComment : null
      }
    })

    commit('updateLawFirmRelationRating', result.data.rateProposalCategoryOrSubItem)
  },

  async removePitchDocument ({ dispatch, state, commit }, attachment) {
    commit('removePitchDocument', attachment)
    await dispatch('deleteFile', {
      rfpId: state.currentRfp.id,
      fileId: attachment.id
    })
  },

  async deleteCredential ({ getters, state, commit }, credential) {
    await apollo(state).mutate({
      mutation: gql`
        mutation deleteProposalCategorySubItem($rfpId: ID!, $lawFirmId: ID!, $subItemId: Int!) {
          deleteProposalCategorySubItem(rfpId: $rfpId, lawFirmId: $lawFirmId, subItemId: $subItemId)
        }
      `,
      variables: {
        rfpId: state.currentRfp.id,
        lawFirmId: getters.currentLawFirm.id,
        subItemId: credential.id
      }
    })

    commit('deleteCredential', credential)
  },

  checkProposalUnsavedChanges ({ state, commit }) {
    let somethingChanged = false

    if (state.currentRfp.terms.qualityScoreCriteria.includes('TEAM')) {
      const draftTeam = state.draftProposal.teamMembers
      if (draftTeam && draftTeam.some(member => member.update)) {
        somethingChanged = true
      }
    }

    if (state.currentRfp.terms.qualityScoreCriteria.includes('CREDENTIALS')) {
      if (state.draftProposal.showNewCredentialInputForm) {
        somethingChanged = true
      } else {
        const draftCredentials = state.draftProposal.credentials
        if (draftCredentials && draftCredentials.subItems && draftCredentials.subItems.some(member => member.update)) {
          somethingChanged = true
        }
      }
    }

    if (state.currentRfp.terms.qualityScoreCriteria.includes('CREDENTIALS')) {
      if (state.draftProposal.editLiability) {
        somethingChanged = true
      }
      if (state.draftProposal.newLiability && (state.draftProposal.liability.extendedFields.maximumLiability || state.draftProposal.liability.extendedFields.explanation)) {
        somethingChanged = true
      }
    }

    if (state.draftProposal.editFirstBid) {
      somethingChanged = true
    }

    if (somethingChanged) {
      window.onbeforeunload = function () {
        return 'You have unsaved changes'
      }
      commit('setProposalHasUnsavedChanges', true)
    } else {
      window.onbeforeunload = null
      commit('setProposalHasUnsavedChanges', false)
    }
  }

}
