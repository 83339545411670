<template>
  <v-card>
    <div class="status">
      <section class="sections">
        <span class="label">{{ "activeRFP.summarySection.biddingType" | t }}</span>
        <span class="status-label">
          {{ $t(`activeRFP.biddingProcessSection.${currentRfp.auctionType}`) }}
        </span>
      </section>

      <section class="sections">
        <span class="label">{{ 'activeRFP.biddingProcessSection.durationLabel' | t }}</span>
        <span class="status-label">{{ durationCalc() }}</span>
      </section>

      <section class="sections">
        <span class="label">{{ "activeRFP.biddingProcessSection.feeStructure" | t }}</span>
        <span class="status-label">
          {{ $t(`dropDownOptions.FeeStructure.${currentRfp.terms.feeStructure}`) }} {{ (currentRfp.terms.feeStructureSubType && currentRfp.terms.feeStructure !== 'FIXED') ? '(' + $t(`dropDownOptions.FeeStructureSubType.${currentRfp.terms.feeStructureSubType}`) + ')' : ''}}
        </span>
      </section>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'AuctionSummaryInfo',
  computed: {
    ...mapGetters(['auctionProgress', 'isSimpleRfp', 'formatDateTime', 'isEAuction']),
    ...mapState({
      currentRfp: state => state.currentRfp
    })
  },
  methods: {
    durationCalc () {
      let endDateTime
      let startDateTime
      endDateTime = moment.utc(this.currentRfp.auctionEnd)
      if (!this.isEAuction) {
        startDateTime = this.currentRfp.allowQA ? moment.utc(this.currentRfp.qaEnd) : moment.utc(this.currentRfp.submission)
      } else {
        startDateTime = moment.utc(this.currentRfp.auctionStart)
      }
      const duration = moment.duration(endDateTime.diff(startDateTime))
      return duration.humanize()
      // // console.log(startDateTime, endDateTime)
      // const day = duration.days() > 0 ? duration.days() : 0
      // const hour = duration.hours() > 0 ? duration.hours() : 0
      // const minute = duration.minutes() > 0 ? duration.minutes() : 0
      //
      // let durationValue
      // if (day > 0) {
      //   durationValue = `${day} day` + (day > 1 ? 's' : '') + ' ' + (hour > 11 ? hour + 'h' : '')
      // } else {
      //   durationValue = `${hour}h ${minute}m`
      // }
      //
      // return durationValue
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../../assets/css/variables.styl"

  .status
    display: grid
    grid-template-columns repeat(3, auto)

  .sections
    margin-right 20px
    display grid
    grid-template-rows auto 1fr

  .label
    font-weight bold
    color $icon-gray

  .status-label
    font-weight bold

  .v-card
    border-radius: 10px;
    background: #fff;
    padding: 30px;
</style>
