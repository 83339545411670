export const lintumUser = `
id
active
fullName
email
position
phone
mobile
mainContactPerson
language
invitePending
companyName
deletable
lawFirm {
  id
         }
corporate {
  id
  transactionVolume {
    periodStart
    periodEnd
    volumeEUR
    volumeUSD
              }
            }
primaryRole`
