<template>
  <div v-if="show" style="margin: 10px 0px" class="panel">
    <h2 style="margin-bottom: 10px">
      {{ subject.translatedLabel || subject.key }}
      <ChangeIcon v-if="subjectChangedToLastVersion(subject.key)"></ChangeIcon>
    </h2>
    <Section
      v-for="(section, index) in layoutDefinitions[subject.key].sections"
      :key="'dynamic'+index"
      :subject="subject"
      :root-object="root"
      :section="section"
      :update-mutation="''"
      :update-list-item-mutation="''"
      :add-list-item-mutation="''"
      readonly />

    <div v-if="filteredAttachements.length" class="summary-panel" style="display: grid; grid-template-columns: 200px 1fr; padding-top: 20px;">
      <h2>{{ "activeRFP.summarySection.termsAndConditions.documents" | t }}</h2>
      <ul>
        <li v-for="attachment in filteredAttachements" :key="attachment.url">
          <a :href="attachment.url" target="_blank"><v-icon>ic-file</v-icon>&nbsp;{{ attachment.fileName }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Section from '../../../generalComponents/inputWrapper/Section'
import ChangeIcon from '@/views/generalComponents/ChangeIcon'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'DynamicSummaryPanel',
  components: { Section, ChangeIcon },
  props: {
    root: {
      type: Object,
      required: true
    },
    subject: {
      type: Object,
      required: true
    },
    layoutDefinitions: {
      type: Object,
      required: true
    },
    currentRfp: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['sectionShouldBeShownOnSummary', 'subjectChangedToLastVersion']),

    filteredAttachements () {
      return this.currentRfp.attachments.filter(document => document.rfpFileType === 'SCOPE_GENERATOR' && document.subject === this.subject.key)
    },

    show () {
      if (this.currentRfp.attachments && this.currentRfp.attachments.some(file => file.subject === this.subject.key && file.rfpFileType === 'SCOPE_GENERATOR')) {
        return true
      }

      return this.layoutDefinitions[this.subject.key].sections.some(section => {
        return this.sectionShouldBeShownOnSummary(section, this.subject, this.root)
      })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  .panel
    background #fff
    padding 60px 100px
    border-radius 10px
    box-shadow 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)

  .panel >>> .v-card
    padding 0 !important
    box-shadow none !important

  .readonly:not(:last-child)
    border-bottom 1px solid $light-gray
    padding 10px 0 10px 0

  a
    text-decoration none
    color inherit

  .icon
    .v-icon
      color $dark-gray !important
      font-size 22px

</style>
