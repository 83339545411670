<template>
  <div class="main">
    <v-card class="feedbackCard" >
      <div style="margin: 0; height: 30px" class="rating-fields">
        <h3>{{ 'activeRFP.linkTabs.feedback' | t }}</h3>
        <section v-if="feedbackLawfirmRelation.overallFeedbackRating > 0" style="justify-self: end">
          <b><span style="margin-right: 5px; color: #ababb5">{{ 'activeRFP.feedback.overallRating' | t }}</span></b>
          <v-avatar size="30" class="rating">
              <span style="color: #fff;">{{ Math.round(feedbackLawfirmRelation.overallFeedbackRating * 10) / 10 }}</span>
          </v-avatar>
        </section>
      </div>
      <v-divider></v-divider>
      <section class="feedbackSection" v-for="feedbackSection in feedbackSections" :key="feedbackSection.name">
        <div class="rating-fields">
          <h3>{{ `activeRFP.feedback.${feedbackSection.name}Label` | t }}</h3>
          <section v-if="isFeedbacSectionRated(feedbackSection.name)" style="justify-self: center">
            <!-- <b><span style="color: #ababb5">Section Rating:</span></b><br /> -->
            <v-avatar size="30" class="rating">
              <span style="color: #fff;">{{ Math.round(feedbackLawfirmRelation.feedbackRatingByCategory[feedbackSection.name.toUpperCase()] * 10) / 10 }}</span>
            </v-avatar>
          </section>
        </div>
          <div>
            <div class="questionRow" v-for="subItem in feedbackSection.subItems" :key="subItem.questionId">
              <p style="margin-bottom: 0px">{{ $t(`activeRFP.feedback.${feedbackSection.name.toLowerCase()}.${subItem.questionKey}`, { corporate: currentRfp.corporate.name }) | t }}</p>
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                  <v-btn
                    class="reset-rating"
                    v-if="subItem.rating !== null && !diableRating && hover"
                    @click="rateLawFirm({ questionKey: subItem.questionKey, category: subItem.category, extendedFields: null, deleteRating: true })"
                    fab
                    icon><v-icon >close</v-icon></v-btn>
                  <span v-else></span>
                  <v-rating
                    @input="value => rateFeedbackItem({ questionKey: subItem.questionKey, category: subItem.category, value })"
                    :value="subItem.rating"
                    :readonly="diableRating"
                    small
                    color="orange"
                    background-color="orange lighten-3"
                    half-increments hover />
                </div>
              </v-hover>
              <v-divider style="margin: 10px 0; border-color: rgb(245, 245, 245)" v-if="subItem.questionId !== feedbackSection.subItems.length - 1" ></v-divider>
            </div>
          </div>
        <v-divider></v-divider>
      </section>

        <template v-if="hasTeam">
          <section class="feedbackSection">
          <div class="rating-fields">
            <h3>{{ `activeRFP.feedback.teamMembersLabel` | t }}</h3>
            <v-avatar v-if="isFeedbacSectionRated('TEAM')" size="30" style="justify-self: center" class="rating">
              <span style="color: #fff;">{{ Math.round(feedbackLawfirmRelation.feedbackRatingByCategory['TEAM'] * 10) / 10 }}</span>
            </v-avatar>
          </div>
            <div>
              <div class="questionRow" v-for="(teamUser, index) in actualTeam" :key="teamUser.id">

                <section style="display: inline-flex">
                  <v-avatar size="30" class="circle-span">
                    <v-icon style="font-size: 24px">ic-user</v-icon>
                  </v-avatar>
                  <div>
                    <p><b>{{ teamUser.linkedUser.fullName }}</b></p>
                    <p>{{ `dropDownOptions.SeniorityLevel.${teamUser.seniorityLevel}` | t }}</p>
                  </div>
                </section>
                <v-hover>
                  <div class="rating-lf" slot-scope="{ hover }">
                    <v-btn
                      class="reset-rating"
                      v-if="teamUser.rating !== null && !diableRating && hover"
                      @click="rateLawFirm({ userId: teamUser.linkedUser.id, category: 'TEAM', extendedFields: null, deleteRating: true })"
                      fab
                      icon><v-icon >close</v-icon></v-btn>
                    <span v-else></span>
                    <v-rating
                      @input="value => rateTeamMember({ userId: teamUser.linkedUser.id, category: 'TEAM', value, extendedFields: teamUser.extendedFields })"
                      :value="teamUser.rating"
                      :readonly="diableRating"
                      small
                      color="orange"
                      background-color="orange lighten-3"
                      half-increments hover />
                  </div>
                </v-hover>
                <template v-if="!diableRating && teamUser.rating !== null && (teamUser.extendedFields === null || getComment(teamUser.linkedUser.id).edit)">
                  <div>
                    <v-text-field
                      label=""
                      :class="{missing: invalidFields.includes(`comment${teamUser.linkedUser.id}`)}"
                      :value="getComment(teamUser.linkedUser.id).comment"
                      @blur="value => updateNewComment(value, teamUser.linkedUser.id)"
                      @keyup="value => updateNewComment(value, teamUser.linkedUser.id)"
                      :placeholder="$t('activeRFP.feedback.team.textPlaceholder')"
                      solo flat />
                    <span @click="submitComment({ userId: teamUser.linkedUser.id, value: teamUser.rating })" class="edit-answer"><v-icon class="edit-answer">edit</v-icon> {{ 'activeRFP.feedback.submitBtn' | t }}</span>
                  </div>
                </template>
                <template v-else-if="teamUser.rating !== null">
                  <div>
                    <p style="margin: 10px 0">{{ getComment(teamUser.linkedUser.id).comment }}</p>
                    <div v-if="!diableRating">
                      <span class="edit-answer" @click="setEditComment(teamUser.linkedUser.id)"><v-icon class="edit-answer">edit</v-icon> {{ 'activeRFP.q&a.edit' | t }}</span>
                      <span class="delete-answer" @click="showDeleteConfirm({ userId: teamUser.linkedUser.id, value: teamUser.rating })"><v-icon class="delete-answer">delete</v-icon> {{ 'activeRFP.q&a.delete' | t }}</span>
                    </div>
                  </div>
                </template>
                <v-divider style="margin: 10px 0; border-color: rgb(245, 245, 245)" v-if="index !== actualTeam.length - 1" ></v-divider>
              </div>
            </div>
          </section>
        </template>
    </v-card>
    <ConfirmDialog
      v-if="showDeleteCommentDialog"
      :text="'Are you sure you want to delete this comment?'"
      :title="'Delete comment'"
      :cancelButtonTextOrKey="'buttons.cancel'"
      @close="showDeleteCommentDialog = false"
      @submit="deleteComment()" />
    <FeedbackFooter @submitFeedback="feedbackSubmited = true" v-if="!diableRating" isFeedback />
  </div>
</template>

<script>
import FeedbackFooter from '../proposal/ProposalFooter'
import ConfirmDialog from '../../generalComponents/ConfirmDialog'
import { questions } from './feedbackQuestions'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Feedback',
  components: { FeedbackFooter, ConfirmDialog },
  data () {
    return {
      allComments: [],
      feedbackSubmited: false,
      showDeleteCommentDialog: false,
      deleteValues: null
    }
  },
  created () {
    this.setAllComments()
    if (this.currentRfp.status === 'COMPLETED_MATTER_CLOSED') {
      this.feedbackSubmited = true
    }
  },
  computed: {
    ...mapState({
      currentRfp: state => state.currentRfp,
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields
    }),
    ...mapGetters(['userIsLawFirm']),

    diableRating () {
      return this.currentRfp.status === 'COMPLETED_MATTER_CLOSED' || this.userIsLawFirm || this.feedbackSubmited
    },

    feedbackSections () {
      if (this.feedbackLawfirmRelation.feedbackRatings.length) {
        let actualQuestions = questions()
        actualQuestions.forEach(sections => {
          this.feedbackLawfirmRelation.feedbackRatings.forEach(rating => {
            const sectionIndex = sections.subItems.findIndex(subItem => subItem.questionKey === rating.questionKey)
            if (sectionIndex > -1) {
              sections.subItems[sectionIndex].rating = rating.rating
            }
          })
        })
        return actualQuestions
      }
      return questions()
    },

    feedbackLawfirmRelation () {
      return this.currentRfp.lawFirmRelations.filter(lawFirmRelation => lawFirmRelation.status === 'COMPLETED_WON')[0]
    },

    hasTeam () {
      return this.currentRfp.terms.qualityScoreCriteria.length > 0 && this.currentRfp.terms.qualityScoreCriteria.includes('TEAM')
    },

    actualTeam () {
      if (!this.hasTeam) {
        return false
      }

      let teamArray = this.feedbackLawfirmRelation.proposal[this.feedbackLawfirmRelation.proposal.findIndex(proposalItem => proposalItem.name === 'TEAM')].subItems
      teamArray = teamArray.map(team => {
        return {
          id: team.id,
          seniorityLevel: team.extendedFields.seniorityLevel ? team.extendedFields.seniorityLevel : 'ASSOCIATE',
          linkedUser: team.linkedUser,
          rating: null,
          extendedFields: null
        }
      })

      if (this.feedbackLawfirmRelation.feedbackRatings.some(feedbackRating => feedbackRating.linkedUser)) {
        this.feedbackLawfirmRelation.feedbackRatings.filter(feedbackRating => feedbackRating.linkedUser).forEach(rating => {
          teamArray[teamArray.findIndex(team => team.linkedUser.id === rating.linkedUser.id)].rating = rating.rating
          teamArray[teamArray.findIndex(team => team.linkedUser.id === rating.linkedUser.id)].extendedFields = rating.extendedFields
        })
      }

      return teamArray
    }
  },
  methods: {
    ...mapActions(['rateLawFirm']),
    ...mapMutations(['removeMissingMandatoryField', 'addInvalidField', 'removeInvalidField']),

    rateFeedbackItem ({ questionKey, value, category }) {
      this.removeMissingMandatoryField('feedback')
      this.rateLawFirm({ questionKey, value, category, deleteRating: false })
    },

    rateTeamMember ({ userId, value, extendedFields }) {
      this.removeMissingMandatoryField('feedback')
      this.rateLawFirm({ userId, value, category: 'TEAM', extendedFields, deleteRating: false })
    },

    submitComment ({ userId, value }) {
      if (this.allComments.some(comment => comment.id === userId)) {
        const extendedFields = {
          comment: this.allComments[this.allComments.findIndex(comment => comment.id === userId)].comment
        }
        this.allComments[this.allComments.findIndex(comment => comment.id === userId)].edit = false
        this.rateLawFirm({ userId, value, extendedFields, category: 'TEAM', deleteRating: false })
      } else {
        this.addInvalidField(`comment${userId}`)
      }
    },

    showDeleteConfirm ({ userId, value }) {
      this.deleteValues = function () {
        return { userId, value }
      }
      this.showDeleteCommentDialog = true
    },

    updateNewComment (event, teamUserId) {
      this.removeInvalidField(`comment${teamUserId}`)
      if (this.allComments.some(comment => comment.id === teamUserId)) {
        this.allComments[this.allComments.findIndex(comment => comment.id === teamUserId)].comment = event.target.value
      } else {
        this.allComments.push({
          comment: event.target.value,
          id: teamUserId,
          edit: true
        })
      }
    },

    setEditComment (teamUserId) {
      if (this.allComments.some(comment => comment.id === teamUserId)) {
        this.allComments[this.allComments.findIndex(comment => comment.id === teamUserId)].edit = true
      }
    },

    setAllComments () {
      if (!this.hasTeam) {
        return
      }
      this.actualTeam.forEach(teamMember => {
        if (teamMember.extendedFields) {
          this.allComments.push({
            comment: teamMember.extendedFields.comment,
            id: teamMember.linkedUser.id,
            edit: false
          })
        }
      })
    },

    deleteComment () {
      const values = this.deleteValues()
      this.rateLawFirm({ userId: values.userId, value: values.value, category: 'TEAM', extendedFields: null, deleteRating: false })
      this.allComments.splice(this.allComments.findIndex(comment => comment.id === values.userId), 1)
      this.showDeleteCommentDialog = false
      this.deleteValues = null
    },

    getComment (teamUserId) {
      if (this.allComments.some(comment => comment.id === teamUserId)) {
        return this.allComments.find(comment => comment.id === teamUserId)
      } else {
        return ''
      }
    },

    isFeedbacSectionRated (sectionName) {
      return this.feedbackLawfirmRelation.feedbackRatingByCategory[sectionName.toUpperCase()] > 0
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../../assets/css/variables.styl"

  .feedbackCard
    border-radius 10px
    margin-top 20px
    padding 60px 100px

  .feedbackSection
    display grid
    grid-template-columns 200px 1fr

  .v-divider
    margin 20px 0
    grid-column-end: span 2

  .questionRow
    display grid
    grid-column-gap 40px
    grid-template-columns 1fr auto

  h3
    color $icon-gray

  :deep(.v-rating)
    .v-icon
      color $gold100 !important

  :deep(.v-icon:before)
    color: $light-gray !important

  p
    margin-bottom 0px

  .circle-span
    margin 8px 10px 0 0
    background-color $icon-gray

  :deep(.v-text-field)
    .v-input__slot
      border 1px solid rgb(158, 158, 158)
      border-radius 5px !important
      margin 10px 0px

  :deep(.v-text-field__details)
    display none

  .edit-answer, .delete-answer
    font-weight bold

    .v-icon
      font-size 14px

    &:hover
      border-radius 5px
      cursor pointer

  .edit-answer
    color $dark-gray !important

  .delete-answer
    color $red !important
    margin-left 10px

  .rating-lf
    display grid
    grid-template-columns  32px auto

  .reset-rating
    justify-self end
    height 30px
    width 30px
    margin 0
    .v-icon
      font-size 20px
      color $gold100 !important

  .rating
    background-color $gold100

  .rating-fields
    display grid
    grid-template-columns 0.8fr auto
    margin-right 20px
</style>
