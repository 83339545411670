import apollo from '../../apollo'
import gql from 'graphql-tag'
import moment from 'moment'
import { lawFirmRelationJustId } from '../graphqlTags/rfpTags'

export default {

  async setCorporateBilled ({ state, commit }, { rfpId, corporateBilled }) {
    console.log('corporateBilled', corporateBilled)
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation setCorporateBilled($rfpId: ID!, $corporateBilled: Boolean) {
          setCorporateBilled(rfpId: $rfpId, corporateBilled: $corporateBilled) {
            id
            corporateBilled
            corporateBilledDate
          }
        }
      `,
      variables: {
        rfpId: rfpId,
        corporateBilled: corporateBilled
      }
    })

    const setCorporateBilled = response.data.setCorporateBilled
    commit('updateCorporateBilled', setCorporateBilled)
  },

  async setCorporateBilledDate ({ state, commit }, { rfpId, corporateBilledDate }) {
    const newDateMoment = moment(corporateBilledDate, 'DD.MM.YYYY')

    if (!newDateMoment.isValid()) {
      return
    }
    const value = moment()

    value.milliseconds(0)
    value.seconds(0)

    value.date(newDateMoment.date())
    value.month(newDateMoment.month())
    value.year(newDateMoment.year())

    corporateBilledDate = value.toISOString()

    const response = await apollo(state).mutate({
      mutation: gql`
        mutation setCorporateBilledDate($rfpId: ID!, $corporateBilledDate: DateTime) {
          setCorporateBilledDate(rfpId: $rfpId, corporateBilledDate: $corporateBilledDate) {
            id
            corporateBilledDate
          }
        }
      `,
      variables: {
        rfpId: rfpId,
        corporateBilledDate: corporateBilledDate
      }
    })

    const setCorporateBilledDate = response.data.setCorporateBilledDate
    commit('updateCorporateBilledDate', setCorporateBilledDate)
  },

  async setInvoiceAmount ({ state, commit }, { rfpId, invoiceAmount }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation setInvoiceAmount($rfpId: ID!, $invoiceAmount: Float!) {
          setInvoiceAmount(rfpId: $rfpId, invoiceAmount: $invoiceAmount) {
            id
            invoiceAmount
          }
        }
      `,
      variables: {
        rfpId: rfpId,
        invoiceAmount: invoiceAmount || 0
      }
    })

    const setInvoiceAmount = response.data.setInvoiceAmount
    commit('updateInvoiceAmount', setInvoiceAmount)
  },

  async setLawFirmBilled ({ state, commit }, { rfpId, lawFirmId, lawFirmBilled }) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation setLawFirmBilled($rfpId: ID!, $lawFirmId: ID!, $lawFirmBilled: Boolean) {
          setLawFirmBilled(rfpId: $rfpId, lawFirmId: $lawFirmId, lawFirmBilled: $lawFirmBilled) {
            id
            lawFirmRelations {
              ${lawFirmRelationJustId(false, true)}
            }
          }
        }
      `,
      variables: {
        rfpId: rfpId,
        lawFirmId: lawFirmId,
        lawFirmBilled: lawFirmBilled
      }
    })

    const setLawFirmBilled = response.data.setLawFirmBilled
    commit('updateLawFirmBilled', setLawFirmBilled)
  }

}
