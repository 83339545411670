export default {

  allFields (state) {
    if (!state.admin.scopeGenerator.currentSubject || !state.admin.scopeGenerator.currentSubject.scopeGeneratorDraftLayout) {
      return []
    }

    const layout = state.admin.scopeGenerator.currentSubject.scopeGeneratorDraftLayout

    const result = []
    layout.sections.forEach(section => {
      section.panels.forEach(panel => {
        panel.rows.forEach(row => {
          row.fields.forEach(field => {
            result.push({
              text: section.key + '.' + panel.key + '.' + field.key,
              value: field.key,
              type: field.fieldType,
              optionListKey: field.optionListKey
            })
          })
        })
      })
    })

    return result
  },

  allPanels (state) {
    if (!state.admin.scopeGenerator.currentSubject || !state.admin.scopeGenerator.currentSubject.scopeGeneratorDraftLayout) {
      return []
    }

    const layout = state.admin.scopeGenerator.currentSubject.scopeGeneratorDraftLayout

    const result = []
    layout.sections.forEach(section => {
      section.panels.forEach(panel => {
        result.push(panel)
      })
    })

    return result
  },

  allFieldsInNonListPanels (state) {
    if (!state.admin.scopeGenerator.currentSubject || !state.admin.scopeGenerator.currentSubject.scopeGeneratorDraftLayout) {
      return []
    }

    const layout = state.admin.scopeGenerator.currentSubject.scopeGeneratorDraftLayout

    const result = []
    layout.sections.forEach(section => {
      section.panels.forEach(panel => {
        if (panel.isList) {
          return
        }

        panel.rows.forEach(row => {
          row.fields.forEach(field => {
            result.push({
              text: section.key + '.' + panel.key + '.' + field.key,
              value: field.key,
              type: field.fieldType,
              optionListKey: field.optionListKey
            })
          })
        })
      })
    })

    return result
  },

  allOtherFields (state, getters) {
    return (field) => getters.allFieldsInNonListPanels.filter(_field => _field.value !== field.key)
  },

  allFieldsInPanel (state, getters) {
    return (panel) => {
      const result = []

      panel.rows.forEach(row => {
        row.fields.forEach(field => {
          result.push(field.key)
        })
      })

      return result.map(key => getters.allFields.find(field => field.value === key))
    }
  },

  allOtherFieldsInPanel (state, getters) {
    return (panel, field) => {
      return getters.allFieldsInPanel(panel).filter(_field => _field.value !== field.key)
    }
  },

  allFieldsInOtherPanels (state, getters) {
    return (panel) => {
      return getters.allFields.filter(field => !getters.allFieldsInPanel(panel).includes(field))
    }
  },

  enabledByApplicableFields () {
    return (fields) => {
      return fields.filter(field => ['CHECKBOX', 'CHECKBOX_INDENTED', 'SINGLE_SELECT', 'RADIO'].includes(field.type))
    }
  }
}
