<template>
  <v-card>
    <v-card-text style="padding-right: 90px; padding-left: 90px; padding-bottom: 20px;">

      <span class="mandatory">{{ 'dashboard.createNewRfp.projectName' | t }}</span>
      <v-text-field
        label=""
        :class="{missing: missingMandatoryFields.includes('name')}"
        :disabled="!isRfpFieldEditable(true)"
        :value="currentRfp.name"
        @input="value => {removeMissingMandatoryField('name'); updateCurrentRfp({ field: 'name', value: value })}"
        :placeholder="'dashboard.createNewRfp.placeHolders.namePH' | t "
        solo
        flat
      ></v-text-field>

      <span class="mandatory">{{ 'dashboard.createNewRfp.projectDescription' | t }}</span>
      <v-textarea
        label=""
        :class="{missing: missingMandatoryFields.includes('description')}"
        :disabled="!isRfpFieldEditable(true)"
        :value="currentRfp.description"
        @input="value => {removeMissingMandatoryField('description'); updateCurrentRfp({ field: 'description', value: value })}"
        :placeholder="'dashboard.createNewRfp.placeHolders.descriptionPH' | t "
        solo
        flat
      ></v-textarea>

      <span class="mandatory">{{ 'dashboard.createNewRfp.projectMatter' | t }}</span>
      <v-select
        label=""
        :class="{missing: missingMandatoryFields.includes('mandateType')}"
        :disabled="!isRfpFieldEditable()"
        :value="currentRfp.mandateType"
        @input="value => {removeMissingMandatoryField('mandateType'); updateCurrentRfp({ field: 'mandateSubType', value: null }); updateCurrentRfp({ field: 'mandateType', value: value })}"

        :items="mandateTypeOptions"
        :placeholder="'dashboard.createNewRfp.placeHolders.matterPH' | t "
        solo
        flat
      >
      </v-select>

      <span v-if="mandateSubTypeOptionsFiltered.length" class="mandatory">{{ 'dashboard.createNewRfp.projectSubMatter' | t }}</span>
      <v-select
        v-if="mandateSubTypeOptionsFiltered.length"
        label=""
        :class="{missing: missingMandatoryFields.includes('mandateSubType')}"
        :disabled="!isRfpFieldEditable()"
        :value="currentRfp.mandateSubType"
        @input="value => {removeMissingMandatoryField('mandateSubType'); updateCurrentRfp({ field: 'mandateSubType', value: value })}"
        :items="mandateSubTypeOptionsFiltered"
        :placeholder="'dashboard.createNewRfp.placeHolders.subMatterPH' | t "
        solo
        flat
      >
      </v-select>

      <span class="mandatory" style="display: block; margin-top: 10px">{{ 'dashboard.createNewRfp.keySubject' | t }}</span>
      <v-select
        label=""
        :class="{missing: missingMandatoryFields.includes('subject_' + currentRfp.subjects[0].id)}"
        :disabled="!isRfpFieldEditable()"
        :value="currentRfp.subjects[0].key"
        @input="value => {removeMissingMandatoryField('subject_' + currentRfp.subjects[0].id); updateSubject(value, 0) }"
        :items="applicableSubjects(currentRfp.subjects[0])"
        item-text="translatedLabel"
        item-value="key"
        :placeholder="'dashboard.createNewRfp.placeHolders.keySubject' | t "
        solo
        flat
      >
      </v-select>

      <div
        class="other-parties-header"
        style="height: 45px"
        v-if="currentRfp.subjects.length > 1 || (currentRfp.subjects.length === 1 && currentRfp.subjects[0].key)">
        <span v-if="currentRfp.subjects.length > 1">{{ 'dashboard.createNewRfp.otherSubjects' | t }}</span>
        <v-btn
          flat @click="addOtherSubject"
          :style="currentRfp.subjects.length > 1 ? '' : 'width: 134px'"
          :disabled="currentRfp.subjects.length >= 5 || !isRfpFieldEditable()"><v-icon>ic-plus-circle</v-icon>&nbsp;{{ 'dashboard.createNewRfp.addOtherSubject' | t }}</v-btn>
      </div>

      <div
        v-for="(subject, index) in currentRfp.subjects.slice(1)"
        :key="subject.id"
        style="display: grid; grid-template-columns: 1fr 60px; grid-column-gap: 10px; height: 70px">

        <div>
          <v-select
            label=""
            :value="subject.key"
            @input="value => {removeMissingMandatoryField('subject_' + subject.id); updateSubject(value, index + 1)}"
            :class="{missing: missingMandatoryFields.includes('subject_' + subject.id)}"
            :items="applicableSubjects(subject)"
            item-text="translatedLabel"
            item-value="key"
            :placeholder="'dashboard.createNewRfp.placeHolders.otherSubject' | t "
            solo
            flat
          >
          </v-select>
        </div>

        <div>
          <v-btn
            style="margin-top: -5px;"
            @click="removeSubject(subject.id, index + 1)" flat fab icon>
            <v-icon>clear</v-icon>
          </v-btn>
        </div>
      </div>

      <span class="mandatory">{{ 'dashboard.createNewRfp.projectClientName' | t }}</span>
      <v-text-field
        label=""
        :class="{missing: missingMandatoryFields.includes('clientName')}"
        :disabled="!isRfpFieldEditable(true)"
        :value="currentRfp.clientName"
        @input="value => {removeMissingMandatoryField('clientName'); updateCurrentRfp({ field: 'clientName', value: value })}"
        :placeholder="'dashboard.createNewRfp.placeHolders.clientNamePH' | t "
        solo
        flat
      ></v-text-field>

      <div class="other-parties-header">
        <span v-if="currentRfp.coi1.otherParties.length">{{ 'dashboard.createNewRfp.projectOtherParties' | t }}</span>
        <v-btn
          :disabled="!isRfpFieldEditable(true)"
          flat @click="addParty"
          :style="currentRfp.coi1.otherParties.length ? '' : 'width: 125px'"><v-icon>ic-user-plus</v-icon>&nbsp;{{ 'dashboard.createNewRfp.addOtherParty' | t }}</v-btn>
      </div>
      <div v-for="(party, index) in currentRfp.coi1.otherParties" class="other-parties" :key="party.id">

        <div>
          <span class="mandatory">{{ 'dashboard.createNewRfp.otherPartyName' | t }}</span>
          <v-text-field
            label=""
            :class="{missing: missingMandatoryFields.includes('coi1.otherParties.' + party.id + '.name')}"
            :disabled="!isRfpFieldEditable(true)"
            :value="party.name"
            @input="value => { removeMissingMandatoryField('coi1.otherParties.' + party.id + '.name'); updatePartyName(value, index) }"
            :placeholder="'dashboard.createNewRfp.placeHolders.otherPartyName' | t "
            solo
            flat
          ></v-text-field>
        </div>

        <div>
          <span class="mandatory">{{ 'dashboard.createNewRfp.otherPartyRole' | t }}</span>
          <v-combobox
            :items="otherPartyRolesOptions"
            label=""
            solo flat
            :item-text="itemText"
            :disabled="!isRfpFieldEditable(true)"
            :class="{missing: missingMandatoryFields.includes('coi1.otherParties.' + party.id + '.role')}"
            :value="otherPartyRolesOptions.find(option => option.value === party.role) || party.role"
            @input="value => { removeMissingMandatoryField('coi1.otherParties.' + party.id + '.role'); updatePartyRole(value, index) }"
            :placeholder="'dashboard.createNewRfp.placeHolders.otherPartyRole' | t "
          ></v-combobox>
        </div>

        <div>
          <v-btn
            style="margin-top: 15px;"
            :disabled="!isRfpFieldEditable(true)"
            @click="removeParty(party, index)" flat fab icon>
            <v-icon>clear</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import shortid from 'shortid'

export default {
  name: 'GeneralRfpPanel',
  computed: {
    ...mapGetters(['currentRfpOrDraft', 'isRfpFieldEditable', 'scopeGeneratorAvailable', 'rfpHasOldScope', 'mandateTypeOptions', 'mandateSubTypeOptions', 'otherPartyRolesOptions']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      subjects: state => state.admin.scopeGenerator.subjects,
      allRfps: state => state.allRfps,
      currentRfp () {
        return this.currentRfpOrDraft
      },

      orderedRfps () {
        return this.allRfps.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          return 0
        })
      },

      mandateSubTypeOptionsFiltered () {
        if (!this.mandateSubTypeOptions) {
          return []
        }
        return this.mandateSubTypeOptions.filter(subType => this.currentRfp.mandateType && subType.value.startsWith(this.currentRfp.mandateType))
      }
    })
  },
  methods: {
    ...mapActions(['deleteFile']),
    ...mapMutations(['updateCurrentRfp', 'updateCurrentRfpFreeScope', 'removeMissingMandatoryField', 'resetMandatoryViolations', 'setHasChangedSubject']),

    applicableSubjects (currentValue) {
      return this.subjects
        .filter(subject => !this.currentRfp.subjects.some(_subject => _subject.key === subject.key && subject.key !== currentValue.key))
        .filter(subject =>
          subject.mandateTypes.includes(this.currentRfp.mandateType) &&
          (subject.mandateSubTypes.includes(this.currentRfp.mandateSubType) || (!this.currentRfp.mandateSubType)))
    },
    itemText (option) {
      const key = `dropDownOptions.OtherPartyRoles.${option.value}`
      if (this._i18n.i18next.exists(key)) {
        return this.$t(key)
      } else {
        return option.value
      }
    },
    addOtherSubject () {
      if (this.currentRfp.subjects.length < 5) {
        this.currentRfp.subjects.push({ key: '', id: shortid.generate() })
        this.setHasChangedSubject(true)
        this.updateCurrentRfp({ field: 'usesScopeGenerator', value: false })
        this.updateCurrentRfp({ field: 'subjects', value: this.currentRfp.subjects })
      }
    },
    updateSubject (value, index) {
      const subject = this.subjects.find(_subject => _subject.key === value)
      this.currentRfp.subjects[index].key = subject.key
      this.currentRfp.subjects[index].translatedLabel = subject.translatedLabel
      this.setHasChangedSubject(true)
      this.updateCurrentRfp({ field: 'usesScopeGenerator', value: false })
      this.updateCurrentRfp({ field: 'subjects', value: this.currentRfp.subjects })
    },
    removeSubject (id, index) {
      this.removeMissingMandatoryField('subject_' + id)
      this.currentRfp.subjects.splice(index, 1)
      this.setHasChangedSubject(true)
      this.updateCurrentRfp({ field: 'usesScopeGenerator', value: false })
      this.updateCurrentRfp({ field: 'subjects', value: this.currentRfp.subjects })
    },
    addParty () {
      this.currentRfp.coi1.otherParties.push({
        id: shortid.generate(),
        name: '',
        role: ''
      })
    },
    updatePartyName (value, index) {
      this.currentRfp.coi1.otherParties[index].name = value
      this.updateCurrentRfp({ field: 'coi1', value: this.currentRfp.coi1 })
    },
    updatePartyRole (value, index) {
      const party = this.currentRfp.coi1.otherParties[index]
      if (typeof value === 'string') {
        party.role = value
      } else {
        party.role = value.value
      }
      this.updateCurrentRfp({ field: 'coi1', value: this.currentRfp.coi1 })
    },
    removeParty (party, index) {
      this.removeMissingMandatoryField('coi1.otherParties.' + party.id + '.name')
      this.removeMissingMandatoryField('coi1.otherParties.' + party.id + '.role')
      this.currentRfp.coi1.otherParties.splice(index, 1)
      this.updateCurrentRfp({ field: 'coi1', value: this.currentRfp.coi1 })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "../../../assets/css/variables.styl"

.button-grid
  display grid

.v-card
  border-radius 10px 0 0 10px

.v-card__text
  span
    color rgb(158, 158, 158)
    padding-left 12px

>>> .v-input__slot
  border 1px solid rgb(158, 158, 158)
  border-radius 5px !important

>>> .v-dialog
  overflow auto
  border-radius 10px

>>> .v-dialog::-webkit-scrollbar {
  border-radius 0 10px 10px 0
  background-color #ffffff
  width 10px
}
>>> .v-dialog::-webkit-scrollbar-thumb {
  background-color #aaa
  border-radius 5px
}
>>> .v-dialog::-webkit-scrollbar-button {
  display none
}

.other-parties
  display grid
  grid-template-columns 1fr 1fr 60px
  grid-column-gap 10px

  >>> .v-input__slot
    margin-bottom 10px !important

.other-parties-header
  display grid
  grid-template-columns 1fr 150px
  height 36px

  span
    line-height 36px

  button
    margin-top 0
    width 150px

  .v-icon
    font-size 15px
    &:before
      color $dark-gray !important

>>> .v-text-field__details
  display none

>>> .v-input__slot
  margin-bottom 25px !important
</style>
