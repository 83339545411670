import apollo from '../../apollo'
import gql from 'graphql-tag'
import { lawFirm, rating } from '../graphqlTags/rfpTags'

export default {

  async loadAllLawFirms ({ commit, state }) {
    const response = await apollo(state).query({
      query: gql`
        query allLawFirm {
          allLawFirms {
            ${lawFirm}
          }
        }
      `
    })

    commit('setAllLawFirms', response.data.allLawFirms)
  },

  async createLawFirm ({ dispatch, getters, state }, lawFirm) {
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation createLawFirm ($createLawFirmInput: CreateLawFirmInput!) {
          createLawFirm (createLawFirmInput: $createLawFirmInput) {
            id
          }
        }
      `,
      variables: {
        createLawFirmInput: lawFirm
      }
    })

    dispatch('loadAllLawFirms')
    return response.data.createLawFirm.id
  },

  async updateLawFirm ({ commit, getters, state }, lawFirmValue) {
    delete lawFirmValue.deletable
    const response = await apollo(state).mutate({
      mutation: gql`
        mutation updateLawFirm($updateLawFirmInput: UpdateLawFirmInput!) {
          updateLawFirm (updateLawFirmInput: $updateLawFirmInput) {
            ${lawFirm}
          }
        }
      `,
      variables: {
        updateLawFirmInput: lawFirmValue
      }

    })

    commit('updateLawFirm', response.data.updateLawFirm)
  },

  async deleteLawFirm ({ dispatch, getters, state }, lawFirmId) {
    await apollo(state).mutate({
      mutation: gql`
        mutation deleteLawFirm ($id: ID!) {
          deleteLawFirm (id: $id)
        }
      `,
      variables: {
        id: lawFirmId
      }
    })

    dispatch('loadAllLawFirms')
  },

  async toggleLawFirmActiveStatus ({ dispatch }, lawFirm) {
    lawFirm.active = !lawFirm.active
    await dispatch('updateLawFirm', lawFirm)
  },

  async uploadLawFirmsFromCSV ({ state, commit }, { file }) {
    const data = new FormData()
    data.append('file', file)
    const request = {
      method: 'POST',
      body: data,
      enctype: 'multipart/form-data',
      headers: { 'x-auth-token': state.authToken }
    }
    let url = '/rest/lawfirm/import'
    const response = await fetch(url, request)
    if (!response.ok) {
      const text = await response.text()
      commit('setSpecialErrorMessage', text)
      commit('setShowDefaultError', true)
      throw new Error()
    }
  },

  async loadTopRatedLawFirms ({ commit, state }, { mandateType }) {
    const response = await apollo(state).query({
      query: gql`
        query ratings($mandateType : String){
          ratings(mandateType : $mandateType) {
            ${rating}
          },
        }
      `,
      variables: {
        mandateType
      }
    })
    return response.data.ratings
  },

  async loadLawFirmsPaginated ({ state, commit }, { queryParams }) {
    const response = await apollo(state).query({
      query: gql`
        query paginatedLawFirms($queryParams : String){
          paginatedLawFirms(queryParams : $queryParams) {
            items {
              ${lawFirm}
            }
            currentPage
            totalPages
          },
        }
      `,
      variables: {
        queryParams: queryParams
      }
    })
    // commit('setAllLawFirms', response.data.paginatedLawFirms.items)
    return response.data.paginatedLawFirms
  }
}
