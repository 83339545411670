import { RfpProgress } from '../../util/constants'
import moment from 'moment'

export default {

  RfpProgress () {
    return RfpProgress
  },

  // getter that returns the Label for the rfp panel with the description of the rfp status
  rfpLabelsStatus: (state, getters) => (rfp) => {
    const status = rfp.status
    const auctionType = rfp.auctionType
    const currentLawFirmRelation = rfp.lawFirmRelations[0]

    if (getters.userIsLawFirm && currentLawFirmRelation.status !== 'ACTIVE' && currentLawFirmRelation.status !== 'COMPLETED_WON') {
      return {
        stage: 'general.rfp.lawFirmSpecificStatus.' + currentLawFirmRelation.status,
        end: '-',
        next: '-'
      }
    }

    if (status === 'ACTIVE_QA') {
      return {
        stage: 'general.rfp.status.ACTIVE_QA',
        end: getters.formatDateTime(rfp.qaEnd),
        next: 'ACTIVE_FIRST_BIDS.' + auctionType
      }
    } else if (status === 'ACTIVE_FIRST_BIDS') {
      return {
        stage: 'general.rfp.status.ACTIVE_FIRST_BIDS.' + auctionType,
        end: getters.formatDateTime(auctionType === 'E_AUCTION' || auctionType === 'ADVANCED' ? rfp.firstBidDeadline : rfp.auctionEnd),
        next: auctionType === 'SIMPLE' ? 'ACTIVE_DECISION_PENDING' : 'ACTIVE_FIRST_BIDS_CLOSED'
      }
    } else if (status === 'ACTIVE_FIRST_BIDS_CLOSED') {
      return {
        stage: 'general.rfp.status.ACTIVE_FIRST_BIDS_CLOSED',
        end: getters.formatDateTime(auctionType === 'E_AUCTION' ? rfp.auctionStart : rfp.auctionEnd),
        next: auctionType === 'SIMPLE' ? 'ACTIVE_DECISION_PENDING' : 'ACTIVE_SECOND_BIDS_OR_AUCTION.' + auctionType
      }
    } else if (status === 'ACTIVE_SECOND_BIDS_OR_AUCTION') {
      return {
        stage: 'general.rfp.status.ACTIVE_SECOND_BIDS_OR_AUCTION.' + auctionType,
        end: getters.formatDateTime(rfp.auctionEnd),
        next: 'ACTIVE_DECISION_PENDING'
      }
    } else if (status === 'ACTIVE_DECISION_PENDING') {
      return {
        stage: 'general.rfp.status.ACTIVE_DECISION_PENDING',
        end: '-',
        next: 'COMPLETED_MATTER_IN_PROGRESS'
      }
    } else if (status === 'COMPLETED_MATTER_IN_PROGRESS') {
      return {
        stage: 'general.rfp.status.COMPLETED_MATTER_IN_PROGRESS',
        end: '-',
        next: getters.userIsCorporate ? 'COMPLETED_FEEDBACK_REQUIRED' : 'COMPLETED_MATTER_CLOSED'
      }
    } else if (status === 'COMPLETED_FEEDBACK_REQUIRED') {
      return {
        stage: getters.userIsCorporate ? 'general.rfp.status.COMPLETED_FEEDBACK_REQUIRED' : 'general.rfp.status.COMPLETED_MATTER_CLOSED',
        end: '-',
        next: getters.userIsCorporate ? 'COMPLETED_MATTER_CLOSED' : '-'
      }
    } else if (status === 'COMPLETED_MATTER_CLOSED') {
      return {
        stage: 'general.rfp.status.COMPLETED_MATTER_CLOSED',
        end: '-',
        next: '-'
      }
    } else if (status === 'COMPLETED_ABORTED') {
      return {
        stage: 'general.rfp.status.COMPLETED_ABORTED',
        end: '-',
        next: '-'
      }
    } else if (status === 'COMPLETED_ABORTED_BY_CORPORATE') {
      return {
        stage: 'general.rfp.status.COMPLETED_ABORTED_BY_CORPORATE',
        end: '-',
        next: '-'
      }
    } else {
      return {
        stage: '',
        end: '',
        next: ''
      }
    }
  },

  // Getter for setting defining the tab for opening according status
  loadRfpStatusTab: (state, getters) => (rfp) => {
    const status = rfp.status
    const lawFirmRelation = rfp.lawFirmRelations[0]

    if (getters.userIsLawFirm && lawFirmRelation.status !== 'ACTIVE' && lawFirmRelation.status !== 'COMPLETED_WON') {
      return 'summary'
    }

    if (status === 'ACTIVE_QA') {
      return 'qa'
    } else if (status === 'ACTIVE_FIRST_BIDS') {
      if (getters.userIsLawFirm && rfp.terms.qualityScoreCriteria.length > 0 && !getters.proposalIsClosed(rfp)) {
        return lawFirmRelation.coi2Confirmed ? 'proposal' : 'summary'
      } else {
        return !getters.corporateQandAFinished(rfp) && rfp.allowQA ? 'qa' : 'bidding'
      }
    } else if (['ACTIVE_FIRST_BIDS_CLOSED', 'ACTIVE_SECOND_BIDS_OR_AUCTION'].includes(status)) {
      return (getters.userIsLawFirm && lawFirmRelation.status === 'COMPLETED_MISSED_FIRST_BID_DEADLINE') ? 'summary' : 'bidding'
    } else if (status === 'ACTIVE_DECISION_PENDING') {
      const canTakeDecision = (!getters.userIsAdmin && rfp.terms.qualityScoreCriteria.length > 0) ? rfp.lawFirmRelations.some(lawFirmRelation => lawFirmRelation.proposalSubmitted) : true

      return (getters.userIsCorporate && canTakeDecision) ? 'decision' : 'summary'
    } else if (status === 'COMPLETED_FEEDBACK_REQUIRED') {
      return getters.userIsCorporate ? 'feedback' : 'summary'
    } else {
      return 'summary'
    }
  },

  auctionProgress: (state, getters) => (rfp) => {
    const status = rfp.status
    const lawFirmRelation = rfp.lawFirmRelations[0]

    if (getters.userIsLawFirm && lawFirmRelation.status !== 'ACTIVE' && lawFirmRelation.status !== 'COMPLETED_WON') {
      return 4
    }

    if (status === 'COMPLETED_ABORTED_BY_CORPORATE') {
      return 7
    }

    return RfpProgress[status]
  },

  progressPreAbortion: () => (rfp) => {
    const status = rfp.statusPreAbortion
    return RfpProgress[status]
  },

  auctionNextActionLabel: (state, getters) => (rfp) => {
    const status = rfp.status
    const auctionType = rfp.auctionType
    const lawFirmRelation = rfp.lawFirmRelations[0]

    if (getters.userIsLawFirm && lawFirmRelation.status !== 'ACTIVE' && lawFirmRelation.status !== 'COMPLETED_WON') {
      return ''
    }

    if (status === 'ACTIVE_QA') {
      return 'general.rfp.status.ACTIVE_FIRST_BIDS.' + auctionType
    } else if (status === 'ACTIVE_FIRST_BIDS') {
      return 'activeRFP.biddingProcessSection.nextActions.' + (auctionType === 'SIMPLE' ? 'biddingEnds' : 'initialBiddingEnds')
    } else if (status === 'ACTIVE_FIRST_BIDS_CLOSED') {
      return 'activeRFP.biddingProcessSection.nextActions.eAuctionStarts'
    } else if (status === 'ACTIVE_SECOND_BIDS_OR_AUCTION') {
      return getters.isEAuction
        ? 'activeRFP.biddingProcessSection.nextActions.eAuctionEnds'
        : 'activeRFP.biddingProcessSection.nextActions.finalBiddingEnds'
    } else {
      return ''
    }
  },

  decisionIsMade: (state, getters) => (rfp) => {
    return RfpProgress[rfp.status] > RfpProgress['ACTIVE_DECISION_PENDING']
  },

  auctionIsInProgress: (state, getters) => (rfp) => {
    const progress = getters.auctionProgress(rfp)
    return progress === RfpProgress.ACTIVE_SECOND_BIDS_OR_AUCTION ? getters.isEAuction : false
  },

  auctionIsClosed: (state, getters) => (rfp) => {
    const progress = getters.auctionProgress(rfp)
    return progress > RfpProgress.ACTIVE_SECOND_BIDS_OR_AUCTION
  },

  proposalIsClosed: (state, getters) => (rfp) => {
    const progress = getters.auctionProgress(rfp || state.currentRfp)
    return progress >= RfpProgress.ACTIVE_FIRST_BIDS_CLOSED
  },

  isInitialBidding: (state, getters) => (rfp) => {
    const progress = getters.auctionProgress(rfp)
    return progress === RfpProgress.ACTIVE_FIRST_BIDS
  },

  isSecondBidding: (state, getters) => (rfp) => {
    const progress = getters.auctionProgress(rfp)
    return progress === RfpProgress.ACTIVE_SECOND_BIDS_OR_AUCTION
  },

  initialBiddingClosed: (state, getters) => (rfp) => {
    const progress = getters.auctionProgress(rfp)
    return progress === RfpProgress.ACTIVE_FIRST_BIDS_CLOSED
  },

  isQandA: (state, getters) => (rfp) => {
    const progress = getters.auctionProgress(rfp)
    return progress === RfpProgress.ACTIVE_QA
  },

  corporateQandAFinished: () => (rfp) => {
    const deadline = rfp.auctionType === 'SIMPLE' ? rfp.auctionEnd : rfp.firstBidDeadline
    const deadlineMoment = moment.utc(deadline).subtract(3, 'hours')
    const now = moment()
    now.milliseconds(0)
    now.seconds(0)

    return now.isSameOrAfter(deadlineMoment)
  }
}
