<template>
  <div class="summary-panel">
    <v-card class="grid">
      <h3>{{ "activeRFP.summarySection.biddingType" | t }}</h3>
      <span class="strong">{{ currentRfp.auctionType && `dropDownOptions.AuctionType.${currentRfp.auctionType}` | t }}</span>

      <h3>
        {{ "activeRFP.summarySection.rulesLabel" | t }}
        <ChangeIcon v-if="biddingRulesChanged" category></ChangeIcon>
      </h3>
      <section ref="rules">

        <div style="margin-bottom: 20px" v-if="qaAllowed">
          <p class="subtitle">{{ "activeRFP.linkTabs.Q&A" | t }}</p>
          <span class="strong" style="margin-bottom: 10px">{{ "activeRFP.summarySection.biddingDetails.allowQ&AText" | t }}</span>
        </div>

        <p class="subtitle">{{ "activeRFP.summarySection.biddingDetails.auctionTimeline" | t }}</p>
        <div>
          <div class="time-grid" v-if="qaAllowed">
              <span class="light">{{ 'newRFP.biddingType.qaEnd' | t }}</span>

              <span class="strong" style="grid-column-start: 1;">
                {{ formatDateTime(currentRfp.qaEnd) }}
              </span>
          </div>

          <div class="time-grid" v-if="isAdvancedRfp || isEAuction">
            <div>
              <span class="light">{{ 'newRFP.biddingType.initialBidTitle' | t }}&nbsp;</span>
              <span class="light">({{ $t('newRFP.biddingType.proposalSubtitle', {first: 'First'}) }})</span>
            </div>
            <span class="strong" style="grid-column-start: 1;">
              {{ formatDateTime(currentRfp.firstBidDeadline) }}
            </span>
          </div>

          <div class="time-grid">
            <template v-if="isEAuction">
              <span class="light">{{ 'newRFP.biddingType.eauctionTitle' | t }}</span>
            </template>
            <div v-if="isSimpleRfp">
              <span class="light">{{ 'newRFP.biddingType.initialBidTitle' | t }}&nbsp;</span>
              <span class="light">({{ $t('newRFP.biddingType.proposalSubtitle') }})</span>
            </div>
            <span v-else class="light">{{ isEAuction ? 'newRFP.biddingType.eAuctionEnd' : 'newRFP.biddingType.finalBidTitle' | t }}</span>

            <template v-if="isEAuction">
              <span class="strong">
                {{ formatDateTime(currentRfp.auctionStart) }}
              </span>
            </template>
            <span class="strong" :class="{'auction-type': !isEAuction}">
              {{ formatDateTime(currentRfp.auctionEnd) }}
            </span>
          </div>

        </div>

        <div style="margin-bottom: 20px" v-if="isEAuction">
          <span class="strong">{{ 'newRFP.biddingType.eAuctionScheduleInformation' | t }}</span><br><br>
          <span class="strong">{{ 'newRFP.biddingType.extensionLabel' | t }}</span>
        </div>
        <div style="margin-bottom: 20px" v-if="isAdvancedRfp">
          <span class="strong">{{ 'newRFP.biddingType.advancedRfpScheduleInformation' | t }}</span>
        </div>
      </section>

      <h3>
        {{ "activeRFP.summarySection.biddingDetails.disclosureModeTitle" | t }}
        <ChangeIcon v-if="biddingDisclosureChanged"></ChangeIcon>
      </h3>
      <div>
        <p class="strong">{{ `dropDownOptions.DisclosureMode.${currentRfp.disclosureMode}` | t }}</p>
        <span class="strong">{{ `newRFP.biddingType.disclosureDescription${ isEAuction ? 'EAuction' : (isAdvancedRfp ? 'AdvancedRFP' : 'SimpleRFP') }` | t }}</span>
      </div>

      <template v-if="interviewSelected(currentRfp) || (currentRfp.nextSteps !== null && currentRfp.nextSteps.length > 0)">
        <h3>
          {{ 'activeRFP.summarySection.biddingDetails.nextStepsTitle' | t }}
          <ChangeIcon v-if="interviewQualityScoreCriteriaChanged"></ChangeIcon>
        </h3>
        <div>
          <span v-if="currentRfp.nextSteps !== null && currentRfp.nextSteps.length > 0" class="strong">{{ currentRfp.nextSteps }}</span>
          <br v-if="currentRfp.nextSteps !== null && currentRfp.nextSteps.length > 0 && interviewSelected(currentRfp)">
          <br v-if="currentRfp.nextSteps !== null && currentRfp.nextSteps.length > 0 && interviewSelected(currentRfp)">
          <span v-if="interviewSelected(currentRfp)" class="strong">{{ $t('activeRFP.summarySection.interviews') }}</span>
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import splitDateTime from '../../../../util/splitDateTime'
import ChangeIcon from '@/views/generalComponents/ChangeIcon'

export default {
  name: 'BiddingTypePanel',
  components: { ChangeIcon },
  props: {
    currentRfp: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'interviewSelected',
      'biddingNextStepsChanged',
      'biddingRulesChanged',
      'biddingDisclosureChanged',
      'isEAuction',
      'isAdvancedRfp',
      'formatDateTime',
      'userIsCorporate',
      'isSimpleRfp',
      'interviewQualityScoreCriteriaChanged'
    ]),

    qaAllowed () {
      return this.currentRfp.allowQA
    }
  },
  methods: {
    splitDateTime,

    biddingText (biddingRules) {
      if (biddingRules === 'STRICT') {
        return this.$t('newRFP.biddingType.strictTypeInfo' + (this.isAdvancedRfp ? 'Advanced' : 'EAuction'))
      } else {
        return this.$t('newRFP.biddingType.looseTypeInfo' + (this.isAdvancedRfp ? 'Advanced' : 'EAuction'))
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  .tooltipSettings >>> .v-icon
    color $icon-gray !important
    font-size 15px !important

  .icon
    .v-icon
      color $gold100 !important
      font-size 20px

  .name
    font-size 18px
    color $dark-gray
    text-align left
    font-weight bold

  .time-grid
    display grid
    grid-template-columns repeat(2, auto)
    margin-bottom 16px

  .light
    color $icon-gray

  .strong
    color $dark-gray
    font-size 16px
    font-weight bold

  .qa-grid
    display grid
    grid-template-columns 1fr
    grid-row-gap 5px

  .auction-type
    grid-column-start 1

  .subtitle
    font-size 14px !important
    font-weight bold
    color $icon-gray
    margin-bottom 5px
</style>
