import Vue from 'vue'
import shortId from 'shortid'

export default {

  updateScopeField (state, { subjectKey, field, value }) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    if (!currentRfp.scope[subjectKey]) {
      Vue.set(currentRfp.scope, subjectKey, {})
    }

    Vue.set(currentRfp.scope[subjectKey], field, value)
  },

  addScopeListItem (state, { subjectKey, panelKey }) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    if (!currentRfp.scope[subjectKey]) {
      Vue.set(currentRfp.scope, subjectKey, {})
    }

    if (!currentRfp.scope[subjectKey][panelKey]) {
      Vue.set(currentRfp.scope[subjectKey], panelKey, [])
    }

    currentRfp.scope[subjectKey][panelKey].push({
      id: shortId.generate()
    })
  },

  removeScopeListItem (state, { subjectKey, panelKey, listItemId }) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    const index = currentRfp.scope[subjectKey][panelKey].findIndex(item => item.id === listItemId)

    if (index > -1) {
      Vue.delete(currentRfp.scope[subjectKey][panelKey], index)
    }
  },

  updateScopeListItemField (state, { subjectKey, panelKey, listItemId, field, value }) {
    const currentRfp = state.currentRfp.status === 'DRAFT' ? state.currentRfp : state.rfpDraft
    const index = currentRfp.scope[subjectKey][panelKey].findIndex(item => item.id === listItemId)

    if (index > -1) {
      const listItem = currentRfp.scope[subjectKey][panelKey][index]
      listItem[field] = value
      Vue.set(currentRfp.scope[subjectKey][panelKey], index, listItem)
    }
  }

}
