<template>
  <DynamicInputPage
    :root="currentRfpOrDraft.scope"
    :definitions="currentRfpOrDraft.scopeGeneratorLayoutsBySubject"
    :iconMappings="iconMappings"
    :subjects="currentRfpOrDraft.subjects"
    update-mutation="updateScopeField"
    update-list-item-mutation="updateScopeListItemField"
    add-list-item-mutation="addScopeListItem" />
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DynamicInputPage from '../../generalComponents/inputWrapper/DynamicInputPage'

export default {
  name: 'ScopeGenerator',
  components: { DynamicInputPage },
  data () {
    return {
      iconMappings: {
        'RELEVANT': 'ic-document',
        'REQUESTED_SERVICES': 'ic-layers',
        'GOVERNING_LAW': 'ic-gavel',
        'AREAS_OF_LAW': 'layers',
        'DOCUMENTS': 'ic-attach',
        'TIMELINE': 'ic-calendar',
        'LANGUAGE': 'language'
      }
    }
  },
  computed: {
    ...mapGetters(['currentRfpOrDraft']),
    ...mapState({
      currentRfp () {
        return this.currentRfpOrDraft
      }
    })
  }
}
</script>

<style scoped lang="stylus">

</style>
