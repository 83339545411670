import Vue from 'vue'

export default {

  setAlert (state, alert) {
    Vue.set(state, 'alert', alert)
  },

  setAlertVisible (state, alertVisible) {
    Vue.set(state.alert, 'visible', alertVisible)
  }

}
