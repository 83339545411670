<template>
  <div>
    <div style="display: grid; grid-template-columns: max-content 1fr; grid-column-gap: 10px">
      <h2 ref="title">{{ 'activeRFP.proposal.pitchDocument' | t }}</h2>
      <v-rating
        v-if="category && category.rating"
        small
        color="orange"
        class="raitings"
        background-color="orange lighten-3"
        readonly
        :value="category.rating"
        half-increments hover />
    </div>
    <p
      v-if="requestedContent || definitionDocuments.length"
      style="max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%; margin-top: -10px; margin-bottom: 20px; ">{{ 'activeRFP.proposal.requestedInformationPitchDocument' | t }} {{ requestedContent }}
      <br v-if="requestedContent">
      <FileChip
        v-for="file in definitionDocuments"
        :key="file.id"
        :file="file" />
    </p>
    <p
        v-if="category && category.ratingComment"
        style="margin-top: -10px; margin-bottom: 20px; grid-column: 1 / 3; max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%">
      Feedback from the client: {{ category.ratingComment }}</p>

    <v-card :class="{missing: missingMandatoryFields.includes('PITCH_DOCUMENT')}">
      <DocumentUpload
        type="PROPOSAL_CATEGORY"
        qualityScoreCriterium="PITCH_DOCUMENT"
        :deleteIcon="proposalIsEditable && updateMode"
        :documents="pitchDocuments"
        @deleteFile="file => removePitchDocument(file)"
        :hide-upload="!(proposalIsEditable && updateMode)" />
    </v-card>
  </div>
</template>

<script>
import DocumentUpload from '../../generalComponents/DocumentUpload'
import FileChip from '../../generalComponents/FileChip'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'ProposalPitchDocument',
  components: { DocumentUpload, FileChip },
  computed: {
    ...mapGetters(['currentRfpLawFirmRelation', 'proposalIsEditable']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      currentRfp: state => state.currentRfp,
      updateMode: state => state.draftProposal.updateMode
    }),

    category () {
      return this.currentRfpLawFirmRelation.proposal ? (this.currentRfpLawFirmRelation.proposal.find(category => (this.updateMode ? category.draft : !category.draft) && category.name === 'PITCH_DOCUMENT') || this.currentRfpLawFirmRelation.proposal.find(category => category.name === 'PITCH_DOCUMENT')) : null
    },

    pitchDocuments () {
      return this.category ? this.category.attachments : []
    },

    requestedContent () {
      return this.currentRfp.terms.requestedPitchDocumentContent
    },

    definitionDocuments () {
      return this.currentRfp.attachments.filter(document => document.rfpFileType === 'PITCH_DOCUMENT_DEFINITION')
    }
  },
  methods: {
    ...mapActions(['removePitchDocument'])
  }
}
</script>

<style scoped>

</style>
