<template>
  <div class="main">
    <section>
      <v-card class="grid">
        <h2>{{isWinner? $t('activeRFP.decision.lawfirmWonTitle') : $t('activeRFP.decision.lawfirmLostTitle')}}</h2>
        <p v-html="message" class="show-whitespace">
        </p>
      </v-card>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'DecisionLawfirm',
  computed: {
    ...mapState({
      currentRfp: state => state.currentRfp,
      userData: state => state.userData
    }),
    isWinner () {
      return this.currentRfp.lawFirmRelations[0].status === 'COMPLETED_WON'
    },
    message () {
      if (this.isWinner) {
        return this.currentRfp.winnerMessage || this.$t('activeRFP.decision.lawfirmWonMessageDefault')
      } else {
        return this.currentRfp.loserMessage || this.$t('activeRFP.decision.lawfirmLostMessageDefault')
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  div.main
    padding: 30px 50px
    section .v-card
      border-radius 10px
      padding:26px
      h2 {
        margin-bottom: 20px
      }
</style>
