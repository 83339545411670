<template>
  <div>
    <span class="light" :class="{ light: readonly }">{{ translatedName }}</span>
    <v-radio-group
      v-if="!readonly"
      style="margin-top: 0px;"
      :value="value"
      @change="value => $emit('input', value)">
      <v-radio
        v-for="option in sortedOptions"
        :key="option.value"
        :label="option.translatedLabel"
        :value="option.key"
        :disabled="disabled"
      ></v-radio>
    </v-radio-group>
    <p v-else>{{ readonlyValue }}</p>
  </div>
</template>

<script>
import InputFieldMixin from './InputFieldMixin'

export default {
  name: 'RadioField',
  mixins: [InputFieldMixin],
  computed: {
    readonlyValue () {
      const option = this.options.find(option => option.key === this.value)
      return option ? option.translatedLabel : ''
    }
  }
}
</script>

<style scoped lang="stylus">

</style>
