<template>
  <div>
    <span class="light" :class="{ mandatory: mandatory && !readonly, light: readonly }">{{ translatedName }}</span>
    <v-select
      v-if="!readonly"
      label=""
      :value="value"
      @input="value => $emit('input', value)"
      :items="sortedOptions"
      :multiple="type === 'MULTI_SELECT'"
      :disabled="disabled"

      :class="{ invalid }"

      item-text="translatedLabel"
      item-value="key"
      solo
      flat
    ></v-select>
    <p v-else>{{ readonlyValue }}</p>
  </div>
</template>

<script>
import InputFieldMixin from './InputFieldMixin'

export default {
  name: 'SelectField',
  mixins: [InputFieldMixin],
  computed: {
    readonlyValue () {
      if (this.type === 'MULTI_SELECT') {
        return this.sortedOptions.filter(option => this.value.includes(option.key)).map(option => option.translatedLabel).join(', ')
      } else {
        const option = this.options.find(option => this.value === option.key)
        return option ? option.translatedLabel : ''
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  span:not(.light)
    padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-text-field__details
    display none

  >>> .v-input--checkbox .v-input__slot
    border none !important

  >>> .v-input--is-disabled .v-input__slot
    background $light-gray !important

  >>> .v-icon
    color $dark-gray !important
    font-size 20px !important
    width 24px
</style>
