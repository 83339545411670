import router from '../../router'
import debounce from 'lodash.debounce'
// import fetch from 'unfetch'

export default {

  async login ({ state, commit, dispatch }, { email, password }) {
    // try {
    let request = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(`${email.toLowerCase()}:${password}`)
      },
      // body: JSON.stringify({ email, password }),
      method: 'POST'
    }

    const response = await fetch((process.env.JEST_WORKER_ID ? (process.env.JENKINS_RUN ? 'http://localhost:9090' : 'http://localhost:8080') : '') + '/rest/login', request)

    if (response.status === 200) {
      const json = await response.json()
      commit('setAuthToken', json.session)
      commit('setMfaMethod', json.mfaMethod)

      if (!json.requiresMfaVerification) {
        await dispatch('loadDataAfterLogin', json)
      }
      return { success: true, requiresMfaVerification: json.requiresMfaVerification }
    } else {
      return { success: false }
    }
  },

  /**
   * Simply keeps the current session alive
   */
  async keepLoginAlive ({ state, commit }) {
    if (state.authToken) {
      console.log('keeping login session alive')
      let request = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': state.authToken
        },
        method: 'POST'
      }
      const response = await fetch((process.env.JEST_WORKER_ID ? (process.env.JENKINS_RUN ? 'http://localhost:9090' : 'http://localhost:8080') : '') + '/rest/keep-alive', request)
      if (response.status === 401) {
        console.log('session expired')
        commit('setAuthToken', null) // delete auth token as it is invalid
      }
      commit('resetSessionExpiry')
    } else {
      console.log('not logged in yet, skipping keep-alive')
    }
  },

  async verifyMfa ({ state, commit, dispatch }, { code, tempMfa = false }) {
    let request = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': state.authToken
      },
      body: JSON.stringify({ code: code }),
      method: 'POST'
    }

    const response = await fetch((process.env.JEST_WORKER_ID ? (process.env.JENKINS_RUN ? 'http://localhost:9090' : 'http://localhost:8080') : '') + `/rest/login/verify-mfa?verifyTempMfa=${tempMfa}`, request)

    if (response.status === 200) {
      const json = await response.json()
      await dispatch('loadDataAfterLogin', json)
      return true
    }
    return false
  },

  async loadDataAfterLogin ({ state, commit, dispatch }, json) {
    const userIsAdmin = json.userDetails.authorities.some(auth => auth.authority === 'ROLE_LINTUM_ADMIN')

    commit('setUserData', json.userDetails)
    dispatch('loadOptionLists', userIsAdmin)

    if (!userIsAdmin) {
      dispatch('loadAllSubjects')
    }

    dispatch('loadAllUsers')
    dispatch('subscribeToNotifications')
    await dispatch('loadAllLawFirms')
    // state.callbacks.afterUserDataLoaded.forEach(callback => callback())
    // commit('resetCallbacks', 'afterUserDataLoaded')

    const opener = parent.window.opener
    console.log('opener origin', parent.window.opener)
    console.log('router.currentRoute.query', router.currentRoute.query)
    if (opener && router.currentRoute.query.sessionExpired && [
      'https://app-test.lintum.com',
      'https://app.lintum.com',
      'https://demo.lintum.com',
      'https://lintum-dev.azurewebsites.net',
      'https://lintum-dev-de.azurewebsites.net',
      'http://localhost:8081',
      'http://localhost:8082'].includes(opener.origin)) {
      window.opener.postMessage('LIN_LOGIN_RESTORED', '*')
      window.close()
    }
  },

  async loadUserData ({ commit, state, dispatch }) {
    const fetchResponse = await fetch('/rest/current-user', { headers: { 'x-auth-token': state.authToken } })
    if (fetchResponse.status === 200) {
      let response = await fetchResponse.json()
      if (!response.userData.mfaVerified) {
        commit('setMfaMethod', response.mfaMethod)
        return { mfaVerified: false }
      }
      const { userData } = response
      const userIsAdmin = userData.authorities.some(auth => auth.authority === 'ROLE_LINTUM_ADMIN')

      commit('setUserData', userData)

      dispatch('loadAllLawFirms')
      dispatch('loadOptionLists', userIsAdmin)

      if (!userIsAdmin) {
        dispatch('loadAllSubjects')
      }

      dispatch('loadAllUsers')
      dispatch('subscribeToNotifications')

      // state.callbacks.afterUserDataLoaded.forEach(callback => callback())
      // commit('resetCallbacks', 'afterUserDataLoaded')
    } else {
      dispatch('restoreTokenFromCookie')
    }

    return { mfaVerified: true }
  },

  async restoreTokenFromCookie ({ commit, dispatch }, handleError = true) {
    const response = await fetch('/rest/restore-token-from-cookie')
    if (response.status === 200) {
      const json = await response.json()
      commit('setAuthToken', json.session)
      const { mfaVerified } = await dispatch('loadUserData')
      if (!mfaVerified) {
        const query = window.location.pathname !== '/' ? {
          nextRoute: window.location.pathname + window.location.search
        } : {}
        query.mfa = true
        router.push({
          name: 'login',
          query: query
        })
        return
      }
      dispatch('loadOptionLists', false)
    } else if (handleError) {
      console.log('trying to push route to login, current route: ', window.location.pathname + window.location.search)
      const query = window.location.pathname !== '/' ? {
        nextRoute: window.location.pathname + window.location.search
      } : {}
      router.push({
        name: 'login',
        query: query
      })
    }
  },

  async logout ({ commit, state, dispatch }) {
    await fetch((process.env.JEST_WORKER_ID ? 'http://localhost:8080' : '') + '/rest/logout', { method: 'POST', headers: { 'x-auth-token': state.authToken } })

    dispatch('unSubscribeToNotifications')
    dispatch('unsubscribeStatusChanges')
    dispatch('unsubscribeAuction')

    commit('setNoWebsocketSupportError', false)
    commit('resetState')
    router.push({
      name: 'login'
    })
  }
}
