import Vue from 'vue'

export default {

  setAllLawFirms (state, lawFirms) {
    Vue.set(state, 'lawFirms', lawFirms)
  },

  updateLawFirm (state, lawFirm) {
    const index = state.lawFirms.findIndex(_lawFirm => _lawFirm.id === lawFirm.id)

    if (index > -1) {
      Vue.set(state.lawFirms, index, lawFirm)
    } else {
      const newValue = [...state.lawFirms]
      newValue.push(lawFirm)
      Vue.set(state, 'lawFirms', newValue)
    }
  }

}
