<template>
  <v-dialog
    :value="showDefaultError"
    @input="setShowDefaultError(false); setSpecialErrorMessage(null)"
    width="700"
  >
    <v-card>
      <v-card-title
        class="headline"
        primary-title
      >
        {{ 'error.title' | t }}

      </v-card-title>

      <v-divider></v-divider>

      <v-card-text >
        <p v-if="specialErrorMessage">"{{specialErrorMessage}}"</p>
        <p v-html="$t('error.popUpText')"></p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat class="main" @click="setShowDefaultError(false); setSpecialErrorMessage(null)">
          {{ 'buttons.ok' | t }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'ErrorDialog',
  computed: {
    ...mapState({
      showDefaultError: state => state.error.showDefaultError,
      specialErrorMessage: state => state.error.specialErrorMessage
    })
  },
  methods: {
    ...mapMutations(['setShowDefaultError', 'setSpecialErrorMessage'])
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  .v-card
    border-radius 10px

  .v-card__text
    span
      color rgb(158, 158, 158)
      padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input--checkbox .v-input__slot
    border none !important

  >>> a {
    color: $gray70
  }
</style>
