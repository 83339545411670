<template>
  <div v-if="currentSubject">
    <h1>
      <v-btn style="text-transform: capitalize;" flat @click="leaving()"><v-icon>ic-chevron-left</v-icon> {{ currentSubject.translatedLabel }}</v-btn>
    </h1>
    <SectionDefinition @subjectChange="changeToNotSaved()" v-for="section in currentSubject.scopeGeneratorDraftLayout.sections" :key="section.key" :section="section" />
    <v-footer class="elevation-15" fixed>
      <div class="footer-content">
        <div class="btn-wrapper" style="grid-column-start: 2;">
          <v-btn flat @click="showResetDialog = true">
            <span>{{ 'scopeGeneratorAdminView.layout.reset' | t }} </span>
          </v-btn>
        </div>
        <div class="btn-wrapper">
          <v-btn flat @click="showActivateDialog = true">
            <span>{{ `scopeGeneratorAdminView.layout.${currentSubject.active ? 'deactivate' : 'activate'}` | t }} </span>
          </v-btn>
        </div>
        <div class="btn-wrapper">
          <v-btn flat @click="saveSubject">
            <span>{{ 'scopeGeneratorAdminView.layout.save' | t }} </span>
          </v-btn>
        </div>
        <div class="btn-wrapper">
          <v-btn flat @click="previewSubject">
            <span>{{ 'scopeGeneratorAdminView.layout.preview' | t }} </span>
          </v-btn>
        </div>
        <div class="btn-wrapper">
          <v-btn flat @click="showPublishDialog = true; activateSubjectOnPublish = false">
            <span>{{ 'scopeGeneratorAdminView.layout.publish' | t }} </span>
          </v-btn>
        </div>
      </div>
    </v-footer>
    <ConfirmDialog
      v-if="showLeavingDialog"
      :title="$t('scopeGeneratorAdminView.leaveConfirmTitle')"
      :text="$t('scopeGeneratorAdminView.leaveConfirmText')"
      :acceptButtonTextOrKey="$t('scopeGeneratorAdminView.leaveButton')"
      @close="showLeavingDialog = false"
      @submit="$router.push({ name: 'scopeGeneratorAdminView' })" />
    <ConfirmDialog
      v-if="showActivateDialog"
      :title="$t(`scopeGeneratorAdminView.layout.${currentSubject.active ? 'deactivateDialogTitle' : 'activateDialogTitle'}`, { subjectName: currentSubject.translatedLabel })"
      :text="$t(`scopeGeneratorAdminView.layout.${currentSubject.active ? 'deactivateDialogText' : 'activateDialogText'}`,  { subjectName: currentSubject.translatedLabel })"
      :acceptButtonTextOrKey="$t(`scopeGeneratorAdminView.layout.${currentSubject.active ? 'deactivate' : 'activate'}`,  { subjectName: currentSubject.translatedLabel })"
      @close="showActivateDialog = false"
      @submit="toggleActive" />
    <ConfirmDialog
      v-if="showResetDialog"
      :title="$t('scopeGeneratorAdminView.layout.resetDialogTitle')"
      :text="$t('scopeGeneratorAdminView.layout.resetDialogText', { subjectName: currentSubject.translatedLabel })"
      :acceptButtonTextOrKey="$t('scopeGeneratorAdminView.layout.resetButton')"
      :buttonDisable="isButtonExecuting"
      cancelButtonTextOrKey="buttons.cancel"
      @close="showResetDialog = false"
      @submit="waitButtonToEnd(resetLayout)" />
    <ConfirmDialog
      v-if="showPublishDialog"
      :title="$t('scopeGeneratorAdminView.layout.publishDialogTitle')"
      :text="$t('scopeGeneratorAdminView.layout.publishDialogText', { subjectName: currentSubject.translatedLabel })"
      :acceptButtonTextOrKey="$t('scopeGeneratorAdminView.layout.publishButton')"
      :buttonDisable="isButtonExecuting"
      cancelButtonTextOrKey="buttons.cancel"
      @close="showPublishDialog = false"
      @submit="waitButtonToEnd(publishLayout)">
      <template v-slot:custom-input>
        <v-checkbox
          style="margin-left: -4px;"
          v-if="!currentSubject.active"
          v-model="activateSubjectOnPublish"
          label="Activate the Scope Generator for this subject"
        ></v-checkbox>
      </template>
    </ConfirmDialog>

    <v-snackbar
      v-model="showSuccessMessage">
      <p style="color: white; margin-bottom: 0;">{{ 'scopeGeneratorAdminView.saveSuccess' | t }}</p>
      <v-btn
        icon
        style="margin-top: -18px;"
        @click="showSuccessMessage = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="showResetSuccessMessage">
      <p style="color: white; margin-bottom: 0;">{{ 'scopeGeneratorAdminView.layout.resetSuccess' | t }}</p>
      <v-btn
        icon
        style="margin-top: -18px;"
        @click="showResetSuccessMessage = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="showPublishSuccessMessage">
      <p style="color: white; margin-bottom: 0;">{{ 'scopeGeneratorAdminView.layout.publishSuccess' | t }}</p>
      <v-btn
        icon
        style="margin-top: -18px;"
        @click="showPublishSuccessMessage = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="showActivateSuccessMessage">
      <p style="color: white; margin-bottom: 0;">{{ `scopeGeneratorAdminView.layout.${currentSubject.active ? 'activateSuccess' : 'deactivateSuccess'}` | t }}</p>
      <v-btn
        icon
        style="margin-top: -18px;"
        @click="showActivateSuccessMessage = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import SectionDefinition from './components/SectionDefinition'
import ConfirmDialog from '../generalComponents/ConfirmDialog'

export default {
  name: 'ScopeGeneratorLayoutDefinitionView',
  components: { SectionDefinition, ConfirmDialog },
  props: {
    subjectKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showLeavingDialog: false,
      notSaved: false,
      showSuccessMessage: false,
      showResetDialog: false,
      showPublishDialog: false,
      showResetSuccessMessage: false,
      showPublishSuccessMessage: false,
      showActivateDialog: false,
      activateSubjectOnPublish: false,
      showActivateSuccessMessage: false
    }
  },
  created () {
    this.loadSubjectWithLayout({ subjectKey: this.subjectKey, adminView: true })
  },
  destroy () {
    window.removeEventListener('beforeunload', this.showLeave)
  },
  computed: {
    ...mapState({
      currentSubject: state => state.admin.scopeGenerator.currentSubject
    })
  },
  methods: {
    ...mapActions(['loadSubjectWithLayout', 'saveCurrentSubject', 'resetDraftSubject', 'publishDraftSubject']),
    ...mapMutations(['setCurrentSubject', 'currentSubjectToggleActive']),

    showLeave (event) {
      event.returnValue = `Are you sure you want to leave?`
    },

    async toggleActive () {
      this.showActivateDialog = false
      this.currentSubjectToggleActive()
      this.saveSubject(false)
      this.showActivateSuccessMessage = true
    },

    async resetLayout () {
      await this.resetDraftSubject({ subject: this.currentSubject })
      this.showResetDialog = false
      this.showResetSuccessMessage = true
    },

    async publishLayout () {
      if (this.activateSubjectOnPublish) {
        this.showPublishDialog = false
        this.currentSubjectToggleActive()
      }

      await this.saveSubject(false)
      await this.publishDraftSubject({ subject: this.currentSubject })
      this.showPublishDialog = false
      this.showPublishSuccessMessage = true
    },

    async previewSubject () {
      await this.saveSubject(false)
      this.$router.push({ name: 'scopeGeneratorLayoutPreview' })
    },

    async saveSubject (showSuccessMessage = true) {
      window.removeEventListener('beforeunload', this.showLeave)
      this.notSaved = false
      await this.saveCurrentSubject()
      this.showSuccessMessage = showSuccessMessage
    },

    leaving () {
      if (this.notSaved && !this.showLeavingDialog) {
        this.showLeavingDialog = true
      } else {
        this.$router.push({ name: 'scopeGeneratorAdminView' })
      }
    },

    changeToNotSaved () {
      // console.log('helo')
      this.notSaved = true
      window.addEventListener('beforeunload', this.showLeave)
    }
  },
  beforeRouteLeave (to, from, next) {
    window.removeEventListener('beforeunload', this.showLeave)
    this.showLeavingDialog = false
    this.setCurrentSubject(null)
    next()
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  footer
    background #ffffff !important
    height 74px !important

  .footer-content
    width $max-width
    margin 0 auto
    background #ffffff
    display grid
    height 74px !important
    grid-template-columns 1fr 200px 200px 200px 200px 200px

    .btn-wrapper:not(:first-child)
      border-left 1px solid lightgray

    .v-btn
      height 100%
      margin 0
      width 100%
      font-weight normal !important
      .v-icon,
      .v-icon:before
        color $dark-gray !important

  .v-btn
    height 100%
    margin 0
    border-radius 5px

  h2
    margin-bottom 30px

  h1
    display inline-block

    *,
    *:before
      font-size 28px
      color $dark-gray !important
      padding-left 0

  >>> .v-text-field__details
    display none

  >>> .v-icon,
  >>> .v-icon:before,
  >>> .v-label
    color $dark-gray !important
</style>
