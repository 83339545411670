<template>
  <div>
    <div style="display: grid; grid-template-columns: max-content 1fr; grid-column-gap: 10px">
      <h2 ref="title">{{ title }}</h2>
      <v-rating
        v-if="nonDraftItem && nonDraftItem.rating"
        small
        color="orange"
        class="raitings"
        background-color="orange lighten-3"
        readonly
        :value="nonDraftItem.rating"
        half-increments hover />
    </div>
    <p
      v-if="requestedContent"
      style="max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%; margin-top: -10px; margin-bottom: 20px; ">{{ 'activeRFP.proposal.requestedInformationLiability' | t }} {{ requestedContent }}</p>

    <p
      v-if="nonDraftItem && nonDraftItem.ratingComment"
      style="margin-top: -10px; margin-bottom: 20px; grid-column: 1 / 3; max-width: 100%; word-break: break-word; line-break: anywhere; width: 100%">
      Feedback from the client: {{ nonDraftItem.ratingComment }}</p>

    <v-card class="grid" :class="{missingcard: missingMandatoryFields.includes(qualityScoreCriterium)}">

    <template v-if="(editLiability || newLiability) && updateMode">
      <div style="grid-column-start: 1; grid-column-end: 3;">
        <span class="light mandatory">{{ title }}</span>
        <v-textarea
          label=""
          :disabled="!proposalIsEditable"
          solo flat
          :class="{missing: missingFields.explanation}"
          @input="missingFields.explanation = false; checkProposalUnsavedChanges()"
          v-model="liability.extendedFields.explanation"></v-textarea>
      </div>

      <div class="actions">
        <v-btn flat v-if="proposalIsEditable && !isNewLiability" @click="cancel">{{ 'buttons.cancel' | t }}</v-btn>
        <v-btn class="main" v-if="proposalIsEditable" @click="save">{{ 'activeRFP.proposal.saveLiability' | t }}</v-btn>
      </div>
    </template>

    <template v-else-if="liability">

      <div v-if="liability.extendedFields.explanation" style="grid-column-start: 1; grid-column-end: 2;">
        <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span>
        <p>{{ liability.extendedFields.explanation }}</p>
      </div>

      <v-btn class="edit-liability" v-if="proposalIsEditable && updateMode" icon @click="editProposal"><v-icon style="font-size: 18px">ic-edit</v-icon></v-btn>
    </template>

    </v-card>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ProposalFreeText',
  props: {
    qualityScoreCriterium: {
      type: String
    }
  },
  data () {
    return {
      missingFields: {
        explanation: false
      }
    }
  },
  watch: {
    currentRfpLawFirmRelation () {
      this.assignLiability()
    }
  },
  computed: {
    ...mapGetters(['getCustomQualityScoreConfig', 'currentRfpLawFirmRelation', 'formatNumber', 'proposalIsEditable', 'currencySymbol']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      currentRfp: state => state.currentRfp,
      updateMode: state => state.draftProposal.updateMode
    }),

    title () {
      if (this.qualityScoreCriterium === 'STRATEGY') {
        return this.$t('activeRFP.proposal.strategy')
      }
      return this.currentRfp ? this.getCustomQualityScoreConfig(this.currentRfp, this.qualityScoreCriterium).title : ''
    },

    requestedContent () {
      if (this.qualityScoreCriterium === 'STRATEGY') {
        return this.currentRfp ? this.currentRfp.terms.requestedStrategyContent : ''
      }
      return this.currentRfp ? this.getCustomQualityScoreConfig(this.currentRfp, this.qualityScoreCriterium).requestedContent : ''
    },

    nonDraftItem () {
      return this.currentRfpLawFirmRelation.proposal.find(category => (this.updateMode ? category.draft : !category.draft) && category.name === this.qualityScoreCriterium) || { extendedFields: {} }
    },

    editLiability () {
      return this.$store.state.draftProposal['edit' + this.qualityScoreCriterium]
    },

    newLiability () {
      return this.$store.state.draftProposal['new' + this.qualityScoreCriterium]
    },

    draft () {
      return this.$store.state.draftProposal[this.qualityScoreCriterium]
    },

    liability () {
      if (this.updateMode) {
        return this.draft
      }

      return this.nonDraftItem
    },

    isNewLiability () {
      if (this.currentRfpLawFirmRelation.proposal) {
        return !this.currentRfpLawFirmRelation.proposal.some(category => category.name === this.qualityScoreCriterium)
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions(['saveLiability', 'checkProposalUnsavedChanges', 'saveProposalCategory']),
    ...mapMutations(['setDraftProposalItem']),

    async save () {
      let missing = false
      const requiredFields = ['explanation']
      requiredFields.forEach(field => {
        if (!this.liability.extendedFields[field]) {
          this.missingFields[field] = true
          missing = true
        }
      })

      if (missing) {
        return
      }

      await this.saveProposalCategory({
        category: this.qualityScoreCriterium,
        item: {
          name: this.qualityScoreCriterium,
          extendedFields: this.liability.extendedFields,
          draft: this.liability.draft
        }
      })

      this.setDraftProposalItem({
        key: 'edit' + this.qualityScoreCriterium,
        value: false
      })
      this.setDraftProposalItem({
        key: 'new' + this.qualityScoreCriterium,
        value: false
      })
      this.checkProposalUnsavedChanges()
    },

    cancel () {
      // this.liability = this.lastLiability
      this.setDraftProposalItem({
        key: this.qualityScoreCriterium,
        value: this.currentRfpLawFirmRelation.proposal.find(category => category.draft && category.name === this.qualityScoreCriterium) || {
          name: this.qualityScoreCriterium,
          extendedFields: {
            explanation: ''
          },
          draft: true
        }
      })

      this.setDraftProposalItem({
        key: 'edit' + this.qualityScoreCriterium,
        value: !this.editLiability
      })
      this.checkProposalUnsavedChanges()
    },

    editProposal () {
      this.lastLiability = JSON.parse(JSON.stringify(this.liability))
      this.setDraftProposalItem({
        key: 'edit' + this.qualityScoreCriterium,
        value: !this.editLiability
      })
      this.checkProposalUnsavedChanges()
    },

    assignLiability () {
      if (this.draft) {
        return
      }

      this.setDraftProposalItem({
        key: this.qualityScoreCriterium,
        value: this.currentRfpLawFirmRelation.proposal.find(category => category.draft && category.name === this.qualityScoreCriterium) || {
          name: this.qualityScoreCriterium,
          extendedFields: {
            explanation: ''
          },
          draft: true
        }
      })

      this.setDraftProposalItem({
        key: 'new' + this.qualityScoreCriterium,
        value: !this.liability.extendedFields.explanation
      })
    }

  }
}
</script>

<style scoped lang="stylus">
  .grid
    display grid
    grid-template-columns 1fr 1fr

  .actions
    grid-column-start 1
    grid-column-end 3
    text-align right

    .liability-save
      margin-right 24px
      color rgb(102, 174, 4)

    button
      border-radius 5px

  .edit-liability
    grid-column-start: 2
    height 24px
    width 24px
    justify-self right
</style>
