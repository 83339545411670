import Vue from 'vue'
import Router from 'vue-router'

import Dashboard from './views/dashboard/Dashboard'
import Login from './views/login/Login.vue'
import NewRfpCorporate from './views/newRfpCorporate/NewRfpCorporate'
import ActiveRfp from './views/activeRfp/ActiveRfp'
import ConflictCheckII from './views/newRfpLawFirm/coiII/ConflictCheckII'
import Settings from './views/settings/Settings'
import PageNotFound from './views/generalComponents/PageNotFound'
import ScopeGeneratorAdminView from './views/scopeGeneratorAdmin/ScopeGeneratorAdminView'
import ScopeGeneratorAdminInputView from './views/scopeGeneratorAdmin/ScopeGeneratorAdminInputView'
import ScopeGeneratorLayoutDefinitionView from './views/scopeGeneratorAdmin/ScopeGeneratorLayoutDefinitionView'
import ScopeGeneratorLayoutPreview from './views/scopeGeneratorAdmin/ScopeGeneratorLayoutPreview'
import store from './store.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      component: Dashboard
    },
    {
      path: '/rfps',
      name: 'dashboardRfps',
      component: Dashboard,
      meta: {
        dashboardHeader: true,
        type: 'rfps'
      }
    },
    {
      path: '/matters',
      name: 'dashboardMatters',
      component: Dashboard,
      meta: {
        dashboardHeader: true,
        type: 'matters'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: (route) => ({
        sessionExpired: Boolean(route.query.sessionExpired),
        isLoginPage: true,
        isResetPassword: false
      })
    },
    {
      path: '/signup',
      name: 'signUp',
      component: Login,
      props: (route) => ({
        sessionExpired: Boolean(route.query.sessionExpired),
        isLoginPage: false,
        isResetPassword: false
      })
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: Login,
      props: (route) => ({
        sessionExpired: Boolean(route.query.sessionExpired),
        isLoginPage: true,
        isResetPassword: true
      })
    },
    {
      path: '/cookie-declaration',
      name: 'cookieDeclaration',
      component: Login,
      props: (route) => ({
        sessionExpired: Boolean(route.query.sessionExpired),
        isLoginPage: true,
        isResetPassword: true,
        isCookieDeclaration: true
      })
    },
    {
      path: '/rfp/:id/new/',
      name: 'newRfp',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        newRfpHeader: true
      }
    },
    {
      path: '/rfp/:id/edit-active/',
      name: 'EditActiveRfp',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        newRfpHeader: true
      }
    },
    {
      path: '/rfp/:id/new/general/',
      name: 'generalInfoCorporate',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        component: 'generalRfpInfo',
        newRfpHeader: true,
        newRfpStepperIndex: 1,
        newRfpPreviousPage: {
          label: 'Exit',
          route: 'index'
        }
      }
    },
    {
      path: '/rfp/:id/new/scope/',
      name: 'scopeDetailsCorporate',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        component: 'ScopeDefinition',
        newRfpHeader: true,
        newRfpStepperIndex: 2,
        newRfpPreviousPage: {
          label: 'newRFP.general.generalTitle',
          route: 'generalInfoCorporate'
        }
      }
    },
    {
      path: '/rfp/:id/new/law-firms/',
      name: 'lawFirmSelectionCoprporate',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        component: 'LawFirmSelection',
        newRfpHeader: true,
        newRfpStepperIndex: 3,
        newRfpPreviousPage: {
          label: 'newRFP.scopeDefinition.scopeTitle',
          route: 'scopeDetailsCorporate'
        }
      }
    },
    {
      path: '/rfp/:id/new/proposal',
      name: 'proposal',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        component: 'Proposal',
        newRfpHeader: true,
        newRfpStepperIndex: 4,
        newRfpPreviousPage: {
          label: 'newRFP.lawfirmSelection.lawfirmTitle',
          route: 'lawFirmSelectionCoprporate'
        }
      }
    },
    {
      path: '/rfp/:id/new/terms',
      name: 'termsAndConditionsCorporate',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        component: 'TermsConditions',
        newRfpHeader: true,
        newRfpStepperIndex: 5,
        newRfpPreviousPage: {
          label: 'newRFP.feeDefinition.feeTitle',
          route: 'proposal'
        }
      }
    },
    {
      path: '/rfp/:id/new/bidding',
      name: 'billingTypeCorporate',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        component: 'BillingType',
        newRfpHeader: true,
        newRfpStepperIndex: 6,
        newRfpPreviousPage: {
          label: 'newRFP.termsAndConditions.termsTitle',
          route: 'termsAndConditionsCorporate'
        }
      }
    },
    {
      path: '/rfp/:id/new/summary',
      name: 'summaryCorporate',
      component: NewRfpCorporate,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        component: 'SummaryPage',
        newRfpHeader: true,
        newRfpStepperIndex: 7,
        newRfpPreviousPage: {
          label: 'newRFP.biddingType.title',
          route: 'billingTypeCorporate'
        }
      }
    },
    {
      path: '/rfp/:id',
      name: 'activeRfpCorp',
      component: ActiveRfp,
      props: (route) => ({
        rfpId: route.params.id
      })
    },
    {
      path: '/rfp/:id/:activeTab',
      name: 'activeRfp',
      component: ActiveRfp,
      props: (route) => ({
        rfpId: route.params.id,
        activeTab: (() => {
          switch (route.params.activeTab) {
            case 'summary':
              return 'Summary'
            case 'bidding':
              return 'EAuction'
            case 'qa':
              return 'QAndA'
            case 'proposal':
              return 'Proposal'
            case 'team':
              return 'Team'
            case 'decision':
              return 'Decision'
            case 'feedback':
              return 'Feedback'
            default:
              return null
          }
        })()
      })
    },
    {
      path: '/rfp/:id/coi/conflict-check/',
      name: 'coiCheckII',
      component: ConflictCheckII,
      props: (route) => ({
        rfpId: route.params.id
      }),
      meta: {
        coiCheckHeader: true
      }
    },
    {
      path: '/user-management',
      name: 'userManagement',
      component: Settings
    },
    {
      path: '/profile',
      name: 'profile',
      component: Settings
    },
    {
      path: '/legal-documents',
      name: 'legalDocuments',
      component: Settings
    },
    {
      path: '/organization',
      name: 'organizationConfig',
      component: Settings
    },
    {
      path: '/scope-generator-settings',
      name: 'scopeGeneratorAdminView',
      component: ScopeGeneratorAdminView
    },
    {
      path: '/scope-generator-input',
      name: 'scopeGeneratorInputView',
      component: ScopeGeneratorAdminInputView
    },
    {
      path: '/scope-generator-settings/:key/preview',
      name: 'scopeGeneratorLayoutPreview',
      component: ScopeGeneratorLayoutPreview,
      props: (route) => ({
        subjectKey: route.params.key
      })
    },
    {
      path: '/scope-generator-settings/:key',
      name: 'scopeGeneratorLayoutView',
      component: ScopeGeneratorLayoutDefinitionView,
      props: (route) => ({
        subjectKey: route.params.key
      })
    },
    {
      path: '*',
      name: 'pageNotFound',
      component: PageNotFound
    }
  ]
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

router.beforeEach(async (to, from, next) => {
  // console.log(to)
  const newRouteNamesArr = [
    'newRfp',
    'generalInfoCorporate',
    'scopeDetailsCorporate',
    'lawFirmSelectionCoprporate',
    'proposal',
    'termsAndConditionsCorporate',
    'billingTypeCorporate',
    'summaryCorporate'
  ]

  const routesArr = [
    'activeRfpCorp',
    'activeRfp',
    'coiCheckII'
  ]

  if (routesArr.includes(to.name) || newRouteNamesArr.includes(to.name)) {
    // console.log('try to restore')
    let restoreCookie = false
    if (store.state.authToken === null) {
      await store.dispatch('restoreTokenFromCookie')
      restoreCookie = true
    }

    if (store.state.authToken !== null) {
      if (restoreCookie) {
        await store.dispatch('loadAllRfps')
      }

      if (userHaveRFP(to.params.id)) {
        // Rfp and RFP status Assign
        const rfp = store.state.currentRfp ? store.state.currentRfp : store.state.allRfps[store.state.allRfps.findIndex(rfp => rfp.id === to.params.id)]
        const rfpProgress = rfp.status === 'DRAFT' ? -1 : store.getters.auctionProgress(rfp)

        if ((to.name === 'activeRfpCorp' && rfpProgress >= 0) || (newRouteNamesArr.includes(to.name) && rfp.submission !== null)) {
          if (rfp.status === 'COMPLETED_ABORTED' && from.params.id === to.params.id) {
            return next({ path: '/rfp/' + to.params.id + '/summary', query: { aborted: `${(Math.round(Math.random() * 10000))}` }, replace: true })
          }
          next() // next({ path: '/rfp/' + to.params.id + '/summary', replace: true })
        } else if (to.name === 'activeRfp') {
          if (store.getters.userIsLawFirm) {
            await store.dispatch('loadRfp', { rfpId: to.params.id })
            /* if (store.state.currentRfp.lawFirmRelations[0].status === 'COMPLETED_MISSED_FIRST_BID_DEADLINE') {
              next(false)
              return
            } */

            if (!store.state.currentRfp.lawFirmRelations[0].coi1Confirmed) {
              next({ name: 'dashboardRfps', query: { id: to.params.id }, replace: true })
            } else if (!store.state.currentRfp.lawFirmRelations[0].coi2Confirmed) {
              next({ path: `/rfp/${to.params.id}/coi/conflict-check/`, replace: true })
            } else if (rfpProgress >= 0 && rfpProgress < 4 && to.params.activeTab === 'decision') {
              next({ path: '/rfp/' + to.params.id + '/summary', replace: true })
            } else {
              next()
            }
          } else if (store.getters.userIsCorporate) {
            if (rfpProgress >= 0 && rfpProgress <= 3 && to.params.activeTab === 'decision') {
              next({ path: '/rfp/' + to.params.id + '/summary', replace: true })
            } else if (rfpProgress === -1) {
              next({ path: '/rfp/' + to.params.id + '/new', replace: true })
            } else {
              next()
            }
          } else {
            next()
          }
        } else if (newRouteNamesArr.includes(to.name) && routeForDraftStep(to.name) > routeForDraftStep(rfp.draftStep) && !routeForStepComplete(to.name)) {
          console.log('a', to.params.id, rfp.draftStep)
          next({ path: `/rfp/${to.params.id}/new/${routeForDraftStep(rfp.draftStep, false)}`, replace: true })
        } else {
          next()
        }
      } else {
        next({
          name: 'pageNotFound',
          params: [to.path],
          replace: true
        })
      }
    }
  } else {
    next()
  }
})

export default router

const userHaveRFP = function (rfpId) {
  return store.state.allRfps.length > 0 && store.state.allRfps.some(rfp => rfp.id === rfpId)
}

const routeForStepComplete = function (name) {
  switch (name) {
    case 'lawFirmSelectionCoprporate': return store.getters.lawFirmSelectionCompleted
    case 'proposal': return store.getters.feesCompleted
    case 'termsAndConditionsCorporate': return store.getters.termsAndConditionsCompleted
    case 'billingTypeCorporate': return store.getters.billingTypeCompleted
    case 'summaryCorporate': return false
  }
  return true
}

const routeForDraftStep = function (step, forName = true) {
  switch (true) {
    case (step === 'TODO' || step === 'generalInfoCorporate'):
      return forName ? 0 : 'general'
    case (step === 'step1SD' || step === 'scopeDetailsCorporate'):
      return forName ? 1 : 'scope'
    case (step === 'step2LS' || step === 'lawFirmSelectionCoprporate'):
      return forName ? 2 : 'law-firms'
    case (step === 'step3Fe' || step === 'proposal'):
      return forName ? 3 : 'proposal'
    case (step === 'step4TC' || step === 'termsAndConditionsCorporate'):
      return forName ? 4 : 'terms'
    case (step === 'step5BT' || step === 'billingTypeCorporate'):
      return forName ? 5 : 'bidding'
    case (step === 'step6Sm' || step === 'summaryCorporate'):
      return forName ? 6 : 'summary'
    default:
      return forName ? 0 : 'general'
  }
}
