<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'LawFirmRatingMixin',
  data () {
    return {
      showSuccessMessageForProposalComment: false
    }
  },
  computed: {
    ...mapGetters(['getCustomQualityScoreConfig', 'relevantQualityScoreCriteriaSelected', 'formatNumber', 'getTeamMemberNameFromProposalTeam']),
    ...mapState({
      currentRfp: state => state.currentRfp
    }),

    disabled () {
      return !!this.currentRfp.winner || this.currentRfp.status === 'COMPLETED_ABORTED_BY_CORPORATE'
    },

    qaEnabled () {
      return this.currentRfp.terms.qualityScoreCriteria.includes('QA')
    },

    interviewEnabled () {
      return this.currentRfp.terms.qualityScoreCriteria.includes('INTERVIEW')
    },

    onlyInterviewOrQaSelected () {
      return !this.relevantQualityScoreCriteriaSelected(this.currentRfp)
    },

    failedProposal () {
      let statusArr = [
        'ACTIVE_FIRST_BIDS_CLOSED',
        'ACTIVE_SECOND_BIDS_OR_AUCTION',
        'ACTIVE_DECISION_PENDING',
        'COMPLETED_MATTER_IN_PROGRESS',
        'COMPLETED_ABORTED'
      ]

      if (statusArr.includes(this.currentRfp.status)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions(['rateProposal']),

    relationRated (relation) {
      const team = this.team(relation)
      let teamRated = false

      if (team) {
        teamRated = team.subItems.some(member => member.rating !== null)
      }

      const credentials = this.credentials(relation)
      const liability = this.liability(relation)
      const pitchDocument = this.pitchDocument(relation)
      const interview = this.interview(relation)
      const qa = this.qa(relation)
      const strategy = this.strategy(relation)
      const custom1 = this.custom(relation, 'CUSTOM1')
      const custom2 = this.custom(relation, 'CUSTOM2')
      const custom3 = this.custom(relation, 'CUSTOM3')

      return (!credentials || credentials.rating !== null) &&
        (!liability || liability.rating !== null) &&
        (!pitchDocument || pitchDocument.rating !== null) &&
        (!interview || interview.rating !== null) &&
        (!qa || qa.rating !== null) &&
        (!strategy || strategy.rating !== null) &&
        (!custom1 || custom1.rating !== null) &&
        (!custom2 || custom2.rating !== null) &&
        (!custom3 || custom3.rating !== null)
      /* &&
        (!team || teamRated) */
    },

    rateProposalLocal ({ categoryId, subItemId, value }) {
      this.rateProposal({ categoryId, subItemId, value })
    },

    async saveProposalComment ({ categoryId, subItemId, ratingComment }) {
      await this.rateProposal({ categoryId, subItemId, ratingComment: ratingComment || '' })
      // this.showSuccessMessageForProposalComment = true
    },

    hasQualityScore (qualityScoreCriteria) {
      return this.currentRfp.terms.qualityScoreCriteria.includes(qualityScoreCriteria)
    },

    team (relation) {
      if (this.hasQualityScore('TEAM')) {
        return relation.proposal.find(category => !category.draft && category.name === 'TEAM')
      }

      return null
    },

    strategy (relation) {
      if (this.hasQualityScore('STRATEGY')) {
        return relation.proposal.find(category => !category.draft && category.name === 'STRATEGY')
      }

      return null
    },

    custom (relation, name) {
      if (this.hasQualityScore(name)) {
        return relation.proposal.find(category => !category.draft && category.name === name)
      }

      return null
    },

    customTitle (name) {
      return this.currentRfp ? this.getCustomQualityScoreConfig(this.currentRfp, name).title : ''
    },

    qa (relation) {
      if (this.hasQualityScore('QA')) {
        return relation.proposal.find(category => !category.draft && category.name === 'QA')
      }

      return null
    },

    interview (relation) {
      if (this.hasQualityScore('INTERVIEW')) {
        return relation.proposal.find(category => !category.draft && category.name === 'INTERVIEW')
      }

      return null
    },

    liability (relation) {
      if (this.hasQualityScore('LIABILITY')) {
        return relation.proposal.find(category => !category.draft && category.name === 'LIABILITY')
      }

      return null
    },

    liabilityText (relation) {
      if (this.hasQualityScore('LIABILITY') && this.liability(relation)) {
        if (this.liability(relation).extendedFields.explanation) {
          return this.liability(relation).extendedFields.explanation
        } else {
          return this.$t('activeRFP.proposal.noLiabilityExplanationLabel')
        }
      } else {
        return '-'
      }
    },

    strategyText (relation) {
      if (this.hasQualityScore('STRATEGY') && this.strategy(relation)) {
        if (this.strategy(relation).extendedFields.explanation) {
          return this.strategy(relation).extendedFields.explanation
        } else {
          return this.$t('activeRFP.proposal.noLiabilityExplanationLabel')
        }
      } else {
        return '-'
      }
    },

    customText (relation, name) {
      if (this.hasQualityScore(name) && this.custom(relation, name)) {
        if (this.custom(relation, name).extendedFields.explanation) {
          return this.custom(relation, name).extendedFields.explanation
        } else {
          return this.$t('activeRFP.proposal.noLiabilityExplanationLabel')
        }
      } else {
        return '-'
      }
    },

    credentials (relation) {
      if (this.hasQualityScore('CREDENTIALS')) {
        return relation.proposal.find(category => !category.draft && category.name === 'CREDENTIALS')
      }

      return null
    },

    renderRole (teamMember) {
      if (teamMember.role) {
        return this.$t('dropDownOptions.CredentialRole.' + teamMember.role)
      }

      return ''
    },

    pitchDocument (relation) {
      if (this.hasQualityScore('PITCH_DOCUMENT')) {
        return relation.proposal.find(category => !category.draft && category.name === 'PITCH_DOCUMENT')
      }

      return null
    },

    spanName (userName) {
      const trimmedName = userName ? userName.trim() : userName
      let firstLetter = ''
      let secondLetter = ''
      if (trimmedName && trimmedName.split(' ').length > 1) {
        firstLetter = trimmedName.split(' ')[0] && trimmedName.split(' ')[0][0] ? trimmedName.split(' ')[0][0].toUpperCase() : ''
        secondLetter = trimmedName.split(' ')[1] && trimmedName.split(' ')[1][0] ? trimmedName.split(' ')[1][0].toUpperCase() : ''
      } else if (trimmedName && trimmedName.length > 1) {
        firstLetter = trimmedName[0] ? trimmedName[0].toUpperCase() : ''
        secondLetter = trimmedName[1] ? trimmedName[1].toLowerCase() : ''
      }
      return firstLetter + secondLetter
    },

    rejectedConflictedCoiText (relation) {
      if (relation.coi1Confirmed !== null && !relation.coi1Confirmed) {
        return 'activeRFP.lawFirmRating.conflicted'
      } else if (relation.coi2Confirmed !== null && !relation.coi2Confirmed) {
        return 'activeRFP.lawFirmRating.rejected'
      } else if (relation.proposalSubmitted || (this.onlyInterviewOrQaSelected && this.currentRfp.terms.qualityScoreCriteria.length > 0)) {
        if (!this.relationRated(relation) && (this.currentRfp.winner || this.disabled)) {
          return 'activeRFP.decision.notRatedLabel'
        }
        return 'activeRFP.lawFirmRating.ratingPending'
      } else if (!this.currentRfp.terms.qualityScoreCriteria.length) {
        return ''
      } else if (this.failedProposal && !relation.proposalSubmitted) {
        return 'activeRFP.lawFirmRating.failedSubmitProposal'
      } else {
        return 'activeRFP.lawFirmRating.proposalNoYetSubmitted'
      }
    }
  }
}
</script>
