<template>
  <v-checkbox
    :class="{ indent: type === 'CHECKBOX_INDENTED' }"
    v-if="!readonly"
    :inputValue="value"
    @change="value => $emit('input', value)"
    :disabled="disabled">
    <template
      v-slot:label>
      <label :class="{ mandatory }">{{ translatedName }}</label>
    </template>
  </v-checkbox>
  <div v-else-if="value">
    <span>&nbsp;</span>
    <p>{{ translatedName }}</p>
  </div>
</template>

<script>
import InputFieldMixin from './InputFieldMixin'

export default {
  name: 'CheckboxField',
  mixins: [InputFieldMixin]
}
</script>

<style scoped lang="stylus">
  .indent
    margin-left 30px

  .v-input--checkbox.v-input--is-disabled >>> .v-label label
    color rgba(0,0,0,0.38) !important
</style>
