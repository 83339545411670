import Vue from 'vue'
import dotProp from 'dot-prop'
import {
  validateConstraintsExceptMandatoryOnSubject,
  validateMandatoryFieldsOnSubject
} from '../validation/constraints'

const addFieldToArray = (state, field, arrayName) => {
  const arrayFromState = state.validation[arrayName]
  arrayFromState.push(field)
  Vue.set(state.validation, arrayName, Array.from(new Set(arrayFromState)))
}

const removeFieldFromArray = (state, field, arrayName) => {
  const arrayFromState = state.validation[arrayName]
  const fieldIndex = arrayFromState.indexOf(field)

  if (fieldIndex > -1) {
    arrayFromState.splice(fieldIndex, 1)
    Vue.set(state.validation, arrayName, arrayFromState)
  }
}

const removeViolation = ({ state, violationsKey, subjectKey, sectionKey, panelKey, fieldKey, isListObject, listObjectId }) => {
  const path = isListObject
    ? `${subjectKey}.${sectionKey}.${panelKey}.${listObjectId}.${fieldKey}`
    : `${subjectKey}.${sectionKey}.${panelKey}.${fieldKey}`
  const violations = dotProp.get(state.validation[violationsKey], path)

  if (violations) {
    if (isListObject) {
      Vue.delete(state.validation[violationsKey][subjectKey][sectionKey][panelKey][listObjectId], fieldKey)
    } else {
      Vue.delete(state.validation[violationsKey][subjectKey][sectionKey][panelKey], fieldKey)
    }
  }
}

export default {

  validateConstraints (state, subjectKey) {
    let currentRfpOrDraft
    if (state.currentRfp.status === 'DRAFT') {
      currentRfpOrDraft = state.currentRfp
    } else {
      currentRfpOrDraft = state.rfpDraft
    }
    Vue.set(state.validation.constraintViolations, subjectKey, validateConstraintsExceptMandatoryOnSubject(currentRfpOrDraft.scopeGeneratorLayoutsBySubject[subjectKey], subjectKey, currentRfpOrDraft))
  },

  validateMandatoryFields (state, subjectKey) {
    let currentRfpOrDraft
    if (state.currentRfp.status === 'DRAFT') {
      currentRfpOrDraft = state.currentRfp
    } else {
      currentRfpOrDraft = state.rfpDraft
    }
    Vue.set(state.validation.mandatoryViolations, subjectKey, validateMandatoryFieldsOnSubject(currentRfpOrDraft.scopeGeneratorLayoutsBySubject[subjectKey], subjectKey, currentRfpOrDraft))
  },

  updateMandatoryViolations (state, subjectKey) {
    let currentRfpOrDraft
    if (state.currentRfp.status === 'DRAFT') {
      currentRfpOrDraft = state.currentRfp
    } else {
      currentRfpOrDraft = state.rfpDraft
    }
    const oldViolations = { ...state.validation.mandatoryViolations[subjectKey] }

    if (oldViolations) {
      const newViolations = validateMandatoryFieldsOnSubject(currentRfpOrDraft.scopeGeneratorLayoutsBySubject[subjectKey], subjectKey, currentRfpOrDraft)

      for (const sectionKey of Object.keys(oldViolations)) {
        for (const panelKey of Object.keys(oldViolations[sectionKey])) {
          for (const fieldOrListKey of Object.keys(oldViolations[sectionKey][panelKey])) {
            if (Array.isArray(oldViolations[sectionKey][panelKey][fieldOrListKey])) {
              const path = `${sectionKey}.${panelKey}.${fieldOrListKey}`

              if (!dotProp.get(newViolations, path)) {
                dotProp.delete(oldViolations, path)
              }
            } else {
              for (const listItemField of Object.keys(oldViolations[sectionKey][panelKey][fieldOrListKey])) {
                const path = `${sectionKey}.${panelKey}.${fieldOrListKey}.${listItemField}`

                if (!dotProp.get(newViolations, path)) {
                  dotProp.delete(oldViolations, path, [])
                }
              }
            }
          }
        }
      }

      Vue.set(state.validation.mandatoryViolations, subjectKey, oldViolations)
    }
  },

  addInternalViolation (state, { subjectKey, sectionKey, panelKey, field, listItemId, isList }) {
    const subjectViolations = state.validation.internalViolations[subjectKey] || {}
    const path = isList
      ? `${sectionKey}.${panelKey}.${listItemId}.${field}`
      : `${sectionKey}.${panelKey}.${field}`
    const newViolations = dotProp.set(subjectViolations, path, ['Internal'])
    Vue.set(state.validation.internalViolations, subjectKey, newViolations)
  },

  removeAllInternalViolationsForListItem (state, { subjectKey, sectionKey, panelKey, listItemId }) {
    const subjectViolations = state.validation.internalViolations[subjectKey] || {}
    const path = `${sectionKey}.${panelKey}.${listItemId}`
    dotProp.delete(subjectViolations, path)
    Vue.set(state.validation.internalViolations, subjectKey, subjectViolations)
  },

  removeInternalViolation (state, { subjectKey, sectionKey, panelKey, fieldKey, isListObject, listObjectId }) {
    removeViolation({
      state,
      subjectKey,
      sectionKey,
      panelKey,
      fieldKey,
      isListObject,
      listObjectId,
      violationsKey: 'internalViolations'
    })
  },

  removeMandatoryViolation (state, { subjectKey, sectionKey, panelKey, fieldKey, isListObject, listObjectId }) {
    removeViolation({
      state,
      subjectKey,
      sectionKey,
      panelKey,
      fieldKey,
      isListObject,
      listObjectId,
      violationsKey: 'mandatoryViolations'
    })
  },

  addMissingMandatoryField (state, field) {
    addFieldToArray(state, field, 'missingMandatoryFields')
  },

  removeMissingMandatoryField (state, field) {
    removeFieldFromArray(state, field, 'missingMandatoryFields')
  },

  resetMandatoryFields (state) {
    Vue.set(state.validation, 'missingMandatoryFields', [])
  },

  addInvalidField (state, field) {
    addFieldToArray(state, field, 'invalidFields')
  },

  removeInvalidField (state, field) {
    removeFieldFromArray(state, field, 'invalidFields')
  },

  resetInvalidFields (state) {
    Vue.set(state.validation, 'invalidFields', [])
  },

  resetMandatoryViolations (state) {
    Vue.set(state.validation, 'mandatoryViolations', {})
  }

}
