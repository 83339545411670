<template>
  <div class="main" :class="{newrfp: isNewRfpSummary}">
    <div class="side-nav">
      <Sidebar
        :isCOICheck="isCOICheck"
        :optionsArray="optionArr"
        :isNewRfpSummary="isNewRfpSummary"
        :alertOffset="userDidNotEnterInitialBid"
        :componentsArray="componentArr" />
    </div>

    <div class="content">
      <div v-if="!isNewRfpSummary && rfpHistory.length > 1" style="position: sticky; top: 64px; z-index: 10; margin-bottom: 10px">
        <v-tabs
          show-arrows
        >
          <v-tabs-slider color="#AE9664"></v-tabs-slider>
          <v-tab
            :ripple="{ class: `lime--text text--darken-4` }"
            @click="selectedHistoryRfp = rfp; setHistoryRfpVersion(rfp.version)"
            v-for="rfp in rfpHistory"
            :key="rfp.id"
          >
            {{ formatTimestamp(rfp.submission, 'DD.MM.YYYY HH:mm') }}
          </v-tab>
        </v-tabs>
      </div>

      <div v-if="currentRfp.submissionMessage" class="panel-header break-before-print" style="margin-top: -10px;" ref="scopeDef">
        <h2 style="padding-top: 8px">{{ "activeRFP.summarySection.submissionMessage" | t }}</h2>
      </div>
      <div v-if="currentRfp.submissionMessage" class="summary-panel">
        <v-card>
          <p>{{ currentRfp.submissionMessage }}</p>
        </v-card>
      </div>

      <div class="panel-header" :style="currentRfp.submissionMessage ? 'margin-top: 2.5em;' : 'margin-top: -10px'" id="general" ref="general">
        <div>
          <h2 style="display: inline-block; padding-top: 8px">{{ "activeRFP.summarySection.general" | t }}</h2>
          <TooltipHolder
            v-if="userIsCorporate && isNewRfpSummary"
            class="info-icon"
            style="vertical-align: bottom"
            :spanId="$t('tooltips.generalSummarySubtitleTooltip')"
            :width="160"
            :moveTop="30"
            :icon="'info'"
            :tooltipText="$t('tooltips.generalSummarySubtitleTooltip')"
            right summaryTooltip />
        </div>
        <BetterTooltip
          v-if="isNewRfpSummary || rfpCanBeEdited"
          :disabled="isNewRfpSummary"
          top="-50px"
          width="200px"
          left="-50px"
          :tooltip-text="$t('activeRFP.summarySection.changeDeadline', { deadline: changeDeadline })">
          <v-btn @click="moveTo('generalInfoCorporate')" flat><v-icon>ic-edit</v-icon>&nbsp; {{ "buttons.edit" | t}}</v-btn>
        </BetterTooltip>
      </div>
      <GeneralInfoPanel :current-rfp="currentRfp" />

      <div class="panel-header break-before-print" style="margin-top: 2.5em;" ref="scopeDef">
        <h2 style="padding-top: 8px">
          {{ "activeRFP.summarySection.scope" | t }}
          <ChangeIcon v-if="scopeChanged" section></ChangeIcon>
        </h2>
        <BetterTooltip
          v-if="isNewRfpSummary || rfpCanBeEdited"
          :disabled="isNewRfpSummary"
          top="-50px"
          width="200px"
          left="-50px"
          :tooltip-text="$t('activeRFP.summarySection.changeDeadline', { deadline: changeDeadline })">
          <v-btn @click="moveTo('scopeDetailsCorporate')" flat><v-icon>ic-edit</v-icon>&nbsp; {{ "buttons.edit" | t}}</v-btn>
        </BetterTooltip>
      </div>
      <ScopeDefinitionPanel :current-rfp="currentRfp" v-if="!currentRfp.usesScopeGenerator" />

      <DynamicSummaryPanel
        v-else
        v-for="subject in currentRfp.subjects"
        :key="subject.key"
        :subject="subject"
        :layout-definitions="currentRfp.scopeGeneratorLayoutsBySubject"
        :root="currentRfp.scope"
        :current-rfp="currentRfp"
      />

      <template v-if="userIsCorporate">
        <div class="panel-header break-before-print" style="margin-top: 2.5em;" ref="lawfirm">
          <h2 style="padding-top: 8px">{{ "activeRFP.summarySection.lawFirms" | t }}
            <ChangeIcon v-if="lawFirmsHaveChanged" section></ChangeIcon>
          </h2>
          <BetterTooltip
            v-if="isNewRfpSummary || rfpCanBeEdited"
            :disabled="isNewRfpSummary"
            top="-50px"
            width="200px"
            left="-50px"
            :tooltip-text="$t('activeRFP.summarySection.changeDeadline', { deadline: changeDeadline })">
            <v-btn v-if="isNewRfpSummary || rfpCanBeEdited" @click="moveTo('lawFirmSelectionCoprporate')" flat><v-icon>ic-edit</v-icon>&nbsp; {{ "buttons.edit" | t}}</v-btn>
          </BetterTooltip>
        </div>
        <LawFirmPanel :current-rfp="currentRfp" />
      </template>

      <div class="panel-header break-before-print" style="margin-top: 2.5em;" ref="feeSum">
        <h2 style="padding-top: 8px">
          {{ "activeRFP.summarySection.proposal" | t }}
          <ChangeIcon v-if="requestedInformationChanged" section></ChangeIcon>
        </h2>
        <BetterTooltip
          v-if="isNewRfpSummary || rfpCanBeEdited"
          :disabled="isNewRfpSummary"
          top="-50px"
          width="200px"
          left="-50px"
          :tooltip-text="$t('activeRFP.summarySection.changeDeadline', { deadline: changeDeadline })">
          <v-btn v-if="isNewRfpSummary || rfpCanBeEdited" @click="moveTo('proposal')" flat><v-icon>ic-edit</v-icon>&nbsp; {{ "buttons.edit" | t}}</v-btn>
        </BetterTooltip>
      </div>
      <ProposalSummaryPanel :current-rfp="currentRfp" />

      <div class="panel-header break-before-print" ref="terms" style="margin-top: 2.5em;">
        <h2  style="padding-top: 8px">
          {{ "activeRFP.summarySection.Terms&Conditions" | t }}
          <ChangeIcon v-if="termsChanged" section></ChangeIcon>
        </h2>
        <BetterTooltip
          v-if="isNewRfpSummary || rfpCanBeEdited"
          :disabled="isNewRfpSummary"
          top="-50px"
          width="200px"
          left="-50px"
          :tooltip-text="$t('activeRFP.summarySection.changeDeadline', { deadline: changeDeadline })">
          <v-btn v-if="isNewRfpSummary || rfpCanBeEdited" @click="moveTo('termsAndConditionsCorporate')" flat><v-icon>ic-edit</v-icon>&nbsp; {{ "buttons.edit" | t}}</v-btn>
        </BetterTooltip>
      </div>
      <TermsConditionsPanel
        v-if="!hideTermsPanel"
        :isNewRfpSummary="isNewRfpSummary"
        :expensesApplicable="expensesApplicable"
        :feesApplicable="feesApplicable"
        :current-rfp="currentRfp"
        :isApplicable="isApplicable" />

      <div class="panel-header break-before-print" style="margin-top: 2.5em;" ref="bidding">
        <h2 style="padding-top: 8px">
          {{ "activeRFP.linkTabs.biddingProcess" | t }}
          <ChangeIcon v-if="biddingChanged" section></ChangeIcon>
        </h2>
        <BetterTooltip
          v-if="isNewRfpSummary || rfpCanBeEdited || userIsCorporate"
          :disabled="isNewRfpSummary"
          top="-50px"
          width="200px"
          left="-50px"
          :tooltip-text="$t('activeRFP.summarySection.changeDeadline', { deadline: changeDeadline })">
          <v-btn v-if="isNewRfpSummary || rfpCanBeEdited || userIsCorporate" @click="moveTo('billingTypeCorporate')" flat><v-icon>ic-edit</v-icon>&nbsp; {{ "buttons.edit" | t}}</v-btn>
        </BetterTooltip>
      </div>
      <BiddingTypePanel :current-rfp="currentRfp" style="margin-bottom: 300px;" class="bidding-summary" />

    </div>

    <ScrollDownIndicator :bottom="$route.name !== 'summaryCorporate'" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import ScopeDefinitionPanel from './components/ScopeDefinitionPanel'
import LawFirmPanel from './components/LawFirmPanel'
import BiddingTypePanel from './components/BiddingTypePanel'
import TermsConditionsPanel from './components/TermsConditionsPanel'
import ProposalSummaryPanel from './components/ProposalSummaryPanel'
import GeneralInfoPanel from './components/GeneralInfoPanel'
import Sidebar from '../../generalComponents/SideBar'
import DynamicSummaryPanel from './dynamicSummaryPanel/DynamicSummaryPanel'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import ScrollDownIndicator from '../../generalComponents/ScrollDownIndicator'
import BetterTooltip from '@/views/generalComponents/BetterTooltip'
import moment from 'moment'
import ChangeIcon from '@/views/generalComponents/ChangeIcon'

export default {
  name: 'Summary',
  components: {
    ChangeIcon,
    BetterTooltip,
    ScrollDownIndicator,
    DynamicSummaryPanel,
    ProposalSummaryPanel,
    TermsConditionsPanel,
    BiddingTypePanel,
    LawFirmPanel,
    ScopeDefinitionPanel,
    GeneralInfoPanel,
    Sidebar,
    TooltipHolder
  },
  props: {
    isNewRfpSummary: {
      default: false,
      required: false
    },
    userDidNotEnterInitialBid: {
      default: false,
      required: false
    },
    isCOICheck: {
      default: false,
      required: false
    }
  },
  data () {
    return {
      selectedHistoryRfp: null,
      isOnPosition: false,
      refVal: '',
      optionArr: [],
      componentArr: [],
      lawFirmArr: [
        { name: 'activeRFP.summarySection.general', idRef: 'general', status: true, icon: 'ic-settings', top: 75 },
        { name: 'activeRFP.summarySection.scope', idRef: 'scopeDef', status: false, icon: 'gps_fixed', top: 90 },
        { name: 'activeRFP.summarySection.proposal', idRef: 'feeSum', status: false, icon: 'ic-clipboard', top: 107 },
        { name: 'activeRFP.summarySection.Terms&Conditions', idRef: 'terms', status: false, icon: 'ic-pen-tool', top: 140 },
        { name: 'activeRFP.linkTabs.biddingProcess', idRef: 'bidding', status: false, icon: 'ic-gavel', top: 150 }
      ],
      corporateArr: [
        { name: 'activeRFP.summarySection.general', idRef: 'general', status: true, icon: 'ic-settings', top: 75 },
        { name: 'activeRFP.summarySection.scope', idRef: 'scopeDef', status: false, icon: 'gps_fixed', top: 90 },
        { name: 'activeRFP.summarySection.lawFirms', idRef: 'lawfirm', status: false, icon: 'ic-users', top: 107 },
        { name: 'activeRFP.summarySection.proposal', idRef: 'feeSum', status: false, icon: 'ic-clipboard', top: 140 },
        { name: 'activeRFP.summarySection.Terms&Conditions', idRef: 'terms', status: false, icon: 'ic-pen-tool', top: 150 },
        { name: 'activeRFP.linkTabs.biddingProcess', idRef: 'bidding', status: false, icon: 'ic-gavel', top: 200 }
      ]
    }
  },
  created () {
    if (this.userIsCorporate) {
      this.optionArr = this.corporateArr
    } else {
      this.optionArr = this.lawFirmArr
    }

    if (!this.isNewRfpSummary && (this.rfpCanBeEdited || (this.userIsLawFirm))) {
      console.log('asdas')
      this.selectedHistoryRfp = this.rfpHistory[0]
      this.setHistoryRfpVersion(this.selectedHistoryRfp.version)
    }
  },
  mounted () {
    this.componentArr = this.optionArr.map(tabObj => {
      const component = this.$refs[tabObj.idRef]
      return component
    })
  },
  computed: {
    ...mapGetters(['scopeChanged', 'requestedInformationChanged', 'biddingChanged', 'userIsCorporate', 'userIsLawFirm', 'formatTimestamp', 'currentRfpOrDraft', 'lawFirmsHaveChanged', 'termsChanged']),
    ...mapState({
      _currentRfp: state => state.currentRfp,
      history: state => state.history
    }),

    changeDeadline () {
      return moment.utc(this._currentRfp.auctionEnd).tz('Europe/Berlin').format('DD.MM.YYYY HH:mm')
    },

    rfpHistory () {
      if (this.history && this.history.length > 1) {
        return this.userIsCorporate ? this.history.slice(1) : this.history
      }
      return []
    },

    currentRfp () {
      if (this.isNewRfpSummary) {
        return this.currentRfpOrDraft
      }
      return this.selectedHistoryRfp || this._currentRfp
    },

    rfpCanBeEdited () {
      return ['ACTIVE_QA', 'ACTIVE_FIRST_BIDS', 'ACTIVE_FIRST_BIDS_CLOSED', 'ACTIVE_SECOND_BIDS_OR_AUCTION'].includes(this._currentRfp.status) && this.userIsCorporate
    },

    expensesApplicable () {
      const expensesFields = ['generalFees', 'flightExpenseType', 'trainExpenseType', 'rentalCarExpenseType', 'hotelExpenseType']
      return expensesFields.some(entry => this.isApplicable(entry))
    },

    feesApplicable () {
      const feesFields = ['needsConsentToLeaveScope']

      if (this.currentRfp.terms.feeStructure === 'FIXED') {
        feesFields.push('hasAlertThreshold')
      } else if (this.currentRfp.terms.feeStructure === 'RATE_CARD') {
        feesFields.push('internalCoordinationType', 'associatesExpenseType', 'internalCoordinationType', 'travelExpenseType', 'traineesExpenseType', 'trainingExpenseType')
      }

      return feesFields.some(entry => this.isApplicable(entry))
    },

    hideTermsPanel () {
      return this.currentRfp.terms.usesTermsGenerator &&
        !this.expensesApplicable &&
        !this.feesApplicable &&
        !this.isApplicable('billingType') &&
        !this.isApplicable('liabilityType')
    }
  },
  methods: {
    ...mapMutations(['setHistoryRfpVersion']),
    moveTo (routeName) {
      this.$router.push({
        name: routeName
      })
    },

    edit (routeName) {
      this.$router.push({
        name: 'EditActiveRfp' + routeName,
        params: {
          id: this.currentRfp.id
        }
      })
    },

    isApplicable (option) {
      return this.currentRfp.terms[option] !== 'NOT_APPLICABLE' && this.currentRfp.terms[option] !== false && this.currentRfp.terms[option] !== null
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  h1
    display inline-block
    margin-bottom 1.5em

  .info-icon
    font-size 20px
    margin-left 5px

  .main
    display grid
    grid-template-columns 200px minmax(0,1fr)
    grid-column-gap 20px
    padding-top 1.5em
    &.newrfp
      border-top 1px solid rgb(211, 211, 211)

    .side-nav
      .fixed
        color white
        position fixed
        top 90px
        width 200px

      a
        display inline-block
        color gray
        font-weight bold
        width 100%
        padding 5px 10px
        border-radius 10px

        .v-icon
          font-size 16px
          color gray

        &.active
          color $dark-gray
          background #ffffff

          .v-icon
            color $dark-gray !important

    .content
      h2
        margin-top 1.5em
        margin-bottom 0em
      h2:first-child
        margin-top 0

  .content >>> .summary-panel
    .v-card
      padding 60px 100px
      border-radius 10px

    .grid
      display grid
      grid-template-columns 200px minmax(0,1fr)
      word-wrap break-word
      grid-row-gap 30px

      > *:not(:first-child):not(:nth-child(2))
        border-top 1px solid $light-gray
        border-radius 0
        padding-top: 20px

    .border-top
      border-top 1px solid $light-gray
      border-radius 0
      padding-top: 20px

    h2
      color $icon-gray
      font-weight bold
      font-size 18px

    h3
      color $icon-gray
      font-weight bold

    p
      font-size 16px
      font-weight bold

    ul
      font-size 16px
      padding-left 0

      .v-icon:first-child
        font-size 20px

  .panel-header,
  >>> .panel-header {
    display grid
    grid-template-columns 1fr max-content
    height: 45px;
    margin-bottom: 0.5 em;
    .v-btn {
      color $icon-gray
      font-weight bold

      .v-icon:before {
        font-size 20px
      }
    }
  }

  >>> .override,
  >>> .override p
      font-weight normal !important
      line-height 1.3
      font-size 16px
      p, ul, ol, span
        font-size inherit

      > p
        margin-bottom 5px
        font-weight normal
      > ol, > ul
        font-size 16px
        padding-left 24px
        span
          font-size 16px
      > span
          font-size 16px

</style>
