// import { dateTimeMaxWidthPixels } from '../../../layoutConstants'

const typeWidth = '150px'
const titleWidth = '150px'
const bidTypeWidth = '150px'
const deadlineWidth = '150px'

export const corporateDraftHeaders = [
  {
    text: 'dashboard.rfpInformation.tableHeaders.type',
    align: 'left',
    sortable: true,
    value: 'mandateType',
    width: typeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.title',
    align: 'left',
    sortable: true,
    value: 'name',
    width: titleWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.status',
    align: 'left',
    sortable: true,
    value: 'status'
  },
  {
    text: '',
    sortable: false,
    value: 'action',
    width: '100px'
  },
  {
    text: '',
    sortable: false,
    value: 'action2',
    width: '130px'
  }
]

export const corporateActiveHeaders = [
  {
    text: 'dashboard.rfpInformation.tableHeaders.type',
    align: 'left',
    sortable: true,
    value: 'mandateType',
    width: typeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.title',
    align: 'left',
    sortable: true,
    value: 'name',
    width: titleWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.bidType',
    align: 'left',
    sortable: true,
    value: 'auctionType',
    width: bidTypeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.sentDate',
    align: 'left',
    sortable: true,
    width: '200px',
    value: 'submission'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.status',
    align: 'left',
    sortable: true,
    value: 'status',
    width: '150px'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.nextDeadline',
    align: 'left',
    sortable: true,
    value: 'auctionEnd',
    width: deadlineWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.minBid',
    align: 'left',
    sortable: true,
    value: 'minBid'
  },
  {
    text: '',
    sortable: false,
    value: 'action',
    width: '130px'
  }
]

export const corporateCompleteHeaders = [
  {
    text: 'dashboard.rfpInformation.tableHeaders.type',
    align: 'left',
    sortable: true,
    value: 'mandateType',
    width: typeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.title',
    align: 'left',
    sortable: true,
    value: 'name',
    width: titleWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.firm',
    align: 'left',
    sortable: true,
    value: 'firm',
    width: bidTypeWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.status',
    align: 'left',
    sortable: true,
    value: 'status',
    width: '200px'
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.endDate',
    align: 'left',
    sortable: true,
    value: 'auctionEnd',
    width: deadlineWidth
  },
  {
    text: 'dashboard.rfpInformation.tableHeaders.finalBid',
    align: 'left',
    sortable: true,
    value: 'finalBid'
  },
  {
    text: '',
    sortable: false,
    value: 'action',
    width: '130px'
  }
]
