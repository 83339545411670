<template>
  <div v-if="showComponent">
    <ConfirmDialog
      v-if="showDialog"
      :title="$t('newRFP.lawfirmSelection.topRatedLawFirmsTitle')"
      @close="handleClose"
      @submit="handleClose"
      accept-button-text-or-key="buttons.close"
      hideCancel
      >
      <template v-slot:custom-input>
        <LawFirmList :ratings="ratings"  text-fixed-width="70%" />
      </template>
    </ConfirmDialog>
    <v-card
      class="mx-auto top-rated-card"
    >
   <LawFirmList :ratings="topRates" horizontal text-fixed-width="55%" item-width="26rem">
     <template v-slot:header>
     <div class="list-header">
       <v-sheet class="title">
         <h1>{{$t('newRFP.lawfirmSelection.topRatedLawFirmsTitle')}}</h1>
       </v-sheet>
       <v-sheet  >
         <AwaitButton :callback="showAll" v-if="showButton">{{'buttons.viewAll' | t}}</AwaitButton>
       </v-sheet>
     </div>
     </template>
   </LawFirmList>
    </v-card>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import AwaitButton from '@/views/generalComponents/AwaitButton.vue'
import LawFirmList from '@/views/newRfpCorporate/lawFirmSelection/LawFirmList.vue'
import ConfirmDialog from '@/views/generalComponents/ConfirmDialog.vue'

export default {
  name: 'TopRatedLawFirm',
  components: { ConfirmDialog, LawFirmList, AwaitButton },
  props: {
    mandateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ratings: [],
      showDialog: false
    }
  },
  created () {
    this.loadLawFirms()
  },
  methods: {
    ...mapActions([
      'loadTopRatedLawFirms'
    ]),
    async loadLawFirms () {
      this.ratings = await this.loadTopRatedLawFirms({ mandateType: this.mandateType })
    },
    showAll () {
      this.showDialog = true
    },
    handleClose () {
      this.showDialog = false
    }
  },
  computed: {
    topRates () {
      return this.ratings.slice(0, 3)
    },
    showButton () {
      return this.ratings.length > 3
    },
    showComponent () {
      return this.ratings.length > 0
    }
  }
}
</script>

<style scoped>
.top-rated-card{
  margin-top: 2rem;
  border-radius: 10px !important;
  width: 1200px;
}
h1{
  display: inline-block;
  font-size: 18px;
}

.list-header{
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 0rem 1rem 0;
}
</style>
