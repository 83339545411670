<template>
  <div class="summary-panel">
    <v-card class="grid" style="grid-template-columns: minmax(0,1fr);">

      <div class="project-grid">
      <div style="display: grid; grid-template-columns: 1fr 1fr 20px">
        <span class="light">{{ 'dashboard.createNewRfp.projectName' | t }}</span>
      </div>
        <span class="strong">{{ currentRfp.name}}</span>
      </div>

      <div class="description">
        <span class="light" for="description" >{{ "dashboard.createNewRfp.projectDescription" | t }}</span>
        <p style="margin-bottom: 0px; font-weight: bold;">{{ currentRfp.description }}</p>
      </div>

      <div class="matter-grid">
        <div class="matter-grid-item">
          <span class="light">
            {{ 'activeRFP.summarySection.mandateType' | t }}
          </span>
          <ChangeIcon v-if="mandateTypeChangedToLastVersion" style="position:relative; top: 8px"></ChangeIcon>
        </div>

        <div class="matter-grid-item">
          <span class="light" v-show="currentRfp.mandateSubType">
            {{ 'activeRFP.summarySection.mandateSubType' | t }}
          </span>
          <ChangeIcon v-if="mandateSubTypeChangedToLastVersion" style="position:relative; top: 8px"></ChangeIcon>
        </div>
        <span class="strong" v-show="!currentRfp.mandateSubType"></span>
        <span class="light"></span>

        <span class="strong" style="padding-right: 10px;">{{ `dropDownOptions.MandateType.${currentRfp.mandateType}`| t }}</span>
        <span class="strong" v-show="currentRfp.mandateSubType">{{ `dropDownOptions.MandateSubType.${currentRfp.mandateSubType}` | t }}</span>
        <span class="strong" v-show="!currentRfp.mandateSubType"></span>
        <span class="strong"></span>
      </div>

      <div v-if="currentRfp.subjects.length" class="subject-grid">
        <div
          class="subjects"
          v-for="(subject, index) in currentRfp.subjects"
          :key="'subject' + index"
          :style="currentRfp.subjects.length === 1 ? 'grid-column: 1 / 3;' : ''">
          <template v-if="index === 0">
            <span class="light">{{ 'dashboard.createNewRfp.keySubject' | t }}</span>
            <ChangeIcon v-if="subjectChangedToLastVersion(subject.key)" style="position:relative; top: 8px"></ChangeIcon>
            <br>
            <TooltipHolder
              v-if="currentRfp.subjects.length > 1"
              :text="subject.translatedLabel"
              :tooltipText="subject.translatedLabel"
              :tableDataWidth="200"
              :moveLeft="-20"
              tableData
              bold />
            <span class="strong" v-else>{{ subject.translatedLabel }}</span>
            <!-- <span class="strong">{{ subject.translatedLabel }}</span> -->
          </template>
          <template v-else>
            <span class="light" v-if="index === 1">{{ 'dashboard.createNewRfp.otherSubjects' | t }}</span>
            <ChangeIcon v-if="subjectChangedToLastVersion(subject.key)" style="position:relative; top: 8px"></ChangeIcon>
            <br>
            <span class="strong">{{ subject.translatedLabel }}</span>
             <!-- <span class="strong">{{ subject.translatedLabel }}</!-->
          </template>
        </div>
      </div>

      <div class="matter-grid">
        <span class="light">{{ 'dashboard.createNewRfp.projectClientName' | t }}</span>
        <span class="light" v-if="currentRfp.coi1 && currentRfp.coi1.otherParties.length">{{ 'dashboard.createNewRfp.projectOtherParties' | t }}</span>
        <span class="light"></span>

        <span class="strong" style="grid-column-start: 1; padding-right: 10px;">{{ currentRfp.clientName}}</span>
        <!-- span class="strong">{{ (currentRfp.coi1.otherParties) ? currentRfp.coi1.otherParties : $t("dashboard.createNewRfp.noOtherParty") }}</span -->
        <div v-if="currentRfp.coi1 && currentRfp.coi1.otherParties.length">
          <div v-for="party in currentRfp.coi1.otherParties" :key="party.id" class="other-parties">
            <span class="strong" :key="party.id + 'name'">{{ party.name }}</span>
            <span class="strong" :key="party.id + 'party'">{{ otherPartyRole(party.role) }}</span>
          </div>
        </div>
        <span class="strong"></span>
      </div>

      <div
        v-if="userIsLawFirm && currentRfpLawFirmRelation.requestedLawyers && currentRfpLawFirmRelation.requestedLawyers.length"
        class="matter-grid"
        style="grid-template-columns: 1fr">
        <span class="light">{{ 'dashboard.createNewRfp.requestedLawyers' | t }}</span>
        <span
          v-for="requestedLawyer in currentRfpLawFirmRelation.requestedLawyers"
          :key="requestedLawyer.id"
          class="strong">{{ requestedLawyer.invitePending ? requestedLawyer.email + ' ' + $t('dashboard.createNewRfp.invitePendingAppendix')  : requestedLawyer.fullName }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TooltipHolder from '../../../generalComponents/TooltipHolder'
import ChangeIcon from '@/views/generalComponents/ChangeIcon'

export default {
  name: 'GeneralInfoPanel',
  components: { TooltipHolder, ChangeIcon },
  props: {
    currentRfp: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'userIsLawFirm',
      'currentRfpLawFirmRelation',
      'formatDateTime',
      'mandateTypeChangedToLastVersion',
      'mandateSubTypeChangedToLastVersion',
      'subjectChangedToLastVersion'
    ])
  },
  methods: {

    otherPartyRole (role) {
      const key = `dropDownOptions.OtherPartyRoles.${role}`
      if (this._i18n.i18next.exists(key)) {
        return this.$t(key)
      } else {
        return role
      }
    }

  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"
  .grid
    display grid
    grid-row-gap 30px

  a
    text-decoration none
    color inherit

  .project-grid
    display grid
    grid-template-columns 2fr

  .matter-grid
    display grid
    grid-template-columns 200px 410px 1fr
    padding-top 20px
    border-top 1px solid #f5f5f5;
    grid-column-gap 10px

  .matter-grid-item
    align-self: end

  .strong
    color $dark-gray
    font-size 16px
    font-weight bold

  .description
    border-top 1px solid #f5f5f5
    padding-top 20px
    color $dark-gray
    font-size 16px

  .light
    color: #ababb5

  .other-parties
    display grid
    grid-template-columns 200px 200px
    grid-column-gap 10px

  .subject-grid
    display grid
    grid-template-rows 2
    grid-template-columns 200px 1fr
    grid-column-gap 10px
    .subjects
      grid-row-start 2
      display inline-block
      min-width 80px

</style>
