import i18n from '../../langs/i18next'
import moment from 'moment'

export default {
  getStringsArray: () => (translationKey, parameters, shortid) => {
    const translation = i18n.t(translationKey, parameters) || ''
    const result = translation.split(shortid)

    if (translation.startsWith(shortid)) {
      result.unshift(null)
    }

    return result
  },

  currencySymbol: (state) => (rfp = state.currentRfp) => {
    const currency = rfp && rfp.terms ? rfp.terms.currency : null

    switch (currency) {
      case 'EUR': return '€'
      case 'USD': return '$'
      default: return '€'
    }
  },

  formatTimestamp: () => (timestamp, format = 'DD.MM.YYYY') => {
    return moment(timestamp).format(format)
  },

  formatUser: () => (user) => {
    return user.fullName + (user.companyName ? `, ${user.companyName}` : '')
  }
}
