<template>
  <v-card>
    <div class="header">
      <h2>{{ "activeRFP.notifications.header" | t }}</h2>
      <v-btn flat icon class="open-notif" @click="toggleNotification()"><v-icon class="icon-display" :style="showNotificationPanel ? 'transform: rotate(270deg)': 'transform: rotate(90deg)' ">ic-chevron-right</v-icon></v-btn>
    </div>

    <div v-if="showNotificationPanel" class="notification-wrapper">
      <v-stepper vertical v-if="notifications.length">
        <div  v-for="(notification) in notifications" :key="notification.id">
          <v-stepper-step step="" :class="{general: notification.type === 'general'}">
            <span class="stepper-title">{{ notification.title }}</span>
            <small v-if="notification.type === 'bid'">{{ renderLawFirm(notification.lawFirmId) }}</small>
            <small>{{ formatDateTime(notification.timestamp) }}</small>
          </v-stepper-step>
          <!-- v-divider v-if="index !== sampleNotifications.length - 1" vertical></v-divider -->
        </div>
      </v-stepper>
      <span v-else>{{ 'activeRFP.notifications.noBidsAvailable' | t }}</span>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AuctionNotifications',
  computed: {
    ...mapGetters([
      'userIsLawFirm',
      'userIsCorporate',
      'isEAuction',
      'isSimpleRfp',
      'isAdvancedRfp',
      'currentLawFirmHash',
      'currentLawFirm',
      'renderLawFirm',
      'formatDateTime',
      'roundBidToThousands',
      'formatNumber',
      'RfpProgress',
      'currencySymbol'
    ]),
    ...mapState({
      lawFirms: state => state.lawFirms,
      bids: state => state.auction.bids,
      currentRfp: state => state.currentRfp,
      userData: state => state.userData,
      showNotificationPanel: state => state.auction.showNotificationPanel
    }),

    initialBids () {
      // simpleRfp doesn't have a "firstBidDeadline" element, therefore "auctionEnd" is used to get the bids
      const firstBidDeadline = this.isSimpleRfp ? moment.utc(this.currentRfp.auctionEnd) : moment.utc(this.currentRfp.firstBidDeadline)
      return this.bids.filter(bid => moment.utc(bid.time_of_placement).isBefore(firstBidDeadline))
    },

    nonInitialBids () {
      const firstBidDeadline = moment.utc(this.currentRfp.firstBidDeadline)
      return this.bids.filter(bid => moment.utc(bid.time_of_placement).isSameOrAfter(firstBidDeadline))
    },

    notifications () {
      const initialBids = this.initialBids.slice().map(bid => {
        return {
          id: bid.id,
          type: 'bid',
          lawFirmId: bid.law_firm_id,
          timestamp: bid.time_of_placement,
          title: this.$t('activeRFP.notifications.newInitialBid', { value: (bid.relevant_display_value >= 1000000 ? this.roundBidToThousands(Math.round(bid.relevant_display_value)) + ' k' : this.formatNumber(Math.round(bid.relevant_display_value)) + ` ${this.currencySymbol()}`) })
        }
      })

      const nonInitialBids = this.nonInitialBids.slice().map(bid => {
        return {
          id: bid.id,
          type: 'bid',
          lawFirmId: bid.law_firm_id,
          timestamp: bid.time_of_placement,
          title: this.$t('activeRFP.notifications.newBid', { value: (bid.relevant_display_value >= 1000000 ? this.roundBidToThousands(Math.round(bid.relevant_display_value)) + ' k' : this.formatNumber(Math.round(bid.relevant_display_value)) + ` ${this.currencySymbol()}`) })
        }
      })

      const all = nonInitialBids.concat(initialBids) // this.isEAuction || this.userIsCorporate ? nonInitialBids.concat(initialBids) : []
      all.sort((a, b) => {
        const aTime = moment.utc(a.timestamp)
        const bTime = moment.utc(b.timestamp)

        return aTime.isSame(bTime) ? 0 : (aTime.isBefore(bTime) ? 1 : -1)
      })

      return all
    },

    generalNotifications () {
      const result = []

      result.push({
        id: 'initial_bidding_started',
        type: 'general',
        title: this.isSimpleRfp ? this.$t('activeRFP.notifications.biddingStarted') : this.$t('activeRFP.notifications.initialBiddingStarted'),
        timestamp: this.currentRfp.submission
      })

      if (this.auctionProgress(this.currentRfp) > this.RfpProgress.ACTIVE_FIRST_BIDS) {
        result.push({
          id: 'initial_bidding_closed',
          type: 'general',
          title: this.isSimpleRfp ? this.$t('activeRFP.notifications.biddingClosed') : this.$t('activeRFP.notifications.initialBiddingClosed'),
          timestamp: this.currentRfp.firstBidDeadline
        })

        result.push({
          id: 'initial_bidding_published',
          type: 'general',
          title: this.isSimpleRfp ? this.$t('activeRFP.notifications.bidsPublished') : this.$t('activeRFP.notifications.initialBidsPublished'),
          timestamp: moment.utc(this.currentRfp.firstBidDeadline).add(1, 'second').toISOString()
        })
      }

      if (!this.isSimpleRfp) {
        if (this.auctionProgress(this.currentRfp) > this.RfpProgress.ACTIVE_FIRST_BIDS_CLOSED) {
          result.push({
            id: 'auction_started',
            type: 'general',
            title: this.isAdvancedRfp ? this.$t('activeRFP.notifications.finalBiddingStarted') : this.$t('activeRFP.notifications.auctionStarted'),
            timestamp: this.isAdvancedRfp ? moment.utc(this.currentRfp.firstBidDeadline).add(2, 'second').toISOString() : this.currentRfp.auctionStart
          })
        }
        if (this.auctionProgress(this.currentRfp) > this.RfpProgress.ACTIVE_SECOND_BIDS_OR_AUCTION) {
          result.push({
            id: 'auction_closed',
            type: 'general',
            title: this.isAdvancedRfp ? this.$t('activeRFP.notifications.finalBiddingClosed') : this.$t('activeRFP.notifications.auctionClosed'),
            timestamp: this.isAdvancedRfp ? this.currentRfp.auctionEnd : this.currentRfp.auctionEnd
          })

          if (this.isAdvancedRfp) {
            result.push({
              id: 'second_publish',
              type: 'general',
              title: this.$t('activeRFP.notifications.finalBidsPublished'),
              timestamp: moment.utc(this.currentRfp.auctionEnd).add(1, 'second').toISOString()
            })
          }
        }
      }

      return result
    }
  },
  methods: {
    ...mapMutations(['toggleNotification']),

    // roundBidToThousands (bid) {
    //   return Math.trunc(bid / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    // },

    setBidDisplayToEuro (bid) {
      return (bid).toLocaleString('de-DE')
    }

  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"
  .header
    display grid
    grid-template-columns 80% auto
    align-items center

  .v-card
    border-radius: 10px;
    background: #fff;
    padding: 30px;

  .v-stepper
    box-shadow: none !important;

  :deep(.v-stepper__step__step)
    height: 15px;
    min-width: 15px;
    width: 15px;
    background-color: $dark-gray !important;

  :deep(.v-stepper__step)
    padding-top 5px
    padding-left 0px
    padding-bottom 0px

    .v-stepper__step__step
      position relative
      top: -15px;

    .stepper-title
      font-weight bold

    &.general
      .stepper-title
        color $icon-gray
        font-size 12px

      .v-stepper__step__step
        background-color: $icon-gray !important;
        top: -10px;

  .notification-wrapper {
    margin-top 20px
    max-height: 750px
    overflow-y: auto
  }

  .open-notif
    justify-self right
    margin 0px
    height 24px
    width 22px

  .icon-display
    font-size 24px
</style>
