<template>
  <v-card>
    <template v-if="this.currentRfp.auctionType !== null">
      <h2 style="display: inline-block">{{ 'activeRFP.linkTabs.Q&A' | t }}</h2>
      <TooltipHolder
        class="info-icon"
        style="vertical-align: top"
        :spanId="$t('tooltips.qaTitleTooltip')"
        :width="160"
        :moveTop="30"
        :icon="'info'"
        :tooltipText="$t('tooltips.qaTitleTooltip')"
        right
        summaryTooltip />
      <v-checkbox
        class="qa-check"
        :disabled="currentRfp.status !== 'DRAFT'"
        :input-value="this.allowQA"
        @change="value => {qaSetTime(value); this.allowQA = !this.allowQA; $emit('qaChanged'); removeMissingMandatoryField('qaStart'); removeMissingMandatoryField('qaEnd')}"
        color="rgb(163, 163, 174)"
        :label="$t('newRFP.biddingType.allowqa')"
        v-if="this.currentRfp.auctionType !== null"></v-checkbox>

      <v-divider class="divider"></v-divider>
      <h2>{{ 'newRFP.biddingType.scheduleTitle' | t }}</h2>

      <div
        class="grid"
        :class="{
          simpleBidGrid: isSimpleRfp,
          advancedBidGrid: isAdvancedRfp,
          eAuctionBidGrid: isEAuction,
          qaAllowed: this.allowQA
        }" >

        <template v-if="this.allowQA">
          <div class="input-grid">
            <section>
              <span class="mandatory">{{ $t('newRFP.biddingType.qaEnd') }}</span>
              <DateField
                :icon="true"
                :class="{missing: missingMandatoryFields.includes('qaEnd') || invalidFields.includes('qaEnd') }"
                :disabled="isDateFieldDisabled('qaEnd')"
                @input="value => {timeConstrain({ field: 'qaEnd', value, type: 'date' }); removeMissingMandatoryField('qaEnd');}"
                :value="splitDateTime({ timestamp: qaEnd, part: 'date' })"
                :placeholder=" 'newRFP.biddingType.selectDatePlaceholder' | t "/>
            </section>
            <section>
              <!-- <span class="mandatory">{{ $t('newRFP.biddingType.qaEnd') | t }}</span> -->
              <TimeInput
                :class="{missing: missingMandatoryFields.includes('qaEnd') || invalidFields.includes('qaEnd')}"
                :disabled="isDateFieldDisabled('qaEnd')"
                @input="value => {timeConstrain({ field: 'qaEnd', value, type: 'time' }); removeMissingMandatoryField('qaEnd');}"
                :value="splitDateTime({ timestamp: qaEnd, part: 'time' })"
                :placeholder=" 'newRFP.biddingType.selectTimePlaceholder' | t " />
            </section>
          </div>
        </template>

        <div v-if="this.allowQA" >
          <section class="arrow" :style=" isEAuction ? 'left: -10px': 'left: -7px'">
            <i class="ic-chevron-right arrows" ></i>
            <i class="ic-chevron-right" ></i>
          </section>
        </div>

        <template v-if="isAdvancedRfp || isEAuction">
          <div class="input-grid">
            <section>
              <div>
                <span>{{ 'newRFP.biddingType.initialBidTitle' | t }}&nbsp;</span>
                <TooltipHolder
                  :icon="'info'"
                  :width="200"
                  :tooltipText="$t('tooltips.biddingRulesProposalDeadlineTooltip', { first: 'First' })"
                  :moveTop="105"
                  :moveLeft="35"
                  :spanId="'tooltipDateAdvanced'"
                  class="tooltipSettings mandatory"
                  right />
              </div>
              <DateField
                :icon="true"
                :class="{missing: missingMandatoryFields.includes('firstBidDeadline') || invalidFields.includes('firstBidDeadline')}"
                :disabled="isDateFieldDisabled('firstBidDeadline')"
                @input="value => {timeConstrain({ field: 'firstBidDeadline', value, type: 'date' }); removeMissingMandatoryField('firstBidDeadline');}"
                :value="splitDateTime({ timestamp: firstBidDeadline, part: 'date' })"
                :placeholder=" 'newRFP.biddingType.selectDatePlaceholder' | t" />
            </section>
            <section>
              <TimeInput
                :class="{missing: missingMandatoryFields.includes('firstBidDeadline') || invalidFields.includes('firstBidDeadline')}"
                :disabled="isDateFieldDisabled('firstBidDeadline')"
                @input="value => {timeConstrain({ field: 'firstBidDeadline', value, type: 'time' }); removeMissingMandatoryField('firstBidDeadline');}"
                :value="splitDateTime({ timestamp: firstBidDeadline, part: 'time' })"
                :placeholder=" 'newRFP.biddingType.selectTimePlaceholder' | t" />
            </section>
          </div>
        </template>

        <div v-if="isEAuction" >
          <section class="arrow" :style=" isEAuction ? 'left: -10px': 'left: -7px'">
            <i class="ic-chevron-right arrows" ></i>
            <i class="ic-chevron-right" ></i>
          </section>
        </div>

        <div v-if="isEAuction" class="input-grid" >
          <section>
            <span class="mandatory">{{ 'newRFP.biddingType.eauctionTitle' | t }}</span>
            <DateField
              :icon="true"
              :class="{missing: missingMandatoryFields.includes('auctionStart') || invalidFields.includes('auctionStart')}"
              :disabled="isDateFieldDisabled('auctionStart')"
              @input="value => {timeConstrain({ field: 'auctionStart', value, type: 'date' }); removeMissingMandatoryField('auctionStart');}"
              :value="splitDateTime({ timestamp: auctionStart, part: 'date' })"
              :placeholder=" 'newRFP.biddingType.selectDatePlaceholder' | t" />
          </section>
          <section>
            <!-- <span class="mandatory">{{ 'newRFP.biddingType.eauctionTitle' | t }}</span> -->
            <TimeInput
              :class="{missing: missingMandatoryFields.includes('auctionStart') || invalidFields.includes('auctionStart')}"
              :disabled="isDateFieldDisabled('auctionStart')"
              @input="value => {timeConstrain({ field: 'auctionStart', value, type: 'time' }); removeMissingMandatoryField('auctionStart');}"
              :value="splitDateTime({ timestamp: auctionStart, part: 'time' })"
              :placeholder=" 'newRFP.biddingType.selectTimePlaceholder' | t" />
          </section>
        </div>

        <div v-if="isEAuction || isAdvancedRfp" >
          <section class="arrow" :style=" isEAuction ? 'left: -10px': 'left: -7px'">
            <i class="ic-chevron-right arrows" ></i>
            <i class="ic-chevron-right" ></i>
          </section>
        </div>

        <div class="input-grid">
          <section>
            <div v-if="isSimpleRfp">
              <span >{{ $t('newRFP.biddingType.initialBidTitle') }}&nbsp;</span>
              <TooltipHolder
                :icon="'info'"
                :width="200"
                :tooltipText="$t('tooltips.biddingRulesProposalDeadlineTooltip')"
                :moveTop="105"
                :moveLeft="35"
                :spanId="'tooltipDateProposal'"
                class="tooltipSettings mandatory"
                right />
            </div>
            <span v-else class="mandatory">{{ isEAuction ? 'newRFP.biddingType.eAuctionEnd' : 'newRFP.biddingType.finalBidTitle' | t }}</span>
            <DateField
              :icon="true"
              :class="{missing: missingMandatoryFields.includes('auctionEnd') || invalidFields.includes('auctionEnd')}"
              :disabled="isDateFieldDisabled('auctionEnd')"
              @input="value => {timeConstrain({ field: 'auctionEnd', value, type: 'date' }); removeMissingMandatoryField('auctionEnd');}"
              :value="splitDateTime({ timestamp: auctionEnd, part: 'date' })"
              :placeholder=" 'newRFP.biddingType.selectDatePlaceholder' | t" />
          </section>
          <section>
            <TimeInput
              :class="{missing: missingMandatoryFields.includes('auctionEnd') || invalidFields.includes('auctionEnd')}"
              :disabled="isDateFieldDisabled('auctionEnd')"
              @input="value => {timeConstrain({ field: 'auctionEnd', value, type: 'time' }); removeMissingMandatoryField('auctionEnd');}"
              :value="splitDateTime({ timestamp: auctionEnd, part: 'time' })"
              :placeholder=" 'newRFP.biddingType.selectTimePlaceholder' | t" />
          </section>
        </div>
      </div>
    </template>

    <div v-if="this.currentRfp.auctionType === null" >
      <p style="margin: 60px 0">{{ 'newRFP.biddingType.selectBiddingBefore' | t}}</p>
    </div>

    <div v-if="invalidFields.includes('qaEnd')">
      <span class="alert">
      {{ alerts.qaEnd }}
      </span>
    </div>
    <div v-if="invalidFields.includes('firstBidDeadline')">
      <span class="alert">
        {{ alerts.firstBidDeadline }}
      </span>
    </div>
    <div v-if="invalidFields.includes('auctionStart')">
      <span class="alert">
        {{ alerts.auctionStart }}
      </span>
    </div>
    <div v-if="invalidFields.includes('auctionEnd')">
      <span class="alert">
        {{ alerts.auctionEnd }}
      </span>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import DateField from '../../../generalComponents/DateField'
import TimeInput from '../../../generalComponents/TimeInput'
import splitDateTime from '../../../../util/splitDateTime'
import moment from 'moment'
import i18next from 'i18next'
import TooltipHolder from '../../../generalComponents/TooltipHolder'

export default {
  name: 'BiddingParametersPanel',
  components: { TimeInput, DateField, TooltipHolder },
  props: {
    auctionParam: {
      required: false,
      type: String,
      default: ''
    }
  },
  watch: {
    /* Watcher that resets the values for the dates and times when the Bidding type is changed */
    auctionParam () {
      this.alerts.qaEnd = ''
      this.alerts.auctionStart = ''
      this.alerts.firstBidDeadline = ''
      this.alerts.auctionEnd = ''
      this.removeInvalidField('qaEnd')
      this.removeInvalidField('firstBidDeadline')
      this.removeInvalidField('auctionStart')
      this.removeInvalidField('auctionEnd')
      this.setDefaultValue(this.auctionParam)
      if ((this.auctionParam === 'E_AUCTION' || this.auctionParam === 'ADVANCED') && !this.currentRfp.auctionSubType) {
        this.updateAuctionSubType('LOOSE')
      } else if (this.auctionParam === 'SIMPLE') {
        this.updateAuctionSubType(null)
      }
    }
  },
  data () {
    return {
      alerts: {
        firstBidDeadline: '',
        auctionStart: '',
        auctionEnd: '',
        qaEnd: ''
      },
      rfpDatesArray: ['firstBidDeadline', 'auctionEnd', 'auctionStart', 'qaEnd'],
      qaEnd: '',
      firstBidDeadline: '',
      auctionStart: '',
      auctionEnd: '',
      allowQA: false,
      isValidDateState: true
    }
  },
  created () {
    if (this.areDatesSet) {
      this.setDefaultValue(this.currentRfp.auctionType || 'SIMPLE')
    } else {
      this.rfpDatesArray.forEach(rfpDateField => {
        this[rfpDateField] = this.currentRfpOrDraft[rfpDateField]
      })
    }
    this.allowQA = this.currentRfp.allowQA
  },
  computed: {
    ...mapState({
      currentRfp: state => state.currentRfp,
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields
    }),
    ...mapGetters(['auctionSubTypeOptions', 'isAdvancedRfp', 'isEAuction', 'isSimpleRfp', 'getValueFromPreviousVersion', 'currentRfpOrDraft', 'isRfpFieldEditable']),

    isDraft () {
      return this.currentRfp.status === 'DRAFT'
    },

    isEditRfp () {
      return this.currentRfp.status !== 'DRAFT'
    },

    canExtendQaDeadline () {
      if (!this.allowQA || !this.isEditRfp) {
        return false
      }

      if (moment().isAfter(moment(this.getValueFromPreviousVersion('firstBidDeadline')))) {
        return false
      }

      return moment().isAfter(moment(this.getValueFromPreviousVersion('qaEnd')))
    },

    areDatesSet () {
      if (this.rfpDatesArray.map(rfpDateField => this.currentRfp[rfpDateField] === null).includes(false)) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapMutations(['updateCurrentRfp', 'updateCurrentRfpDate', 'updateCurrentRfpTime', 'removeMissingMandatoryField', 'addInvalidField', 'removeInvalidField', 'updateCurrentRfpDateTime']),
    splitDateTime,

    updateAuctionSubType (value) {
      this.updateCurrentRfp({ field: 'auctionSubType', value })
      this.removeMissingMandatoryField('auctionSubType')
    },

    isDateFieldDisabled (field) {
      if (!this.isEditRfp) {
        return false
      }

      if (field === 'qaEnd' && this.canExtendQaDeadline) {
        return false
      }

      if (moment().isAfter(moment(this.getValueFromPreviousVersion(field)))) {
        return true
      }

      return false
    },

    setDefaultValue (biddingType) {
      const actualnow = moment()
      const remainder = (10 - (actualnow.minute() % 10)) === 0 ? 10 : 10 - (actualnow.minute() % 10)
      switch (biddingType) {
        case 'E_AUCTION':
          this.firstBidDeadline = moment().add(2, 'd').add(remainder, 'm').seconds(0).milliseconds(0)
          this.auctionStart = moment().add(3, 'd').add(remainder, 'm').seconds(0).milliseconds(0)
          this.auctionEnd = moment().add(3, 'd').add(1, 'h').add(remainder, 'm').seconds(0).milliseconds(0)
          this.updateCurrentRfpDateTime({ field: 'firstBidDeadline', value: this.firstBidDeadline })
          this.updateCurrentRfpDateTime({ field: 'auctionStart', value: this.auctionStart })
          this.updateCurrentRfpDateTime({ field: 'auctionEnd', value: this.auctionEnd })
          break
        case 'ADVANCED':
          this.firstBidDeadline = moment().add(2, 'd').add(remainder, 'm').seconds(0).milliseconds(0)
          this.auctionEnd = moment().add(3, 'd').add(remainder, 'm').seconds(0).milliseconds(0)
          this.updateCurrentRfpDateTime({ field: 'firstBidDeadline', value: this.firstBidDeadline })
          this.updateCurrentRfpDateTime({ field: 'auctionEnd', value: this.auctionEnd })
          break
        case 'SIMPLE':
          this.auctionEnd = moment().add(2, 'd').add(remainder, 'm').seconds(0).milliseconds(0)
          this.updateCurrentRfpDateTime({ field: 'auctionEnd', value: this.auctionEnd })
          break
      }
      this.qaEnd = moment().add(1, 'd').add(remainder, 'm').seconds(0).milliseconds(0)
      this.updateCurrentRfpDateTime({ field: 'qaEnd', value: this.qaEnd })
    },

    qaEndEarliestDate () {
      let highestDate = moment().add(1, 'd').format() // minimum: current date plus 1 day
      let alert = i18next.t('newRFP.biddingType.qaAlert')

      if (this.isEditRfp) {
        if (moment(this.getValueFromPreviousVersion('qaEnd')).isBefore(moment())) {
          // previous Q&A deadline already past, new deadline must be  at least one day from now
          highestDate = moment().add(1, 'd').format() // current date plus 1 day
          alert = i18next.t('newRFP.biddingType.qaAlert')
        } else if (moment(this.getValueFromPreviousVersion('qaEnd')).isAfter(moment().add(1, 'd'))) {
          // previous Q&A deadline is more than 1 day in the future, new deadline must be at least one day from now
          highestDate = moment().add(1, 'd').format() // current date plus 1 day
          alert = i18next.t('newRFP.biddingType.qaAlert')
        } else {
          // previous Q&A deadline is less than 1 day in the future, new deadline cannot be before previous Q&A deadline
          highestDate = this.getValueFromPreviousVersion('qaEnd')
          alert = i18next.t('newRFP.biddingType.qaDeadlineMustBeAfterPreviousValue')
        }
      }

      return { compareTime: highestDate, alert }
    },
    firstBidDeadLineEarliestDate () {
      let highestDate = moment().add(1, 'd').format() // minimum: current date plus 1 day
      let alert = i18next.t('newRFP.biddingType.firstBidDeadlineAlert')

      if (this.isEditRfp) {
        if (moment(this.getValueFromPreviousVersion('firstBidDeadline')).isBefore(moment())) {
          // previous first bid deadline already past, edit it should be not possible
          // do nothing
        } else if (moment(this.getValueFromPreviousVersion('firstBidDeadline')).isAfter(moment().add(1, 'd'))) {
          // previous first bid deadline is more than 1 day in the future, new deadline must be at least one day from now
          highestDate = moment().add(1, 'd').format() // current date plus 1 day
          alert = i18next.t('newRFP.biddingType.firstBidDeadlineAlert')
        } else {
          // previous first bid deadline is less than 1 day in the future, new deadline cannot be before previous Q&A deadline
          highestDate = this.getValueFromPreviousVersion('firstBidDeadline')
          alert = i18next.t('newRFP.biddingType.firstBidDeadlineMustBeAfterPreviousValue')
        }
      }

      if (this.allowQA && moment(this.qaEnd).add(1, 'd').isAfter(highestDate)) {
        // New first bid deadline must be one day after Q&A end
        highestDate = moment(this.qaEnd).add(1, 'd').format()
        alert = i18next.t('newRFP.biddingType.firstBidDeadlineOnQAAlert')
      }

      return { compareTime: highestDate, alert }
    },

    auctionStartEarliestDate () {
      let highestDate = moment(this.firstBidDeadline).add(1, 'h').format() // minimum: first bid deadline plus 1 hour
      let alert = i18next.t('newRFP.biddingType.auctionStartAlert')

      if (this.isEditRfp) {
        if (moment(this.getValueFromPreviousVersion('auctionStart')).isBefore(moment())) {
          // previous auction start already past, edit it should be not possible
          // do nothing
        } else if (moment(this.getValueFromPreviousVersion('auctionStart')).isAfter(moment().add(1, 'd'))) {
          // previous auction start is more than 1 day in the future, new deadline must be at least one day from now
          highestDate = moment().add(1, 'd').format()
          alert = i18next.t('newRFP.biddingType.auctionStartDayAfterAlert')
        } else {
          // previous auction start is less than 1 day in the future, new deadline cannot be before previous auction start
          highestDate = this.getValueFromPreviousVersion('auctionStart')
          alert = i18next.t('newRFP.biddingType.auctionStartMustBeAfterPreviousValue')
        }
      }

      if (moment(this.firstBidDeadline).add(1, 'h').isAfter(highestDate)) {
        // new auction start must be one hour after first bid deadline at least
        highestDate = moment(this.firstBidDeadline).add(1, 'h').format()
        alert = i18next.t('newRFP.biddingType.auctionStartAlert')
      }

      return { compareTime: highestDate, alert }
    },

    auctionEndEarliestDate () {
      let highestDate = moment().add(30, 'm').format() // minimum: current day plus 30 min
      let alert = i18next.t('newRFP.biddingType.auctionEndAlertSimple')
      // if (this.isAdvancedRfp) {
      //   highestDate = moment(this.firstBidDeadline).add(1, 'h').format()
      //   alert = i18next.t('newRFP.biddingType.auctionEndAlertAdvance')
      // } else if (this.isEAuction) {
      //   highestDate = moment(this.auctionStart).add(30, 'm').format()
      //   alert = i18next.t('newRFP.biddingType.auctionEndAlertEAuction')
      // }

      if (this.isEditRfp) {
        if (moment(this.getValueFromPreviousVersion('auctionEnd')).isBefore(moment())) {
          // previous auction start already past, edit it should be not possible
          // do nothing
        } else if (moment(this.getValueFromPreviousVersion('auctionEnd')).isAfter(moment().add(1, 'd'))) {
          // previous auction start is more than 1 day in the future, new deadline must be at least one day from now
          highestDate = moment().add(1, 'd').format()
          if (this.isAdvancedRfp) {
            alert = i18next.t('newRFP.biddingType.auctionEndDayAfterAlertAdvance')
          } else if (this.isEAuction) {
            alert = i18next.t('newRFP.biddingType.auctionEndDayAfterAlert')
          } else {
            alert = i18next.t('newRFP.biddingType.firstBidDeadlineAlert')
          }
        } else {
          // previous auction start is less than 1 day in the future, new deadline cannot be before previous auction end value
          highestDate = this.getValueFromPreviousVersion('auctionEnd')
          if (this.isAdvancedRfp) {
            alert = i18next.t('newRFP.biddingType.auctionEndMustBeAfterPreviousValueAdvance')
          } else if (this.isEAuction) {
            alert = i18next.t('newRFP.biddingType.auctionEndMustBeAfterPreviousValueEAuction')
          } else {
            alert = i18next.t('newRFP.biddingType.auctionEndMustBeAfterPreviousValueSimple')
          }
        }
      }

      if (this.isSimpleRfp && moment().add(1, 'h').isAfter(highestDate)) {
        highestDate = moment().add(1, 'h').format()
        alert = i18next.t('newRFP.biddingType.auctionEndAlertSimple')
      } else if (this.isAdvancedRfp && moment(this.firstBidDeadline).add(1, 'h').isAfter(highestDate)) {
        highestDate = moment(this.firstBidDeadline).add(1, 'h').format()
        alert = i18next.t('newRFP.biddingType.auctionEndAlertAdvance')
      } else if (this.isEAuction && moment(this.auctionStart).add(30, 'm').isAfter(highestDate)) {
        highestDate = moment(this.auctionStart).add(30, 'm').format()
        alert = i18next.t('newRFP.biddingType.auctionEndAlertEAuction')
      }

      if (this.allowQA && moment(this.qaEnd).add(1, 'd').isAfter(highestDate)) {
        // New auction end deadline must be one day after Q&A end
        highestDate = moment(this.qaEnd).add(1, 'd').format()
        alert = i18next.t('newRFP.biddingType.SimpleOnQAAlert')
      }

      return { compareTime: highestDate, alert }
    },

    timeSelectoreCompare (field) {
      switch (field) {
        case 'qaEnd':
          return this.qaEndEarliestDate()
        case 'firstBidDeadline': // First Bid Deadline used in Advanced and first bid used eAuction
          return this.firstBidDeadLineEarliestDate()
        case 'auctionStart': // eAuction Start used in eAuction
          return this.auctionStartEarliestDate()
        case 'auctionEnd': // Bid for simple bid, Second Bid for advanced and eAuction end for eAcution
          return this.auctionEndEarliestDate()
        default:
          return { compareTime: moment().add(1, 'h').format(), alert: 'The value must be at least 1 hour from now.' }
      }
    },

    async qaSetTime (qaStatus) {
      if (!qaStatus) {
        this.removeInvalidField('qaEnd')
        this.alerts.qaEnd = ''
      }
      await new Promise((resolve) => {
        setTimeout(function (currentRfp, revalidate, updateCurrentRfpDateTime, auctionEnd, firstBidDeadline) {
          if ((currentRfp.auctionType === 'ADVANCED' || currentRfp.auctionType === 'E_AUCTION') && revalidate('firstBidDeadline')) {
            updateCurrentRfpDateTime({ field: 'firstBidDeadline', value: firstBidDeadline })
          } else if (revalidate('auctionEnd')) {
            updateCurrentRfpDateTime({ field: 'auctionEnd', value: auctionEnd })
          }
          resolve()
        }, 500, this.currentRfp, this.revalidate, this.updateCurrentRfpDateTime, this.auctionEnd, this.firstBidDeadline)
      })
      if (this.isValidDateState) {
        this.updateCurrentRfp({ field: 'allowQA', value: this.allowQA })
      }
    },

    timeConstrain ({ field, value, type }) {
      this.alerts[field] = ''
      // const compareDate = this.timeSelectoreCompare(field).compareTime

      let newRFPDate = ''
      const partSel = type === 'time' ? 'date' : 'time'

      if (type === 'time') {
        newRFPDate = this[field] !== null ? moment(`${splitDateTime({ timestamp: this[field], part: partSel })} ${value}`, 'DD.MM.YYYY HH:mm').format() : moment(`${splitDateTime({ timestamp: moment(), part: partSel })} ${value}`, 'DD.MM.YYYY HH:mm').format()
      } else {
        newRFPDate = moment(`${value} ${splitDateTime({ timestamp: this[field], part: partSel })}`, 'DD.MM.YYYY HH:mm').format()
      }
      this[field] = newRFPDate
      let dateValidationArr = []
      if (!this.isDateFieldDisabled('auctionEnd')) {
        dateValidationArr.splice(0, 0, 'auctionEnd')
      }
      if (this.isEAuction && !this.isDateFieldDisabled('auctionStart')) {
        dateValidationArr.splice(0, 0, 'auctionStart')
      }
      if ((this.isEAuction || this.isAdvancedRfp) && !this.isDateFieldDisabled('firstBidDeadline')) {
        dateValidationArr.splice(0, 0, 'firstBidDeadline')
      }

      if (this.allowQA && !this.isDateFieldDisabled('qaEnd')) {
        if (this.canExtendQaDeadline && moment(this.qaEnd).isSame(moment(this.getValueFromPreviousVersion('qaEnd')))) {
          // do nothing when user do not extend Q&A deadline
        } else {
          dateValidationArr.splice(0, 0, 'qaEnd')
        }
      }
      let dateStateArr = dateValidationArr.map(dateValidator => {
        return this.revalidate(dateValidator)
      })
      if (!dateStateArr.includes(false)) {
        dateValidationArr.forEach(dateField => {
          this.updateCurrentRfpDateTime({ field: dateField, value: this[dateField] })
        })
        this.removeInvalidField(field)
        this.isValidDateState = true
        this.updateCurrentRfp({ field: 'allowQA', value: this.allowQA })
      } else {
        this.isValidDateState = false
      }
    },

    revalidate (field) {
      this.alerts[field] = ''
      const compareDate = this.timeSelectoreCompare(field).compareTime
      const currentValue = this[field]
      if (!moment(currentValue).isSameOrAfter(compareDate)) {
        this.alerts[field] = this.timeSelectoreCompare(field).alert
        this.addInvalidField(field)
        this.isValidDateState = false
        return false
      } else {
        this.removeInvalidField(field)
        this.isValidDateState = true
        return true
      }
    },

    biddingText (option) {
      if (option.value === 'STRICT') {
        return this.$t('newRFP.biddingType.strictTypeInfo' + (this.isAdvancedRfp ? 'Advanced' : 'EAuction'))
      } else {
        return this.$t('newRFP.biddingType.looseTypeInfo' + (this.isAdvancedRfp ? 'Advanced' : 'EAuction'))
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  .tooltipSettings >>> .v-icon
    color $icon-gray !important
    font-size 15px !important

  .v-card
    border-radius 10px
    padding 26px

    h2
      margin-bottom 20px

  .grid
    display grid
    grid-column-gap 15px

    &.simpleBidGrid
      grid-template-columns 1fr
      margin 0 65% 0 0
      &.qaAllowed
        grid-template-columns 1fr .1fr 1fr
        margin 0 23% 0 0

    &.advancedBidGrid
      grid-template-columns 1fr .1fr 1fr
      margin 0 23% 0 0
      &.qaAllowed
        grid-template-columns 1fr .1fr 1fr .1fr 1fr
        margin 0

    &.eAuctionBidGrid
      grid-template-columns 1fr .1fr 1fr .1fr 1fr
      &.qaAllowed
        grid-template-columns 1fr .1fr 1fr .1fr 1fr .1fr 1fr

  span
    color $gray40
    padding-left 12px

  .qa-sub
    color $gray40
    padding 0 !important

  .qa-check
    padding-top 0 !important
    margin-top 5px !important

  >>> .v-text-field .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important
    margin-bottom 0

  >>> .v-input--is-disabled .v-input__slot
    background lightgray !important

  >>> .v-input.qa-check.v-input--is-disabled .v-input__slot
    background #fff !important

  .input-grid {
    display grid
    padding 15px
    border-radius 5px
    grid-row-gap 8px
    grid-template-rows repeat(2, auto)
    background: $light-gray
  }

  .divider
    margin 25px 0

  .alert
    color $red

  >>> .v-text-field__details {
    display: none
  }

  >>> .v-icon
    font-size: 20px !important
    color: $dark-gray !important

  >>> .theme--light.v-messages
    display none

  .arrow
    color $dark-gray !important
    font-size 25px
    position relative
    top 43%
    .arrows
      position relative
      left 19px

  .bidding-subtype
    display grid
    grid-template-columns 2fr 2fr
    grid-column-gap 10px
    p
      margin-bottom 0
    .label-display
      display grid
      grid-template-rows 20px 1fr

  >>> .v-text-field.qaTimefield .v-input__slot
    border-top-left-radius 0px !important
    border-bottom-left-radius 0px !important

  >>> .v-text-field.qaDatefield .v-input__slot
    border-top-right-radius 0px !important
    border-bottom-right-radius 0px !important
    border-right 0px

  .v-radio
    align-items: end !important

  .v-input--selection-controls
    margin-top 0px
    padding-top 20px
    border 2px solid #fff
    &.missing
      border 2px solid $red !important
      border-radius 10px !important
</style>
