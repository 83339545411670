<template>
  <div class="comparisonView">
    <div style="z-index: 1; margin-bottom: 3px">
      <div v-if="currentPage !== 0" class="slider-nav elevation-10" @click="moveBack">
        <v-icon>chevron_left</v-icon>
      </div>
    </div>

    <Carousel :perPage="numberOfCards" style="overflow-x: hidden;" ref="carousel" :paginationEnabled="false" @pageChange="updatePage" :mouse-drag="false">
      <Slide v-for="relation in sortedLawFirmRelations" :key="relation.lawFirm.id">
        <LawFirmRatingCard
          @toggleSection="value => toggleHideShowSection(value)"
          @updateMaxHeight="name => updateMaxHeight(name)"
          :cardInfo="relation"
          :teamMaxHeight="teamHeight"
          :liabilityMaxHeight="liabilityHeight"
          :showCustom1="showCustom1"
          :showCustom2="showCustom2"
          :showCustom3="showCustom3"
          :showStrategy="showStrategy"
          :credentialsMaxHeight="credentialsHeight"
          :pitch_documentMaxHeight="pitch_documentHeight"
          :showTeam="showTeam"
          :showLiability="showLiability"
          :showCredentials="showCredentials"
          :showPitch_document="showPitch_document" />
      </Slide>
    </Carousel>

    <div style="z-index: 1; margin-bottom: 3px">
      <div v-if="currentPage !== maxPage - 1" class="slider-nav elevation-10" @click="moveForward">
        <v-icon>chevron_right</v-icon>
      </div>
    </div>

  </div>
</template>

<script>
import LawFirmRatingCard from './LawFirmRatingCard'
import LawFirmRatingMixin from './LawFirmRatingMixin'
import { Carousel, Slide } from 'vue-carousel'
import { mapState } from 'vuex'

export default {
  name: 'LawFirmComparisonRate',
  mixins: [ LawFirmRatingMixin ],
  components: { LawFirmRatingCard, Carousel, Slide },
  data () {
    return {
      currentPage: 0,
      maxPage: 1,
      teamHeight: 0,
      liabilityHeight: 0,
      credentialsHeight: 0,
      pitch_documentHeight: 0,
      showTeam: true,
      showLiability: true,
      showCredentials: true,
      showPitch_document: true,
      showStrategy: true,
      showCustom1: true,
      showCustom2: true,
      showCustom3: true
    }
  },
  mounted () {
    this.setMaxPage()
    if (this.currentRfp.terms.qualityScoreCriteria && this.currentRfp.terms.qualityScoreCriteria.length > 0) {
      this.currentRfp.terms.qualityScoreCriteria.forEach(section => {
        if (section === 'INTERVIEW' || section === 'QA') {
          return
        }
        this[`${section.toLowerCase()}Height`] = this.findMaxSectionHeight(section.toLowerCase())
        // this.$refs[`${section.toLowerCase()}Description`].setAttribute('style', `height: ${this[section.toLowerCase() + 'Height'] + 10}px`)
      })
    }
  },
  computed: {
    ...mapState({
      currentRfp: state => state.currentRfp,
      lawFirms: state => state.lawFirms,
      openLawFirmRelation: state => state.openLawFirmRelation
    }),

    sortedLawFirmRelations () {
      return [...this.currentRfp.lawFirmRelations].sort((a, b) => { return a.lawFirm.name.toLowerCase() < b.lawFirm.name.toLowerCase() ? -1 : 1 })
    },

    numberOfCards () {
      return 3 // this.sortedLawFirmRelations.length > 3 ? 2.85 : 3
    }
  },
  methods: {
    async updateMaxHeight (section) {
      // await this.$nextTick()
      // this[`${section}Height`] = this.findMaxSectionHeight(section)
    },

    updatePage () {
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    },

    moveBack () {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    },

    moveForward () {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
      this.currentPage = this.$refs.carousel.currentPage
      this.maxPage = this.$refs.carousel.pageCount
    },

    setMaxPage () {
      this.maxPage = this.$refs.carousel.pageCount
    },

    findMaxSectionHeight (section) {
      const arrayValues = this.$refs.carousel.$children.map(cards => cards.$children[0].$refs[section] ? cards.$children[0].$refs[section].clientHeight : 0)
      console.log('----- arrayValues', section, arrayValues)
      return Math.max(...arrayValues)
    },

    toggleHideShowSection (section) {
      this[`show${section}`] = !this[`show${section}`]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .comparisonView
    padding-top 21px
    display grid
    grid-template-columns repeat(3, auto)

  .subtitle
    display grid
    grid-template-columns auto auto
    margin-top 10px
    height 40px
    h3
      line-height 1
      align-self center

    .v-btn
      justify-self end
      margin 0

  .qualityCriteria
    display grid
    grid-template-rows 61.25px repeat(2, auto)

  .slider-nav
    height 100%
    display flex
    align-items center
    justify-content  center
    background #fff
    border-radius 5px

  .arrowUp
    transform: rotate(270deg)

  .arrowDown
    transform: rotate(90deg)

</style>
