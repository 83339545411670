<template>
  <div class="main">
    <Tabs style="grid-column-start: 1; grid-column-end: 3; margin-bottom: 10px; grid-template-columns: 1fr 1fr 1fr 1fr 1fr;">
      <a
        v-for="subject in subjects"
        :key="subject.key"
        :class="{active: subject.key === currentSubject.key, invalid: subjectIsInvalid(subject.key)}"
        @click="setCurrentSubjectUser(subject)">
        <TooltipHolder
          :text="subject.translatedLabel"
          :tooltipText="subject.translatedLabel"
          :tableDataWidth="227"
          :spanId="subject.key"
          :moveLeft="-30"
          tableData  />
        </a>
    </Tabs>

    <Sidebar
      :components-array="components"
      :options-array="options"
      :alertOffset="!isPreviewView"
      :terms="isPreviewView"
    />

    <div>
      <Section
        v-for="section in currentLayout"
        :ref="section.key"
        :key="section.key"
        :subject="currentSubject"
        :section="section"
        :update-mutation="updateMutation"
        :update-list-item-mutation="updateListItemMutation"
        :add-list-item-mutation="addListItemMutation"
        :root-object="root"
        :disabled="!isRfpFieldEditable()" />

      <div style="margin-bottom: 40vh">
        <h1 id="documentUpload" ref="documentUpload" style="margin-bottom: 0.5em">{{ 'newRFP.scopeDefinition.generatorDocuments' | t }}</h1>
        <DocumentUpload
          type="SCOPE_GENERATOR"
          @deleteFile="value => deleteDocuments(value)"
          :disabled="!isRfpFieldEditable()"
          :documents="currentRfp.attachments"
          :deleteIcon="currentRfp.status === 'DRAFT'"
          :subject="currentSubject.key" />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '../SideBar'
import Section from './Section'
import Tabs from '../Tabs'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import dotProp from 'dot-prop'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import { validateMandatoryFieldsOnSubject } from '../../../vuex/validation/constraints'
import DocumentUpload from '../DocumentUpload'

export default {
  name: 'DynamicInputPage',
  components: { DocumentUpload, Tabs, Section, Sidebar, TooltipHolder },
  props: {
    root: {
      type: Object,
      required: true
    },
    definitions: {
      type: Object,
      required: true
    },
    subjects: {
      type: Array,
      required: true
    },
    updateMutation: {
      type: String,
      required: true
    },
    updateListItemMutation: {
      type: String,
      required: true
    },
    addListItemMutation: {
      type: String,
      required: true
    },
    /** map of section keys to icon names in the icon font for display in the sidebar */
    iconMappings: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      components: [],
      refVal: null
    }
  },
  created () {
    this.setCurrentSubjectUser(this.subjects[0])
  },
  computed: {
    ...mapGetters(['currentRfpOrDraft', 'objectIsEmpty', 'isRfpFieldEditable']),
    ...mapState({
      currentSubject: state => state.currentSubject,
      constraintViolations: state => state.validation.constraintViolations,
      mandatoryViolations: state => state.validation.mandatoryViolations,
      internalViolations: state => state.validation.internalViolations,
      currentRfp () {
        return this.currentRfpOrDraft
      }
    }),

    isPreviewView () {
      return this.$route.name === 'scopeGeneratorLayoutPreview'
    },

    currentLayout () {
      return this.definitions[this.currentSubject.key].sections.filter(section => section.panels && section.panels.length)
    },

    options () {
      const result = []

      this.currentLayout.forEach((section, index) => {
        const mainItem = {
          name: this.$t(`sections.${section.key}`),
          idRef: section.key,
          status: index === 0,
          classStatus: this.sectionIsInvalid(section) ? 'invalid' : (this.sectionIsCompleted(section) ? 'finished' : false),
          icon: this.sectionIsInvalid(section) ? 'error' : (this.sectionIsCompleted(section) ? 'check_circle' : (this.iconMappings[section.key] || 'info')),
          top: 85,
          subItemsIdRefs: []
        }

        result.push(mainItem)

        section.panels.filter(panel => panel.translatedLabel).forEach(panel => {
          result.push({
            name: panel.translatedLabel,
            idRef: panel.key,
            status: false,
            isSubField: true,
            top: 85,
            classStatus: this.panelIsInvalid(section, panel) ? 'invalid' : ''
          })
          mainItem.subItemsIdRefs.push(panel.key)
        })
      })

      result.push({
        name: this.$t('newRFP.scopeDefinition.generatorDocuments'),
        idRef: 'documentUpload',
        status: false,
        icon: 'ic-folder',
        top: 85,
        subItemsIdRefs: []
      })

      return result
    }
  },
  mounted () {
    const result = []

    for (const option of this.options) {
      if (this.$refs[option.idRef] && (this.$refs[option.idRef][0] || this.$refs[option.idRef].id === option.idRef)) {
        if (this.$refs[option.idRef].id === option.idRef) {
          result.push(this.$refs[option.idRef])
        } else {
          this.$refs[option.idRef][0].$el.refVal = option.idRef
          result.push(this.$refs[option.idRef][0].$el)
        }
        for (const subIdRef of option.subItemsIdRefs) {
          if (this.$refs[option.idRef][0].$refs[subIdRef] && this.$refs[option.idRef][0].$refs[subIdRef][0]) {
            this.$refs[option.idRef][0].$refs[subIdRef][0].$el.refVal = subIdRef
            result.push(this.$refs[option.idRef][0].$refs[subIdRef][0].$el)
          }
        }
      }
    }

    this.components = result // this.options.filter(option => this.$refs[option.idRef] && this.$refs[option.idRef][0]).map(option => this.$refs[option.idRef][0].$el)
  },
  methods: {
    ...mapActions(['deleteFile']),
    ...mapMutations(['setCurrentSubjectUser']),

    sectionIsInvalid (section) {
      const path = `${this.currentSubject.key}.${section.key}`

      const constraintViolations = dotProp.get(this.constraintViolations, path)
      const mandatoryViolations = dotProp.get(this.mandatoryViolations, path)
      const internalViolations = dotProp.get(this.internalViolations, path)

      return (constraintViolations && !this.objectIsEmpty(constraintViolations)) ||
        (mandatoryViolations && !this.objectIsEmpty(mandatoryViolations)) ||
        (internalViolations && !this.objectIsEmpty(internalViolations))
    },

    sectionIsCompleted (section) {
      const validationResult = validateMandatoryFieldsOnSubject(this.currentRfp.scopeGeneratorLayoutsBySubject[this.currentSubject.key], this.currentSubject.key, this.currentRfp)

      const mandatoryViolations = validationResult[section.key]
      return (!mandatoryViolations || this.objectIsEmpty(mandatoryViolations))
    },

    panelIsInvalid (section, panel) {
      const path = `${this.currentSubject.key}.${section.key}.${panel.key}`
      const mandatoryViolations = dotProp.get(this.mandatoryViolations, path)
      return !this.objectIsEmpty(mandatoryViolations)
    },

    subjectIsInvalid (subjectKey) {
      return !this.objectIsEmpty(this.constraintViolations[subjectKey]) || !this.objectIsEmpty(this.mandatoryViolations[subjectKey])
    },

    deleteDocuments (event) {
      this.deleteFile({ rfpId: this.currentRfp.id, fileId: event.id })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .main
    display grid
    grid-template-columns 200px 1fr
    grid-column-gap 20px
    margin-top 1.5em

  h1,
  >>> h1
    font-size 28px
</style>
