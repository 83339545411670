<template>
  <div class="tooltip">
    <div>
      <slot></slot>
    </div>

    <span
      v-if="!disabled"
      class="tooltipText default"
      :style="style">
      <span v-html="tooltipText"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BetterTooltip',
  props: {
    // The text shown in the tooltip, if this prop isn't passed through the parent the text 'prop' will be shown in the tooltip
    tooltipText: {
      required: false,
      type: String
    },
    disabled: Boolean,
    top: String,
    width: String,
    left: String,
    absolute: Boolean
  },
  computed: {
    style () {
      const result = []

      if (this.top) {
        result.push(`top: ${this.top};`)
      }
      if (this.width) {
        result.push(`width: ${this.width};`)
      }
      if (this.left) {
        result.push(`left: ${this.left};`)
      }
      if (this.absolute) {
        result.push('position: absolute;')
      }

      return result.join('')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .tooltip
    position relative
    display inline-block

  .tooltipText
    visibility hidden
    background #fff
    color rgb(78, 78, 78)
    padding 5px
    z-index 1000
    position absolute
    opacity 0
    border 1px solid rgb(78, 78, 78)
    transition opacity 0.3s
    border-radius 4px
    width auto
    white-space initial
    text-align center
    &.default
      text-align center
      top -110%
      left 0%

    &.right
      top 0%
      left 120%

    &.bottom
      top 100%
      left 0%

    &.left
      top 0%
      left -105%

    &.tableData
      bottom 105%
      left -30%

    &.popUp
      width 230px
      top -215%

  .tooltip .tooltipText::after
    content ""
    position absolute
    border-width 5px
    border-style solid

  .tooltipText.default::after
    border-color #555 transparent transparent transparent
    margin-left -5px
    top: 100%
    left 50%

  .tooltipText.right::after
    border-color: transparent #555 transparent transparent
    margin-top: -5px
    top: 50%
    right: 100%

  .tooltipText.summaryTooltip::after
    border-color: transparent #555 transparent transparent
    margin-top: -5px
    top: 26px
    right: 100%

  .tooltipText.bottom::after
    border-color: transparent transparent #555 transparent
    margin-left: -5px
    bottom: 100%
    left: 10%

  .tooltipText.left::after
    border-color: transparent transparent transparent #555
    margin-top: -5px;
    top: 50%
    left: 100%

  .tooltipText.tableData::after
    border-color #555 transparent transparent transparent
    margin-left -5px
    top: 100%
    left 25%

  .tooltipText.fixed-arrow.tableData::after
    left 40px

  .tooltip:hover .tooltipText
    visibility visible
    opacity 1

  .bold
    font-weight bold

  .name-holder
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
    max-width 120px
    &.changeHeight
      height 24px

  .breakdown
    display grid
    align-items center
    grid-column-gap 5px
    margin 5px 0
    &.showPercentaje
      grid-template-columns 15px 1fr 0.3fr 0.3fr
    &.noPercentaje
      grid-template-columns 15px 1fr 0.5fr

  .dot
    width 15px
    height 15px
    border-radius 50%
    display inline-block
    // background-color #ababb5

  .image-size
    height 24px
    width auto
    display flex

  .rfp-title
    font-family 'Roboto', sans-serif !important
    height 30px
</style>
