<template>
  <div class="main-area">
    <DecisionCorporate @changeCardView="$emit('changeCardView')" v-if="userIsCorporate"></DecisionCorporate>
    <DecisionLawfirm v-else></DecisionLawfirm>
  </div>
</template>

<script>
import DecisionCorporate from './DecisionCorporate'
import DecisionLawfirm from './DecisionLawfirm'
import { mapGetters } from 'vuex'

export default {
  name: 'Decision',
  components: {
    DecisionCorporate,
    DecisionLawfirm
  },
  computed: {
    ...mapGetters(['userIsCorporate'])
  }
}
</script>

<style scoped lang="stylus">

</style>
