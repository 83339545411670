<template>
  <div>
    <div class="breadcrumbs">
      <span><router-link :to="{ name: 'dashboardRfps' }">{{ 'settings.breadcrumbs.dashboard' | t }}</router-link></span>
      <span> / </span>
      <span class="project-name">{{ 'settings.breadcrumbs.settings' | t }}</span>
    </div>

    <div class="main">
      <div class="side-nav">
        <div>
          <router-link :to="{ name: 'profile' }" :class="$route.name === 'profile' ? 'active elevation-2' : ''">
            <v-icon>account_circle</v-icon>&nbsp;<span>{{ 'settings.navigation.profile' | t }}</span>
          </router-link>
        </div>
        <div v-if="userIsOrganizationAdmin && userIsCorporate">
          <router-link :to="{ name: 'organizationConfig' }" :class="$route.name === 'organizationConfig' ? 'active elevation-2' : ''"><v-icon>settings</v-icon>&nbsp;<span>{{ 'settings.navigation.organizationConfig' | t }}</span></router-link>
        </div>
        <div v-if="userIsAdmin || userIsOrganizationAdmin">
          <router-link :to="{ name: 'userManagement' }" :class="$route.name === 'userManagement' ? 'active elevation-2' : ''"><v-icon>group</v-icon>&nbsp;<span>{{ 'settings.navigation.users' | t }}</span></router-link>
        </div>
        <div v-if="!userIsAdmin">
          <router-link :to="{ name: 'legalDocuments' }" :class="$route.name === 'legalDocuments' ? 'active elevation-2' : ''"><v-icon>ic-document</v-icon>&nbsp;<span>{{ 'settings.navigation.legalDocuments' | t }}</span></router-link>
        </div>
      </div>
      <div class="content">
        <UserManagement v-if="$route.name === 'userManagement'" />
        <OrganizationConfig v-if="$route.name === 'organizationConfig'" />
        <Profile v-if="$route.name === 'profile'" />
        <LegalDocuments v-if="$route.name === 'legalDocuments'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import UserManagement from './components/UserManagement'
import Profile from './components/Profile'
import OrganizationConfig from './components/OrganizationConfig'
import LegalDocuments from './components/LegalDocuments'

export default {
  name: 'Settings',
  components: { Profile, UserManagement, LegalDocuments, OrganizationConfig },
  computed: {
    ...mapGetters(['userIsAdmin', 'userIsOrganizationAdmin', 'userIsCorporate']),
    ...mapState({
      currentRfp: state => state.currentRfp
    })
  },
  methods: {
    ...mapMutations(['updateCurrentRfp'])
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"

  h1
    display inline-block
    margin-bottom 1.5em

  .info-icon
    font-size 20px
    margin-left 5px

  .main
    display grid
    grid-template-columns 200px 1fr
    grid-column-gap 20px
    margin-top 1.5em

    .side-nav
      a
        display inline-block
        color gray
        font-weight bold
        width 100%
        padding 5px 10px
        border-radius 10px
        text-decoration none

        span
          color gray
          font-weight bold
          font-size 14px

        .v-icon
          font-size 16px
          color gray

        &.active
          color $dark-gray
          background #ffffff
          text-decoration none

          span
            text-decoration underline
          .v-icon
            color $dark-gray !important

    .content
      h2
        margin-top 1.5em
        margin-bottom 0.5em
      h2:first-child
        margin-top 0
</style>
