<template>
  <v-card class="carrusel-card">
    <div class="main-title" >
      <h3>
        <TooltipHolder
          :text="cardInfo.lawFirm.name"
          :tooltipText="cardInfo.lawFirm.name"
          :tableDataWidth="250"
          :moveLeft="-30"
          tableData
          width="max-content"
          changeHeight
          bold />
    </h3>

      <v-avatar v-if="relationRated(cardInfo) && cardInfo.proposalSubmitted" size="30" class="rating">
        <span style="color: #fff;">{{ parseFloat(cardInfo.proposalRating).toFixed(1) }}</span>
      </v-avatar>
      <span v-else-if="cardInfo.proposalSubmitted ">
      </span>
      <!-- <span v-else-if="cardInfo.proposalSubmitted" class="lf-action">{{ 'activeRFP.lawFirmRating.ratingPending' | t }}</span> -->
      <span v-else class="lf-action">{{ rejectedConflictedCoiText(cardInfo) | t }}</span>
    </div>
    <v-divider style="margin-bottom: 10px"></v-divider>

    <!-- The ref Team is set same for all the cards, it is really important to keep it this way, because the parent
    component uses the ref to set the maximum height to all the cars -->
    <!-- template v-if="cardInfo.proposalSubmitted ">
      <div class="empty-item" ref="infoCard">
        {{ $t('activeRFP.decision.submittedDeadlinenotReached') }}
      </div>
    </template -->
    <template v-if="cardInfo.proposalSubmitted">
    <div class="cardSection" v-if="team(cardInfo)" ref="team">
      <div class="title">
        <v-btn @click="$emit('toggleSection', 'Team')" icon><v-icon :class="{arrowUp: showTeam, arrowDown: !showTeam}">ic-chevron-right</v-icon></v-btn>
        <h3>{{ 'activeRFP.proposal.team' | t}}</h3>
      </div>
      <v-divider></v-divider>
      <div v-if="showTeam">
      <div v-if="team(cardInfo).subItems.length">
      <div v-for="member in team(cardInfo).subItems" :key="member.id">
        <div class="team-title">
          <v-avatar size="30" class="circle-span">
            <span style="color: #fff;">{{ spanName(member.linkedUser.fullName) }}</span>
          </v-avatar>
          <section>
            <div style="display: grid; grid-template-columns: 100px auto; grid-column-gap: 5px">
              <TooltipHolder
                style="font-size: 16px; padding-top: 7px;"
                :text="member.linkedUser.fullName"
                :tooltipText="member.linkedUser.fullName"
                :spanId="member.id"
                :moveLeft="-15"
                :moveTop="30"
                tableData
                bold />
              <!-- <p :style=" !(member.extendedFields && member.extendedFields.seniorityLevel) ? 'margin-top: 10px' :''"><b>{{ member.linkedUser.fullName }}</b></p> -->
              <v-hover>
                <div class="rating-lf" slot-scope="{ hover }">
                <v-btn
                  v-if="member.rating !== null && !disabled && hover"
                  flat
                  class="reset-rating"
                  @click="rateProposalLocal({ categoryId: team(cardInfo).id, subItemId: member.id, value: 'DELETE' })"
                  icon><v-icon>close</v-icon></v-btn>
                <span v-else></span>
                <v-rating
                  small
                  color="orange"
                  class="raitings"
                  background-color="orange lighten-3"
                  :readonly="disabled"
                  :value="member.rating"
                  @input="value => rateProposalLocal({ categoryId: team(cardInfo).id, subItemId: member.id, value })"
                  half-increments hover />
                </div>
              </v-hover>
            </div>
              <p>{{ (member.extendedFields && member.extendedFields.seniorityLevel ? `dropDownOptions.SeniorityLevel.${member.extendedFields.seniorityLevel}` : '') | t }}</p>
          </section>
        </div>

        <section v-if="member.extendedFields.hasDocumentUpload" style="margin-top: 7px">
          <FileChip v-for="attachment in member.attachments" :file="attachment" :key="attachment.id" showIcon/>
        </section>
        <section v-else-if="member.extendedFields.cvLink" style="display: inline-block; margin-left: 40px; font-size: 16px">
          <a :href="formattedUrl(member.extendedFields.cvLink)" target="_blank" rel="noopener">{{ member.extendedFields.cvLink }}</a>
        </section>
      </div>
      </div>
      <div v-else>
        <span class="noSubmission">{{ $t('activeRFP.proposal.noTeamProvided') }}</span>
      </div>
      <LawFirmRatingComment
        :disabled="disabled"
        @toggle="$emit('updateMaxHeight', 'team')"
        @save="saveProposalComment({ categoryId: team(cardInfo).id, ratingComment: team(cardInfo).ratingComment || '' })"
        v-model="team(cardInfo).ratingComment" />
      </div>
    </div>

    <!-- The ref credentials is set same for all the cards, it is really important to keep it this way, because the parent
    component uses the ref to set the maximum height to all the cars -->
    <div class="cardSection" v-if="credentials(cardInfo)" ref="credentials">
      <div class="title">
        <v-btn @click="$emit('toggleSection', 'Credentials')" icon><v-icon :class="{arrowUp: showCredentials, arrowDown: !showCredentials}">ic-chevron-right</v-icon></v-btn>
        <h3>{{ 'activeRFP.proposal.credentials' | t}}</h3>
        <v-hover>
          <div class="rating-lf" slot-scope="{ hover }">
            <v-btn
              v-if="credentials(cardInfo).rating !== null && !disabled && hover"
              flat
              class="reset-rating"
              @click="rateProposalLocal({ categoryId: credentials(cardInfo).id, value: 'DELETE' })"
              icon><v-icon>close</v-icon></v-btn>
            <span v-else></span>
            <v-rating
              small
              color="orange"
              class="raitings"
              background-color="orange lighten-3"
              :readonly="disabled"
              :value="credentials(cardInfo).rating"
              @input="value => rateProposalLocal({ categoryId: credentials(cardInfo).id, value })"
              half-increments hover />
          </div>
        </v-hover>
      </div>
      <v-divider></v-divider>

      <div v-if="showCredentials">
        <div v-if="credentials(cardInfo).subItems.length">
          <div class="credential-item" v-for="(credential, index) in credentials(cardInfo).subItems" :key="credential.id">
            <v-divider class="credential-divider" v-if="index !== 0"></v-divider>
            <span class="light">{{ 'activeRFP.proposal.description' | t }}</span>
            <p>{{ credential.extendedFields.description }}</p>
            <span class="light">{{ 'activeRFP.proposal.year' | t }}</span>
            <p>{{ credential.extendedFields.year }}</p>
            <template v-if="currentRfp.terms.qualityScoreCriteria && currentRfp.terms.qualityScoreCriteria.includes('TEAM')">
              <span class="light">{{ 'activeRFP.proposal.involvementOfTeamMembers' | t }}</span>
              <div style="margin-bottom: 16px" v-if="credential.extendedFields.involvementOfProposedTeamMembers && credential.extendedFields.involvementOfProposedTeamMembers.length">
                <div
                  style="display: grid; grid-template-columns: 220px 1fr;"
                  v-for="(teamMember, index) in credential.extendedFields.involvementOfProposedTeamMembers"
                  :key="teamMember.userId + teamMember.role">
                  <p style="margin-bottom: 0">{{ getTeamMemberNameFromProposalTeam(teamMember.userId, cardInfo) }}</p>
                  <TooltipHolder
                    :text="renderRole(teamMember)"
                    :tooltipText="renderRole(teamMember)"
                    :spanId="'CREDENTIAL_TEAM_MEMBER_INVOLVEMENT' + teamMember.userId + index"
                    :moveLeft="-10"
                    fixedArrow
                    tableData
                    width="max-content"
                    style="font-size: 16px;"
                    :moveTop="0" />
                </div>
              </div>
              <p v-else>{{ 'activeRFP.proposal.clientAdvisedByOtherLawyers' | t }}</p>
            </template>
          </div>
        </div>
        <div v-else>
          <span class="noSubmission">{{ $t('activeRFP.proposal.noCredentialsProvided') }}</span>
        </div>
        <LawFirmRatingComment
          :disabled="disabled"
          @save="saveProposalComment({ categoryId: credentials(cardInfo).id, ratingComment: credentials(cardInfo).ratingComment || '' })"
          v-model="credentials(cardInfo).ratingComment" />
      </div>

    </div>

    <!-- The ref pitch document is set same for all the cards, it is really important to keep it this way, because the parent
    component uses the ref to set the maximum height to all the cars -->
    <div class="cardSection" v-if="pitchDocument(cardInfo)" ref="pitch_document" >
      <div class="title">
        <v-btn @click="$emit('toggleSection', 'Pitch_document')" icon><v-icon :class="{arrowUp: showPitch_document, arrowDown: !showPitch_document}">ic-chevron-right</v-icon></v-btn>
        <h3>{{ 'activeRFP.proposal.pitchDocument' | t}}</h3>
        <v-hover>
          <div class="rating-lf" slot-scope="{ hover }">
            <v-btn
              v-if="pitchDocument(cardInfo).rating !== null && !disabled && hover"
              @click="rateProposalLocal({ categoryId: pitchDocument(cardInfo).id, value: 'DELETE' })"
              class="reset-rating"
              flat icon>
              <v-icon>close</v-icon>
            </v-btn>
            <span v-else></span>
            <v-rating
              small
              color="orange"
              class="raitings"
              background-color="orange lighten-3"
              :readonly="disabled"
              :value="pitchDocument(cardInfo).rating"
              @input="value => rateProposalLocal({ categoryId: pitchDocument(cardInfo).id, value })"
              half-increments hover />
          </div>
        </v-hover>
      </div>
      <v-divider></v-divider>

      <div v-if="showPitch_document">
        <div v-if="pitchDocument(cardInfo).attachments.length" class="credential-item">
          <FileChip v-for="attachment in pitchDocument(cardInfo).attachments" :file="attachment" :key="attachment.id" showIcon />
        </div>
        <div v-else>
          <span class="noSubmission">{{ $t('activeRFP.proposal.noLiabilityProvided') }}</span>
        </div>
        <LawFirmRatingComment
          :disabled="disabled"
          @save="saveProposalComment({ categoryId: pitchDocument(cardInfo).id, ratingComment: pitchDocument(cardInfo).ratingComment || '' })"
          v-model="pitchDocument(cardInfo).ratingComment" />
      </div>
    </div>

    <!-- The ref liability is set same for all the cards, it is really important to keep it this way, because the parent
    component uses the ref to set the maximum height to all the cars -->
    <div class="cardSection" v-if="liability(cardInfo)" ref="liability">
      <div class="title">
        <v-btn @click="$emit('toggleSection', 'Liability')" icon><v-icon :class="{arrowUp: showLiability, arrowDown: !showLiability}">ic-chevron-right</v-icon></v-btn>
        <h3>{{ 'activeRFP.proposal.liability' | t}}</h3>
        <v-hover>
          <div class="rating-lf" slot-scope="{ hover }">
            <v-btn
              v-if="liability(cardInfo).rating !== null && !disabled && hover"
              flat
              class="reset-rating"
              @click="rateProposalLocal({ categoryId: liability(cardInfo).id, value: 'DELETE' })"
              icon><v-icon>close</v-icon></v-btn>
            <span v-else></span>
            <v-rating
              small
              class="raitings"
              color="orange"
              background-color="orange lighten-3"
              :readonly="disabled"
              :value="liability(cardInfo).rating"
              @input="value => rateProposalLocal({ categoryId: liability(cardInfo).id, value })"
              half-increments hover />
          </div>
        </v-hover>
      </div>
      <v-divider></v-divider>

      <div v-if="showLiability">
      <div v-if="(liability(cardInfo).extendedFields.maximumLiability === 0 || liability(cardInfo).extendedFields.maximumLiability)" class="credential-item">
        <span class="light">{{ 'activeRFP.proposal.maximumLiability' | t }}</span><br>
        <p class="dark">{{ formatNumber(liability(cardInfo).extendedFields.maximumLiability) }} {{currencySymbol()}}</p>
        <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
        <p>{{ liabilityText(cardInfo) }}</p>
      </div>
      <div v-else>
        <span class="noSubmission">{{ $t('activeRFP.proposal.noPitchDocumentProvided') }}</span>
      </div>
      <LawFirmRatingComment
        :disabled="disabled"
        @save="saveProposalComment({ categoryId: liability(cardInfo).id, ratingComment: liability(cardInfo).ratingComment || '' })"
        v-model="liability(cardInfo).ratingComment" />
      </div>
    </div>

      <div class="cardSection" v-if="strategy(cardInfo)" ref="strategy">
        <div class="title">
          <v-btn @click="$emit('toggleSection', 'Strategy')" icon><v-icon :class="{arrowUp: showStrategy, arrowDown: !showStrategy}">ic-chevron-right</v-icon></v-btn>
          <h3>{{ 'activeRFP.proposal.strategy' | t}}</h3>
          <v-hover>
            <div class="rating-lf" slot-scope="{ hover }">
              <v-btn
                v-if="strategy(cardInfo).rating !== null && !disabled && hover"
                flat
                class="reset-rating"
                @click="rateProposalLocal({ categoryId: strategy(cardInfo).id, value: 'DELETE' })"
                icon><v-icon>close</v-icon></v-btn>
              <span v-else></span>
              <v-rating
                small
                class="raitings"
                color="orange"
                background-color="orange lighten-3"
                :readonly="disabled"
                :value="strategy(cardInfo).rating"
                @input="value => rateProposalLocal({ categoryId: strategy(cardInfo).id, value })"
                half-increments hover />
            </div>
          </v-hover>
        </div>
        <v-divider></v-divider>

        <div v-if="showStrategy">
          <div v-if="strategyText(cardInfo)" class="credential-item">
            <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
            <p>{{ strategyText(cardInfo) }}</p>
          </div>
          <div v-else>
            <span class="noSubmission">{{ $t('activeRFP.proposal.noPitchDocumentProvided') }}</span>
          </div>
          <LawFirmRatingComment
            :disabled="disabled"
            @save="saveProposalComment({ categoryId: strategy(cardInfo).id, ratingComment: strategy(cardInfo).ratingComment || '' })"
            v-model="strategy(cardInfo).ratingComment" />
        </div>
      </div>

      <div class="cardSection" v-if="custom(cardInfo, 'CUSTOM1')" ref="custom1">
        <div class="title">
          <v-btn @click="$emit('toggleSection', 'Custom1')" icon><v-icon :class="{arrowUp: showCustom1, arrowDown: !showCustom1}">ic-chevron-right</v-icon></v-btn>
          <h3>{{ customTitle('CUSTOM1') }}</h3>
          <v-hover>
            <div class="rating-lf" slot-scope="{ hover }">
              <v-btn
                v-if="custom(cardInfo, 'CUSTOM1').rating !== null && !disabled && hover"
                flat
                class="reset-rating"
                @click="rateProposalLocal({ categoryId: custom(cardInfo, 'CUSTOM1').id, value: 'DELETE' })"
                icon><v-icon>close</v-icon></v-btn>
              <span v-else></span>
              <v-rating
                small
                class="raitings"
                color="orange"
                background-color="orange lighten-3"
                :readonly="disabled"
                :value="custom(cardInfo, 'CUSTOM1').rating"
                @input="value => rateProposalLocal({ categoryId: custom(cardInfo, 'CUSTOM1').id, value })"
                half-increments hover />
            </div>
          </v-hover>
        </div>
        <v-divider></v-divider>

        <div v-if="showCustom1">
          <div v-if="custom(cardInfo, 'CUSTOM1')" class="credential-item">
            <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
            <p>{{ customText(cardInfo, 'CUSTOM1') }}</p>
          </div>
          <div v-else>
            <span class="noSubmission">{{ $t('activeRFP.proposal.noPitchDocumentProvided') }}</span>
          </div>
          <LawFirmRatingComment
            :disabled="disabled"
            @save="saveProposalComment({ categoryId: custom(cardInfo, 'CUSTOM1').id, ratingComment: custom(cardInfo, 'CUSTOM1').ratingComment || '' })"
            v-model="custom(cardInfo, 'CUSTOM1').ratingComment" />
        </div>
      </div>
      <div class="cardSection" v-if="custom(cardInfo, 'CUSTOM2')" ref="custom2">
        <div class="title">
          <v-btn @click="$emit('toggleSection', 'Custom2')" icon><v-icon :class="{arrowUp: showCustom2, arrowDown: !showCustom2}">ic-chevron-right</v-icon></v-btn>
          <h3>{{ customTitle('CUSTOM2') }}</h3>
          <v-hover>
            <div class="rating-lf" slot-scope="{ hover }">
              <v-btn
                v-if="custom(cardInfo, 'CUSTOM2').rating !== null && !disabled && hover"
                flat
                class="reset-rating"
                @click="rateProposalLocal({ categoryId: custom(cardInfo, 'CUSTOM2').id, value: 'DELETE' })"
                icon><v-icon>close</v-icon></v-btn>
              <span v-else></span>
              <v-rating
                small
                class="raitings"
                color="orange"
                background-color="orange lighten-3"
                :readonly="disabled"
                :value="custom(cardInfo, 'CUSTOM2').rating"
                @input="value => rateProposalLocal({ categoryId: custom(cardInfo, 'CUSTOM2').id, value })"
                half-increments hover />
            </div>
          </v-hover>
        </div>
        <v-divider></v-divider>

        <div v-if="showCustom2">
          <div v-if="custom(cardInfo, 'CUSTOM2')" class="credential-item">
            <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
            <p>{{ customText(cardInfo, 'CUSTOM2') }}</p>
          </div>
          <div v-else>
            <span class="noSubmission">{{ $t('activeRFP.proposal.noPitchDocumentProvided') }}</span>
          </div>
          <LawFirmRatingComment
            :disabled="disabled"
            @save="saveProposalComment({ categoryId: custom(cardInfo, 'CUSTOM1').id, ratingComment: custom(cardInfo, 'CUSTOM1').ratingComment || '' })"
            v-model="custom(cardInfo, 'CUSTOM1').ratingComment" />
        </div>
      </div>
      <div class="cardSection" v-if="custom(cardInfo, 'CUSTOM3')" ref="custom1">
        <div class="title">
          <v-btn @click="$emit('toggleSection', 'Custom3')" icon><v-icon :class="{arrowUp: showCustom3, arrowDown: !showCustom3}">ic-chevron-right</v-icon></v-btn>
          <h3>{{ customTitle('CUSTOM3') }}</h3>
          <v-hover>
            <div class="rating-lf" slot-scope="{ hover }">
              <v-btn
                v-if="custom(cardInfo, 'CUSTOM3').rating !== null && !disabled && hover"
                flat
                class="reset-rating"
                @click="rateProposalLocal({ categoryId: custom(cardInfo, 'CUSTOM3').id, value: 'DELETE' })"
                icon><v-icon>close</v-icon></v-btn>
              <span v-else></span>
              <v-rating
                small
                class="raitings"
                color="orange"
                background-color="orange lighten-3"
                :readonly="disabled"
                :value="custom(cardInfo, 'CUSTOM3').rating"
                @input="value => rateProposalLocal({ categoryId: custom(cardInfo, 'CUSTOM3').id, value })"
                half-increments hover />
            </div>
          </v-hover>
        </div>
        <v-divider></v-divider>

        <div v-if="showCustom3">
          <div v-if="custom(cardInfo, 'CUSTOM3')" class="credential-item">
            <span class="light">{{ 'activeRFP.proposal.explanation' | t }}</span><br>
            <p>{{ customText(cardInfo, 'CUSTOM3') }}</p>
          </div>
          <div v-else>
            <span class="noSubmission">{{ $t('activeRFP.proposal.noPitchDocumentProvided') }}</span>
          </div>
          <LawFirmRatingComment
            :disabled="disabled"
            @save="saveProposalComment({ categoryId: custom(cardInfo, 'CUSTOM3').id, ratingComment: custom(cardInfo, 'CUSTOM3').ratingComment || '' })"
            v-model="custom(cardInfo, 'CUSTOM3').ratingComment" />
        </div>
      </div>

      <div class="cardSection" v-if="qaEnabled" ref="qa">
        <div class="title">
          <span></span>
          <h3>{{ 'activeRFP.proposal.qa' | t}}</h3>
          <v-hover>
            <div class="rating-lf" slot-scope="{ hover }">
              <v-btn
                v-if="qa(cardInfo).rating !== null && !disabled && hover"
                flat
                class="reset-rating"
                @click="rateProposalLocal({ categoryId: qa(cardInfo).id, value: 'DELETE' })"
                icon><v-icon>close</v-icon></v-btn>
              <span v-else></span>
              <v-rating
                small
                class="raitings"
                color="orange"
                background-color="orange lighten-3"
                :readonly="disabled"
                :value="qa(cardInfo).rating"
                @input="value => rateProposalLocal({ categoryId: qa(cardInfo).id, value })"
                half-increments hover />
            </div>
          </v-hover>
        </div>
        <v-divider></v-divider>
        <LawFirmRatingComment
          :disabled="disabled"
          @save="saveProposalComment({ categoryId: qa(cardInfo).id, ratingComment: qa(cardInfo).ratingComment || '' })"
          v-model="qa(cardInfo).ratingComment" />
      </div>

      <div class="cardSection" v-if="interviewEnabled" ref="interview">
        <div class="title">
          <span></span>
          <h3>{{ 'activeRFP.proposal.interview' | t}}</h3>
          <v-hover>
            <div class="rating-lf" slot-scope="{ hover }">
              <v-btn
                v-if="interview(cardInfo).rating !== null && !disabled && hover"
                flat
                class="reset-rating"
                @click="rateProposalLocal({ categoryId: interview(cardInfo).id, value: 'DELETE' })"
                icon><v-icon>close</v-icon></v-btn>
              <span v-else></span>
              <v-rating
                small
                class="raitings"
                color="orange"
                background-color="orange lighten-3"
                :readonly="disabled"
                :value="interview(cardInfo).rating"
                @input="value => rateProposalLocal({ categoryId: interview(cardInfo).id, value })"
                half-increments hover />
            </div>
          </v-hover>
        </div>
        <v-divider></v-divider>
        <LawFirmRatingComment
          :disabled="disabled"
          @save="saveProposalComment({ categoryId: interview(cardInfo).id, ratingComment: interview(cardInfo).ratingComment || '' })"
          v-model="interview(cardInfo).ratingComment" />
      </div>
    </template>

    <template v-else>
      <div class="cardSection" v-if="hasQualityScore('TEAM')" ref="team"></div>
      <div class="cardSection" v-if="hasQualityScore('CREDENTIALS')" ref="credentials"></div>
      <div class="cardSection" v-if="hasQualityScore('PITCH_DOCUMENT')" ref="pitch_document"></div>
      <div class="cardSection" v-if="hasQualityScore('LIABILITY')" ref="liability"></div>
      <div class="cardSection" v-if="hasQualityScore('INTERVIEW')" ref="interview"></div>
      <div class="cardSection" v-if="hasQualityScore('QA')" ref="qa"></div>
      <div class="cardSection" v-if="hasQualityScore('STRATEGY')" ref="strategy"></div>
      <div class="cardSection" v-if="hasQualityScore('CUSTOM1')" ref="custom1"></div>
      <div class="cardSection" v-if="hasQualityScore('CUSTOM2')" ref="custom2"></div>
      <div class="cardSection" v-if="hasQualityScore('CUSTOM3')" ref="custom3"></div>
      <div class="empty-item" ref="infoCard">
        <p>{{ (!cardInfo.coi2Confirmed && cardInfo.coi2Confirmed !== null) ? cardInfo.coi2RejectionReason : $t(rejectedConflictedCoiText(cardInfo)) }}</p>
      </div>
    </template>

    <v-snackbar
      v-model="showSuccessMessageForProposalComment">
      <p style="color: white; margin-bottom: 0;">{{ $t('activeRFP.proposal.commentSaved') }}</p>
      <v-btn
        icon
        @click="showSuccessMessageForProposalComment = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import LawFirmRatingMixin from './LawFirmRatingMixin'
import FileChip from '../../generalComponents/FileChip'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import { mapGetters } from 'vuex'
import LawFirmRatingComment from '@/views/activeRfp/lawFirmRating/LawFirmRatingComment'

export default {
  name: 'LawFirmRatingCard',
  mixins: [ LawFirmRatingMixin ],
  components: { LawFirmRatingComment, FileChip, TooltipHolder },
  props: {
    // Object containing the Card Info
    cardInfo: {
      type: Object,
      required: true
    },
    // Props that control the max Height and set the same size for all cards
    teamMaxHeight: {
      type: Number
    },

    liabilityMaxHeight: {
      type: Number
    },

    credentialsMaxHeight: {
      type: Number
    },

    pitch_documentMaxHeight: {
      type: Number
    },
    // Props that control the visibility of the section accordingly
    showTeam: {
      type: Boolean
    },

    showLiability: {
      type: Boolean
    },

    showStrategy: {
      type: Boolean
    },

    showCustom1: {
      type: Boolean
    },

    showCustom2: {
      type: Boolean
    },

    showCustom3: {
      type: Boolean
    },

    showCredentials: {
      type: Boolean
    },

    showPitch_document: {
      type: Boolean
    }
  },
  watch: {
    teamMaxHeight () {
      console.log('change', this.teamMaxHeight)
      this.setSectionHeight('team', this.teamMaxHeight)
    },

    showTeam () {
      this.setSectionHeight('team', (this.showTeam ? this.teamMaxHeight : 40))
    },

    liabilityMaxHeight () {
      this.setSectionHeight('liability', this.liabilityMaxHeight)
    },

    showLiability () {
      this.setSectionHeight('liability', (this.showLiability ? this.liabilityMaxHeight : 40))
    },

    credentialsMaxHeight () {
      this.setSectionHeight('credentials', this.credentialsMaxHeight)
    },

    showCredentials () {
      this.setSectionHeight('credentials', (this.showCredentials ? this.credentialsMaxHeight : 40))
    },

    pitch_documentMaxHeight () {
      this.setSectionHeight('pitch_document', this.pitch_documentMaxHeight)
    },

    showPitch_document () {
      this.setSectionHeight('pitch_document', (this.showPitch_document ? this.pitch_documentMaxHeight : 40))
    }
  },
  computed: {
    ...mapGetters(['formattedUrl', 'currencySymbol'])
  },
  methods: {
    setSectionHeight (section, height) {
      if (this.cardInfo.proposalSubmitted) {
        this.$refs[section].setAttribute('style', `min-height: ${height}px`)
      } else {
        // console.log('card', this.cardInfo)
        this.setTotalHeight()
      }
    },

    setTotalHeight () {
      this.$refs.infoCard.setAttribute('style', `height: ${this.calculateTotalHeight()}px`)
    },

    calculateTotalHeight () {
      let totalHeight = 0
      this.currentRfp.terms.qualityScoreCriteria.forEach(section => {
        if (this[`show${section[0] + section.substring(1).toLowerCase()}`]) {
          // console.log(section[0] + section.substring(1).toLowerCase())
          totalHeight = this[`${section.toLowerCase()}MaxHeight`] + totalHeight + 10
        } else {
          totalHeight = 50 + totalHeight
        }
      })
      if (this.currentRfp.allowQA) {
        totalHeight = 50 + totalHeight
      }
      return totalHeight
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import "../../../assets/css/variables.styl"
  .main-title
    display grid
    grid-template-columns 1fr auto
    margin-bottom 5px
    height 40px
    align-items center

  .title
    display grid
    grid-template-columns 36px 1fr 45px 150px
    // border-bottom 1px solid lightgray
    height 40px
    button
      margin 0
      align-self center
    h3
      line-height 1
      align-self center
    .v-icon
      font-size 24px
    h3
      align-self center

    >>> .v-icon
      color $gold100 !important

  .carrusel-card
    padding 30px 15px !important
    width 370px
    border-radius 10px
    margin 0 5px 5px 5px

  .team-title
    display grid
    grid-template-columns 40px auto
    margin-top 5px

    p
      margin-bottom 0px

    .circle-span
      margin 15px 10px 0 0
      background-color $icon-gray

    >>> .v-icon
      color $gold100 !important

  .rating-lf
    display grid
    grid-template-columns 45px 150px

  .rating
    background-color $gold100

  .reset-rating
    justify-self end
    height 30px
    width 30px
    margin 0

    .v-icon
      font-size 18px

  .cardSection
    margin-bottom 10px

  .credential-divider
    border-color rgba(0, 0, 0, 0.08)

  .empty-item
    text-align center
    display flex
    justify-content center
    p
      margin-bottom 0
      margin auto 0

  .arrowUp
    transform: rotate(270deg)

  .arrowDown
    transform: rotate(90deg)

  .raitings
    align-self center

  .noSubmission
    width 100%
    text-align center
    display block
    line-height 40px
</style>
