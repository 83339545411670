import i18next from 'i18next'
import { vue } from '../../main'
import i18nextI from '../../langs/i18next'
function getEnumOptionList (state, key) {
  const listObject = state.optionLists.find(list => list.key === key)
  if (!listObject) {
    return []
  } else {
    return listObject.options.map(option => {
      const currency = state.currentRfp && state.currentRfp.terms ? i18next.t(`dropDownOptions.Currency.${state.currentRfp.terms.currency || 'EUR'}`) : ''

      // Only translate the option if it isn't already translated by the back end
      const translatedLabel = (!option.translatedLabel || option.key === option.translatedLabel)
        ? (key === 'PrimaryRole' ? i18next.t(`roles.${option.key}`) : i18next.t(`dropDownOptions.${key}.${option.key}`, { currency }))
        : option.translatedLabel
      return {
        // TODO refactor usages to use 'key' and 'translatedLabel' as well
        value: option.key,
        text: translatedLabel
      }
    })
  }
}

export default {

  optionListByKey: (state) => (optionListKey) => {
    return state.optionLists.find(list => list.key === optionListKey)
  },

  optionListForEnabledBy: (state, getters) => (enabledByRelation) => {
    const key = enabledByRelation.relativePath ? enabledByRelation.relativePath.optionListKey : (enabledByRelation.absolutePath ? enabledByRelation.absolutePath.optionListKey : null)
    const optionList = getters.optionListByKey(key)

    return optionList ? optionList.options : null
  },

  lawfirmUserPositionOptions (state) {
    return getEnumOptionList(state, 'LawfirmUserPosition')
  },

  corporateUserPositionOptions (state) {
    return getEnumOptionList(state, 'CorporateUserPosition')
  },

  mandateTypeOptions (state) {
    const mandateTypeOptions = getEnumOptionList(state, 'MandateType')
    const disabledMandateTypeOptions = [
      'ENVIRONMENTAL_LAW',
      'INTERNAL_INVESTIGATIONS',
      'SUBSIDIES',
      'REGULATED_INDUSTRIES'
    ]
    return mandateTypeOptions.filter(option => !disabledMandateTypeOptions.includes(option.value))
  },

  seniorityLevelOptions (state) {
    return getEnumOptionList(state, 'SeniorityLevel')
  },

  mandateSubTypeOptions (state) {
    const mandateSubTypeOptions = getEnumOptionList(state, 'MandateSubType')
    const disabledMandateSubTypeOptions = [
      'CORPORATE_COMPANY_FORMATION',
      'CORPORATE_GENERAL_CORPORATE',
      'CORPORATE_M_AND_A',
      'CORPORATE_JOINT_VENTURE',
      'BANKING_AND_CAPITAL_MARKETS_CORPORATE_LENDING',
      'BANKING_AND_CAPITAL_MARKETS_REAL_ESTATE_FINANCING',
      'BANKING_AND_CAPITAL_MARKETS_INFRASTRUCTURE_FINANCING',
      'BANKING_AND_CAPITAL_MARKETS_PROJECT_FINANCING',
      'BANKING_AND_CAPITAL_MARKETS_EXPORT_FINANCING',
      'BANKING_AND_CAPITAL_MARKETS_STRUCTURED_PRODUCTS_AND_DERIVATIVES',
      'BANKING_AND_CAPITAL_MARKETS_DEBT_CAPITAL_MARKETS',
      'BANKING_AND_CAPITAL_MARKETS_EQUITY_CAPITAL_MARKETS',
      'BANKING_AND_CAPITAL_MARKETS_BANKING_SUPERVISION_FINANCIAL_MARKETS_REGULATION',
      'COMMERCIAL_SOURCING',
      'COMMERCIAL_SALES_AND_DISTRIBUTION',
      'COMMERCIAL_TERMS_AND_CONDITIONS',
      'INTELLECTUAL_PROPERTY_INDUSTRIAL_DESIGN',
      'INFORMATION_TECHNOLOGY_IT_PROJECT',
      'INFORMATION_TECHNOLOGY_OUTSOURCING',
      'EMPLOYMENT_EMPLOYMENT_CONTRACT',
      'EMPLOYMENT_COLLECTIVE_AGREEMENT',
      'EMPLOYMENT_CO_DETERMINATION',
      'REAL_ESTATE_SINGLE_ASSET_TRANSACTION',
      'REAL_ESTATE_PORTFOLIO_TRANSACTION',
      'REAL_ESTATE_LEASE_AGREEMENT',
      'LITIGATION_COMMERCIAL_CONTRACTS',
      'LITIGATION_POST_M_AND_A',
      'LITIGATION_OTHER',
      'REGULATED_INDUSTRIES_ENERGY_LAW',
      'REGULATED_INDUSTRIES_HEALTH_CARE',
      'REGULATED_INDUSTRIES_FOOD_LAW',
      'REGULATED_INDUSTRIES_TELECOMMUNICATIONS_LAW',
      'REGULATED_INDUSTRIES_TRANSPORT_SECTOR'
    ]
    return mandateSubTypeOptions.filter(option => !disabledMandateSubTypeOptions.includes(option.value))
  },

  qualityScoreOptions (state) {
    const hideValues = ['QA', 'INTERVIEW', 'CUSTOM1', 'CUSTOM2', 'CUSTOM3']
    return getEnumOptionList(state, 'QualityScoreCriterium').filter(x => !hideValues.includes(x.value))
  },

  currencyOptions (state) {
    return getEnumOptionList(state, 'Currency')
  },

  decisionTypeOptions (state) {
    return getEnumOptionList(state, 'DecisionType')
  },

  feeStructureOptions (state) {
    return getEnumOptionList(state, 'FeeStructure')
  },

  feeStructureSubOptions (state) {
    return getEnumOptionList(state, 'FeeStructureSubType')
  },

  billingTypeOptions (state) {
    return getEnumOptionList(state, 'BillingType')
  },

  auctionTypeOptions (state) {
    return getEnumOptionList(state, 'AuctionType')
  },

  auctionSubTypeOptions (state) {
    return getEnumOptionList(state, 'AuctionSubType')
  },

  internalCoordinationTypeOptions (state) {
    return getEnumOptionList(state, 'InternalCoordinationType')
  },

  travelExpenseTypeOptions (state) {
    return getEnumOptionList(state, 'TravelExpenseType')
  },

  associatesExpenseTypeOptions (state) {
    return getEnumOptionList(state, 'AssociatesExpenseType')
  },

  traineesExpenseTypeOptions (state) {
    return getEnumOptionList(state, 'TraineesExpenseType')
  },

  trainingExpenseTypeOptions (state) {
    return getEnumOptionList(state, 'TrainingExpenseType')
  },

  flightExpenseTypeOptions (state) {
    return getEnumOptionList(state, 'FlightExpenseType')
  },

  trainExpenseTypeOptions (state) {
    return getEnumOptionList(state, 'TrainExpenseType')
  },

  rentalCarExpenseTypeOptions (state) {
    return getEnumOptionList(state, 'RentalCarExpenseType')
  },

  hotelExpenseTypeOptions (state) {
    return getEnumOptionList(state, 'HotelExpenseType')
  },

  representationTypeOptions (state) {
    return getEnumOptionList(state, 'RepresentationType')
  },

  liabilityTypeOptions (state) {
    return getEnumOptionList(state, 'LiabilityType')
  },

  generalFeesOptions (state) {
    return getEnumOptionList(state, 'GeneralFees')
  },

  primaryRoleOptions (state) {
    return getEnumOptionList(state, 'PrimaryRole')
  },

  otherPartyRolesOptions (state) {
    return getEnumOptionList(state, 'OtherPartyRoles')
  },

  fieldTypeOptions (state) {
    return getEnumOptionList(state, 'FieldType')
  },

  constraintTypeOptions (state) {
    return getEnumOptionList(state, 'ConstraintType')
  },

  customOptionLists (state) {
    return state.optionLists.filter(optionList => !optionList.isReserved)
  },

  disclosureRulesOptions (state) {
    return getEnumOptionList(state, 'DisclosureMode')
  },

  credentialRoleOptions (state) {
    return getEnumOptionList(state, 'CredentialRole')
  },

  languageOptions () {
    const data = i18nextI.store.data
    const keys = Object.keys(data)

    return keys.reduce((result, key) => {
      result.push({
        key: key,
        translatedLabel: data[key].label
      })

      return result
    }, [])
  }

}
