import Vue from 'vue'
import newState from '../state'
import moment from 'moment'

export default {

  setUserData (state, userData) {
    Vue.set(state, 'userData', { ...userData, sessionExpiry: moment().add(10, 'minutes').add(20, 'seconds') })
  },

  resetSessionExpiry (state) { // 20 seconds of leeway is added to allow for a graceful keepalive without flickering between 10m am 9m
    // Vue.set(state, 'userData', { ...state.userData, sessionExpiry: moment().add(10, 'minutes').add(20, 'seconds') })
    state.userData.sessionExpiry = moment().add(10, 'minutes').add(20, 'seconds')
  },

  setAuthToken (state, authToken) {
    state.authToken = authToken
  },

  setMfaMethod (state, mfaMethod) {
    Vue.set(state, 'mfaMethod', mfaMethod)
  },

  resetState (state) {
    Object.assign(state, newState.defaultState())
  }

}
