import Vue from 'vue'

export function handleGraphQlErrors (state, graphQLErrors) {
  _handleGraphQlErrors(state, graphQLErrors)
}

function _handleGraphQlErrors (state, graphQLErrors) {
  const graphQlError = graphQLErrors[0]
  if (graphQlError && graphQlError.extensions && graphQlError.extensions.httpStatusEquivalent) {
    if (state && state.error) {
      Vue.set(state.error, 'httpStatusEquivalent', graphQlError.extensions.httpStatusEquivalent)
    }
  }

  if (graphQlError && graphQlError.extensions && graphQlError.extensions.httpStatusEquivalent === 401) {
    Vue.set(state, 'authToken', null) // delete auth token as it is invalid
  } else {
    if (graphQlError && graphQlError.message) {
      if (state && state.error) {
        Vue.set(state.error, 'specialErrorMessage', graphQlError.message)
      }
    }

    if (state && state.error) {
      Vue.set(state.error, 'showDefaultError', true)
    }
  }
}

export default {

  setShowDefaultError (state, showDefaultError) {
    Vue.set(state.error, 'showDefaultError', showDefaultError)
  },

  setSpecialErrorMessage (state, errorMessage) {
    Vue.set(state.error, 'specialErrorMessage', errorMessage)
  },

  setHttpStatusEquivalent (state, httpStatus) {
    Vue.set(state.error, 'httpStatusEquivalent', httpStatus)
  },

  setShowNotOnlineError (state, showNotOnlineError) {
    Vue.set(state.error, 'showNotOnlineError', showNotOnlineError)
  },

  setShowSubscriptionError (state, showSubscriptionError) {
    Vue.set(state.error, 'showSubscriptionError', showSubscriptionError)
  },

  setNoWebsocketSupportError (state, showSubscriptionError) {
    Vue.set(state.error, 'noWebsocketSupport', showSubscriptionError)
  },

  setErrorOperation (state, operation) {
    Vue.set(state.error, 'operation', operation)
  },

  handleGraphQlErrors (state, graphQlErrors) {
    _handleGraphQlErrors(state, graphQlErrors)
  }

}
