<template>
  <div>
    <div class="image-container"><img class="not-found" src="../../assets/img/LIN_404.png" ></div>
    <section class="text-found">
      <h1 class="title-found">404</h1>
      <h1>{{ "pageNotFound.title" | t }}</h1>
      <p>{{ "pageNotFound.text" | t }}</p>
      <v-btn @click.prevent="toDashboard()" class="main" flat>{{ (userData ? "pageNotFound.buttonToDashboard" : "pageNotFound.buttonToLogin") | t }}</v-btn>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PageNotFound',
  computed: {
    ...mapState({
      userData: state => state.userData
    })
  },
  methods: {
    toDashboard () {
      if (this.userData) {
        this.$router.push({
          name: 'dashboardRfps'
        })
      } else {
        this.$router.push({
          name: 'login'
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../assets/css/variables.styl"
  .image-container
    overflow: hidden
    max-height: 91vh

  .not-found
    max-width 100vw
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

  .title-found
    font-size 7em
    max-height 120px
    vertical-align middle
    color $gold100

  .text-found
    position fixed
    top 50%
    left 50%
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align center

  @media screen and (max-width: 1100px) {
    .not-found {
      max-width inherit
      max-height 100vh
      background-position: center;
      background-repeat: repeat;
      background-size: cover;
    }
    .image-container {
      overflow inherit;
    }
  }
</style>
