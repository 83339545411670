<template>
  <div class="summary-panel">
    <v-card class="grid" :style="orderedQualityScoreCriteria.length ? 'grid-row-gap: 20px;' : ''">
      <div v-if="orderedQualityScoreCriteria.length" style="grid-column-start: 1; grid-column-end: 3;">
      <h3 style="align-self: center; margin-bottom: 16px" v-if="orderedQualityScoreCriteria.length">
        {{ 'newRFP.feeDefinition.requestedInformationTitle' | t }}
        <ChangeIcon v-if="requestedInformationChanged"></ChangeIcon>
      </h3>
        <SelectableCardMultiSelect
          style="grid-template-columns: 1fr 1fr; padding-bottom: 30px;"
          small
          only-selected
          disabled
          :files="files"
          :custom-title="customTitle"
          :options="localQualityScoreOptions"
          :has-text-field="hasTextField"
          :text-field-value="textFieldValue"
          :text-field-placeholder="textFieldPlaceholder"
          :value="currentRfp.terms.qualityScoreCriteria" />
      </div>
      <h3 >{{ "activeRFP.summarySection.feeDetails.feeModel" | t }}</h3>
      <p class="dark" style="padding-top: 0; border-top: 0;">
      <!-- <p class="dark" style="margin-bottom: 0;"> -->
        {{ currentRfp.terms.feeStructure && `dropDownOptions.FeeStructure.${currentRfp.terms.feeStructure}` | t  }}{{ hasSubFeeStructure ? ( ', ' + $t(`dropDownOptions.FeeStructureSubType.${currentRfp.terms.feeStructureSubType}`)) : '' }}
      </p>

      <h3 >{{ "activeRFP.summarySection.feeDetails.currency" | t }}</h3>
      <p class="dark">
        {{ `dropDownOptions.Currency.${currentRfp.terms.currency || 'EUR'}` | t  }}
      </p>

    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import splitDateTime from '../../../../util/splitDateTime'
import SelectableCardMultiSelect from '../../../generalComponents/SelectableCardMultiSelect'
import ChangeIcon from '@/views/generalComponents/ChangeIcon'

export default {
  name: 'ProposalSummaryPanel',
  components: { ChangeIcon, SelectableCardMultiSelect },
  props: {
    currentRfp: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      criteriaOrdering: {
        TEAM: 1,
        CREDENTIALS: 2,
        PITCH_DOCUMENT: 3,
        LIABILITY: 4,
        STRATEGY: 5,
        CUSTOM1: 6,
        CUSTOM2: 7,
        CUSTOM3: 8,
        CUSTOM4: 9
      }
    }
  },
  computed: {
    ...mapGetters(['qualityScoreOptions', 'requestedInformationChanged']),

    localQualityScoreOptions () {
      const res = [...this.qualityScoreOptions]

      for (const custom of ['CUSTOM1', 'CUSTOM2', 'CUSTOM3']) {
        if (this.currentRfp.terms.qualityScoreCriteria.includes(custom)) {
          res.push({
            value: custom
          })
        }
      }

      return res
    },

    hasSubFeeStructure () {
      return ['RATE_CARD', 'RATE_CARD_CAP', 'ESTIMATE_RATE_CARD'].includes(this.currentRfp.terms.feeStructure) && this.currentRfp.terms.feeStructureSubType
    },

    orderedQualityScoreCriteria () {
      if (this.currentRfp.terms.qualityScoreCriteria) {
        const criteria = [...this.currentRfp.terms.qualityScoreCriteria]
        const criteriaOrdering = this.criteriaOrdering

        criteria.sort((a, b) => criteriaOrdering[a] - criteriaOrdering[b])

        return criteria
      } else {
        return []
      }
    }
  },
  methods: {
    splitDateTime,

    files (option) {
      if (option.value === 'PITCH_DOCUMENT') {
        return this.currentRfp.attachments.filter(document => document.rfpFileType === 'PITCH_DOCUMENT_DEFINITION')
      }

      return []
    },

    hasTextField (option) {
      const basic = ['CREDENTIALS', 'PITCH_DOCUMENT', 'LIABILITY', 'TEAM', 'STRATEGY'].includes(option.value)
      const custom = option.value.includes('CUSTOM')
      return (basic || custom) &&
        this.currentRfp.terms.qualityScoreCriteria.includes(option.value)
    },

    textFieldTitle (option) {
      switch (option.value) {
        case 'CREDENTIALS': return 'newRFP.feeDefinition.requestedTypeOfCredentials'
        case 'PITCH_DOCUMENT': return 'newRFP.feeDefinition.requestedContentOfPitchDocument'
        default: return ''
      }
    },

    textFieldValue (option) {
      let teamValue = ''
      if (this.currentRfp.terms.isCVMandatory) {
        teamValue = this.$t('newRFP.feeDefinition.isCVMandatory') + '\n'
      }
      teamValue += this.currentRfp.terms.teamDiversity || ''
      switch (option.value) {
        case 'CREDENTIALS': return this.currentRfp.terms.requestedCredentialType
        case 'PITCH_DOCUMENT': return this.currentRfp.terms.requestedPitchDocumentContent
        case 'LIABILITY': return this.currentRfp.terms.requestedLiabilityContent
        case 'STRATEGY': return this.currentRfp.terms.requestedStrategyContent
        case 'TEAM': return teamValue
        case 'CUSTOM1': return this.currentRfp.terms.customQualityScoreCriteria.custom1.requestedContent
        case 'CUSTOM2': return this.currentRfp.terms.customQualityScoreCriteria.custom2.requestedContent
        case 'CUSTOM3': return this.currentRfp.terms.customQualityScoreCriteria.custom3.requestedContent
        default: return ''
      }
    },

    customTitle (option) {
      return this.currentRfp.terms.customQualityScoreCriteria[option.toLowerCase()].title
    },

    textFieldPlaceholder (option) {
      switch (option.value) {
        case 'CREDENTIALS': return 'newRFP.feeDefinition.requestedCredentialsPlaceholder'
        case 'PITCH_DOCUMENT': return 'newRFP.feeDefinition.requestedPitchDocumentPlaceholder'
        default: return ''
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../../assets/css/variables.styl"

  .icon
    .v-icon
      color $dark-gray !important
      font-size 22px

  ul
    grid-column-start: 2
    li
      height 35Px
      display inline
      float: left
      margin-right 15px
      padding 5px 0
      min-width 70px
      p
        margin-bottom 0px

  .name
    font-size 18px
    color $dark-gray
    text-align left
    font-weight bold

  .time-grid
    display grid
    grid-template-columns 1fr 1fr 1fr 1fr
    margin-bottom 25px

  .light
    color $icon-gray

  .dark
    color $dark-gray !important
    font-size 21px

  .strong
    color $dark-gray
    font-size 16px
    font-weight bold

  .qa-grid
    display grid
    grid-template-columns 1fr
    grid-row-gap 5px

  >>> .v-input__slot
    align-items end !important

</style>
