<template>
  <div class="grid">
    <h3 style="line-height: 48px;">{{ $t(`sections.${section.key}`) }}</h3>

    <v-btn flat @click="showNewPanelInput = true; $emit('subjectChange')">
      <v-icon>ic-plus-circle</v-icon>
      &nbsp;
      {{ 'scopeGeneratorAdminView.layout.addSubSection' | t }}
    </v-btn>

    <PanelDefinition
      style="grid-column-start: 1; grid-column-end: 3;"
      v-for="(panel, index) in section.panels"
      @moveUp="move(index, 1)"
      @moveDown="move(index, -1)"
      :disable-up-button="index === 0"
      :disable-down-button="index === section.panels.length - 1"
      @delete="section.panels.splice(index, 1)"
      @update="updateSomeSection(section)"
      @addRowChange="$emit('subjectChange')"
      :key="panel.key"
      :ref="panel.key"
      :panel="panel" />

    <PanelDefinitionInput
      style="grid-column-start: 1; grid-column-end: 3;"
      v-if="showNewPanelInput"
      :panel="newPanel"
      @cancel="cancel"
      @add="addNew" />
  </div>
</template>

<script>
import PanelDefinition from './PanelDefinition'
import PanelDefinitionInput from './PanelDefinitionInput'
import { mapMutations } from 'vuex'

export default {
  name: 'SectionDefinition',
  components: { PanelDefinitionInput, PanelDefinition },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showNewPanelInput: false,
      newPanel: {
        key: '',
        isList: false,
        rows: [],
        labelTranslations: { en: '' },
        enabledBy: []
      }
    }
  },
  methods: {
    ...mapMutations(['updateSection']),

    addNew () {
      this.section.panels.push(JSON.parse(JSON.stringify(this.newPanel)))
      const key = this.newPanel.key
      // console.log('addsasd')
      this.$emit('subjectChange')

      this.$nextTick(() => {
        this.$refs[key][0].add()
      })

      this.cancel()
    },

    move (index, direction = 1) {
      const selectedIndex = index
      const targetIndex = index - direction

      const target = this.section.panels[targetIndex]
      const selected = this.section.panels[selectedIndex]

      this.$set(this.section.panels, targetIndex, selected)
      this.$set(this.section.panels, selectedIndex, target)
    },

    updateSomeSection (section) {
      this.updateSection(section)
      this.$emit('subjectChange')
    },

    cancel () {
      this.newPanel = {
        key: '',
        isList: false,
        labelTranslations: { en: '' },
        enabledBy: [],
        rows: []
      }
      this.showNewPanelInput = false
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .v-card,
  >>> .v-card
    padding 30px 40px
    margin-bottom 20px
    border-radius 10px
    display grid

  .v-card
    grid-template-columns 50px 1fr 50px 150px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input--checkbox .v-input__slot
    border none !important

  span
    color rgb(158, 158, 158)
    padding-left 12px

  .grid
    display grid
    grid-template-columns 1fr 150px
    grid-column-gap 10px

    >>> .v-icon:before
      font-size 20px
      color $dark-gray !important

    >>> button:disabled .v-icon:before
      color inherit !important
</style>
