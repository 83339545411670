
export const terms = `
  decisionType
  feeStructure
  feeStructureSubType
  liabilityType
  liabilityAmount
  billingType
  billingTypeSpecification
  generalFees
  maximumFee
  travelExpenseType
  travelExpenseCap
  representationType
  qualityScoreCriteria
  internalCoordinationType
  associatesExpenseType
  traineesExpenseType
  trainingExpenseType
  flightExpenseType
  customFlightExpense
  trainExpenseType
  customTrainExpense
  rentalCarExpenseType
  customRentalCarExpense
  hotelExpenseType
  customHotelExpense
  quickTermsAndConditions
  usesTermsGenerator
  hasAlertThreshold
  alertThreshold
  needsConsentToLeaveScope
  requestedCredentialType
  requestedPitchDocumentContent
  requestedLiabilityContent
  requestedStrategyContent
  isCVMandatory
  currency
  teamDiversity
  customQualityScoreCriteria {
    custom1 {
      title
      requestedContent
    }
    custom2 {
      title
      requestedContent
    }
    custom3 {
      title
      requestedContent
    }
  }
`

export const termsForListView = `
  decisionType
  feeStructure
  feeStructureSubType
  qualityScoreCriteria
  currency
`

export const lawFirm = `
  id
  active
  deletable
  name
  street
  city
  country
  taxId
`

export const corporate = `
    id
    active
    deletable
    name
    rfps {
      id
    }
    panelLawFirms {
      active
      lawFirm {
        id
        name
      }
    }
    paymentPeriodStart
    transactionVolume {
      volumeEUR
      volumeUSD
      periodStart
      periodEnd
    }
`

export const attachment = `
  id
  fileName
  createdAt
  rfpFileType
  url
  subject
  originalFileId
`
export const rating =
   ` lawFirm {
    id
    name
  }
  rate
`
export const lawFirmRelationJustId = (userIsLawFirm, userIsAdmin) => {
  const name = userIsLawFirm ? '' : 'name'
  const lawFirmHash = userIsLawFirm ? 'lawFirmHash' : ''
  // const proposalSubmitted = userIsLawFirm ? 'proposalSubmitted' : ''
  return `
    rfp {
      id
    }
    lawFirm {
      id
      ${name}
    }
    ${lawFirmHash}
    ${userIsAdmin ? `
      lawfirmBilled
      participationFee
      firstBidSubmission
      requiresResubmission
    ` : ''}
    coi1Confirmed
    coi2Confirmed
    coi2RejectionReason
    status
    statusPreAbortion
    rank
    proposalSubmitted
    proposalSubmission
  `
}

export const lawFirmRelationWithProposal = (userIsLawFirm) => {
  const ratingField = `
    rating
    ratingComment
    ratingTime
    ratingCommentTime
  `

  const ratingFieldSubItems = userIsLawFirm ? '' : 'rating'
  return `
  ${lawFirmRelationJustId(userIsLawFirm)}
  requestedLawyers {
   id
   fullName
   email
   invitePending
  }
  requiresResubmission
  proposalSubmitted
  proposalSubmission
  proposalRating
  priceRating
  overallStarRating
  discloseFeedback
  overallFeedbackRating
  feedbackRatingByCategory
  feedbackRatings {
    questionKey
    category
    rating
    extendedFields
    linkedUser {
      id
      fullName
    }
  }
  feedbackRatingByCategory
  overallFeedbackRating
  currentDisplayBid {
    relevantDisplayValue
    id
    ${auctionBid}
  }
  firstBidToBePlaced {
    ${auctionBid}
  }
  firstBidToBePlacedDraft {
    ${auctionBid}
  }
  proposalUpdate
  proposal {
    id
    name
    draft
    ${ratingField}
    attachments {
      ${attachment}
    }
    subItems {
      id
      draft
      ${ratingFieldSubItems}
      linkedUser {
        id
        fullName
      }
      extendedFields
      attachments {
        ${attachment}
      }
    }
    extendedFields
  }
`
}

export const completeRfp = (userIsLawFirm) => {
  const winneruser = !userIsLawFirm ? 'winner { id name}' : ''
  return `
  id
  name
  description
  shareLiveFeedback
  corporate {
    name
  }
  mandateType
  mandateSubType
  activeLawFirms
  abortionMessage
  subjects {
    key
    translatedLabel
  }
  scopeGeneratorLayoutsBySubject
  attachments {
    ${attachment}
  }
  quickScope
  scope
  lawFirmRelations {
    ${lawFirmRelationWithProposal(userIsLawFirm)}
  }
  terms {
    ${terms}
  }
  auctionType
  auctionSubType
  status
  statusPreAbortion
  allowQA
  newBidExtendsAuction
  qaEnd
  firstBidDeadline
  auctionStart
  auctionEnd
  clientName
  submission
  ${winneruser}
  coi1
  draftStep
  proposalScoreWeight
  usesScopeGenerator
  disclosureMode
  nextSteps
  team {
    rfp {
      id
    }
    lintumUser {
      id
      fullName
    }
    level
    hideInProposal
  }
  ${userIsLawFirm ? '' : 'decisionNotes'}
  winnerMessage
  loserMessage
  freeScope {
    facts
    requestedServices
    governingLaw
    areasOfLaw
    timeline
    languages
  }
  winnerSelectionDate
  minBid
  teamScoreWeight
  credentialsScoreWeight
  liabilityScoreWeight
  pitchDocumentScoreWeight
  qaScoreWeight
  strategyScoreWeight
  interviewScoreWeight
  custom1ScoreWeight
  custom2ScoreWeight
  custom3ScoreWeight
`
}

export const rfpForListView = (userIsLawFirm, userIsAdmin) => {
  const corporate = userIsAdmin ? 'corporate { name }' : ''
  const loadAttachment = userIsAdmin ? `attachments { ${attachment} }` : ''
  return `
    id
    name
    description
    ${corporate}
    ${loadAttachment}
    ${userIsAdmin ? `
      corporateBilled
      corporateBilledDate
      invoiceAmount
      winnerSelectionDate
    ` : ''}
    mandateType
    mandateSubType
    lawFirmRelations {
      ${lawFirmRelationJustId(userIsLawFirm, userIsAdmin)}
    }
    terms {
      ${termsForListView}
    }
    coi1
    subjects {
      key
      translatedLabel
    }
    auctionType
    auctionSubType
    status
    statusPreAbortion
    allowQA
    qaEnd
    firstBidDeadline
    auctionStart
    auctionEnd
    invoiceUploadDate
    clientName
    submission
    winner {
      id
      name
    }
    draftStep
    minBid
    finalBid
  `
}

export const question = (userIsLawFirm) => {
  const idField = userIsLawFirm ? 'lawFirmId: lawFirmHash' : 'lawFirmId'
  return `
  id
  text
  timestamp
  ${idField}
  answer {
    id
    text
    timestamp
    lastChange
    attachments {
      ${attachment}
    }
  }
  attachments {
    ${attachment}
  }
`
}

export const questionWithRfp = (userIsLawFirm) => {
  const idField = userIsLawFirm ? 'lawFirmId: lawFirmHash' : 'lawFirmId'
  return `
  id
  text
  timestamp
  ${idField}
  rfp {
    id
  }
  answer {
    id
    text
    timestamp
    lastChange
    attachments {
      ${attachment}
    }
  }
  attachments {
    ${attachment}
  }
`
}

export const scopeGeneratorLayout = (forAdminView) => {
  const labelField = forAdminView ? 'labelTranslations' : 'translatedLabel'
  const itemNameField = forAdminView ? 'itemNameTranslations' : 'translatedItemName'
  return `
   sections {
      icon
      key
      ${labelField}
      panels {
        key
        isList
        ${labelField}
        ${itemNameField}
        enabledBy {
          absolutePath
          relativePath
          values
        }
        rows {
          fields {
            key
            fieldType
            ${labelField}
            optionListKey
            enabledBy {
              absolutePath
              relativePath
              values
            }
            constraints {
              key
              dtype
              absolutePath
              relativePath
              value
            }
          }
        }
      }
    }
  `
}

export const auctionBid = `
  amountBlendedRate
  amountFixed
  amountRateLevel1
  amountRateLevel2
  amountRateLevel3
  amountRateLevel4
  hoursLevel1
  hoursLevel2
  hoursLevel3
  hoursLevel4
`
export const lawFirmAnalytics = `
  period
  rfpsParticipated
  rfpsWon
  agreedRevenue
  rfpsByFeeStructure
`

export const corporateAnalytics = `
  period
  totalRfps
  agreedFees
  savings
  rfpsByFeeStructure
`
