<template>
  <div>
    <div class="center-title">
      <h1>{{ 'newRFP.feeDefinition.feeTitle' | t }}</h1>
      <TooltipHolder
        class="info-icon"
        :titleText="$t('newRFP.feeDefinition.feeTitle')"
        :spanId="$t('newRFP.feeDefinition.feeTitle')"
        :width="150"
        :moveTop="30"
        :icon="'info'"
        :tooltipText="$t('tooltips.proposalTitleTooltip')"
        right
        summaryTooltip />
    </div>
    <v-divider style="margin-bottom: 55px;"></v-divider>

    <div class="content">
      <div style="display: grid; grid-template-columns: 1fr max-content">
        <h2>{{ 'newRFP.feeDefinition.requestedInformationTitle' | t  }}</h2>
        <v-btn class="add-btn" @click="addCriterium" flat><v-icon>add_circle</v-icon>&nbsp;Add Criterium</v-btn>
      </div>

      <div>
        <SelectableCardMultiSelect
          style="grid-template-columns: 1fr 1fr"
          :options="qualityScoreOptionsLocal"
          :shouldStartShowingSubOptions="shouldStartShowingSubOptions"
          :value="currentRfp.terms.qualityScoreCriteria"
          :disabled="!isRfpFieldEditable()"
          :has-text-field="hasTextField"
          :text-field-value="textFieldValue"
          :text-field-placeholder="textFieldPlaceholder"
          :has-title-tooltip="hasTitleTooltip"
          :title-tooltip-text="titleTooltipText"
          :show-file-icon="showFileIcon"
          :has-additional-check-box="hasAdditionalCheckBox"
          :additional-check-box-text="checkBoxText"
          :additional-check-box-value="checkBoxValue"
          :custom-title="customTitle"
          :set-custom-title="setCustomTitle"
          @fileIconClicked="fileIconClicked"
          @deleteIconClicked="deleteIconClicked"
          small
          showDeleteIconForFiles
          :files="files"
          ref="proposalSelect"
          @deleteFile="deleteFileLocal"
          @textFieldInput="textFieldInput"
          @checkBoxInput="checkBoxInput"
          @input="value => {updateCurrentRfpTerms({ field: 'qualityScoreCriteria', value });}" />
      </div>

      <h2>{{ 'newRFP.feeDefinition.feeModelTitle' | t }}</h2>
      <SelectableCardRadio
        :class="{
          missing: missingMandatoryFields.includes('terms/feeStructure'),
          'missing-sub-item': missingMandatoryFields.includes('terms/feeStructureSubType')
        }"
        :disabled="!isRfpFieldEditable(true)"
        :options="feeStructureOptions"
        :value="currentRfp.terms.feeStructure"
        @input="value => {updateCurrentRfpTerms({ field: 'feeStructure', value });  removeMissingMandatoryField('terms/feeStructure');}"
        :shouldStartShowingOptions="['RATE_CARD', 'RATE_CARD_CAP', 'ESTIMATE_RATE_CARD'].includes(currentRfp.terms.feeStructure)"
        :subOptions="feeStructureSubOptions"
        :should-show-sub-options="shouldShowFeeSubStructure"
        :subOptionValue="currentRfp.terms.feeStructureSubType"
        @update:subOptionValue="value => {updateCurrentRfpTerms({ field: 'feeStructureSubType', value }); removeMissingMandatoryField('terms/feeStructureSubType')}"
        small
          />

      <h2>{{ 'newRFP.feeDefinition.currency' | t }}</h2>
      <SelectableCardRadio
        :class="{
          missing: missingMandatoryFields.includes('terms/currency')
        }"
        :smallSpace="true"
        :options="currencyOptions"
        :disabled="!isRfpFieldEditable()"
        :value="currentRfp.terms.currency"
        @input="value => {updateCurrentRfpTerms({ field: 'currency', value }); removeMissingMandatoryField('terms/currency');}"
        small
      />

      <input
        hidden
        ref="fileUpload"
        @change="fileChanged"
        type="file"
        accept=".pdf,.docx">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import SelectableCardRadio from '../../generalComponents/SelectableCardRadio'
import SelectableCardMultiSelect from '../../generalComponents/SelectableCardMultiSelect'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import Vue from 'vue'

export default {
  name: 'Proposal',
  components: {
    SelectableCardMultiSelect,
    SelectableCardRadio,
    TooltipHolder
  },
  data () {
    return {
      qualityScoreOptionsLocal: []
    }
  },
  created () {
    this.qualityScoreOptionsLocal = [...this.qualityScoreOptions]

    for (const custom of ['CUSTOM1', 'CUSTOM2', 'CUSTOM3']) {
      if (this.currentRfp.terms.customQualityScoreCriteria[custom.toLowerCase()]) {
        this.qualityScoreOptionsLocal.push({
          value: custom
        })
      }
    }

    if (!this.currentRfp.terms.feeStructureSubType) {
      this.updateCurrentRfpTerms({ field: 'feeStructureSubType', value: 'BLENDED' })
    }
  },
  computed: {
    ...mapGetters(['currentRfpOrDraft', 'decisionTypeOptions', 'feeStructureOptions', 'qualityScoreOptions', 'feeStructureSubOptions', 'currencyOptions', 'isRfpFieldEditable']),
    ...mapState({
      _currentRfp: state => state.currentRfp,
      missingMandatoryFields: state => state.validation.missingMandatoryFields
    }),

    currentRfp () {
      return this.currentRfpOrDraft
    },

    displaySubStructure () {
      if (this.currentRfp.terms.qualityScoreCriteria.length >= 1) {
        return true
      } else {
        return false
      }
    },

    shouldStartShowingSubOptions () {
      return {
        upperCards: this.currentRfp.terms.qualityScoreCriteria.includes('TEAM') || this.currentRfp.terms.qualityScoreCriteria.includes('LIABILITY'),
        downCards: this.currentRfp.terms.qualityScoreCriteria.includes('CREDENTIALS') || this.currentRfp.terms.qualityScoreCriteria.includes('PITCH_DOCUMENT'),
        thirdCards: this.currentRfp.terms.qualityScoreCriteria.includes('CUSTOM1') || this.currentRfp.terms.qualityScoreCriteria.includes('STRATEGY'),
        fourdCards: this.currentRfp.terms.qualityScoreCriteria.includes('CUSTOM2') || this.currentRfp.terms.qualityScoreCriteria.includes('CUSTOM3')
      }
    }
  },
  methods: {
    ...mapActions(['uploadFile', 'deleteFile']),
    ...mapMutations(['updateCurrentRfpTerms', 'removeMissingMandatoryField']),

    customTitle (option) {
      return this.currentRfp.terms.customQualityScoreCriteria[option.toLowerCase()].title
    },

    setCustomTitle (option, value) {
      this.currentRfp.terms.customQualityScoreCriteria[option.toLowerCase()].title = value
    },

    deleteIconClicked (option) {
      const value = option.value
      if (value === 'CUSTOM3') {
        this.removeOption('CUSTOM3')
      }

      const threeToTwo = () => {
        if (this.currentRfp.terms.customQualityScoreCriteria.custom3) {
          this.currentRfp.terms.customQualityScoreCriteria.custom2 = this.currentRfp.terms.customQualityScoreCriteria.custom3
          this.reassignIfNeeded('CUSTOM3', 'CUSTOM2')
          this.removeOption('CUSTOM3')
        } else {
          this.removeOption('CUSTOM2')
        }
      }

      if (value === 'CUSTOM2') {
        threeToTwo()
      }

      if (value === 'CUSTOM1') {
        if (this.currentRfp.terms.customQualityScoreCriteria.custom2) {
          this.currentRfp.terms.customQualityScoreCriteria.custom1 = this.currentRfp.terms.customQualityScoreCriteria.custom2
          this.reassignIfNeeded('CUSTOM2', 'CUSTOM1')
          // this.removeOption('CUSTOM2')

          threeToTwo()
        } else {
          this.removeOption('CUSTOM1')
        }
      }
    },

    reassignIfNeeded (_from, to) {
      const fromSelected = this.currentRfp.terms.qualityScoreCriteria.includes(_from)
      const toSelected = this.currentRfp.terms.qualityScoreCriteria.includes(to)

      if (fromSelected === toSelected) {
        return
      }
      this.$refs.proposalSelect.handleSelected({
        value: to
      })
    },

    removeOption (criterium) {
      Vue.set(this.currentRfp.terms.customQualityScoreCriteria, criterium.toLowerCase(), null)
      this.qualityScoreOptionsLocal = this.qualityScoreOptionsLocal.filter(x => x.value !== criterium)
      if (this.currentRfp.terms.qualityScoreCriteria.includes(criterium)) {
        this.$refs.proposalSelect.handleSelected({
          value: criterium
        })
      }
    },

    async addCriterium () {
      if (!this.currentRfp.terms.customQualityScoreCriteria) {
        Vue.set(this.currentRfp.terms, 'customQualityScoreCriteria', {
          custom1: null,
          custom2: null,
          custom3: null
        })
      }

      if (!this.qualityScoreOptionsLocal.some(x => x.value === 'CUSTOM1')) {
        this.qualityScoreOptionsLocal.push({
          value: 'CUSTOM1'
        })
        Vue.set(this.currentRfp.terms.customQualityScoreCriteria, 'custom1', {
          title: '',
          requestedContent: ''
        })
        await this.$nextTick()
        this.$refs.proposalSelect.handleSelected({
          value: 'CUSTOM1'
        })
      } else if (!this.qualityScoreOptionsLocal.some(x => x.value === 'CUSTOM2')) {
        this.qualityScoreOptionsLocal.push({
          value: 'CUSTOM2'
        })
        Vue.set(this.currentRfp.terms.customQualityScoreCriteria, 'custom2', {
          title: '',
          requestedContent: ''
        })
        await this.$nextTick()
        this.$refs.proposalSelect.handleSelected({
          value: 'CUSTOM2'
        })
      } else if (!this.qualityScoreOptionsLocal.some(x => x.value === 'CUSTOM3')) {
        this.qualityScoreOptionsLocal.push({
          value: 'CUSTOM3'
        })
        Vue.set(this.currentRfp.terms.customQualityScoreCriteria, 'custom3', {
          title: '',
          requestedContent: ''
        })
        await this.$nextTick()
        this.$refs.proposalSelect.handleSelected({
          value: 'CUSTOM3'
        })
      }
    },

    files (option) {
      if (option.value === 'PITCH_DOCUMENT') {
        return this.currentRfp.attachments.filter(document => document.rfpFileType === 'PITCH_DOCUMENT_DEFINITION')
      }

      return []
    },

    deleteFileLocal (file) {
      this.deleteFile({ rfpId: this.currentRfp.id, fileId: file.id, type: 'PITCH_DOCUMENT_DEFINITION' })
    },

    shouldShowFeeSubStructure (option) {
      return ['RATE_CARD', 'RATE_CARD_CAP', 'ESTIMATE_RATE_CARD'].includes(option.value) && this.currentRfp.terms.feeStructure === option.value
    },

    hasTextField (option) {
      const basic = ['CREDENTIALS', 'PITCH_DOCUMENT', 'LIABILITY', 'TEAM', 'STRATEGY'].includes(option.value)
      const custom = option.value.includes('CUSTOM')
      return (basic || custom) &&
        this.currentRfp.terms.qualityScoreCriteria.includes(option.value)
    },

    hasTitleTooltip (option) {
      return ['LIABILITY', 'TEAM'].includes(option.value)
    },

    showFileIcon (option) {
      return option.value === 'PITCH_DOCUMENT' && this.currentRfp.terms.qualityScoreCriteria.includes(option.value)
    },

    fileIconClicked () {
      this.$refs.fileUpload.click()
    },

    async fileChanged (event) {
      const file = event.target.files[0]
      await this.uploadFile({ file, type: 'PITCH_DOCUMENT_DEFINITION' })
    },

    titleTooltipText (option) {
      switch (option.value) {
        case 'LIABILITY': return this.$t('tooltips.liabilityCardTooltip')
        case 'TEAM': return this.$t('tooltips.teamCardTooltip')
      }
    },

    textFieldTitle (option) {
      switch (option.value) {
        case 'CREDENTIALS': return 'newRFP.feeDefinition.requestedTypeOfCredentials'
        case 'PITCH_DOCUMENT': return 'newRFP.feeDefinition.requestedContentOfPitchDocument'
        default: return ''
      }
    },

    textFieldValue (option) {
      switch (option.value) {
        case 'CREDENTIALS': return this.currentRfp.terms.requestedCredentialType
        case 'PITCH_DOCUMENT': return this.currentRfp.terms.requestedPitchDocumentContent
        case 'LIABILITY': return this.currentRfp.terms.requestedLiabilityContent
        case 'STRATEGY': return this.currentRfp.terms.requestedStrategyContent
        case 'TEAM': return this.currentRfp.terms.teamDiversity
        case 'CUSTOM1': return this.currentRfp.terms.customQualityScoreCriteria.custom1.requestedContent
        case 'CUSTOM2': return this.currentRfp.terms.customQualityScoreCriteria.custom2.requestedContent
        case 'CUSTOM3': return this.currentRfp.terms.customQualityScoreCriteria.custom3.requestedContent
        default: return ''
      }
    },

    checkBoxValue (option) {
      switch (option.value) {
        case 'TEAM': return this.currentRfp.terms.isCVMandatory
        default: return false
      }
    },

    checkBoxText (option) {
      switch (option.value) {
        case 'TEAM': return 'newRFP.feeDefinition.isCVMandatory'
        default: return false
      }
    },

    hasAdditionalCheckBox (option) {
      switch (option.value) {
        case 'TEAM': return true
        default: return false
      }
    },

    textFieldInput ({ option, value }) {
      if (option.value.includes('CUSTOM')) {
        switch (option.value) {
          case 'CUSTOM1':
            this.currentRfp.terms.customQualityScoreCriteria.custom1.requestedContent = value
            break
          case 'CUSTOM2':
            this.currentRfp.terms.customQualityScoreCriteria.custom2.requestedContent = value
            break
          case 'CUSTOM3':
            this.currentRfp.terms.customQualityScoreCriteria.custom3.requestedContent = value
            break
        }
        return
      }

      let field
      switch (option.value) {
        case 'CREDENTIALS':
          field = 'requestedCredentialType'
          break
        case 'PITCH_DOCUMENT':
          field = 'requestedPitchDocumentContent'
          break
        case 'LIABILITY':
          field = 'requestedLiabilityContent'
          break
        case 'TEAM':
          field = 'teamDiversity'
          break
        case 'STRATEGY':
          field = 'requestedStrategyContent'
          break
        default:
          field = ''
          break
      }
      this.updateCurrentRfpTerms({ field, value })
    },

    checkBoxInput ({ option, value }) {
      let field
      switch (option.value) {
        case 'TEAM':
          field = 'isCVMandatory'
          break
        default:
          return
      }

      console.log(this.currentRfp.terms.qualityScoreCriteria, option.value === 'TEAM' && value && !this.currentRfp.terms.qualityScoreCriteria.includes('TEAM'))
      if (option.value === 'TEAM' && value && !this.currentRfp.terms.qualityScoreCriteria.includes('TEAM')) {
        // this.updateCurrentRfpTerms({ field: 'qualityScoreCriteria', value: [...this.currentRfp.terms.qualityScoreCriteria, 'TEAM'] })
        this.$refs.proposalSelect.handleSelected(option)
      }

      this.updateCurrentRfpTerms({ field, value })
    },

    textFieldPlaceholder (option) {
      switch (option.value) {
        case 'CREDENTIALS': return 'newRFP.feeDefinition.requestedCredentialsPlaceholder'
        case 'PITCH_DOCUMENT': return 'newRFP.feeDefinition.requestedPitchDocumentPlaceholder'
        case 'LIABILITY': return 'newRFP.feeDefinition.requestedLiabilityContent'
        case 'TEAM': return 'newRFP.feeDefinition.teamDiversity'
        case 'STRATEGY': return 'newRFP.feeDefinition.strategyContent'
        default: return 'newRFP.feeDefinition.customContent'
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .add-btn
    border-radius 5px

  h1
    display inline-block

  .center-title
    font-size 32px
    margin-bottom 1.5em
    .info-icon
      font-size 20px
      margin-left 5px
      vertical-align middle

  >>> .info-icon
    font-size 20px
    margin-left 5px
    margin-bottom 4px
    .v-icon
      font-size 20px !important
      color: #ababb5 !important
      vertical-align middle

  .content
    h2
      margin-top 2.5em
      margin-bottom 0.5em
      display inline-block
    h2:first-child
      margin-top 0

  .decision-type
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-column-gap 15px

  .fee-structure
    display grid
    grid-template-columns 1fr 1fr 1fr 1fr
    grid-column-gap 15px

  .quality-score
    margin-top 2.5em
    .v-card
      padding 20px
      border-radius 10px
</style>
