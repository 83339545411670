<template>
  <div>
    <div class="center-title">
      <h1>{{ 'newRFP.termsAndConditions.termsTitle' | t }}</h1>
      <TooltipHolder
        class="info-icon"
        :titleText="$t('newRFP.termsAndConditions.termsTitle')"
        :spanId="$t('newRFP.termsAndConditions.termsTitle')"
        :width="150"
        :moveTop="30"
        :icon="'info'"
        :tooltipText="$t('tooltips.termsAndConditionsTitleTooltip')"
        right
        summaryTooltip />
    </div>

    <Tabs>
      <a :class="{active: currentRfp.terms.usesTermsGenerator}" @click="updateCurrentRfpTerms({ field: 'usesTermsGenerator', value: true }); resetMandatoryFields(); waitChange(true)">{{ 'newRFP.termsAndConditions.generatorTab' | t }}</a>
      <a :class="{active: !currentRfp.terms.usesTermsGenerator}" @click="updateCurrentRfpTerms({ field: 'usesTermsGenerator', value: false }); reset(); waitChange(false);">{{ 'newRFP.termsAndConditions.quickT&CTab' | t }}</a>
    </Tabs>

    <div class="main">
      <div class="side-nav" v-if="currentRfp.terms.usesTermsGenerator">
        <Sidebar
        :optionsArray="termsAndContitionSidebarArray"
        :terms="true"
        :componentsArray="componentArr" />
      </div>
      <div class="content" :style="!currentRfp.terms.usesTermsGenerator ? 'grid-column: 1 / 3' : ''">

        <template v-if="!currentRfp.terms.usesTermsGenerator">
          <QuickScopeEditor
            :disabled="!isRfpFieldEditable()"
            :placeholder="'newRFP.termsAndConditions.quickConditionPlaceholder' | t"
            :value="currentRfp.terms.quickTermsAndConditions"
            style="margin-top: 10px; padding: 13px 26px;"
            @input="value => { removeMissingMandatoryField('terms/quickTermsAndConditions'); updateCurrentRfpTerms({ field: 'quickTermsAndConditions', value }) }"
            :class="{missing: missingMandatoryFields.includes('terms/quickTermsAndConditions')}" />
          <DocumentUpload @deleteFile="value => deleteFiles(value)" :disabled="!isRfpFieldEditable()" :deleteIcon="isRfpFieldEditable()" style="margin-bottom: 150px; margin-top: 40px" :documents="currentRfp.attachments" type="TERMS" />
        </template>

        <template v-else>
          <h2 v-if="currentRfp.terms.feeStructure !== 'FIXED'" id="fees" ref="fees">{{ 'newRFP.termsAndConditions.fees' | t }}</h2>
          <FeesPanel v-if="currentRfp.terms.feeStructure !== 'FIXED'" />
          <h2 id="expenses" ref="expenses">{{ 'newRFP.termsAndConditions.expenses' | t }}</h2>
          <ExpensesPanel />
          <h2 id="billing" ref="billing">{{ 'newRFP.termsAndConditions.billing' | t }}</h2>
          <SelectableCardRadio
            style="grid-template-columns: 1fr 1fr 1fr 1fr; border-width: 2px !important;"
            small
            :class="{missing: missingMandatoryFields.includes('terms/billingType')}"
            :disabled="!isRfpFieldEditable()"
            :options="billingTypeOptions"
            :value="currentRfp.terms.billingType"
            @input="value => {updateCurrentRfpTerms({ field: 'billingType', value }); removeMissingMandatoryField('terms/billingType')}" />
          <v-card v-if="currentRfp.terms.billingType === 'OTHER'" style="margin-top: 1em; padding: 20px 50px;" >
            <v-card-text>
              <span class="light">{{'newRFP.termsAndConditions.placeHolders.otherBillingTypeLabel' | t}}</span>
              <v-textarea
                :class="{missingTextArea: missingMandatoryFields.includes('terms/billingTypeSpecification')}"
                :value="currentRfp.terms.billingTypeSpecification"
                @input="value => { updateCurrentRfpTerms({ field: 'billingTypeSpecification', value}); removeMissingMandatoryField('terms/billingTypeSpecification'); }"
                solo
                class="textarea"
                :placeholder="'newRFP.termsAndConditions.placeHolders.otherBillingType' | t"></v-textarea>
            </v-card-text>
          </v-card>
          <template v-if="!(currentRfp.terms.qualityScoreCriteria && currentRfp.terms.qualityScoreCriteria.includes('LIABILITY'))">
            <h2 id="liability" ref="liability">{{ 'newRFP.termsAndConditions.liability' | t }}</h2>
            <LiabilityPanel />
          </template>
          <div :style="currentRfp.terms.qualityScoreCriteria.includes('LIABILITY') ? 'height: 55vh' : 'height: 40vh'"></div>
        </template>

      </div>
    </div>

    <ScrollDownIndicator v-if="currentRfp.terms.usesTermsGenerator" />
  </div>
</template>

<script>
import QuickScopeEditor from '../../generalComponents/QuickScopeEditor'
import DocumentUpload from '../../generalComponents/DocumentUpload'
import Tabs from '../../generalComponents/Tabs'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import SelectableCardRadio from '../../generalComponents/SelectableCardRadio'
import FeesPanel from './components/FeesPanel'
import LiabilityPanel from './components/LiabilityPanel'
import ExpensesPanel from './components/ExpensesPanel'
import Sidebar from '../../generalComponents/SideBar'
import TooltipHolder from '../../generalComponents/TooltipHolder'
import ScrollDownIndicator from '../../generalComponents/ScrollDownIndicator'

export default {
  name: 'ScopeDefinition',
  components: {
    ScrollDownIndicator,
    ExpensesPanel,
    LiabilityPanel,
    FeesPanel,
    Tabs,
    QuickScopeEditor,
    DocumentUpload,
    SelectableCardRadio,
    Sidebar,
    TooltipHolder
  },
  data () {
    return {
      optionArr: [],
      componentArr: [],
      refVal: ''
    }
  },
  mounted () {
    this.componentArr = this.termsAndContitionSidebarArray.map(tabObj => {
      const component = this.$refs[tabObj.idRef]
      return component
    })
  },
  computed: {
    ...mapGetters(['billingTypeOptions', 'termsAndContitionSidebarArray', 'currentRfpOrDraft', 'isRfpFieldEditable']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      invalidFields: state => state.validation.invalidFields
    }),

    currentRfp () {
      return this.currentRfpOrDraft
    }
  },
  methods: {
    ...mapActions(['deleteFile']),
    ...mapMutations(['updateCurrentRfp', 'updateCurrentRfpTerms', 'removeMissingMandatoryField', 'resetMandatoryFields', 'removeInvalidField']),
    async waitChange (usesTerms) {
      await new Promise((resolve) => {
        setTimeout(function (changeSidebarArrays) {
          changeSidebarArrays()
          resolve()
        }, 100, this.changeSidebarArrays)
      })
    },

    changeSidebarArrays () {
      this.componentArr = this.termsAndContitionSidebarArray.map(tcObj => {
        const component = this.$refs[tcObj.idRef]
        return component
      })
    },

    reset () {
      if (this.invalidFields.includes('terms/travelExpenseCap')) {
        this.updateCurrentRfpTerms({ field: 'travelExpenseType', value: null })
        this.updateCurrentRfpTerms({ field: 'travelExpenseCap', value: null })
        this.removeInvalidField('terms/travelExpenseCap')
      }
      if (this.invalidFields.includes('terms/liabilityAmount') || this.currentRfp.terms.liabilityType === null) {
        this.updateCurrentRfpTerms({ field: 'liabilityAmount', value: null })
        this.updateCurrentRfpTerms({ field: 'liabilityType', value: null })
        this.removeInvalidField('terms/liabilityAmount')
      }
      if (this.invalidFields.includes('terms/maximumFee')) {
        this.updateCurrentRfpTerms({ field: 'generalFees', value: null })
        this.updateCurrentRfpTerms({ field: 'maximumFee', value: null })
        this.removeInvalidField('terms/maximumFee')
      }
      this.resetMandatoryFields()
    },

    deleteFiles (event) {
      this.deleteFile({ rfpId: this.currentRfp.id, fileId: event.id, type: 'TERMS' })
    }
  }

}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"
  >>> .v-input__slot
    margin-bottom: 5px !important

  h1
    display inline-block

  .center-title
    font-size 32px
    margin-bottom 1.5em
    .info-icon
      font-size 20px
      margin-left 5px
      vertical-align middle

  >>> .info-icon
    font-size 20px
    margin-left 5px
    margin-bottom 4px
    vertical-align middle
    .v-icon
      font-size 20px !important
      color: #ababb5 !important
      vertical-align middle

  .icon-green
    color rgb(102, 174, 4) !important

  .main
    display grid
    grid-template-columns 200px 1fr
    grid-column-gap 20px
    margin-top 1.5em

    .side-nav
      .fixed
        color white
        position fixed
        top 90px
        width 200px

      a
        display inline-block
        color gray
        font-weight bold
        width 100%
        padding 5px 10px
        border-radius 10px
        border: 1px solid rgba(0,0,0,0)
        margin 2px 0

        .v-icon
          font-size 16px
          color gray
        &.finished
          .v-icon
            color rgb(102, 174, 4) !important
        &.invalid
          border-color $red !important
          .v-icon
            color: $red  !important
        &.active
          color $dark-gray
          background #ffffff
          &.finished
            .v-icon
              color rgb(46, 121, 2) !important

          .v-icon
            color $dark-gray !important

          &.invalid
            border-color rgb(168, 18, 35) !important
            .v-icon
              color: rgb(168, 18, 35) !important

    .content
      h2
        margin-top 1.5em
        margin-bottom 0.5em
      h2:first-child
        margin-top 0
      .missing
        border: 2px solid $red !important
        border-radius: 10px !important

  >>> .v-card:not(.small):not(.elevation-2) {
    padding 13px 26px
    border-radius: 10px;

    .v-icon, label {
      color: $dark-gray !important
    }

    .v-icon {
      font-size: 20px !important
    }
  }

  >>> .v-input--radio-group {
    border 2px solid rgba(0,0,0,0)

    &.missing  {
      border: 2px solid $red !important
      border-radius: 10px !important
    }
  }

  >>> .v-messages {
    display: none
  }

  >>> .v-radio {
    margin-bottom: 0 !important;
  }

  .textarea >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  .missingTextArea >>> .v-input__slot
    border:2px solid $red !important

  >>> .inline-input {
    border: 1px solid #9e9e9e;
    border-radius: 5px;
    padding: 3px;
    width: 400px;
    margin-left: 10px;
    caret-color: black
  }

  >>> .light
    color: #ababb5
</style>
