<template>
  <div v-if="showFieldSummary">
    <component
      :is="fieldComponent(field)"

      :field-key="field.key"
      :translated-name="field.translatedLabel"

      :validations="[]"
      :enabled-by="field.enabledBy"
      :options="options"
      :type="field.fieldType"

      :readonly="readonly"

      @input="handleInput"
      @invalidInput="$emit('invalidInput')"
      :invalid="invalid"

      :mandatory="mandatory && !disabled"

      :min="field.fieldType === 'PERCENT' ? 0 : null"
      :percentage-input="field.fieldType === 'PERCENT'"

      :disabled="disabled"
      :value="dynamicValue" />

  </div>
</template>

<script>
import CheckboxField from '../inputFields/CheckboxField'
import RadioField from '../inputFields/RadioField'
import SingleLineField from '../inputFields/SingleLineField'
import MultiLineField from '../inputFields/MultiLineField'
import SelectField from '../inputFields/SelectField'
import TagField from '../inputFields/TagField'
import DateField from '../inputFields/DateField'
import NumberField from '../inputFields/NumberField'
import { mapGetters } from 'vuex'
import DateTimeField from '../inputFields/DateTimeField'
import moment from 'moment'

export default {
  name: 'DynamicField',
  components: { DateTimeField, TagField, SelectField, MultiLineField, SingleLineField, RadioField, CheckboxField, DateField, NumberField },
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      required: true
    },
    disabled: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['optionListByKey', 'inputDateToISO']),

    dynamicValue () {
      if (this.field.fieldType === 'DATE') {
        return this.value && moment(this.value).isValid() ? moment.utc(this.value).format('DD.MM.YYYY') : this.value
      }

      return this.value
    },

    mandatory () {
      return this.field.constraints.some(validation => validation.dtype === 'Mandatory')
    },

    options () {
      const optionList = this.optionListByKey(this.field.optionListKey)

      return optionList ? optionList.options : []
    },

    showFieldSummary () {
      if (this.readonly) {
        if (!this.disabled) {
          switch (this.field.fieldType) {
            case 'TEXT':
              return this.value.length > 0
            case 'CHECKBOX':
              return this.value
            default:
              return this.value !== null
          }
        } else {
          return false
        }
      } else {
        return true
      }
    }
  },
  methods: {
    fieldComponent (field) {
      switch (field.fieldType) {
        case 'CHECKBOX_INDENTED':
        case 'CHECKBOX': return 'CheckboxField'
        case 'RADIO': return 'RadioField'
        case 'TEXT': return 'SingleLineField'
        case 'MULTILINE_TEXT': return 'MultiLineField'
        case 'MULTI_SELECT':
        case 'SINGLE_SELECT': return 'SelectField'
        case 'TAGGABLE_SINGLE_SELECT': return 'TagField'
        case 'DATE': return 'DateField'
        case 'PERCENT':
        case 'NUMBER': return 'NumberField'
        case 'DATETIME': return 'DateTimeField'
        default: return ''
      }
    },

    handleInput (value) {
      if (this.field.fieldType === 'DATE' && value && moment(value, 'DD.MM.YYYY').isValid()) {
        value = this.inputDateToISO(value)
      }

      this.$emit('input', value)
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  >>> .v-icon
    color $dark-gray !important
    font-size 20px !important
    width 24px

  >>> .invalid,
  >>> .invalid .v-input__slot
    color $red
    border-color $red

    input
      color $red
</style>
