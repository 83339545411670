<template>
  <div>
    <span class="light" :class="{ mandatory: mandatory && !readonly, light: readonly }">{{ translatedName }}</span>
    <v-textarea
      v-if="!readonly"
      :value="value"
      @input="value => $emit('input', value)"
      :disabled="disabled"
      label=""
      :placeholder="placeholder"
      :class="{ invalid }"
      solo
      flat
      rows="3"
    ></v-textarea>
    <p v-else>{{ value }}</p>
  </div>
</template>

<script>
import InputFieldMixin from './InputFieldMixin'

export default {
  name: 'MultiLineField',
  mixins: [InputFieldMixin]
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"
  span:not(.light)
    padding-left 12px

  >>> .v-input__slot
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  >>> .v-input--is-disabled .v-input__slot
    background $light-gray !important

  >>> .v-text-field__details
    display none
</style>
