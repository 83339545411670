<template>
  <v-dialog
    :value="visible"
    @input="value => { $emit('visibilityChange', value); resetUser() }"
    width="700"
    persistent
    no-click-animation
  >
    <v-card v-click-outside-dialog="closeEvent">
      <v-card-title
        class="headline"
        primary-title
      >
        {{ `userManagement.dialog.title.${title}` | t }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <span class="mandatory">{{ 'userManagement.dialog.name' | t }}</span>
        <v-text-field
          label=""
          v-model="user.fullName"
          @input="removeMissingMandatoryField('fullName')"
          :placeholder="'userManagement.dialog.placeholder.name' | t "
          :class="{missing: missingMandatoryFields.includes('fullName')}"
          solo
          flat
        ></v-text-field>

        <span class="mandatory">{{ 'userManagement.dialog.mail' | t }}</span>
        <v-text-field
          label=""
          v-model="user.email"
          @input="removeMissingMandatoryField('email')"
          :placeholder="'userManagement.dialog.placeholder.mail' | t "
          :class="{missing: missingMandatoryFields.includes('email')}"
          solo
          flat
        ></v-text-field>

        <template v-if="userIsAdmin">
          <span class="mandatory">{{ 'userManagement.dialog.privileges' | t }}</span>
          <v-select
            label=""
            v-model="user.primaryRole"
            @input="removeMissingMandatoryField('primaryRole')"
            :items="primaryRoleOptions"
            :placeholder="'userManagement.dialog.placeholder.privileges' | t "
            :class="{missing: missingMandatoryFields.includes('primaryRole')}"
            solo
            flat
          >
          </v-select>

          <template v-if="user.primaryRole && user.primaryRole !== 'ROLE_LINTUM_ADMIN'">
            <span class="mandatory">{{ (newUserIsLawFirm ? 'userManagement.dialog.lawFirm' : 'userManagement.dialog.corporate') | t }}</span>
            <v-select
              label=""
              v-model="user[ newUserIsLawFirm ? 'lawFirm' : 'corporate' ]"
              @input="removeMissingMandatoryField(newUserIsLawFirm ? 'lawFirm' : 'corporate')"
              :items="newUserIsLawFirm ? sortedLawFirms : sortedCorporates"
              :item-text="itemName"
              return-object
              :placeholder="`userManagement.dialog.placeholder.${newUserIsLawFirm ? 'lawFirm' : 'corporate'}` | t "
              :class="{missing: missingMandatoryFields.includes(newUserIsLawFirm ? 'lawFirm' : 'corporate')}"
              solo
              flat></v-select>
          </template>
        </template>

        <template v-if="user.primaryRole && user.primaryRole !== 'ROLE_LINTUM_ADMIN'">
          <span>{{ 'userManagement.dialog.position' | t }}</span>
          <v-select
            label=""
            v-model="user.position"
            :items="user.primaryRole ? (user.primaryRole === 'ROLE_CORPORATE' ? corporateUserPositionOptions : lawfirmUserPositionOptions) : []"
            solo
            flat
          ></v-select>
        </template>

        <SelectField
          :translatedName="'settings.profile.language' | t"
          :options="languageOptions"
          v-model="user.language" />

        <v-checkbox v-model="user.mainContactPerson" class="check" :label="'userManagement.dialog.mainContact' | t"></v-checkbox>
        <v-checkbox
          v-model="user.active"
          class="check"
          :disabled="user.id === userData.userId"
          :label="'userManagement.dialog.active' | t"></v-checkbox>

        <template v-if="userIsAdmin && !user.id">
          <v-switch
            class="check"
            v-model="setPassword"
            :label="'userManagement.dialog.setPasswordManually' | t"
          ></v-switch>
          <span v-if="setPassword">{{ 'userManagement.dialog.password' | t }}</span>
          <v-text-field
            v-if="setPassword"
            label=""
            v-model="password"
            @input="validatePassword()"
            :placeholder="'userManagement.dialog.placeholder.password' | t "
            :class="{missing: passwordValidations.includes('has')}"
            solo
            flat
          ></v-text-field>
          <ul v-if="setPassword">
            <li :class="{missing: passwordValidations.includes('has')}">{{ 'userManagement.dialog.explanation.validSymbols' | t }}</li>
          </ul>
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="cancel">{{ 'buttons.cancel' | t }}</v-btn>
        <AwaitButton
          class="main"
          :callback="saveUserLocal">
          {{ 'userManagement.dialog.submit' | t }}
        </AwaitButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import AwaitButton from '../../generalComponents/AwaitButton'
import SelectField from '@/views/generalComponents/inputFields/SelectField'
import PasswordValidator from 'password-validator'

export default {
  name: 'AddUserDialog',
  components: { SelectField, AwaitButton },
  model: {
    prop: 'visible',
    event: 'visibilityChange'
  },
  props: {
    // Wether the dialog should be visible
    // Used with v-model (see model definition above)
    visible: {
      type: Boolean,
      required: false,
      default: false
    },

    userToUpdate: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      title: 'add',
      user: {
        email: '',
        fullName: '',
        position: '',
        mainContactPerson: false,
        primaryRole: null,
        lawFirm: null,
        corporate: null,
        active: true,
        language: this.$i18n.i18next.language
      },
      setPassword: false,
      password: '',
      passwordValidations: []
    }
  },
  created () {
    this.resetUser()
    this.schema = new PasswordValidator()
    this.schema
      .has(/^[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@\\[\]^_`{|}~]*$/)
  },
  watch: {
    userToUpdate () {
      if (this.userToUpdate) {
        this.title = 'update'
        this.user = { ...this.userToUpdate }
        if (!this.userIsAdmin) {
          this.user.primaryRole = this.userIsLawFirm ? 'ROLE_LAWFIRM' : 'ROLE_CORPORATE'
        }
      } else {
        this.resetUser()
      }
    }
  },
  computed: {
    ...mapGetters(['isValidEmail', 'primaryRoleOptions', 'userIsAdmin', 'userIsLawFirm', 'corporateUserPositionOptions', 'lawfirmUserPositionOptions', 'languageOptions']),
    ...mapState({
      missingMandatoryFields: state => state.validation.missingMandatoryFields,
      lawFirms: state => state.lawFirms,
      corporates: state => state.corporates,
      userData: state => state.userData
    }),

    sortedLawFirms: function () {
      return [...this.lawFirms].sort((a, b) => a.name < b.name ? -1 : 1)
    },
    sortedCorporates () {
      return [...this.corporates].sort((a, b) => a.name < b.name ? -1 : 1)
    },

    newUserIsLawFirm () {
      return this.user.primaryRole && this.user.primaryRole === 'ROLE_LAWFIRM'
    }
  },
  methods: {
    ...mapActions(['validate', 'createUser', 'updateUser']),
    ...mapMutations(['removeMissingMandatoryField', 'resetMandatoryFields', 'addMissingMandatoryField']),

    validatePassword () {
      this.passwordValidations = this.schema.validate(this.password, { list: true })
    },

    async saveUserLocal () {
      const requiredFields = ['fullName', 'email']

      if (this.userIsAdmin) {
        requiredFields.push('primaryRole')
        if (this.user.primaryRole === 'ROLE_CORPORATE') {
          requiredFields.push('corporate')
        } else if (this.user.primaryRole === 'ROLE_LAWFIRM') {
          requiredFields.push('lawFirm')
        }
      } else {
        this.user.lawFirm = this.userData.lawFirmId ? { id: this.userData.lawFirmId } : null
        this.user.corporate = this.userData.corporateId ? { id: this.userData.corporateId } : null
        this.user.primaryRole = this.userIsLawFirm ? 'ROLE_LAWFIRM' : 'ROLE_CORPORATE'
      }

      this.validate({ requiredFields, object: this.user })

      if (!this.isValidEmail(this.user.email)) {
        this.addMissingMandatoryField('email')
      }

      if (this.missingMandatoryFields.length > 0) {
        return
      }

      if (this.setPassword && this.passwordValidations.length > 0) {
        return
      }

      if (typeof this.user.id === 'undefined') {
        if (this.setPassword) {
          this.$set(this.user, 'password', this.password)
        }

        await this.createUser(this.user)
      } else {
        await this.updateUser(this.user)
      }

      this.$emit('visibilityChange', false)
      this.resetUser()
    },

    cancel () {
      this.resetUser()
      this.resetMandatoryFields()
      this.$emit('visibilityChange', false)
    },

    resetUser () {
      this.title = 'add'
      this.user = {
        fullName: '',
        email: '',
        primaryRole: null,
        corporate: null,
        lawFirm: null,
        active: true,
        language: this.$i18n.i18next.language
      }

      if (!this.userIsAdmin) {
        this.user.primaryRole = this.userIsLawFirm ? 'ROLE_LAWFIRM' : 'ROLE_CORPORATE'
      }

      if (this.userIsAdmin) {
        this.setPassword = false
        this.password = ''
        this.passwordValidations = []
      }
    },

    itemName (item) {
      const arr = this.newUserIsLawFirm ? 'lawFirms' : 'corporates'

      if (this[arr]) {
        const obj = this[arr].find(entity => entity.id === item.id)

        return obj ? obj.name : ''
      } else {
        return ''
      }
    },

    closeEvent (event) {
      if (this.visible) {
        this.cancel()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../../assets/css/variables.styl"

  .missing
    color $red !important

  .v-card
    border-radius 10px
    .v-card__actions
      padding 16px 45px 24px 45px
    .v-card__title
      padding 24px 45px 16px 45px

  .v-card__text
    padding 16px 45px
    span
      color rgb(158, 158, 158)
      padding-left 12px

  :deep(.v-input__slot)
    border 1px solid rgb(158, 158, 158)
    border-radius 5px !important

  .check {
    margin-top: 0px;

    :deep(.v-input__slot) {
      border: none;
    }

    :deep(.v-icon) {
      color: $dark-gray !important;
    }
  }

  :deep(.v-text-field__details),
  :deep(.v-messages)
    display none
</style>
