import apollo from '../../apollo'
import gql from 'graphql-tag'

export default {

  loadNotifications () {

  },

  unSubscribeToNotifications ({ state, commit }) {
    const { notificationSubscription } = state.notifications

    if (notificationSubscription) {
      notificationSubscription.unsubscribe()
      commit('setNotificationsSubscription', null)
    }
  },

  subscribeToNotifications ({ commit, state, getters }, notificationLenght = 0) {
    const observer = apollo(state).subscribe({
      // GraphQL document
      query: gql`subscription notification ($limit: Int!, $offset: Int!) {
        notification(limit: $limit, order_by: {created_at: desc}, offset: $offset) {
          id
          is_read
          message_key
          rfp_id
          user_id
          url_path
          created_at
          ${getters.userIsAdmin ? '' : `rfp_notification {
              name
            }`}
        }
      }`,
      variables: {
        limit: 20,
        offset: notificationLenght
      }
    })

    const subscription = observer.subscribe({
      next (data) {
        commit('setNotifications', data.data.notification)
      },
      error () {
        commit('setNoWebsocketSupportError', true)
      }
    })
    if (notificationLenght === 0) {
      commit('setNotificationsSubscription', subscription)

      console.log('Subscription', subscription)
      console.log('Subscription state', subscription._state)
    }
  },

  async setNotficationsToRead ({ getters, state }, notificationIds) {
    await apollo(state).mutate({
      mutation: gql`
        mutation setNotificationsToRead($notificationIds: [Int]!) {
          setNotificationsToRead(notificationIds: $notificationIds)
        }
      `,
      variables: {
        notificationIds: notificationIds
      }
    })
  }

}
